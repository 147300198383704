import * as actionTypes from '../actions/types'

const initialState = {
  loading: true,
  error: null,
  listStaticsOfPost: [],
  metaData: {
    totalPages: 1,
  },
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case actionTypes.GET_STATICS_USER:
      const { statics, metaData } = payload
      return {
        ...state,
        loading: false,
        error: null,
        listStaticsOfPost: statics,
        metaData,
      }
    default:
      return state
  }
}
