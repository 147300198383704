import {connect, useDispatch} from "react-redux";
import Paper from "@material-ui/core/Paper";
import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/Notice/notice2style";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "../../components/Gm-TextField/TextFieldForDatePicker";
import Box from "@material-ui/core/Box";
import TimePicker from "../Statistics/components/TimePicker2";
import TimePickerMin from "../Statistics/components/TimePickerMin";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {FiberManualRecord} from "@material-ui/icons";
import Button from "../../components/CustomButtons/Button";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import CustomButton from "../../components/CustomButtons/Button";
import moment from "moment";
import Spinner from "../../components/Spinner/Spinner";
import PushModal from './components/PushModal.js'
import TagsInput from 'react-tagsinput';
//자동제안은 react suggest

import stylesModal from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";


import bannerManagingApi from "../../api/mainManaging/bannerManagingApi";
import Checkbox from "@material-ui/core/Checkbox";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MenuItem from "@material-ui/core/MenuItem";
import CustomTextField from "../../components/Gm-TextField/TextField";
import EditBanner from "../MainManaging/components/EditBanner";
import {
    bannerManagingRequestErrorAction,
    editBannerManagingAction, getListBannerManagingAction
} from "../../redux/actions/mainManaging/bannerManagingAction.js";
import eventsManagingApi from "../../api/eventsManagingApi.js";
import {getListEventsAction} from "../../redux/actions/eventsManagingAction.js";
import userManagingApi from "../../api/userManagingApi.js";
import {getListUserAction, requestUserManagingAction} from "../../redux/actions/userManagingAction";
import userManaging from "../../redux/reducers/userManagingReducer";
import Table from "../UserManaging/components/Table";
import pushManagingApi from "../../api/pushManangingApi";
import PostModal from "../ContentsManaging/components/PostModal";
import postManagingApi from "../../api/postManagingApi";
import {handleFormValue} from "../../util/common";

const useStyles = makeStyles(styles)
const useStylesModal = makeStyles(stylesModal)

// 이벤트 상세임 아무튼 그럼
const PushDetail = (props) => {

    const {
        match,
        pushDetail,
        events,
        users,
        metaData: {totalRecords}, // user에 대한
    } = props

    const classes = useStyles()
    const classesModal = useStylesModal()
    const dispatch = useDispatch()

    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz

    const [loading, setLoading] = React.useState(false)

    // const [tags, setTags] = React.useState([]);
    const [formData, setFormData] = React.useState({
        id: isNaN(match?.params?.id) ? 0 : match?.params?.id,
        title: pushDetail != null ? pushDetail.title : '',
        contents: '',
        targetType: 'ALL',
        sendTime: '',
        linkUrl: '',
        type: 'WEB_VIEW',
        alarmType: 'TEXT',
        eventsTitle: "",
        eventsId: 0,
        startAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        fromTime: 0,
        fromMin: 0,
        files: '',
        isSend: false


    })
    const [imageFile, setImageFile] = React.useState(null)
    const [loadingBtn, setLoadingBtn] = React.useState(false)
    const [alert2, setAlert] = React.useState(null)
    const [member, setMember] = React.useState([])
    const [posts, setPosts] = React.useState([])


    const dateTimeCheck = (date, time, min) => {

        let Min = "00";
        if (min <= 9){
            Min = min.toString()
        }else{
            Min = min.toString()
        }

        if (time <= 9) {
            return date + " " + "0" + time + ":"+Min
        } else {
            return date + " " + time + ":"+Min
        }





    }
    const createPush = async () => {


        try {

            //내부, 외부 URL 체크
            //이벤트일때 이벤트 선택 필수
            //챌린지일때 챌린지 선택 필수
            const fd = new FormData()


            let sendMember = member.map((item) => {
                return item.id
            })

            let body = {
                id: formData.id,
                title: formData.title,
                contents: formData.contents,
                targetType: formData.targetType,
                sendTime: new Date(moment(dateTimeCheck(formData.startAt, formData.fromTime, formData.fromMin)).format("YYYY-MM-DD HH:mm")).getTime(),
                linkUrl: formData.linkUrl,
                type: formData.type,
                alarmType: formData.alarmType,
                eventsTitle: formData.eventsTitle,
                eventsId: formData.eventsId,
                member: sendMember,
                isSend: formData.isSend,
                postId: posts[0]?.id || null
            }


            if (imageFile == null) {
                //파일이 없을떄, 기존 이미지 수정할떄
                if (formData.files != null && formData.files != '') {
                    body.innerImage = formData.files.id
                }

            } else {
                //파일 새로 들어올떄
                setLoadingBtn(true)
                fd.append('file', imageFile, imageFile.name)
                fd.append('type', 'PUSH')
                setLoadingBtn(true)
                const {
                    data: {id: idImage, url, filename},
                } = await bannerManagingApi.uploadImage(fd);
                body.innerImage = idImage
                setLoadingBtn(false)
            }


            //업데이트 일때
            if (formData.id != 0) {


                const {data} = await pushManagingApi.update(body);

                if (data) {
                    alert("수정이 완료되었습니다.")
                    // Router.push('/admin/event-managing/' + formData.type)
                    // location.href = '/admin/event-mnaging/CHALLENGE'
                }
                return
            }
            //하단은 Insert일떄

            const {data} = await pushManagingApi.create(body);

            setLoadingBtn(false)


            if (data.id != null) {
                alert("저장이 완료되었습니다.")
                location.href = '/admin/push-managing'
            }


        } catch (e) {

        }


    }
    /*이벤트 조회*/
    const getListEvents = async (type) => {

        let orderString = '';

        //if (orderBy == 'first') {
        //처음 정렬시 현재 front에 나오는 표출 순서대로
        orderString = 'numOrder,DESC&createdAt,DESC';
        // } else {
        //   orderString = orderBy + ',' + order
        // }


        let params = {
            limit: 10000,
            offset: 0,
            type: type,
            order: orderString,
            searchText: '',
            searchKey: 'title',
            endAt: 'more60day'
        }

        try {

            const {data} = await eventsManagingApi.getListEvents(params)
            dispatch(getListEventsAction(data))

        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                console.log(error.response.data)
            }
        }
    }


    //이벤트 챌린지 변할 시
    useEffect(() => {

        // if (formData?.type === 'EVENT') {
        getListEvents(formData?.type)
        // } else if (formData?.type === 'CHALLENGE') {
        //     getListEvents('CHALLENGE')
        // }

    }, [formData.type]);


    const handleChangeTimePickerStart = (event) => {
        setFormData({...formData, fromTime: event.target.value})
    }

    const handleChangeFile = (e, type) => {
        //파일 Onchage 시 이미지 체인지
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            const img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.addEventListener('load', () => {
                setImageFile(file) // 일단 바로 체크
                // validateUploadImage(file, 'size') ||
                // (!validateUploadImage(img, 'widthAndHeight') && setImageFile(file))
            })
        }
    }

    const hideAlert = () => {
        setAlert(null)
    }


    //회원 Modal
    // Modal
    const showFeedModal = () => {

        //insert
        setAlert(
            <PostModal
                item={users}
                hideAlert={hideAlert}
                setAlert={setAlert}
                // getMemberList={getMemberList}
                postManagingApi={postManagingApi}
                dispatch={dispatch}
                setPosts={setPosts}
                posts={posts}
            />
        )


    }

    //회원 Modal
    // Modal
    const showUserModal = () => {


        // alert("Fdfd")
        // setSearchKey('ㅇㅇㅇㅇㅇ')
        //insert
        setAlert(
            <PushModal
                item={users}
                hideAlert={hideAlert}
                setAlert={setAlert}
                // getMemberList={getMemberList}
                userManagingApi={userManagingApi}
                dispatch={dispatch}
                setMember={setMember}
                member={member}

                // setSearchKey={setSearchKey}
                // searchKey={searchKey}
                // getListUserAction={getListUserAction}
                // totalRecords={totalRecords}
                // setRowsPerPage={setRowsPerPage}
                // rowsPerPage={rowsPerPage}
                // setPage={setPage}
                // page={page}
                // searchText={searchText}
                // setSearchText={setSearchText}

                // bannerManagingRequestErrorAction={
                //     bannerManagingRequestErrorAction
                // }


            />
        )


    }

    const delMemberList = (i) => {
        let member2 = member.splice(0, i)
        if (member.splice(member.length).length > 0) {
            member2.push(member.splice(member.length))
        }
        setMember(member2)
    }


    //modal에서 가져왔을때
    useEffect(() => {
    }, [member, formData.isSend]);


    //UPDATE일떄
    const getPushData = async () => {
        try {

            setLoading(true)

            if (formData.id > 0) {
                //데이터가 있는 것

                //setLoading 필요

                const data2 = await pushManagingApi.getPushInfo({id: parseInt(formData.id)});
                //데이터 매핑 dispatch쓰면 코드가 깔끔해지나 쓰지 않음
                const data = data2.data?.pushInfo
                const posts = data2.data?.posts

                setPosts(posts)

                // const data = pushInfo
                const tagsRe = []

                data.push_users.forEach((item) => {
                    tagsRe.push({
                        // id: item.userID,
                        id: item.userId,
                        nickname: item.nickname,
                        clientId: item.clientId,
                    })
                })

                setMember(tagsRe)
                //가져온데이터 다시 매핑
                setFormData({
                    id: isNaN(match?.params?.id) ? 0 : match?.params?.id,
                    title: data.title,
                    contents: data.contents,
                    targetType: data.targetType,
                    startAt: moment(data.sendTime).format('YYYY-MM-DD'),
                    fromTime: parseInt(moment(data.sendTime).format('HH')),
                    fromMin: parseInt(moment(data.sendTime).format('mm')),
                    linkUrl: data.linkUrl,
                    type: data.type,
                    alarmType: data.alarmType,
                    eventsTitle: "",
                    eventsId: data.eventsId,
                    files: data.files,
                    isSend: data.isSend
                })


                setLoading(false)

            }
            setLoading(false)

        } catch (e) {

        }
    }

    React.useEffect(() => {
        //getData
        getPushData()
    }, [formData.id])


    return (


        // <Paper  variant='outlined'>
        // <h3>이벤트 기간</h3>
        // </Pape>

        <div>
            {loading ? (<Spinner/>) : (<div>
                {alert2}
                <Paper variant='outlined' className={classes.paper}>
                    <GridContainer className={classes.grid} alignItems='center'>
                        {/*<GridItem xs={1} sm={1} md={1}>푸시 제목</GridItem>*/}
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'푸시 제목'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleFormValue(event.target.value, 'title', formData, setFormData)
                                    },
                                }}
                                value={formData.title}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />
                        </GridItem>
                        <GridItem xs={6}>

                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.grid} alignItems='center'>

                        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                            이미지
                        </GridItem>


                        <GridItem xs={5} sm={5} md={5}>
                            <input
                                accept='image/*'
                                className={classes.inputBtnUpload}
                                id='contained-button-file'
                                type='file'
                                onClick={(event => {
                                    event.target.value = ''
                                })}
                                onChange={(e) => {
                                    handleChangeFile(e, 1)
                                }}
                            />
                            <label htmlFor='contained-button-file'>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    component='span'
                                    fullWidth={true}
                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                >
                                    {imageFile
                                        ? imageFile.name
                                        : ' 이미지를 첨부하세요'}
                                </Button>
                            </label>
                            {
                                formData?.files != null ? <img src={formData?.files?.url}/> : null
                            }

                        </GridItem>

                        {/*<GridItem xs={2} sm={2} md={2}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={formData.alarmType === 'TEXT'}
                                        onChange={() => handleFormValue('TEXT', "alarmType")}
                                        value="true"
                                        name="radio button enabled"
                                        aria-label="T"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="텍스트"
                            />
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={formData.alarmType == 'IMAGE'}
                                        onChange={() => handleFormValue('IMAGE', "alarmType")}
                                        value="false"
                                        name="radio button enabled"
                                        aria-label="F"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="이미지"
                            />
                        </GridItem>*/}


                    </GridContainer>

                    <GridContainer className={classes.grid} alignItems='center'>
                        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                            알림 내용
                        </GridItem>

                        {formData.alarmType === 'TEXT' ? (
                            <GridItem xs={5} sm={5} md={5}>
                                <Box className='notice-detail'>
                                    <TextareaAutosize
                                        // className={classes.contentsStyle}
                                        minRows={5}
                                        maxRows={9}
                                        aria-label='maximum height'
                                        placeholder='내용을 입력해주세요'
                                        value={formData?.contents}
                                        // onChange={handleFormValue(, 'sendTime')}
                                        onChange={(e) =>
                                            handleFormValue(e.target.value, 'contents', formData, setFormData)
                                        }
                                        name='content'
                                        style={{width: '100%', height: '50px'}}
                                    />
                                </Box>
                            </GridItem>
                        ) : null}


                        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                            발송 시간
                        </GridItem>
                        <GridItem
                            xs={2}
                            sm={2}
                            // className={classes.styleDatePicker}
                        >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant='inline'
                                    TextFieldComponent={TextField}
                                    format='yyyy-MM-dd'
                                    id='date-picker-inline1'
                                    value={formData?.startAt}
                                    onChange={(date) =>
                                        handleFormValue(date, 'startAt', formData, setFormData)
                                    }
                                    autoOk={true}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </GridItem>
                        <GridItem
                            xs={1}
                            sm={1}
                            // className={classes.styleDatePicker}
                        >

                            <Box>
                                <TimePicker
                                    time={formData?.fromTime}
                                    handleChangeTimePicker={handleChangeTimePickerStart}
                                />
                            </Box>
                        </GridItem>
                        <GridItem
                            xs={1}
                            sm={1}
                            // className={classes.styleDatePicker}
                        >

                            <Box>
                                <TimePickerMin
                                    time={formData?.fromMin}
                                    handleChangeTimePicker={(e) => {
                                        handleFormValue(e.target.value, 'fromMin', formData, setFormData)
                                    }}
                                />
                            </Box>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.grid} alignItems='center'
                                   style={{marginTop: '10px', marginBottom: '10px'}}>
                    </GridContainer>
                    <GridContainer className={classes.grid} alignItems='center' style={{marginBottom: '10px'}}>
                        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                            연결 타입
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            {/*{formData.type}*/}
                            <TextField
                                id='user-managing-select'
                                select
                                size='small'
                                className={classes.widthTextFieldModal}
                                value={formData.type}
                                onChange={(e) => {
                                    handleFormValue(e.target.value, 'type', formData, setFormData)
                                }}
                                label='연결 타입을 선택해주세요'
                                style={{width: '100%'}}
                            >
                                <MenuItem value={'WEB_VIEW'}>내부 URL</MenuItem>
                                <MenuItem value={'URL'}>외부 URL</MenuItem>
                                <MenuItem value={'EVENT'}>이벤트</MenuItem>
                                <MenuItem value={'CHALLENGE'}>챌린지</MenuItem>
                                <MenuItem value={'FEED'}>게시물</MenuItem>
                            </TextField>
                        </GridItem>

                        <GridItem xs={3} sm={3} md={3}>
                            {formData.type == 'URL' || formData.type == 'WEB_VIEW' ? (
                                <CustomTextField
                                    id='outlined-basic'
                                    value={formData?.linkUrl}
                                    name='linkUrl'
                                    onChange={(e) => handleFormValue(e.target.value, 'linkUrl', formData, setFormData)}
                                    label='URL을 입력하세요'
                                    className={classes.linkTypeInput}
                                    style={{width: '100%'}}
                                />
                            ) : null
                            }
                            {formData.type == 'EVENT' ?
                                (
                                    // 이벤트
                                    <TextField
                                        id='user-managing-select'
                                        select
                                        size='small'
                                        className={classes.widthTextFieldModal}
                                        value={formData.eventsId}
                                        onChange={(e) => {
                                            handleFormValue(e.target.value, 'eventsId', formData, setFormData);
                                        }}
                                        label='이벤트를 선택해주세요(종료된 지 60일이후 이벤트 제외)'
                                        style={{width: '100%'}}
                                    >
                                        {
                                            events.map((event, i) => (
                                                <MenuItem key={event.id} title={event.title}
                                                          value={event.id}>{event.title}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                ) : null
                            }
                            {formData.type == 'CHALLENGE' ?
                                (
                                    //  챌린지
                                    <TextField
                                        id='user-managing-select'
                                        select
                                        size='small'
                                        className={classes.widthTextFieldModal}
                                        onChange={(e) => {
                                            handleFormValue(e.target.value, 'eventsId', formData, setFormData);
                                        }}
                                        value={formData.eventsId}
                                        label='챌린지를 선택해주세요'
                                        style={{width: '100%'}}
                                    >
                                        {
                                            events.map((event, i) => (
                                                <MenuItem key={event.id} title={event.title}
                                                          value={event.id}>{event.title}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                ) : null
                            }
                            {
                                formData?.type == 'FEED' && (
                                    <GridContainer className={classes.grid} alignItems='center'>
                                        <GridItem xs={3} sm={3} md={3} className={classes.lableSt}>
                                            피드 선택
                                            <CustomButton color="primary" mr={2} variant="contained"
                                                          onClick={showFeedModal}
                                            >검색</CustomButton>
                                        </GridItem>
                                        <GridItem xs={7} sm={7} md={7}>
                                            {posts.length > 0 ?
                                                posts.map((row, i) => (
                                                    <div key={row.id} onClick={() => delMemberList(i)}>
                                                        {row?.title}({row.owner?.nickname})
                                                        <img src={row?.thumbnailImage} alt="" width={100}/>
                                                        <a style={{color: 'red'}}>X</a></div>
                                                )) : null}
                                            {/*리스트로 표시한다음에 개별 리스트 삭제로 진행 할 예정*/}
                                        </GridItem>
                                    </GridContainer>
                                )
                            }


                        </GridItem>


                    </GridContainer>
                    <GridContainer className={classes.grid} alignItems='center'>
                        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                            알림 대상
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={formData.targetType === 'ALL'}
                                        onChange={() => handleFormValue('ALL', "targetType", formData, setFormData)}
                                        value={'ALL'}
                                        name="radio button enabled"
                                        aria-label="T"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="전체"
                            />
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={formData.targetType == 'PERSONAL'}
                                        onChange={() => handleFormValue('PERSONAL', "targetType", formData, setFormData)}
                                        value={'PERSONAL'}
                                        name="radio button enabled"
                                        aria-label="F"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="개인"
                            />
                            {formData.targetType == 'ALL' ? null : (
                                <CustomButton color="primary" mr={2} variant="contained"
                                              onClick={showUserModal}
                                >검색</CustomButton>
                            )

                            }
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={formData.targetType == 'PERSONAL'}
                                        onChange={() => handleFormValue('PERSONAL', "targetType", formData, setFormData)}
                                        value={'PERSONAL'}
                                        name="radio button enabled"
                                        aria-label="F"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="이벤트"
                            />
                            {formData.targetType == 'ALL' ? null : (
                                <CustomButton color="primary" mr={2} variant="contained"
                                              onClick={showUserModal}
                                >검색</CustomButton>
                            )

                            }
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={formData.targetType == 'PERSONAL'}
                                        onChange={() => handleFormValue('PERSONAL', "targetType", formData, setFormData)}
                                        value={'PERSONAL'}
                                        name="radio button enabled"
                                        aria-label="F"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="챌린지"
                            />
                            {formData.targetType == 'ALL' ? null : (
                                <CustomButton color="primary" mr={2} variant="contained"
                                              onClick={showUserModal}
                                >검색</CustomButton>
                            )

                            }
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            {member.length > 0 ?
                                member.map((row, i) => (
                                    <div key={row.id} onClick={() => delMemberList(i)}>{row.nickname}({row.clientId}) <a
                                        style={{color: 'red'}}>X</a></div>
                                )) : null}
                            {/*리스트로 표시한다음에 개별 리스트 삭제로 진행 할 예정*/}
                        </GridItem>

                    </GridContainer>
                    <GridContainer className={classes.grid} alignItems='center'>
                        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                        </GridItem>

                        <GridItem xs={4} sm={4} md={4}>
                            {/*이벤트, 챌린지 리스트 */}


                        </GridItem>
                    </GridContainer>
                </Paper>


                <Box className='add-list' display='flex' justifyContent='center'>


                    {(loadingBtn) ? (
                        <Spinner/>
                    ) : (
                        (formData.isSend) ? null : (<CustomButton color="primary" mr={2} variant="contained"
                                                                  onClick={createPush}
                        >저장</CustomButton>)
                    )}
                    <CustomButton color="transparent" mr={2} variant="contained"
                                  onClick={(event) => history.back()}
                    >취소</CustomButton>

                </Box>
            </div>)}


        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        events: state.eventsReducer.events,
        users: state.userManaging.users,
        metaData: state.userManaging.metaData,
        totalUser: state.userManaging.totalUser,
        totalUserBySearch: state.userManaging.totalUserBySearch,
        paginationUserManaging: state.userManaging.paginationUserManaging,
        // userDetail: state.push.pushDetail,
        // loading: state.pushReducer.loading,
        // pushDetail: state.pushReducer.pushDetail,
        // push: state.pushReducer.push,
        // metaData: state.pushReducer.metaData,
    }
}

export default connect(mapStateToProps, {
    getListEventsAction,
    requestUserManagingAction,
    getListUserAction,
})(PushDetail)
