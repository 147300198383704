import axiosInterceptors from 'api/axios'

const postInfoManagingApi = {

    createPostInfoManaging: (body) => {
        const url = '/postInfo/create'
        return axiosInterceptors.post(url, body)
    },
    getListPostInfoManaging: (params) => {
        const url = '/postInfo/getList'
        return axiosInterceptors.get(url, { params })
    },
    updatePostInfoManaging: (body) => {
        const url = '/postInfo/update'
        return axiosInterceptors.put(url, body)
    },


}

export default postInfoManagingApi;