import axiosInterceptors from 'api/axios'
import queryString from "query-string";

const contentManagingApi = {
    getList: (params) => {
        const url = '/contentManaging/getContentListNow'
        return axiosInterceptors.get(url, { params })
    },
    getContentsDetail: (params) => {
            const url = '/contentManaging/getContents'
        return axiosInterceptors.get(url, { params })
    },
    updateOrderContents: (params) => {
        const url = '/contentManaging/updateContentsOrder'
        return axiosInterceptors.put(url, { params })
    },
    updateWriterManagingOrder: (params) => {
        const url = '/contentManaging/updateWriterManagingOrder'
        return axiosInterceptors.put(url, { params })
    },
    deleteContent: (params) => {
        const url = '/contentManaging/deleteContent'
        return axiosInterceptors.delete(url, { params })
    },
    getMultiList: (params)=>{
        const url = '/contentManaging/getMultiMainList'
        return axiosInterceptors.get(url, { params })
    },
    getMultiOne: (params)=>{
        const url = '/contentManaging/getMultiMainByType'
        return axiosInterceptors.get(url, { params })
    },
    createMultiMain: (params)=>{
        const url = '/contentManaging/createMultiMain'
        return axiosInterceptors.post(url, { params })
    },
    updateMultiMain: (params) =>{
        const url = '/contentManaging/updateMultiMain'
        return axiosInterceptors.put(url, { params })

    },
    create: (params)=>{
        const url = '/contentManaging/setContent'
        return axiosInterceptors.post(url, { params })
    },
    update: (params)=>{
        const url = '/contentManaging/setContent'
        return axiosInterceptors.post(url, { params })
    }


}

export default contentManagingApi