import { primaryColor } from 'assets/jss/material-dashboard-pro-react.js'
import {blackColor} from "assets/jss/material-dashboard-pro-react";

const notice2Style = {
    // buttonProgress: {
    //     color: primaryColor[0],
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     marginTop: -12,
    //     marginLeft: -12,
    // },
    //
    // // * Notice Add
    // textareaAutosize: {
    //     width: '100%',
    // },
    grid: {
        margin: 0,
        marginTop: '10px',
        marginBottom: '10px',
        width: '100%',
    },
    lableSt:{
        margin: 'auto 0px'
    },
    lableSt2:{
        margin: 'auto 0px',
        paddingTop: '5px !important',
    },
    pt20:{
        paddingTop: '10px !important'
        // marginBottom: '10px !important',
        // marginTop: '10px !important',
    },
    gridItem:{
        textAlign: "right",
    },
    inputBtnUpload: {
        display: 'none',
    },
    paper: {
        paddingBottom: 25
    },
    label: {
      color: '#3c4858'
    },
    inputClass : {
        display: 'flex'
    },
    textUpperSet: {
        textTransform : 'unset'

    },
    tagInput: {
        paddingTop: '12px !important'
    },
    border:{
        margin: '20px',
        border: '1px solid #eeeeee'
    }

}


export default notice2Style
