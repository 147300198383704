import React from 'react'
import clsx from 'clsx'
import moment from 'moment'

import {lighten, makeStyles, useTheme} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import CustomButton from "../../../components/CustomButtons/Button";
import writerScheduleManagingApi from '../../../api/writerScheduleManagingApi'
import Checkbox from "@material-ui/core/Checkbox";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Switch from "../../Notice/components/Swtich";
import MenuSelect from "../../Notice/components/MenuSelect";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
// import GotoDetailPage from './GotoDetailPage'

const useStyles = makeStyles({
    table: {
        minWidth: 1000,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    groupBtnDropdown: {
        boxShadow: 'unset',
    },
    menuList: {
        '& li': {
            '& span': {
                paddingLeft: '0',
            },
        },
    },
    customBtnMI: {
        borderColor: '#222',
        color: '#222',
        '&:hover': {
            borderColor: 'unset',
            boxShadow: 'none',
        },
    },
    setZindexMenuList: {
        '& div:last-child': {
            zIndex: 9999,
        },
    },
})

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}))

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}))

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles()
    const { numSelected, handleDeletePost, loading } = props

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color='inherit'
                    variant='subtitle1'
                    component='div'
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant='h6'
                    id='tableTitle'
                    component='div'
                ></Typography>
            )}

            {/* <Tooltip title='Delete'>
        <Button
          disabled={loading}
          onClick={handleDeletePost}
          aria-label='delete'
          color='primary'
        >
          삭제하기
        </Button>
      </Tooltip> */}
        </Toolbar>
    )
}

const TablePaginationActions = (props) => {
    const classes = useStyles1()
    const theme = useTheme()
    const { count, page, rowsPerPage, onPageChange } = props

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0)
    }

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1)
    }

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1)
    }

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label='first page'
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label='previous page'
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='next page'
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='last page'
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    )
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, headCells } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell style={{ width: '50px' }}>No.</TableCell>
                {headCells.map((headCell, i) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            minWidth: headCell.minWidth ? headCell.minWidth : 170,
                        }}
                    >
                        <TableSortLabel
                            // active={orderBy === headCell.id}
                            active={headCell.allowSortable}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            // onClick={createSortHandler(headCell.id)}
                            onClick={
                                headCell.allowSortable
                                    ? createSortHandler(headCell.id)
                                    : undefined
                            }
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell style={{ width: '120px' }}></TableCell>
            </TableRow>
        </TableHead>
    )
}

const useRowStyles = makeStyles({
    table: {
        minWidth: 900,
    },
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    styleBox: {
        backgroundColor: '#EDEDED',
    },
})

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

export default function BasicTable(props) {
    const classes = useStyles()

    const {
        rows,
        headCells,
        sortable,
        totalRecords,
        setRowsPerPage,
        rowsPerPage,
        setPage,
        page,
        order,
        setOrder,
        setOrderBy,
        orderBy,
        getListWriterSchedules,
    } = props
    const Row = (props) => {
        const { row, index, page, selected, setSelected } = props
        const classes = useRowStyles()

        const [open, setOpen] = React.useState(false)

        const handleClick = (event, id) => {
            const selectedIndex = selected.indexOf(id)
            let newSelected = []

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id)
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1))
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1))
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                )
            }

            setSelected(newSelected)
        }


        const isSelected = (id) => selected.indexOf(id) !== -1
        const isItemSelected = isSelected(row?.id)
        const labelId = `enhanced-table-checkbox-${index}`

        const number = page === 0 ? index + 1 : index + 1 + parseInt(`${page}0`)

        return (
            <React.Fragment>
                <TableRow hover={true} className={classes.root}>
                    {/*<TableCell padding='checkbox'>
                        <Checkbox
                            onClick={(event) => handleClick(event, row?.id)}
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                    </TableCell>*/}
                    <TableCell align='left'>
                        <a onClick={(e)=>{
                            moveDetail(row.id)}
                        }>{row.id}</a>
                    </TableCell>
                    <TableCell>
                        <IconButton
                            aria-label='expand row'
                            size='small'
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>

                        <a onClick={(e)=>{
                            moveDetail(row.id)}
                        }> {row.nickname}</a>
                    </TableCell>
                    <TableCell align='right'>
                        <img style={{'maxWidth': '100px'}} src={row.avatar} alt=""/>
                    </TableCell>
                    <TableCell align='right'>{row.category}</TableCell>
                    <TableCell align='right'>{row.title}</TableCell>
                    <TableCell align='right'>{row.linkUrl}</TableCell>
                    <TableCell align='right'>
                        {moment(row?.startedAt).format('YYYY/MM/DD')}
                    </TableCell>
                    <TableCell align='right'>
                        {moment(row?.endedAt).format('YYYY/MM/DD')}
                    </TableCell>
                    <TableCell align='right'>
                        {moment(row?.createdAt).format('YYYY/MM/DD')}
                    </TableCell>
                    <TableCell align='right'>
                        <CustomButton color="primary" mr={2} onClick={(event) => handleDeleteInfo(event, row.id)} variant="contained">삭제</CustomButton>
                        </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={open} timeout='auto' unmountOnExit>
                            <Box m={1} p={1} className={classes.styleBox} component={Paper}>
                                <Typography gutterBottom component='p'>
                                    {/*{row.contentBr}*/}
                                    <p dangerouslySetInnerHTML={ {__html: row.content.replace(/\n/g, "<br/>")} }>
                                    </p>

                                </Typography>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )
    }

    const moveDetail = (id) => {
        location.href = 'writer-schedules/detail/'+id
    }
    const [selected, setSelected] = React.useState([])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleDeleteInfo = async (e,id) => {
        await writerScheduleManagingApi.deleteWriterSchedule({ id })
        getListWriterSchedules()

    }

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        setSelected(newSelected)
    }

    const isSelected = (id) => selected.indexOf(id) !== -1

    return (
        <TableContainer component={Paper}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <Table className={classes.table} aria-label='simple table'>
                <EnhancedTableHead
                    sortable={sortable}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headCells}
                />

                <TableBody>
                    {rows.map((row, index) => {
                        const isItemSelected = isSelected(row?.id)
                        const labelId = `enhanced-table-checkbox-${index}`

                        const number =
                            page === 0 ? index + 1 : index + 1 + parseInt(`${page}0`)

                        return (
                            <Row
                                key={row.id}
                                row={row}
                                page={page}
                                setSelected={setSelected}
                                selected={selected}
                            />




                            /*<TableRow hover key={index}>
                                <TableCell align='left'>
                                    <a onClick={(e)=>{
                                        moveDetail(row.id)}
                                    }>{row.id}</a>
                                </TableCell>
                                <TableCell align='right' style={{'cursor': 'pointer'}} >
                                    <a onClick={(e)=>{
                                        moveDetail(row.id)}
                                    }> {row.nickname}</a>
                                    </TableCell>
                                <TableCell align='right'>
                                    <img style={{'maxWidth': '100px'}} src={row.avatar} alt=""/>
                                </TableCell>
                                <TableCell align='right'>{row.category}</TableCell>
                                <TableCell align='right'>{row.title}</TableCell>
                                <TableCell align='right'>{row.content}</TableCell>
                                <TableCell align='right'>{row.linkUrl}</TableCell>
                                <TableCell align='right'>
                                    {moment(row?.startedAt).format('YYYY/MM/DD')}
                                </TableCell>
                                <TableCell align='right'>
                                    {moment(row?.endedAt).format('YYYY/MM/DD')}
                                </TableCell>
                                <TableCell align='right'>
                                    {moment(row?.createdAt).format('YYYY/MM/DD')}
                                </TableCell>
                                <TableCell align='right'>
                                    <CustomButton color="primary" mr={2} onClick={(event) => handleDeleteInfo(event, row.id)} variant="contained">삭제</CustomButton>
                                    {/!*<CustomButton color="primary" mr={2} variant="contained" onClick={(event) => handleSelEdit(event,row)} >삭제</CustomButton>*!/}
                                </TableCell>
                            </TableRow>*/
                        )
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[
                                10,
                                30,
                                50,
                                100,
                                { label: 'All', value: totalRecords },
                            ]}
                            colSpan={10}
                            count={totalRecords ? totalRecords : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}
