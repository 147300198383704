import React, {useEffect} from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'

import { makeStyles } from '@material-ui/core/styles'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import TextField from 'components/Gm-TextField/TextField'
import Button from 'components/CustomButtons/Button'

import { connect } from 'react-redux'
import { editnoticeAction } from 'redux/actions/notice'
import noticeApi from 'api/noticeApi'
import stylesAlert from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import Switch from "@material-ui/core/Switch";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import TimePicker from "../../Statistics/components/TimePicker2";
import TimePickerMin from "../../Statistics/components/TimePickerMin";
import moment from "moment";

const useStylesModal = makeStyles(stylesAlert)

const ModalEditNotice = ({
  title,
  content,
    sendTime,
  hideAlert,
  editnoticeAction,
  id,
}) => {
  const d = new Date(sendTime)
  let tz = d.getTimezoneOffset() / 60
  tz = tz < 0 ? tz * -1 : tz
  const classesAlert = useStylesModal()
  const [formData, setFormData] = React.useState({
    title: title,
    content: content,
    startAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
    fromTime: 0,
    fromMin: 0,
  })
  const [isSetSendTime, setSendTime] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    //mounted
    document.querySelector('.sweet-alert').parentElement.style.zIndex = 1034;

    if (sendTime != null){
      setSendTime(true)

      setFormData({
        ...formData,
        ['startAt']: moment(sendTime).format('YYYY-MM-DD'),
        ['fromTime']: parseInt(moment(sendTime).format('HH')),
        ['fromMin']: parseInt(moment(sendTime).format('mm')),
      })


    }


  }, []);

  const dateTimeCheck = (date, time, min) => {
    let Min = "00";
    if (min <= 9){
      Min = min+"0";
    }else{
      Min = min
    }

    if (time <= 9) {
      return date + " " + time + ":"+Min
    } else {
      return date + " " + time + ":"+Min
    }



  }

    const callApiEditNotice = async () => {


      let body = {
        id,
        title: formData.title,
        content: formData.content,
      }
      if (isSetSendTime) {

        body.sendTime = new Date(moment(dateTimeCheck(formData.startAt, formData.fromTime, formData.fromMin)).format("YYYY-MM-DD HH:mm")).getTime();

      } else {
        body.sendTime = null
      }


      delete body.startAt
      delete body.fromMin
      delete body.fromTime

      try {
        setLoading(true)
        await noticeApi.editNotice(body)
        editnoticeAction(body)
        setLoading(false)
        hideAlert()
      } catch (error) {
        setLoading(false)
        hideAlert()
      }
    }
    const handleSnedTime = () => {
      setSendTime(!isSetSendTime)
    }


    const handleForm = (value, key) => {
      //vue의 데이터 매핑 형태였던 것 같은데.... key도 매핑되네 히야...

      switch (key) {
        case 'startAt' :
          setFormData({
            ...formData,
            [key]: moment(value).format('YYYY-MM-DD'),
          })
          break;
        case 'endAt' :
          setFormData({
            ...formData,
            [key]: moment(value).format('YYYY-MM-DD'),
          })
          break;
        case 'type' :
          setFormData({
            ...formData,
            [key]: value,
          })
          break;
        default:
          setFormData({
            ...formData,
            [key]: value,
          })

      }
    }

    const handleChangeTimePickerStart = (event) => {
      setFormData({...formData, fromTime: event.target.value})
    }
    return (
        <SweetAlert
            style={{display: 'block', marginTop: '-100px', width: '90em',}}
            onConfirm={() => {
            }}
            onCancel={hideAlert}
            title=''
            showConfirm={false}
            confirmBtnCssClass={classesAlert.button + ' ' + classesAlert.success}
        >
          <GridContainer>
            <GridItem
                container
                justifyContent='flex-start'
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
            >
              <TextField
                  id='edit-notice'
                  label='Title'
                  name='title'
                  onChange={handleChange}
                  value={formData.title}
              />
            </GridItem>

            <GridItem
                container
                justifyContent='flex-start'
                xs={9}
                sm={9}
                md={9}
                lg={9}
                xl={9}
            >
              <Switch
                  checked={isSetSendTime}
                  onChange={handleSnedTime}
                  // disabled={loading}
                  name='checkedA'
                  inputProps={{'aria-label': 'secondary checkbox'}}
              />


              {
                isSetSendTime ? (

                    <React.Fragment>
                      <GridItem xs={2} sm={2} md={2} style={{'marginLeft': '20px'}}>
                        발송 시간
                      </GridItem>

                      <GridItem
                          xs={3}
                          sm={3}
                          // className={classes.styleDatePicker}
                      >


                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                              variant='inline'
                              TextFieldComponent={TextField}
                              format='yyyy-MM-dd'
                              id='date-picker-inline1'
                              value={formData?.startAt}
                              // className={classes.inputT}
                              // style={{height: '40px'}}
                              onChange={(date) =>
                                  handleForm(date, 'startAt')
                              }
                              autoOk={true}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                          />
                        </MuiPickersUtilsProvider>

                      </GridItem>

                      <GridItem
                          xs={2}
                          sm={2}
                          // className={classes.styleDatePicker}
                      >

                        <Box>
                          <TimePicker
                              time={formData?.fromTime}
                              handleChangeTimePicker={handleChangeTimePickerStart}
                          />
                        </Box>
                      </GridItem>
                      <GridItem
                          xs={2}
                          sm={2}
                          // className={classes.styleDatePicker}
                      >

                        <Box>
                          <TimePickerMin
                              time={formData?.fromMin}
                              handleChangeTimePicker={(e) => {
                                handleForm(e.target.value, 'fromMin')
                              }}
                          />
                        </Box>
                      </GridItem>
                    </React.Fragment>
                ) : null
              }


            </GridItem>


            <GridItem
                container
                justifyContent='flex-start'
                style={{marginTop: '16px'}}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
            >
              <TextareaAutosize
                  aria-label='minimum height'
                  minRows={3}
                  maxRows={5}
                  style={{width: '100%', minHeight: '400px', maxHeight: '700px'}}
                  name='content'
                  value={formData.content}
                  onChange={handleChange}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                  onClick={callApiEditNotice}
                  disabled={loading}
                  color='primary'
              >
                수정하기
              </Button>
            </GridItem>
          </GridContainer>
        </SweetAlert>
    )
  }

export default connect(null, { editnoticeAction })(ModalEditNotice)
