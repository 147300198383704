import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
import cx from 'classnames'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
// material-ui icons
import Menu from '@material-ui/icons/Menu'

// core components
import AdminNavbarLinks from './AdminNavbarLinks'
import Button from 'components/CustomButtons/Button.js'

import { useDispatch, useSelector } from 'react-redux'
import { setIsCheckedAction } from 'redux/actions/notice'

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js'

const useStyles = makeStyles(styles)

export default function AdminNavbar(props) {
  const classes = useStyles()
  const { color, rtlActive, brandText, history } = props
  const {
    location: { pathname },
  } = history

  const { isCheckedInNoticeAdd } = useSelector((state) => state.notice)
  const dispatch = useDispatch()

  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  })

  const sidebarMinimize =
    classes.sidebarMinimize +
    ' ' +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    })

  const renderbrandText = (param) => {
    if (param.indexOf('admin/post-list/1') > -1 ){
      return '게시물 관리'
    }
    if (param.indexOf('admin/post-list/2') > -1 ){
      return '꾸밍톡 관리'
    }
    if (param.indexOf('admin/post-talk') > -1 ){
      return '꾸밍톡'
    }
    if (param.indexOf('admin/post-issue') > -1 ){
      return '이슈뉴스'
    }
    if (param.indexOf('admin/post-detail') > -1 ){
      return '게시글 상세'
    }
    if (param.indexOf('/admin/event-managing/detail/CHALLENGE/') > -1 ){
      return '챌린지 수정'
    }
    if (param.indexOf('/admin/event-managing/detail/EVENT/') > -1 ){
      return '이벤트 수정'
    }
    if (param.indexOf('/admin/event-managing/view/EVENT/') > -1 ){
      return '이벤트 상세 조회'
    }
    if (param.indexOf('/content-managing/SHOP_CONTENT/detail') > -1 ){
      return '유저 꾸미기 연계영역-등록'
    }

    if (param.indexOf('/writer-schedules/detail') > -1 ){
      return '작가 일정 등록/수정'
    }
    if (param.indexOf('/writer-main-managing/detail') > -1 ){
      return '작가 메인 등록/수정'
    }


    switch (param) {
      case '/admin/post-detail':
        return '게시물 관리'
      case '/admin/user-detail':
        return '회원 관리'
      case '/admin/report-block-detail':
        return '신고/차단 관리'
      case '/admin/notice-add':
        return '공지 게시글'
      case '/admin/event-managing/EVENT':
        return '이벤트 리스트'
      case '/admin/event-managing/CHANLLENGE':
        return '챌린지 리스트'
      case '/admin/event-managing/detail/EVENT':
        return '이벤트 등록'
      case '/admin/event-managing/view/EVENT':
        return '이벤트 상세조회'
      case '/admin/event-managing/detail/CHALLENGE':
        return '챌린지 등록'
      case '/admin/push-managing/detail':
        return '푸시 등록'
      case '/admin/content-managing/SHOP_CONTENT':
        return '하단 컨텐츠 관리- 유저 꾸미기 연계영역'
      default:
        return brandText
    }


  }

  const handleChangeChecked = (event) => {
    dispatch(setIsCheckedAction(event.target.checked))
  }

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button href='#' className={classes.title} color='transparent'>
            {renderbrandText(pathname)}
          </Button>

          {pathname === '/admin/notice-add' && (
            <FormControlLabel
              value='top'
              control={
                <Switch
                  checked={isCheckedInNoticeAdd}
                  onChange={handleChangeChecked}
                />
              }
              label='공개 여부'
              labelPlacement='start'
            />
          )}
        </div>
        <Hidden smDown implementation='css'>
          <AdminNavbarLinks rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation='css'>
          <Button
            className={classes.appResponsive}
            color='transparent'
            justIcon
            aria-label='open drawer'
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
}
