import React from 'react'
import moment from 'moment'
import SweetAlert from 'react-bootstrap-sweetalert'

import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Switch from '@material-ui/core/Switch'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Chip from '@material-ui/core/Chip'
import Table from './components/PostDetail/CollapsibleTable'
import CollapsibleTableProductRequest from './components/PostDetail/CollapsibleTableProductRequest'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined'
import IconButton from '@material-ui/core/IconButton'
import Spinner from './components/PostDetail/SpinerForPostDetail'
import Menu from '@material-ui/core/Menu'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import MenuItem from '@material-ui/core/MenuItem'
import ShowAlert from './components/PostDetail/ShowAlert'
import Pagination from '@material-ui/lab/Pagination'
import {createTheme, ThemeProvider, useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import {connect} from 'react-redux'
import {
    requestPostManagingAction,
    getPostDetailAction,
    postManagingErrAction,
    deletePostAction,
    toggleRecommendPostAction,
    getListCommentInPostAction,
} from 'redux/actions/postManaging'
import postManagingApi from 'api/postManagingApi'

import {Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, {Navigation} from 'swiper'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'

SwiperCore.use([Navigation])

import stylesAlert from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'

const useStylesModal = makeStyles(stylesAlert)
import styles from 'assets/jss/material-dashboard-pro-react/views/PostManaging/postManaging'
import {capitalize} from 'lodash'
import CustomButton from "../../components/CustomButtons/Button";

const useStyles = makeStyles(styles)

const PostDetail = ({
                        requestPostManagingAction,
                        getPostDetailAction,
                        postManagingErrAction,
                        deletePostAction,
                        postDetail,
                        toggleRecommendPostAction,
                        getListCommentInPostAction,
                        listCommentOfPosts,
                        requestProducts,
                        location: {
                            // state: {postId},
                        },
                        loading,
                        history,
                        match,
                        metadataForPostDetail: {totalPages},
                    }) => {
    const classes = useStyles()
    const classesAlert = useStylesModal()

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))
    const themePagination = createTheme()
    const [postId, setPostId] = React.useState(
        isNaN(match?.params?.id) ? 0 : match?.params?.id
    )

    const [pagePagination, setPagePagination] = React.useState(1)
    const [
        isPreventCallApiGetListComment,
        setIsPreventCallApiGetListComment,
    ] = React.useState(false)
    const [alert, setAlert] = React.useState(null)
    const [modal, setModal] = React.useState(null)
    const [loadingSpinner, setLoadingSpinner] = React.useState(true)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const kFormatter = (num) => {
        return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
            : Math.sign(num) * Math.abs(num)
    }

    const handleChangeSwitch = async (postId, type, value ) => {
        try {
            requestPostManagingAction()
            const data = await postManagingApi.toggleContentManaging({type: type, toggle: value, postId: postId})
            toggleRecommendPostAction(data.data)
        } catch (error) {
            console.log(error.response)
            if (error && error.response && error.response.data) {
                postManagingErrAction(error.response.data)
            }
        }
    }

    const handleChangeSwitch2 = async (event) => {
        try {
            requestPostManagingAction()
            const {
                data: {isNotiPost},
            } = await postManagingApi.toggleisNotiPost({postId})
            toggleRecommendPostAction({isNotiPost, postId})
        } catch (error) {
            console.log(error.response)
            if (error && error.response && error.response.data) {
                postManagingErrAction(error.response.data)
            }
        }
    }

    // For mortvert block one
    const handleClickMenuSelect = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenuSelect = () => {
        setAnchorEl(null)
    }

    // Alert
    const showAlert = () => {
        setAlert(
            <ShowAlert
                hideAlert={hideAlert}
                requestPostManagingAction={requestPostManagingAction}
                deletePostAction={deletePostAction}
                postManagingErrAction={postManagingErrAction}
                postId={postId}
                history={history}
                postManagingApi={postManagingApi}
            />,
        )
    }

    const showAlert2 = (message) => {
        setModal(
            <SweetAlert
                warning
                style={{display: 'block', marginTop: '-100px'}}
                title=''
                onConfirm={hideModal}
                onCancel={hideModal}
                confirmBtnCssClass={classesAlert.button + ' ' + classesAlert.success}
                cancelBtnCssClass={classesAlert.button + ' ' + classesAlert.danger}
                confirmBtnText='OK'
            >
                <Typography component='p'>{message}</Typography>
            </SweetAlert>,
        )
    }

    const hideModal = () => {
        setModal(null)
        history.goBack()
    }

    const hideAlert = () => {
        setAlert(null)
    }
    const styleFilter = (value)=>{

        switch (value){
            case 'KITCH':
                return '키치'
            case 'LOVELY_CUTE':
                return '러블리큐트'
            case 'CHIC':
                return '시크'
            case 'EMOTION':
                return '감정'
            case 'DREAM':
                return '몽환'
            case 'VINTAGE':
                return '빈티지'
            case 'NATURAL':
                return '자연'
            case 'TYPO':
                return '타이포'
            case 'SIMPLE':
                return '심플'
            case 'ANIMAL':
                return '동물'
        }


    }

    React.useEffect(() => {
        const getPostDetailAndListComment = async () => {
            try {
                requestPostManagingAction()
                // just call at first page load, when user select pagination -> this api not call
                if (!isPreventCallApiGetListComment) {
                    const {data} = await postManagingApi.getPostDetail({postId})
                    getPostDetailAction(data)
                    setLoadingSpinner(false)
                    setIsPreventCallApiGetListComment(!isPreventCallApiGetListComment)
                }

                // get list comment
                const params = {
                    postId,
                    limit: 10,
                    offset: pagePagination,
                    order: 'DESC',
                }

                const dataComments = await postManagingApi.getListCommentsOfPost(params)
                getListCommentInPostAction(dataComments.data)
            } catch (error) {
                console.log(error.response)
                showAlert2(error?.response?.data?.data?.error)
                setLoadingSpinner(false)
                if (error && error.response && error.response.data) {
                    postManagingErrAction(error.response.data)
                }
            }
        }

        getPostDetailAndListComment()
    }, [pagePagination])

    // handle problem for first load
    if (!loadingSpinner && postDetail !== null) {
        var {
            owner: {memberID, nickname},
            createdAt,
            hashTags,
            styleTag,
            description,
            album,
            totalLikes,
            totalScraps,
            totalViews,
            postType,
            postInfoType,
            postSubGoods,
            writerTag,
            voteQuestions,
            isKkommingTalk,
            totalApplyCount,
            isNotiPost,
            isStar,
            isFeed,
            isOnSale,
            id
        } = postDetail

    }

    return (
        <>
            {modal}
            {postDetail === null ? null : (
                <>
                    {loadingSpinner ? (
                        <Spinner/>
                    ) : (
                        <div className={'post-detail'} >
                            {alert}
                            <Paper className={[classes.paper,
                                postInfoType == 'FEED' && classes.feedColor,
                                postInfoType == 'VOTE' && classes.voteColor,
                                postInfoType == 'ETC' && classes.etcColor,
                                postInfoType == 'STAR' && classes.starColor,
                                postInfoType == 'ISSUE' && classes.issueColor,
                                postInfoType == 'POST_GOODS' && classes.goodsColor
                            ]} variant='outlined'>
                                <GridContainer alignItems='center'>
                                    <GridItem xs={12} sm={4} md={4} lg={3} xl={3}>
                                        {postInfoType == 'FEED' ?
                                            <Chip  key={'FEED'} label={'피드'}/> : null}
                                        {postInfoType == 'VOTE' ?
                                            <Chip  classes={classes.voteColor} key={'VOTE'} label={'투표문답'}/> : null}
                                        {postInfoType == 'VOTE' ?
                                            <label style={{'margin-left': '5px'}}>
                                                {totalApplyCount}참여중
                                            </label> : null}
                                        {postInfoType == 'ETC' ?
                                            <Chip  classes={classes.etcColor} key={'ETC'} label={'자유잡담'}/> : null}

                                        {/*{postInfoType == 4 ? '꾸밍톡' : null}*/}
                                        {postInfoType == 'STAR' ?
                                            <Chip  classes={classes.starColor} key={'STAR'} label={'라이징스타'}/> : null}
                                        {postInfoType == 'ISSUE' ?
                                            <Chip classes={classes.issueColor} key={'ISSUE'} label={'이슈뉴스'}/> : null}
                                        {postInfoType == 'POST_GOODS' ?
                                            <Chip classes={classes.goodsColor} key={'POST_GOODS'} label={'굿즈'}/> : null}

                                        <p>
                                            <strong>ID: {memberID}</strong>&nbsp;&nbsp;&nbsp;
                                            <span>@{nickname}</span>
                                        </p>
                                    </GridItem>

                                    <GridItem
                                        container
                                        justifyContent='flex-end'
                                        className={classes.gridContainerOne}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={8}
                                        xl={8}
                                    >
                                        <p>업로드 일자</p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <p>{moment(createdAt).format('YYYY/MM/DD hh:mmA')}</p>
                                    </GridItem>

                                    <GridItem xs={2} sm={2} md={2} lg={1} xl={1}>
                                        <IconButton
                                            aria-controls={`post-managing-delete`}
                                            aria-haspopup='true'
                                            onClick={handleClickMenuSelect}
                                        >
                                            <MoreVertIcon/>
                                        </IconButton>
                                        <Menu
                                            id={`post-managing-delete`}
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleCloseMenuSelect}
                                        >
                                            <MenuItem
                                                onClick={() => {
                                                    handleCloseMenuSelect()
                                                    showAlert()
                                                }}
                                            >
                                                삭제하기
                                            </MenuItem>
                                        </Menu>
                                    </GridItem>
                                </GridContainer>
                            </Paper>

                            <Paper className={classes.paper} variant='outlined'>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={8} xl={9}>

                                        일반태그 및 추천태그

                                        <div className={classes.postDetailTags}>
                                            {hashTags.map((tag, i) => {
                                                return <Chip key={i} label={tag}/>
                                            })}
                                        </div>


                                        {/*스타일태그*/}
                                        {styleTag ? (

                                            <div>

                                                <Box my={2}>
                                                    <Divider/>
                                                </Box>
                                                스타일 태그
                                                <div className={classes.postDetailTags}>
                                                    {styleTag?.map((tag, i) => {
                                                        return <Chip key={i} label={styleFilter(tag)}/>
                                                    })}
                                                </div>

                                            </div>
                                        ) : null}
                                        {/*작가태그*/}
                                        {writerTag ? (

                                            <div>

                                                <Box my={2}>
                                                    <Divider/>
                                                </Box>
                                                작가/브랜드 태그
                                                <div className={classes.postDetailTags}>
                                                    {writerTag?.map((tag, i) => {
                                                        return <Chip key={i} label={tag}/>
                                                    })}
                                                </div>

                                            </div>
                                        ) : null}

                                        <Box my={2}>
                                            <Divider/>
                                        </Box>

                                        <div className={classes.postDetailContentDiv}>
                                            <p>{description}</p>
                                        </div>
                                        <Divider/>

                                        {postInfoType == 'POST_GOODS' && (<div className={classes.postDetailContentDiv}>
                                            {isOnSale == true &&
                                                <Chip color={'info'} key={'true'} label={'판매중'}/>}
                                            {isOnSale == false &&
                                                <Chip color={'primary'} key={'true'} label={'판매예정'}/>}
                                        </div>)
                                        }
                                        {
                                            postSubGoods?.map((item, index)=>{
                                                return (
                                                    <div className={classes.subGoodsDiv}>
                                                        <div>상품명 {index+1} : {item.subGoodsName}</div>
                                                        <div><a href={item.subGoodsLink}>{item.subGoodsLinkUrl}</a> ({item.totalClick == null ? 0 : item.totalClick}명 클릭)</div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {/*<Box my={2}>
                                            <Paper
                                                className={`${classes.paperSwitch} ${classes.postDetailToggleBtn}`}
                                                variant='outlined'
                                            >
                                                <p>베스트 꾸미기 on/off</p>
                                                <Switch
                                                    checked={postType === 'RECOMMEND' ? true : false}
                                                    onChange={handleChangeSwitch}
                                                    disabled={loading}
                                                    name='checkedA'
                                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                                />
                                            </Paper>
                                        </Box>*/}

                                        {
                                            (postInfoType == 'FEED' || postInfoType == 'STAR') &&(  <Box my={2}>
                                            <Paper
                                                className={`${classes.paperSwitch} ${classes.postDetailToggleBtn}`}
                                                variant='outlined'
                                            >
                                                <p>인기 피드 on/off</p>
                                                <Switch
                                                    checked={isFeed}
                                                    onChange={(e)=>{
                                                        handleChangeSwitch(id,'FEED', !isFeed)}
                                                    }
                                                    disabled={loading}
                                                    name='checkedA'
                                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                                />
                                            </Paper>
                                        </Box>)}

                                        {/*<Box my={2}>
                                            <Paper
                                                className={`${classes.paperSwitch} ${classes.postDetailToggleBtn}`}
                                                variant='outlined'
                                            >
                                                <p>라이징스타 on/off</p>
                                                <Switch
                                                    checked={isStar}
                                                    onChange={(e)=>{
                                                        handleChangeSwitch(id,'STAR', !isStar)}
                                                    }
                                                    disabled={loading}
                                                    name='checkedA'
                                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                                />
                                            </Paper>
                                        </Box>*/}

                                        {/*{*/}
                                        {/*    isKkommingTalk &&*/}
                                        {/*    (<Box my={2}>*/}
                                        {/*        <Paper*/}
                                        {/*            className={`${classes.paperSwitch} ${classes.postDetailToggleBtn}`}*/}
                                        {/*            variant='outlined'*/}
                                        {/*        >*/}
                                        {/*            <p>알려드려요(꾸밍톡) on/off</p>*/}
                                        {/*            <Switch*/}
                                        {/*                checked={isNotiPost ? true : false}*/}
                                        {/*                onChange={handleChangeSwitch2}*/}
                                        {/*                disabled={loading}*/}
                                        {/*                name='checkedA'*/}
                                        {/*                inputProps={{'aria-label': 'secondary checkbox'}}*/}
                                        {/*            />*/}
                                        {/*        </Paper>*/}
                                        {/*    </Box>)*/}
                                        {/*}*/}


                                        {/*투표문답 질문*/}
                                        {voteQuestions?.map((item, i) => {

                                            return <Box my={2}>
                                                <Paper
                                                    className={`${classes.paperSwitch} ${classes.votePaper}`}
                                                    variant='outlined'
                                                >
                                                    {
                                                        item.files != null ? <div style={{'width':'20%', 'margin-right': '5%'}}>
                                                            <img style={{'width': '100%'}} src={item?.files?.url} alt="" />
                                                        </div> : null
                                                    }
                                                    {
                                                        <div style={{'width':'75%'}}>
                                                            <span style={{'display':'flex'}}>{item.questionTitle} </span>
                                                            <span>( {item.persent}% / {item.questionIsApplyCount}명 참여 )</span>
                                                        </div>
                                                    }

                                                </Paper>
                                            </Box>


                                        })}

                                    </GridItem>



                                    {postInfoType != 'VOTE' ?
                                        (
                                            <GridItem
                                                container
                                                justifyContent='center'
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={4}
                                                xl={3}
                                            >
                                                {album == 0 ? (

                                                    <div className={classes.swiperCustomStyle}>
                                                        <Card variant='outlined'>
                                                            <CardActionArea>
                                                                <CardMedia
                                                                    className='card-media-post-detail'
                                                                    title='Contemplative Reptile'
                                                                >
                                                                    <div className={classes.totalViewVote}>
                                                                        <p>
                                                                            <FavoriteBorderIcon
                                                                                className={classes.wrapIcon}
                                                                            />
                                                                            &nbsp;&nbsp;
                                                                            <span>{kFormatter(totalLikes)}</span>
                                                                        </p>
                                                                        <p>
                                                                            <BookmarkBorderOutlinedIcon
                                                                                className={classes.wrapIcon}
                                                                            />
                                                                            &nbsp;&nbsp;
                                                                            <span>{kFormatter(totalScraps)}</span>
                                                                        </p>
                                                                        <p>
                                                                            <VisibilityOutlinedIcon
                                                                                className={classes.wrapIcon}
                                                                            />
                                                                            &nbsp;&nbsp;
                                                                            <span>{kFormatter(totalViews)}</span>
                                                                        </p>
                                                                    </div>
                                                                </CardMedia>

                                                            </CardActionArea>
                                                        </Card>
                                                    </div>
                                                ) : (
                                                    <Swiper
                                                        id='main'
                                                        navigation
                                                        className={classes.swiperCustomStyle}
                                                        spaceBetween={0}
                                                        slidesPerView={1}
                                                    >
                                                        {album?.map((item, i) => {
                                                            return (
                                                                <SwiperSlide
                                                                    className={classes.swiper}
                                                                    key={`slide-${i}`}
                                                                >
                                                                    <Card variant='outlined'>
                                                                        <CardActionArea>
                                                                            <CardMedia
                                                                                className='card-media-post-detail'
                                                                                image={item}
                                                                                title='Contemplative Reptile'
                                                                            >
                                                                                <div className='total-view'>
                                                                                    <p>
                                                                                        <FavoriteBorderIcon
                                                                                            className={classes.wrapIcon}
                                                                                        />
                                                                                        &nbsp;&nbsp;
                                                                                        <span>{kFormatter(totalLikes)}</span>
                                                                                    </p>
                                                                                    <p>
                                                                                        <BookmarkBorderOutlinedIcon
                                                                                            className={classes.wrapIcon}
                                                                                        />
                                                                                        &nbsp;&nbsp;
                                                                                        <span>{kFormatter(totalScraps)}</span>
                                                                                    </p>
                                                                                    <p>
                                                                                        <VisibilityOutlinedIcon
                                                                                            className={classes.wrapIcon}
                                                                                        />
                                                                                        &nbsp;&nbsp;
                                                                                        <span>{kFormatter(totalViews)}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </CardMedia>

                                                                            <CardContent>
                                                                                <Typography
                                                                                    gutterBottom
                                                                                    variant='h5'
                                                                                    component='h2'
                                                                                >
                                                                                    {capitalize(nickname)}
                                                                                </Typography>
                                                                                <Typography
                                                                                    variant='body2'
                                                                                    color='textSecondary'
                                                                                    component='p'
                                                                                    gutterBottom
                                                                                >
                                                                                    {description}
                                                                                </Typography>
                                                                                <Typography
                                                                                    variant='body2'
                                                                                    color='textSecondary'
                                                                                    component='p'
                                                                                >
                                                                                    {moment(createdAt).format(
                                                                                        'YYYY/MM/DD hh:mmA',
                                                                                    )}
                                                                                </Typography>
                                                                            </CardContent>
                                                                        </CardActionArea>
                                                                    </Card>
                                                                </SwiperSlide>
                                                            )
                                                        })}
                                                    </Swiper>
                                                ) }


                                            </GridItem>
                                        ) :
                                        ( <GridItem
                                            container
                                            justifyContent='center'
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={4}
                                            xl={3}
                                        >
                                            {/*totalViewVote*/}
                                            <div className={classes.swiperCustomStyle}>
                                            <Card variant='outlined'>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className='card-media-post-detail'
                                                        title='Contemplative Reptile'
                                                    >
                                                        <div className={classes.totalViewVote}>
                                                            <p>
                                                                <FavoriteBorderIcon
                                                                    className={classes.wrapIcon}
                                                                />
                                                                &nbsp;&nbsp;
                                                                <span>{kFormatter(totalLikes)}</span>
                                                            </p>
                                                            <p>
                                                                <BookmarkBorderOutlinedIcon
                                                                    className={classes.wrapIcon}
                                                                />
                                                                &nbsp;&nbsp;
                                                                <span>{kFormatter(totalScraps)}</span>
                                                            </p>
                                                            <p>
                                                                <VisibilityOutlinedIcon
                                                                    className={classes.wrapIcon}
                                                                />
                                                                &nbsp;&nbsp;
                                                                <span>{kFormatter(totalViews)}</span>
                                                            </p>
                                                        </div>
                                                    </CardMedia>

                                                </CardActionArea>
                                            </Card>
                                            </div>
                                        </GridItem>)



                                    }
                                    {}




                                </GridContainer>
                            </Paper>

                            <Box mt={5}>
                                <CollapsibleTableProductRequest hover rows={requestProducts}/>
                            </Box>


                            <Box mt={5}>
                                <Table hover rows={listCommentOfPosts}/>
                            </Box>

                            <Box
                                mt={1}
                                display='flex'
                                justifyContent='flex-end'
                                className='pagiantion'
                            >
                                <ThemeProvider theme={themePagination}>
                                    <Pagination
                                        onChange={(e, value) => setPagePagination(value)}
                                        size={matches ? 'small' : 'large'}
                                        count={totalPages}
                                        showFirstButton
                                        showLastButton
                                    />
                                </ThemeProvider>
                            </Box>


                            <Box className='add-list' display='flex' justifyContent='center'>


                                {loading && isKkommingTalk  ? (
                                    <Spinner/>
                                ) : (
                                    <CustomButton color="primary" mr={2} variant="contained"
                                                  onClick={()=>{
                                                      if(postDetail.isKkommingTalk){
                                            location.href='/admin/post-talk/'+id
                                        }else{
                                            location.href='/admin/post-issue/'+id
                                        }
                                                  }
                                                  }
                                    >수정</CustomButton>)
                                }
                                {/*<CustomButton color="default" mr={2} variant="contained"*/}
                                {/*              onClick={(event) => history.back()}*/}
                                {/*>취소</CustomButton>*/}

                            </Box>




                        </div>
                    )}
                </>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    postDetail: state.postManaging.postDetail,
    loading: state.postManaging.loading,
    listCommentOfPosts: state.postManaging.listCommentOfPosts,
    metadataForPostDetail: state.postManaging.metadataForPostDetail,
    requestProducts: state.postManaging.requestProducts,
})

export default connect(mapStateToProps, {
    requestPostManagingAction,
    getPostDetailAction,
    postManagingErrAction,
    deletePostAction,
    toggleRecommendPostAction,
    getListCommentInPostAction,
})(PostDetail)
