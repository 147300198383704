import * as actionTypes from '../actions/types'
import {pl} from "date-fns/locale";

const initialState = {
	loading: false,
	goods: [],
	metaData: {
		totalPages: 1,
	},
	totalWriterGoods: 0,
	totalWriterGoodsBySearch: 0,
	paginationWriterManaging: 1,
	goodsManagingList: [],

	// user-detail
	writerDetail: null,
	error: null,
	listReportedInWriterDetail: [],
	metaDataForListReportedInWriterDetail: {
		totalPages: 1,
	},
}

export default function (state = initialState, action) {
	const { type, payload } = action

	switch (type) {
		case actionTypes.REQUEST_WRITER_GOODS_MANAGING:
			return {
				...state,
				loading: true,
			}
		case actionTypes.GET_LIST_WRITER_GOODS:
			const {
				goods,
				metaData,
				totalWriterGoods,
				totalWriterGoodsBySearch,
				goodsManagingList,
				hasClientIdData,
			} = payload

			const resetTotalUserBySearchToZeroIfHasClientIdEqualFalse = hasClientIdData
					? state.totalWriterGoodsBySearch
					: 0

			return {
				...state,
				loading: false,
				error: null,
				goods: goods,
				metaData,
				totalWriterGoods: totalWriterGoods !== null ? totalWriterGoods : state.totalWriterGoods,
				totalWriterGoodsBySearch:
						totalWriterGoodsBySearch !== null
								? totalWriterGoodsBySearch
								: resetTotalUserBySearchToZeroIfHasClientIdEqualFalse,
				goodsManagingList: goodsManagingList || [],
			}
		case actionTypes.SET_PAGINATION_WRITERGOODSMANAGING:
			return {
				...state,
				loading: false,
				error: null,
				paginationWriterManaging: payload,
			}
		case actionTypes.REQUEST_WRITER_GOODS_MANAGING_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			}
		case actionTypes.TOGGLE_WRITER_GOODS_IS_SYNC:
			let {goodsId, isSync} = payload
			return {
				...state,
				goods: state.goods.filter(item => {
					if (item.id == goodsId){
						item.isSync = isSync
						return item
					}else{
						return item
					}
				}),
				// listManagerManaging: state.listManagerManaging.filter(
				// (item) => item.id !== payload,
				// ),

			}
		case actionTypes.TOGGLE_WRITER_GOODS_IS_SHOW:
			let {goodId, isShow} = payload
			return {
				...state,
				goods: state.goods.filter(item => {
					if (item.id == goodId){
						item.isShow = isShow
						return item
					}else{
						return item
					}
				}),
				// listManagerManaging: state.listManagerManaging.filter(
				// (item) => item.id !== payload,
				// ),

			}
		default:
			return state
	}
}
