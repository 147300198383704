import * as actionTypes from '../actions/types'


const initialState = {
    loading: true,
    pushInfos: [],
    metaData: {
        totalPages: 1,
        totalRecords: 0,
    },
    error: null,
}


export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case actionTypes.GET_PUSH_INFO_LIST:
            const {pushInfoList, metaData} = payload
            return {
                ...state,
                loading: false,
                pushInfos: pushInfoList,
                metaData,
            }
        default:
            return state
    }
}