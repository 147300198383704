import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from 'components/CustomButtons/Button.js'
import Box from '@material-ui/core/Box'
import Table from './components/CollapsibleTable'
import CircularProgress from '@material-ui/core/CircularProgress'

import { connect } from 'react-redux'
import {
  requestNoticeAction,
  getListNoticesAction,
  noticesWithErrAction,
} from 'redux/actions/notice'
import noticeApi from 'api/noticeApi'

import styles from 'assets/jss/material-dashboard-pro-react/views/Notice/notice'
import GridItem from "../../components/Grid/GridItem";
import TextField from "../../components/Gm-TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import GridContainer from "../../components/Grid/GridContainer";
const useStyles = makeStyles(styles)

const Notice = (props) => {
  const classes = useStyles()

  const {
    loading,
    notices,
    metaData: { totalRecords },
    requestNoticeAction,
    getListNoticesAction,
    noticesWithErrAction,
  } = props

  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [limit, setLimit] = React.useState(10)
  const [orderBy, setOrderBy] = React.useState('id')
  const [order, setOrder] = React.useState('desc')
  const [searchText, setSearchText] = React.useState(null)

  const getListNotices = async () => {
    let params = {
      limit: limit,
      order: orderBy + ',' + order,
      offset: page + 1,
    }
    if (searchText != null){
      params.title = searchText
    }

    try {
      requestNoticeAction()
      const { data } = await noticeApi.getListNotices(params)
      getListNoticesAction(data)
    } catch (error) {
      if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.data
      ) {
        noticesWithErrAction(error.response.data)
      }
    }
  }

  React.useEffect(() => {
    getListNotices()
  }, [page + 1, limit, order, orderBy])

  return (
    <div className='notice'>

      <GridContainer alignItems='center'>
        <GridItem
            className={classes.gridContainerOne}
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
        >

          <TextField
              id='post-managing-textfield'
              size='small'
              className={classes.textFieldOne}
              placeholder={'공지사항 제목을 입력해주세요'}
              name='clientId'
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
              InputProps={{
                startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon/>
                    </InputAdornment>
                ),
              }}
          />


        </GridItem>
        <GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
          <Button
              color='primary'
              disabled={loading}
              onClick={(e)=>{
                setPage(0)
                getListNotices();
              }}
          >
            검색
          </Button>
        </GridItem>
      </GridContainer>
      <GridContainer alignItems='center'>
        <GridItem xs={6}>
          <Box my={2}>
            <TextField
                className={`${classes.textFieldTwo} ${classes.textFieldTwoChildOne}`}
                id='post-managing-textfield-show-info1'
                size='small'
                value={totalRecords}
                InputProps={{
                  startAdornment: (
                      <InputAdornment position='start'>전체</InputAdornment>
                  ),
                  readOnly: true,
                }}
            />
          </Box>
        </GridItem>

          <GridItem xs={4}>
          </GridItem>
          <GridItem xs={2} sm={2} md={2} lg={2} xl={2} >
          <Box display='flex' justifyContent='flex-end' className='add-notice'>
            <Button
                color='primary'
                onClick={() => props.history.push('/admin/notice-add')}
            >
              추가하기
            </Button>
          </Box>

        </GridItem>
      </GridContainer>



      <Box my={2} className='table'>
        {loading ? (
          <CircularProgress size={30} className={classes.buttonProgress} />
        ) : (
          <Table
            rows={notices}
            totalRecords={totalRecords}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            setLimit={setLimit}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
        )}
      </Box>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.notice.loading,
    notices: state.notice.notices,
    metaData: state.notice.metaData,
  }
}

export default connect(mapStateToProps, {
  requestNoticeAction,
  getListNoticesAction,
  noticesWithErrAction,
})(Notice)
