import axiosInterceptors from 'api/axios'

const writerSearchManagingApi = {

    getWriterSearchGoodsByPosts: (params) => {
        const url = '/goods/writerSearchManaging/getWriterSearchGoodsByPosts'
        return axiosInterceptors.get(url, {params})
    },
}

export default writerSearchManagingApi