import {connect, useDispatch} from "react-redux";
import Paper from "@material-ui/core/Paper";
import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/Notice/notice2style";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "../../components/Gm-TextField/TextFieldForDatePicker";
import Box from "@material-ui/core/Box";
import TimePicker from "../Statistics/components/TimePicker2";
import TimePickerMin from "../Statistics/components/TimePickerMin";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {FiberManualRecord} from "@material-ui/icons";
import Button from "../../components/CustomButtons/Button";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import CustomButton from "../../components/CustomButtons/Button";
import moment from "moment";
import Spinner from "../../components/Spinner/Spinner";
// import SplashModal from './components/SplashModal.js'
import TagsInput from 'react-tagsinput';
//자동제안은 react suggest

import stylesModal from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";


import bannerManagingApi from "../../api/mainManaging/bannerManagingApi";
import Checkbox from "@material-ui/core/Checkbox";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MenuItem from "@material-ui/core/MenuItem";
import CustomTextField from "../../components/Gm-TextField/TextField";
import EditBanner from "../MainManaging/components/EditBanner";
import {
    bannerManagingRequestErrorAction,
    editBannerManagingAction, getListBannerManagingAction
} from "../../redux/actions/mainManaging/bannerManagingAction.js";
import {getListSplashAction} from "../../redux/actions/splashManagingAction.js";
import userManagingApi from "../../api/userManagingApi.js";
import {getListUserAction, requestUserManagingAction} from "../../redux/actions/userManagingAction";
import userManaging from "../../redux/reducers/userManagingReducer";
import Table from "../UserManaging/components/Table";
import splashManagingApi from "../../api/splashManangingApi";
import {dateTimeCheck, handleFormValue} from "../../util/common";

const useStyles = makeStyles(styles)
const useStylesModal = makeStyles(stylesModal)

// 이벤트 상세임 아무튼 그럼
const SplashDetail = (props) => {

    const {
        match,
        splashDetail,
        events,
        users,
        metaData: {totalRecords}, // user에 대한
    } = props

    const classes = useStyles()
    const classesModal = useStylesModal()
    const dispatch = useDispatch()

    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz

    const [loading, setLoading] = React.useState(false)

    // const [tags, setTags] = React.useState([]);
    const [formData, setFormData] = React.useState({
        id: isNaN(match?.params?.id) ? 0 : match?.params?.id,
        title: splashDetail != null ? splashDetail.title : '',
        sendTime: '',
        startAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        endAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        fromTime: 0,
        toTime: 23,
        file: '',


    })
    const [imageFile, setImageFile] = React.useState(null)
    const [loadingBtn, setLoadingBtn] = React.useState(false)
    const [alert2, setAlert] = React.useState(null)
    const [member, setMember] = React.useState([])

    const handleChangeTimePickerEnd = (event) => {

        setFormData({...formData, toTime: event.target.value})
    }


    const createSplash = async () => {


        try {

            const fd = new FormData()



            let body = {
                id: formData.id,
                title: formData.title,
                startAt: new Date(moment(dateTimeCheck(formData.startAt, formData.fromTime)).format("YYYY-MM-DD HH:mm")).getTime(),
                endAt: new Date(moment(dateTimeCheck(formData.endAt, formData.toTime)).format("YYYY-MM-DD HH:mm")).getTime(),
                status : formData.status,

            }


            if (imageFile == null) {
                //파일이 없을떄, 기존 이미지 수정할떄
                if (formData.file != null && formData.file != '') {
                    body.fileId = formData.file.id
                }

            } else {
                //파일 새로 들어올떄
                setLoadingBtn(true)
                fd.append('file', imageFile, imageFile.name)
                fd.append('type', 'SPLASH')
                setLoadingBtn(true)
                const {
                    data: {id: idImage, url, filename},
                } = await bannerManagingApi.uploadImage(fd);
                body.fileId = idImage
                setLoadingBtn(false)
            }


            //업데이트 일때
            if (formData.id != 0) {


                const {data} = await splashManagingApi.update(body);

                if (data) {
                    alert("수정이 완료되었습니다.")
                    location.href = '/admin/splash-managing'
                }
                return
            }
            //하단은 Insert일떄


            const {data} = await splashManagingApi.create(body);

            setLoadingBtn(false)


            if (data.id != null) {
                alert("저장이 완료되었습니다.")
                location.href = '/admin/splash-managing'
            }


        } catch (e) {

        }


    }
    /*이벤트 조회*/
    const getListSplash = async (type) => {

        let orderString = '';

        //if (orderBy == 'first') {
        //처음 정렬시 현재 front에 나오는 표출 순서대로
        orderString = 'createdAt,DESC';
        // } else {
        //   orderString = orderBy + ',' + order
        // }


        let params = {
            limit: 10000,
            offset: 0,
            type: type,
            order: orderString,
            searchText: '',
            searchKey: 'title',
        }

        try {

            const {data} = await splashManagingApi.getListSplash(params)
            dispatch(getListSplashAction(data))

        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                // noticesWithErrAction(error.response.data)
                console.log(error.response.data)
            }
        }
    }


    //이벤트 챌린지 변할 시
    useEffect(() => {

        // if (formData?.type === 'EVENT') {
        getListSplash(formData?.type)
        // } else if (formData?.type === 'CHALLENGE') {
        //     getListSplash('CHALLENGE')
        // }

    }, [formData.type]);


    const handleChangeTimePickerStart = (event) => {

        setFormData({...formData, fromTime: event.target.value})
    }
    // const handleChangeTimePickerEnd = (event) => {
    //
    //     setFormData({...formData, toTime: event.target.value})
    // }
    const handleChangeFile = (e, type) => {
        //파일 Onchage 시 이미지 체인지
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            // const img = new Image()
            // img.src = window.URL.createObjectURL(file)
            // img.addEventListener('load', () => {
                setImageFile(file) // 일단 바로 체크
                // validateUploadImage(file, 'size') ||
                // (!validateUploadImage(img, 'widthAndHeight') && setImageFile(file))
            // })
        }
    }

    const hideAlert = () => {
        setAlert(null)


    }

    //회원 Modal
    // Modal
    const showUserModal = () => {


        // alert("Fdfd")
        // setSearchKey('ㅇㅇㅇㅇㅇ')
        //insert
        // setAlert(
        //     <SplashModal
        //         item={users}
        //         hideAlert={hideAlert}
        //         setAlert={setAlert}
        //         // getMemberList={getMemberList}
        //         userManagingApi={userManagingApi}
        //         dispatch={dispatch}
        //         setMember={setMember}
        //         member={member}
        //
        //         // setSearchKey={setSearchKey}
        //         // searchKey={searchKey}
        //         // getListUserAction={getListUserAction}
        //         // totalRecords={totalRecords}
        //         // setRowsPerPage={setRowsPerPage}
        //         // rowsPerPage={rowsPerPage}
        //         // setPage={setPage}
        //         // page={page}
        //         // searchText={searchText}
        //         // setSearchText={setSearchText}
        //
        //         // bannerManagingRequestErrorAction={
        //         //     bannerManagingRequestErrorAction
        //         // }
        //
        //
        //     />
        // )


    }

    const delMemberList = (i) => {
        let member2 = member.splice(0, i)
        if (member.splice(member.length).length > 0) {
            member2.splash(member.splice(member.length))
        }
        setMember(member2)
    }


    //modal에서 가져왔을때
    useEffect(() => {
    }, [member, formData.isSend]);


    //UPDATE일떄
    const getSplashData = async () => {
        try {

            setLoading(true)

            if (formData.id > 0) {
                //데이터가 있는 것

                //setLoading 필요

                const {data} = await splashManagingApi.getsplash({id: parseInt(formData.id)});
                //데이터 매핑 dispatch쓰면 코드가 깔끔해지나 쓰지 않음
                // dispatch

                //가져온데이터 다시 매핑
                setFormData({
                    id: isNaN(match?.params?.id) ? 0 : match?.params?.id, //이거 넣으면 useEffect의 파라미터때문에 무한루프
                    title: data.title,
                    status: data.status,
                    startAt: moment(data.startAt).format('YYYY-MM-DD'),
                    endAt: moment(data.endAt).format('YYYY-MM-DD'),
                    fromTime: parseInt(moment(data.startAt).format('HH')),
                    toTime: parseInt(moment(data.endAt).format('HH')),
                    file: data.file,
                    filesId: data.fileId,
                })


                setLoading(false)

            }
            setLoading(false)

        } catch (e) {

        }
    }

    React.useEffect(() => {
        //getData
        getSplashData()
    }, [formData.id])


    return (


        // <Paper  variant='outlined'>
        // <h3>이벤트 기간</h3>
        // </Pape>

        <div>
            {loading ? (<Spinner/>) : (<div>
                {alert2}

                <Paper variant='outlined' className={classes.paper}>
                    {/*1 제목*/}
                    <GridContainer className={classes.grid} alignItems='center'>
                        {/*<GridItem xs={1} sm={1} md={1}>푸시 제목</GridItem>*/}
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'스플래시 제목'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleFormValue(event.target.value, 'title', formData, setFormData)
                                    },
                                }}
                                value={formData.title}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />
                        </GridItem>
                        <GridItem xs={6}>

                        </GridItem>
                    </GridContainer>

                    {/*2 기간*/}
                    <GridContainer className={classes.grid}>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem className={classes.lableSt}
                                          xs={1} sm={1} md={1}
                                >
                                    노출 시작 기간
                                </GridItem>
                                <GridItem
                                    xs={2}
                                    sm={2}
                                    // className={classes.styleDatePicker}
                                >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            variant='inline'
                                            TextFieldComponent={TextField}
                                            format='yyyy-MM-dd'
                                            id='date-picker-inline1'
                                            value={formData?.startAt}
                                            onChange={(date) =>
                                                handleFormValue(date, 'startAt', formData, setFormData)
                                            }
                                            autoOk={true}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </GridItem>
                                <GridItem
                                    // className={classes.paddingLeft}
                                    xs={2}
                                    sm={2}
                                >
                                    <Box>
                                        <TimePicker
                                            time={formData?.fromTime}
                                            handleChangeTimePicker={handleChangeTimePickerStart}
                                        />
                                    </Box>
                                </GridItem>
                                {/*공백용*/}
                                {/*<GridItem xs={2} sm={2} md={2} />*/}
                                <GridItem className={classes.lableSt}
                                          xs={1} sm={1} md={1}
                                >
                                    노출 종료 기간
                                </GridItem>
                                <GridItem
                                    xs={2}
                                    sm={2}
                                    // className={classes.styleDatePicker}
                                >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            variant='inline'
                                            TextFieldComponent={TextField}
                                            format='yyyy-MM-dd'
                                            id='date-picker-inline1'
                                            value={formData?.endAt}
                                            onChange={(date) =>
                                                handleFormValue(date, 'endAt', formData, setFormData)
                                            }
                                            autoOk={true}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </GridItem>
                                <GridItem
                                    // className={classes.paddingLeft}
                                    xs={2}
                                    sm={2}
                                >
                                    <Box>
                                        <TimePicker
                                            time={formData?.toTime}
                                            handleChangeTimePicker={handleChangeTimePickerEnd}
                                            setKey='toTime'
                                        />
                                    </Box>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    {/*3 활성여부*/}
                    <GridContainer className={classes.grid}>
                        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                            노출여부
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={formData.status === 'ACTIVE'}
                                        onChange={() => handleFormValue('ACTIVE', "status", formData, setFormData)}
                                        value="true"
                                        name="radio button enabled"
                                        aria-label="T"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="노출(활성화)"
                            />
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={formData.status == 'DEACTIVATED'}
                                        onChange={() => handleFormValue('DEACTIVATED', "status", formData, setFormData)}
                                        value="false"
                                        name="radio button enabled"
                                        aria-label="F"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="비노출(비활성화)"
                            />
                        </GridItem>
                    </GridContainer>
                    {/*4 파일*/}
                    <GridContainer className={classes.grid} alignItems='center'>

                        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                            파일 (  JPG, PNG, GIF, MP4, JSON )
                        </GridItem>


                        <GridItem xs={5} sm={5} md={5}>
                            <input
                                accept='image/*,image/jpeg,image/png,video/mp4,image/gif,application/json'
                                className={classes.inputBtnUpload}
                                id='contained-button-file'
                                type='file'
                                onClick={(event => {
                                    event.target.value = ''
                                })}
                                onChange={(e) => {
                                    handleChangeFile(e, 1)
                                }}
                            />
                            <label htmlFor='contained-button-file'>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    component='span'
                                    fullWidth={true}
                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                >
                                    {imageFile
                                        ? imageFile.name
                                        : ' 파일을 첨부하세요'}
                                </Button>
                            </label>
                            {
                                formData?.file != null ? <img src={formData?.file?.url}/> : null
                            }

                        </GridItem>
                        {/*<GridItem xs={7} sm={7} md={7}>
                            <img src="" alt=""/>
                        </GridItem>*/}

                        {/*<GridItem xs={2} sm={2} md={2}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={formData.alarmType === 'TEXT'}
                                        onChange={() => handleFormValue('TEXT', "alarmType")}
                                        value="true"
                                        name="radio button enabled"
                                        aria-label="T"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="텍스트"
                            />
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={formData.alarmType == 'IMAGE'}
                                        onChange={() => handleForm('IMAGE', "alarmType")}
                                        value="false"
                                        name="radio button enabled"
                                        aria-label="F"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="이미지"
                            />
                        </GridItem>*/}


                    </GridContainer>

                </Paper>


                <Box className='add-list' display='flex' justifyContent='center'>


                    {(loadingBtn) ? (
                        <Spinner/>
                    ) : (
                        (formData.isSend) ? null : (<CustomButton color="primary" mr={2} variant="contained"
                                                                  onClick={createSplash}
                        >저장</CustomButton>)
                    )}
                    <CustomButton color="transparent" mr={2} variant="contained"
                                  onClick={(event) => history.back()}
                    >취소</CustomButton>

                </Box>
            </div>)}


        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        events: state.eventsReducer.events,
        users: state.userManaging.users,
        metaData: state.userManaging.metaData,
        totalUser: state.userManaging.totalUser,
        realTotalUser: state.userManaging.realTotalUser,
        totalUserBySearch: state.userManaging.totalUserBySearch,
        paginationUserManaging: state.userManaging.paginationUserManaging,
        // userDetail: state.splash.splashDetail,
        // loading: state.splashReducer.loading,
        // splashDetail: state.splashReducer.splashDetail,
        // splash: state.splashReducer.splash,
        // metaData: state.splashReducer.metaData,
    }
}

export default connect(mapStateToProps, {
    getListSplashAction,
    requestUserManagingAction,
    getListUserAction,
})(SplashDetail)
