import {connect, useDispatch} from "react-redux";
import Paper from "@material-ui/core/Paper";
import React, {useEffect} from "react";
import {createTheme, makeStyles, ThemeProvider, useTheme} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/Notice/notice2style";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "../../components/Gm-TextField/TextFieldForDatePicker";
import Box from "@material-ui/core/Box";
import TimePicker from "../Statistics/components/TimePicker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {FiberManualRecord} from "@material-ui/icons";
import Button from "../../components/CustomButtons/Button";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import CustomButton from "../../components/CustomButtons/Button";
import moment from "moment";
import Spinner from "../../components/Spinner/Spinner";
import eventsManagingApi from "../../api/eventsManagingApi";
import CommentTable from "./components/CollapsibleTableEventComment"


import TagsInput from 'react-tagsinput';
//자동제안은 react suggest

import stylesModal from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";


import bannerManagingApi from "../../api/mainManaging/bannerManagingApi";
import Checkbox from "@material-ui/core/Checkbox";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import VoteQuestion from "../../components/VoteQuestion/VoteQuestion";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Chip from "@material-ui/core/Chip";
import reportBlockManagingApi from "../../api/reportBlockManagingApi";
import fileDownload from "js-file-download";
import {reportBlockManagingRequestWithError} from "../../redux/actions/reportBlockManagingAction";
import Table from "../PostManaging/components/PostDetail/CollapsibleTable";
import {getEventsAction, getListCommentsAction, getListEventsAction} from "../../redux/actions/eventsManagingAction";
import Pagination from "@material-ui/lab/Pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import postManagingApi from "../../api/postManagingApi";
import {dateTimeCheck} from "../../util/common";

const useStyles = makeStyles(styles)
const useStylesModal = makeStyles(stylesModal)
const themePagination = createTheme()
// 이벤트 상세임 아무튼 그럼
const EventsView = (props) => {

    const dispatch = useDispatch()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    const {
        match,
        EventsView,
        listCommentOfEvents,
        getListCommentsAction,
        metadataForEventView: {totalPages}
    } = props

    const classes = useStyles()
    const classesModal = useStylesModal()


    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz

    const [type, setType] = React.useState(match.url.split('/admin/event-managing/view/')[1])
    const [loading, setLoading] = React.useState(false)
    const [typeName, setTypeName] = React.useState(match?.params?.type == 'EVENT' ? '이벤트' : '챌린지')

    const [formData, setFormData] = React.useState({
        id: isNaN(match?.params?.id) ? 0 : match?.params?.id,
        title: EventsView != null ? EventsView.title : '',
        type: type,
        status: 'ACTIVE',
        startAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        endAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        fromTime: 0,
        toTime: 23,
        contentLinkType: 'URL',
        linkUrl: '',
        numOrder: 0,
        tags: [],
        files: '',
        files2: '',
        primaryTag: type == 'EVENT'? '' : '#꾸밍챌린지',
        isDuplication: false,
        contents:''

    })
    const [imageFile, setImageFile] = React.useState(null)
    const [imageFile2, setImageFile2] = React.useState(null)
    const [loadingBtn, setLoadingBtn] = React.useState(false)
    const [changePageComment, setChangePageComment] = React.useState(1)


    const eventsModel = {
        questionTitle: '',
        files: {}
    }


    const [eventVoteQuestionList, setEventVoteQuestionList] = React.useState([eventsModel])



    const handleForm = (value, key) => {
        //vue의 데이터 매핑 형태였던 것 같은데.... key도 매핑되네 히야...

        switch (key) {
            case 'startAt' :
                setFormData({
                    ...formData,
                    [key]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'endAt' :
                setFormData({
                    ...formData,
                    [key]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'isDuplication' :
                setFormData({
                    ...formData,
                    [key]: !formData.isDuplication,
                })
                break;
            default:
                setFormData({
                    ...formData,
                    [key]: value,
                })

        }

    }
    const handleFormQuestionList = (value, key, index) => {
        let tempObject = eventVoteQuestionList[index];
        tempObject[key] = value;
        setEventVoteQuestionList([ ...eventVoteQuestionList.slice(0,index),
            tempObject,
            ...eventVoteQuestionList.slice(index+1)]
        )
    }
    const handleFormQuestionFile = async (file,  index) => {
        //파일 저장해서 가져와진 fileidx를 input


        //이미지 cdn 업로드
        const fd = new FormData()

        fd.append('file', file, file.name)
        fd.append('type', 'SLIDE')
        const {
            data: {id: idImage, url, filename},
        } = await bannerManagingApi.uploadImage(fd); //banner라고 세팅했지만원본은 file api 로 통신


        let tempObject = eventVoteQuestionList[index];
        tempObject.files = {id: idImage, url, filename};
        setEventVoteQuestionList([ ...eventVoteQuestionList.slice(0,index),
            tempObject,
            ...eventVoteQuestionList.slice(index+1)]
        )

    }



    const handleChangeTimePickerStart = (event) => {
        setFormData({...formData, fromTime: event.target.value})
    }
    const handleChangeTimePickerEnd = (event) => {
        setFormData({...formData, toTime: event.target.value})
    }
    const handleChangeFile = (e, type) => {
        //파일 Onchage 시 이미지 체인지


        if (type == 1) {
            if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0]
                const img = new Image()
                img.src = window.URL.createObjectURL(file)
                img.addEventListener('load', () => {
                    setImageFile(file) // 일단 바로 체크
                })
            }
        } else {
            if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0]
                const img = new Image()
                img.src = window.URL.createObjectURL(file)
                img.addEventListener('load', () => {
                    setImageFile2(file) // 일단 바로 체크
                })
            }
        }


    }


    //api 통신해서 db저장 이벤트 생성
    const createEventsManaging = async () => {


        try {


            //정규식 체크
            if (isNaN(formData.numOrder)) {
                //문자열일때
                alert("숫자 입력해주세요")
                return
            }


            //업데이트 일때, 하단은 Insert
            if (formData.id != 0) {
                const fd = new FormData()
                const fd2 = new FormData()

                //업로드 안할시 Null임


                const body = {}

                if (imageFile == null) {
                    body.imageUrlId = formData.imageUrlId
                } else {
                    setLoadingBtn(true)
                    fd.append('file', imageFile, imageFile.name)
                    fd.append('type', 'SLIDE')
                    setLoadingBtn(true)
                    const {
                        data: {id: idImage, url, filename},
                    } = await bannerManagingApi.uploadImage(fd);
                    body.imageUrlId = idImage
                }


                if (imageFile2 == null) {
                    body.imageUrlId2 = formData.imageUrlId2
                } else {
                    fd2.append('file', imageFile2, imageFile2.name)
                    fd2.append('type', 'SLIDE')
                    const {
                        data: {id: idImage2, url2, filename2},
                    } = await bannerManagingApi.uploadImage(fd2); //banner라고 세팅했지만원본은 file api 로 통신
                    body.imageUrlId2 = idImage2
                }


                body.title = formData.title
                body.type = formData.type
                body.startAt = new Date(moment(dateTimeCheck(formData.startAt, formData.fromTime)).format("YYYY-MM-DD HH:mm")).getTime()
                body.endAt = new Date(moment(dateTimeCheck(formData.endAt, formData.toTime)).format("YYYY-MM-DD HH:mm")).getTime()
                body.contentLinkType = formData.contentLinkType
                body.linkUrl = formData.linkUrl
                body.numOrder = parseInt(formData.numOrder)
                body.tags = formData.tags
                body.id = parseInt(isNaN(match?.params?.id) ? 0 : match?.params?.id)
                body.primaryTag = formData.primaryTag
                body.status = formData.status
                body.isDuplication = formData.isDuplication
                body.eventVoteQuestionList = eventVoteQuestionList
                body.contents = formData.contents
                // setEventVoteQuestionList()





                const {data} = await eventsManagingApi.update(body);


                if (data || data[0] == 1) {
                    alert("수정이 완료되었습니다.")
                    // Router.push('/admin/event-managing/' + )
                    location.href = '/admin/event-managing/' + formData.type
                }
                return
            }

            //하단 부터 insert


            //이미지 cdn 업로드
            const fd = new FormData()
            const fd2 = new FormData()

            if (imageFile == null) {
                //이미지 입력 체크
                alert("이미지를 첨부해주세요")
                return
            }
            setLoadingBtn(true)
            fd.append('file', imageFile, imageFile.name)
            fd.append('type', 'SLIDE')
            setLoadingBtn(true)
            const {
                data: {id: idImage, url, filename},
            } = await bannerManagingApi.uploadImage(fd); //banner라고 세팅했지만원본은 file api 로 통신




            //type이 이벤트일 경우
            if (imageFile2 == null) {
                //이미지 입력 체크
                alert("이미지를 첨부해주세요")
                return
            }

            fd2.append('file', imageFile2, imageFile2.name)
            fd2.append('type', 'SLIDE')



            const {
                data: {id: idImage2, url2, filename2},
            } = await bannerManagingApi.uploadImage(fd2); //banner라고 세팅했지만원본은 file api 로 통신



            //이벤트 저장
            if (type == 'EVENT') {


                const body = {
                    title: formData.title,
                    type: formData.type,
                    startAt: moment(dateTimeCheck(formData.startAt, formData.fromTime)).format("YYYY-MM-DD HH:mm"),
                    endAt: moment(dateTimeCheck(formData.endAt, formData.toTime)).format("YYYY-MM-DD HH:mm"),
                    contentLinkType: formData.contentLinkType,
                    linkUrl: formData.linkUrl,
                    numOrder: parseInt(formData.numOrder),
                    imageUrlId: idImage,
                    imageUrlId2: idImage2,
                    status : formData.status,
                    isDuplication: formData.isDuplication || false,
                    contents : formData.contents,
                    eventVoteQuestionList, //투표문답형의 질문 테이블
                }

                const {data} = await eventsManagingApi.create(body);

                setLoadingBtn(false)


                if (data.id != null) {
                    alert("저장이 완료되었습니다.")
                    location.href = '/admin/event-managing/EVENT'
                }else {
                    alert("수정이 완료되었습니다.")
                    location.href = '/admin/event-managing/EVENT'
                }


            } else {
                const body = {
                    title: formData.title,
                    type: formData.type,
                    startAt: new Date(moment(dateTimeCheck(formData.startAt, formData.fromTime)).format("YYYY-MM-DD HH:mm")).getTime(),
                    endAt: new Date(moment(dateTimeCheck(formData.endAt, formData.toTime)).format("YYYY-MM-DD HH:mm")).getTime(),
                    contentLinkType: formData.contentLinkType,
                    linkUrl: formData.linkUrl,
                    numOrder: parseInt(formData.numOrder),
                    imageUrlId: idImage,
                    imageUrlId2: idImage2,
                    tags: formData.tags,
                    status : formData.status,
                    primaryTag : formData.primaryTag,

                }
                const {data} = await eventsManagingApi.create(body);


                if (data.id != null) {
                    alert("저장이 완료되었습니다.")
                    location.href = '/admin/event-managing/CHALLENGE'
                }else {
                    alert("수정이 완료되었습니다.")
                    location.href = '/admin/event-managing/CHALLENGE'
                }
                setLoadingBtn(false)
            }


        } catch (e) {
            setLoadingBtn(false)
        }

    }
    const getEventData = async () => {
        try {



            setLoading(true)
            if (formData.id > 0) {
                //데이터가 있는 것

                //setLoading 필요

                let {data} = await eventsManagingApi.getEvents({id: parseInt(formData.id)});
                //데이터 매핑 dispatch쓰면 코드가 깔끔해지나 쓰지 않음
                // 이건 굳이 안해도 될듯?
                dispatch(getEventsAction(data))
                data = data.events



                const tagsRe = []

                data.manage_tags?.forEach((item) => {
                    tagsRe.push(item.tagName)
                })


                //가져온데이터 다시 매핑
                setFormData({
                    // id: data.id, //이거 넣으면 useEffect의 파라미터때문에 무한루프
                    title: data.title,
                    type: data.type,
                    status: data.status,
                    startAt: moment(data.startAt).format('YYYY-MM-DD'),
                    endAt: moment(data.endAt).format('YYYY-MM-DD'),
                    fromTime: parseInt(moment(data.startAt).format('HH')),
                    toTime: parseInt(moment(data.endAt).format('HH')),
                    contentLinkType: data.contentLinkType,
                    linkUrl: data.linkUrl,
                    numOrder: data.numOrder,
                    files: data.files,
                    files2: data.files2,
                    tags: tagsRe, //태그 넣을땐 일괄로 넣고 일괄로 삭제하는 방향으로
                    imageUrlId: data.imageUrlId,
                    imageUrlId2: data.imageUrlId2,
                    primaryTag: data.primaryTag,
                    isDuplication: data.isDuplication || false,
                    contents : data.contents,
                    totalApplyCount: data.totalApplyCount
                })
                setEventVoteQuestionList(data.event_vote_questions);


                // setFormData('')


                setType(data.type)
                setTypeName(data.type == 'EVENT' ? '이벤트' : '챌린지')
                setLoading(false)

            }
            setLoading(false)

        } catch (e) {

        }
    }

    const getExcelFile = async () => {
        const d = new Date()
        let tz = d.getTimezoneOffset() / 60
        try {
            // setLoadingGetExcelFile(true)

            // alert(formData.id)
            const params = {
                // state: selectHoldOrBlock,
                // limit: rowsPerPage,
                // order: order.toUpperCase(),
                eventId: match?.params?.id,
                localTimeZone: tz
            }
            // selectHoldOrBlock === 'ALL' && delete params.state
            // selected.length === 0 && delete params.reportBlockIds

            // if (orderBy) {
            //     params['orderBy'] = orderBy
            // }

            const data = await eventsManagingApi.getExcelFile(params)


            fileDownload(data, formData.title+'_이벤트_응모리스트.xlsx')

            alert("엑셀을 다운로드 하였습니다")
            // setLoadingGetExcelFile(false)
        } catch (error) {
            // setLoadingGetExcelFile(false)
            alert("엑셀을 다운받는 도중 에러가 발생했습니다.")
            // if (error && error.response && error.response.data)
            //     dispatch(reportBlockManagingRequestWithError(error.response.data))
        }
    }
    const getExcelFileCommentList = async () => {
        const d = new Date()
        let tz = d.getTimezoneOffset() / 60
        try {
            // setLoadingGetExcelFile(true)

            // alert(formData.id)
            const params = {
                // state: selectHoldOrBlock,
                // limit: rowsPerPage,
                // order: order.toUpperCase(),
                eventId: match?.params?.id,
                localTimeZone: tz
            }
            // selectHoldOrBlock === 'ALL' && delete params.state
            // selected.length === 0 && delete params.reportBlockIds

            // if (orderBy) {
            //     params['orderBy'] = orderBy
            // }

            const data = await eventsManagingApi.getEventsCommentListExcel(params)

            fileDownload(data, formData.title+'_이벤트_댓글리스트.xlsx')

            alert("엑셀을 다운로드 하였습니다")
            // setLoadingGetExcelFile(false)
        } catch (error) {
            // setLoadingGetExcelFile(false)
            alert("엑셀을 다운받는 도중 에러가 발생했습니다.")
            // if (error && error.response && error.response.data)
            //     dispatch(reportBlockManagingRequestWithError(error.response.data))
        }
    }



    let count = 0;
    React.useEffect(() => {
        //getData
        getEventData()
    }, [formData.id])

    const getPostDetailAndListComment = async () => {
        try {
            // get list comment
            const params = {
                eventsId: match?.params?.id,
                limit: 10,
                offset: changePageComment,
                order: 'DESC',
            }

            const dataComments = await eventsManagingApi.getListComments(params)
            getListCommentsAction(dataComments.data)
        } catch (error) {
            console.log(error.response)
        }
    }
    React.useEffect(() => {
        getPostDetailAndListComment();

        // getListCommentsAction()
    }, [changePageComment])
    // const handleTitle = (e)

    return (

        <div>

            {loading ? (<Spinner/>) : ( <div>
                <Paper variant='outlined' className={classes.paper}>
                    {/*<GridContainer  className={classes.grid} alignItems='center'>*/}
                    {/*    <h3>*/}
                    {/*        비노출 일경우 리스트에 표현되지 않습니다.*/}
                    {/*    </h3>*/}
                    {/*</GridContainer>*/}

                    <GridContainer className={classes.grid} alignItems='center'>
                        <GridItem xs={12} sm={8} md={8} lg={8} xl={8} style={{'padding-top': '20px !important','padding-bottom': '20px !important', 'margin-top': '20px'}}>
                            <p>
                                {/*<button  onClick={getExcelFile}>Zmffflrgowntpdy</button>*/}


                                {formData.contentLinkType == 'APPLY' && (<Button
                                    // disabled={loadingGetExcelFile}
                                    onClick={getExcelFile}
                                    color='primary'
                                >
                                    엑셀 다운로드(응모한사람)
                                </Button>)
                                }

                                <Button
                                    // disabled={loadingGetExcelFile}
                                    onClick={getExcelFileCommentList}
                                    color='primary'
                                >
                                    엑셀 다운로드(댓글)
                                </Button>

                                <h3>{formData.title}</h3>
                                {/*<strong>ID: {memberID}</strong>&nbsp;&nbsp;&nbsp;*/}
                                {/*<span>@{nickname}</span>*/}
                            </p>
                            <p>
                                <strong style={{'margin-right': '10px'}}>이벤트 일자</strong>
                                <span>{moment(formData?.startAt).format('YYYY/MM/DD hh:mmA')}
                                    ~ {moment(formData?.endAt).format('YYYY/MM/DD hh:mmA')}</span>
                                {/*<strong>ID: {memberID}</strong>&nbsp;&nbsp;&nbsp;*/}
                                {/*<span>@{nickname}</span>*/}
                            </p>
                        </GridItem>
                        <GridItem xs={12} sm={8} md={8} lg={8} xl={8} style={{'padding-top': '20px !important','padding-bottom': '20px !important', 'margin-top': '20px'}}>
                            <p>
                                <strong style={{'margin-right': '10px'}}>노출순서</strong>
                                <span>{formData?.numOrder}</span>
                                {/*<strong>ID: {memberID}</strong>&nbsp;&nbsp;&nbsp;*/}
                                {/*<span>@{nickname}</span>*/}
                            </p>
                        </GridItem>
                        <GridItem xs={12} sm={8} md={8} lg={8} xl={8} style={{'padding-top': '20px !important','padding-bottom': '20px !important','margin-top': '15px'}}>

                            <p>
                                <strong style={{'margin-right': '10px'}}>노출 여부</strong>
                                <span>
                                    {formData?.status == 'ACTIVE' ?  <span style={{'backgroundColor': '#86E57F', 'borderRadius': '7px', 'padding':'5px'}}>노출(활성화)</span> : <span >비노출(비활성화)</span>}
                                </span>
                                {/*<strong>ID: {memberID}</strong>&nbsp;&nbsp;&nbsp;*/}
                                {/*<span>@{nickname}</span>*/}
                            </p>
                        </GridItem>
                        <GridItem xs={12} sm={8} md={8} lg={8} xl={8}  style={{'padding-top': '20px !important','padding-bottom': '20px !important','margin-top': '15px'}}>

                            <p>
                                <strong style={{'margin-right': '10px'}}>내용 타입</strong>
                                <span>
                                    {formData.contentLinkType == 'URL' &&  <span    style={{'backgroundColor': '#eee', 'borderRadius': '7px', 'padding':'5px'}}>링크형</span> }
                                    {formData.contentLinkType == 'VOTE' &&  <span   style={{'backgroundColor': '#A566FF', 'borderRadius': '7px', 'padding':'5px'}}>투표문답형</span> }
                                    {formData.contentLinkType == 'APPLY' &&  <span style={{'backgroundColor': '#6799FF', 'borderRadius': '7px', 'padding':'5px'}}>참여형</span> }
                                </span>
                                {/*<strong>ID: {memberID}</strong>&nbsp;&nbsp;&nbsp;*/}
                                {/*<span>@{nickname}</span>*/}
                            </p>
                        </GridItem>

                        <GridItem xs={12} sm={8} md={8} lg={8} xl={8}  style={{'padding-top': '20px !important','padding-bottom': '20px !important','margin-top': '15px'}}>

                            <p>
                                <strong style={{'margin-left': '10px'}}>참여자</strong>
                                &nbsp;&nbsp;&nbsp;<span>
                                    {formData?.totalApplyCount} 명
                                </span>
                                {/*<strong>ID: {memberID}</strong>&nbsp;&nbsp;&nbsp;*/}
                                {/*<span>@{nickname}</span>*/}
                            </p>
                        </GridItem>


                    </GridContainer>
                </Paper>
                {/*리스트 배너*/}
                <Paper variant='outlined' className={classes.paper} style={{'marginTop': '20px', 'paddingTop': '20px'}}>
                    <GridContainer className={classes.grid} alignItems='center'>
                        {/*<GridItem xs={1}  style={{'textAlign': 'center'}} >
                            <strong style={{'margin-right': '10px'}}>이벤트 배너<br/>리스트</strong>
                        </GridItem>
*/}



                                <img src={formData?.files?.url} style={{maxWidth: '100%'}}/>


                    </GridContainer>
                </Paper>

                {/*이벤트 상세 */}
                    <Paper variant='outlined' className={classes.paper} style={{'margin-top': '20px','padding-top': '20px !important'}}>
                    <GridContainer className={classes.grid} alignItems='center'>

                        <GridItem xs={12} style={{'max-width': '100%', marginBottom: '20px', marginTop: '20px', padding: '50px !important'}}>
                            <img src={formData?.files2?.url} style={{'max-width': '100%'}}/>
                        </GridItem>
                        <GridItem xs={12} style={{'max-width': '100%', padding: '20px !important'}}>
                            <span style={{ whiteSpace: "pre-line" }}>{formData?.contents}</span>
                        </GridItem>
                    </GridContainer>
                </Paper>


                {/*투표문답 현황*/}
                <Paper variant='outlined' className={classes.paper} style={{ marginTop: '20px'}}>
                    <GridContainer className={classes.grid} alignItems='center'  style={{'margin-left': '20px','margin-top': '20px','padding-top': '20px !important'}}>

                        {/*투표문답 질문*/}
                        {eventVoteQuestionList?.map((item, i) => {

                            return <GridItem xs={10} style={{'max-width': '50%', marginBottom: '20px', marginTop: '20px', padding: '50px !important'}}>
                                <Paper
                                    className={`${classes.paperSwitch} ${classes.votePaper}`}
                                    style={{ display: 'flex'}}
                                    variant='outlined'
                                >
                                    {
                                        item.files != null ? <div style={{'width':'20%', 'margin-right': '5%'}}>
                                            <img style={{'width': '100%', maxWidth: '100px'}} src={item?.files?.url} alt="" />
                                        </div> : null
                                    }
                                    {
                                        <div style={{'width':'75%', padding: '10px'}}>
                                            <span style={{'display':'flex'}}>{item.questionTitle} </span>
                                            <span>( {item.persent}% / {item.questionIsApplyCount}명 참여 )</span>
                                        </div>
                                    }

                                </Paper>
                            </GridItem>
                        })}

                    </GridContainer>
                </Paper>

                {/*댓글리스트*/}
                <Box mt={5}>
                    <CommentTable hover rows={listCommentOfEvents}/>
                </Box>
                <Box
                    mt={1}
                    display='flex'
                    justifyContent='flex-end'
                    className='pagiantion'
                >
                    <ThemeProvider theme={themePagination}>
                        <Pagination
                            onChange={(e, value) => setChangePageComment(value)}
                            size={matches ? 'small' : 'large'}
                            count={totalPages}
                            showFirstButton
                            showLastButton
                        />
                    </ThemeProvider>
                </Box>

                <Box className='add-list' display='flex' justifyContent='center'>


                    {loadingBtn ? (
                        <Spinner/>
                    ) : (
                        <CustomButton color="primary" mr={2} variant="contained"
                                      onClick={createEventsManaging}
                        >수정</CustomButton>)
                    }
                    <CustomButton color="default" mr={2} variant="contained"
                                  onClick={(event) => history.back()}
                    >취소</CustomButton>

                </Box>
            </div>)}


        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        // userDetail: state.events.EventsView,
        loading: state.eventsReducer.loading,
        EventsView: state.eventsReducer.EventsView,
        events: state.eventsReducer.events,
        metaData: state.eventsReducer.metaData,
        listCommentOfEvents: state.eventsReducer.listCommentOfEvents,
        metadataForEventView: state.eventsReducer.metadataForEventView
    }
}

export default connect(mapStateToProps, {
    getListCommentsAction
    // requestNoticeAction,
    // getListNoticesAction,
    // noticesWithErrAction,
})(EventsView)
