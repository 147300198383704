import * as actionTypes from './types'

export const requestWriterGoodsManagingAction = () => {
	return {
		type: actionTypes.REQUEST_WRITER_GOODS_MANAGING,
	}
}

export const getListWriterGoodsAction = (payload) => {
	return {
		type: actionTypes.GET_LIST_WRITER_GOODS,
		payload: payload,
	}
}

export const toggleWriterGoodsIsSyncAction = (payload) => {
	return {
		type: actionTypes.TOGGLE_WRITER_GOODS_IS_SYNC,
		payload: payload,
	}
}


export const toggleWriterGoodsIsShowAction = (payload) => {
	return {
		type: actionTypes.TOGGLE_WRITER_GOODS_IS_SHOW,
		payload: payload,
	}
}

export const setPaginationWriterGoodsManagingAction = (payload) => {
	return {
		type: actionTypes.SET_PAGINATION_WRITERGOODSMANAGING,
		payload: payload,
	}
}

export const requestWriterGoodsManagingErrorAction = (payload) => {
	return {
		type: actionTypes.REQUEST_WRITER_GOODS_MANAGING_ERROR,
		payload: payload,
	}
}
