import React from 'react'
import cx from 'classnames'
import { Switch, Redirect } from 'react-router-dom'
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

import { makeStyles } from '@material-ui/core/styles'

import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import Sidebar from 'components/Gm-SideBar/Sidebar'
import PostDetail from 'views/PostManaging/PostDetail'
import UserDetail from 'views/UserManaging/UserDetail'
import ReportBlockDetail from 'views/ReportBlockManaging/ReportBlockDetail'
import NoticeAdd from 'views/Notice/NoticeAdd'
import EventsDetail from 'views/Notice/EventsDetail'

import routes from 'routes.js'
import PrivateRoute from 'components/PrivateRoute/PrivateRoute'

import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js'
import PostInfoManaging from "../views/PostInfoManaging/PostInfoManaging.js";
import PostManaging from "../views/PostManaging/PostManaging";
import EventManaging from "../views/Notice/EventManaging";
import PushDetail from "../views/PushManaging/PushDetail";
import WriterEdit from "../views/WriterManaging/WriterEdit";
import WriterMainEdit from "../views/WriterManaging/WriterMainDetail";
import PostTalk from "../views/PostManaging/PostTalk";
import PostIssue from "../views/PostManaging/PostIssue";
import EventsView from "../views/Notice/EventView";
import SplashDetail from "../views/SplashManaging/SplashDetail";
import GoodsManagingWriter from "../views/ContentsManaging/GoodsManagingWriter";
import GoodsManaging from "../views/ContentsManaging/GoodsManaging";
import ContentsMultiDetail from "../views/ContentsManaging/ContentsMultiDetail";
import ContentsMultiManaging from "../views/ContentsManaging/ContentsMultiManaging";
import ContentsManaging from "../views/ContentsManaging/ContentsManaging";
import ContentsManagingWriter from "../views/ContentsManaging/ContentsManagingWriter";
import TagManaging from "../views/MainManaging/TagManaging";
import VersionDetailEdit from "../views/Setting/VersionDetail";
import WriterSchedulesDetail from "../views/WriterManaging/WriterSchedulesDetail";
import StickerTagManagingDetail from "../views/StickerManaging/StickerTagManagingDetail";



const useStyles = makeStyles(styles)

export default function Dashboard(props) {
  const { ...rest } = props
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [miniActive, setMiniActive] = React.useState(false)
  const [image, setImage] = React.useState(
    require('assets/img/sidebar-2.jpg').default,
  )
  const [color, setColor] = React.useState('blue')
  const [bgColor, setBgColor] = React.useState('black')
  const [logo, setLogo] = React.useState(
    require('assets/img/admin_logo.png').default,
  )
  // styles
  const classes = useStyles()
  const mainPanelClasses =
    classes.mainPanel +
    ' ' +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    })
  // ref for main panel div
  const mainPanel = React.createRef()
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      var ps;
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
      document.body.style.overflow = 'hidden'
    }
    window.addEventListener('resize', resizeFunction)

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy()
      }
      window.removeEventListener('resize', resizeFunction)
    }
  })

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const   getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text'
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name
        }
      }
    }
    return activeRoute
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views)
      }
      if (prop.layout === '/admin') {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }

  const sidebarMinimize = () => {
    setMiniActive(!miniActive)
  }

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false)
    }
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={'Ggooming'}
        image={image}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            {/*!!!  순서바뀌면 안됨 !!!*/}
            <Switch>

              <PrivateRoute path='/admin/sticker-managing/detail/:id' component={StickerTagManagingDetail}></PrivateRoute>

              <PrivateRoute path='/admin/recommend-writer-goods-managing/:type/detail/:id' component={GoodsManagingWriter}></PrivateRoute>
              <PrivateRoute path='/admin/content-managing/:type/detail/:id' component={ContentsMultiDetail}></PrivateRoute>
              <PrivateRoute path='/admin/content-managing/:type/detail' component={ContentsMultiDetail}></PrivateRoute>
              <PrivateRoute path='/admin/content-managing/HOME_WRITER' component={ContentsManagingWriter}></PrivateRoute>
              <PrivateRoute path='/admin/content-managing/:type' component={ContentsManaging}></PrivateRoute>
              <PrivateRoute path='/admin/content-multi-managing/:type' component={ContentsMultiManaging}></PrivateRoute>
              <PrivateRoute path='/admin/recommend-writer-goods-managing/:type' component={GoodsManaging}></PrivateRoute>
              {/*<PrivateRoute path='/admin/recommend-writer-goods-managing/detail/:id' component={GoodsManagingWriter}></PrivateRoute>*/}
              <PrivateRoute path='/admin/splash-managing/detail/:id' component={SplashDetail} />
              <PrivateRoute path='/admin/splash-managing/detail' component={SplashDetail} />
              <PrivateRoute path='/admin/tag-managing/:type' component={TagManaging} />
              {/*update*/}
              <PrivateRoute path='/admin/push-managing/detail/:id' component={PushDetail} />
              {/*insert*/}
              <PrivateRoute path='/admin/push-managing/detail' component={PushDetail} />

              <PrivateRoute path='/admin/version-managing/detail/:id' component={VersionDetailEdit} />
              <PrivateRoute path='/admin/version-managing/detail' component={VersionDetailEdit} />
              {getRoutes(routes)}
              {/*update*/}
              <PrivateRoute path='/admin/push-detail/detail/:id' component={PushDetail} />
              <PrivateRoute path='/admin/event-managing/detail/:type/:id' component={EventsDetail} />
              <PrivateRoute path='/admin/event-managing/view/:type/:id' component={EventsView} />
              {/*insert*/}
              <PrivateRoute path='/admin/push-detail/detail' component={PushDetail} />
              <PrivateRoute path='/admin/event-managing/detail/:type' component={EventsDetail} />
              <PrivateRoute path='/admin/writer-detail/:id' component={WriterEdit} />
              <PrivateRoute path='/admin/writer-detail' component={WriterEdit} />
              <PrivateRoute path='/admin/writer-main/detail/:id' component={WriterMainEdit} />
              <PrivateRoute path='/admin/writer-main/detail' component={WriterMainEdit} />
              <PrivateRoute path='/admin/writer-schedules/detail/:id' component={WriterSchedulesDetail} />
              <PrivateRoute path='/admin/writer-schedules/detail' component={WriterSchedulesDetail} />

              <PrivateRoute path='/admin/post-talk/:id'  component={PostTalk}  ></PrivateRoute>
              <PrivateRoute path='/admin/post-talk'  component={PostTalk}  ></PrivateRoute>
              <PrivateRoute path='/admin/post-issue/:id'  component={PostIssue}  ></PrivateRoute>
              <PrivateRoute path='/admin/post-issue'  component={PostIssue}  ></PrivateRoute>

              {/*{추가 라우터 설정 부분}*/}
              <PrivateRoute path='/admin/post-detail/:id' component={PostDetail} />
              <PrivateRoute path='/admin/post-detail' component={PostDetail} />
              <PrivateRoute path='/admin/user-detail' component={UserDetail} />


              <PrivateRoute
                path='/admin/report-block-detail'
                component={ReportBlockDetail}
              />
              <PrivateRoute path='/admin/notice-add' component={NoticeAdd} />
              {/*<PrivateRoute path='/admin/notice-add2' component={EventsDetail} />*/}
              {/*<PrivateRoute path='/admin/post-list'  component={PostManaging}  ></PrivateRoute>*/}
              {/*<PrivateRoute path='/admin/event-managing/:type'  component={EventManaging}  />*/}
              <Redirect from='/admin' to='/admin/statistics-main' />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}
