import axiosInterceptors from 'api/axios'
import queryString from "query-string";

const goodsManagingApi = {
    create: (params) => {
        const url = "/goodsManaging/createGoodsManaging"
        return axiosInterceptors.post(url, {params})
    },
    update: (params) => {
        const url = "/goodsManaging/updateGoodsManaging"
        return axiosInterceptors.put(url, {params})
    },
    updateGoodsManagingOrder: (params) => {
        const url = "/goodsManaging/updateGoodsManagingOrder"
        return axiosInterceptors.put(url, {params})
    },
    getList: (params )=> {
        const url = "/goodsManaging/getGoodsManaging"
        return axiosInterceptors.get(url, {params})
    },
    getGoodsManagingWriterList: (params )=> {
        const url = "/goodsManaging/getGoodsManagingWriterList"
        return axiosInterceptors.get(url, {params})
    },
    deleteContent: (params )=> {
        const url = "/goodsManaging/deleteGoodsContent"
        return axiosInterceptors.delete(url, {params})
    },
    deleteGoodsContentWriterGoods: (params )=> {
        const url = "/goodsManaging/deleteGoodsContentWriterGoods"
        return axiosInterceptors.delete(url, {params})
    },
    //태그에 순서변경
    updateOrderContents: (params) => {
        const url = "/goodsManaging/updateOrderContents"
        return axiosInterceptors.put(url, {params})
    },
    //상품에 대한 순서변경
    updateOrderWriterGoods: (params) => {
        const url = "/goodsManaging/updateOrderWriterGoods"
        return axiosInterceptors.put(url, {params})
    },
    updateOrderContentsOrderStatic: (params) => {
        const url = "/goodsManaging/updateOrderContentsOrderStatic"
        return axiosInterceptors.put(url, {params})
    },


}
export default goodsManagingApi