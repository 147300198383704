import React from 'react'
import fileDownload from 'js-file-download'

import {makeStyles} from '@material-ui/core/styles'
import TextField from 'components/Gm-TextField/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import GridContainer from 'components/Grid/GridContainer.js'
import InputAdornment from '@material-ui/core/InputAdornment'
import Box from '@material-ui/core/Box'
import SearchIcon from '@material-ui/icons/Search'
import GridItem from 'components/Grid/GridItem.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from 'components/CustomButtons/Button.js'
import Table from './components/Table'

import {connect, useDispatch} from 'react-redux'
import {
	getListWriterAction,
	requestWriterManagingAction,
	requestWriterManagingErrorAction,
	toggleWriterExposureAction,
	toggleWriterIsSyncAction
} from 'redux/actions/writerManagingAction'
import writerManagingApi from 'api/writerManagingApi'

import styles from 'assets/jss/material-dashboard-pro-react/views/UserManaging/userManaging'
import {requestUserManagingAction} from "../../redux/actions/userManagingAction";

const useStyles = makeStyles(styles)

const WriterManaging = (props) => {

	const {
		requestWriterManagingAction,
		getListWriterAction,
		requestWriterManagingErrorAction,
		toggleWriterExposureAction,
		toggleWriterIsSyncAction,
		loading,
		writers,
		totalWriter,
		totalWriterBySearch,
		metaData: { totalRecords },
	} = props

	const fileInput = React.createRef()
	const imgInput = React.createRef()
	const classes = useStyles()
	const dispatch = useDispatch()

	const [loadingBtnGetExcel, setLoadingBtnGetExcel] = React.useState(false)
	const [select, setSelect] = React.useState('all')
	const [order, setOrder] = React.useState('desc')
	const [orderBy, setOrderBy] = React.useState('')
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [isResetPagination, setIsResetPagination] = React.useState(false)
	const [page, setPage] = React.useState(0)
	const [searchKey, setSearchKey] = React.useState('all')
	const [searchText, setSearchText] = React.useState('')

	//멤버 지정 관련 변수
	const [memberList, setMemberList] = React.useState([])
	const [metaData, setMetaData] = React.useState({
		totalRecords: 0,
		totalPages:0
	})


	const handleChangeSearchInput = (e) => {
		setSearchText(e.target.value)
		!isResetPagination && setIsResetPagination(true)
	}

	const handleChangeSelect = (event) => {
		setSelect(event.target.value)
		!isResetPagination && setIsResetPagination(true)
	}

	const headCells = [
		{
			id: 'home',
			numeric: false,
			disablePadding: false,
			label: '홈노출',
			allowSortable: false,
		},
		{
			id: 'sync',
			numeric: false,
			disablePadding: false,
			label: '동기화여부',
			allowSortable: false,
			minWidth: '100px'
		},
		{
			id: 'nickname',
			numeric: true,
			disablePadding: false,
			label: '닉네임',
			allowSortable: true,
		},
		{
			id: 'users',
			numeric: true,
			disablePadding: false,
			label: '계정 매칭',
			allowSortable: false,
		},
		{
			id: 'img',
			numeric: 'false',
			disablePadding: false,
			label: '프로필',
			allowSortable: false,
			minWidth: '100px'
		},
		{
			id: 'img',
			numeric: 'false',
			disablePadding: false,
			label: '배경화면',
			allowSortable: false,
		},
		{
			id: 'goodsLinkType',
			numeric: false,
			disablePadding: false,
			label: '연동사이트명',
			allowSortable: false,
		},
		{
			id: 'bio',
			numeric: false,
			disablePadding: false,
			label: '자기소개',
			allowSortable: false,
		},
		{
			id: 'email',
			numeric: false,
			disablePadding: false,
			label: '이메일',
			allowSortable: false,
		},
		/*{
			id: 'linkShareName',
			numeric: false,
			disablePadding: false,
			label: '사이트명',
			allowSortable: false,
		},*/
		/*{
			id: 'linkShare',
			numeric: false,
			disablePadding: false,
			label: '기타url',
			allowSortable: false,
		},*/
		{
			id: 'linkShare',
			numeric: false,
			disablePadding: false,
			label: '링크들',
			allowSortable: false,
		},
		{
			id: 'totalLiker',
			numeric: true,
			disablePadding: false,
			label: '팔로워수',
			allowSortable: true,
		},
		{
			id: 'role',
			numeric: true,
			disablePadding: false,
			label: '프로/작가',
			allowSortable: true,
		},
		{
			id: 'bio',
			numeric: true,
			disablePadding: false,
			label: '작가소개',
			allowSortable: false,
		},
		{
			id: 'id',
			numeric: true,
			disablePadding: false,
			label: '등록일',
			allowSortable: true,
		}
	]
	const getListWriters = async () => {
		let orderString = '';

		if (orderBy == 'first') {
			//처음 정렬시 현재 front에 나오는 표출 순서대로
			orderString = 'numOrder,DESC&createdAt,DESC';
		} else {
			orderString = orderBy + ',' + order
		}

		try {
			requestWriterManagingAction()
			const params = {
				limit: rowsPerPage,
				offset: page + 1,
				order: order,
				orderBy: orderBy,
				searchKey: searchKey,
				searchText: searchText
				// userStatus: select,
				// clientId,
			}


			const { data } = await writerManagingApi.getListWriters(params)
			getListWriterAction(data)
		} catch (error) {
			console.log(error.response)
			if (error && error.response && error.response.data) {
				// requestUserManagingErrorAction(error.response.data)
			}
		}
	}

	const getListUsers = async () => {
		let orderString = '';

		if (orderBy == 'first') {
			//처음 정렬시 현재 front에 나오는 표출 순서대로
			orderString = 'numOrder,DESC&createdAt,DESC';
		} else {
			orderString = orderBy + ',' + order
		}
		try {
			requestUserManagingAction()
			const params = {
				limit: 10,
				offset: page == 0 ? 1 :  page,
				order: 'DESC',
				orderBy: 'DATE_SIGN_UP',
				searchKey: searchKey,
				searchText: searchText
				// userStatus: select,
				// clientId,
			}
			const { data } = await userManagingApi.getListUsers2(params)
			setMemberList(data.users)



		} catch (error) {
			console.log(error.response)
			if (error && error.response && error.response.data) {
				// requestUserManagingErrorAction(error.response.data)
			}
		}
	}



	const handleSearchKey = ( valuwe) => {
		setSearchKey(valuwe.target.value)
		setOrder('asc')
	}

	const handleButtonClick = async e => {
		fileInput.current.click();
	}

	const handleButtonClickImg = async e => {
		imgInput.current.click();
	}
	const downloadExcel = async e => {
		location.href = 'https://d16sraug4hsad1.cloudfront.net/excel_example/writer_profile5.xlsx'
	}

	const getExcelFileWriterManaging = async () => {
		let orderString = '';

		if (orderBy == 'first') {
			//처음 정렬시 현재 front에 나오는 표출 순서대로
			orderString = 'numOrder,DESC&createdAt,DESC';
		} else {
			orderString = orderBy + ',' + order
		}

		try {
			const params = {
				limit: rowsPerPage,
				offset: page + 1,
				order: order,
				orderBy: orderBy,
				searchKey: searchKey,
				searchText: searchText
			}


			// const { data } = await writerScheduleManagingApi.getListSchedule(params)
			const data  = await writerManagingApi.getListWritersExcel(params)

			fileDownload(data, 'writers.xlsx')

		} catch (error) {
			console.log(error.response)
			if (error && error.response && error.response.data) {
				requestWriterGoodsManagingErrorAction(error.response.data)
			}
		}
	}

	const handleChangeImg = async e => {
		const files = e.target.files;
		const formData = new FormData()

		for(let i=0; i<files.length; i++) {
			formData.append('files',  e.target.files[i])
		}
		formData.append('type', 'PROFILE')

		const { data } = await writerManagingApi.saveImageFile(formData)
		if (data){
			// alert("파일 업로드가 완료되었습니다.")
			alert(data.messages)
		}

	}
	const handleChange = async e => {
		try {
			const file = e.target.files[0];
			const fd = new FormData()
			fd.append('xlsx', file, file.name)
			fd.append('type', 'xlsx')

			const { data } = await writerManagingApi.setExcelFile(fd)

			if (data?.messages){
				alert(data?.messages)
			}
			getListWriters()
			// getListWriterAction(data)

		} catch (error) {
			console.log(error.response)
			if (error && error.response && error.response.data) {
			}
		}
	}
	React.useEffect(()=>{
		getListUsers()
	}, [])

	React.useEffect(() => {
		getListWriters()
	}, [page, rowsPerPage, order])

	return (
			<div className='writer-managing'>
				<GridContainer alignItems='center'>
					<GridItem
							className={classes.gridContainerOne}
							xs={12}
							sm={4}
							md={3}
							lg={2}
							xl={2}
					>
						<TextField
								id='writer-managing-select'
								select
								size='small'
								className={classes.textFieldOne}
								label='검색타입'
								value={searchKey}
								onChange={handleSearchKey}
						>
							<MenuItem value={'all'}>전체</MenuItem>
							<MenuItem value={'nickname'}>이름</MenuItem>
							<MenuItem value={'bio'}>자기소개</MenuItem>
							{/*<MenuItem value={'linkShareName'}>사이트명</MenuItem>*/}
							{/*<MenuItem value={'BLOCKED'}>차단</MenuItem>*/}
							{/*<MenuItem value={'BLOCKED'}>차단</MenuItem>*/}
						</TextField>
					</GridItem>
					<GridItem xs={12} sm={6} md={5} lg={4} xl={4}>
						<TextField
								id='post-managing-textfield'
								size='small'
								className={classes.textFieldOne}
								placeholder='검색어를 입력해주세요'
								name='searchText'
								value={searchText}
								onChange={handleChangeSearchInput}
								InputProps={{
									startAdornment: (
											<InputAdornment position='start'>
												<SearchIcon />
											</InputAdornment>
									),
								}}
						/>
					</GridItem>

					<GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
						<Button
								color='primary'
								disabled={loading}
								onClick={() => {
									setPage(0)
									getListWriters()
								}}
						>
							검색
						</Button>
					</GridItem>
				</GridContainer>

				<Box my={2}>
					<TextField
							className={`${classes.textFieldTwo} ${classes.textFieldTwoChildOne}`}
							id='post-managing-textfield-show-info1'
							size='small'
							value={totalWriter}
							InputProps={{
								startAdornment: (
										<InputAdornment position='start'>전체</InputAdornment>
								),
								readOnly: true,
							}}
					/>
					<TextField
							className={classes.textFieldTwo}
							id='post-managing-textfield-show-info2'
							size='small'
							value={totalWriterBySearch}
							InputProps={{
								startAdornment: (
										<InputAdornment position='start'>검색</InputAdornment>
								),
								readOnly: true,
							}}
					/>
				</Box>

				<Box className={classes.btnGetExcelAndPaginationTable} mb={2} style={{'justifyContent': 'space-between'}}>

					<Button
						color='warning'
						onClick={(e)=>{
							location.href = '/admin/content-managing/HOME_WRITER'
						}}
					>
						작가 노출관리(홈노출) 이동
					</Button>

					<div>

						<Button
							color='github'
							onClick={getExcelFileWriterManaging}
						>
							작가 엑셀 다운로드
						</Button>
						<>
							<Button
								color='info'
								onClick={handleButtonClick}
							>
								작가 엑셀 업로드(수정/등록)
							</Button>
							<input
								name="FILE_UPLOAD"
								type="file"
								ref={fileInput}
								id="fileUpload"
								onChange={handleChange}
								onClick={(event)=> {event.target.value = null}}    //같은 파일 적용도 가능하게
								accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  //엑셀만 허용
								style={{ display: "none" }}
							/>
						</>


						<>
							<Button
								color='success'
								onClick={handleButtonClickImg}
							>
								사진 업로드
							</Button>
							<input
								name="FILE_UPLOAD"
								type="file"
								multiple
								ref={imgInput}
								id="fileUpload"
								onChange={handleChangeImg}
								onClick={(event)=> {event.target.value = null}}    //같은 파일 적용도 가능하게
								accept='image/*'
								style={{ display: "none" }}
							/>
						</>

						<Button
							color='github'
							onClick={(e)=>{
								location.href = 'https://d16sraug4hsad1.cloudfront.net/excel_example/writer_profile5.xlsx'
							}}
						>
							작가 엑셀 양식
						</Button>
						<Button
							color='github'
							onClick={(e)=>{
								location.href = '/admin/writer-detail'
							}}
						>
							작가 등록
						</Button>

					</div>


				</Box>

				<Box mb={2}>
					{loading ? (
							<CircularProgress size={30} className={classes.buttonProgress} />
					) : (
							<Table
									headCells={headCells}
									rows={writers}
									totalRecords={totalRecords}
									setRowsPerPage={setRowsPerPage}
									rowsPerPage={rowsPerPage}
									setPage={setPage}
									page={page}
									setOrder={setOrder}
									order={order}
									setOrderBy={setOrderBy}
									orderBy={orderBy}
									getListWriters={getListWriters}
									member={memberList}
									setMember={setMemberList}
									dispatch={dispatch}
									toggleWriterExposureAction={toggleWriterExposureAction}
									toggleWriterIsSyncAction={toggleWriterIsSyncAction}
							/>
					)}
				</Box>
			</div>
	)
}

const mapStateToProps = (state) => ({
	loading: state.writerManaging.loading,
	writers: state.writerManaging.writers,
	metaData: state.writerManaging.metaData,
	totalWriter: state.writerManaging.totalWriter,
	totalWriterBySearch: state.writerManaging.totalWriterBySearch,
	paginationWriterManaging: state.writerManaging.paginationUserManaging,
})

export default connect(mapStateToProps, {
	requestWriterManagingAction,
	getListWriterAction,
	requestWriterManagingErrorAction,
	toggleWriterExposureAction,
	toggleWriterIsSyncAction
})(WriterManaging)
