import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/Notice/notice2style";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "../../components/Gm-TextField/TextFieldForDatePicker";
import Box from "@material-ui/core/Box";
import TimePicker from "../Statistics/components/TimePicker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {FiberManualRecord} from "@material-ui/icons";
import Button from "../../components/CustomButtons/Button";
import CustomButton from "../../components/CustomButtons/Button";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import moment from "moment";
import Spinner from "../../components/Spinner/Spinner";
import eventsManagingApi from "../../api/eventsManagingApi";


import TagsInput from 'react-tagsinput';
//자동제안은 react suggest
import stylesModal from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import bannerManagingApi from "../../api/mainManaging/bannerManagingApi";
import Checkbox from "@material-ui/core/Checkbox";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MenuItem from "@material-ui/core/MenuItem";
import {API_URL} from "../../api/apiUrl";
import {dateTimeCheck} from "../../util/common";

const useStyles = makeStyles(styles)
const useStylesModal = makeStyles(stylesModal)

// 이벤트 상세임 아무튼 그럼
const EventsDetail = (props) => {

    const {
        match,
        eventsDetail,
    } = props

    const classes = useStyles()
    const classesModal = useStylesModal()


    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz

    const [type, setType] = React.useState(match?.params?.type)
    const [loading, setLoading] = React.useState(false)
    const [typeName, setTypeName] = React.useState(match?.params?.type == 'EVENT' ? '이벤트' : '챌린지')
    const [eventList, setEventList] = React.useState([])
    const [formData, setFormData] = React.useState({
        id: isNaN(match?.params?.id) ? 0 : match?.params?.id,
        title: eventsDetail != null ? eventsDetail.title : '',
        type: type,
        status: 'ACTIVE',
        startAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        endAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        checkStartAt: moment().format('YYYY-MM-DD'),
        checkEndAt: moment().format('YYYY-MM-DD'),
        fromTime: 0,
        toTime: 23,
        contentLinkType: 'URL',
        linkUrl: '',
        numOrder: 0,
        tags: [],
        files: '',
        files2: '',
        files3: '',
        primaryTag: type == 'EVENT' ? '' : '#꾸밍챌린지',
        isDuplication: false,
        contents: '',
        subType: null
    })
    const [imageFile, setImageFile] = React.useState(null)
    const [imageFile2, setImageFile2] = React.useState(null)
    const [imageFile3, setImageFile3] = React.useState(null)
    const [loadingBtn, setLoadingBtn] = React.useState(false)

    const eventsModel = {
        questionTitle: '',
        files: {}
    }
    const [eventVoteQuestionList, setEventVoteQuestionList] = React.useState([eventsModel])


    function getDatesBetween(startDate, endDate) {
        const dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            dateArray.push(currentDate.toISOString().split('T')[0]);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dateArray;
    }

    function filterQuestionLabel(index){

        if (type == 'EVENT'){

            if (formData.contentLinkType == 'BINGO'){
                return '빙고 항목' + ( index + 1) + " 이미지 사이즈: 178x148 ( 없을경우 기본 이미지)"
            }
            if (formData.contentLinkType == 'DAILY_CHECK'){
                return '출석체크 기간 조정하실 경우 자동으로 생성됩니다. ' + " 이미지 사이즈: 178x148 ( 없을경우 기본 이미지)"
            }
            if (formData.contentLinkType == 'MULTI'){
                return "개수 입력해주세요 ex: 10" + " 이미지 사이즈: 178x148 ( 없을경우 기본 이미지)"
            }



        }
        return '질문' + (index + 1)

    }


    const handleFormEventDetail = (value, key) => {
        //vue의 데이터 매핑 형태였던 것 같은데.... key도 매핑되네 히야...

        switch (key) {
            case 'startAt' :
                setFormData({
                    ...formData,
                    [key]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'endAt' :
                setFormData({
                    ...formData,
                    [key]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'isDuplication' :
                setFormData({
                    ...formData,
                    [key]: !formData.isDuplication,
                })
                break;
            case 'contentLinkType' :

                if (value == 'DAILY_CHECK' || value == 'MULTI'){

                    setFormData({
                        ...formData,
                        'contentLinkType': value,
                        'linkUrl': API_URL.split('/api/ADMIN/v1.5/')[0]  + '/eventPage',
                    })
                }else{
                    setFormData({
                        ...formData,
                        'contentLinkType': value,
                    })

                }


                break;
            case 'checkStartAt' :
                setFormData({
                    ...formData,
                    [key]: value,
                })

                if (formData.checkEndAt != null){
                    const datesBetween = getDatesBetween(value, formData.checkEndAt)
                    const eventVoteQuestionsist = []
                    datesBetween.map((item)=>{
                        eventVoteQuestionsist.push({
                            questionTitle: item,
                            files: {}
                        })
                    })
                    setEventVoteQuestionList(eventVoteQuestionsist)
                }
                break;
            case 'checkEndAt' :
                setFormData({
                    ...formData,
                    [key]: value,
                })

                if (formData.checkEndAt != null){
                    const datesBetween = getDatesBetween(formData.checkStartAt,value);
                    const eventVoteQuestionsist = []
                    datesBetween.map((item)=>{
                        eventVoteQuestionsist.push({
                            questionTitle: item,
                            files: {}
                        })
                    })
                    setEventVoteQuestionList(eventVoteQuestionsist)
                }
                break;
            default:
                setFormData({
                    ...formData,
                    [key]: value,
                })

        }
        //eventsId 사용시 챌린지
        if (key == 'eventsId'){
            eventList.map((item)=>{
                if (item.contentLinkType == 'BINGO' && item.id == value){
                    setFormData({
                        ...formData,
                        'eventsId': value,
                        'linkUrl': API_URL.split('/api/ADMIN/v1.5/')[0]  + '/eventPage?eventsId=' + value, //id=1243&
                    })
                }
            })
        }


    }
    const getListEvents = async (type) => {

        let orderString = '';

        //if (orderBy == 'first') {
        //처음 정렬시 현재 front에 나오는 표출 순서대로
        orderString = 'numOrder,DESC&createdAt,DESC';
        // } else {
        //   orderString = orderBy + ',' + order
        // }


        let searchType = 'CHALLENGE_BINGO'
        if (match.params?.type == 'CHALLENGE'){
            //챌린지일 경우 내용 연결타입 이벤트형 추가로 이벤트 리스트 조회
            searchType = 'EVENT'
        }


        let params = {
            limit: 10000,
            offset: 0,
            type: searchType,
            order: orderString,
            searchText: '',
            searchKey: 'title',
            endAt: 'more60day'
        }

        try {
            //requestEventsAction()
            const {data} = await eventsManagingApi.getListEvents(params)
            //getListEventsAction(data)
            setEventList(data.rows)
        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                console.log(error.response.data)
            }
        }
    }
    const handleFormEventDetailQuestionList = (value, key, index) => {

        let tempObject = eventVoteQuestionList[index];
        tempObject[key] = value;
        setEventVoteQuestionList([...eventVoteQuestionList.slice(0, index),
            tempObject,
            ...eventVoteQuestionList.slice(index + 1)]
        )
    }
    const handleFormEventDetailQuestionFile = async (file, index) => {
        //파일 저장해서 가져와진 fileidx를 input

        //이미지 cdn 업로드
        const fd = new FormData()

        fd.append('file', file, file.name)
        fd.append('type', 'SLIDE')
        const {
            data: {id: idImage, url, filename},
        } = await bannerManagingApi.uploadImage(fd); //banner라고 세팅했지만원본은 file api 로 통신


        let tempObject = eventVoteQuestionList[index];
        tempObject.files = {id: idImage, url, filename};
        setEventVoteQuestionList([...eventVoteQuestionList.slice(0, index),
            tempObject,
            ...eventVoteQuestionList.slice(index + 1)]
        )
    }

    const handleChangeTimePickerStart = (event) => {
        setFormData({...formData, fromTime: event.target.value})
    }
    const handleChangeTimePickerEnd = (event) => {
        setFormData({...formData, toTime: event.target.value})
    }
    const handleChangeFile = (e, type) => {
        //파일 Onchage 시 이미지 체인지


        if (type == 1) {
            if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0]
                const img = new Image()
                img.src = window.URL.createObjectURL(file)
                img.addEventListener('load', () => {
                    setImageFile(file) // 일단 바로 체크
                })
            }
        } else if(type == 2) {
            if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0]
                const img = new Image()
                img.src = window.URL.createObjectURL(file)
                img.addEventListener('load', () => {
                    setImageFile2(file) // 일단 바로 체크
                })
            }
        }else{
            if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0]
                const img = new Image()
                img.src = window.URL.createObjectURL(file)
                img.addEventListener('load', () => {
                    setImageFile3(file) // 일단 바로 체크
                })
            }

        }


    }


    //api 통신해서 db저장 이벤트 생성
    const createEventsManaging = async () => {


        try {


            //정규식 체크
            if (isNaN(formData.numOrder)) {
                //문자열일때
                alert("숫자 입력해주세요")
                return
            }

            //업데이트 일때, 하단은 Insert
            if (formData.id != 0) {
                const fd = new FormData()
                const fd2 = new FormData()
                const fd3 = new FormData()

                //업로드 안할시 Null임


                const body = {}

                if (imageFile == null) {
                    body.imageUrlId = formData.imageUrlId
                } else {
                    setLoadingBtn(true)
                    fd.append('file', imageFile, imageFile.name)
                    fd.append('type', 'SLIDE')
                    setLoadingBtn(true)
                    const {
                        data: {id: idImage, url, filename},
                    } = await bannerManagingApi.uploadImage(fd);
                    body.imageUrlId = idImage
                }

                if (imageFile2 == null) {
                    body.imageUrlId2 = formData.imageUrlId2
                } else {
                    fd2.append('file', imageFile2, imageFile2.name)
                    fd2.append('type', 'SLIDE')
                    const {
                        data: {id: idImage2, url2, filename2},
                    } = await bannerManagingApi.uploadImage(fd2); //banner라고 세팅했지만원본은 file api 로 통신
                    body.imageUrlId2 = idImage2
                }

                if (imageFile3 == null) {
                    body.imageUrlId3 = formData.imageUrlId3
                } else {
                    fd3.append('file', imageFile3, imageFile3.name)
                    fd3.append('type', 'SLIDE')
                    const {
                        data: {id: idImage3, url3, filename3},
                    } = await bannerManagingApi.uploadImage(fd3); //banner라고 세팅했지만원본은 file api 로 통신
                    body.imageUrlId3 = idImage3
                }


                body.title = formData.title
                body.type = formData.type
                body.startAt = new Date(moment(dateTimeCheck(formData.startAt, formData.fromTime)).format("YYYY-MM-DD HH:mm")).getTime()
                body.endAt = new Date(moment(dateTimeCheck(formData.endAt, formData.toTime)).format("YYYY-MM-DD HH:mm")).getTime()
                body.contentLinkType = formData.contentLinkType
                body.linkUrl = formData.linkUrl
                body.numOrder = parseInt(formData.numOrder)
                body.tags = formData.tags
                body.id = parseInt(isNaN(match?.params?.id) ? 0 : match?.params?.id)
                body.primaryTag = formData.primaryTag
                body.status = formData.status
                body.isDuplication = formData.isDuplication
                body.eventVoteQuestionList = eventVoteQuestionList
                body.contents = formData.contents
                body.subEventsId = formData?.subEventsId || null
                // setEventVoteQuestionList()

                const {data} = await eventsManagingApi.update(body);


                if (data || data[0] == 1) {
                    alert("수정이 완료되었습니다.")
                    // Router.push('/admin/event-managing/' + )
                    location.href = '/admin/event-managing/' + formData.type
                }
                return
            }

            //하단 부터 insert


            //이미지 cdn 업로드
            const fd = new FormData()
            const fd2 = new FormData()
            const fd3 = new FormData()

            if (imageFile == null) {
                //이미지 입력 체크
                alert("이미지를 첨부해주세요")
                return
            }


            setLoadingBtn(true)
            fd.append('file', imageFile, imageFile.name)
            fd.append('type', 'SLIDE')
            setLoadingBtn(true)
            const {
                data: {id: idImage, url, filename},
            } = await bannerManagingApi.uploadImage(fd); //banner라고 세팅했지만원본은 file api 로 통신


            //type이 이벤트일 경우
            //contentLinkType이 url webview !
            if (formData.contentLinkType != 'URL' && formData.contentLinkType != 'WEB_VIEW' &&
                !(formData.type == 'EVENT' && formData.contentLinkType == 'BINGO') && imageFile2 == null) {
                //이미지 입력 체크
                alert("이미지를 첨부해주세요")
                return
            }
            let idImage2 = null;
            if (imageFile2 != null) {

                fd2.append('file', imageFile2, imageFile2.name)
                fd2.append('type', 'SLIDE')

                const {
                    data: {id: idImage3, url2, filename2},
                } = await bannerManagingApi.uploadImage(fd2); //banner라고 세팅했지만원본은 file api 로 통신
                idImage2 = idImage3;
            }

            let idImage3 = null;
            if (imageFile3 != null) {

                fd3.append('file', imageFile3, imageFile3.name)
                fd3.append('type', 'SLIDE')

                const {
                    data: {id: idImage4, url2, filename2},
                } = await bannerManagingApi.uploadImage(fd3); //banner라고 세팅했지만원본은 file api 로 통신
                idImage3 = idImage4;
            }


            //이벤트 저장
            if (type == 'EVENT') {


                const body = {
                    title: formData.title,
                    type: formData.type,
                    startAt: new Date(moment(dateTimeCheck(formData.startAt, formData.fromTime)).format("YYYY-MM-DD HH:mm")).getTime(),
                    endAt: new Date(moment(dateTimeCheck(formData.endAt, formData.toTime)).format("YYYY-MM-DD HH:mm")).getTime(),
                    contentLinkType: formData.contentLinkType,
                    linkUrl: formData.linkUrl,
                    numOrder: parseInt(formData.numOrder),
                    imageUrlId: idImage,
                    imageUrlId2: idImage2 || null,
                    imageUrlId3: idImage3 || null,
                    status: formData.status,
                    isDuplication: formData.isDuplication || false,
                    contents: formData.contents,
                    eventVoteQuestionList, //투표문답형의 질문 테이블
                }

                const {data} = await eventsManagingApi.create(body);

                setLoadingBtn(false)


                if (data.id != null) {
                    alert("저장이 완료되었습니다.")
                    location.href = '/admin/event-managing/EVENT'
                } else {
                    alert("수정이 완료되었습니다.")
                    location.href = '/admin/event-managing/EVENT'
                }


            } else {
                const body = {
                    title: formData.title,
                    type: formData.type,
                    startAt: new Date(moment(dateTimeCheck(formData.startAt, formData.fromTime)).format("YYYY-MM-DD HH:mm")).getTime(),
                    endAt: new Date(moment(dateTimeCheck(formData.endAt, formData.toTime)).format("YYYY-MM-DD HH:mm")).getTime(),
                    contentLinkType: formData.contentLinkType,
                    linkUrl: formData.linkUrl,
                    numOrder: parseInt(formData.numOrder),
                    imageUrlId: idImage,
                    imageUrlId2: idImage2,
                    imageUrlId3: idImage3,
                    tags: formData.tags,
                    status: formData.status,
                    primaryTag: formData.primaryTag,
                    subEventsId: formData?.subEventsId || null,
                    eventVoteQuestionList, //투표문답형의 질문 테이블

                }
                const {data} = await eventsManagingApi.create(body);


                if (data.id != null) {
                    alert("저장이 완료되었습니다.")
                    location.href = '/admin/event-managing/CHALLENGE'
                } else {
                    alert("수정이 완료되었습니다.")
                    location.href = '/admin/event-managing/CHALLENGE'
                }
                setLoadingBtn(false)
            }


        } catch (e) {
            setLoadingBtn(false)
        }

    }
    const getEventData = async () => {
        try {


            setLoading(true)

            if (formData.id > 0) {
                //데이터가 있는 것
                //setLoading 필요

                let {data} = await eventsManagingApi.getEvents({id: parseInt(formData.id)});
                //데이터 매핑 dispatch쓰면 코드가 깔끔해지나 쓰지 않음
                data = data.events

                // 이건 굳이 안해도 될듯?
                // dispatch(getEventDetailAction(data))


                const tagsRe = []

                data.manage_tags?.forEach((item) => {
                    tagsRe.push(item.tagName)
                })

                data.type = data.type?.split('/')[0]
                // setFormData('')

                let eventsId = null
                if( (data.type == 'EVENT' && data.contentLinkType == 'BINGO')
                    || (data.type == 'EVENT' && data.contentLinkType == 'DAILY_CHECK')
                    || (data.type == 'EVENT' && data.contentLinkType == 'MULTI')
                ){
                    const regex = /[?&]eventsId=(\d+)/;
                    const aa = data?.linkUrl?.match(regex);
                    // setFormData({...formData, 'eventsId': })
                    if (aa?.length > 0){
                        eventsId = aa[1]
                        data.linkUrl = API_URL?.split('/api/ADMIN/v1.5/')[0]  + '/eventPage?eventsId=' + eventsId //id=1243&
                    }
                }


                if(data.type == 'CHALLENGE' && data.contentLinkType == 'BINGO'){
                    data.linkUrl = '' //저장할 때 이상하게 안들어가기 위해
                }

                //가져온데이터 다시 매핑
                setFormData({
                    // id: data.id, //이거 넣으면 useEffect의 파라미터때문에 무한루프
                    title: data.title,
                    type: data.type?.split('/')[0],
                    status: data.status,
                    startAt: moment(data.startAt).format('YYYY-MM-DD'),
                    endAt: moment(data.endAt).format('YYYY-MM-DD'),
                    fromTime: parseInt(moment(data.startAt).format('HH')),
                    toTime: parseInt(moment(data.endAt).format('HH')),
                    contentLinkType: data.contentLinkType,
                    linkUrl: data.linkUrl,
                    numOrder: data.numOrder,
                    files: data.files,
                    files2: data.files2,
                    files3: data.files3,
                    tags: tagsRe, //태그 넣을땐 일괄로 넣고 일괄로 삭제하는 방향으로
                    imageUrlId: data.imageUrlId,
                    imageUrlId2: data.imageUrlId2,
                    imageUrlId3: data.imageUrlId3,
                    primaryTag: data.primaryTag,
                    isDuplication: data.isDuplication || false,
                    contents: data.contents,
                    subEventsId: data.subEventsId,
                    eventsId
                })
                setEventVoteQuestionList(data.event_vote_questions);

                setType(data.type)
                setTypeName(data.type == 'EVENT' ? '이벤트' : '챌린지')
                setLoading(false)

            }
            setLoading(false)

        } catch (e) {

        }
    }

    let count = 0;
    React.useEffect(() => {
        //getData
        getEventData()
        getListEvents()
    }, [formData.id])

    // const handleTitle = (e)

    return (

        <div>

            {loading ? (<Spinner/>) : (<div>
                <Paper variant='outlined' className={classes.paper}>
                    {/*<GridContainer  className={classes.grid} alignItems='center'>*/}
                    {/*    <h3>*/}
                    {/*        비노출 일경우 리스트에 표현되지 않습니다.*/}
                    {/*    </h3>*/}
                    {/*</GridContainer>*/}
                    <GridContainer className={classes.grid} alignItems='center'>
                        {/*1*/}
                        <GridContainer className={classes.grid}>
                            <GridItem xs={6} sm={6} md={6}>
                                <CustomInput
                                    labelText={typeName + '이름'}
                                    id="float"
                                    inputProps={{
                                        onChange: (event) => {
                                            handleFormEventDetail(event.target.value, 'title')
                                        },
                                    }}
                                    value={formData.title}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    // onChange={handleTitle()}
                                />

                            </GridItem>
                            <GridItem xs={4} sm={4} md={4} className={classes.inputClass}>


                            </GridItem>
                            {/*공백용*/}
                            <GridItem xs={1} sm={1} md={1}/>
                        </GridContainer>
                        {/*2*/}
                        <GridContainer className={classes.grid}>
                            <GridItem xs={12} sm={12} md={12}>
                                <GridContainer>
                                    <GridItem className={classes.lableSt}
                                              xs={1} sm={1} md={1}
                                    >
                                        {typeName} 시작 기간
                                    </GridItem>
                                    <GridItem
                                        xs={2}
                                        sm={2}
                                        // className={classes.styleDatePicker}
                                    >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                variant='inline'
                                                TextFieldComponent={TextField}
                                                format='yyyy-MM-dd'
                                                id='date-picker-inline1'
                                                value={formData?.startAt}
                                                onChange={(date) =>
                                                    handleFormEventDetail(date, 'startAt')
                                                }
                                                autoOk={true}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </GridItem>
                                    <GridItem
                                        // className={classes.paddingLeft}
                                        xs={2}
                                        sm={2}
                                    >
                                        <Box>
                                            <TimePicker
                                                time={formData?.fromTime}
                                                handleChangeTimePicker={handleChangeTimePickerStart}
                                            />
                                        </Box>
                                    </GridItem>
                                    {/*공백용*/}
                                    {/*<GridItem xs={2} sm={2} md={2} />*/}
                                    <GridItem className={classes.lableSt}
                                              xs={1} sm={1} md={1}
                                    >
                                        {typeName} 종료 기간
                                    </GridItem>
                                    <GridItem
                                        xs={2}
                                        sm={2}
                                        // className={classes.styleDatePicker}
                                    >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                variant='inline'
                                                TextFieldComponent={TextField}
                                                format='yyyy-MM-dd'
                                                id='date-picker-inline1'
                                                value={formData?.endAt}
                                                onChange={(date) =>
                                                    handleFormEventDetail(date, 'endAt')
                                                }
                                                autoOk={true}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </GridItem>
                                    <GridItem
                                        // className={classes.paddingLeft}
                                        xs={2}
                                        sm={2}
                                    >
                                        <Box>
                                            <TimePicker
                                                time={formData?.toTime}
                                                handleChangeTimePicker={handleChangeTimePickerEnd}
                                                setKey='toTime'
                                            />
                                        </Box>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                        {/*3*/}
                        <GridContainer className={classes.grid}>
                            <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                                노출여부
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2}>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={formData.status === 'ACTIVE'}
                                            onChange={() => handleFormEventDetail('ACTIVE', "status")}
                                            value="true"
                                            name="radio button enabled"
                                            aria-label="T"
                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                            checkedIcon={
                                                <FiberManualRecord className={classes.radioChecked}/>
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label
                                    }}
                                    label="노출(활성화)"
                                />
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2}>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={formData.status == 'DEACTIVATED'}
                                            onChange={() => handleFormEventDetail('DEACTIVATED', "status")}
                                            value="false"
                                            name="radio button enabled"
                                            aria-label="F"
                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                            checkedIcon={
                                                <FiberManualRecord className={classes.radioChecked}/>
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label
                                    }}
                                    label="비노출(비활성화)"
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer className={classes.grid}>
                            <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                                참여유형
                            </GridItem>

                        </GridContainer>
                        {/*4*/}
                        <GridContainer className={classes.grid}>
                            <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                                내용 연결 타입
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={formData.contentLinkType === "WEB_VIEW"}
                                            onChange={() => handleFormEventDetail('WEB_VIEW', "contentLinkType")}
                                            value="WEB_VIEW"
                                            name="radio button enabled"
                                            aria-label="B"
                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                            checkedIcon={
                                                <FiberManualRecord className={classes.radioChecked}/>
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.lableSt
                                    }}
                                    label="링크형(내부)"
                                />
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={formData.contentLinkType === "URL"}
                                            onChange={() => handleFormEventDetail('URL', "contentLinkType")}
                                            value="URL"
                                            name="radio button enabled"
                                            aria-label="B"
                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                            checkedIcon={
                                                <FiberManualRecord className={classes.radioChecked}/>
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.lableSt
                                    }}
                                    label="링크형(외부)"
                                />
                            </GridItem>

                            {
                                formData.type == 'CHALLENGE' ? (
                                    <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={formData.contentLinkType === "BINGO"}
                                                    onChange={() => handleFormEventDetail('BINGO', "contentLinkType")}
                                                    value="BINGO"
                                                    name="radio button enabled"
                                                    aria-label="B"
                                                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                    checkedIcon={
                                                        <FiberManualRecord className={classes.radioChecked}/>
                                                    }
                                                    classes={{
                                                        checked: classes.radio,
                                                        root: classes.radioRoot
                                                    }}
                                                />
                                            }
                                            classes={{
                                                label: classes.lableSt
                                            }}
                                            label="빙고형"
                                        />
                                    </GridItem>
                                ) : null
                            }

                            {
                                formData.type == 'CHALLENGE' ? (
                                    <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={formData.contentLinkType === "EVENT"}
                                                    onChange={() => handleFormEventDetail('EVENT', "contentLinkType")}
                                                    value="EVENT"
                                                    name="radio button enabled"
                                                    aria-label="B"
                                                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                    checkedIcon={
                                                        <FiberManualRecord className={classes.radioChecked}/>
                                                    }
                                                    classes={{
                                                        checked: classes.radio,
                                                        root: classes.radioRoot
                                                    }}
                                                />
                                            }
                                            classes={{
                                                label: classes.lableSt
                                            }}
                                            label="이벤트형"
                                        />
                                    </GridItem>
                                ) : null
                            }
                            {
                                formData.type == 'EVENT' ? (
                                    <>
                                        <GridItem xs={2} sm={2} md={2}>
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={formData.contentLinkType === 'VOTE'}
                                                        onChange={() => handleFormEventDetail('VOTE', "contentLinkType")}
                                                        value="VOTE"
                                                        name="radio button enabled"
                                                        aria-label="T"
                                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                        checkedIcon={
                                                            <FiberManualRecord className={classes.radioChecked}/>
                                                        }
                                                        classes={{
                                                            checked: classes.radio,
                                                            root: classes.radioRoot
                                                        }}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label
                                                }}
                                                label="투표문답형"
                                            />
                                        </GridItem>
                                        <GridItem xs={2} sm={2} md={2}>
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={formData.contentLinkType == 'APPLY'}
                                                        onChange={() => handleFormEventDetail('APPLY', "contentLinkType")}
                                                        value="APPLY"
                                                        name="radio button enabled"
                                                        aria-label="F"
                                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                        checkedIcon={
                                                            <FiberManualRecord className={classes.radioChecked}/>
                                                        }
                                                        classes={{
                                                            checked: classes.radio,
                                                            root: classes.radioRoot
                                                        }}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label
                                                }}
                                                label="응모형"
                                            />
                                        </GridItem>
                                        <GridItem xs={2} sm={2} md={2}>
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={formData.contentLinkType == 'COMMENT'}
                                                        onChange={() => handleFormEventDetail('COMMENT', "contentLinkType")}
                                                        value="COMMENT"
                                                        name="radio button enabled"
                                                        aria-label="F"
                                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                        checkedIcon={
                                                            <FiberManualRecord className={classes.radioChecked}/>
                                                        }
                                                        classes={{
                                                            checked: classes.radio,
                                                            root: classes.radioRoot
                                                        }}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label
                                                }}
                                                label="댓글형"
                                            />
                                        </GridItem>
                                        <GridItem xs={1} sm={1} md={1}></GridItem>
                                        <GridItem xs={1} sm={1} md={1}></GridItem>
                                        <GridItem xs={2} sm={2} md={2}>
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={formData.contentLinkType == 'BINGO'}
                                                        onChange={() => handleFormEventDetail('BINGO', "contentLinkType")}
                                                        value="COMMENT"
                                                        name="radio button enabled"
                                                        aria-label="F"
                                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                        checkedIcon={
                                                            <FiberManualRecord className={classes.radioChecked}/>
                                                        }
                                                        classes={{
                                                            checked: classes.radio,
                                                            root: classes.radioRoot
                                                        }}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label
                                                }}
                                                label="빙고형"
                                            />
                                        </GridItem>
                                        <GridItem xs={2} sm={2} md={2}>
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={formData.contentLinkType == 'DAILY_CHECK'}
                                                        onChange={() => handleFormEventDetail('DAILY_CHECK', "contentLinkType")}
                                                        value="COMMENT"
                                                        name="radio button enabled"
                                                        aria-label="F"
                                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                        checkedIcon={
                                                            <FiberManualRecord className={classes.radioChecked}/>
                                                        }
                                                        classes={{
                                                            checked: classes.radio,
                                                            root: classes.radioRoot
                                                        }}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label
                                                }}
                                                label="출석체크형"
                                            />
                                        </GridItem>
                                        <GridItem xs={2} sm={2} md={2}>
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={formData.contentLinkType == 'MULTI'}
                                                        onChange={() => handleFormEventDetail('MULTI', "contentLinkType")}
                                                        value="POST_N"
                                                        name="radio button enabled"
                                                        aria-label="F"
                                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                                        checkedIcon={
                                                            <FiberManualRecord className={classes.radioChecked}/>
                                                        }
                                                        classes={{
                                                            checked: classes.radio,
                                                            root: classes.radioRoot
                                                        }}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label
                                                }}
                                                label="혼합형"
                                            />
                                        </GridItem>
                                    </>
                                ) : null
                            }



                            <GridItem xs={6} sm={6} md={6}/>
                            <GridItem xs={2} sm={2} md={2}/>



                            {
                                formData.contentLinkType == 'DAILY_CHECK' && (
                                    <GridContainer className={classes.grid}>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <GridContainer>
                                                <GridItem className={classes.lableSt}
                                                          xs={1} sm={1} md={1}
                                                >
                                                    출석체크 시작 기간
                                                </GridItem>
                                                <GridItem
                                                    xs={2}
                                                    sm={2}
                                                    // className={classes.styleDatePicker}
                                                >
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            variant='inline'
                                                            TextFieldComponent={TextField}
                                                            format='yyyy-MM-dd'
                                                            id='date-picker-inline1'
                                                            value={formData?.checkStartAt}
                                                            onChange={(date) =>
                                                                handleFormEventDetail(date, 'checkStartAt')
                                                            }
                                                            autoOk={true}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </GridItem>
                                                {/*공백용*/}
                                                {/*<GridItem xs={2} sm={2} md={2} />*/}
                                                <GridItem className={classes.lableSt}
                                                          xs={1} sm={1} md={1}
                                                >
                                                    출석체크 종료 기간
                                                </GridItem>
                                                <GridItem
                                                    xs={2}
                                                    sm={2}
                                                    // className={classes.styleDatePicker}
                                                >
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            variant='inline'
                                                            TextFieldComponent={TextField}
                                                            format='yyyy-MM-dd'
                                                            id='date-picker-inline1'
                                                            value={formData?.checkEndAt}
                                                            onChange={(date) =>
                                                                handleFormEventDetail(date, 'checkEndAt')
                                                            }
                                                            autoOk={true}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                )
                            }
                            {
                                formData.contentLinkType == 'EVENT' && (
                                    <GridContainer className={classes.grid}>
                                        <GridItem xs={12} sm={12} md={12}>
                                            {/*selectbox event 선택*/}

                                            <TextField
                                                id='banner-managing-select'
                                                select
                                                size='small'
                                                style={{'width': '100%'}}
                                                className={classes.linkTypeSelect}
                                                value={formData?.subEventsId}
                                                label='연결할 이벤트를 선택해주세요(최근 60일)'
                                                onChange={(e) => {
                                                    handleFormEventDetail(e.target.value, 'subEventsId')
                                                }}
                                            >
                                                {
                                                    eventList == null ?
                                                        <MenuItem>등록된 이벤트리스트가 없습니다!</MenuItem>
                                                        : eventList.map((event, i) =>
                                                            (event.contentLinkType == 'COMMENT' || event.contentLinkType == 'URL' ||
                                                            event.contentLinkType == 'WEB_VIEW') &&
                                                            <MenuItem value={event.id}>{event.title}</MenuItem>


                                                        )
                                                }
                                            </TextField>
                                        </GridItem>
                                    </GridContainer>
                                )
                            }
                            <GridItem xs={12} sm={12} md={12} className={classes.border}>
                                {
                                    formData.contentLinkType == 'URL' || formData.contentLinkType == 'WEB_VIEW' ? (
                                        <CustomInput
                                            labelText="URL"
                                            id="float"
                                            inputProps={{
                                                onChange: (event) => {
                                                    handleFormEventDetail(event.target.value, 'linkUrl')
                                                },
                                            }}
                                            value={formData.linkUrl}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    ) : null
                                }

                                {
                                    formData.contentLinkType == 'VOTE'
                                    ||  formData.contentLinkType == 'DAILY_CHECK'
                                    ||  formData.contentLinkType == 'MULTI'
                                        ? (
                                        <div>
                                            <Box className='add-list' display='flex' justifyContent='left'>





                                                <CustomButton color="default" mr={100} variant="contained"
                                                              onClick={(event) => {
                                                                  setEventVoteQuestionList([
                                                                      ...eventVoteQuestionList,
                                                                      {
                                                                          questionTitle: '',
                                                                          type: null,
                                                                          files: {}
                                                                      }
                                                                  ])
                                                              }}
                                                >질문 추가</CustomButton>


                                                <div className={classes.lableSt} style={{'margin-left': '10px'}}>
                                                    중복 체크 가능여부
                                                </div>
                                                <Checkbox
                                                    onClick={(event) => handleFormEventDetail(event.target.value, 'isDuplication')}
                                                    checked={formData.isDuplication == true ? true : false}
                                                    // inputProps={{ 'aria-labelledby': 1 }}
                                                />


                                            </Box>

                                            {eventVoteQuestionList.map((row, item) => {

                                                return (


                                                    <GridContainer alignItems='center'>
                                                        {
                                                            formData.contentLinkType == 'MULTI' && (<GridItem
                                                                className={classes.gridContainerOne}
                                                                xs={2} sm={2} md={2}
                                                            >
                                                                <TextField
                                                                    id='user-managing-select'
                                                                    select
                                                                    size='small'
                                                                    className={classes.textFieldOne}
                                                                    value={row.type}
                                                                    onChange={(e)=>{
                                                                        handleFormEventDetailQuestionList(e.target.value, 'type', item)
                                                                    }}
                                                                >
                                                                    <MenuItem value={'COMMENT'}>댓글</MenuItem>
                                                                    <MenuItem value={'LIKE'}>좋아요</MenuItem>
                                                                    <MenuItem value={'UPLOAD_FEED'}>게시글 꾸밍뷰-꾸미기</MenuItem>
                                                                    <MenuItem value={'UPLOAD_STAR'}>게시글 꾸밍뷰-일러스트</MenuItem>
                                                                    <MenuItem value={'UPLOAD_ETC'}>게시글 커뮤니티-자유잡담</MenuItem>
                                                                    <MenuItem value={'UPLOAD_VOTE'}>게시글 커뮤니티-투표문답</MenuItem>
                                                                    {/*<MenuItem value={'ISSUE'}>커뮤니티-투표문답</MenuItem>*/}
                                                                </TextField>
                                                            </GridItem>)}
                                                        <GridItem xs={6} sm={6} md={6}>
                                                            <CustomInput
                                                                labelText={
                                                                    filterQuestionLabel(item)}
                                                                id="float"
                                                                inputProps={{
                                                                    onChange: (event) => {

                                                                        handleFormEventDetailQuestionList(event.target.value, 'questionTitle', item)
                                                                    }
                                                                }}
                                                                value={row.questionTitle}
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                            />
                                                        </GridItem>
                                                        <GridItem xs={4} sm={4} md={4}>
                                                            <input
                                                                accept='image/*'
                                                                className={classes.inputBtnUpload}
                                                                id={'contained-button-file-' + item}
                                                                type='file'
                                                                onClick={(event => {
                                                                    event.target.value = ''
                                                                })}
                                                                onChange={(e) => {
                                                                    //1차로 서버에 저장
                                                                    //2차로 해당 이벤트와 연결되어서 저장
                                                                    //
                                                                    // if (e.target.files && e.target.files.length > 0) {
                                                                    //     const file = e.target.files[0]
                                                                    //     const img = new Image()
                                                                    //     img.src = window.URL.createObjectURL(file)
                                                                    //     img.addEventListener('load', () => {
                                                                    //
                                                                    //         // setImageFile2(file)
                                                                    //         // validateUploadImage(file, 'size') ||
                                                                    //         // (!validateUploadImage(img, 'widthAndHeight') && setImageFile(file))
                                                                    //     })
                                                                    // }



                                                                    if (e.target.files && e.target.files.length > 0) {
                                                                        const file = e.target.files[0]
                                                                        const img = new Image()
                                                                        img.src = window.URL.createObjectURL(file)
                                                                        // img.addEventListener('load', (e) => {
                                                                        handleFormEventDetailQuestionFile(file, item)
                                                                        // validateUploadImage(file, 'size') ||
                                                                        // (!validateUploadImage(img, 'widthAndHeight') && setImageFile(file))
                                                                        // })
                                                                    }

                                                                }}
                                                            />
                                                            <label htmlFor={'contained-button-file-' + item}>
                                                                <Button
                                                                    variant='outlined'
                                                                    color='primary'
                                                                    component='span'
                                                                    fullWidth={true}
                                                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                                                >
                                                                    {row.files
                                                                        ? row.files.filename
                                                                        : ' 이미지를 첨부하세요'}
                                                                </Button>
                                                            </label>
                                                        </GridItem>
                                                        <GridItem xs={2} sm={2} md={2}>
                                                            {
                                                                row?.files != null ? <img style={{'max-width': '200px'}}
                                                                                          src={row?.files?.url}/> : null
                                                            }
                                                        </GridItem>


                                                    </GridContainer>
                                                )
                                            })
                                            }
                                        </div>
                                    ) : null
                                }


                                {
                                    formData.contentLinkType == 'BINGO' && formData.type == 'CHALLENGE' ? (
                                        <div>
                                            <Box className='add-list' display='flex' justifyContent='left'>

                                                <CustomButton color="default" mr={100} variant="contained"
                                                              onClick={(event) => {
                                                                  setEventVoteQuestionList([
                                                                      ...eventVoteQuestionList,
                                                                      {
                                                                          questionTitle: '',
                                                                          type: "BINGO",
                                                                          files: {}
                                                                      }
                                                                  ])
                                                              }}
                                                >항목 추가</CustomButton>


                                                {/*<div className={classes.lableSt} style={{'margin-left': '10px'}}>
                                                    중복 체크 가능여부
                                                </div>
                                                <Checkbox
                                                    onClick={(event) => handleFormEventDetail(event.target.value, 'isDuplication')}
                                                    checked={formData.isDuplication == true ? true : false}
                                                    // inputProps={{ 'aria-labelledby': 1 }}
                                                />*/}


                                            </Box>

                                            {eventVoteQuestionList.map((row, item) => {

                                                return (


                                                    <GridContainer alignItems='center'>

                                                        <GridItem xs={6} sm={6} md={6}>
                                                            <CustomInput
                                                                labelText={
                                                                    filterQuestionLabel(item)
                                                                }
                                                                id="float"
                                                                inputProps={{
                                                                    onChange: (event) => {

                                                                        handleFormEventDetailQuestionList(event.target.value, 'questionTitle', item)
                                                                    }
                                                                }}
                                                                value={row.questionTitle}
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                            />
                                                        </GridItem>
                                                        <GridItem xs={4} sm={4} md={4}>
                                                            <input
                                                                accept='image/*'
                                                                className={classes.inputBtnUpload}
                                                                id={'contained-button-file-' + item}
                                                                type='file'
                                                                onClick={(event => {
                                                                    event.target.value = ''
                                                                })}
                                                                onChange={(e) => {
                                                                    //1차로 서버에 저장
                                                                    //2차로 해당 이벤트와 연결되어서 저장
                                                                    //
                                                                    // if (e.target.files && e.target.files.length > 0) {
                                                                    //     const file = e.target.files[0]
                                                                    //     const img = new Image()
                                                                    //     img.src = window.URL.createObjectURL(file)
                                                                    //     img.addEventListener('load', () => {
                                                                    //
                                                                    //         // setImageFile2(file)
                                                                    //         // validateUploadImage(file, 'size') ||
                                                                    //         // (!validateUploadImage(img, 'widthAndHeight') && setImageFile(file))
                                                                    //     })
                                                                    // }



                                                                    if (e.target.files && e.target.files.length > 0) {
                                                                        const file = e.target.files[0]
                                                                        const img = new Image()
                                                                        img.src = window.URL.createObjectURL(file)
                                                                        // img.addEventListener('load', (e) => {
                                                                        handleFormEventDetailQuestionFile(file, item)
                                                                        // validateUploadImage(file, 'size') ||
                                                                        // (!validateUploadImage(img, 'widthAndHeight') && setImageFile(file))
                                                                        // })
                                                                    }

                                                                }}
                                                            />
                                                            <label htmlFor={'contained-button-file-' + item}>
                                                                <Button
                                                                    variant='outlined'
                                                                    color='primary'
                                                                    component='span'
                                                                    fullWidth={true}
                                                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                                                >
                                                                    {row.files
                                                                        ? row.files.filename
                                                                        : ' 이미지를 첨부하세요'}
                                                                </Button>
                                                            </label>
                                                        </GridItem>
                                                        <GridItem xs={2} sm={2} md={2}>
                                                            {
                                                                row?.files != null ? <img style={{'max-width': '200px'}}
                                                                                          src={row?.files?.url}/> : null
                                                            }
                                                        </GridItem>


                                                    </GridContainer>
                                                )
                                            })
                                            }
                                        </div>
                                    ) : null
                                }

                                {
                                    formData.contentLinkType == 'BINGO' && formData.type == 'EVENT' ? (
                                        <GridItem xs={4} sm={4} md={4}>
                                            <TextField
                                                id='banner-managing-select'
                                                select
                                                size='small'
                                                style={{'width': '100%'}}
                                                className={classes.linkTypeSelect}
                                                value={formData.eventsId}
                                                label='챌린지를 선택해주세요'
                                                onChange={(e) => {
                                                    handleFormEventDetail(e.target.value, 'eventsId')
                                                }}
                                            >
                                                {
                                                    eventList == null ?
                                                        <MenuItem>등록된 첼린지가 없습니다!</MenuItem>
                                                        : eventList.map((event, i) =>
                                                            <MenuItem value={event.id}>{event.title}</MenuItem>
                                                        )
                                                }
                                            </TextField>
                                        </GridItem>
                                    ) : null
                                }

                            </GridItem>


                        </GridContainer>
                        {/*5*/}
                        <GridContainer className={classes.grid}>
                            <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                                {typeName} 배너 <br/> (리스트)
                                {/*챌린지의 경우 메인페이지의 배너에 리스트 이미지가 나오고, 상세일 경우 상세 배너가 나온다.*/}
                                {/*이벤트의 경우 지금 배너랑 연결되기때문에 나오고 있지 않음*/}
                            </GridItem>
                            <GridItem xs={6} sm={6} md={5} lg={3} xl={3}>
                                <input
                                    accept='image/*'
                                    className={classes.inputBtnUpload}
                                    id='contained-button-file'
                                    type='file'
                                    onClick={(event => {
                                        event.target.value = ''
                                    })}
                                    onChange={(e) => {
                                        handleChangeFile(e, 1)
                                    }}
                                />
                                <label htmlFor='contained-button-file'>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        component='span'
                                        fullWidth={true}
                                        startIcon={<AddCircleOutlineOutlinedIcon/>}
                                    >
                                        {imageFile
                                            ? imageFile.name
                                            : typeName + ' 이미지를 첨부하세요'}
                                    </Button>
                                </label>
                                {
                                    formData?.files != null ? <img src={formData?.files?.url}/> : null
                                }

                            </GridItem>
                        </GridContainer>
                        {
                            (formData.contentLinkType != 'URL' && formData.contentLinkType != 'WEB_VIEW')
                                && !(formData.type == 'EVENT' && formData.contentLinkType == 'BINGO')
                            || formData.type == 'CHALLENGE'

                                ? (
                                <GridContainer className={classes.grid}>
                                    <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                                        {typeName} 배너 <br/> (상세)
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={5} lg={3} xl={3}>
                                        <input
                                            accept='image/*'
                                            className={classes.inputBtnUpload}
                                            id='contained-button-file2'
                                            type='file'
                                            onClick={(event => {
                                                event.target.value = ''
                                            })}
                                            onChange={(e) => {
                                                handleChangeFile(e, 2)
                                            }}
                                        />
                                        <label htmlFor='contained-button-file2'>
                                            <Button
                                                variant='outlined'
                                                color='primary'
                                                component='span'
                                                fullWidth={true}
                                                startIcon={<AddCircleOutlineOutlinedIcon/>}
                                            >
                                                {imageFile2 != null
                                                    ? imageFile2.name
                                                    : typeName + ' 이미지를 첨부하세요'}
                                            </Button>
                                        </label>
                                        {
                                            formData?.files2 != null ? <img src={formData?.files2?.url}/> : null
                                        }

                                        {/*<img src={formData?.files2?.url}/>*/}
                                    </GridItem>
                                </GridContainer>
                            ) : null
                        }
                        {
                            type != 'EVENT' ? (
                                <GridContainer className={classes.grid}>
                                    <GridItem xs={1} sm={1} md={1} className={classes.lableSt2}>
                                        챌린지 태그
                                    </GridItem>
                                    <GridItem xs={4} sm={4} md={4} className={classes.tagInput}>
                                        <TagsInput
                                            // className={classes.tagInput}
                                            value={formData.tags}
                                            onChange={(e) => {
                                                handleFormEventDetail(e, 'tags')
                                            }}
                                            tagProps={{className: "react-tagsinput-tag "}}
                                        />
                                    </GridItem>
                                    <GridItem xs={1} sm={1} md={1} className={classes.lableSt2}>
                                        필수 태그
                                    </GridItem>
                                    <GridItem xs={2} sm={2} md={2}>
                                        <TextField className={classes.pt20}
                                                   value={formData.primaryTag}
                                                   onChange={(e) => {
                                                       handleFormEventDetail(e.target.value, 'primaryTag')
                                                   }}/>
                                    </GridItem>
                                </GridContainer>) : null
                        }
                        {
                            (formData.contentLinkType == 'BINGO') && formData.type == 'CHALLENGE' ? (
                                <GridContainer className={classes.grid}>
                                    <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                                        {typeName} 배너 <br/> (내부)
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={5} lg={3} xl={3}>
                                        <input
                                            accept='image/*'
                                            className={classes.inputBtnUpload}
                                            id='contained-button-file3'
                                            type='file'
                                            onClick={(event => {
                                                event.target.value = ''
                                            })}
                                            onChange={(e) => {
                                                handleChangeFile(e, 3)
                                            }}
                                        />
                                        <label htmlFor='contained-button-file3'>
                                            <Button
                                                variant='outlined'
                                                color='primary'
                                                component='span'
                                                fullWidth={true}
                                                startIcon={<AddCircleOutlineOutlinedIcon/>}
                                            >
                                                {imageFile3 != null
                                                    ? imageFile3.name
                                                    : typeName + ' 이미지를 첨부하세요'}
                                            </Button>
                                        </label>
                                        {
                                            formData?.files3 != null ? <img src={formData?.files3?.url}/> : null
                                        }

                                        {/*<img src={formData?.files2?.url}/>*/}
                                    </GridItem>
                                </GridContainer>
                            ) : null
                        }

                        <GridContainer className={classes.grid}>
                            <GridItem xs={1} sm={1} md={1} className={classes.lableSt2}>
                                {typeName} 노출 순서
                            </GridItem>
                            <GridItem xs={2} sm={2} md={2}>
                                <TextField className={classes.pt20}
                                           value={formData.numOrder}
                                           onChange={(e) => {
                                               handleFormEventDetail(e.target.value, 'numOrder')
                                           }}
                                           inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}/>
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4} className={classes.lableSt2}>
                                노출순서가 높을수록 먼저 표출됩니다. 노출순서가 같을 경우는 최신순으로 표출됩니다.
                            </GridItem>
                        </GridContainer>

                        {
                            type == 'EVENT' ? (
                                <GridContainer className={classes.grid} style={{'margin-top': '10px'}}>
                                    <GridItem xs={1} sm={1} md={1} className={classes.lableSt2}>
                                        {typeName} 내용
                                    </GridItem>
                                    <GridItem xs={11} sm={11} md={11}>
                                        <Box className='notice-detail'>
                                            <TextareaAutosize
                                                style={{'width': '100%'}}
                                                className={classes.textareaAutosize}
                                                minRows={5}
                                                maxRows={9}
                                                aria-label='maximum height'
                                                placeholder='내용 입력'
                                                value={formData.contents}
                                                onChange={(e) => {
                                                    handleFormEventDetail(e.target.value, 'contents')
                                                }}
                                                name='contents'
                                            />
                                        </Box>
                                    </GridItem>
                                </GridContainer>
                            ) : null}

                    </GridContainer>
                </Paper>


                <Box className='add-list' display='flex' justifyContent='center'>


                    {loadingBtn ? (
                        <Spinner/>
                    ) : (
                        <CustomButton color="primary" mr={2} variant="contained"
                                      onClick={createEventsManaging}
                        >저장</CustomButton>)
                    }
                    <CustomButton color="default" mr={2} variant="contained"
                                  onClick={(event) => history.back()}
                    >취소</CustomButton>

                </Box>
            </div>)}


        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        // userDetail: state.events.eventsDetail,
        loading: state.eventsReducer.loading,
        eventsDetail: state.eventsReducer.eventsDetail,
        events: state.eventsReducer.events,
        metaData: state.eventsReducer.metaData,
    }
}

export default connect(mapStateToProps, {
    // requestNoticeAction,
    // getListNoticesAction,
    // noticesWithErrAction,
})(EventsDetail)
