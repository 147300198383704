import { primaryColor } from 'assets/jss/material-dashboard-pro-react.js'

const tagManaging = (theme) => ({
  paper: {
    width: '100%',
    padding: '0rem',
    marginBottom: theme.spacing(1),
  },
  symBolTag: {
    '& p': {
      fontSize: '24px',
      fontWeight: '700',
      color: '#222',
    },
  },
  textFieldControl: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    '& div': {
      // '& input': {
      //   textAlign: 'center',
      // },
      '& fieldset': {
        border: 'none',
      },
    },
    // textAlign: 'left'
  },
  textFlex: {
    // '& div' : {
      '& .MuiFormControl-root': {
        flexDirection: 'unset !important'
      }
    // }

  },
  center: {
    '& input': {
      textAlign : 'center'
    }

  },
  marginAuto: {
    margin: 'auto',
    textAlign: 'center'

  },
  textFieldAddTag: {
    '& div': {
      '& input': {
        textAlign: 'center',
      },
    },
  },
  customStyleBtn: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    '& button': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  },
  notNow: {
    // backgroundColor: 'grey'
    textDecoration: 'line-through'
  }
})

export default tagManaging
