const postInfoManaging = (theme) => ({

    groupBtnDropdown: {
        boxShadow: 'unset',
    },
    test: {
        margin: 0
    },

    paperCommon: {
        padding: theme.spacing(2),
    },

    inputTextAdd__textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: theme.spacing(1),
        },
    },

    setPositionRelative: {
        position: 'relative',
    },
    bttonGroup:{
        margin: 0,
        marginLeft: 7
    },
    formLabel:{
        marginBottom: 10
    }


})

export default postInfoManaging