import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeStyles } from '@material-ui/core/styles'

import stylesAlert from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import {el} from "date-fns/locale";
const useStylesModal = makeStyles(stylesAlert)

const Modal = (props) => {
  const classesAlert = useStylesModal()

  const {
      hideAlert,
      postIds,
      postInfoId,
      postManagingApi,
      deletePostAction,
      movePostAction,
      movePostPushAction,
      postManagingErrAction,
      handleMovePushPosts,
      setSelected,
      type
  } = props

  const handleDeletePost = async () => {
    try {
      deletePostAction(postIds)
      hideAlert()
      setSelected([])

      await postManagingApi.delete({ postIds })
        //급한대로 그냥 alert창 띄우기
    if (confirm("해당 유저에게 PUSH 알림을 보내시겠습니까?")){
        await postManagingApi.deletePush({ postIds })
    }




    } catch (error) {
      console.error(error)
      postManagingErrAction(error?.response?.data)
    }
  }
  /*const handleMovePushPosts = async () => {
    try {
        alert("handleMovePushPosts")
    } catch (error) {
      console.error(error)
      postManagingErrAction(error?.response?.data)
    }
  }*/
  const filter = ( type)=>{
      if(type == 'M'){
          return '이동'
      }else if(type == 'D'){
          return  '삭제'
      }else{
          return  '보내기'
      }
  }
  const filterEvent = (e, type)=>{
      if(type == 'M'){
          handleMovePosts(e)
      }else if(type == 'D'){
          handleDeletePost(e )
      }else{
          handleMovePushPosts(e)
      }
  }

  const handleMovePosts = async () => {
    try {

      movePostAction({postIds, postInfoId})

      //push 여부 물어보기 / push ok면 푸시 관리 생성
      // movePostPushAction({postIds, postInfoId})
      await postManagingApi.move({ postIds, postInfoId })

        hideAlert()
        setSelected([])

      //급한대로 그냥 alert창 띄우기
      if (confirm("해당 유저에게 PUSH 알림을 보내시겠습니까?")){
          await postManagingApi.movePush({ postIds, postInfoId })
      }

    } catch (error) {
      console.error(error)
      postManagingErrAction(error?.response?.data)
    }
  }

  return (
    <SweetAlert
      warning
      style={{ display: 'block', marginTop: '-100px' }}
      title={type == 'D' ? '삭제하시겠습니까?' : '이동하시겠습니까?'}
      onConfirm={(e)=>{
          filterEvent(e, type)}}
      onCancel={hideAlert}
      confirmBtnCssClass={classesAlert.button + ' ' + classesAlert.success}
      cancelBtnCssClass={classesAlert.button + ' ' + classesAlert.danger}
      confirmBtnText={filter(type)}
      cancelBtnText='취소'
      showCancel
    />
  )
}

export default Modal
