import * as actionTypes from './types'

export const requestVersionManagingAction = () => {
    return {
        type: actionTypes.REQUEST_VERSION_MANAGING
    }
}

export const getListVersionAction = (payload) => {
    return {
        type: actionTypes.GET_LIST_VERSION,
        payload: payload,
    }
}

export const requestVersionErrorAction = (payload) => {
    return {
        type: actionTypes.REQUEST_VERSION_ERROR,
        payload: payload,
    }
}
