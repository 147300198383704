import React from 'react'

import {makeStyles} from '@material-ui/core/styles'
import TextField from 'components/Gm-TextField/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import GridContainer from 'components/Grid/GridContainer.js'
import InputAdornment from '@material-ui/core/InputAdornment'
import Box from '@material-ui/core/Box'
import SearchIcon from '@material-ui/icons/Search'
import GridItem from 'components/Grid/GridItem.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from 'components/CustomButtons/Button.js'
import Table from './components/WriterSchedulesTable.js'

import {connect} from 'react-redux'
import {
	getListWriterSchedulesAction,
	requestWriterSchedulesManagingAction,
	requestWriterSchedulesManagingErrorAction,
} from 'redux/actions/writerSchedulesManagingAction'
import writerScheduleManagingApi from 'api/writerScheduleManagingApi'
import writerManagingApi from 'api/writerManagingApi'

import styles from 'assets/jss/material-dashboard-pro-react/views/UserManaging/userManaging'

const useStyles = makeStyles(styles)

const WriterSchedulesManaging = (props) => {
	const {
		requestWriterSchedulesManagingAction,
		getListWriterSchedulesAction,
		requestWriterSchedulesManagingErrorAction,
		loading,
		schedules,
		totalWriterSchedule,
		totalWriterScheduleBySearch,
		metaData: { totalRecords },
	} = props

	const fileInput = React.createRef()
	const imgInput = React.createRef()
	const classes = useStyles()

	const [loadingBtnGetExcel, setLoadingBtnGetExcel] = React.useState(false)
	const [select, setSelect] = React.useState('NORMAL')
	const [order, setOrder] = React.useState('desc')
	const [orderBy, setOrderBy] = React.useState('')
	const [clientId, setClientId] = React.useState('')
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [isResetPagination, setIsResetPagination] = React.useState(false)
	const [page, setPage] = React.useState(0)
	const [searchKey, setSearchKey] = React.useState('clientId')
	const [searchText, setSearchText] = React.useState('')

	const handleChangeSearchInput = (e) => {
		setClientId(e.target.value)
		!isResetPagination && setIsResetPagination(true)
	}

	const handleChangeSelect = (event) => {
		setSelect(event.target.value)
		!isResetPagination && setIsResetPagination(true)
	}

	const headCells = [
		{
			id: 'nickname',
			numeric: true,
			disablePadding: false,
			label: '닉네임',
			allowSortable: true,
		},
		{
			id: 'img',
			numeric: true,
			disablePadding: false,
			label: '이미지',
			allowSortable: true,
		},
		{
			id: 'category',
			numeric: true,
			disablePadding: false,
			label: '카테고리',
			allowSortable: true,
		},
		{
			id: 'title',
			numeric: true,
			disablePadding: false,
			label: '제목',
			allowSortable: true,
		},
		/*{
			id: 'content',
			numeric: true,
			disablePadding: false,
			label: '내용',
			allowSortable: false,
		},*/
		{
			id: 'linkUrl',
			numeric: true,
			disablePadding: false,
			label: '링크',
			allowSortable: false,
		},
		{
			id: 'startedAt',
			numeric: true,
			disablePadding: false,
			label: '시작일',
			allowSortable: true,
		},
		{
			id: 'endedAt',
			numeric: true,
			disablePadding: false,
			label: '종료일',
			allowSortable: true,
		},
		{
			id: 'id',
			numeric: true,
			disablePadding: false,
			label: '등록일',
			allowSortable: true,
		}
	]
	const getListWriterSchedules = async () => {
		let orderString = '';

		if (orderBy == 'first') {
			//처음 정렬시 현재 front에 나오는 표출 순서대로
			orderString = 'numOrder,DESC&createdAt,DESC';
		} else {
			orderString = orderBy + ',' + order
		}

		try {
			requestWriterSchedulesManagingAction()
			const params = {
				limit: rowsPerPage,
				offset: page + 1,
				order: order,
				orderBy: orderBy,
				searchKey: searchKey,
				searchText: clientId
			}


			const { data } = await writerScheduleManagingApi.getListSchedule(params)

			getListWriterSchedulesAction(data)

		} catch (error) {
			if (error && error.response && error.response.data) {
				requestWriterSchedulesManagingErrorAction(error.response.data)
			}
		}
	}

	const handleSearchKey = (value) => {
		// setRowsPerPage( valuwe)
		// dispatch(setSearchKey( valuwe));
		setSearchKey(value)
		setOrder('asc')
	}

	const handleButtonClick = async e => {
		fileInput.current.click();
	}

	const handleButtonClickImg = async e => {
		imgInput.current.click();
	}

	const handleChangeImg = async e => {
		const files = e.target.files;
		const formData = new FormData()

		for(let i=0; i<files.length; i++) {
			formData.append('files',  e.target.files[i])
		}
		formData.append('type', 'SCHEDULE')

		const { data } = await writerManagingApi.saveImageFile(formData)

		if (data){
			// alert("파일 업로드가 완료되었습니다.")
			alert(data.messages)
		}

	}

	const handleChange = async e => {
		try {
			const file = e.target.files[0];
			const fd = new FormData()
			fd.append('xlsx', file, file.name)
			fd.append('type', 'xlsx')

			const { data } = await writerScheduleManagingApi.setExcelFile(fd)

			getListWriterSchedules()

		} catch (error) {
			console.log(error.response)
			if (error && error.response && error.response.data) {
			}
		}
	}

	React.useEffect(() => {
		getListWriterSchedules()
	}, [page, rowsPerPage, order])

	return (
			<div className='writer-managing'>
				<GridContainer alignItems='center'>
					<GridItem
							className={classes.gridContainerOne}
							xs={12}
							sm={4}
							md={3}
							lg={2}
							xl={2}
					>
						<TextField
								id='writer-managing-select'
								select
								size='small'
								className={classes.textFieldOne}
								label='작가'
								value={select}
								onChange={handleChangeSelect}
						>
							<MenuItem value={'nickname'}>작가 닉네임</MenuItem>
							<MenuItem value={'BLOCKED'}></MenuItem>
						</TextField>
					</GridItem>
					<GridItem xs={12} sm={8} md={5} lg={4} xl={4}>
						<TextField
								id='post-managing-textfield'
								size='small'
								className={classes.textFieldOne}
								placeholder='검색어를 입력해주세요'
								name='clientId'
								value={clientId}
								onChange={handleChangeSearchInput}
								InputProps={{
									startAdornment: (
											<InputAdornment position='start'>
												<SearchIcon />
											</InputAdornment>
									),
								}}
						/>
					</GridItem>

					<GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
						<Button
								color='primary'
								disabled={loading}
								onClick={() => {
									setPage(0)
									getListWriterSchedules()
								}}
						>
							검색
						</Button>
					</GridItem>
				</GridContainer>

				<Box my={2}>
					<TextField
							className={`${classes.textFieldTwo} ${classes.textFieldTwoChildOne}`}
							id='post-managing-textfield-show-info1'
							size='small'
							value={totalWriterSchedule}
							InputProps={{
								startAdornment: (
										<InputAdornment position='start'>전체</InputAdornment>
								),
								readOnly: true,
							}}
					/>
					<TextField
							className={classes.textFieldTwo}
							id='post-managing-textfield-show-info2'
							size='small'
							value={totalWriterScheduleBySearch}
							InputProps={{
								startAdornment: (
										<InputAdornment position='start'>검색</InputAdornment>
								),
								readOnly: true,
							}}
					/>
				</Box>

				<Box className={classes.btnGetExcelAndPaginationTable} mb={2}>
					<div>
					{/*<span style={{'paddingTop': '15px', 'paddingRight': '10px'}}>* 작가 일정을 수정하시려는 경우 기존 등록된 작가 일정을 삭제한 후 다시 등록해주세요.</span><br/>
					<span style={{ 'display': 'inline-block'}}>* 작가 상품, 작가 일정, 작가메인을 삭제할 경우 관련된 사진은 전부 삭제가 되므로 다시 파일 업로드 후 엑셀을 넣어주세요.</span>*/}
					</div>
					<>
						<Button
								color='success'
								onClick={handleButtonClickImg}
						>
							사진 업로드
						</Button>
						<input
								name="FILE_UPLOAD"
								type="file"
								multiple
								ref={imgInput}
								id="fileUpload"
								onChange={handleChangeImg}
								onClick={(event)=> {event.target.value = null}}    //같은 파일 적용도 가능하게
								accept='image/*'
								style={{ display: "none" }}
						/>
					</>
					<>
						<Button
								color='info'
								onClick={handleButtonClick}
						>
							엑셀 업로드
						</Button>
						<input
								name="FILE_UPLOAD"
								type="file"
								ref={fileInput}
								id="fileUpload"
								onChange={handleChange}
								onClick={(event)=> {event.target.value = null}}    //같은 파일 적용도 가능하게
								accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  //엑셀만 허용
								style={{ display: "none" }}
						/>
					</>
					<Button
						color='github'
						onClick={(e)=>{
							location.href = '/admin/writer-schedules/detail'
						}}
					>
						일정 등록
					</Button>
				</Box>

				<Box mb={2}>
					{loading ? (
							<CircularProgress size={30} className={classes.buttonProgress} />
					) : (
							<Table
									headCells={headCells}
									rows={schedules}
									totalRecords={totalRecords}
									setRowsPerPage={setRowsPerPage}
									rowsPerPage={rowsPerPage}
									setPage={setPage}
									page={page}
									setOrder={setOrder}
									order={order}
									setOrderBy={setOrderBy}
									orderBy={orderBy}
									getListWriterSchedules={getListWriterSchedules}
							/>
					)}
				</Box>
			</div>
	)
}

const mapStateToProps = (state) => ({
	loading: state.writerSchedulesManaging.loading,
	schedules: state.writerSchedulesManaging.schedules,
	metaData: state.writerSchedulesManaging.metaData,
	totalWriterSchedule: state.writerSchedulesManaging.totalWriterSchedule,
	totalWriterScheduleBySearch: state.writerSchedulesManaging.totalWriterScheduleBySearch,
	paginationWriterManaging: state.writerSchedulesManaging.paginationUserManaging,
})

export default connect(mapStateToProps, {
	requestWriterSchedulesManagingAction,
	getListWriterSchedulesAction,
	requestWriterSchedulesManagingErrorAction,
})(WriterSchedulesManaging)
