import React from 'react'
import moment from 'moment'
import fileDownload from 'js-file-download'
import queryString from 'query-string'


import {makeStyles} from '@material-ui/core/styles'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Box from '@material-ui/core/Box'
import TimePicker from '../components/TimePicker'
import TextField from 'components/Gm-TextField/TextFieldForDatePicker'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import Spinner from 'components/Spinner/Spinner'
import Pagination from 'components/Pagination/Pagination'

import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import {StaticOfClickTable} from '../components/Table'

import {useSelector, useDispatch} from 'react-redux'
import {
    getListStaticsOfQuitReasonClickAction,
} from 'redux/actions/staticsOfQuitReasonAction'
import staticsOfClickApi from 'api/staticsOfClickApi'

import styles from 'assets/jss/material-dashboard-pro-react/views/Statistics/statisticSearch.js'
import {StaticJoinOutTable} from "../components/StatisticJoinOutTable";

const useStyles = makeStyles(styles)

const StatisticJoinOut = () => {
    const classes = useStyles()

    const dispatch = useDispatch()
    const {
        loading: loadingPage,
        listStaticsOfClick,
        metaData: {totalPages},
        totalCount,
        totalKind
    } = useSelector((state) => ({
        loading: state.staticsOfJoinOut.loading,
        listStaticsOfClick: state.staticsOfJoinOut.listStaticsOfClick,
        metaData: state.staticsOfJoinOut.metaData,
        totalCount: state.staticsOfJoinOut.totalCount,
        totalKind: state.staticsOfJoinOut.totalKind,
    }))

    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [order, setOrder] = React.useState('desc')
    const [loadingButtonGetExcel, setLoadingButtonGetExcel] = React.useState(
        false,
    )

    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz

    const [pagination, setPagination] = React.useState(1)
    const [isFirstLoad, setIsFirstLoad] = React.useState(true)
    const [formData, setFormData] = React.useState({
        type: 'ALL',
        fromDate: moment().subtract(7, 'days').subtract(tz, 'hours').calendar({
            sameElse: 'YYYY-MM-DD',
        }),
        toDate: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        fromTime: 0,
        toTime: 23,
        limit: 10,
    })

    const handleChangeFormDate = (date, key) => {
        setFormData({
            ...formData,
            [key]: moment(date).subtract(tz, 'hours').format('YYYY-MM-DD'),
        })
    }

    const handleChangeTimePicker = (event, key) => {
        setFormData({...formData, [key]: event.target.value})
    }

    const options = [
        '전체',
        '오늘의 꾸미기',
        '베스트 꾸미기',
        '꾸밍 챌린지',
        '꾸밍 태그',
        '피드',
        '팔로잉',
    ]

    const convertOptionToEnglish = (option) => {
        switch (option) {
            case '전체':
                return 'ALL'
            case '오늘의 꾸미기':
                return 'POST_TODAY_DECORATING'
            case '베스트 꾸미기':
                return 'POST_BEST_DECORATING'
            case '꾸밍 챌린지':
                return 'POST_CHALLENGE'
            case '꾸밍 태그':
                return 'POST_BY_TAG'
            case '피드':
                return 'POST_FEED'
            case '팔로잉':
                return 'POST_FOLLOWING'
        }
    }

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index)
        setFormData({...formData, type: convertOptionToEnglish(options[index])})
        setOpen(false)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    const headCells = [
        {
            id: 'updatedAt',
            disablePadding: false,
            label: '탈퇴 일자',
        },
        {id: 'clientId', disablePadding: false, label: '고객번호'},
        {id: 'userId', disablePadding: false, label: '아이디'},
        {id: 'accountType', disablePadding: false, label: '로그인타입'},
        {id: 'nickname', disablePadding: false, label: '닉네임'},
        {id: 'reason', disablePadding: false, label: '탈퇴 이유'},
    ]

    // Handle API
    const compiled = _.template('${ date } ${ time }:00:00')
    const {type, limit, fromDate, toDate, fromTime, toTime} = formData

    const convertTime = (isFirstLoad, time) => {
        let result

        if (isFirstLoad) {
            result = _.split(
                moment().subtract(tz, 'hours').format('YYYY-MM-DD, H'),
                ',',
                2,
            )[1]?.trim()
        } else {
            const hour = time - tz;
            result = hour < 0 ? hour + 24 : hour;
        }

        return result <= 9 ? `0${result}` : result
    }

    const convertDate = (time, date) =>
        time >= tz ? date : moment(date).subtract(1, 'days').format('YYYY-MM-DD')

    let params = {
        type,
        limit,
        sortByTotalView: order.toUpperCase(),
        offset: pagination,
        fromDate: compiled({
            date: convertDate(fromTime, fromDate),
            time: convertTime(isFirstLoad, fromTime),
        }),
        toDate: compiled({
            date: convertDate(toTime, toDate),
            time: convertTime(isFirstLoad, toTime),
        }),
    }

    const getExcelFile = async () => {
        try {
            setLoadingButtonGetExcel(true)
            params['limit'] = 1000
            delete params['offset']
            const convertParamsToQueryUrl = queryString.stringify(params)

            const data = await staticsOfClickApi.getExcelFileJoinOut(convertParamsToQueryUrl)
            fileDownload(data, '회원탈퇴' + moment(params.fromDate).format('YYYYMMDD') + '_' + moment(params.toDate).format('YYYYMMDD') + '.xlsx')
            setLoadingButtonGetExcel(false)
        } catch (error) {
            setLoadingButtonGetExcel(false)
            // dispatch(staticsOfClickRequestErrorAction(error?.response?.data))
        }
    }

    const getListStaticsOfQuitReasonClick = async () => {
        try {
            setLoading(true)
            const {data} = await staticsOfClickApi.getListStaticsOfQuitReasonClick(params)
            dispatch(getListStaticsOfQuitReasonClickAction(data))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            // dispatch(staticsOfClickRequestErrorAction(error?.response?.data))
        }
    }

    React.useEffect(() => {
        getListStaticsOfQuitReasonClick()
        setIsFirstLoad(false)
    }, [pagination, order])

    return (
        <div className='statistic-click'>
            {loadingPage ? (
                <Spinner/>
            ) : (
                <>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3} lg={1} xl={1}>
                            기간별 조회

                        </GridItem>

                        <GridItem
                            container
                            alignItems='center'
                            className={classes.setFlexBasis}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={10}
                            xl={7}
                        >
                            <GridContainer
                                justifyContent='flex-end'
                                className={classes.setJustifyContent}
                            >
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={5}
                                    xl={5}
                                    className={classes.responsiveStyle}
                                >
                                    <GridContainer>
                                        <GridItem
                                            xs={7}
                                            sm={5}
                                            md={4}
                                            lg={7}
                                            xl={7}
                                            className={classes.styleDatePicker}
                                        >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    variant='inline'
                                                    TextFieldComponent={TextField}
                                                    format='yyyy/MM/dd'
                                                    id='date-picker-inline1'
                                                    value={formData?.fromDate}
                                                    onChange={(date) =>
                                                        handleChangeFormDate(date, 'fromDate')
                                                    }
                                                    autoOk={true}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </GridItem>
                                        <GridItem
                                            className={classes.paddingLeft}
                                            xs={5}
                                            sm={3}
                                            md={3}
                                            lg={5}
                                            xl={5}
                                        >
                                            <Box>
                                                <TimePicker
                                                    time={formData?.fromTime}
                                                    handleChangeTimePicker={handleChangeTimePicker}
                                                    setKey='fromTime'
                                                />
                                            </Box>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                <Box
                                    display='flex'
                                    flexDirection='center'
                                    alignItems='center'
                                    className={classes.styleSymbol}
                                >
                                    <p>~</p>
                                </Box>

                                <GridItem xs={12} sm={12} md={12} lg={5} xl={5}>
                                    <GridContainer>
                                        <GridItem
                                            xs={7}
                                            sm={5}
                                            md={4}
                                            lg={7}
                                            xl={7}
                                            className={classes.styleDatePicker}
                                        >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    variant='inline'
                                                    format='yyyy/MM/dd'
                                                    TextFieldComponent={TextField}
                                                    id='date-picker-inline2'
                                                    autoOk={true}
                                                    value={formData?.toDate}
                                                    onChange={(date) =>
                                                        handleChangeFormDate(date, 'toDate')
                                                    }
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </GridItem>
                                        <GridItem
                                            className={classes.paddingLeft}
                                            xs={5}
                                            sm={3}
                                            md={3}
                                            lg={5}
                                            xl={5}
                                        >
                                            <Box>
                                                <TimePicker
                                                    time={formData?.toTime}
                                                    handleChangeTimePicker={handleChangeTimePicker}
                                                    setKey='toTime'
                                                />
                                            </Box>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </GridItem>

                        <Box className={classes.styleButtonSubmit}>
                            <Button
                                color='primary'
                                disabled={loading}
                                onClick={getListStaticsOfQuitReasonClick}
                            >
                                검색
                            </Button>
                        </Box>
                    </GridContainer>

                    <GridContainer>
                        <GridItem container justifyContent='flex-end' xs={12}>
                            <Button
                                color='primary'
                                disabled={loadingButtonGetExcel}
                                onClick={getExcelFile}
                            >
                                엑셀 다운로드
                            </Button>
                        </GridItem>

                        <GridItem xs={12}>
                            {loading ? (
                                <Spinner/>
                            ) : (
                                <StaticJoinOutTable
                                    headCells={headCells}
                                    rows={listStaticsOfClick}
                                    setOrder={setOrder}
                                    order={order}
                                />
                            )}

                            <Box mt={2} display='flex' justifyContent='flex-end'>
                                <Pagination
                                    pagination={pagination}
                                    totalPages={totalPages}
                                    setPagination={setPagination}
                                />
                            </Box>
                        </GridItem>
                    </GridContainer>
                </>
            )}
            {
                loadingPage ? (
                    <Spinner/>
                ) : (

                    <>
                        <div><span style={{'fontWeight': 'bold'}}>기간별 총 탈퇴 건수 :</span> {totalCount}</div>
                        <br/>
                        <div><span style={{'fontWeight': 'bold', 'fontSize': '18px'}}>기간별 탈퇴 사유당 건수 :</span><br/>

                            {
                                totalKind.map((item) => {
                                    return (
                                       <>
                                        <span style={{'fontWeight': 'bold'}}>{item?.report_category?.description}</span>
                                        : <>{item.totalCount}</>

                                    </>
                                )
                                        })
                            }

        </div>


</>


)
}
</div>
)
}

export default StatisticJoinOut
