import React from 'react'
import capitalize from 'lodash/capitalize'

import {makeStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CustomTextField from 'components/Gm-TextField/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import Spinner from 'components/Spinner/Spinner'

import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'

import tagApi from 'api/tagApi'
import {connect} from 'react-redux'
import {
    requestTagAction,
    createTagAction,
    updateTagAction,
    createTagErrAction,
    getListTagsAction,
    deleteTagAction,
    orderTagAction,
} from 'redux/actions/tagManaging'

import styles from 'assets/jss/material-dashboard-pro-react/views/MainManaging/tagManaging'
import moment from "moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "../../components/Gm-TextField/TextFieldForDatePicker";
import Box from "@material-ui/core/Box";
import TimePicker from "../Statistics/components/TimePicker";
import {dateFormat} from "../../util/filter";
import {CustomAlert, dateTimeCheck} from "../../util/common";

const useStyles = makeStyles(styles)

const TagManaging = (props) => {
    const {
        requestTagAction,
        createTagAction,
        updateTagAction,
        createTagErrAction,
        deleteTagAction,

        getListTagsAction,
        orderTagAction,
        loading,
        tags,
        match,
    } = props

    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz

    const [formData, setFormData] = React.useState({
        startAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        endAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        fromTime: 0,
        toTime: 23,
        // type: 'RECOMMEND',
        type: match?.params?.type || 'RECOMMEND'
    })

    const [loadingSpinner, setLoadingSpinner] = React.useState(true)
    const [mode, setMode] = React.useState('I')
    const [stateOfAlert, setStateOfAlert] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
    })
    const [filter, setFilter] = React.useState({
        limit: 12,
        offset: 0,
    })

    const classes = useStyles()

    // fn for show & hide alert
    const {open, vertical, horizontal, message} = stateOfAlert
    const handleClick = (newState) => {
        setStateOfAlert({open: true, ...newState})
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setStateOfAlert({...stateOfAlert, open: false})
    }

    const handleChangeTimePickerStart = (event) => {
        setFormData({...formData, fromTime: event.target.value})
    }
    const handleChangeTimePickerEnd = (event) => {
        setFormData({...formData, toTime: event.target.value})
    }
    const handleForm = (value, key) => {
        switch (key) {
            case 'startAt' :
                setFormData({
                    ...formData,
                    [key]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'endAt' :
                setFormData({
                    ...formData,
                    [key]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            default:
                setFormData({
                    ...formData,
                    [key]: value,
                })

        }
    }

    const changeIndexOfArr = async (up, down, numOrder, tagId, index) => {
        const deepCloneData = [...tags]
        const currentIndex = index
        if (up) {
            if (index !== 0) {
                requestTagAction()
                try {
                    let changeUpIndex = index - 1
                    let body = {
                        tagId,
                        action: 'UP',
                    }
                    await tagApi.updateOrderTag(body)

                    let updateNumOrder = deepCloneData.map((item, i) => {
                        if (currentIndex === i) {
                            item.numOrder = item.numOrder - 1
                        }

                        if (changeUpIndex === i) {
                            item.numOrder = item.numOrder + 1
                        }

                        return item
                    })

                    updateNumOrder.sort((a, b) => a.numOrder - b.numOrder)
                    orderTagAction(updateNumOrder)
                } catch (error) {
                    console.log(error.response)
                }
            }
        } else if (down) {
            if (index !== deepCloneData.length - 1) {
                requestTagAction()
                try {
                    let changeDownIndex = index + 1
                    let body = {
                        tagId,
                        action: 'DOWN',
                    }
                    await tagApi.updateOrderTag(body)

                    let updateNumOrder = deepCloneData.map((item, i) => {
                        if (currentIndex === i) {
                            item.numOrder = item.numOrder + 1
                        }

                        if (changeDownIndex === i) {
                            item.numOrder = item.numOrder - 1
                        }

                        return item
                    })

                    updateNumOrder.sort((a, b) => a.numOrder - b.numOrder)
                    orderTagAction(updateNumOrder)
                } catch (error) {
                    console.log(error.response)
                }
            }
        }
    }
    // createTag Btn
    const onChange = (e) => {
        if (e.target.value.length > 49) {
            handleClick({
                vertical: 'top',
                horizontal: 'center',
                message: '50자 이하로 입력해주시기 바랍니다.',
            })
            return
        }
        handleForm(e.target.value, 'tagName')
    }
    const handleUpdate = async (row) => {


        setFormData({
            id: row.id,
            type: row.type,
            tagName: row.tagName.split('#')[1],
            startAt: moment(row.startAt).format('YYYY-MM-DD'),
            endAt: moment(row.endAt).format('YYYY-MM-DD'),
            fromTime: parseInt(moment(row.startAt).format('HH')),
            toTime: parseInt(moment(row.endAt).format('HH')),

        })


        setMode('U')

    }


    const createTag = async () => {


        let convertInputTag
        if (formData.tagName.includes('#')) {
            convertInputTag = formData.tagName
        } else {
            convertInputTag = `#${formData.tagName}`
        }

        // return

        const body = {
            tagName: convertInputTag,
            startAt: new Date(moment(dateTimeCheck(formData?.startAt, formData?.fromTime)).format("YYYY-MM-DD HH:mm")).getTime(),
            endAt: new Date(moment(dateTimeCheck(formData?.endAt, formData?.toTime)).format("YYYY-MM-DD HH:mm")).getTime(),
            type: formData?.type,
            numOrder: formData?.numOrder,
        }


        if (formData.id != null) {
            //수정
            try {

                body.id = formData.id

                requestTagAction()
                const {data} = await tagApi.updateTag(body)

                handleClick({vertical: 'top', horizontal: 'center', message: '성공'})
                formData.tagName = '#' + formData.tagName
                updateTagAction(formData)

                setFormData({
                    startAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
                    endAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
                    fromTime: 0,
                    toTime: 23,
                    type: formData?.type,
                    tagName: ''
                })
                setMode('I')

                location.reload()


            } catch (error) {
                if (
                    error &&
                    error.response &&
                    error.response.data &&


                    error.response.data.data
                ) {                    // createTagErrAction(error.response.data)
                    if (error.response.data.data.isShow === true) {
                        handleClick({
                            vertical: 'top',
                            horizontal: 'center',
                            message: error.response.data.data.error,
                        })
                    } else {
                        handleClick({
                            vertical: 'top',
                            horizontal: 'center',
                            message: '오류',
                        })
                    }
                }
            }


            return
        }
        //등록


        try {
            requestTagAction()
            const {data} = await tagApi.createTag(body)
            createTagAction(data)
            handleClick({vertical: 'top', horizontal: 'center', message: '성공'})
            setFormData({
                startAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
                endAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
                fromTime: 0,
                toTime: 23,
                type: 'RECOMMEND',
            })

            location.reload()

        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                createTagErrAction(error.response.data)
                if (error.response.data.data.isShow === true) {
                    handleClick({
                        vertical: 'top',
                        horizontal: 'center',
                        message: error.response.data.data.error,
                    })
                } else {
                    handleClick({
                        vertical: 'top',
                        horizontal: 'center',
                        message: '오류',
                    })
                }
            }
        }
    }

    // delete tag
    const deleteTag = async (tagId) => {
        const params = {
            tagId,
        }

        try {
            requestTagAction()
            await tagApi.deleteTag(params)
            deleteTagAction(tagId)
            handleClick({
                vertical: 'top',
                horizontal: 'center',
                message: 'success',
            })
        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                createTagErrAction(error.response.data)
                if (error.response.data.data.isShow === true) {
                    handleClick({
                        vertical: 'top',
                        horizontal: 'center',
                        message: error.response.data.data.error,
                    })
                } else {
                    handleClick({
                        vertical: 'top',
                        horizontal: 'center',
                        message: 'error',
                    })
                }
            }
        }
    }
    const checkCheck = async (startAt, endAt) => {

        if (!(new Date(startAt).getTime() < new Date().getTime()  &&   new Date().getTime() < new Date(endAt).getTime()) ){
            return classes.notNow
        }
        return
    }
    const getListTags = async () => {
        let params = {
            ...filter,
            type: match?.params?.type
        }

        if (!params.tagName) {
            delete params.tagName
        }

        try {
            requestTagAction()

            const {data} = await tagApi.getListTags(params)


            getListTagsAction(data)
            setLoadingSpinner(false)
        } catch (error) {
            setLoadingSpinner(false)
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                createTagErrAction(error.response.data)
                if (error.response.data.data.isShow === true) {
                    handleClick({
                        vertical: 'top',
                        horizontal: 'center',
                        message: error.response.data.data.error,
                    })
                } else {
                    handleClick({
                        vertical: 'top',
                        horizontal: 'center',
                        message: 'error',
                    })
                }
            }
        }
    }

    // Get list tag and paginations
    React.useEffect(() => {
        // setFormData(...formData, {type: match?.params?.type})
        getListTags()
    }, [filter, match?.params?.type])

    return (
        <>
            {loadingSpinner ? (
                <Spinner/>
            ) : (
                <div className='tag-managing'>
                    {
                        match?.params?.type == 'RECOMMEND' &&
                        (<div style={{fontSize: '1rem', marginBottom: '10px', fontWeight: 'bold'}}>[꾸미기, 일러스트]</div>)
                    }
                    {
                        match?.params?.type == 'POST_GOODS' &&
                        (<div style={{fontSize: '1rem', marginBottom: '10px', fontWeight: 'bold'}}>[굿즈]</div>)
                    }
                    <Paper className={classes.paper} variant='outlined' square>
                        <GridContainer>
                            <GridItem
                                className={classes.symBolTag}
                                container
                                alignItems='center'
                                justifyContent='center'
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                            >
                                <p>#</p>
                            </GridItem>
                            <GridItem
                                container
                                alignItems='center'
                                xs={3}
                            >
                                <CustomTextField
                                    className={classes.textFieldAddTag}
                                    id='tag-register-new'
                                    label='태그명을 입력하세요'
                                    fullWidth={true}
                                    size='small'
                                    onChange={onChange}
                                    value={formData?.tagName}
                                    // defaultValue='Default Value'
                                    variant='outlined'
                                />
                            </GridItem>
                            <GridItem
                                container
                                alignItems='center'
                                xs={7}
                            >
                                <GridContainer>
                                    {/*공백용*/}
                                    <GridItem xs={1}
                                              sm={1}>
                                    </GridItem>
                                    <GridItem
                                        xs={3}
                                        sm={3}
                                        // className={classes.styleDatePicker}
                                    >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                variant='inline'
                                                TextFieldComponent={TextField}
                                                format='yyyy-MM-dd'
                                                id='date-picker-inline1'
                                                value={formData?.startAt}
                                                onChange={(date) =>
                                                    handleForm(date, 'startAt')
                                                }
                                                autoOk={true}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </GridItem>
                                    <GridItem
                                        // className={classes.paddingLeft}
                                        xs={2}
                                        sm={2}
                                    >
                                        <Box>
                                            <TimePicker
                                                time={formData?.fromTime}
                                                handleChangeTimePicker={handleChangeTimePickerStart}
                                            />
                                        </Box>
                                    </GridItem>
                                    <GridItem xs={1}
                                              sm={1} className={classes.marginAuto}>
                                        ~
                                    </GridItem>
                                    <GridItem
                                        xs={3}
                                        sm={3}
                                        // className={classes.styleDatePicker}
                                    >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                variant='inline'
                                                TextFieldComponent={TextField}
                                                format='yyyy-MM-dd'
                                                id='date-picker-inline1'
                                                value={formData?.endAt}
                                                onChange={(date) =>
                                                    handleForm(date, 'endAt')
                                                }
                                                autoOk={true}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </GridItem>
                                    <GridItem
                                        // className={classes.paddingLeft}
                                        xs={2}
                                        sm={2}
                                    >
                                        <Box>
                                            <TimePicker
                                                time={formData?.toTime}
                                                handleChangeTimePicker={handleChangeTimePickerEnd}
                                                setKey='toTime'
                                            />
                                        </Box>
                                    </GridItem>

                                </GridContainer>

                            </GridItem>
                            <GridItem
                                container
                                // justifyContent='flex-end'
                                className={classes.customStyleBtn}
                                xs={1}
                            >
                                <Button disabled={loading} onClick={() => createTag()} color='primary'>
                                    {mode == 'I' ? '등록' : '수정'}
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </Paper>

                    <GridContainer>
                        <GridItem
                            // className={classes.symBolTag}
                            container
                            alignItems='center'
                            justifyContent='center'
                            xs={1}
                            sm={1}
                            md={1}
                            lg={1}
                            xl={1}
                        >
                            <p>순서</p>
                        </GridItem>


                        <GridItem
                            container
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            className={classes.textFlex}
                        >
                            <CustomTextField
                                className={classes.textField}
                                // id={`tag-registered-${i}`}
                                // defaultValue='태그명'
                                value={'태그명'}
                                fullWidth={true}
                                variant='outlined'
                                size='small'
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </GridItem>
                        {/*시작 기간 ~ 종료 기간*/}
                        <GridItem
                            container
                            // direction='column'
                            alignItems='center'
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            className={classes.textFlex}
                        >

                            {/*{dateFormat(item.startAt)} ~ {dateFormat(item.endAt)}*/}

                            <CustomTextField
                                className={[classes.textField, classes.textFlex, classes.center]}
                                // id={`tag-date-${i}`}
                                // defaultValue='태그명'
                                value={'노출 시작 기간 ~ 종료 기간'}
                                fullWidth={true}
                                variant='outlined'
                                size='small'
                                InputProps={{
                                    readOnly: true,
                                }}
                            />


                        </GridItem>

                        <GridItem
                            container
                            justifyContent='flex-end'
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                        >
                            {/*<IconButton*/}
                            {/*    disabled={loading}*/}
                            {/*    aria-label='delete'*/}
                            {/*    onClick={() => deleteTag(item.id)}*/}
                            {/*>*/}
                            {/*  <HighlightOffIcon />*/}
                            {/*</IconButton>*/}
                        </GridItem>
                        <GridItem
                            container
                            direction='column'
                            alignItems='center'
                            xs={1}
                            sm={1}
                            md={1}
                            lg={1}
                            xl={1}
                        >
                            {/*<div>*/}
                            {/*    /!*<IconButton*!/*/}
                            {/*    /!*    size='small'*!/*/}
                            {/*    /!*    disabled={loading}*!/*/}
                            {/*    /!*    onClick={() =>*!/*/}
                            {/*    /!*        changeIndexOfArr(*!/*/}
                            {/*    /!*            true,*!/*/}
                            {/*    /!*            false,                             /!*            item.numOrder,*!/*/}
                            {/*    /!*            item.id,*!/*/}
                            {/*    /!*            i,*!/*/}
                            {/*    /!*        )*!/*/}
                            {/*    /!*    }*!/*/}
                            {/*    /!*>*!/*/}
                            {/*    /!*  <ExpandLessIcon />*!/*/}
                            {/*    /!*</IconButton>*!/*/}
                            {/*</div>*/}
                            {/*/!*<div>*!/*/}
                            {/*/!*  <IconButton*!/*/}
                            {/*/!*      size='small'*!/*/}
                            {/*/!*      disabled={loading}*!/*/}
                            {/*/!*      onClick={() =>*!/*!/*/}

                            {/*/!*          changeIndexOfArr(*!/*/}
                            {/*/!*              false,*!/*/}
                            {/*/!*              true,*!/*/}
                            {/*/!*              item.numOrder,*!/*/}
                            {/*/!*              item.id,*!/*/}
                            {/*/!*              i,*!/*/}
                            {/*/!*          )*!/*/}
                            {/*/!*      }*!/*/}
                            {/*/!*  >*!/*/}
                            {/*/!*    <ExpandMoreIcon />*!/*/}
                            {/*/!*  </IconButton>*!/*/}
                            {/*/!*</div>*!/*/}
                        </GridItem>
                    </GridContainer>

                    {tags.map((item, i) => {
                        return (
                            <Paper
                                key={i}
                                className={[classes.paper, !(new Date(item.startAt).getTime() < new Date().getTime()  &&   new Date().getTime() < new Date(item.endAt).getTime()) && classes.notNow ]}
                                variant='outlined'
                                square
                            >
                                <GridContainer>
                                    <GridItem
                                        // className={classes.symBolTag}
                                        container
                                        alignItems='center'
                                        justifyContent='center'
                                        xs={1}
                                        sm={1}
                                        md={1}
                                        lg={1}
                                        xl={1}
                                    >
                                        {/*<p>{item.numOrder}</p>*/}
                                        <p>{i+1}</p>
                                    </GridItem>


                                    <GridItem
                                        container
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        className={classes.textFlex}
                                    >
                                        <CustomTextField
                                            className={classes.textField}
                                            id={`tag-registered-${i}`}
                                            // defaultValue='태그명'
                                            value={item.tagName}
                                            fullWidth={true}
                                            variant='outlined'
                                            size='small'
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </GridItem>
                                    {/*시작 기간 ~ 종료 기간*/}
                                    <GridItem
                                        container
                                        // direction='column'
                                        alignItems='center'
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        className={classes.textFlex}
                                    >

                                        <CustomTextField
                                            className={[classes.textField, classes.textFlex, classes.center]}
                                            id={`tag-date-${i}`}
                                            // defaultValue='태그명'
                                            value={`${dateFormat(item.startAt)}~${dateFormat(item.endAt)}`}
                                            fullWidth={true}
                                            variant='outlined'
                                            size='small'
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />


                                    </GridItem>

                                    <GridItem
                                        container
                                        justifyContent='flex-end'
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                    >

                                        <Button disabled={loading} onClick={() => handleUpdate(item)} color='primary'>
                                            수정
                                        </Button>


                                        <IconButton
                                            disabled={loading}
                                            aria-label='delete'
                                            onClick={() => deleteTag(item.id)}
                                        >
                                            <HighlightOffIcon/>
                                        </IconButton>
                                    </GridItem>
                                    <GridItem
                                        container
                                        direction='column'
                                        alignItems='center'
                                        xs={1}
                                        sm={1}
                                        md={1}
                                        lg={1}
                                        xl={1}
                                    >
                                        <div>
                                            <IconButton
                                                size='small'
                                                disabled={loading}
                                                onClick={() =>
                                                    changeIndexOfArr(
                                                        true,
                                                        false,
                                                        item.numOrder,
                                                        item.id,
                                                        i,
                                                    )
                                                }
                                            >
                                                <ExpandLessIcon/>
                                            </IconButton>
                                        </div>
                                        <div>
                                            <IconButton
                                                size='small'
                                                disabled={loading}
                                                onClick={() =>
                                                    changeIndexOfArr(
                                                        false,
                                                        true,
                                                        item.numOrder,
                                                        item.id,
                                                        i,
                                                    )
                                                }
                                            >
                                                <ExpandMoreIcon/>
                                            </IconButton>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </Paper>
                        )
                    })}

                    {/* Alert */}
                    <Snackbar
                        anchorOrigin={{vertical, horizontal}}
                        open={open}
                        autoHideDuration={message === 'success' ? 2500 : 6000}
                        onClose={handleClose}
                    >
                        <CustomAlert
                            onClose={handleClose}
                            severity={message === 'success' ? 'success' : 'error'}
                        >
                            {capitalize(message)}
                        </CustomAlert>
                    </Snackbar>
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.tagManaging.loading,
        tags: state.tagManaging.tags,
        metaDataOfTags: state.tagManaging.metaDataOfTags,
    }
}

export default connect(mapStateToProps, {
    requestTagAction,
    updateTagAction,
    createTagAction,
    createTagErrAction,
    deleteTagAction,
    getListTagsAction,
    orderTagAction,
})(TagManaging)
