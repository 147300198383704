import React, {useEffect} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {makeStyles} from "@material-ui/core/styles";
import styles from 'assets/jss/material-dashboard-pro-react/views/MainManaging/bannerManaging'
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import TextField from "../../../components/Gm-TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "../../../components/CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import {requestUserManagingAction} from "../../../redux/actions/userManagingAction";
import writerGoodsManagingApi from "../../../api/writerGoodsManagingApi";
import {connect} from "react-redux";
import {getListWriterGoodsAction} from "../../../redux/actions/writerGoodsManagingAction";
import CustomTextField from "../../../components/Gm-TextField/TextField";
import {handleFormEvent} from "../../../util/common";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import bannerManagingApi from "../../../api/mainManaging/bannerManagingApi";
import PropTypes from "prop-types";
import EditBanner from "../../MainManaging/components/EditBanner";
import stickerApi from "../../../api/stickerApi";


const useStyles = makeStyles(styles)


const StickerModal = (props) => {


    const {
        hideAlert,
        setAlert,
        dispatch,
        getListUserAction,
        loading,
        setAddMemberIds,
        goodsManagingId,
        setSticker,
        sticker,
        tagName,
        metaData

    } = props
    const [selected, setSelected] = React.useState([])

    const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('')
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [page, setPage] = React.useState(0)

    useEffect(() => {
        document.querySelector('.sweet-alert').parentElement.style.zIndex = 1034;
    }, [page])



    const handleChangeFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            // if (validateUploadImage(file, 'size')) return

            const updateState = (file) => {
                setImageFile(file)
                setIsUploadImage(true)
            }
            // use for check width and height image
            const img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.addEventListener('load', () => {
                updateState(file)
            })
        }
    }


    const classes = useStyles()

    const [showNotification, setShowNotification] = React.useState({
        open: false,
        message: '',
    })


    const [limit, setLimit] = React.useState(10)
    const [searchText, setSearchText] = React.useState('')
    const [searchKey, setSearchKey] = React.useState('writerName')
    const [imageFile, setImageFile] = React.useState(null)
    const [isUploadImage, setIsUploadImage] = React.useState(false)
    const [formData, setFormData] = React.useState({
        id: sticker?.id || null,
        name: sticker?.name,
        writerName: sticker?.writerName,
        imgUrl: sticker?.imgUrl,

    })

    const handleCloseOpen = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setShowNotification({...showNotification, open: false})
    }

    const handleSearchKey = (valuwe) => {
        setSearchKey(valuwe)
        setOrder('asc')
    }
    const saveSticker = async () => {
        // setLoading(true)
        let setNewmainImage
        if (isUploadImage) {
            const formData = new FormData()
            formData.append('file', imageFile, imageFile.name)
            formData.append('type', 'STICKER')

            const {
                data: {id, url, filename},
            } = await bannerManagingApi.uploadImage(formData)

            setNewmainImage = {
                id,
                url,
                filename,
            }
            setIsUploadImage(false)
        }

        const body = {
            id: formData?.id,
            name: formData.name,
            tagName: tagName,
            writerName: formData?.writerName,
            imgUrl: isUploadImage ? setNewmainImage.url : formData?.imgUrl,
        }

        if(body.name == null || body.tagName == null || body.writerName == null || body.imgUrl == null){
            alert("잘못된 값입니다")
            return
        }

        if (sticker?.id) {
            await stickerApi.update(body)  //수정
        } else {
            await stickerApi.create(body)  //생성
        }


        alert("저장되었습니다")


        // setLoading(true)
        hideAlert()
        location.reload()

    }


    return (
        <>
            <SweetAlert
                style={{display: 'block', marginTop: '-100px', width: '80em', zIndex: '1034',}}
                title={'스티커 추가'}
                onConfirm={() => {
                }}
                showConfirm={false}
                onCancel={() => hideAlert()}
            >

                <GridContainer>
                    <GridItem
                        container
                        justifyContent='flex-start'
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}

                        className={classes.spacingbetweenTwoColOfModal}
                    >
                        스티커명
                    </GridItem>

                    <GridItem
                        container
                        justifyContent='flex-start'
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                    >
                        <CustomTextField
                            id='editBannerUpload'
                            label='스티커명을 입력하세요'
                            variant='standard'
                            name='name'
                            value={formData?.name}
                            className={classes.widthTextFieldModalTwo}
                            onChange={(e)=>{
                                handleFormEvent(e,'name', formData, setFormData)
                            }}
                            style={{marginBottom: '20px', width: '50%'}}
                        />
                    </GridItem>

                </GridContainer>
                <GridContainer>
                    <GridItem
                        container
                        justifyContent='flex-start'
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}

                        className={classes.spacingbetweenTwoColOfModal}
                    >
                        작가명
                    </GridItem>

                    <GridItem
                        container
                        justifyContent='flex-start'
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                    >
                        <CustomTextField
                            id='editBannerUpload'
                            label='작가명을 입력하세요'
                            variant='standard'
                            name='writerName'
                            value={formData?.writerName}
                            className={classes.widthTextFieldModalTwo}
                            onChange={(e)=>{
                                handleFormEvent(e,'writerName', formData, setFormData)
                            }}
                            style={{marginBottom: '20px', width: '50%'}}
                        />
                    </GridItem>

                </GridContainer>
                <GridContainer>
                    <GridItem
                        container
                        justifyContent='flex-start'
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        className={classes.spacingbetweenTwoColOfModal}
                    >
                        이미지
                    </GridItem>

                    <GridItem
                        container
                        justifyContent='flex-start'
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                    >
                        <Box style={{width: '30%'}}>
                            <input
                                accept='image/*'
                                className={classes.inputBtnUpload}
                                id='contained-button-file-2'
                                multiple
                                type='file'
                                onClick={(event)=>{
                                        event.target.value = ''
                                }}
                                onChange={(e) => handleChangeFile(e)}
                            />
                            <label htmlFor='contained-button-file-2'>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    component='span'
                                    fullWidth={true}
                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                    disabled={loading}
                                >
                                    {imageFile
                                        ? imageFile.name
                                        : formData?.imgUrl}
                                    {imageFile == null && formData?.imgUrl == null && '스티커 이미지를 등록해주세요'}
                                </Button>
                            </label>
                        </Box>
                    </GridItem>

                </GridContainer>
                <GridContainer>
                    <GridItem
                        container
                        justifyContent='flex-start'
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}

                        className={classes.spacingbetweenTwoColOfModal}
                    >
                    </GridItem>

                    <Box mt={1} style={{marginLeft: '15px'}}>
                        <p style={{paddingTop: '5px'}}><br/>
                            * 스티커 규격: 297px*297px, png 파일 첨부 가능​ <br/>
                            등록 시 바로 앱에서 노출됩니다.​
                        </p>
                    </Box>
                </GridContainer>







                <Box mt={1} display='flex' justifyContent='center'>
                    <Button
                        onClick={hideAlert}
                        className={classes.marginRightBtnInModal}
                        color='danger'
                    >
                        취소
                    </Button>
                    <Button
                        color='success'
                        disabled={loading}
                        onClick={(e)=>{
                            saveSticker()
                        }}
                    >

                        {
                            sticker?.id ? '수정': '등록'
                        }
                    </Button>
                </Box>

            </SweetAlert>

        </>


    )

}

StickerModal.propTypes = {
    sticker: PropTypes.object.isRequired,
    hideAlert: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
}
export default StickerModal