import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "../../../components/CustomButtons/Button";
import CustomTextField from "../../../components/Gm-TextField/TextField";
import GridItem from "../../../components/Grid/GridItem";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/PushManaging/PushManangingStyle";
import GridContainer from "../../../components/Grid/GridContainer";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import bannerManagingApi from "../../../api/mainManaging/bannerManagingApi";
import codeManagingApi from "../../../api/codeManagingApi";

import Switch from "@material-ui/core/Switch";
import {handleFormEvent} from "../../../util/common";

const useStyles = makeStyles(styles)



const ModalCategorylAdd = (props) => {

    const [imageFile, setImageFile] = React.useState(null)
    const [isUploadImage, setIsUploadImage] = React.useState(false)

    const [formData, setFormData] = React.useState({
        codeName: '',
        code: '',
        iconUrl: '',

    })

    const {
        hideAlert,
        title,
        dispatch,
        setModal,
        categoryItem,
        code,
        getCodeList,
        codeVO,
        iconImgVO,
        mode
    } = props

    const classesAlert = useStyles()

    useEffect(() => {
        document.querySelector('.sweet-alert').parentElement.style.zIndex = 1034;
        setFormData({...formData, code: code})
        if (codeVO != null){
           setFormData({...formData,
               code: codeVO.code,
                codeName: codeVO.codeName,
                iconImgId: codeVO.iconImgId,
               isShow: codeVO.isShow
            })
        }
        // getPostList();
    }, [])

    const onInputClick = (event) => {
        event.target.value = ''
    }
    const createCode = async (event) => {



        //1차적으로 이미지 저장

        //이미지 존재 여부 체크
        let fileId = null;


        if (mode == 'parent'){

            if (codeVO?.iconImg?.id == null && imageFile == null){
                alert("이미지를 넣어주세요")
                return
            }

            if (codeVO?.iconImg?.id && imageFile == null){
                //기존에 있던 파일 사용
                fileId = codeVO?.iconImg?.id;
            }else{
                const sendformData = new FormData()
                sendformData.append('file', imageFile, imageFile.name)
                sendformData.append('type', 'COMMON')
                const {
                    data: {id, url, filename},
                } = await bannerManagingApi.uploadImageCommon(sendformData)
                setIsUploadImage(false)
                fileId =id;
            }
        }



        try{
            if (codeVO?.code){
                //수정

                const  result =  await codeManagingApi.updateCode({
                    code: formData.code,
                    codeName: formData.codeName,
                    iconImgId: fileId,
                    isShow: formData.isShow
                })

                if (result?.success){
                    setModal(null)
                    alert("수정에 성공했습니다.")
                    getCodeList();
                    return true
                }


            }else{
                //신규
                //나머지 저장
                //error 메시지 표준이 하위와 같음 0--
                const  result =   await codeManagingApi.createCode({
                    code: formData.code,
                    codeName: formData.codeName,
                    iconImgId: fileId,
                    isShow: formData.isShow
                })

                if (result?.success){
                    setModal(null)
                    alert("저장에 성공했습니다.")
                    getCodeList();
                    return true
                }

            }

        }catch (error){
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                const errRes = error.response.data.data;

                const message = "[" + errRes.code + "] : " + errRes.messages
                alert(message)
                return false
            }
        }


    }
    const handleChangeFile = (e, whichBanner) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            // if (validateUploadImage(file, 'size')) return

            const updateState = (file) => {
                setImageFile(file)
                setIsUploadImage(true)
            }
            // use for check width and height image
            const img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.addEventListener('load', () => {
                updateState(file)
            })
        }
    }
    return (
        <>
            <SweetAlert
                style={{display: 'block', marginTop: '-100px', width: '80em', zIndex: '1034',}}
                title={title}
                onConfirm={() => {
                }}
                showConfirm={false} 를
                onCancel={() => hideAlert()}
            >
                <GridContainer style={{'width': '60%', margin: 'auto'}}>
                    <GridItem xs={2} sm={2} md={2} lg={2} xl={2} style={{margin: 'auto'}}>
                        카테고리 명
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} xl={10}>
                        <CustomTextField
                            id='categoryUpload'
                            label='카테고리명을 입력하세요'
                            variant='standard'
                            name='categoryName'
                            value={formData?.codeName}
                            // className={classes.widthTextFieldModalTwo}
                            onChange={(e) => {
                                handleFormEvent(e, 'codeName', formData, setFormData)
                            }}
                            style={{marginBottom: '20px', width: '50%'}}
                        />
                    </GridItem>
                    <GridItem xs={2} sm={2} lg={2} lg={2} xl={2} style={{margin: 'auto'}}>
                        코드 번호
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} xl={10}>
                        <CustomTextField
                            id='codeUpload'
                            label='코드 번호 입력하세요(자동부여)'
                            variant='standard'
                            name='code'
                            value={formData?.code}
                            InputProps={{
                                readOnly: true,
                            }}
                            // className={classes.widthTextFieldModalTwo}
                            onChange={(e) => {
                                handleFormEvent(e, 'code')
                            }}
                            style={{marginBottom: '20px', width: '50%'}}
                        />
                    </GridItem>
                    {
                        mode == 'parent' && (

                            <>
                            <GridItem xs={2} sm={2} lg={2} lg={2} xl={2} style={{margin: 'auto'}}>
                                아이콘
                            </GridItem>

                                <GridItem xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <Box style={{width: '30%'}}>
                                        <input
                                            accept='image/*'
                                            style={{display: 'none'}}
                                            id='contained-button-file-1'
                                            // className={classes.inputBtnUpload}
                                            multiple
                                            type='file'
                                            onClick={onInputClick}
                                            onChange={(e) => handleChangeFile(e, 'main')}
                                        />
                                        <label htmlFor='contained-button-file-1'>
                                            <div style={{display: 'flex'}}>
                                                <Button
                                                    variant='outlined'
                                                    color='github'
                                                    component='span'
                                                    fullWidth={true}
                                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                                    // disabled={loading}
                                                >
                                                    {/*신규등록 image*/}
                                                    {imageFile ? imageFile.name :
                                                        codeVO?.iconImg?.filename
                                                    }
                                                    {imageFile == null && formData.iconImgId == null && '아이콘 이미지 등록해주세요'}
                                                </Button>
                                                {codeVO?.iconImg?.url &&
                                                    <img src={codeVO?.iconImg?.url} style={{'width':'100px'}} />
                                                }
                                            </div>
                                        </label>
                                    </Box>
                                </GridItem>
                                </>

                        )

                    }

                    <GridItem  xs={2} sm={2} lg={2} lg={2} xl={2} style={{margin: 'auto'}}>
                        노출여부
                    </GridItem>
                    <GridItem xs={10} sm={10} md={10} lg={10} xl={10}>
                        <Switch
                            checked={formData?.isShow == true ? true : false}
                            onChange={(e) => {
                                setFormData( {...formData,isShow: e.target.checked})
                            }}
                            // dis값abled={loading}
                            name='checkedB'
                            inputProps={{'aria-label': 'secondary checkbox'}}
                        />
                    </GridItem>
                </GridContainer>
                <Box mt={1} display='flex' justifyContent='center'>
                    <Button
                        onClick={hideAlert}
                        // className={classes.marginRightBtnInModal}
                        color='danger'
                    >
                        취소
                    </Button>
                    <Button
                        color='success'
                        // disabled={loading}지
                        onClick={createCode}
                    >
                        추가
                    </Button>
                </Box>
            </SweetAlert>
        </>
    )


}
ModalCategorylAdd.propTypes = {}
export default ModalCategorylAdd