import React from 'react'

import {makeStyles} from '@material-ui/core/styles'
import Button from 'components/CustomButtons/Button.js'
// import CommentsTable from './components/CommentsTable.js'

import {connect, useDispatch} from 'react-redux'
import {getChildCodeListAction, getCodeListAction, requestCommentsAction} from 'redux/actions/codeManagingAction.js'

import styles from 'assets/jss/material-dashboard-pro-react/views/Notice/notice'
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import ModalCategorylAdd from "./components/ModalCategorylAdd";
import codeManagingApi from "../../api/codeManagingApi";
import CodeManagingApi from "../../api/codeManagingApi";
import CustomButton from "../../components/CustomButtons/Button";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import fileDownload from "js-file-download";
import moment from "moment/moment";
// import Table from './components/CommentsTable'

const useStyles = makeStyles(styles)

const CategoryManaging = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const {
        loading,
        // metaData: {totalPages, totalRecords},
        // requestNoticeAction,
        getChildCodeListAction,
        getCodeListAction, requestCommentsAction,
        codeList,
        subCodeList,
        match
    } = props

    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [orderBy, setOrderBy] = React.useState('first')
    const [order, setOrder] = React.useState('desc')
    const [modal, setModal] = React.useState(null)
    const [depth, setDepth] = React.useState(0)
    const [parentCode, setParentCode] = React.useState(null)
    const [searchText, setSearchText] = React.useState(null)


    const changeIndexOfArr = async (up, down, orderNum, id, index) => {
        // const deepCloneData = [...codeList]
        const currentIndex = index
        if (up) {
            if (index !== 0) {
                // requestTagAction()
                try {
                    let changeUpIndex = index - 1
                    let body = {
                        code: id,
                        action: 'UP',
                    }
                    await CodeManagingApi.updateOrderCode(body)
/*

                    let updateorderNum = deepCloneData.map((item, i) => {
                        if (currentIndex === i) {
                            item.orderNum = item.orderNum - 1
                        }
                        ``
                        if (changeUpIndex === i) {
                            item.orderNum = item.orderNum + 1
                        }

                        return item
                    })
*/

                    // updateorderNum.sort((a, b) => a.orderNum - b.orderNum)
                    // await getNewCode()
                    location.reload()
                } catch (error) {
                    console.log(error.response)
                }
            }
        } else if (down) {
            // if (index !== deepCloneData.length - 1) {
                // requestTagAction()
                try {
                    let changeDownIndex = index + 1
                    let body = {
                        code: id,
                        action: 'DOWN',
                    }
                    await CodeManagingApi.updateOrderCode(body)

                    location.reload()

                    // let updateorderNum = deepCloneData.map((item, i) => {
                    //     if (currentIndex === i) {
                    //         item.orderNum = item.orderNum + 1
                    //     }
                    //
                    //     if (changeDownIndex === i) {
                    //         item.orderNum = item.orderNum - 1
                    //     }
                    //
                    //     return item
                    // })

                    // updateorderNum.sort((a, b) => a.orderNum - b.orderNum)
                    // orderContentAction(updateorderNum)
                    // await getListGoodsManaging()

                } catch (error) {
                    console.log(error.response)
                }
            // }
        }
    }

    const getCodeList = async () => {

        let params = {
            limit: limit,
            offset: page,
            depth,
            parentCode
        }

        try {
            requestCommentsAction()
            const result = await codeManagingApi.getCodeList(params)
            getCodeListAction(result.data)
        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                // noticesWithErrAction(error.response.data)
                console.log(error.response.data)
            }
        }
    }
    const getHottrakcsCodeAndKKoommingCode = async () => {

        let params = {
        }

        try {
            const result = await codeManagingApi.getHottrakcsCodeAndKKoommingCode(params)


            fileDownload(result, '핫트랙스_꾸밍_카테고리_'+  moment(new Date()).format('YYYYMMDD') +'.xlsx')
        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                // noticesWithErrAction(error.response.data)
                console.log(error.response.data)
            }
        }
    }
    const moveGoodsPage = async (childCode) => {
        // location.href = '/'
    }
    const getChildCodeList = async (parentCode) => {

        setParentCode(parentCode)

        let params = {
            limit: limit,
            offset: page,
            depth:1,
            parentCode
        }

        try {
            requestCommentsAction()
            const result = await codeManagingApi.getChildCodeList(params)
            getChildCodeListAction({
                subCodeList: result.data.codeList
            })
        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                // noticesWithErrAction(error.response.data)
                console.log(error.response.data)
            }
        }
    }

    const getNewCode = async (param) => {
        try {
            const {data} = await codeManagingApi.getNewCode(param)
            return data
        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                // noticesWithErrAction(error.response.data)
                console.log(error.response.data)
            }
        }
    }

    const hidemodal = () => {
        setModal(null)
    }
    const popCategory = async (e, t) => {

        const data = await getNewCode({
            depth: 0,
            parentCode: null
        })
        setModal(
            <ModalCategorylAdd
                hideAlert={hidemodal}
                title={'카테고리 추가(대분류)'}
                dispatch={dispatch}
                setModal={setModal}
                searchText={searchText}
                code={data.code}
            />
        )
    }
    const editCode = async (row ,mode) => {

        /*const data = await getNewCode({
            depth: 0,
            parentCode: null
        })*/
        setModal(
            <ModalCategorylAdd
                hideAlert={hidemodal}
                title={'카테고리 수정'}
                dispatch={dispatch}
                setModal={setModal}
                searchText={searchText}
                mode={mode}
                code={row.code}
                codeVO={row}
            />
        )
    }
    const subPopCategory = async (e, t) => {
        if (parentCode == null || parentCode == ''){
            alert('대분류를 먼저 선택해주세요')
            return
        }


        const data = await getNewCode({
            depth: 1,
            parentCode: parentCode
        })
        setModal(
            <ModalCategorylAdd
                hideAlert={hidemodal}
                title={'카테고리 추가(중분류)'}
                dispatch={dispatch}
                setModal={setModal}
                searchText={searchText}
                getCodeList={getCodeList}
                code={data.code}
            />
        )
    }

    React.useEffect(() => {
        getCodeList()
    }, [page + 1, limit, order]) //해당 값들이  state값이 변하면 해당 부분 실행됨....


    return (
        <div className='notice'>

            <Button color='primary'
                    onClick={(event) => getHottrakcsCodeAndKKoommingCode()}
            >
                핫트랙스-꾸밍 카테고리 현황
            </Button>


            <TableContainer component={Paper}>
                {modal}
                <Paper
                    variant='outlined'
                    square
                >
                    <GridContainer className={[classes.paperSwitch]}>
                        <GridItem
                            // className={classes.symBolTag}
                            container
                            alignItems='center'
                            justifyContent='center'
                            xs={7}
                            sm={7}
                            md={7}
                            lg={7}
                            xl={7}
                        >
                            대분류
                        </GridItem>
                        <GridItem
                            // className={classes.symBolTag}
                            container
                            alignItems='center'
                            justifyContent='center'
                            xs={5}
                            sm={5}
                            md={5}
                            lg={5}
                            xl={5}
                        >
                            중분류
                        </GridItem>
                    </GridContainer>

                    <Paper
                        // key={i}
                        // className={[classes.paper, !(new Date(item.startAt).getTime() < new Date().getTime()  &&   new Date().getTime() < new Date(item.endAt).getTime()) && classes.notNow ]}
                        variant='outlined'
                        square
                    >
                        <GridContainer>
                            <GridItem

                                container
                                alignItems='center'
                                justifyContent='center'
                                xs={7}
                                sm={7}
                                md={7}
                                lg={7}
                                xl={7}
                            >
                                <div className={classes.w100 }
                                >
                                <div className={classes.p20}>
                                    순서
                                </div>
                                <div className={classes.p20}>
                                    코드번호
                                </div>
                                <div className={classes.p20}>
                                    이름
                                </div>
                                <div className={classes.p20}>
                                    아이콘
                                </div>
                                <div className={classes.p20}>
                                    노출여부
                                </div>
                                <div className={classes.p20}>
                                    상품개수
                                </div>
                                <div className={classes.p20}>
                                    수정
                                </div>
                                <div className={classes.p20}>
                                    정렬
                                </div>
                                </div>

                                {codeList?.map((row, i) => {
                                    return (
                                        <div className={classes.w100 + ' ' + (row.code == parentCode && classes.backgroundGrey)}
                                        >
                                            <div className={classes.p20}>
                                                {row.orderNum}
                                            </div>
                                            <div className={classes.p20} onClick={()=>{getChildCodeList(row.code)}}>
                                                {row.code}
                                            </div>
                                            <div className={classes.p20}>
                                                {row.codeName}
                                            </div>
                                            <div style={{maxWidth: '30px'}}>
                                                <img src={row?.iconImg?.url} style={{maxWidth: '30px'}} alt=""/>
                                            </div>
                                            <div style={{maxWidth: '30px'}}>
                                                {row.isShow ? '노출'  : '미노출'}
                                            </div>
                                            <div onClick={(e)=>{
                                                // moveGoodsPage(row.code)
                                                // location.href =
                                                window.open('/admin/writer-goods-managing?parentCode=' + row.code)

                                                    // + '&childCode=' + row.code

                                            }} style={{'color': 'blue', 'textDecoration': 'underline', }}>
                                                {row.totalGoodsCount}
                                            </div>
                                            <CustomButton color="primary" mr={2} variant="contained"
                                                          onClick={() => editCode(row, 'parent')}
                                            >수정</CustomButton>

                                            <div>
                                                <div >
                                                    {<IconButton
                                                        size='small'
                                                        disabled={loading}
                                                        onClick={() =>
                                                            changeIndexOfArr(
                                                                true,
                                                                false,
                                                                row.orderNum,
                                                                row.code,
                                                                i,
                                                            )
                                                        }
                                                    >
                                                        <ExpandLessIcon/>
                                                    </IconButton>}
                                                </div>
                                                <div>
                                                    {<IconButton
                                                        size='small'
                                                        disabled={loading}
                                                        onClick={() =>
                                                            changeIndexOfArr(
                                                                false,
                                                                true,
                                                                row.orderNum,
                                                                row.code,
                                                                i,
                                                            )
                                                        }
                                                    >
                                                        <ExpandMoreIcon/>
                                                    </IconButton>}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </GridItem>
                            <GridItem
                                className={classes.align + ' ' +classes.displayBlock}
                                container
                                alignItems='center'
                                justifyContent='center'
                                xs={5}
                                sm={5}
                                md={5}
                                lg={5}
                                xl={5}
                            >
                                <div className={classes.w100}>

                                    <div className={classes.p20}>
                                        코드번호
                                    </div>
                                    <div className={classes.p20}>
                                        이름
                                    </div>
                                    <div className={classes.p20}>
                                        상품개수
                                    </div>
                                    <div className={classes.p20}>
                                        수정
                                    </div>정
                                </div>

                                {subCodeList?.map((row, i) => {
                                    return (
                                        <div className={classes.w100}
                                             // onClick={()=>{
                                            // getChildCodeList(row.code)}}
                                            >
                                            <div className={classes.p20}>
                                                {row.code}
                                            </div>
                                            <div className={classes.p20}>
                                                {row.codeName}
                                            </div>
                                            <div onClick={(e)=>{
                                                // moveGoodsPage(row.code)
                                                // location.href =
                                                window.open('/admin/writer-goods-managing?parentCode=' + row.code.substring(0,4) + '&childCode=' + row.code )

                                                // + '&childCode=' + row.code

                                            }} style={{'color': 'blue', 'textDecoration': 'underline', }}>
                                                {row.totalGoodsCount}
                                            </div>
                                            <CustomButton color="primary" mr={2} variant="contained"
                                                          onClick={() => editCode(row, 'child')}
                                            >수정</CustomButton>


                                        </div>
                                    )
                                })}
                            </GridItem>
                        </GridContainer>
                    </Paper>
                    <Paper
                        // key={i}
                        // className={[classes.paper, !(new Date(item.startAt).getTime() < new Date().getTime()  &&   new Date().getTime() < new Date(item.endAt).getTime()) && classes.notNow ]}
                        variant='outlined'
                        square
                    >
                        <GridContainer>
                            <GridItem
                                // className={classes.symBolTag}
                                container
                                alignItems='left'
                                justifyContent='left'
                                xs={7}
                                sm={7}
                                md={7}
                                lg={7}
                                xl={7}
                            >
                                <Button color='primary'
                                        onClick={(event) => popCategory(event,
                                            null
                                            // item
                                        )}
                                >
                                    카테고리 추가
                                </Button>
                            </GridItem>
                            <GridItem
                                // className={classes.symBolTag}
                                container
                                alignItems='left'
                                justifyContent='left'
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                            >
                                <Button color='primary'
                                        onClick={(event) => subPopCategory(event,
                                            null
                                            // item
                                        )}
                                >
                                    카테고리 추가
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </Paper>


                </Paper>
            </TableContainer>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {

        loading: state.codeManagingReducer.loading,
        code: state.codeManagingReducer.code,
        codeList: state.codeManagingReducer.codeList,
        subCodeList: state.codeManagingReducer.subCodeList
        // metaData: state.codeManagingReducer.metaData,

    }
}

export default connect(mapStateToProps, {
    getCodeListAction, requestCommentsAction,getChildCodeListAction
    // updateCommentsAction
})(CategoryManaging)
