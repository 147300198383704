import axiosInterceptors from 'api/axios'

const writerScheduleManagingApi = {

	getListSchedule: (params) => {
		const url = '/schedule/writerScheduleManaging/getListSchedule'
		return axiosInterceptors.get(url, { params })
	},

	setExcelFile: (body) => {
		const url = '/schedule/writerScheduleManaging/setExcelFile'
		return axiosInterceptors.post(url, body)
	},

	deleteWriterSchedule: (params) => {
		const url = '/schedule/writerScheduleManaging/delete'
		return axiosInterceptors.delete(url, { params })
	},

	getSchedule: (params) => {
		const url = '/schedule/writerScheduleManaging/getWriterSchedule'
		return axiosInterceptors.get(url, { params })
	},
	create: (params)=> {
		const url = `/schedule/writerScheduleManaging/create`
		return axiosInterceptors.post(url, { params })
	},
	update: (body)=> {
		const url = `/schedule/writerScheduleManaging/update`
		return axiosInterceptors.put(url, body)
	}

}

export default writerScheduleManagingApi