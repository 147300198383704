//postInto Table component


import React, {useEffect} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import Switch from "../../Notice/components/Swtich";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Pagination from "components/Pagination/Pagination";
// import Button from "@material-ui/core/Button";
import CustomButton from 'components/CustomButtons/Button.js'
import UserDetail from "../../UserManaging/UserDetail";
import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import routes from "../../../routes";
import PostDetail from "../../PostManaging/PostDetail";
import {useHistory} from "react-router-dom";

const useRowStyles = makeStyles({
    table: {
        minWidth: 900,
    },
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    styleBox: {
        backgroundColor: '#EDEDED',
    },
})

/**
 * 게시판 정보 테이블 row
 * @param props
 * @returns {*}
 * @constructor
 */
const Row = (props) => {
    const { row, index, page, selected, setSelected, selEdit,postInfoTypeList } = props
    const classes = useRowStyles()
    const history = useHistory()

    const [open, setOpen] = React.useState(false)

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        setSelected(newSelected)
    }

    const handleEditPostInfo = (row, index) => {
        // postInfo 수정
        //reducer로 전달??
    }

    const handleSelEdit = (e,row)=> {
        selEdit(row);
    }
    const handleDeletePostInfo = (e,row) => {
        // postInfo 삭제
    }
    const handleBoardList = (e,id) => {
        // postInfo 삭제
        if (isPreventOnRowClick) return
        history.push({
            pathname: '/admin/post-list/' + id,
            state: { listId: id },
        })
    }


    const isSelected = (id) => selected.indexOf(id) !== -1
    const isItemSelected = isSelected(row?.id)
    const labelId = `enhanced-table-checkbox-${index}`

    const number = page === 0 ? index + 1 : index + 1 + parseInt(`${page}0`)
    const [isPreventOnRowClick, setIsPreventOnRowClick] = React.useState(false)



    // 테이블 row 구성
    return (
        <React.Fragment>

            {/*{검색창}*/}


            {/*{pageRow}*/}

            <TableRow hover={true} className={classes.root}>

                {/*{페이지 row 및 검색도 같이 넣기}*/}


                <TableCell padding='checkbox'>
                    <Checkbox
                        onClick={(event) => handleClick(event, row?.id)}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                    <IconButton
                        size='small'
                        onClick={() => setOpen(!open)}
                    >
                    </IconButton>
                </TableCell>
                <TableCell component='th' scope='row' onClick={(event) => handleBoardList(event, row?.id)}>

                    {row.description}

                </TableCell>
                <TableCell align='center'>
                    {moment(row.createdAt).format('YYYY-MM-DD')}
                </TableCell>
                <TableCell align='center'>
                    {postInfoTypeList.map(
                        (option, index) => (
                            option.key == row.postInfoType && option.name
                        ))}
                </TableCell>
                <TableCell align='center'>
                    <Switch id={row.id} isShow={row.isShow} />
                </TableCell>
                <TableCell align='center'>
                    {/*수정삭제*/}

                    {/*Button.js*/}
                    <CustomButton color="primary" mr={2} variant="contained" onClick={(event) => handleSelEdit(event,row)} >수정</CustomButton>
                    <CustomButton color="danger" onClick={handleDeletePostInfo(row)} variant="contained">삭제</CustomButton>

                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box m={1} p={1} className={classes.styleBox} component={Paper}>
                            <Typography gutterBottom component='p'>
                                {row.content}
                            </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}


/**
 * 게시판 정보 테이블 전체 컴포넌트
 * @param props
 * @returns {*}
 * @constructor
 */
const PostInfoTables = (props) => {
    const classes = useRowStyles()

    const {
        postInfoTypeList,
        setFormData,
        setIsShowPostInfoInputField,
        rows,
        setPostInfoType,
        setMode
    } = props

    const [selected, setSelected] = React.useState([])

    const selEdit = async (data) =>{
        //setState를 갖고왔기때문에 가능함
        setFormData(data)
        setMode('UPDATE')
        setPostInfoType(data.postInfoType) // groupbutton 바꾸기 위헤ㅐ
        setIsShowPostInfoInputField(true)
    }

    return (
        <TableContainer component={Paper}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <Table className={classes.table} aria-label='collapsible table'>
                <TableHead>
                    <TableRow>
                        <TableCell
                            padding='checkbox'
                            style={{ width: '50px', maxWidth: '50px' }}
                        ></TableCell>
                        <TableCell

                            style={{
                                width: 50,
                            }}
                        >
                            Id.
                        </TableCell>
                        <TableCell
                            style={{
                                width: 50,
                            }}
                        />
                        <TableCell
                            style={{
                                minWidth: 170,
                                width: 450,
                            }}
                        >
                            게시판 명
                        </TableCell>
                        <TableCell
                            align='center'
                            style={{
                                minWidth: 170,
                            }}
                        >
                            생성 일자
                        </TableCell>
                        <TableCell
                            align='center'
                            style={{
                                minWidth: 170,
                            }}
                        >
                            게시판 타입
                        </TableCell>
                        <TableCell
                            align='center'
                            style={{
                                minWidth: 170,
                            }}
                        >
                            표출여부
                        </TableCell>
                        <TableCell
                            align='center'
                            style={{
                                minWidth: 170,
                            }}
                        >
                            수정/삭제
                        </TableCell>

                        {/*<TableCell
                            align='right'
                            style={{
                                minWidth: 170,
                            }}
                        ></TableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => {
                        return (
                            <Row
                                key={row.id}
                                row={row}
                                index={i}
                                // page={page}
                                setSelected={setSelected}
                                selected={selected}
                                postInfoTypeList={postInfoTypeList}
                                selEdit={selEdit} //수정삭제버튼

                            />
                        )
                    })}
                </TableBody>
                {/*{테이블페이지 네이션테이블페이지 네이션 기존게 맘에 안들어서 바꿈}*/}
                <TableFooter>
                    {/*<TableRow>*/}
                    {/*    <TablePagination*/}
                    {/*        colSpan={6}*/}
                    {/*        count={totalRecords ? totalRecords : 0}*/}
                    {/*        rowsPerPage={rowsPerPage}*/}
                    {/*        page={page}*/}
                    {/*        onPageChange={handleChangePage}*/}
                    {/*        onRowsPerPageChange={handleChangeRowsPerPage}*/}
                    {/*        ActionsComponent={TablePaginationActions}*/}
                    {/*    />*/}
                    {/*</TableRow>*/}
                </TableFooter>


            </Table>

            <Box mb={2} mt={2} display='flex' justifyContent='flex-end'>
                <Pagination
                    pagination={1}
                    setPagination={1}
                    totalPages={10}
                />
            </Box>

        </TableContainer>

    )
}
export default PostInfoTables



