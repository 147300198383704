import { combineReducers } from 'redux'
import auth from './auth'
import tagManaging from './tagManaging'
import notice from './notice'
import bestDecorating from './mainManaging/bestDecorating'
import postManaging from './postManaging'
import userManaging from './userManagingReducer'
import writerManaging from './writerManagingReducer'
import writerSchedulesManaging from './writerSchedulesManagingReducer'
import WriterGoodsManaging from './writerGoodsManagingReducer'
import writerMainManaging from './writerMainManagingReducer'
import bestUser from './mainManaging/bestUserReducer'
import reportBlockManaging from './reportBlockManagingReducer'
import questionAndAnswer from './questionAndAnswerReducer'
import managerManaging from './managerManagingReducer'
import staticsOfClick from './staticsOfClickReducer'
import staticsOfSearch from './staticsOfSearchReducer'
import staticsOfJoinOut from './staticsOfJoinOutReducer'
import bannerManaging from './mainManaging/bannerManagingReducer'
import ggoomingChallenge from './mainManaging/ggoomingChallengeReducer'
import postInfoReducer from './mainManaging/postInfoReducer'
import eventsReducer from './eventsManagingReducer'
import pushInfoReducer from './pushManagingReducer'
import splashManagingReducer from './splashManagingReducer'
import commentsReducer from './commentManagingReducer'
import contentsReducer from './contentsManagingReducer'
import phrasesSettingReducer from './phrasesSettingReducer'
import goodsManagingReducer from './goodsManagingReducer'
import codeManagingReducer from './codeManagingReducer'
import staticsPostReducer from './staticsPostReducer'
import staticsUserReducer from './staticsUserReducer'
import versionManagingReducer from './versionManagingReducer'
import aiCommentManagingReducer from './aiManagingReducer'

export default combineReducers({
  auth,
  tagManaging,
  notice,
  bestDecorating,
  postManaging,
  userManaging,
  writerManaging,
  writerSchedulesManaging,
  WriterGoodsManaging,
  writerMainManaging,
  bestUser,
  reportBlockManaging,
  questionAndAnswer,
  managerManaging,
  staticsOfClick,
  staticsOfSearch,
  staticsOfJoinOut,
  bannerManaging,
  ggoomingChallenge,
  postInfoReducer,
  eventsReducer,
  pushInfoReducer,
  splashManagingReducer,
  commentsReducer,
  contentsReducer,
  goodsManagingReducer,
  phrasesSettingReducer,
  codeManagingReducer,
  staticsPostReducer,
  staticsUserReducer,
  versionManagingReducer,
  aiCommentManagingReducer
})
