import {connect, useDispatch} from "react-redux";
import Paper from "@material-ui/core/Paper";
import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/Notice/notice2style";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "../../components/Gm-TextField/TextFieldForDatePicker";
import Box from "@material-ui/core/Box";
import TimePicker from "../Statistics/components/TimePicker2";
import TimePickerMin from "../Statistics/components/TimePickerMin";
import Button from "../../components/CustomButtons/Button";
import CustomButton from "../../components/CustomButtons/Button";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import moment from "moment";
import Spinner from "../../components/Spinner/Spinner";
import PostModal from './components/PostModal.js'
import TagsInput from 'react-tagsinput';
//자동제안은 react suggest
import stylesModal from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import bannerManagingApi from "../../api/mainManaging/bannerManagingApi";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MenuItem from "@material-ui/core/MenuItem";
import CustomTextField from "../../components/Gm-TextField/TextField";
import eventsManagingApi from "../../api/eventsManagingApi.js";
import {getListEventsAction} from "../../redux/actions/eventsManagingAction.js";
import postManagingApi from "../../api/postManagingApi.js";
import {getListUserAction, requestUserManagingAction} from "../../redux/actions/userManagingAction";
import contentManagingApi from "../../api/contentManagingApi";
import {dateTimeCheckThree, handleFormEvent, handleFormValue} from "../../util/common";
// import contentsManagingApi from "../../api/contentManagingApi";

const useStyles = makeStyles(styles)
const useStylesModal = makeStyles(stylesModal)

// 이벤트 상세임 아무튼 그럼
const ContentsMultiDetail = (props) => {

    const {
        match,
        ContentsMultiDetail,
        events,
        users,
        metaData: {totalRecords}, // user에 대한
    } = props

    const classes = useStyles()
    const classesModal = useStylesModal()
    const dispatch = useDispatch()

    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz

    const [loading, setLoading] = React.useState(false)

    // const [tags, setTags] = React.useState([]);
    const [formData, setFormData] = React.useState({
        id: isNaN(match?.params?.id) ? 0 : match?.params?.id,
        title: ContentsMultiDetail != null ? ContentsMultiDetail.title : '',
        tags: [],
        contents: '',
        files: '',

        startAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        endAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        fromTime: 0,
        fromMin: 0,
        toTime: 23,
        toMin: 0,

        linkUrl: '',
        type: 'WEB_VIEW',

        eventsId: null,
        postId: null,

    })
    const [imageFile, setImageFile] = React.useState(null)
    const [loadingBtn, setLoadingBtn] = React.useState(false)
    const [alert2, setAlert] = React.useState(null)
    const [posts, setPosts] = React.useState([])


    const createContents = async () => {


        try {
            //내부, 외부 URL 체크
            //이벤트일때 이벤트 선택 필수
            //챌린지일때 챌린지 선택 필수
            const fd = new FormData()


            let body = {
                id: formData.id,
                title: formData.title,
                tags: formData.tags.filter(item => item !== "").join(','),
                multiType: match?.params?.type || 'SHOP_CONTENT',

                contents: formData?.contents || '',
                startAt: new Date(moment(dateTimeCheckThree(formData.startAt, formData.fromTime, formData.fromMin)).format("YYYY-MM-DD HH:mm")).getTime(),
                endAt: new Date(moment(dateTimeCheckThree(formData.endAt, formData.toTime, formData.toMin)).format("YYYY-MM-DD HH:mm")).getTime(),

                type: formData.type,
                linkUrl: formData.linkUrl,

                eventsId: formData.eventsId,
                postId: posts[0]?.id || null
            }



            if (body?.type == 'FEED' && (body?.postId == null || body?.postId == 0)){
                alert("피드를 선택해주세요")
                return
            }



            if (body?.type == 'TAG'){
                if (!body?.linkUrl?.includes('#')){
                    body.linkUrl = '#' + body.linkUrl
                }
            }
            if (body?.type == 'STYLE_TAG'){
                // if (!body?.linkUrl.contains('#')){
                    body.linkUrl =  body.linkUrl
                // }
            }
            if (body?.type == 'WRITER_TAG'){
                if (!body?.linkUrl?.includes('@')){
                    body.linkUrl = '@'+ body.linkUrl
                }
            }


            if (imageFile == null) {
                //파일이 없을떄, 기존 썸네일 수정할떄
                if (parseInt(formData?.id) == 0){
                    alert("썸네일을 넣어주세요")
                    return
                }
                if (formData.thumbnailImage != null && formData.thumbnailImage != '') {
                    body.thumbnail = formData.thumbnailImage.id
                }

            } else {

                //파일 새로 들어올떄
                setLoadingBtn(true)
                fd.append('file', imageFile, imageFile.name)
                fd.append('type', 'SHOP')
                setLoadingBtn(true)
                const {
                    data: {id: idImage, url, filename},
                } = await bannerManagingApi.uploadImage(fd);
                body.thumbnail = idImage
                setLoadingBtn(false)
            }


            //업데이트 일때
            if (formData.id != 0) {


                const {data} = await contentManagingApi.update(body);

                if (data) {
                    alert("수정이 완료되었습니다.")
                    location.href = '/admin/content-multi-managing/' + match?.params?.type
                    // Router.push('/admin/event-managing/' + formData.type)
                    // location.href = '/admin/event-mnaging/CHALLENGE'
                }
                return
            }


            const {data} = await contentManagingApi.create(body);

            setLoadingBtn(false)


            // if (data.id != null) {
                alert("저장이 완료되었습니다.")
                location.href = '/admin/content-multi-managing/' + match?.params?.type
            // }


        } catch (e) {

        }


    }
    /*이벤트 조회*/
    const getListEvents = async (type) => {

        let orderString = '';
        orderString = 'numOrder,DESC&createdAt,DESC';

        let params = {
            limit: 10000,
            offset: 0,
            type: type,
            order: orderString,
            searchText: '',
            searchKey: 'title',
            endAt: 'more60day'
        }

        try {

            const {data} = await eventsManagingApi.getListEvents(params)
            dispatch(getListEventsAction(data))

        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                // noticesWithErrAction(error.response.data)
                console.log(error.response.data)
            }
        }
    }


    //이벤트 챌린지 변할 시
    useEffect(() => {
        getListEvents(formData?.type)
    }, [formData.type]);


    const handleChangeTimePickerStart = (event) => {
        setFormData({...formData, fromTime: event.target.value})
    }
    const handleChangeFile = (e, type) => {
        //파일 Onchage 시 썸네일 체인지
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            const img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.addEventListener('load', () => {
                setImageFile(file) // 일단 바로 체크
            })
        }
    }

    const hideAlert = () => {
        setAlert(null)
    }

    //회원 Modal
    // Modal
    const showFeedModal = () => {

        //insert
        setAlert(
             <PostModal
                 item={users}
                 hideAlert={hideAlert}
                 setAlert={setAlert}
                 type={formData.type}
                 // getMemberList={getMemberList}
                 postManagingApi={postManagingApi}
                 dispatch={dispatch}
                 setPosts={setPosts}
                 posts={posts}
             />
         )


    }

    const delMemberList = (i) => {
        let member2 = posts.splice(0, i)
        if (posts.splice(posts.length).length > 0) {
            member2.push(posts.splice(posts.length))
        }
        setPosts(member2)
    }


    //modal에서 가져왔을때
    useEffect(() => {
    }, [posts, formData.isSend]);


    //UPDATE일떄
    const getContentsData = async () => {
        try {

            setLoading(true)

            if (formData.id > 0) {
                //데이터가 있는 것

                //setLoading 필요
                const data1 = await contentManagingApi.getContentsDetail({id: parseInt(formData.id)});
                //데이터 매핑 dispatch쓰면 코드가 깔끔해지나 쓰지 않음

                const data = data1?.data?.content
                if (data.type == 'FEED'){
                    setPosts([data1?.data?.post])
                }



                const tagsRe = []


                //가져온데이터 다시 매핑
                setFormData({
                    id: isNaN(match?.params?.id) ? 0 : match?.params?.id,
                    title: data?.title || '',
                    tags: data?.tags == null ? [] :  data?.tags?.split(','),
                    multiType: match?.params?.type || 'SHOP_CONTENT',
                    contents: data?.contents || [],

                    startAt: moment(data.startAt).format('YYYY-MM-DD'),
                    endAt: moment(data.endAt).format('YYYY-MM-DD'),
                    fromTime: parseInt(moment(data.startAt).format('HH')),
                    fromMin: parseInt(moment(data.startAt).format('mm')),
                    toTime: parseInt(moment(data.endAt).format('HH')),
                    toMin: parseInt(moment(data.endAt).format('mm')),

                    type: data.type,
                    linkUrl: data?.linkUrl || '',
                    thumbnailImage: data?.thumbnailImage || null,
                    eventsId: data.eventsId || null,   // postId: posts[0]?.id || null
                })
                setLoading(false)

            }
            setLoading(false)

        } catch (e) {

        }
    }

    React.useEffect(() => {
        //getData
        getContentsData()
    }, [formData.id])


    return (
        <div>
            {loading ? (<Spinner/>) : (<div>
                {alert2}
                <Paper variant='outlined' className={classes.paper}>
                    <GridContainer className={classes.grid} alignItems='center'>
                        {/*<GridItem xs={1} sm={1} md={1}>푸시 제목</GridItem>*/}
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'내용 제목'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleFormEvent(event, 'title', formData, setFormData)
                                    },
                                }}
                                value={formData.title}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />
                        </GridItem>

                        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                            태그
                        </GridItem>
                        <GridItem xs={5} sm={5} md={5} className={classes.lableSt}>

                            <TagsInput
                                // className={classes.tagInput}
                                //이벤트가 2번 실행됨 why?
                                value={formData.tags}
                                onChange={(e) => {
                                    handleFormValue(e, 'tags', formData, setFormData)
                                }}
                                tagProps={{className: "react-tagsinput-tag"}}
                            />
                        </GridItem>


                    </GridContainer>

                    <GridContainer className={classes.grid} alignItems='center'>

                        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                            부제목
                        </GridItem>

                        <GridItem xs={5} sm={5} md={5}>
                            <Box className='notice-detail'>
                                <TextareaAutosize
                                    // className={classes.contentsStyle}
                                    minRows={5}
                                    maxRows={9}
                                    aria-label='maximum height'
                                    placeholder='내용을 입력해주세요'
                                    value={formData?.contents}
                                    // onChange={handleForm(, 'startAt')}
                                    onChange={(e) =>
                                        handleFormValue(e.target.value, 'contents', formData, setFormData)
                                    }
                                    name='content'
                                    style={{width: '100%', height: '50px'}}
                                />
                            </Box>
                        </GridItem>
                        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                            썸네일
                        </GridItem>


                        <GridItem xs={5} sm={5} md={5}>
                            <input
                                accept='image/*'
                                className={classes.inputBtnUpload}
                                id='contained-button-file'
                                type='file'
                                onClick={(event => {
                                    event.target.value = ''
                                })}
                                onChange={(e) => {
                                    handleChangeFile(e, 1)
                                }}
                            />
                            <label htmlFor='contained-button-file'>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    component='span'
                                    fullWidth={true}
                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                >
                                    {imageFile
                                        ? imageFile.name
                                        : ' 이미지를 첨부하세요'}
                                </Button>
                            </label>
                            {
                                formData?.thumbnailImage != null ? <img src={formData?.thumbnailImage?.url} width={300}/> : null
                            }

                        </GridItem>

                    </GridContainer>

                    <GridContainer className={classes.grid} alignItems='center'>



                    </GridContainer>

                    <GridContainer className={classes.grid} alignItems='center'
                                   style={{marginTop: '10px', marginBottom: '10px'}}>
                    </GridContainer>
                    <GridContainer className={classes.grid} alignItems='center' style={{marginBottom: '10px'}}>

                        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                            노출 기간
                        </GridItem>

                        <GridItem
                            xs={2}
                            sm={2}
                            // className={classes.styleDatePicker}
                        >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant='inline'
                                    TextFieldComponent={TextField}
                                    format='yyyy-MM-dd'
                                    id='date-picker-inline1'
                                    value={formData?.startAt}
                                    onChange={(date) =>
                                        handleFormValue(date, 'startAt', formData, setFormData)
                                    }
                                    autoOk={true}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </GridItem>
                        <GridItem
                            xs={1}
                            sm={1}
                            // className={classes.styleDatePicker}
                        >

                            <Box>
                                <TimePicker
                                    time={formData?.fromTime}
                                    handleChangeTimePicker={handleChangeTimePickerStart}
                                />
                            </Box>
                        </GridItem>
                        <GridItem
                            xs={1}
                            sm={1}
                            // className={classes.styleDatePicker}
                        >

                            <Box>
                                <TimePickerMin
                                    time={formData?.fromMin}
                                    handleChangeTimePicker={(e) => {
                                        handleFormValue(e.target.value, 'fromMin', formData, setFormData)
                                    }}
                                />
                            </Box>
                        </GridItem>
                        ~
                        <GridItem
                            xs={2}
                            sm={2}
                            // className={classes.styleDatePicker}
                        >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant='inline'
                                    TextFieldComponent={TextField}
                                    format='yyyy-MM-dd'
                                    id='date-picker-inline1'
                                    value={formData?.endAt}
                                    onChange={(date) =>
                                        handleFormValue(date, 'endAt', formData, setFormData)
                                    }
                                    autoOk={true}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </GridItem>
                        <GridItem
                            xs={1}
                            sm={1}
                            // className={classes.styleDatePicker}
                        >

                            <Box>
                                <TimePicker
                                    time={formData?.toTime}
                                    handleChangeTimePicker={handleChangeTimePickerStart}
                                />
                            </Box>
                        </GridItem>
                        <GridItem
                            xs={1}
                            sm={1}
                            // className={classes.styleDatePicker}
                        >

                            <Box>
                                <TimePickerMin
                                    time={formData?.tomMin}
                                    handleChangeTimePicker={(e) => {
                                        handleFormValue(e.target.value, 'tomMin', formData, setFormData)
                                    }}
                                />
                            </Box>
                        </GridItem>

                    </GridContainer>
                    <GridContainer className={classes.grid} alignItems='center' style={{marginBottom: '10px'}}>
                        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                            연결 타입
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            {/*{formData.type}*/}
                            <TextField
                                id='user-managing-select'
                                select
                                size='small'
                                className={classes.widthTextFieldModal}
                                value={formData.type}
                                onChange={(e) => {
                                    handleFormValue(e.target.value, 'type', formData, setFormData)
                                }}
                                label='연결 타입을 선택해주세요'
                                style={{width: '100%'}}
                            >
                                <MenuItem value={'FEED'}>피드</MenuItem>
                                <MenuItem value={'STAR'}>일러스트</MenuItem>
                                <MenuItem value={'POST_GOODS'}>굿즈</MenuItem>
                                {/*<MenuItem value={'VOTE'}>투표문답</MenuItem>*/}
                                <MenuItem value={'WEB_VIEW'}>내부 URL</MenuItem>
                                <MenuItem value={'URL'}>외부 URL</MenuItem>
                                <MenuItem value={'EVENT'}>이벤트</MenuItem>
                                <MenuItem value={'CHALLENGE'}>챌린지</MenuItem>
                                <MenuItem value={'TAG'}>태그</MenuItem>
                                <MenuItem value={'STYLE_TAG'}>스타일태그</MenuItem>
                                <MenuItem value={'WRITER_TAG'}>작가태그</MenuItem>
                            </TextField>
                        </GridItem>

                        <GridItem xs={3} sm={3} md={3}>
                            {formData.type == 'TAG' || formData.type == 'WRITER_TAG' ? (
                                <CustomTextField
                                    id='outlined-basic'
                                    value={formData?.linkUrl}
                                    name='linkUrl'
                                    onChange={(e) => handleFormValue(e.target.value, 'linkUrl', formData, setFormData)}
                                    label='태그를 입력하세요'
                                    className={classes.linkTypeInput}
                                    style={{width: '100%'}}
                                />
                            ) : null}

                            {formData.type == 'STYLE_TAG' ?
                                (
                                    // 이벤트
                                    <TextField
                                        id='user-managing-select'
                                        select
                                        size='small'
                                        className={classes.widthTextFieldModal}
                                        value={formData?.linkUrl}
                                        onChange={(e) => handleFormValue(e.target.value, 'linkUrl', formData, setFormData)}
                                        label='스타일 태그를 선택해주세요'
                                        style={{width: '100%'}}
                                    >
                                        <MenuItem key={'KITCH'} title={'키치'}
                                                  value={'KITCH'}>{'키치'}</MenuItem>
                                        <MenuItem key={'LOVELY_CUTE'} title={'러블리큐트'}
                                                  value={'LOVELY_CUTE'}>{'러블리큐트'}</MenuItem>
                                        <MenuItem key={'CHIC'} title={'시크'}
                                                  value={'CHIC'}>{'시크'}</MenuItem>
                                        <MenuItem key={'EMOTION'} title={'감정'}
                                                  value={'EMOTION'}>{'감정'}</MenuItem>
                                        <MenuItem key={'DREAM'} title={'몽환'}
                                                  value={'DREAM'}>{'몽환'}</MenuItem>
                                        <MenuItem key={'VINTAGE'} title={'빈티지'}
                                                  value={'VINTAGE'}>{'자연'}</MenuItem>
                                        <MenuItem key={'NATURAL'} title={'자연'}
                                                  value={'NATURAL'}>{'키치'}</MenuItem>
                                        <MenuItem key={'ANIMAL'} title={'동물'}
                                                  value={'ANIMAL'}>{'동물'}</MenuItem>
                                        <MenuItem key={'TYPO'} title={'타이포'}
                                                  value={'TYPO'}>{'타이포'}</MenuItem>
                                        <MenuItem key={'SIMPLE'} title={'심플'}
                                                  value={'SIMPLE'}>{'심플'}</MenuItem>
                                    </TextField>
                                ) : null
                            }


                            {formData.type == 'URL' || formData.type == 'WEB_VIEW' ? (
                                <CustomTextField
                                    id='outlined-basic'
                                    value={formData?.linkUrl}
                                    name='linkUrl'
                                    onChange={(e) => handleFormValue(e.target.value, 'linkUrl', formData, setFormData)}
                                    label='URL을 입력하세요'
                                    className={classes.linkTypeInput}
                                    style={{width: '100%'}}
                                />
                            ) : null}
                            {formData.type == 'EVENT' ?
                                (
                                    // 이벤트
                                    <TextField
                                        id='user-managing-select'
                                        select
                                        size='small'
                                        className={classes.widthTextFieldModal}
                                        value={formData.eventsId}
                                        onChange={(e) => {
                                            handleFormValue(e.target.value, 'eventsId', formData, setFormData);
                                        }}
                                        label='이벤트를 선택해주세요(종료된 지 60일이후 이벤트 제외)'
                                        style={{width: '100%'}}
                                    >
                                        {
                                            events.map((event, i) => (
                                                <MenuItem key={event.id} title={event.title}
                                                          value={event.id}>{event.title}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                ) : null
                            }
                            {formData.type == 'CHALLENGE' ?
                                (
                                    //  챌린지
                                    <TextField
                                        id='user-managing-select'
                                        select
                                        size='small'
                                        className={classes.widthTextFieldModal}
                                        onChange={(e) => {
                                            handleFormValue(e.target.value, 'eventsId', formData, setFormData);
                                        }}
                                        value={formData.eventsId}
                                        label='챌린지를 선택해주세요(종료된 지 60일이후 챌린지 제외)'
                                        style={{width: '100%'}}
                                    >
                                        {
                                            events.map((event, i) => (
                                                <MenuItem key={event.id} title={event.title}
                                                          value={event.id}>{event.title}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                ) : null
                            }
                        </GridItem>
                    </GridContainer>
                    {
                        (formData?.type == 'FEED' ||
                        formData?.type == 'STAR' ||
                        formData?.type == 'POST_GOODS')
                        && (
                            <GridContainer className={classes.grid} alignItems='center'>
                                <GridItem xs={1} sm={1} md={1} className={classes.lableSt}>
                                    {formData?.type == 'FEED' && '피드'}
                                    {formData?.type == 'STAR' && '일러스트'}
                                    {formData?.type == 'POST_GOODS' && '굿즈'}
                                    선택
                                </GridItem>
                                <GridItem xs={2} sm={2} md={2}>
                                    <CustomButton color="primary" mr={2} variant="contained"
                                                  onClick={showFeedModal}
                                    >검색</CustomButton>
                                </GridItem>
                                <GridItem xs={2} sm={2} md={2}>
                                    {posts.length > 0 ?
                                        posts.map((row, i) => (
                                            <div key={row.id} onClick={() => delMemberList(i)}>
                                                {row?.title}({row.owner?.nickname})
                                                <img src={row?.thumbnailImage} alt="" width={100}/>
                                                <a style={{color: 'red'}}>X</a></div>
                                        )) : null}
                                    {/*리스트로 표시한다음에 개별 리스트 삭제로 진행 할 예정*/}
                                </GridItem>
                            </GridContainer>
                        )
                    }

                </Paper>
                <Box className='add-list' display='flex' justifyContent='center'>
                    {(loadingBtn) ? (
                        <Spinner/>
                    ) : (<CustomButton color="primary" mr={2} variant="contained"
                                                                  onClick={createContents}>저장</CustomButton>
                    )}
                    <CustomButton color="transparent" mr={2} variant="contained"
                                  onClick={(event) => history.back()}
                    >취소</CustomButton>
                </Box>
            </div>)}
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        events: state.eventsReducer.events,
        users: state.userManaging.users,
        metaData: state.userManaging.metaData,
        totalUser: state.userManaging.totalUser,
        realTotalUser: state.userManaging.realTotalUser,
        totalUserBySearch: state.userManaging.totalUserBySearch,
        paginationUserManaging: state.userManaging.paginationUserManaging,
        // userDetail: state.push.ContentsMultiDetail,
        // loading: state.pushReducer.loading,
        // ContentsMultiDetail: state.pushReducer.ContentsMultiDetail,
        // push: state.pushReducer.push,
        // metaData: state.pushReducer.metaData,
    }
}

export default connect(mapStateToProps, {
    getListEventsAction,
    requestUserManagingAction,
    getListUserAction,
})(ContentsMultiDetail)
