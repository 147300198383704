import React from 'react'
import clsx from 'clsx'
import moment from 'moment'

import {lighten, makeStyles, useTheme} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import CustomButton from "../../../components/CustomButtons/Button";
import writerManagingApi from "../../../api/writerManagingApi";
import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import UserModal from "../components/UserModal";
import userManagingApi from "../../../api/userManagingApi";

const useStyles = makeStyles({
	table: {
		minWidth: 1000,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	groupBtnDropdown: {
		boxShadow: 'unset',
	},
	menuList: {
		'& li': {
			'& span': {
				paddingLeft: '0',
			},
		},
	},
	customBtnMI: {
		borderColor: '#222',
		color: '#222',
		'&:hover': {
			borderColor: 'unset',
			boxShadow: 'none',
		},
	},
	setZindexMenuList: {
		'& div:last-child': {
			zIndex: 9999,
		},
	},
	cell: {
		fontSize: '0.8rem',
		padding: '10px'
	},
	tabl1e: {
		'& tbody': {
			'& td': {
				fontSize: '0.8rem',
				padding: '10px',
				textAlign: 'center',
			},
		},
		'& thead': {
			'& th': {
				fontSize: '0.8rem',
				padding: '10px',
				textAlign: 'center',
			},
		}
	}
})

const useStyles1 = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}))

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
			theme.palette.type === 'light'
					? {
						color: theme.palette.secondary.main,
						backgroundColor: lighten(theme.palette.secondary.light, 0.85),
					}
					: {
						color: theme.palette.text.primary,
						backgroundColor: theme.palette.secondary.dark,
					},
	title: {
		flex: '1 1 100%',
	},
}))

const EnhancedTableToolbar = (props) => {
	const classes = useToolbarStyles()
	const { numSelected, handleDeletePost, loading } = props

	return (
			<Toolbar
					className={clsx(classes.root, {
						[classes.highlight]: numSelected > 0,
					})}
			>
				{numSelected > 0 ? (
						<Typography
								className={classes.title}
								color='inherit'
								variant='subtitle1'
								component='div'
						>
							{numSelected} selected
						</Typography>
				) : (
						<Typography
								className={classes.title}
								variant='h6'
								id='tableTitle'
								component='div'
						></Typography>
				)}

				{/* <Tooltip title='Delete'>
        <Button
          disabled={loading}
          onClick={handleDeletePost}
          aria-label='delete'
          color='primary'
        >
          삭제하기
        </Button>
      </Tooltip> */}
			</Toolbar>
	)
}

const TablePaginationActions = (props) => {
	const classes = useStyles1()
	const theme = useTheme()
	const { count, page, rowsPerPage, onPageChange } = props

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0)
	}

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1)
	}

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1)
	}

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
	}

	return (
			<div className={classes.root}>
				<IconButton
						onClick={handleFirstPageButtonClick}
						disabled={page === 0}
						aria-label='first page'
				>
					{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
				</IconButton>
				<IconButton
						onClick={handleBackButtonClick}
						disabled={page === 0}
						aria-label='previous page'
				>
					{theme.direction === 'rtl' ? (
							<KeyboardArrowRight />
					) : (
							<KeyboardArrowLeft />
					)}
				</IconButton>
				<IconButton
						onClick={handleNextButtonClick}
						disabled={page >= Math.ceil(count / rowsPerPage) - 1}
						aria-label='next page'
				>
					{theme.direction === 'rtl' ? (
							<KeyboardArrowLeft />
					) : (
							<KeyboardArrowRight />
					)}
				</IconButton>
				<IconButton
						onClick={handleLastPageButtonClick}
						disabled={page >= Math.ceil(count / rowsPerPage) - 1}
						aria-label='last page'
				>
					{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
				</IconButton>
			</div>
	)
}

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort, headCells } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
			<TableHead>
				<TableRow>
					<TableCell style={{ width: '50px' }}>No.</TableCell>
					{headCells.map((headCell, i) => (
							<TableCell
									key={headCell.id}
									align={headCell.numeric ? 'right' : 'left'}
									padding={headCell.disablePadding ? 'none' : 'normal'}
									sortDirection={orderBy === headCell.id ? order : false}
									style={{
										minWidth: headCell.minWidth ? headCell.minWidth : 170,
									}}
							>
								<TableSortLabel
										// active={orderBy === headCell.id}
										active={headCell.allowSortable}
										direction={orderBy === headCell.id ? order : 'asc'}
										// onClick={createSortHandler(headCell.id)}
										onClick={
											headCell.allowSortable
													? createSortHandler(headCell.id)
													: undefined
										}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
											<span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
									) : null}
								</TableSortLabel>
							</TableCell>
					))}
					<TableCell style={{ width: '120px' }}></TableCell>
				</TableRow>
			</TableHead>
	)
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

export default function BasicTable(props) {
	const classes = useStyles()

	const {
		rows,
		headCells,
		sortable,
		totalRecords,
		setRowsPerPage,
		rowsPerPage,
		setPage,
		page,
		order,
		setOrder,
		setOrderBy,
		orderBy,
		getListWriters,
		toggleWriterExposureAction,
		toggleWriterIsSyncAction,
		setMember,
		member,
		dispatch
	} = props

	const [selected, setSelected] = React.useState([])

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const handleDeleteInfo = async (e,nickname) => {

		if(confirm("작가를 삭제할시 해당 작가와 연관된 일정, 상품, 메인의 데이터가 삭제됩니다. 그래도 삭제하시겠습니까?")){
			await writerManagingApi.deleteWriterManaging({ nickname })
			getListWriters()
		}

	}
	const handleDetail = async (e,id) => {
		location.href = '/admin/writer-detail/' + id

	}

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id)
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
					selected.slice(0, selectedIndex),
					selected.slice(selectedIndex + 1),
			)
		}

		setSelected(newSelected)
	}

	const handleChangeWriterHome = async (value, id) => {


		//change api

		const writer = await writerManagingApi.toggleWriterExposureMain({
			writerId: id,
			toggle: value
		});


		//해당 데이터 홈 노출 변경
		if (writer){
			toggleWriterExposureAction({
				writerId: id,
				mainExposure:  !value
			})

		}

	}


	const handleChangeSync = async (id,value) => {


		//change api

		const writer = await writerManagingApi.toggleIsSync({
			writerId: id,
			isSync: !value
		});


		//해당 데이터 홈 노출 변경
		if (writer){
			toggleWriterIsSyncAction(
				{writerIds: id,
				isSync: !value
			})

		}

	}

	const isSelected = (id) => selected.indexOf(id) !== -1


	const [alert2, setAlert] = React.useState(null)
	//회원 Modal
	// Modal
	const showUserModal = (writerId) => {

		//insert
		setAlert(
			<UserModal
				writerId={writerId}
				item={member}
				hideAlert={()=>{
					setAlert(null)}
				}
				setAlert={setAlert}
				userManagingApi={userManagingApi}
				dispatch={dispatch}
				setMember={setMember}
			/>
		)


	}

	return (
			<TableContainer component={Paper}>
				{alert2}
				{/* <EnhancedTableToolbar numSelected={selected.length} /> */}
				<Table className={classes.tabl1e} aria-label='simple table'>
					<EnhancedTableHead
							sortable={sortable}
							classes={classes}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							headCells={headCells}
					/>

					<TableBody>
						{rows.map((row, index) => {
							const isItemSelected = isSelected(row?.id)
							const labelId = `enhanced-table-checkbox-${index}`

							const number =
									page === 0 ? index + 1 : index + 1 + parseInt(`${page}0`)

							return (
									<TableRow hover key={index} >
										<TableCell align='left'>{row.id}</TableCell>
										<TableCell  align='left'>
											<Box my={2}>
												<Switch
													checked={row?.mainExposure == true ? true : false}
													onChange={(e)=>{
														handleChangeWriterHome(row.mainExposure, row.id)}
													}
													// disabled={loading}
													name='checkedA'
													inputProps={{'aria-label': 'secondary checkbox'}}
												/>
											</Box>
										</TableCell>
										<TableCell  align='left'>
											<Box my={2}>
												<Switch
													checked={row?.isSync == true ? true : false}
													onChange={(e)=>{
														handleChangeSync(row.id, row.isSync)}
													}
													// disabled={loading}
													name='checkedA'
													inputProps={{'aria-label': 'secondary checkbox'}}
												/>
											</Box>
										</TableCell>
										<TableCell align='right'
												   style={{"cursor": "pointer", color: 'blue'}}
												   onClick={(event) => handleDetail(event, row.id)}
										>
											{row.nickname}
											{/*<img src={row.background} alt=""/>*/}
										</TableCell>
										<TableCell align='right'>

											<CustomButton color="primary" mr={2} variant="contained"
														  onClick={()=>{
															  showUserModal(row.id)}
														  }
											>
												{row.userId == null ? '미지정': '지정('+row?.users?.nickname + ')'}
											</CustomButton>
										</TableCell>
										<TableCell >
											<img style={{maxWidth:'100px'}} src={row.avatar} alt=""/>
											<span>{row?.avatarName}</span>
											{/*<img src={row.background} alt=""/>*/}
										</TableCell>
										<TableCell >
											<img style={{maxWidth:'100px'}} src={row.backImg} alt=""/>
											<span>{row?.backImgName}</span>
											{/*<img src={row.background} alt=""/>*/}
										</TableCell>
										<TableCell>
											{row.goodsLinkType == 'SMARTSTORE' && (<span style={{
												background: '#5AC567',
												color: '#fff',
												'borderRadius': '5px',
												padding: '5px'
											}}>스마트스토어</span>)}
											{row.goodsLinkType == 'OWNSTORE' && (<span style={{
												background: '#0e0e0e',
												color: '#fff',
												'borderRadius': '5px',
												padding: '5px'
											}}>자사몰</span>)}
											{row.goodsLinkType == 'HOTTRACKS' && (<span style={{
												background: '#007aff',
												color: '#fff',
												'borderRadius': '5px',
												padding: '5px'
											}}>교보통합몰</span>)}
											{row.goodsLinkType == 'COVERWELL' && (<span style={{
												background: '#007aff',
												color: '#fff',
												'borderRadius': '5px',
												padding: '5px'
											}}>커버웰</span>)}
											{row.goodsLinkType == 'IDUS' && (<span style={{
												background: '#ff7b30',
												color: '#fff',
												'borderRadius': '5px',
												padding: '5px'
											}}>아이디어스</span>)}
											{row.goodsLinkType == 'MARPPLE' && (<span style={{
												background: '#1c75ff',
												color: '#fff',
												'borderRadius': '5px',
												padding: '5px'
											}}>마플샵</span>)}
											{row.goodsLinkType == 'TEN' && (<span style={{
												background: '#FF603E',
												color: '#fff',
												'borderRadius': '5px',
												padding: '5px'
											}}>텐바이텐</span>)}
											{row.goodsLinkType == 'POSTYPE' && (<span style={{
												background: '#141415',
												color: '#fff',
												'borderRadius': '5px',
												padding: '5px'
											}}>포스타입</span>)}
										</TableCell>
										<TableCell>{row.bio}</TableCell>

										<TableCell>{row.email}</TableCell>
										{/*<TableCell>{row.linkShareName}</TableCell>*/}
										{/*<TableCell>{row.linkShare}</TableCell>*/}
										<TableCell>
											<span >
												{(row.instarLink != null && row.instarLink != '') && (<a href={row.instarLink}>인스타그램</a>)}
											</span>&nbsp;&nbsp;&nbsp;<br/>
											<span>
												{(row.facebookLink != null && row.facebookLink != '') && (<a href={row.facebookLink}>페이스북</a>)}
												{/*<a href={row.facebookLink}>페이스북</a>*/}
											</span>&nbsp;&nbsp;&nbsp;<br/>
											<span>
												{(row.blogLink != null && row.blogLink != '') && (<a href={row.blogLink}>블로그</a>)}
												{/*<a href={row.blogLink}>블로그</a>*/}
											</span>&nbsp;&nbsp;&nbsp;<br/>
											<span>
												{(row.twitterLink != null && row.twitterLink != '') && (<a href={row.twitterLink}>트위터</a>)}
												{/*<a href={row.twitterLink}>트위터</a>*/}
											</span>&nbsp;&nbsp;&nbsp;<br/>
											<span>
												{(row.youtubeLink != null && row.youtubeLink != '') && (<a href={row.youtubeLink}>유투브</a>)}
												{/*<a href={row.youtubeLink}>유투브</a>*/}<br/>
											</span>
											<span>
												{(row.hottracksLink != null && row.hottracksLink != '') && (<a href={row.hottracksLink}>핫트랙스</a>)}
												{/*<a href={row.youtubeLink}>유투브</a>*/}<br/>
											</span>
											<span>
												{(row.smartStoreLink != null && row.smartStoreLink != '') && (<a href={row.smartStoreLink}>스마트스토어</a>)}
												{/*<a href={row.youtubeLink}>유투브</a>*/}<br/>
											</span>
											<span>
												{(row.idusLink != null && row.idusLink != '') && (<a href={row.idusLink}>아이디어스</a>)}
												{/*<a href={row.youtubeLink}>유투브</a>*/}<br/>
											</span>
											<span>
												{(row.marppleLink != null && row.marppleLink != '') && (<a href={row.idusLink}>마플샵</a>)}
												{/*<a href={row.youtubeLink}>유투브</a>*/}<br/>
											</span>
											<span>
												{(row.tenbytenLink != null && row.tenbytenLink != '') && (<a href={row.idusLink}>텐바이텐</a>)}
												{/*<a href={row.youtubeLink}>유투브</a>*/}<br/>
											</span>
											<span>
												{(row.linkShare != null && row.linkShare != '') && (<a href={row.linkShare}>기타</a>)}
												{/*<a href={row.youtubeLink}>유투브</a>*/}<br/>
											</span>
											<span>
												{(row.postypeLink != null && row.postypeLink != '') && (<a href={row.postypeLink}>포스타입</a>)}
												{/*<a href={row.youtubeLink}>유투브</a>*/}<br/>
											</span>
										</TableCell>
										<TableCell align='right'>{row.totalLiker}</TableCell>
										<TableCell align='right'>
											{row.role==='PRO'&& <span style={{background:'#ad0c0c', color:'#fff', 'borderRadius':'5px', padding:'5px'}}>프로작가</span>}
											{row.role==='WRITER'&& <span style={{background:'#45962c', color:'#fff', 'borderRadius':'5px', padding:'5px'}}>작가</span>}
										</TableCell>
										<TableCell align='right'>{row.bio}</TableCell>
										<TableCell align='right'>
											{moment(row?.createdAt).format('YYYY/MM/DD')}
										</TableCell>
										<TableCell align='right'>
											<CustomButton color="primary" mr={2} onClick={(event) => handleDetail(event, row.id)} variant="contained">수정</CustomButton>
											{/*<CustomButton color="primary" mr={2} variant="contained" onClick={(event) => handleSelEdit(event,row)} >삭제</CustomButton>*/}
										</TableCell>
										<TableCell align='right'>
											<CustomButton color="primary" mr={2} onClick={(event) => handleDeleteInfo(event, row.nickname)} variant="contained">삭제</CustomButton>
											{/*<CustomButton color="primary" mr={2} variant="contained" onClick={(event) => handleSelEdit(event,row)} >삭제</CustomButton>*/}
										</TableCell>
									</TableRow>
							)
						})}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
									rowsPerPageOptions={[
										10,
										30,
										50,
										100,
										{ label: 'All', value: totalRecords },
									]}
									colSpan={10}
									count={totalRecords ? totalRecords : 0}
									rowsPerPage={rowsPerPage}
									page={page}
									SelectProps={{
										inputProps: { 'aria-label': 'rows per page' },
										native: true,
									}}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
	)
}
