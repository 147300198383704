import * as actionTypes from '../../actions/types'


const initialState = {
    loading: true,
    postInfoList: [],
    metaData: {
        totalPages: 1,
    },
    postInfo: {
        description: '',
        type: 'GENERAL',
        status: 'ACTIVE',
        postInfoType: 'NORMAL',
        isShow: 1,
        id: 0
    },
    error: null,
}


export default function (state = initialState, action) {

    const {type, payload} = action
    switch (type) {

        //게시판 postInfo getList API에서 가져온
        case actionTypes.GET_LIST_BOARD_INFO_MANAGING:
            const {postInfos, metaData} = payload
            return {
                ...state,
                loading: false,
                postInfoList: postInfos, // postInfos:value 매칭, key값 매칭 잘해줘야함
                metaData,
            }
        //게시판 postInfo error
        case actionTypes.BOARD_INFO_MANAGING_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        //게시판 postInfo create
        case actionTypes.CREATE_BOARD_INFO_MANAGING:
            return {
                ...state,
                postInfoList: [...state.postInfoList, payload],
            }
        //게시판 postInfo update
        case actionTypes.UPDATE_BOARD_INFO_MANAGING:
            return {
                ...state,
                postInfoList: state.postInfoList.map((item) =>
                    item.id === payload.id
                        ? { ...item, ...payload }
                        : item,
                ),
                // postInfo: payload.postInfo,
            }
        default:
            return state
    }


}