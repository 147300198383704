import axiosInterceptors from 'api/axios'

const authApi = {
  loginApi: (body) => {
    const url = `/user/login`
    return axiosInterceptors.post(url, body)
  },
  //인증번호 발급받기 전 유효한 id인지 check
  adminCheckIsuser: (body) => {
    const url = `/user/adminCheckIsuser`
    return axiosInterceptors.post(url, body)
  },
  //인증번호 받기
    setAuthNumber: (body) => {
    const url = `/user/setAuthNumber`
    return axiosInterceptors.post(url, body)
  },


  checkAuthNumber: (body) => {
  const url = `/user/checkAuthNumber`
  return axiosInterceptors.get(url, body)

},

  logoutApi: () => {
    const url = `/user/logout`
    return axiosInterceptors.put(url)
  },

  refreshTokenApi: () => {
    localStorage.setItem('isRefreshToken', true)
    const url = `/user/getAccessToken`
    return axiosInterceptors.get(url)
  },
}

export default authApi
