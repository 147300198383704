import * as actionTypes from './types'

export const requestWriterMainManagingAction = () => {
    return {
        type: actionTypes.REQUEST_WRITER_MAIN_MANAGING,
    }
}

export const getListWriterMainAction = (payload) => {
    return {
        type: actionTypes.GET_LIST_WRITER_MAIN,
        payload: payload,
    }
}
export const changeWriterMainOrderAction = (payload)=>{
    return {
        type: actionTypes.CHANGE_WRITER_MAIN_ORDER,
        payload: payload,
    }
}

export const setPaginationWriterMainManagingAction = (payload) => {
    return {
        type: actionTypes.SET_PAGINATION_WRITERMAINMANAGING,
        payload: payload,
    }
}

export const requestWriterMainManagingErrorAction = (payload) => {
    return {
        type: actionTypes.REQUEST_WRITER_MAIN_MANAGING_ERROR,
        payload: payload,
    }
}
