import * as actionTypes from './types'

export const createBoardManagingAction = (payload) => ({
    type: actionTypes.CREATE_BOARD_INFO_MANAGING,
    payload,
})

//
// export const updateBoardManagingAction = (payload) => ({
//     type: actionTypes.UPDATE_BOARD_INFO_MANAGING,
//     payload,
// })
//
export const getListEventsAction = (payload) => ({
    type: actionTypes.GET_LIST_EVNETS,
    payload,
})
export const getEventsAction = (payload) => ({
    type: actionTypes.GET_EVNETS,
    payload,
})
export const getListCommentsAction = (payload) => ({
    type: actionTypes.GET_LIST_COMMENTS,
    payload,
})


export const requestEventsAction = (payload) => ({
    type: actionTypes.BOARD_INFO_MANAGING_REQUEST_ERROR,
    payload,
})
export const updateEventsAction = (payload) => ({
    type: actionTypes.UPDATE_EVNETS,
    payload,
})

