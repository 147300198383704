import axiosInterceptors from 'api/axios'

const versionManangingApi = {
    createVersion: (body) => {
        const url = `/versionManaging/createVersion`
        return axiosInterceptors.post(url, body)
    },
    update: (params) => {
        const url = `/versionManaging/updateVersion`
        return axiosInterceptors.put(url, {params})
    },
    getListVersions: (params) => {
        const url = '/versionManaging/getListVersions'
        return axiosInterceptors.get(url, { params })
    },
    getVersions: (params) => {
        const url = '/versionManaging/getVersions'
        return axiosInterceptors.get(url, { params })
    },
    deleteVersions: (params) => {
        const url = '/versionManaging/deleteVersion'
        return axiosInterceptors.delete(url, { params })
    },

}

export default versionManangingApi
