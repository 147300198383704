import React from 'react'

import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import {connect, useDispatch} from 'react-redux'
import {getListCommentsAction, requestCommentsAction,} from 'redux/actions/commentsManagingAction.js'

import styles from 'assets/jss/material-dashboard-pro-react/views/Notice/notice'
import postManagingApi from "../../api/postManagingApi.js";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import TextField from "../../components/Gm-TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Table from './components/CommentsTable'

const useStyles = makeStyles(styles)

const CommentManaging = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const {
        loading,
        metaData: {totalPages, totalRecords},
        // requestNoticeAction,
        getListCommentsAction, requestCommentsAction,
        comments,
        match
    } = props

    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [orderBy, setOrderBy] = React.useState('first')
    const [order, setOrder] = React.useState('desc')
    const [alert, setAlert] = React.useState(null)
    const [searchText, setSearchText] = React.useState(null)

    const getListComments = async () => {

        let orderString = '';

        // if (orderBy == 'first') {
        //     //처음 정렬시 현재 front에 나오는 표출 순서대로
        //     orderString = 'status,ASC&numOrder,DESC&createdAt,DESC';
        // } else {
        //     orderString = orderBy + ',' + order
        // }

        let params = {
            limit: limit,
            offset: page
        }

        try {
            requestCommentsAction()
            const {data} = await postManagingApi.getListCommentsOfPost(params)
            getListCommentsAction(data)
        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                // noticesWithErrAction(error.response.data)
                console.log(error.response.data)
            }
        }
    }

    React.useEffect(() => {
        getListComments()
    }, [page + 1, limit, order]) //해당 값들이  state값이 변하면 해당 부분 실행됨....



    return (
        <div className='notice'>

            {/*<GridContainer alignItems='center'>
                <GridItem
                    className={classes.gridContainerOne}
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                >

                    <TextField
                        id='post-managing-textfield'
                        size='small'
                        className={classes.textFieldOne}
                        placeholder={'공지사항 제목을 입력해주세요'}
                        name='clientId'
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value)
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                    />


                </GridItem>
                <GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
                    <Button
                        color='primary'
                        disabled={loading}
                        onClick={getListComments}
                    >
                        검색
                    </Button>
                </GridItem>
            </GridContainer>*/}
            <GridContainer alignItems='center'>
                <GridItem xs={12}>
                    현재 생성되는 댓글/답글 구분없이 최신순으로 뿌려주고 있습니다.  <br/>
                    추후 검색 기능, 답글/댓글 관계, 관리자에서 댓글 관리 기능을 추가하려고 생각중이나, 이것은 추후 기획파트와 다시 이야기를 해봐야할 것 같습니다.

                    추이를 봐주는 용도로 알아주시면 될 것 같습니다.
                </GridItem>
            </GridContainer>
            <GridContainer alignItems='center'>
                <GridItem xs={6}>
                    <Box my={2}>
                        <TextField
                            className={`${classes.textFieldTwo} ${classes.textFieldTwoChildOne}`}
                            id='post-managing-textfield-show-info1'
                            size='small'
                            value={totalRecords}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>전체</InputAdornment>
                                ),
                                readOnly: true,
                            }}
                        />
                    </Box>
                </GridItem>

                <GridItem xs={4}>
                </GridItem>
            </GridContainer>



            <Box my={2} className='table'>
                {loading ? (
                    <CircularProgress size={30} className={classes.buttonProgress} />
                ) : (
                    <Table
                        rows={comments}
                        totalRecords={totalRecords}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        page={page}
                        setPage={setPage}
                        setLimit={setLimit}
                        order={order}
                        orderBy={orderBy}
                        setOrder={setOrder}
                        setOrderBy={setOrderBy}
                    />
                )}
            </Box>
        </div>

        )
}

const mapStateToProps = (state) => {
    return {
        loading: state.commentsReducer.loading,
        comments: state.commentsReducer.comments,
        metaData: state.commentsReducer.metaData,
    }
}

export default connect(mapStateToProps, {
    getListCommentsAction, requestCommentsAction,
    // updateCommentsAction
})(CommentManaging)
