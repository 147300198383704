import {connect, useDispatch} from "react-redux";
import Paper from "@material-ui/core/Paper";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/Notice/notice2style";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import TextField from "../../components/Gm-TextField/TextFieldForDatePicker";
import Box from "@material-ui/core/Box";
import Button from "../../components/CustomButtons/Button";
import CustomButton from "../../components/CustomButtons/Button";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import moment from "moment";
import Spinner from "../../components/Spinner/Spinner";
//자동제안은 react suggest
import stylesModal from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import bannerManagingApi from "../../api/mainManaging/bannerManagingApi";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import writerManagingApi from "../../api/writerManagingApi";
// import GoodsAndWriterModal from "./components/GoodsAndWritersModal";
import MenuItem from "@material-ui/core/MenuItem";
import CustomTextField from "../../components/Gm-TextField/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(styles)
const useStylesModal = makeStyles(stylesModal)

// 이벤트 상세임 아무튼 그럼
const WriterEdit = (props) => {

    const {
        match,
        postForm,
    } = props

    const classes = useStyles()
    const classesModal = useStylesModal()


    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz


    const dispatch = useDispatch()

    const [styleTagSelected, setStyleSelected] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [alert2, setAlert] = React.useState(null)
    const [goods, setGoods] = React.useState([])
    const [isUploadImage, setIsUploadImage] = React.useState(false)
    const [isUploadImage2, setIsUploadImage2] = React.useState(false)
    const [formData, setFormData] = React.useState({
        id: isNaN(match?.params?.id) ? 0 : match?.params?.id,
        nickname: '',
        goodsLinkType: 'SMARTSTORE',
        status: 'ACTIVE',
        instarLink: '',
        facebookLink: '',
        twitterLink: '',
        blogLink: '',
        youtubeLink: '',
        hottracksLink: '',
        smartStoreLink: '',
        idusLink: '',
        avatar: null,
        smartStoreAppId: null,
        smartStoreSecretKey: null
    })
    const [imageFile, setImageFile] = React.useState(null)
    const [imageFile2, setImageFile2] = React.useState(null)
    const hideAlert = () => {
        setAlert(null)
    }
    const [loadingBtn, setLoadingBtn] = React.useState(false)


    const eventsModel = {
        questionTitle: '',
        files: {}
    }


    const [voteQuestionList, setvoteQuestionList] = React.useState([eventsModel])


    const handleForm = (value, key) => {
        //vue의 데이터 매핑 형태였던 것 같은데.... key도 매핑되네 히야...

        switch (key) {
            case 'startAt' :
                setFormData({
                    ...formData,
                    [key]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'endAt' :
                setFormData({
                    ...formData,
                    [key]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'isDuplication' :
                setFormData({
                    ...formData,
                    [key]: !formData.isDuplication,
                })
                break;
            case 'postType' :
                setFormData({
                    ...formData,
                    [key]: value,
                    description: ''
                })

                break;
            default:
                setFormData({
                    ...formData,
                    [key]: value,
                })

        }


    }
    const handleFormQuestionList = (value, key, index) => {
        let tempObject = voteQuestionList[index];
        tempObject[key] = value;
        setvoteQuestionList([...voteQuestionList.slice(0, index),
            tempObject,
            ...voteQuestionList.slice(index + 1)]
        )
    }
    const handleFormQuestionFile = async (file, index) => {
        //파일 저장해서 가져와진 fileidx를 input

        //이미지 cdn 업로드
        const fd = new FormData()

        fd.append('file', file, file.name)
        fd.append('type', 'POST')
        const {
            data: {id: idImage, url, filename},
        } = await bannerManagingApi.uploadImage(fd); //banner라고 세팅했지만원본은 file api 로 통신

        let tempObject = voteQuestionList[index];
        tempObject.files = {id: idImage, url, filename};
        setvoteQuestionList([...voteQuestionList.slice(0, index),
            tempObject,
            ...voteQuestionList.slice(index + 1)]
        )

    }

    const handleFormFeedFile = async (e, index) => {
        //feed 파일
        //파일 저장해서 가져와진 fileidx를 input


        const fd = new FormData()
        for (let i = 0; i < e.target.files.length; i++) {
            fd.append('file', e.target.files[i])
        }
        fd.append('type', 'WRITER')

        const {data} = await bannerManagingApi.uploadImageMulti(fd)

    }


    const handleChangeTimePickerStart = (event) => {
        setFormData({...formData, fromTime: event.target.value})
    }
    const handleChangeTimePickerEnd = (event) => {
        setFormData({...formData, toTime: event.target.value})
    }

    const validateImageAlert = (message) => {
        setAlert(
            <SweetAlert
                warning
                style={{display: 'block', marginTop: '-100px'}}
                title=''
                onConfirm={() => hideAlert()}
                showConfirm={false}
                onCancel={() => hideAlert()}
            >
                <Typography component='p' gutterBottom>
                    {message}
                </Typography>
                <Button color='success' onClick={hideAlert}>
                    OK
                </Button>
            </SweetAlert>,
        )
    }

    const validateUploadImage = (file, whichCheck, whichBanner) => {
        if (whichCheck === 'size' && Math.round(file.size / 1024) > 2048) {
            validateImageAlert('파일이 너무 큽니다. 2MB 넘지 않아야합니다')
            return true
        }

    }
    const handleChangeFile = (e, type) => {
        //파일 Onchage 시 이미지 체인지

        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            if (validateUploadImage(file, 'size')) return

            const updateState = (file) => {
                setImageFile(file)
                setIsUploadImage(true)
            }
            const updateState2 = (file) => {
                setImageFile2(file)
                setIsUploadImage2(true)
            }
            // use for check width and height image
            const img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.addEventListener('load', () => {
                type === 'avatar' && updateState(file)
                //validateUploadImage(file, 'size') || (!validateUploadImage(img, 'widthAndHeight') && updateState(file))
                type === 'backImg' && updateState2(file)
                // !validateUploadImage(file, 'size', whichBanner) && updateState2(file)
            })
        }

    }

    const delGoodsAndWriterList = (i) => {
        let member2 = goods.splice(0, i)
        if (goods.splice(goods.length).length > 0) {
            member2.push(goods.splice(goods.length))
        }
        setGoods(member2)
    }


    //api 통신해서 db저장 이벤트 생성
    const createPostMananging = async () => {


        try {

            //유효성체크
            if (formData.nickname == null){
                alert("닉네임을 입력해주세요")
                return
            }
            if (formData.goodsLinkType == 'SMARTSTORE' && formData.smartStoreLink == null){
                alert("스마트스토어 링크를 입력해주세요")
                return
            }
            if (formData.goodsLinkType == 'IDUS' && formData.idusLink == null){
                alert("스마트스토어 링크를 입력해주세요")
                return
            }
            if (formData.goodsLinkType == 'HOTTRACKS' && formData.hottracksLink == null){
                alert("핫트랙스 링크를 입력해주세요")
                return
            }
            if (formData.goodsLinkType == 'postype' && formData.postypeLink == null){
                alert("포스타입 링크를 입력해주세요")
                return
            }
            if (imageFile == null && formData.avatarUrl == null){
                alert("프로필 사진을 넣어주세요")
                return
            }
            if (imageFile2 == null && formData.backgroundImg == null){
                alert("배경 사진을 넣어주세요")
                return
            }
            if (formData.bio == null){
                alert("자기소개를 넣어주세요")
                return
            }

            const body = formData
            // call api upload file
            setLoading(true)
            let setNewmainImage
            let setNewsubImage
            let setNewPopupImage
            if (isUploadImage) {
                const formData = new FormData()
                formData.append('file', imageFile, imageFile.name)
                formData.append('type', 'WRITER')

                const {
                    data: {id, url, filename},
                } = await bannerManagingApi.uploadImage(formData)

                setNewmainImage = {
                    id,
                    url,
                    filename,
                }
                setIsUploadImage(false)
            }
            if (isUploadImage2) {
                const formData = new FormData()
                formData.append('file', imageFile2, imageFile2.name)
                formData.append('type', 'WRITER')

                const {
                    data: {id, url, filename},
                } = await bannerManagingApi.uploadImage(formData)

                setNewsubImage = {
                    id,
                    url,
                    filename,
                }
                setIsUploadImage2(false)
            }

            body.avatar = ( isUploadImage ? setNewmainImage.id : formData?.avatar )
            body.backImg = ( isUploadImage2 ? setNewsubImage.id : formData?.backImg )
            body.email = formData?.email

            //업데이트 일때, 하단은 Insert
            if (formData.id != 0) {

                // 일반 핃,
                //추천태그 빼기

                const {data} = await writerManagingApi.update(body);
                if (data) {
                    alert("수정되었습니다.")
                    location.href = "/admin/writer-managing"
                }
                return
            }


            const {data} = await writerManagingApi.create(body);
            if (data) {
                alert("저장되었습니다.")
                location.href = "/admin/writer-managing"
            }


        } catch (e) {
            setLoadingBtn(false)
        }

    }
    const getWriterData = async () => {
        try {

            setLoading(true)


            if (formData.id > 0) {
                //데이터가 있는 것

                //setLoading 필요

                let {data} = await writerManagingApi.getWriter({id: parseInt(formData.id)});
                //데이터 매핑 dispatch쓰면 코드가 깔끔해지나 쓰지 않음
                // data = data
                setLoading(false)
                const writer = data.writers

                // 기본
                const formNewDATA = {
                    ...formData,
                    id: writer.id,
                    nickname: writer.nickname,
                    goodsLinkType: writer.goodsLinkType,
                    instarLink: writer.instarLink,
                    facebookLink: writer.facebookLink,
                    twitterLink: writer.twitterLink,
                    blogLink: writer.blogLink,
                    youtubeLink: writer.youtubeLink,
                    hottracksLink: writer.hottracksLink,
                    smartStoreLink: writer.smartStoreLink,
                    idusLink: writer.idusLink,
                    tenbytenLink: writer.tenbytenLink,
                    marppleLink: writer.marppleLink,
                    postypeLink: writer.postypeLink,
                    email: writer.email,
                    avatar: writer.avatarUrl?.id || null,
                    backImg: writer.backgroundImg?.id || null,
                    avatarUrl: writer.avatarUrl,
                    backgroundImg: writer.backgroundImg,
                    bio: writer.bio,
                    smartStoreAppId: writer.smartStoreAppId,
                    smartStoreSecretKey: writer.smartStoreSecretKey
                };
                setGoods(formNewDATA.writerAndGoodsTags)



                //최종데이터 세팅
                setFormData(formNewDATA)

                setLoading(false)

            }
            setLoading(false)

        } catch (e) {

        }
    }

    let count = 0;
    React.useEffect(() => {
        //getData
        getWriterData()
    }, [formData.id])

    // const handleTitle = (e)


    //스타일 태그 input,output
    const selectStyleTag = async (type) => {
        //있으면 빼고 없으면 넣고
        let isContain = false;
        let iindex = 0;

        styleTagSelected.map((item, index) => {
            if (item == type) {
                isContain = true;
                iindex = index
            }
        });

        if (isContain) {
            //해당 인덱스 구해서 빼기
            setStyleSelected(styleTagSelected.slice(0, iindex));

        } else {

            //이렇게 하면 state가 변한걸 탐지 못함
            /*const aa = styleTagSelected;
            aa.push(type)
            setStyleSelected(aa);*/

            const aa = [];
            styleTagSelected.map((item) => {
                aa.push(item)
            });
            aa.push(type)
            setStyleSelected(aa);
        }
    }
    const openGoodsView = async () => {

        setAlert(
            <GoodsAndWriterModal
                item={goods}
                hideAlert={(e) => {
                    setAlert(null)
                }}
                setAlert={setAlert}
                dispatch={dispatch}
                setGoods={setGoods}
                goods={goods}
            />
        )
    }

    return (

        <div>

            {loading ? (<Spinner/>) : (<div>
                {alert2}
                <Paper variant='outlined' className={classes.paper}>


                    <GridContainer className={classes.grid} alignItems='center'
                                   style={{marginBottom: '10px', marginTop: '10px'}}>
                        <GridItem xs={6} sm={6} md={6} className={classes.lableSt}>
                            <CustomTextField
                                id='editBannerUpload'
                                label='작가명을 입력하세요'
                                variant='standard'
                                name='webViewTitle'
                                value={formData?.nickname}
                                className={classes.widthTextFieldModalTwo}
                                onChange={(event) => {
                                    handleForm(event.target.value, 'nickname')
                                }}
                                style={{marginBottom: '20px', width: '50%'}}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.grid} alignItems='center'
                                   style={{marginBottom: '10px', marginTop: '10px'}}>


                        <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                            상품 연결 타입
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            {/*{formData.postType}*/}
                            <TextField
                                id='user-managing-select'
                                select
                                size='small'
                                className={classes.widthTextFieldModal}
                                value={formData.goodsLinkType}
                                onChange={(e) => {
                                    handleForm(e.target.value, 'goodsLinkType')
                                }}
                                label='상품을 연결할 사이트를 선택해주세요'
                                style={{width: '100%'}}
                            >
                                <MenuItem value={'HOTTRACKS'}>핫트랙스</MenuItem>
                                <MenuItem value={'SMARTSTORE'}>스마트스토어</MenuItem>
                                <MenuItem value={'IDUS'}>아이디어스</MenuItem>
                                <MenuItem value={'OWNSTORE'}>자사몰</MenuItem>
                                <MenuItem value={'IDUS'}>아이디어스</MenuItem>
                                <MenuItem value={'MARPPLE'}>마플샵</MenuItem>
                                <MenuItem value={'TEN'}>텐바이텐</MenuItem>
                                <MenuItem value={'POSTYPE'}>포스타입</MenuItem>
                            </TextField>
                        </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.grid}>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'인스타그램 링크'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleForm(event.target.value, 'instarLink')
                                    },
                                }}
                                value={formData.instarLink}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />

                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'페이스북 링크'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleForm(event.target.value, 'facebookLink')
                                    },
                                }}
                                value={formData.facebookLink}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />

                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'트위터 링크'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleForm(event.target.value, 'twitterLink')
                                    },
                                }}
                                value={formData.twitterLink}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />

                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'블로그 링크'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleForm(event.target.value, 'blogLink')
                                    },
                                }}
                                value={formData.blogLink}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />

                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'유투브 링크'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleForm(event.target.value, 'youtubeLink')
                                    },
                                }}
                                value={formData.youtubeLink}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />

                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'핫트랙스 링크'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleForm(event.target.value, 'hottracksLink')
                                    },
                                }}
                                value={formData.hottracksLink}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />

                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'스마트스토어 링크'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleForm(event.target.value, 'smartStoreLink')
                                    },
                                }}
                                value={formData.smartStoreLink}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />

                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'아이디어스 링크'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleForm(event.target.value, 'idusLink')
                                    },
                                }}
                                value={formData.idusLink}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />

                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'마플샵 링크'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleForm(event.target.value, 'marppleLink')
                                    },
                                }}
                                value={formData.marppleLink}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />

                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'텐바이텐 링크'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleForm(event.target.value, 'tenbytenLink')
                                    },
                                }}
                                value={formData.tenbytenLink}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />

                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'포스타입 링크'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleForm(event.target.value, 'postypeLink')
                                    },
                                }}
                                value={formData.postype}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />

                        </GridItem>
                    </GridContainer>

                    <GridContainer alignItems='center' className={classes.grid}>

                        <GridItem xs={6} sm={6} md={6}>
                            <CustomInput
                                labelText={'이메일'}
                                id="float"
                                inputProps={{
                                    onChange: (event) => {
                                        handleForm(event.target.value, 'email')
                                    },
                                }}
                                value={formData.email}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                // onChange={handleTitle()}
                            />

                        </GridItem>
                    </GridContainer>


                    {/*프로필사진*/}
                    <GridContainer alignItems='center' className={classes.grid}>
                        <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                            프로필 사진
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            <input
                                accept='image/*'
                                className={classes.inputBtnUpload}
                                id={'contained-button-file'}
                                multiple
                                type='file'
                                onClick={(event) => {
                                    event.target.value = ''
                                }}
                                onChange={(e) => handleChangeFile(e, 'avatar')}
                            />
                            <label htmlFor={'contained-button-file'}>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    component='span'
                                    fullWidth={true}
                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                >

                                    {imageFile
                                        ? imageFile.name
                                        : '프로필 사진 올리기'}
                                </Button>
                            </label>
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            {
                                formData.avatarUrl != null ? <img style={{'maxWidth':'200px'}} src={formData?.avatarUrl?.url}/> : null
                            }
                        </GridItem>


                    </GridContainer>
                    {/*배경사진*/}
                    <GridContainer alignItems='center' className={classes.grid}>
                        <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                            배경 사진
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            <input
                                accept='image/*'
                                className={classes.inputBtnUpload}
                                id={'contained-button-file-2'}
                                multiple
                                type='file'
                                onClick={(event) => {
                                    event.target.value = ''
                                }}
                                onChange={(e) => handleChangeFile(e, 'backImg')}
                            />
                            <label htmlFor={'contained-button-file-2'}>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    component='span'
                                    fullWidth={true}
                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                >

                                    {imageFile2
                                        ? imageFile2.name
                                        : ' 배경 사진 올리기'}
                                </Button>
                            </label>
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            {
                                formData.backgroundImg != null ? <img style={{'maxWidth':'200px'}} src={formData?.backgroundImg?.url}/> : null
                            }
                        </GridItem>


                    </GridContainer>

                    <GridContainer className={classes.grid} alignItems='center'>

                        {/*내용*/}
                        <GridContainer className={classes.grid}>
                            <GridItem xs={12} sm={12} md={12} className={classes.inputClass}>
                                <Box className='notice-detail'>
                                    <TextareaAutosize
                                        style={{'width': '600px'}}
                                        className={classes.textareaAutosize}
                                        minRows={5}
                                        maxRows={9}
                                        aria-label='maximum height'
                                        placeholder='자기소개'
                                        value={formData.bio}
                                        onChange={(e) => {
                                            setFormData({...formData, bio: e.target.value});
                                        }}
                                        name='content'
                                    />
                                </Box>
                            </GridItem>
                        </GridContainer>

                    </GridContainer>
                    {
                        formData.goodsLinkType == 'SMARTSTORE' && (<GridContainer className={classes.grid} alignItems='center'>
                            {/*스마트스토어 일경우 key값 받기*/}

                            *스마트스토어는 애플리케이션 ID 및 시크릿key가 있어야 상품 연동이 가능합니다.

                            <GridContainer className={classes.grid}>
                                <GridItem xs={6} sm={6} md={6}>
                                    <CustomInput
                                        labelText={'애플리케이션 ID'}
                                        id="float"
                                        inputProps={{
                                            onChange: (event) => {
                                                handleForm(event.target.value, 'smartStoreAppId')
                                            },
                                        }}
                                        value={formData.smartStoreAppId}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        // onChange={handleTitle()}
                                    />

                                </GridItem>
                            </GridContainer>

                            <GridContainer className={classes.grid}>
                                <GridItem xs={6} sm={6} md={6}>
                                    <CustomInput
                                        labelText={'시크릿key'}
                                        id="float"
                                        inputProps={{
                                            onChange: (event) => {
                                                handleForm(event.target.value, 'smartStoreSecretKey')
                                            },
                                        }}
                                        value={formData.smartStoreSecretKey}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        // onChange={handleTitle()}
                                    />

                                </GridItem>
                            </GridContainer>


                        </GridContainer>)
                    }



                </Paper>


                <Box className='add-list' display='flex' justifyContent='center'>


                    {loadingBtn ? (
                            <Spinner/>
                        ) :
                        (
                            <CustomButton color="primary" mr={2} variant="contained"
                                          onClick={createPostMananging}
                            >
                                {formData?.id == 0 ? '등록' : '수정'}
                            </CustomButton>)
                    }
                    <CustomButton color="default" mr={2} variant="contained"
                                  onClick={(event) => history.back()}
                    >취소</CustomButton>

                </Box>
            </div>)}


        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        // userDetail: state.post.postForm,
        loading: state.eventsReducer.loading,
        postForm: state.eventsReducer.postForm,
        post: state.eventsReducer.post,
        metaData: state.eventsReducer.metaData,
    }
}

export default connect(mapStateToProps, {
    // requestNoticeAction,
    // getListNoticesAction,
    // noticesWithErrAction,
})(WriterEdit)
