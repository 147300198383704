import * as actionTypes from '../actions/types'

const initialState = {
    loading: true,
    events: [], //리스트에서 쓰이는거
    metaData: {
        totalPages: 1,
        totalRecords: 0,
    },
    eventsDetail: null,  //등록, 수정화면에서 쓰는거
    error: null,
    listCommentOfEvents: [],
    metadataForEventView: {
        totalPages: 1,
    },
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case actionTypes.GET_LIST_EVNETS:
            const {rows, metaData, commentsList} = payload
            return {
                ...state,
                loading: false,
                events: rows,
                listCommentOfEvents: rows.commentsList?.comments || [],
                metaData: metaData,
                metadataForEventView: commentsList?.metaData,
            }
        case actionTypes.UPDATE_EVNETS:
            return {
                ...state,
                loading: false,
                events: state.events.map((item) =>
                    item.id === payload.id
                        ? { ...item, ...payload }
                        : item,
                ),
            }
        case actionTypes.GET_LIST_COMMENTS:
            return {
                ...state,
                loading: false,
                listCommentOfEvents: payload.comments || [],
                metadataForEventView:payload.metaData,
            }
        case actionTypes.GET_EVNETS:
            const {events} = payload
            return {
                ...state,
                loading: false,
                events: events,
                listCommentOfEvents: events.commentsList?.comments || [],
                metadataForEventView: events.commentsList?.metaData,
            }
        default:
            return state
    }

}