import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import moment from "moment/moment";


export const CustomAlert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />
}
export const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />
}

export const handleFormEvent = (e, keys = null, formData, setFormData) => {

    if (e != null) {
        if (keys == null) keys = e.target.name
        setFormData({...formData, [keys]: e.target.value})
    }
}
export const handleFormValue = (value, keys = null, formData, setFormData) => {
    // if (value != null) {
        // if (keys == null) keys = e.target.name
        switch (keys) {
            case 'startAt' :
                //e가 value
                setFormData({
                    ...formData,
                    [keys]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'endAt' :
                //e가 value
                setFormData({
                    ...formData,
                    [keys]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'fromDate' :
                //e가 value
                setFormData({
                    ...formData,
                    [keys]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'toDate' :
                //e가 value
                setFormData({
                    ...formData,
                    [keys]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'type' :
                setFormData({
                    ...formData,
                    [keys]: value,
                })
                break;
            default:
                setFormData({
                    ...formData,
                    [keys]: value,
                })
        }
    // }

}

export const dateTimeCheck = (date, time) => {
    if (time <= 9) {
        return date + " " + "0" + time + ":00" // YYYY-MM-DD HH:mm
    } else {
        return date + " " + time + ":00"
    }

}
export const dateTimeCheckThree = (date, time, min) => {
    let Min = "00";
    if (min <= 9){
        Min = min+"0";
    }else{
        Min = min
    }

    if (time <= 9) {
        return date + " " + time + ":"+Min
    } else {
        return date + " " + time + ":"+Min
    }
}


export const timeZoneFnc = () => {
    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz
}

export let compiled = _.template('${ date } ${ time }:00:00')


export const convertDate2 = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm')
}

export  const convertDate = (time, date) =>
    time >= timeZoneFnc() ? date : moment(date).subtract(1, 'days').format('YYYY-MM-DD')

export const convertTime = (isFirstLoad, time) => {
    let result
    if (isFirstLoad) {
        result = _.split(
            moment().subtract(tz, 'hours').format('YYYY-MM-DD, H'),
            ',',
            2,
        )[1]?.trim()
    } else {
        const hour = time - tz;
        result = hour < 0 ? hour + 24 : hour;
    }
    return result <= 9 ? `0${result}` : result
}