import * as actionTypes from './types'

export const getStaticsPostsAction = (payload) => ({
  type: actionTypes.GET_STATICS_POST,
  payload,
})
export const getStaticsUsersAction = (payload) => ({
  type: actionTypes.GET_STATICS_USER,
  payload,
})


export const staticsOfClickRequestErrorAction = (payload) => ({
  type: actionTypes.STATICS_OF_CLICK_REQUEST_ERROR,
  payload,
})
