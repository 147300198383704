import React, {useEffect} from 'react'

import {makeStyles} from '@material-ui/core/styles'


import styles from "assets/jss/material-dashboard-pro-react/views/PushManaging/PushManangingStyle"
import {connect, useDispatch, useSelector} from "react-redux";
import GridItem from "../../components/Grid/GridItem";
import TextField from "../../components/Gm-TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import PushTable from "../PushManaging/components/PushTables";
import pushManagingApi from "../../api/pushManangingApi";
import PushTables from "../PushManaging/components/PushTables";
import {
    getPushInfosAction, requestPushInfosAction,
    updatePushInfosAction
} from 'redux/actions/pushManagingAction.js'
import EventsTable from "../Notice/components/EventsTable";
import eventsManagingApi from "../../api/eventsManagingApi";

const useStyles = makeStyles(styles)

//https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/
// https://demos.creative-tim.com/material-dashboard-pro-react/?_ga=2.65713518.1113779052.1651035603-701745760.1651035603#/pages/account/settings

/**
 * 게시판 정보 메인 페이지
 * @param props
 * @returns {*}
 * @constructor
 */
const PushManaging = (props) => {
//
    const classes = useStyles()
    const dispatch = useDispatch()



    //-------------- state 세팅
    const {
        metaData: {totalPages, totalRecords},
        // requestNoticeAction,
        pushInfos,
         requestPushInfosAction,
        match
    } = props
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [orderBy, setOrderBy] = React.useState('first')
    const [order, setOrder] = React.useState('desc')
    const [searchText, setSearchText] = React.useState('')
    const [selected, setSelected] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    // Data for table
    const headCells = [
        {
            id: 'id',
            align: 'center',
            disablePadding: false,
            label: 'No.',
            width: '50',
            minWidth: '50',
            allowSortable: true,
        },
        {
            id: 'title',
            align: 'left',
            disablePadding: false,
            label: '제목',
            minWidth: '340',
            allowSortable: true,
        },
        {
            id: 'targetType',
            align: 'center',
            disablePadding: false,
            label: '발송타입',
            minWidth: '340',
            allowSortable: true,
        },
        {
            id: 'type',
            align: 'center',
            disablePadding: false,
            label: '타입',
            minWidth: '340',
            allowSortable: true,
        },
        {
            id: 'sendTime',
            align: 'center',
            disablePadding: false,
            label: '발송일자',
            minWidth: '340',
            allowSortable: false,
        },
        {
            id: 'createdAt',
            align: 'center',
            disablePadding: false,
            label: '생성 일자',
            minWidth: '170',
            allowSortable: true,
        },
        {
            id: 'isSend',
            align: 'center',
            disablePadding: false,
            label: '발송여부',
            minWidth: '340',
            allowSortable: true,
        },
        {
            id: 'status',
            align: 'center',
            disablePadding: false,
            label: '상태',
            minWidth: '100',
            allowSortable: true,
        },
    ]

    const getPushInfoList = async () => {

        setLoading(true)
        let orderString = '';

        if (orderBy == 'first') {
            //처음 정렬시 현재 front에 나오는 표출 순서대로
            orderString = 'sendTime,DESC&createdAt,DESC';
        } else {
            orderString = orderBy + ',' + order
        }


        let params = {
            limit: limit,
            order: orderString,
            offset: page,
            searchText: searchText,
            searchKey: 'title',
        }

        try {
            requestPushInfosAction()
            const {data} = await pushManagingApi.getListPostManaging(params)
            dispatch(getPushInfosAction(data))

            setLoading(false)

        } catch (error) {
            setLoading(false)
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                console.log(error.response.data)
            }
        }
    }

//     //get mounted ㄱㅏㅌ은 역할
    React.useEffect(() => {

        getPushInfoList()
    }, [page , limit, order])

    const deletePushList = async (row) => {


        const selArr = {id : []}

        selected.forEach((item) => {
            // item = parseInt(item)

            selArr.id.push({
                item
            })
        })


        const {data} = await pushManagingApi.destroy({id: selected})



        getPushInfoList()

    }

    return (
        <div className='notice'>

            <GridContainer alignItems='center'>
                <GridItem
                    className={classes.gridContainerOne}
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                >

                    <TextField
                        id='post-managing-textfield'
                        size='small'
                        className={classes.textFieldOne}
                        placeholder={'푸시 제목을 입력해주세요'}
                        name='clientId'
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value)
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                    />


                </GridItem>
                <GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
                    <Button
                        color='primary'
                        disabled={loading}
                        onClick={(e)=>{
                            setPage(0)
                            getPushInfoList() }
                        }
                    >
                        검색
                    </Button>
                </GridItem>
            </GridContainer>

            <GridContainer alignItems='center'>
                <GridItem xs={6}>
                    <Box my={2}>
                        <TextField
                            className={`${classes.textFieldTwo} ${classes.textFieldTwoChildOne}`}
                            id='post-managing-textfield-show-info1'
                            size='small'
                            value={totalRecords}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>전체</InputAdornment>
                                ),
                                readOnly: true,
                            }}
                        />
                    </Box>
                </GridItem>
                <GridItem xs={4}>
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2} xl={2}>

                    <Box display='flex' justifyContent='flex-end' className='add-notice'>
                        <Button
                            color='primary'
                            onClick={() => props.history.push('/admin/push-managing/detail'  )}
                        >
                            추가하기
                        </Button>
                        <Button
                            color='info'
                            onClick={deletePushList}
                        >
                            삭제하기
                        </Button>
                    </Box>

                </GridItem>

            </GridContainer>
            <GridContainer>
                <GridItem xs={12}>
                {/*발송시간 전에만 수정 가능*/}
                {/*현재 시간 이전에는 발송 불가능*/}
                <Box className={classes.boxTableBlock} my={2}>
                    {loading ? (
                        <CircularProgress size={30} className={classes.buttonProgress} />
                    ) : (
                        <PushTables
                            // headCells={headCells}
                            // rows={pushInfos}
                            // totalRecords={totalRecords}
                            // setRowsPerPage={setRowsPerPage}
                            // rowsPerPage={rowsPerPage}
                            // setPage={setPage}
                            // page={page}
                            // totalPages={totalPages}
                            // // deletePostAction={deletePostAction}
                            // pushManagingApi={pushManagingApi}
                            // // postManagingErrAction={postManagingErrAction}
                            // setOrder={setOrder}
                            // order={order}
                            // setOrderBy={setOrderBy}
                            // orderBy={orderBy}

                            rows={pushInfos}
                            totalPages={totalPages}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            page={page}
                            setPage={setPage}
                            setLimit={setLimit}
                            headCells={headCells}
                            classes={useStyles}
                            order={order}
                            setOrderBy={setOrderBy}
                            setOrder={setOrder}
                            orderBy={orderBy}
                            // updateEvents={updateEvents}
                            setSelected={setSelected}
                            selected={selected}

                        />
                    )
                    }
                </Box>
                </GridItem>
            </GridContainer>


        </div>
    )

}


//redux 상태 세팅
//reducers/index.js
const mapStateToProps = (state) => {
    return {
        loading: state.pushInfoReducer.loading,
        pushInfos: state.pushInfoReducer.pushInfos,
        metaData: state.pushInfoReducer.metaData,
    }
}

export default connect(mapStateToProps, {
    getPushInfosAction, requestPushInfosAction,
})(PushManaging)
