import * as actionTypes from './types'
import {GET_LIST_CONTENT} from "./types";

export const getContentManagingAction = (payload) => ({
    type: actionTypes.GET_LIST_CONTENT,
    payload,
})

export const orderContentAction = (data) => {
    return {
        type: actionTypes.ORDER_CONTENT,
        payload: data,
    }
}

export const deleteContentAction = (data) => {
    return {
        type: actionTypes.DELETE_CONTENT_ACTION,
        payload: data,
    }
}
export const changeContentAction = (data) => {
    return {
        type: actionTypes.CHANGE_CONTENT_ACTION,
        payload: data,
    }
}

export const getContentsMultiManagingListAction = (data) =>{
    return {
        type: actionTypes.GET_LIST_CONTENT_MULTI,
        payload: data,
    }
}