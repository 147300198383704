import axiosInterceptors from 'api/axios'

const writerGoodsManagingApi = {

	getListGoods: (params) => {
		const url = '/goods/writerGoodsManaging/getListGoods'
		return axiosInterceptors.get(url, { params })
	},

	getListGoodsExcel: (params) => {
		const url = `/goods/writerGoodsManaging/getListGoodsExcel?${params}`
		return axiosInterceptors.get(url, {
			responseType: 'blob',
		})
	},

	setExcelFile: (body) => {
		const url = '/goods/writerGoodsManaging/setExcelFile'
		return axiosInterceptors.post(url, body)
	},

	setListGoodsExcel: (body) => {
		const url = '/goods/writerGoodsManaging/setListGoodsExcel'
		return axiosInterceptors.post(url, body)
	},

	newGoodsExcel: (body) => {
		const url = '/goods/writerGoodsManaging/newGoodsExcel'
		return axiosInterceptors.post(url, body)
	},

	deleteWriterGoods: (params) => {
		const url = '/goods/writerGoodsManaging/delete'
		return axiosInterceptors.delete(url, { params })
	},
	deleteGoodsByWriterId:(params) => {
		const url = '/goods/writerGoodsManaging/deleteGoodsByWriterId'
		return axiosInterceptors.delete(url, { params })
	},

	saveImageFile: (body) => {
		const url = '/goods/writerGoodsManaging/saveImageFile'
		return axiosInterceptors.post(url, body)
	},
	updateGoodsManagingWriterGoods:(params) => {
		const url = '/goods/writerGoodsManaging/updateGoodsManagingWriterGoods'
		return axiosInterceptors.put(url, { params })
	},
	deleteGoodsManagingWriterGoods:(params) => {
		const url = '/goods/writerGoodsManaging/deleteGoodsManagingWriterGoods'
		return axiosInterceptors.delete(url, { params })
	},

	toggleIsSync:(param)=>{
		const url = "/goods/writerGoodsManaging/toggleIsSync"
		return axiosInterceptors.put(url, {param});
	},
	toggleIsShow:(param)=>{
		const url = "/goods/writerGoodsManaging/toggleIsShow"
		return axiosInterceptors.put(url, {param});
	}


}

export default writerGoodsManagingApi