import React, {useEffect} from 'react'

import {makeStyles} from '@material-ui/core/styles'
import GridContainer from 'components/Grid/GridContainer.js'
import TextField from 'components/Gm-TextField/TextField'
import GridItem from 'components/Grid/GridItem.js'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import CustomButton from 'components/CustomButtons/Button'



import styles from "assets/jss/material-dashboard-pro-react/views/PostInfoManaging/postInfoManagingStyle"
import Spinner from "../../components/Spinner/Spinner";
import PostInfoTables from "./components/PostInfoTables.js";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import {
    createBoardManagingAction,
    getListPostInfoManagingAction,
    editPostInfoManagingAction,
    postInfoManagingRequestError, updateBoardManagingAction,
} from "../../redux/actions/postInfoManagingAction";
import postInfoManagingApi from "../../api/postInfoManagingApi";
import {connect, useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(styles)

//https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/
// https://demos.creative-tim.com/material-dashboard-pro-react/?_ga=2.65713518.1113779052.1651035603-701745760.1651035603#/pages/account/settings

/**
 * 게시판 정보 메인 페이지
 * @param props
 * @returns {*}
 * @constructor
 */
const PostInfoManaging = (props) => {

    const classes = useStyles()
    const dispatch = useDispatch()

    const {
        postInfoList,
        getListPostInfoManagingAction,
        metaData: metadata,
    } = props


    //-------------- state 세팅
    const [loading, setLoading] = React.useState(false)
    const [selectedPostInfoType, setPostInfoType] = React.useState('GENERAL')
    //게시판 form 입력창 추가 여부
    const [isShowPostInfoInputField, setIsShowPostInfoInputField] = React.useState(false)
    //게시판 데이터 초기화+ props로 보낸 데이터 set
    const [formData, setFormData] = React.useState({
        description: '',
        type: 'GENERAL',
        status: 'ACTIVE',
        postInfoType: 'GENERAL',
        isShow: 1,
        id : 0
    })
    //게시판 수정, 입력시 사용하는 데이터
    const {description, type, status, postInfoType } = formData
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)

    //페이지 관련 변수
    const [pagination, setPagination] = React.useState(1)
    const [page, setPage] = React.useState(0)
    const [limit, setLimit] = React.useState(10)

    //모드 추가/수정
    const [mode, setMode] = React.useState('INSERT')

    //모달창, 에러창
    const [modal, setModal] = React.useState(null)
    const [notifications, setNotifications] = React.useState({
        open: false,
        message: '',
    })
    //-------------- ./state 세팅



    //-----------라벨 list 세팅
    const postInfoTypeList = [
        {key:'GENERAL' , name:'일반', index: 0},
        {key:'EVENT' , name:'이벤트', index: 1},
        {key:'NOTICE' , name:'공지사항', index: 2},
        {key:'FAQ' , name:'FAQ', index: 3},
        {key:'QNA' , name:'QNA', index: 4},
    ]
    const options2 = ['활성화', '비활성화']
    const convertOptionToEnglish2 = (option) => {
        switch (option) {
            case '활성화':
                return 'ACTIVE'
            case '비활성화':
                return 'DEACTIVATED'
        }
    }
    //--------- ./라벨 list 세팅


    //-----------이벤트 받아서 handle
    const handleChange = (e) => {
        //제목 input할때 썻음
        setFormData({...formData, [e.target.name]: e.target.value})
    }
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }
    const handleMenuItemClick = (event, index) => {
        setPostInfoType(postInfoTypeList[index].key)
        setFormData({...formData, postInfoType: postInfoTypeList[index].key})
        setOpen(false)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleNewPostInfo = ()=>{
        setIsShowPostInfoInputField(!isShowPostInfoInputField)
    }
    const handleShowError = (errorMessage) => {
        setNotifications({ ...notifications, open: true, message: errorMessage })
    }

    //-----------./이벤트 받아서 handle



    //게시판 추가 api
    const createBoardsInfoManaging = async () => {
        try {

            // setIsShowPostInfoInputField(true)
            const body = {
                ...formData,
            }

            const {data} = await postInfoManagingApi.createPostInfoManaging(body)
            dispatch(createBoardManagingAction(data))
            setFormData({
                description: '',
                type: 'GENERAL',
                status: 'ACTIVE',
                postInfoType: 'GENERAL',
                isShow: 1,
                id: 0

            })

            setIsShowPostInfoInputField(false) //form hidden 시키기
        } catch (error) {
            setIsShowPostInfoInputField(false)
            handleShowError(error?.response?.data?.data?.error)
            dispatch(postInfoManagingRequestError(error?.response?.data))
        }
    }
//게시판 수정 api
    const updateBoardsInfoManaging = async () => {
        try {

            // setIsShowPostInfoInputField(true)
            const body = {
                ...formData,
            }


            const {data} = await postInfoManagingApi.updatePostInfoManaging(body)


            dispatch(updateBoardManagingAction(body))
            setFormData({
                description: '',
                type: 'GENERAL',
                status: 'ACTIVE',
                postInfoType: 'GENERAL',
                isShow: 1,
                id: 0
            })

            setIsShowPostInfoInputField(false) //form hidden 시키기
        } catch (error) {
            setIsShowPostInfoInputField(false)
            handleShowError(error?.response?.data?.data?.error)
            dispatch(postInfoManagingRequestError(error?.response?.data))
        }
    }


    //get mounted ㄱㅏㅌ은 역할
    React.useEffect(() => {
        const getPostInfoList = async () => {
            let params = {
                limit: limit,
                order: 'ASC',
                offset: page + 1,
            }

            try {
                const {data} = await postInfoManagingApi.getListPostInfoManaging(params)
                getListPostInfoManagingAction(data)
            } catch (error) {
                if (
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.data
                ) {
                    // getListPostInfoManagingAction(error.response.data)
                    console.log(error.response.data)
                }
            }
        }
        getPostInfoList()
    }, [page + 1, limit])

    return (
        <div className='post-info-managing'>

            <Box mb={2} className={classes.setPositionRelative}>
                {loading ? (
                    <Spinner/>
                ) : (
                    <PostInfoTables
                        postInfoTypeList={postInfoTypeList}
                        setIsShowPostInfoInputField={setIsShowPostInfoInputField}
                        setFormData={setFormData}
                        setMode={setMode}
                        setPostInfoType={setPostInfoType} rows={postInfoList ==[] ? [] : postInfoList }/>
                )}
            </Box>

            {/*<Box mb={2} display='flex' justifyContent='flex-end'>
                <Pagination
                    rows={postInfoList}
                    page={page}
                    setPage={setPage}
                    setLimit={setLimit}
                />
            </Box>*/}


            {isShowPostInfoInputField ? (
                <Box
                    component={Paper}
                    my={2}
                    className={`input-text-add ${classes.paperCommon}`}
                >
                    <GridContainer alignItems='left' justifyContent='flex-start'>
                        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} className={classes.formLabel}>
                            <label>{mode == 'INSERT'? '추가' : '수정'}</label>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                mr={4}
                                onChange={handleChange}
                                value={description}
                                className={classes.inputTextAdd__textField}
                                label='게시판명'
                                name='description'
                                variant='outlined'
                                size='small'
                            />
                            <ButtonGroup
                                m={0}
                                className={classes.groupBtnDropdown}
                                variant='contained'
                                color='default'
                                ref={anchorRef}
                                aria-controls={open ? 'split-CustomButton-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup='menu'
                                onClick={handleToggle}
                            >

                                {postInfoTypeList.map(
                                    (option, index) => (
                                        option.key == selectedPostInfoType && <CustomButton  className={classes.bttonGroup}  color='danger' endIcon={<ArrowDropDownIcon/>}>{option.name}</CustomButton>
                                    ))}
                            </ButtonGroup>
                            <Popper
                                open={open}
                                anchorEl={anchorRef.current}
                                className={classes.setZindex}
                                role={undefined}
                                transition
                                disablePortal
                            >
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom' ? 'center top' : 'center bottom',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList id='split-CustomButton-menu'>
                                                    {postInfoTypeList.map(
                                                        (option, index) => (
                                                        <MenuItem
                                                            key={option.key}
                                                            selected={option.key==selectedPostInfoType}
                                                            onClick={(event) =>
                                                                handleMenuItemClick(event, index)
                                                            }
                                                        >
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </GridItem>

                        <GridItem
                            container
                            justifyContent='center'
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        >
                            <CustomButton
                                color='primary'
                                onClick={
                                    mode == 'INSERT' ?
                                    createBoardsInfoManaging :
                                        updateBoardsInfoManaging
                                }
                            >
                                {mode == 'INSERT'? '추가' : '수정'}
                            </CustomButton>
                        </GridItem>
                    </GridContainer>
                </Box>
            ) : null}


            {/*
                추가시 postInfo 추가하는 창 추가
            */}
            {loading ? null : (
                <Box className='add-btn' display='flex' justifyContent='center'>
                    <CustomButton
                        color='primary'
                        onClick={handleNewPostInfo}
                    >
                        {isShowPostInfoInputField != true ? '추가' : '닫기'}
                    </CustomButton>
                </Box>
            )}


        </div>
    )

}


//redux 상태 세팅
//reducers/index.js
const mapStateToProps = (state) => {
    return {
        loading: state.postInfoReducer.loading,
        postInfoList: state.postInfoReducer.postInfoList,
        metaData: state.postInfoReducer.metaData,
        postInfo: state.postInfoReducer.postInfo,
    }
}

export default connect(mapStateToProps, {
    createBoardManagingAction, getListPostInfoManagingAction, postInfoManagingRequestError,
    updateBoardManagingAction
})(PostInfoManaging)
