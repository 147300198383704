import React from 'react'

import {makeStyles} from '@material-ui/core/styles'
import TextField from 'components/Gm-TextField/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import GridContainer from 'components/Grid/GridContainer.js'
import InputAdornment from '@material-ui/core/InputAdornment'
import Box from '@material-ui/core/Box'
import SearchIcon from '@material-ui/icons/Search'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Table from './components/writerMainTable.js'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
    changeWriterMainOrderAction,
    getListWriterMainAction,
    requestWriterMainManagingAction,
    requestWriterMainManagingErrorAction
} from 'redux/actions/writerMainManagingAction'

import {connect} from 'react-redux'

import writerMainManagingApi from 'api/writerMainManagingApi'
import writerManagingApi from 'api/writerManagingApi'

import styles from 'assets/jss/material-dashboard-pro-react/views/UserManaging/userManaging'

const useStyles = makeStyles(styles)

const WriterMainManaging = (props) => {
    const {
        requestWriterMainManagingAction,
        getListWriterMainAction,
        requestWriterMainManagingErrorAction,
        changeWriterMainOrderAction,
        loading,
        mains,
        totalWriterMain,
        totalWriterMainBySearch,
        metaData: { totalRecords },
    } = props

    const fileInput = React.createRef()
    const imgInput = React.createRef()
    const classes = useStyles()

    const [loadingBtnGetExcel, setLoadingBtnGetExcel] = React.useState(false)
    const [select, setSelect] = React.useState('NORMAL')
    const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('')
    const [clientId, setClientId] = React.useState('')
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [isResetPagination, setIsResetPagination] = React.useState(false)
    const [page, setPage] = React.useState(0)
    const [searchKey, setSearchKey] = React.useState('clientId')
    const [searchText, setSearchText] = React.useState('')

    const handleChangeSearchInput = (e) => {
        setClientId(e.target.value)
        !isResetPagination && setIsResetPagination(true)
    }

    const handleChangeSelect = (event) => {
        setSelect(event.target.value)
        !isResetPagination && setIsResetPagination(true)
    }

    const headCells = [
        {
            id: 'nickname',
            numeric: true,
            disablePadding: false,
            label: '닉네임',
            allowSortable: false,
        },
        {
            id: 'title',
            numeric: true,
            disablePadding: false,
            label: '작가이름/브랜드/로고',
            allowSortable: true,
        },
        {
            id: 'backFile',
            numeric: true,
            disablePadding: false,
            label: '배경이미지',
            allowSortable: false,
        },
        {
            id: 'mainFile',
            numeric: true,
            disablePadding: false,
            label: '메인이미지',
            allowSortable: false,
        },
        {
            id: 'orderNum',
            numeric: true,
            disablePadding: false,
            label: '등록순서',
            allowSortable: false,
        },
        {
            id: 'id',
            numeric: true,
            disablePadding: false,
            label: '등록일',
            allowSortable: true,
        }
    ]
    const getListWriterMain = async () => {
        let orderString = '';

        if (orderBy == 'first') {
            //처음 정렬시 현재 front에 나오는 표출 순서대로
            orderString = 'numOrder,DESC&createdAt,DESC';
        } else {
            orderString = orderBy + ',' + order
        }

        try {
            requestWriterMainManagingAction()
            const params = {
                limit: rowsPerPage,
                offset: page + 1,
                order: order,
                orderBy: orderBy,
                searchKey: searchKey,
                searchText: clientId
            }

            const { data } = await writerMainManagingApi.getListMain(params)

            getListWriterMainAction(data)

        } catch (error) {
            console.log(error.response)
            if (error && error.response && error.response.data) {
                requestWriterMainManagingErrorAction(error.response.data)
            }
        }
    }


    const handleSearchKey = (value) => {
        setSearchKey(value)
        setOrder('asc')
    }

    const handleButtonClick = async e => {
        fileInput.current.click();
    }

    const handleButtonClickImg = async e => {
        imgInput.current.click();
    }

    const handleChangeImg = async e => {
        const files = e.target.files;
        const formData = new FormData()

        for(let i=0; i<files.length; i++) {
            formData.append('files',  e.target.files[i])
        }
        formData.append('type', 'MAIN')

        const { data } = await writerManagingApi.saveImageFile(formData)

        if (data){
            // alert("파일 업로드가 완료되었습니다.")
            alert(data.messages)
        }

    }

    const handleChange = async e => {
        try {
            const file = e.target.files[0];
            const fd = new FormData()
            fd.append('xlsx', file, file.name)
            fd.append('type', 'xlsx')

            const { data } = await writerMainManagingApi.setExcelFile(fd)

            if (data?.messages){
                alert(data?.messages)
            }
            getListWriterMain()

        } catch (error) {
            console.log(error.response)
            if (error && error.response && error.response.data) {
            }
        }
    }

    React.useEffect(() => {
        getListWriterMain()
    }, [page, rowsPerPage, order])

    return (
        <div className='writer-managing'>
            <GridContainer alignItems='center'>
                {/*<GridItem
                    className={classes.gridContainerOne}
                    xs={12}
                    sm={4}
                    md={3}
                    lg={2}
                    xl={2}
                >
                    <TextField
                        id='writer-managing-select'
                        select
                        size='small'
                        className={classes.textFieldOne}
                        label='접근 처리 현황'
                        value={select}
                        onChange={handleChangeSelect}
                    >
                        <MenuItem value={'NORMAL'}>정상</MenuItem>
                        <MenuItem value={'BLOCKED'}>차단</MenuItem>
                    </TextField>
                </GridItem>*/}
                <GridItem xs={12} sm={6} md={5} lg={4} xl={4}>
                    <TextField
                        id='post-managing-textfield'
                        size='small'
                        className={classes.textFieldOne}
                        placeholder='검색어를 입력해주세요'
                        name='clientId'
                        value={clientId}
                        onChange={handleChangeSearchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </GridItem>

                <GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
                    <Button
                        color='primary'
                        disabled={loading}
                        onClick={() => {
                            setPage(0)
                            getListWriterMain()
                        }}
                    >
                        검색
                    </Button>
                </GridItem>
            </GridContainer>

            <Box my={2}>
                <TextField
                    className={`${classes.textFieldTwo} ${classes.textFieldTwoChildOne}`}
                    id='post-managing-textfield-show-info1'
                    size='small'
                    value={totalWriterMain}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>전체</InputAdornment>
                        ),
                        readOnly: true,
                    }}
                />
                <TextField
                    className={classes.textFieldTwo}
                    id='post-managing-textfield-show-info2'
                    size='small'
                    value={totalWriterMainBySearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>검색</InputAdornment>
                        ),
                        readOnly: true,
                    }}
                />
            </Box>

            <Box className={classes.btnGetExcelAndPaginationTable} mb={2}>
                <div>
                {/*<span style={{ 'display': 'inline-block'}}>* 작가 메인을 수정하시려는 경우 기존 등록된 작가 메인을 삭제한 후 다시 등록해주세요.</span><br/>
                <span style={{ 'display': 'inline-block'}}>* 작가 상품, 작가 일정, 작가메인을 삭제할 경우 관련된 사진은 전부 삭제가 되므로 다시 파일 업로드 후 엑셀을 넣어주세요..</span>*/}
                </div>
                <>
                    <Button
                        color='github'
                        onClick={(e)=>{
                            location.href = 'https://d16sraug4hsad1.cloudfront.net/excel_example/writer_main.xlsx'
                        }}
                    >
                        엑셀 양식 다운로드
                    </Button>
                    <Button
                        color='success'
                        onClick={handleButtonClickImg}
                    >
                        파일 업로드
                    </Button>
                    <input
                        name="FILE_UPLOAD"
                        type="file"
                        multiple
                        ref={imgInput}
                        id="fileUpload"
                        onChange={handleChangeImg}
                        onClick={(event)=> {event.target.value = null}}
                        accept='image/*,video/mp4,video/x-m4v,video/*,.json'
                        style={{ display: "none" }}
                    />
                </>
                <>
                    <Button
                        color='info'
                        onClick={handleButtonClick}
                    >
                        엑셀 업로드
                    </Button>
                    <input
                        name="FILE_UPLOAD"
                        type="file"
                        ref={fileInput}
                        id="fileUpload"
                        onChange={handleChange}
                        onClick={(event)=> {event.target.value = null}}    //같은 파일 적용도 가능하게
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  //엑셀만 허용
                        style={{ display: "none" }}
                    />
                </>
                <>
                    <Button
                        color='success'
                        onClick={(e)=>{
                            location.href = '/admin/writer-main/detail'
                        }}
                    >
                        신규등록
                    </Button>
                </>
            </Box>

            <Box mb={2}>
                {loading ? (
                    <CircularProgress size={30} className={classes.buttonProgress} />
                ) : (
                    <Table
                        headCells={headCells}
                        rows={mains}
                        totalRecords={totalRecords}
                        setRowsPerPage={setRowsPerPage}
                        rowsPerPage={rowsPerPage}
                        setPage={setPage}
                        page={page}
                        setOrder={setOrder}
                        order={order}
                        setOrderBy={setOrderBy}
                        orderBy={orderBy}
                        getListWriterMain={getListWriterMain}
                        changeWriterMainOrderAction={changeWriterMainOrderAction}
                    />
                )}
            </Box>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.writerMainManaging.loading,
    mains: state.writerMainManaging.mains,
    metaData: state.writerMainManaging.metaData,
    totalWriterMain: state.writerMainManaging.totalWriterMain,
    totalWriterMainBySearch: state.writerMainManaging.totalWriterMainBySearch,
    paginationWriterManaging: state.writerMainManaging.paginationUserManaging,
})

export default connect(mapStateToProps, {
    requestWriterMainManagingAction,
    getListWriterMainAction,
    changeWriterMainOrderAction,
    requestWriterMainManagingErrorAction,
})(WriterMainManaging)
