import axiosInterceptors from 'api/axios'


const pushManagingApi = {
    getListPostManaging: (params) => {
        const url = `/pushInfo/getPushInfoList`
        return axiosInterceptors.get(url, { params })
    },
    create: (param) => {
        const url = '/pushInfo/create'
        return axiosInterceptors.post(url, { param })
    },
    getPushInfo: (params) => {
        const url = '/pushInfo/getPushInfo'
        return axiosInterceptors.get(url, { params })
    },
    update: (params) => {
        const url = '/pushInfo/update'
        return axiosInterceptors.put(url, { params })
    },
    destroy: (params) => {
        const url = '/pushInfo/destroy'
        return axiosInterceptors.delete(url, { params })
    }
}


export default pushManagingApi