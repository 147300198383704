import React, {useEffect} from 'react'

import {makeStyles} from '@material-ui/core/styles'


import styles from "assets/jss/material-dashboard-pro-react/views/PushManaging/PushManangingStyle"
import {connect, useDispatch, useSelector} from "react-redux";
import GridItem from "../../components/Grid/GridItem";
import TextField from "../../components/Gm-TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "../../components/CustomButtons/Button";
import GridContainer from "../../components/Grid/GridContainer";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import SplashTables from "../SplashManaging/components/SplashTables";
import splashManagingApi from "../../api/splashManangingApi";
import {
    getSplashOneAction, requestSplashAction,
    updateSplashAction,getListSplashAction
} from 'redux/actions/splashManagingAction.js'

const useStyles = makeStyles(styles)

/**
 * 게시판 정보 메인 페이지
 * @param props
 * @returns {*}
 * @constructor
 */
const SplashManaging = (props) => {
//
    const classes = useStyles()
    const dispatch = useDispatch()



    //-------------- state 세팅
    const {
        metaData: {totalPages, totalRecords},
        // requestNoticeAction,
        splashList,
        // requestSplashInfosAction,
        match
    } = props
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [orderBy, setOrderBy] = React.useState('first')
    const [order, setOrder] = React.useState('desc')
    const [searchText, setSearchText] = React.useState('')
    const [selected, setSelected] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    // Data for table
    const headCells = [
        {
            id: 'id',
            align: 'center',
            disablePadding: false,
            label: 'No.',
            width: '50',
            minWidth: '50',
            allowSortable: true,
        },
        {
            id: 'title',
            align: 'left',
            disablePadding: false,
            label: '제목',
            minWidth: '340',
            allowSortable: true,
        },
        {
            id: 'startAt',
            align: 'center',
            disablePadding: false,
            label: '시작 일자',
            minWidth: '170',
            allowSortable: true,
        },
        {
            id: 'endAt',
            align: 'center',
            disablePadding: false,
            label: '종료 일자',
            minWidth: '170',
            allowSortable: true,
        },
        {
            id: 'createdAt',
            align: 'center',
            disablePadding: false,
            label: '생성 일자',
            minWidth: '170',
            allowSortable: true,
        },
        {
            id: 'status',
            align: 'center',
            disablePadding: false,
            label: '상태',
            minWidth: '100',
            allowSortable: true,
        },
    ]


    const getSplashInfoList = async () => {

        setLoading(true)
        let orderString = '';

        if (orderBy == 'first') {
            //처음 정렬시 현재 front에 나오는 표출 순서대로
            orderString = 'id,DESC';
        } else {
            orderString = orderBy + ',' + order
        }


        let params = {
            limit: limit,
            order: orderString,
            offset: page,
            searchText: searchText,
            searchKey: 'title',
        }

        try {
            requestSplashAction()
            const {data} = await splashManagingApi.getListSplash(params)
            dispatch(getListSplashAction(data))

            setLoading(false)

        } catch (error) {
            setLoading(false)
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                console.log(error.response.data)
            }
        }
    }

//     //get mounted ㄱㅏㅌ은 역할
    React.useEffect(() => {
        getSplashInfoList()
    }, [page , limit, order])

    const deleteSplashList = async (row) => {


        if(confirm("삭제하시겠습니까?")){


            const selArr = {id : []}

            selected.forEach((item) => {
                // item = parseInt(item)

                selArr.id.push({
                    item
                })
            })


            const {data} = await splashManagingApi.destroy({ids: selected})



            getSplashInfoList()
        }


    }

    return (
        <div className='notice'>

            <GridContainer alignItems='center'>
                <GridItem
                    className={classes.gridContainerOne}
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                >

                    <TextField
                        id='post-managing-textfield'
                        size='small'
                        className={classes.textFieldOne}
                        placeholder={'스플래시 제목을 입력해주세요'}
                        name='clientId'
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value)
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                    />


                </GridItem>
                <GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
                    <Button
                        color='primary'
                        disabled={loading}
                        onClick={getSplashInfoList}
                    >
                        검색
                    </Button>
                </GridItem>
            </GridContainer>

            <GridContainer alignItems='center'>
                <GridItem xs={6}>
                    <Box my={2}>
                        <TextField
                            className={`${classes.textFieldTwo} ${classes.textFieldTwoChildOne}`}
                            id='post-managing-textfield-show-info1'
                            size='small'
                            value={totalRecords}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>전체</InputAdornment>
                                ),
                                readOnly: true,
                            }}
                        />
                    </Box>
                </GridItem>
                <GridItem xs={4}>
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2} xl={2}>

                    <Box display='flex' justifyContent='flex-end' className='add-notice'>
                        <Button
                            color='primary'
                            onClick={() => props.history.push('/admin/splash-managing/detail'  )}
                        >
                            추가하기
                        </Button>
                        <Button
                            color='info'
                            onClick={deleteSplashList}
                        >
                            삭제하기
                        </Button>
                    </Box>

                </GridItem>

            </GridContainer>
            <GridContainer>
                <GridItem xs={12}>
                    {/*발송시간 전에만 수정 가능*/}
                    {/*현재 시간 이전에는 발송 불가능*/}
                    <Box className={classes.boxTableBlock} my={2}>
                        {loading ? (
                            <CircularProgress size={30} className={classes.buttonProgress} />
                        ) : (

                            <SplashTables
                                // headCells={headCells}
                                // rows={splashInfos}
                                // totalRecords={totalRecords}
                                // setRowsPerPage={setRowsPerPage}
                                // rowsPerPage={rowsPerPage}
                                // setPage={setPage}
                                // page={page}
                                // totalPages={totalPages}
                                // // deletePostAction={deletePostAction}
                                // splashManagingApi={splashManagingApi}
                                // // postManagingErrAction={postManagingErrAction}
                                // setOrder={setOrder}
                                // order={order}
                                // setOrderBy={setOrderBy}
                                // orderBy={orderBy}

                                rows={splashList}
                                totalPages={totalPages}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                page={page}
                                setPage={setPage}
                                setLimit={setLimit}
                                headCells={headCells}
                                classes={useStyles}
                                order={order}
                                setOrderBy={setOrderBy}
                                setOrder={setOrder}
                                orderBy={orderBy}
                                // updateEvents={updateEvents}
                                setSelected={setSelected}
                                selected={selected}

                            />
                        )
                        }
                    </Box>
                </GridItem>
            </GridContainer>


        </div>
    )

}


//redux 상태 세팅
//reducers/index.js
const mapStateToProps = (state) => {
    return {
        loading: state.splashManagingReducer.loading,
        splashList: state.splashManagingReducer.splashList,
        metaData: state.splashManagingReducer.metaData,
    }
}

export default connect(mapStateToProps, {
    getSplashOneAction, requestSplashAction,
    updateSplashAction,getListSplashAction
})(SplashManaging)
