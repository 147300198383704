import React from 'react'
import moment from 'moment'


import {makeStyles} from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import TimePicker from './components/TimePicker'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextFieldForDatePicker from 'components/Gm-TextField/TextFieldForDatePicker'
import Box from '@material-ui/core/Box'
import SearchIcon from '@material-ui/icons/Search'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import TextField from 'components/Gm-TextField/TextField'
import Table from './components/Table'

import {connect} from 'react-redux'
import {
    requestPostManagingAction,
    getListPostManagingAction,
    deletePostAction,
    postManagingErrAction,
    setPaginationAction,
    setFormDataGlobalAction,
    movePostAction
} from 'redux/actions/postManaging'
import postManagingApi from 'api/postManagingApi'

import styles from 'assets/jss/material-dashboard-pro-react/views/PostManaging/postManaging'
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {FiberManualRecord} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import fileDownload from "js-file-download";
import eventsManagingApi from "../../api/eventsManagingApi";

const useStyles = makeStyles(styles)

const PostManaging = ({
                          requestPostManagingAction,
                          getListPostManagingAction,
                          postManagingErrAction,
                          deletePostAction,
                          movePostAction,
                          movePostPushAction,
                          postManagingLists,
                          totalPostByTag,
                          totalPost,
                          metaData: {totalRecords},
                          setFormDataGlobalAction,
                          formDataGlobal,
                          match,
                          location,
                          events
                      }) => {
    const classes = useStyles()

    const [loadingBtn, setLoadingBtn] = React.useState(false)
    const [isResetPagination, setIsResetPagination] = React.useState(false)
    const [isFirstLoad, setIsFirstLoad] = React.useState(true)
    const [changePostInfoId, setChangePostInfoId] = React.useState(1)

    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz

    const [formData, setFormData] = React.useState({
        orderBy: formDataGlobal ? formDataGlobal.orderBy : '',
        rowsPerPage: formDataGlobal ? formDataGlobal.rowsPerPage : 10,
        order: formDataGlobal ? formDataGlobal?.order : 'desc',
        searchType: formDataGlobal ? formDataGlobal.searchType : 'ALL', //검색타입
        searchText: formDataGlobal ? formDataGlobal.searchText : '', //검색어
        tagInput: formDataGlobal ? formDataGlobal.tagInput : '',
        writerTag: formDataGlobal ? formDataGlobal.writerTag : '',
        styleTag: formDataGlobal ? formDataGlobal.styleTag : [],
        limit: formDataGlobal ? formDataGlobal.limit : 10,
        fromDate: formDataGlobal
            ? formDataGlobal.fromDate
            : moment().subtract(7, 'days').subtract(tz, 'hours').calendar({
                sameElse: 'YYYY-MM-DD',
            }),
        toDate: formDataGlobal
            ? formDataGlobal.toDate
            : moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        fromTime: formDataGlobal
            ? formDataGlobal.fromTime
            : _.split(moment().add(1, 'hours').format('YYYY-MM-DD, H'), ',', 2)[1]?.trim(),
        toTime: formDataGlobal
            ? formDataGlobal.toTime
            : _.split(moment().add(1, 'hours').format('YYYY-MM-DD, H'), ',', 2)[1]?.trim(),
        postInfoId: formDataGlobal ? formDataGlobal.postInfoId : 0,
        isKkommingTalk: false,
        offset: formDataGlobal ? formDataGlobal.offset : 1,
    })

    const {tagInput, fromDate, toDate, fromTime, toTime, limit} = formData

    //data handeling 범용
    const handleForm = (e, keys = null) => {
        if (e != null) {
            if (keys == null) keys = e.target.name
            setFormData({...formData, [keys]: e.target.value})
        }
    }
    //checkbox일 경우
    const handleMultiCheck = (e, multiSelect = null) => {
        if (e != null) {
            if (multiSelect == null || multiSelect) { //여러개의 option이 들어갈 경우 ex: style태그 여러개 선택
                let arr = new Set();
                if (Array.isArray(formData[e.target.name])) {
                    arr = new Set(formData[e.target.name]);
                }
                if (arr.has(e.target.value)) {
                    arr.delete(e.target.value) //잇으면 체크해제
                } else {
                    arr.add(e.target.value); //없으면 추가
                }
                setFormData({...formData, [e.target.name]: [...arr]}) //중복방지 new Set
            } else {
                //단일 옵션 ex: 꾸밍톡 여부?
                setFormData({...formData, [e.target.name]: formData[e.target.name] ? false : true})
            }
        }
    }
    // Date
    const handleDateChange = (date, key) => {
        setFormData({
            ...formData,
            [key]: moment(date).subtract(tz, 'hours').format('YYYY-MM-DD'),
        })
    }

    // Data for table 기본 꾸미기(피드), 자유잡담
    let headCells = [
        {
            id: 'no',
            allowSortable: false,
            numeric: false,
            disablePadding: false,
            label: 'No.',
            maxWidth: 100,
            minWidth: 50
        },
        {
            id: 'postinfoType',
            allowSortable: false,
            numeric: true,
            disablePadding: false,
            label: '타입',
            maxWidth: 140,
            minWidth: 120
        },
        {
            id: 'post-image',
            allowSortable: false,
            numeric: true,
            disablePadding: false,
            label: '게시글 이미지',
        },
        {
            id: 'title',
            allowSortable: false,
            numeric: true,
            disablePadding: false,
            label: '제목',
        },
        {
            id: 'TOTAL_LIKE',
            allowSortable: true,
            numeric: true,
            disablePadding: false,
            label: '좋아요수',
            minWidth: 120
        },
        {
            id: 'TOTAL_SCRAP',
            allowSortable: true,
            numeric: true,
            disablePadding: false,
            label: '스크랩수',
            minWidth: 120
        },
        {
            id: 'TOTAL_VIEW',
            allowSortable: true,
            numeric: true,
            disablePadding: false,
            label: '조회수',
            minWidth: 120
        },
        {
            id: 'CREATED_AT',
            allowSortable: true,
            numeric: true,
            disablePadding: false,
            label: '업로드일자',
        },
        {
            id: 'delete',
            allowSortable: false,
            numeric: true,
            disablePadding: false,
            label: '삭제',
            maxWidth: 120,
            minWidth: 100
        },
        {
            id: 'write',
            allowSortable: false,
            numeric: true,
            disablePadding: false,
            label: '작성자',
        },
    ]

    const convertTime = (isFirstLoad, time) => {
        let result
        if (isFirstLoad) {
            result = _.split(
                moment().subtract(tz, 'hours').format('YYYY-MM-DD, H'),
                ',',
                2,
            )[1]?.trim()
        } else {
            const hour = time - tz;
            result = hour < 0 ? hour + 24 : hour;
        }
        return result <= 9 ? `0${result}` : result
    }

    const convertDate2 = (date) => {
        return moment(date).format('YYYY-MM-DD HH:mm')
    }

    const convertDate = (time, date) =>
        time >= tz ? date : moment(date).subtract(1, 'days').format('YYYY-MM-DD')

    //조회
    const getListPostManaging = async () => {
        let compiled = _.template('${ date } ${ time }:00:00')
        let params
        try {

            //위의 파라미터를 history push?
            setFormDataGlobalAction(formData) //파라미터 저장때무넹 ㅣㅇㅆ었구나

            let params = JSON.parse(JSON.stringify(formData))
            //style태그 변경
            params.fromDate = compiled({
                date: convertDate(formData.fromTime, formData.fromDate),
                time: convertTime(isFirstLoad, formData.fromTime),
            })
            params.toDate = compiled({
                date: convertDate(formData.toTime, formData.toDate),
                time: convertTime(isFirstLoad, formData.toTime),
            })


            //챌린지에 속한 게시판 리스트 가져오기
            if (location.search.indexOf('eventsId=') > -1) {
                params['eventsId'] = parseInt(location.search.split('eventsId=')[1])
            }

            params.styleTag = formData.styleTag.join(',')
            params.offset = formData.offset
            params.order = formData.order.toUpperCase()


            // const historyData =  history.state
            // historyData.state = params
            // history.replaceState(historyData, null, location.pathname)

            delete params.fromTime
            delete params.toTime

            setLoadingBtn(true)
            requestPostManagingAction()
            const {data} = await postManagingApi.getListPostManaging(params)
            setLoadingBtn(false)
            getListPostManagingAction(data)
            setIsResetPagination(false)
        } catch (error) {
            setLoadingBtn(false)
            console.log(error.response)
            if (error && error.response && error.response.data) {
                postManagingErrAction(error.response.data)
            }
        }
    }
    const getExcelFileBingo = async (eventsId, title) => {
        const d = new Date()
        let tz = d.getTimezoneOffset() / 60
        try {

            const params = {
                eventId: eventsId,
                localTimeZone: tz
            }

            const data = await eventsManagingApi.getEventsBingoExcel(params)

            fileDownload(data, formData.title + '_빙고이벤트.xlsx')

            alert("엑셀을 다운로드 하였습니다")
            // setLoadingGetExcelFile(false)
        } catch (error) {
            // setLoadingGetExcelFile(false)
            alert("엑셀을 다운받는 도중 에러가 발생했습니다.")
            // if (error && error.response && error.response.data)
            //     dispatch(reportBlockManagingRequestWithError(error.response.data))
        }
    }

    const getListPostManagingExcel = async () => {
        let compiled = _.template('${ date } ${ time }:00:00')
        let params
        try {

            //위의 파라미터를 history push?
            setFormDataGlobalAction(formData) //파라미터 저장때무넹 ㅣㅇㅆ었구나

            let params = JSON.parse(JSON.stringify(formData))
            //style태그 변경
            params.fromDate = compiled({
                date: convertDate(formData.fromTime, formData.fromDate),
                time: convertTime(isFirstLoad, formData.fromTime),
            })
            params.toDate = compiled({
                date: convertDate(formData.toTime, formData.toDate),
                time: convertTime(isFirstLoad, formData.toTime),
            })


            //챌린지에 속한 게시판 리스트 가져오기
            if (location.search.indexOf('eventsId=') > -1) {
                params['eventsId'] = parseInt(location.search.split('eventsId=')[1])
            }

            params.styleTag = formData.styleTag.join(',')
            params.offset = formData.offset
            params.order = formData.order.toUpperCase()


            // const historyData =  history.state
            // historyData.state = params
            // history.replaceState(historyData, null, location.pathname)

            delete params.fromTime
            delete params.toTime

            const data = await postManagingApi.getListPostManagingExportExcel(params)

            fileDownload(data, '게시물리스트_' + moment(new Date()).format('YYYYMMDD') + '.xlsx')

            alert("엑셀을 다운로드 하였습니다")
        } catch (error) {
            alert("엑셀을 다운받는 도중 에러가 발생했습니다.")
        }
    }

    React.useEffect(() => {
        getListPostManaging()
        setIsFirstLoad(false)
    }, [formData.offset, formData.limit, formData.order])

    return (
        <div className='post-managing'>

            {/*챌린지가 아니라 일반검색*/}
            {
                events == null ? (
                    <>
                        {/*검색타입+어*/}
                        <GridContainer>
                            <GridItem
                                className={classes.gridContainerOne}
                                md={1}
                                xs={3}
                            >
                                <TextField
                                    id='type-managing-select'
                                    select
                                    size='small'
                                    className={classes.textFieldOne}
                                    label='검색 타입'
                                    value={formData?.searchType}
                                    onChange={(e) => handleForm(e, "searchType")}
                                >
                                    <MenuItem value={'ALL'}>전체</MenuItem>
                                    <MenuItem value={'title'}>제목</MenuItem>
                                    <MenuItem value={'nickname'}>닉네임</MenuItem>
                                    <MenuItem value={'description'}>내용</MenuItem>
                                    <MenuItem value={'tagInputs'}>태그</MenuItem>
                                </TextField>
                            </GridItem>
                            <GridItem md={6} xs={9}>
                                <TextField
                                    id='post-managing-textfield'
                                    size='small'
                                    style={{'width': '100%'}}
                                    placeholder='검색어를 입력해주세요'
                                    name='searchText'
                                    onChange={handleForm}
                                    value={formData?.searchText}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <SearchIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        {/*자*/}
                        <GridContainer style={{'paddingTop': '10px', 'paddingBottom': '10px'}}>

                            <GridItem xs={1} md={1} className={classes.responsiveStyle} className={classes.lableSt}
                                      className={classes.mobileHidden}
                                      style={{'margin': 'auto'}}
                            >
                                일자
                            </GridItem>
                            <GridItem
                                container
                                alignItems='left'
                                xs={11}
                                sm={11}
                                sm={11}
                                md={11}
                                lg={11}
                                xl={11}
                            >
                                <GridContainer
                                >
                                    <GridItem
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={5}
                                        xl={5}
                                        className={classes.responsiveStyle}
                                    >
                                        <GridContainer>
                                            <GridItem
                                                xs={7}
                                                sm={5}
                                                md={4}
                                                lg={7}
                                                xl={7}
                                                className={classes.styleDatePicker}
                                            >
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        className={`${classes.resDateTimePicker}`}
                                                        variant='inline'
                                                        TextFieldComponent={TextFieldForDatePicker}
                                                        format='yyyy/MM/dd'
                                                        id='date-picker-inline1'
                                                        value={formData?.fromDate}
                                                        onChange={(e) => {
                                                            handleDateChange(e, 'fromDate')
                                                        }}
                                                        autoOk={true}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </GridItem>
                                            <GridItem
                                                className={classes.paddingLeft}
                                                xs={5}
                                                sm={3}
                                                md={3}
                                                lg={5}
                                                xl={5}
                                            >
                                                <Box className={classes.setMarginLeft}>
                                                    <TimePicker
                                                        time={formData?.fromTime}
                                                        handlechangetimepicker={(e) => handleForm(e, 'fromTime')}
                                                    />
                                                </Box>
                                            </GridItem>


                                        </GridContainer>
                                    </GridItem>
                                    <GridItem
                                        container
                                        alignItems='left'
                                        xs={1}
                                        sm={1}
                                        md={1}
                                        lg={1}
                                        xl={1}
                                    >
                                        <Box
                                            display='flex'
                                            flexDirection='center'
                                            alignItems='center'
                                            className={classes.styleSymbol}
                                        >
                                            <p>~</p>
                                        </Box>
                                    </GridItem>
                                    <GridItem
                                        container
                                        alignItems='left'
                                        xs={12}
                                        md={5}
                                        lg={5}
                                        xl={5}
                                    >

                                        {
                                            events == null ? (
                                                <GridContainer>
                                                    <GridItem
                                                        xs={6}
                                                        md={4}
                                                        lg={7}
                                                        xl={7}
                                                        className={classes.styleDatePicker}
                                                    >
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                variant='inline'
                                                                className={`${classes.resDateTimePicker}`}
                                                                format='yyyy/MM/dd'
                                                                TextFieldComponent={TextFieldForDatePicker}
                                                                id='date-picker-inline2'
                                                                autoOk={true}
                                                                value={formData?.toDate}
                                                                onChange={(e) => {
                                                                    handleDateChange(e, 'toDate')
                                                                }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </GridItem>
                                                    <GridItem
                                                        className={classes.paddingLeft}
                                                        xs={6}
                                                        md={3}
                                                        lg={5}
                                                        xl={5}
                                                    >
                                                        <Box className={classes.setMarginLeft}>
                                                            <TimePicker
                                                                time={formData?.toTime}
                                                                name={'toTime'}
                                                                handlechangetimepicker={(e) => handleForm(e, 'toTime')}
                                                            />
                                                        </Box>
                                                    </GridItem>
                                                </GridContainer>
                                            ) : (
                                                <span>{convertDate2(events?.endAt)}</span>
                                            )
                                        }


                                    </GridItem>


                                </GridContainer>

                            </GridItem>
                        </GridContainer>
                        {/*게시판 타입*/}
                        <GridContainer>
                            <GridItem xs={1} sm={1} md={1} className={classes.lableSt}
                                      style={{'margin': 'auto', 'padding': '0px'}}
                                      className={classes.responsiveStyle}
                                      className={classes.mobileHidden}>
                                게시판 타입
                            </GridItem>
                            <GridItem xs={11} sm={11} md={11} style={{padding: '20px !important'}}>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={formData.postInfoId == 0}
                                            onChange={(e) => handleForm(e, 'postInfoId')}
                                            value={'0'}
                                            aria-label="T"
                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                            checkedIcon={
                                                <FiberManualRecord className={classes.radioChecked}/>
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.p0
                                    }}
                                    label="전체"
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={formData.postInfoId == '1'}
                                            onChange={(e) => handleForm(e, 'postInfoId')}
                                            value={'1'}
                                            aria-label="T"
                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                            checkedIcon={
                                                <FiberManualRecord className={classes.radioChecked}/>
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label
                                    }}
                                    label="꾸미기(피드)"
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={formData.postInfoId == '2'}
                                            onChange={(e) => handleForm(e, 'postInfoId')}
                                            value={'2'}
                                            aria-label="T"
                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                            checkedIcon={
                                                <FiberManualRecord className={classes.radioChecked}/>
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label
                                    }}
                                    label="투표문답"
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={formData.postInfoId == '3'}
                                            onChange={(e) => handleForm(e, 'postInfoId')}
                                            value={'3'}
                                            aria-label="T"
                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                            checkedIcon={
                                                <FiberManualRecord className={classes.radioChecked}/>
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label
                                    }}
                                    label="자유잡담"
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={formData.postInfoId == '4'}
                                            onChange={(e) => handleForm(e, 'postInfoId')}
                                            value={'4'}
                                            aria-label="T"
                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                            checkedIcon={
                                                <FiberManualRecord className={classes.radioChecked}/>
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label
                                    }}
                                    label="일러스트(라이징스타)"
                                />
                                <FormControlLabel
                                    className={classes.mobileHidden}
                                    control={
                                        <Radio
                                            checked={formData.postInfoId == '5'}
                                            value={5}
                                            onChange={(e) => handleForm(e, 'postInfoId')}
                                            aria-label="T"
                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                            checkedIcon={
                                                <FiberManualRecord className={classes.radioChecked}/>
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label
                                    }}
                                    label="이슈뉴스"
                                />
                                <FormControlLabel
                                    className={classes.mobileHidden}
                                    control={
                                        <Radio
                                            checked={formData.postInfoId == '6'}
                                            value={6}
                                            onChange={(e) => handleForm(e, 'postInfoId')}
                                            aria-label="T"
                                            icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                            checkedIcon={
                                                <FiberManualRecord className={classes.radioChecked}/>
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label
                                    }}
                                    label="굿즈"
                                />
                            </GridItem>


                        </GridContainer>
                        {/*작가 태그, 일반태그*/}
                        {/*                        <GridContainer className={classes.mobileHidden}>
                            <GridItem xs={1} sm={1} md={1} className={classes.lableSt}
                                      style={{'margin': 'auto'}}
                                      className={classes.responsiveStyle}>
                                추천태그 & 일반태그
                            </GridItem>
                            <GridItem
                                container
                                alignItems='center'
                                xs={12}
                                sm={5} md={5}
                            >
                                <TextField
                                    id='post-managing-textfield'
                                    size='small'
                                    fullWidth={true}
                                    placeholder='태그를 입력해주세요(추천태그&일반태그) / ex: #꾸밍챌린지,#꾸밍'
                                    // className={classes.inputSearch}
                                    name={'tagInput'}
                                    value={formData?.tagInput}
                                    style={{'width': '100%'}}
                                    onChange={handleForm}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <SearchIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={1} sm={1} md={1} className={classes.lableSt}
                                      style={{'margin': 'auto'}}
                                      className={classes.responsiveStyle}>
                                작가태그
                            </GridItem>
                            <GridItem
                                container
                                alignItems='center'
                                xs={12}
                                sm={5} md={5}
                            >
                                <TextField
                                    id='post-managing-textfield'
                                    size='small'
                                    fullWidth={true}
                                    placeholder='작가태그 검색 / ex: @오미베어,@베어'
                                    // className={classes.inputSearch}
                                    name='writerTag'
                                    value={formData?.writerTag}
                                    style={{'width': '100%'}}
                                    onChange={handleForm}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <SearchIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </GridItem>
                        </GridContainer>*/}
                        {/*스타일태그 모바일 때 숨김*/}
                        <GridContainer className={classes.mobileHidden}>
                            <GridItem xs={1} sm={1} md={1} className={classes.lableSt}
                                      style={{'margin': 'auto'}}
                                      className={classes.responsiveStyle}>
                                스타일 태그
                            </GridItem>
                            <GridItem xs={11} sm={11} md={11} style={{display: 'flex', justifyContent: 'left'}}>
                                <div>
                                    <Checkbox
                                        name={'styleTag'}
                                        value={'KITCH'}
                                        onClick={(event) => handleMultiCheck(event)}
                                        checked={formData?.styleTag?.includes('KITCH') ? true : false}
                                    />
                                    <label className={classes.lableSt} style={{'margin-left': '10px'}}>
                                        키치
                                    </label>
                                </div>
                                <div>
                                    <Checkbox
                                        name={'styleTag'}
                                        value={'LOVELY_CUTE'}
                                        onClick={(event) => handleMultiCheck(event)}
                                        checked={formData?.styleTag?.includes('LOVELY_CUTE') ? true : false}
                                    />
                                    <label className={classes.lableSt} style={{'margin-left': '10px'}}>
                                        러블리큐트
                                    </label>
                                </div>
                                <div>
                                    <Checkbox
                                        name={'styleTag'}
                                        value={'CHIC'}
                                        onClick={(event) => handleMultiCheck(event)}
                                        checked={formData?.styleTag?.includes('CHIC') ? true : false}
                                    />
                                    <label className={classes.lableSt} style={{'margin-left': '10px'}}>
                                        시크
                                    </label>
                                </div>
                                <div>
                                    <Checkbox
                                        name={'styleTag'}
                                        value={'EMOTION'}
                                        onClick={(event) => handleMultiCheck(event)}
                                        checked={formData?.styleTag?.includes('EMOTION') ? true : false}
                                    />
                                    <label className={classes.lableSt} style={{'margin-left': '10px'}}>
                                        감성
                                    </label>
                                </div>
                                <div>
                                    <Checkbox
                                        name={'styleTag'}
                                        value={'DREAM'}
                                        onClick={(event) => handleMultiCheck(event)}
                                        checked={formData?.styleTag?.includes('DREAM') ? true : false}
                                    />
                                    <label className={classes.lableSt} style={{'margin-left': '10px'}}>
                                        몽환
                                    </label>
                                </div>
                                <div>
                                    <Checkbox
                                        name={'styleTag'}
                                        value={'VINTAGE'}
                                        onClick={(event) => handleMultiCheck(event)}
                                        checked={formData?.styleTag?.includes('VINTAGE') ? true : false}
                                    />
                                    <label className={classes.lableSt} style={{'margin-left': '10px'}}>
                                        빈티지
                                    </label>
                                </div>
                                <div>
                                    <Checkbox
                                        name={'styleTag'}
                                        value={'NATURAL'}
                                        onClick={(event) => handleMultiCheck(event)}
                                        checked={formData?.styleTag?.includes('NATURAL') ? true : false}
                                    />
                                    <label className={classes.lableSt} style={{'margin-left': '10px'}}>
                                        자연
                                    </label>
                                </div>
                                <div>
                                    <Checkbox
                                        name={'styleTag'}
                                        value={'ANIMAL'}
                                        onClick={(event) => handleMultiCheck(event)}
                                        checked={formData?.styleTag?.includes('ANIMAL') ? true : false}
                                    />
                                    <label className={classes.lableSt} style={{'margin-left': '10px'}}>
                                        동물
                                    </label>
                                </div>
                                <div>
                                    <Checkbox
                                        name={'styleTag'}
                                        value={'TYPO'}
                                        onClick={(event) => handleMultiCheck(event)}
                                        checked={formData?.styleTag?.includes('TYPO') ? true : false}
                                    />
                                    <label className={classes.lableSt} style={{'margin-left': '10px'}}>
                                        타이포
                                    </label>
                                </div>
                                <div>
                                    <Checkbox
                                        name={'styleTag'}
                                        value={'SIMPLE'}
                                        onClick={(event) => handleMultiCheck(event)}
                                        checked={formData?.styleTag?.includes('SIMPLE') ? true : false}
                                    />
                                    <label className={classes.lableSt} style={{'margin-left': '10px'}}>
                                        심플
                                    </label>
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer justifyContent='center' style={{display: 'flex', justifyContent: 'center'}}>
                            <GridItem xs={2} sm={2} md={2}>
                                <Box className={classes.styleButtonSubmit}>
                                    <Button
                                        disabled={loadingBtn}
                                        color='primary'
                                        onClick={(e) => {
                                            setFormData({...formData, offset: 1})
                                            getListPostManaging()
                                        }}
                                    >
                                        검색
                                    </Button>
                                </Box>
                            </GridItem>
                        </GridContainer>
                    </>
                ) : null
            }

            {/*챌린지 리스트 검색*/}
            {
                events != null ? (
                    <Paper variant='outlined' className={classes.paper}>
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={6}>
                                챌린지 ID: {events?.id} <br/>
                                <h3>{events?.title}</h3>
                                필수 태그 : <Chip style={{'fontWeight': 'bold'}} label={events?.primaryTag}></Chip> <br/>
                                선택 태그 : {events?.manage_tags?.map((item) => {
                                return (<Chip style={{'fontWeight': 'bold'}} label={item?.tagName}></Chip>)
                            })} <br/>
                                이벤트
                                기간: {moment(events?.startAt).format('YYYY/MM/DD h:mmA')} ~ {moment(events?.endAt).format('YYYY/MM/DD h:mmA')}
                                {/*<Chip  classes={classes.voteColor} label={'투표문답'}/>*/}
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <img src={events?.files?.url} alt="" height={'150px'}/> <br/>
                            </GridItem>

                        </GridContainer>


                        <GridContainer>

                            <Box className={classes.btnGetExcelAndPaginationTable} mb={2}>
                                <Button
                                    color='primary'
                                    onClick={() => {
                                        getExcelFileBingo(events.id)
                                    }
                                    }
                                >
                                    다꾸빙고 엑셀 다운로드
                                </Button>
                            </Box>
                        </GridContainer>


                    </Paper>
                ) : null
            }


            <Box className={classes.boxTableBlock} my={2}>
                {loadingBtn ? (
                    <CircularProgress size={30} className={classes.buttonProgress}/>
                ) : (
                    <Table
                        headCells={headCells}
                        rows={postManagingLists}
                        setFormData={setFormData}
                        formData={formData}
                        totalRecords={totalRecords}
                        deletePostAction={deletePostAction}
                        movePostAction={movePostAction}
                        movePostPushAction={movePostPushAction}
                        postManagingApi={postManagingApi}
                        postManagingErrAction={postManagingErrAction}
                        setChangePostInfoId={setChangePostInfoId}
                        getListPostManagingExcel={getListPostManagingExcel}
                    />
                )}
            </Box>
        </div>
    )
}

const mapStateToProps = (state) => ({
    postManagingLists: state.postManaging.postManagingLists,
    metaData: state.postManaging.metaData,
    pagination: state.postManaging.pagination,
    formDataGlobal: state.postManaging.formDataGlobal,
    totalPostByTag: state.postManaging.totalPostByTag,
    totalPost: state.postManaging.totalPost,
    events: state.postManaging.events
})

export default connect(mapStateToProps, {
    requestPostManagingAction,
    getListPostManagingAction,
    deletePostAction,
    movePostAction,
    postManagingErrAction,
    setPaginationAction,
    setFormDataGlobalAction,
})(PostManaging)
