import * as actionTypes from './types'

export const createBoardManagingAction = (payload) => ({
    type: actionTypes.CREATE_BOARD_INFO_MANAGING,
    payload,
})

export const getListCommentsAction = (payload) => ({
    type: actionTypes.GET_LIST_COMMENTS,
    payload,
})


export const requestCommentsAction = (payload) => ({
    type: actionTypes.BOARD_INFO_MANAGING_REQUEST_ERROR,
    payload,
})