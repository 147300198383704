// 투표문답 문제 + 파일컴포넌트

import React from 'react'
import {useTheme} from "@material-ui/core/styles";
import CustomInput from "../CustomInput/CustomInput";
import CustomPagination from "../Pagination/Pagination";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";


const VoteQuestion = ({voteQuestion, voteFile, index}) => {
    const theme = useTheme()


    return (
        <GridContainer alignItems='center'>
            {/*1*/}
                <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                        labelText={'질문'+(index+1)}
                        id="float"
                        // inputProps={{
                        //     onChange: (event) => {
                        //         handleForm(event.target.value, 'linkUrl')
                        value={voteQuestion}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                    <input
                        accept='image/*'
                        /*className={classes.inputBtnUpload}*/
                        id='contained-button-file'
                        type='file'
                        onClick={(event => {
                            event.target.value = ''
                        })}
                        onChange={(e) => {

                            if (e.target.files && e.target.files.length > 0) {
                                const file = e.target.files[0]
                                const img = new Image()
                                img.src = window.URL.createObjectURL(file)
                                img.addEventListener('load', () => {
                                    setImageFile2(file) // 일단 바로 체크
                                    // validateUploadImage(file, 'size') ||
                                    // (!validateUploadImage(img, 'widthAndHeight') && setImageFile(file))
                                })
                            }

                            handleChangeFile(e, 1)
                        }}
                    />
                    <label htmlFor='contained-button-file'>
                        <Button
                            variant='outlined'
                            color='primary'
                            component='span'
                            fullWidth={true}
                            startIcon={<AddCircleOutlineOutlinedIcon/>}
                        >
                            {voteFile
                                ? voteFile.name
                                : ' 이미지를 첨부하세요'}
                        </Button>
                    </label>
                </GridItem>
        </GridContainer>


    )




}

VoteQuestion.defaultProps = {
    index : 0
}

export default VoteQuestion