import React from 'react'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import IconButton from '@material-ui/core/IconButton'
import MenuSelectForTable from './MenuSelectForTable'
import ShowAlertForTable from './ShowAlertForTable'
import RemoveIcon from '@material-ui/icons/Remove'

const useRowStyles = makeStyles({
  table: {
    minWidth: 900,
  },
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

const Row = (props) => {
  const classes = useRowStyles()

  const {  rows,  row, index, setAlert } = props

  const [open, setOpen] = React.useState(false)

  const showAlert = (
      idComment,
      idReplyComment,
      isDeleteComment,
      isDeleteReplyComment,
  ) => {
    setAlert(
        <ShowAlertForTable
            hideAlert={hideAlert}
            idComment={idComment}
            idReplyComment={idReplyComment}
            isDeleteComment={isDeleteComment}
            isDeleteReplyComment={isDeleteReplyComment}
        />,
    )
  }

  const hideAlert = () => {
    setAlert(null)
  }

  return (
      <React.Fragment>
        <TableRow hover={true} className={classes.root}>
          <TableCell>
            <IconButton
                aria-label='expand row'
                size='small'
                onClick={() => setOpen(!open)}
            >
              {open ? (
                  <KeyboardArrowUpIcon fontSize='small' />
              ) : (
                  <KeyboardArrowDownIcon fontSize='small' />
              )}
            </IconButton>
          </TableCell>
          <TableCell width={'200px'} >
            {row && (rows?.length - row?.rownum+1) }
          </TableCell>
          <TableCell width={'200px'} >
              {row && moment(row?.createdAt).format('YYYY-MM-DD')}
          </TableCell>
          <TableCell width={'200px'} >
              {row && row?.nickname}
          </TableCell>
          <TableCell width={'200px'}>
            {row && row?.isFollow == 0 && '미노출'}
            {row && row?.isFollow == 1 && '노출'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
              style={{
                padding: 0,
              }}
              colSpan={5}
          >
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box>
                <Table size='small' aria-label='purchases'>
                  <TableBody>

                      {rows?.map((item, index) => (

                          index > 0 && (
                              <TableRow hover={true} >
                                <TableCell
                                    style={{
                                      width: 80,
                                    }}
                                >
                                  {/*<IconButton aria-label='expand row' size='small'>
                              <RemoveIcon fontSize='small' />
                            </IconButton>*/}
                                </TableCell>
                                <TableCell
                                    scope='row'
                                    component='th'
                                    width={'200px'}
                                >
                                  {item && (rows?.length - item?.rownum +1) }
                                </TableCell>
                                <TableCell
                                    width={'200px'}
                                >
                                  {item && moment(item?.createdAt).format('YYYY-MM-DD')}
                                </TableCell>
                                <TableCell

                                    width={'200px'}
                                >
                                  {item && item?.nickname}
                                </TableCell>
                                <TableCell
                                    width={'200px'}
                                >
                                  {item && item?.isFollow == 0 && '미노출'}
                                  {item && item?.isFollow == 1 && '노출'}
                                </TableCell>
                              </TableRow>
                          )
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
  )
}

export default function CollapsibleTable({ rows }) {
  const classes = useRowStyles()
  const [alert, setAlert] = React.useState(null)

  return (
      <TableContainer component={Paper}>
        {alert}
        <Table
            className={classes.table}
            size='small'
            aria-label='collapsible table'
        >
          <TableHead>
            <TableRow>
              <TableCell
                  style={{
                    width: 80,
                  }}
              />
              <TableCell
                 
              >
                제작 요청하기 누적 횟수
              </TableCell>
              <TableCell

              >
                요청 일자
              </TableCell>
              <TableCell
                  
                 
              >
                요청자(닉네임)
              </TableCell>
              <TableCell
                  
                 
              >팔로우 및 알림 체크</TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
                <Row rows={rows} row={rows[0]} setAlert={setAlert} />
            </TableBody>
        </Table>
      </TableContainer>
  )
}
