import React, {useEffect} from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import PropTypes from 'prop-types'
import moment from 'moment'

import CustomTextField from 'components/Gm-TextField/TextField'
import {makeStyles} from '@material-ui/core/styles'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import Box from '@material-ui/core/Box'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'

import styles from 'assets/jss/material-dashboard-pro-react/views/MainManaging/bannerManaging'
import MenuItem from "@material-ui/core/MenuItem";

import MenuList from '@material-ui/core/MenuList';
import Radio from "@material-ui/core/Radio";
import TextField from "../../../components/Gm-TextField/TextField";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TimePicker from "../../Statistics/components/TimePicker";
import eventsManagingApi from "../../../api/eventsManagingApi";
import {API_URL} from "../../../api/apiUrl";
import {CustomAlert} from "../../../util/common";

const useStyles = makeStyles(styles)



const EditBanner = (props) => {

	useEffect(() => {
		//mounted
		document.querySelector('.sweet-alert').parentElement.style.zIndex = 1034;

		if (item?.type === 'EVENT') {
			getListEvents('event')
		} else if (item?.type === 'CHALLENGE') {
			getListEvents('challenge')
		}

	}, []);

	const {
		item,
		hideAlert,
		setAlert,
		dispatch,
		editBannerManagingAction,
		bannerManagingRequestErrorAction,
		bannerManagingApi,
		getData,
	} = props

	const classes = useStyles()

	const d = new Date()
	let tz = d.getTimezoneOffset() / 60
	tz = tz < 0 ? tz * -1 : tz

	const getUTCHours = (date) => {
		const dateObj = new Date(date);
		return dateObj.getUTCHours()
	}

	const [imageFile, setImageFile] = React.useState(null)
	const [imageFile2, setImageFile2] = React.useState(null)
	const [imageFile3, setImageFile3] = React.useState(null)
	const [loading, setLoading] = React.useState(false)
	const [showNotification, setShowNotification] = React.useState({
		open: false,
		message: '',
	})
	const [isUploadImage, setIsUploadImage] = React.useState(false)
	const [isUploadImage2, setIsUploadImage2] = React.useState(false)
	const [isUploadImage3, setIsUploadImage3] = React.useState(false)
	const [eventList, setEventList] = React.useState(null)
	const [formDataForEditBanner, setFormDataForEditBanner] = React.useState({

		startAt: item ? moment(item.startAt).format('YYYY-MM-DD') : moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
		endAt: item ? moment(item.endAt).format('YYYY-MM-DD') : moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
		numOrder: item?.numOrder,
		fromTime: item ? parseInt(moment(item.startAt).format('HH')) : 0,
		toTime: item ? parseInt(moment(item.endAt).format('HH')) : 0,
		eventsId: item?.eventsId,
		type: item?.type,
		position: item?.position || 'MAIN',
		exposure: item?.exposure, // 노출 : 배너, 팝업
		webViewTitle: item?.webViewTitle,
		mainImage: item?.mainImage,
		subImage: item?.subImage,
        popupImage: item?.popupImage,
		linkUrl: item?.linkUrl,
		status: item?.status,
	})

	const handleCloseOpen = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setShowNotification({...showNotification, open: false})
	}

	const handleChangeSelect = (e, item) => {
		//update 문 치기 활성화 하나만이라도

		item.status = e.target.value
		handleForm(e.target.value, 'status')
	}

	const handleChangeSelectType = (e, item) => {
		//update 문 치기 활성화 하나만이라도

		item.eventsId = e.target.value
		handleForm(e.target.value, 'eventsId')
	}

	// const handleChangeSelectPosition = (e, item) => {
	// 	//update 문 치기 활성화 하나만이라도
	//
	// 	item.position = e.target.value
	// 	//formDataForEditBanner.positon = item.positon
	// 	handleForm(e.target.value, 'position')
	// }


	const dateTimeCheck = (date, time) => {
		if (time <= 9) {
			return date + " " + "0" + time + ":00" // YYYY-MM-DD HH:mm
		} else {
			return date + " " + time + ":00"
		}

	}

	const handleForm = (value, key) => {
		//vue의 데이터 매핑 형태였던 것 같은데.... key도 매핑되네 히야...


		if (key === 'type') {
			if (value === 'EVENT') {
				getListEvents('event')
			} else if (value === 'CHALLENGE') {
				getListEvents('challenge')
			}
		}

		switch (key) {
			case 'startAt' :
				setFormDataForEditBanner({
					...formDataForEditBanner,
					[key]: moment(value).format('YYYY-MM-DD'),
				})
				break;
			case 'endAt' :
				setFormDataForEditBanner({
					...formDataForEditBanner,
					[key]: moment(value).format('YYYY-MM-DD'),
				})
				break;
			default:
				setFormDataForEditBanner({
					...formDataForEditBanner,
					[key]: value,
				})

		}

		//eventsId 사용시 챌린지
		if (key == 'eventsId'){
			eventList.map((item)=>{
				if (item.contentLinkType == 'BINGO' && item.id == value ){
					setFormDataForEditBanner({
						...formDataForEditBanner,
						'linkUrl': item.linkUrl,
						'eventsId': value
					})
				}else if(
					(item.contentLinkType == 'DAILY_CHECK' && item.id == value) ||
					(item.contentLinkType == 'MULTI' && item.id == value)
				){
					setFormDataForEditBanner({
						...formDataForEditBanner,
						'linkUrl': API_URL.split('/api/ADMIN/v1.5/')[0] + '/eventPage?eventsId='+ value,
						'eventsId': value
					})
				}
			})
		}

		return

	}

	const handleChangeFormDataForEditBanner = (e) => {
		setFormDataForEditBanner({
			...formDataForEditBanner,
			[e.target.name]: e.target.value,
		})
	}

	const validateImageAlert = (message) => {
		setAlert(
				<SweetAlert
						warning
						style={{display: 'block', marginTop: '-100px'}}
						title=''
						onConfirm={() => hideAlert()}
						showConfirm={false}
						onCancel={() => hideAlert()}
				>
					<Typography component='p' gutterBottom>
						{message}
					</Typography>
					<Button color='success' onClick={hideAlert}>
						OK
					</Button>
				</SweetAlert>,
		)
	}

	const handleChangeTimePickerStart = (event) => {
		setFormDataForEditBanner({...formDataForEditBanner, fromTime: event.target.value})
	}
	const handleChangeTimePickerEnd = (event) => {
		setFormDataForEditBanner({...formDataForEditBanner, toTime: event.target.value})
	}

	const handleChangeFile = (e, whichBanner) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0]
			if (validateUploadImage(file, 'size')) return

			const updateState = (file) => {
				setImageFile(file)
				setIsUploadImage(true)
			}
			const updateState2 = (file) => {
				setImageFile2(file)
				setIsUploadImage2(true)
			}
            const updateState3 = (file) => {
                setImageFile3(file)
                setIsUploadImage3(true)
            }
			// use for check width and height image
			const img = new Image()
			img.src = window.URL.createObjectURL(file)
			img.addEventListener('load', () => {
				whichBanner === 'main' && !validateUploadImage(img, 'widthAndHeight', whichBanner) && updateState(file)
				//validateUploadImage(file, 'size') || (!validateUploadImage(img, 'widthAndHeight') && updateState(file))

				whichBanner === 'bottom' && !validateUploadImage(img, 'widthAndHeight', whichBanner) && updateState2(file)

				whichBanner === 'popup' && !validateUploadImage(img, 'widthAndHeight', whichBanner) && updateState3(file)
				// !validateUploadImage(file, 'size', whichBanner) && updateState2(file)
			})
		}
	}

	/*이벤트 조회*/
	const getListEvents = async (type) => {

		let orderString = '';

		//if (orderBy == 'first') {
		//처음 정렬시 현재 front에 나오는 표출 순서대로
		orderString = 'numOrder,DESC&createdAt,DESC';
		// } else {
		//   orderString = orderBy + ',' + order
		// }


		let params = {
			limit: 10000,
			offset: 0,
			type: type,
			order: orderString,
			searchText: '',
			searchKey: 'title',
			endAt: 'more60day'
		}

		try {
			//requestEventsAction()
			const {data} = await eventsManagingApi.getListEvents(params)
			//getListEventsAction(data)
			setEventList(data.rows)
		} catch (error) {
			if (
					error &&
					error.response &&
					error.response.data &&
					error.response.data.data
			) {
				console.log(error.response.data)
			}
		}
	}

	// reset value input width type = file so that can show alert with same file select
	const onInputClick = (event) => {
		event.target.value = ''
	}

	const validateUploadImage = (file, whichCheck, whichBanner) => {
		// if (whichCheck === 'size' && Math.round(file.size / 1024) > 2048) {
		// 	validateImageAlert('File too Big, please select a file less than 2MB')
		// 	return true
		// }

		// if (
		// 		whichCheck === 'widthAndHeight' && whichBanner === 'main' &&
		// 		file.width !== 327 &&
		// 		file.height !== 294
		// ) {
		// 	validateImageAlert(
		// 			'Please upload image with width equal to 327px and height equal to 294px',
		// 	)
		// 	return true
		// }

		/*if (
				whichCheck === 'widthAndHeight' && whichBanner === 'bottom' &&
				file.width !== 375 &&
				file.height !== 230
		) {
			validateImageAlert(
					'Please upload image with width equal to 375px and height equal to 230px',
			)
			return true
		}

      if (
              whichCheck === 'widthAndHeight' && whichBanner === 'popup' &&
              file.width !== 375 &&
              file.height !== 230
      ) {
        validateImageAlert(
                'Please upload image with width equal to 375px and height equal to 230px',
        )
        return true
      }*/
	}

	const successEdit = () => {
		setAlert(
				<SweetAlert
						success
						style={{display: 'block', marginTop: '-100px'}}
						title='Edited!'
						onConfirm={() => hideAlert()}
						showConfirm={false}
						onCancel={() => hideAlert()}
				>
					<Button onClick={hideAlert} color='success'>
						OK
					</Button>
				</SweetAlert>,
		)
	}

	const editBannerManaging = async () => {
		try {

			// return
			if (formDataForEditBanner.webViewTitle===null || formDataForEditBanner.webViewTitle==='' ) {
				//문자열일때
				alert("배너명을 입력해주세요")
				return
			}

			if (formDataForEditBanner.type==='URL' &&
					(formDataForEditBanner.linkUrl===null || formDataForEditBanner.linkUrl === '')) {
				//문자열일때
				alert("URL을 입력해주세요")
				return
			}

			if (formDataForEditBanner.type==='EVENT' && formDataForEditBanner.eventsId < 1) {
				//문자열일때
				alert("이벤트를 선택해주세요")
				return
			}

			if (formDataForEditBanner.type==='CHALLENGE' && formDataForEditBanner.eventsId < 1) {
				//문자열일때
				alert("챌린지를 선택해주세요")
				return
			}

			if (isNaN(formDataForEditBanner.numOrder)) {
				//문자열일때
				alert("우선순위는 숫자만 입력해주세요")
				return
			}

			//이미지 없을경우
			if (formDataForEditBanner.exposure==='BANNER' && formDataForEditBanner.subImage===null && formDataForEditBanner.mainImage===null) {

				if( (formDataForEditBanner.position == 'MAIN' )
					&& (imageFile2===null )){

					alert("하단 배너를 첨부해주세요")
					return
				}
				if( ( formDataForEditBanner.position == 'SHOP_GOODS')
					&& (imageFile===null || imageFile2===null)){

					alert("배너를 첨부해주세요")
					return
				}
				if (formDataForEditBanner.position == 'STAR' && ( imageFile2===null)){
					alert("배너를 첨부해주세요")
					return
				}
				if (formDataForEditBanner.position == 'SHOP_MAIN' && ( imageFile===null)){
					alert("배너를 첨부해주세요")
					return
				}

			}
			if (formDataForEditBanner.exposure==='POPUP' && formDataForEditBanner.popupImage===null && imageFile3===null) {
				alert("배너를 첨부해주세요")
				return
			}

			// call api upload file
			setLoading(true)
			let setNewmainImage
			let setNewsubImage
			let setNewPopupImage
			if (isUploadImage) {
				const formData = new FormData()
				formData.append('file', imageFile, imageFile.name)
				formData.append('type', 'SLIDE')

				const {
					data: {id, url, filename},
				} = await bannerManagingApi.uploadImage(formData)

				setNewmainImage = {
					id,
					url,
					filename,
				}
				setIsUploadImage(false)
			}

			if (isUploadImage2) {
				const formData = new FormData()
				formData.append('file', imageFile2, imageFile2.name)
				formData.append('type', 'SLIDE')

				const {
					data: {id, url, filename},
				} = await bannerManagingApi.uploadImage(formData)

				setNewsubImage = {
					id,
					url,
					filename,
				}
				setIsUploadImage2(false)
			}

          if (isUploadImage3) {
            const formData = new FormData()
            formData.append('file', imageFile3, imageFile3.name)
            formData.append('type', 'SLIDE')

            const {
              data: {id, url, filename},
            } = await bannerManagingApi.uploadImage(formData)

            setNewPopupImage = {
              id,
              url,
              filename,
            }
            setIsUploadImage3(false)
          }


			// call api edit banner
			const body = {
				id: item?.id,
				webViewTitle: formDataForEditBanner?.webViewTitle,
				mainImage:( formDataForEditBanner.exposure === 'BANNER' && ( formDataForEditBanner.position == 'MAIN' || formDataForEditBanner.position == 'SHOP_MAIN' || formDataForEditBanner.position == 'POST_GOODS' ) )?
						( isUploadImage
						? setNewmainImage?.id
						: formDataForEditBanner?.mainImage?.id ) : null,

				subImage: (formDataForEditBanner.exposure === 'BANNER' && (formDataForEditBanner.position != 'SHOP_MAIN'))?
						( isUploadImage2
						? setNewsubImage?.id
						: formDataForEditBanner?.subImage?.id ) : null,

                popupImage: formDataForEditBanner.exposure === 'POPUP'?
		                ( isUploadImage3
                      ? setNewPopupImage?.id
                      : formDataForEditBanner?.popupImage.id ) : null,

				linkUrl: formDataForEditBanner?.linkUrl,

				eventsId: formDataForEditBanner?.eventsId,
				status: formDataForEditBanner?.status,
				position: formDataForEditBanner?.position,
				exposure: formDataForEditBanner?.exposure,
				startAt: new Date(moment(dateTimeCheck(formDataForEditBanner.startAt, formDataForEditBanner.fromTime)).format("YYYY-MM-DD HH:mm")).getTime(),
				endAt: new Date(moment(dateTimeCheck(formDataForEditBanner.endAt, formDataForEditBanner.toTime)).format("YYYY-MM-DD HH:mm")).getTime(),
				numOrder: parseInt(formDataForEditBanner.numOrder),
				type: formDataForEditBanner.type,
			}

			if (item.editBannerAction) {
				await bannerManagingApi.create(body)  //생성
			} else {
				await bannerManagingApi.edit(body)  //수정
			}

			body['mainImage'] = isUploadImage
					? setNewmainImage
					: formDataForEditBanner?.mainImage
			body['updatedAt'] = moment().format()

			dispatch(editBannerManagingAction(body))
			setLoading(false)
			successEdit()
			getData()
		} catch (error) {
			console.log(error)
			setLoading(false)

			setShowNotification({
				...showNotification,
				open: true,
				message: error?.response?.data?.data?.error,
			})

			dispatch(bannerManagingRequestErrorAction(error?.response?.data))
		}
	}

	return (
			<>
				<Snackbar
						anchorOrigin={{vertical: 'top', horizontal: 'center'}}
						open={showNotification.open}
						autoHideDuration={6000}
						onClose={handleCloseOpen}
				>`
					<CustomAlert onClose={handleCloseOpen} severity='error'>
						{showNotification.message}
					</CustomAlert>
				</Snackbar>
				<SweetAlert
						style={{display: 'block', marginTop: '-100px', width: '80em', zIndex: '1034',}}
						title={item.editBannerAction ? '추가하기' : '수정하기'}
						onConfirm={() => {
						}}
						showConfirm={false}
						onCancel={() => hideAlert()}
				>
					<GridContainer>
						<GridItem
								container
								justifyContent='flex-start'
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								className={classes.spacingbetweenTwoColOfModal}
								style={{marginBottom: '10px'}}
						>
							<p style={{padding: '8px', lineHeight: '3'}}>종류 </p>
							<MenuList
									className={classes.menuList}
							>
								<MenuItem
										selected={formDataForEditBanner.exposure === 'BANNER'}
										onClick={() => handleForm('BANNER', 'exposure')}
								>
									<Radio
											checked={formDataForEditBanner.exposure === 'BANNER'}
											size='small'
											value='BANNER'
											name='radio-button-demo'
											inputProps={{'aria-label': 'A'}}
									/>
									배너
								</MenuItem>
								<MenuItem
										selected={formDataForEditBanner.exposure === 'POPUP'}
										onClick={() => handleForm('POPUP', 'exposure')}
								>
									<Radio
											checked={formDataForEditBanner.exposure === 'POPUP'}
											size='small'
											value='POPUP'
											name='radio-button-demo'
											inputProps={{'aria-label': 'B'}}
									/>
									팝업
								</MenuItem>
							</MenuList>

							<TextField
									id='user-managing-select'
									select
									size='small'
									className={classes.widthTextFieldModal}
									value={item.status}
									onChange={(e) => {
										handleChangeSelect(e, item)
									}}
									label='상태 값을 선택해주세요'
									style={{marginLeft: '25px', width: '30%', paddingTop: '10px'}}
							>
								<MenuItem value={'ACTIVE'}>활성화</MenuItem>
								<MenuItem value={'DEACTIVATED'}>비활성화</MenuItem>
								<MenuItem value={'REMOVED'}>삭제됨</MenuItem>
								<MenuItem value={'ALWAYS'}>영구 노출</MenuItem>
							</TextField>

							<TextField
									id='user-managing-select'
									select
									size='small'
									className={classes.widthTextFieldModal}
									value={formDataForEditBanner.position}
									onChange={(e) => {
										handleForm(e.target.value, 'position')
										// handleChangeSelect(e, item)
									}}
									label='위치를 선택해주세요'
									style={{marginLeft: '25px', width: '30%', paddingTop: '10px'}}
							>
								{
									formDataForEditBanner.exposure == 'BANNER' ?  (<MenuItem value={'MAIN'}>메인(검색메인)</MenuItem>) :  (<MenuItem value={'MAIN'}>메인</MenuItem>)
								}
								}


								{
									formDataForEditBanner.exposure == 'BANNER' ?  (<MenuItem value={'STAR'}>라이징스타</MenuItem>) : null
								}

								{
									formDataForEditBanner.exposure == 'BANNER' ?  (<MenuItem value={'SHOP_MAIN'}>메인(작가메인)</MenuItem>) : null
								}
								{
									formDataForEditBanner.exposure == 'BANNER' ?  (<MenuItem value={'POST_GOODS'}>굿즈</MenuItem>) : null
								}



							</TextField>
						</GridItem>
						<GridItem
								container
								justifyContent='flex-start'
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								className={classes.spacingbetweenTwoColOfModal}
						>
							<p style={{padding: '8px 20px 8px 0px', lineHeight: '3'}}>배너명 </p>
							<CustomTextField
									id='editBannerUpload'
									label='배너명을 입력하세요'
									variant='standard'
									name='webViewTitle'
									value={formDataForEditBanner?.webViewTitle}
									className={classes.widthTextFieldModalTwo}
									onChange={handleChangeFormDataForEditBanner}
									style={{marginBottom: '20px', width: '50%'}}
							/>
						</GridItem>
						<GridItem
								container
								justifyContent='flex-start'
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								className={classes.spacingbetweenTwoColOfModal}
								style={{marginBottom: '25px', width: '50%'}}
						>
							{/*<GridItem className={classes.lableSt}*/}
							{/*          xs={1} sm={1} md={1}*/}
							{/*>*/}
							{/*  */}
							{/*</GridItem>*/}
							{formDataForEditBanner.status != 'ALWAYS'&& (<>
								<GridItem
									xs={3}
									sm={3}
									className={classes.styleDatePicker}
								>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											variant='inline'
											label='배너 시작날짜 선택'
											TextFieldComponent={TextField}
											format='yyyy-MM-dd'
											id='date-picker-inline1'
											value={formDataForEditBanner?.startAt}
											onChange={(date) =>
												handleForm(date, 'startAt')
											}
											autoOk={true}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}

										/>
									</MuiPickersUtilsProvider>
								</GridItem>
								<GridItem
									className={classes.paddingLeft}
									xs={2}
									sm={2}
								>
									<Box>
										<TimePicker
											label='배너 시작시간 선택'
											time={formDataForEditBanner?.fromTime}
											handleChangeTimePicker={handleChangeTimePickerStart}
											editBanner={true}
										/>
									</Box>
								</GridItem>
								{/*공백용*/}
								{/*<GridItem xs={2} sm={2} md={2} />*/}
								<GridItem className={classes.lableSt}
										  xs={1} sm={1} md={1}
										  style={{padding: '0'}}
								>
									~
								</GridItem>
								<GridItem
									xs={3}
									sm={3}
									className={classes.styleDatePicker}
								>
									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<KeyboardDatePicker
											label='배너 종료날짜 선택'
											variant='inline'
											TextFieldComponent={TextField}
											format='yyyy-MM-dd'
											id='date-picker-inline1'
											value={formDataForEditBanner?.endAt}
											onChange={(date) =>
												handleForm(date, 'endAt')
											}
											autoOk={true}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
										/>
									</MuiPickersUtilsProvider>
								</GridItem>
								<GridItem
									className={classes.paddingLeft}
									xs={3}
									sm={2}
								>
									<Box>
										<TimePicker
											label='배너 종료시간 선택'
											time={formDataForEditBanner?.toTime}
											handleChangeTimePicker={handleChangeTimePickerEnd}
											setKey='toTime'
											editBanner={true}
										/>
									</Box>
								</GridItem>
							</>)}

						</GridItem>
						<GridItem
								container
								justifyContent='flex-start'
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								className={classes.spacingbetweenTwoColOfModal}
								style={{marginBottom: '10px'}}
						>
							<p style={{padding: '8px', lineHeight: '3'}}>링크 타입 </p>
							<MenuList
									className={classes.menuList}
							>
								<MenuItem
										selected={formDataForEditBanner.type === 'URL'}
										onClick={() => handleForm('URL', 'type')}
								>
									<Radio
											checked={formDataForEditBanner.type === 'URL'}
											value='URL'
											size='small'
											name='radio-button-demo'
											inputProps={{'aria-label': 'A'}}
									/>
									URL(외부)
								</MenuItem>
								<MenuItem
										selected={formDataForEditBanner.type === 'WEB_VIEW'}
										onClick={() => handleForm('WEB_VIEW', 'type')}
								>
									<Radio
											checked={formDataForEditBanner.type === 'WEB_VIEW'}
											value='URL'
											size='small'
											name='radio-button-demo'
											inputProps={{'aria-label': 'A'}}
									/>
									URL(내부)
								</MenuItem>
								<MenuItem
										selected={formDataForEditBanner.type === 'EVENT'}
										onClick={() => handleForm('EVENT', 'type')}
								>
									<Radio
											checked={formDataForEditBanner.type === 'EVENT'}
											size='small'
											value='EVENT'
											name='radio-button-demo'
											inputProps={{'aria-label': 'B'}}
									/>
									이벤트
								</MenuItem>
								<MenuItem
										selected={formDataForEditBanner.type === 'CHALLENGE'}
										onClick={() => handleForm('CHALLENGE', 'type')}
								>
									<Radio
											checked={formDataForEditBanner.type === 'CHALLENGE'}
											size='small'
											value='CHALLENGE'
											name='radio-button-demo'
											inputProps={{'aria-label': 'C'}}
									/>
									챌린지
								</MenuItem>
							</MenuList>

							{
								(( formDataForEditBanner.type === 'URL') ||  (formDataForEditBanner.type === 'WEB_VIEW')) &&
								<CustomTextField
										id='outlined-basic'
										value={formDataForEditBanner?.linkUrl}
										name='linkUrl'
										onChange={handleChangeFormDataForEditBanner}
										label='URL을 입력하세요'
										className={classes.linkTypeInput}
								/>
							}
							{
								formDataForEditBanner.type === 'EVENT' &&
								<TextField
										id='banner-managing-select'
										select
										size='small'
										className={classes.linkTypeSelect}
										value={item.eventsId}
										label='이벤트를 선택해주세요'
										onChange={(e) => {
											handleChangeSelectType(e, item)
										}}
								>
									{
										eventList == null ?
												<MenuItem>등록된 이벤트가 없습니다!</MenuItem>
												: eventList.map((event, i) =>
														<MenuItem value={event.id}>{event.title}</MenuItem>
												)
									}
								</TextField>
							}
							{
								formDataForEditBanner.type === 'CHALLENGE' &&
								<TextField
										id='banner-managing-select'
										select
										size='small'
										className={classes.linkTypeSelect}
										value={item.eventsId}
										label='챌린지를 선택해주세요'
										onChange={(e) => {
											handleChangeSelectType(e, item)
										}}
								>
									{
										eventList == null ?
												<MenuItem>등록된 첼린지가 없습니다!</MenuItem>
												: eventList.map((event, i) =>
														<MenuItem value={event.id}>{event.title}</MenuItem>
												)
									}
								</TextField>
							}
						</GridItem>
						<GridItem
								container
								justifyContent='flex-start'
								xs={12}
								sm={8}
								md={6}
								lg={6}
								xl={5}
								className={classes.spacingbetweenTwoColOfModal}
								style={{marginBottom: '25px'}}
						>

						</GridItem>
						<GridItem
								container
								justifyContent='flex-start'
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								className={classes.spacingbetweenTwoColOfModal}
								style={{marginBottom: '25px'}}
						>
							<TextField
									id='numOrder'
									value={formDataForEditBanner?.numOrder}
									label='우선순위를 입력하세요'
									className={classes.widthTextFieldModal}
									name='numOrder'
									size='small'
									onChange={handleChangeFormDataForEditBanner}
									style={{marginRight: '5px'}}
									inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
							/>
							<p style={{padding: '8px'}}> *노출순서가 높을수록 먼저 표출됩니다. 노출순서가 같을 경우는 최신순으로 표출됩니다.</p>
						</GridItem>
						{
							(formDataForEditBanner.exposure === 'BANNER' && (formDataForEditBanner.position === 'MAIN' || formDataForEditBanner.position === 'STAR' || formDataForEditBanner.position == 'POST_GOODS' )) &&
							<GridItem
									container
									justifyContent='flex-start'
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									className={classes.spacingbetweenTwoColOfModal}
							>
								<Box style={{width: '30%'}}>
									<input
											accept='image/*'
											className={classes.inputBtnUpload}
											id='contained-button-file-2'
											multiple
											type='file'
											onClick={onInputClick}
											onChange={(e) => handleChangeFile(e, 'bottom')}
									/>
									<label htmlFor='contained-button-file-2'>
										<Button
												variant='outlined'
												color='primary'
												component='span'
												fullWidth={true}
												startIcon={<AddCircleOutlineOutlinedIcon/>}
												disabled={loading}
										>
											{imageFile2
													? imageFile2.name
													: formDataForEditBanner?.subImage?.filename}
											{imageFile2 == null && formDataForEditBanner.subImage == null && '하단 배너를 등록해주세요'}
										</Button>
									</label>
								</Box>
								<Box mt={1} style={{marginLeft: '15px'}}>
									<p style={{paddingTop: '5px'}}>
										배너 사이즈: 375*90px. 2MB 이하의 jpg, png 파일 첨부 가능.
										(검색시 보이는 리스트 및 이벤트 리스트에 표출됩니다.)
									</p>
								</Box>
							</GridItem>
						}
						{
							(formDataForEditBanner.exposure === 'BANNER' &&
								(formDataForEditBanner.position == 'MAIN' ||formDataForEditBanner.position == 'SHOP_MAIN' ||
									formDataForEditBanner.position == 'POST_GOODS')) &&
							<GridItem
								container
								justifyContent='flex-start'
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								className={classes.spacingbetweenTwoColOfModal}
							>
								<Box style={{width: '30%'}}>
									<input
										accept='image/*'
										style={{display: 'none'}}
										id='contained-button-file-1'
										className={classes.inputBtnUpload}
										multiple
										type='file'
										onClick={onInputClick}
										onChange={(e) => handleChangeFile(e, 'main')}
									/>
									<label htmlFor='contained-button-file-1'>
										<Button
											variant='outlined'
											color='github'
											component='span'
											fullWidth={true}
											startIcon={<AddCircleOutlineOutlinedIcon/>}
											disabled={loading}
										>
											{imageFile
												? imageFile.name
												: formDataForEditBanner?.mainImage?.filename}
											{imageFile == null && formDataForEditBanner.mainImage == null && (formDataForEditBanner.position == 'SHOP_MAIN'||formDataForEditBanner.position == 'POST_GOODS')  && '상단 배너를 등록해주세요'}
											{imageFile == null && formDataForEditBanner.mainImage == null && formDataForEditBanner.position == 'MAIN' && '상단 배너를 등록해주세요 (사용x)'}
										</Button>
									</label>
								</Box>
								<Box mt={1} style={{marginLeft: '15px'}}>
									<p style={{paddingTop: '5px'}}>
										{formDataForEditBanner.position == 'MAIN' && <> ver 2.6 이전 홈(배너)</>}
										{formDataForEditBanner.position == 'SHOP_MAIN' && <> 작가 메인 콘텐츠 배너: 299x350px </>}
									</p>

								</Box>
							</GridItem>
						}
						{
							formDataForEditBanner.exposure === 'POPUP' &&
							<GridItem
									container
									justifyContent='flex-start'
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									className={classes.spacingbetweenTwoColOfModal}
							>
								<Box style={{width: '30%'}}>
									<input
											accept='image/*'
											className={classes.inputBtnUpload}
											id='contained-button-file-3'
											multiple
											type='file'
											onClick={onInputClick}
											onChange={(e) => handleChangeFile(e, 'popup')}
									/>
									<label htmlFor='contained-button-file-3'>
										<Button
												variant='outlined'
												color='primary'
												component='span'
												fullWidth={true}
												startIcon={<AddCircleOutlineOutlinedIcon/>}
												disabled={loading}
										>
											{imageFile3
													? imageFile3.name
													: formDataForEditBanner?.popupImage?.filename}
											{imageFile3 == null && formDataForEditBanner.popupImage == null && '팝업 배너를 등록해주세요'}
										</Button>
									</label>
								</Box>
								<Box mt={1} style={{marginLeft: '15px'}}>
									<p style={{paddingTop: '5px'}}>
										팝업 사이즈: 375*230px. 2MB 이하의 jpg, png 파일 첨부 가능.
										( 화면 진입시 팝업으로 표출됩니다. )
									</p>
								</Box>
							</GridItem>
						}

					</GridContainer>
					<Box mt={1} display='flex' justifyContent='center'>
						<Button
								onClick={hideAlert}
								className={classes.marginRightBtnInModal}
								color='danger'
						>
							취소
						</Button>
						<Button
								color='success'
								disabled={loading}
								onClick={editBannerManaging}
						>
							수정
						</Button>
					</Box>
				</SweetAlert>
			</>
	)
}

EditBanner.propTypes = {
	item: PropTypes.object.isRequired,
	hideAlert: PropTypes.func.isRequired,
	dispatch: PropTypes.func.isRequired,
	editBannerManagingAction: PropTypes.func.isRequired,
	bannerManagingRequestErrorAction: PropTypes.func.isRequired,
	bannerManagingApi: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
}

export default EditBanner
