import * as actionTypes from '../actions/types'
import {GET_LIST_STATICS_OF_QUIT_REASON_CLICK} from "../actions/types";

const initialState = {
    loading: true,
    error: null,
    listStaticsOfClick: [],
    metaData: {
        totalPages: 1,
    },
    totalCount: 0,
    totalKind: []
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case actionTypes.GET_LIST_STATICS_OF_QUIT_REASON_CLICK:
            const { statics, metaData, totalCount, totalKind } = payload
            JSON.stringify(totalKind)
            return {
                ...state,
                loading: false,
                listStaticsOfClick: statics,
                totalCount:totalCount,
                totalKind: totalKind,
                metaData: metaData,
                error: payload,
            }
        default:
            return state
    }
}
