import React from 'react'
import moment from 'moment'
import fileDownload from 'js-file-download'
import queryString from 'query-string'


import { makeStyles } from '@material-ui/core/styles'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import TimePicker from '../components/TimePicker'
import TextField from 'components/Gm-TextField/TextFieldForDatePicker'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import Spinner from 'components/Spinner/Spinner'
import Pagination from 'components/Pagination/Pagination'

import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import { StatisticUsersTable } from '../components/StatisticUsersTable.js'

import {useSelector, useDispatch, connect} from 'react-redux'
import {
     getStaticsPostsAction,
    staticsOfClickRequestErrorAction,
} from 'redux/actions/staticsPostsAction'
import staticsOfClickApi from 'api/staticsOfClickApi'

import styles from 'assets/jss/material-dashboard-pro-react/views/Statistics/statisticClick.js'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {FiberManualRecord} from "@material-ui/icons";
import {
    getChildCodeListAction,
    getCodeListAction,
    requestCommentsAction
} from "../../../redux/actions/codeManagingAction";
import staticsPostReducer from "../../../redux/reducers/staticsPostReducer";
import Tooltip from "@material-ui/core/Tooltip";
const useStyles = makeStyles(styles)

const StatisticUsers = () => {
    const classes = useStyles()

    const dispatch = useDispatch()
    const {
        loading: loadingPage,
        listStaticsOfPost,
        metaData: { totalPages, totalRecords },
    } = useSelector((state) => ({
        loading: state.staticsPostReducer.loading,
        listStaticsOfPost: state.staticsPostReducer.listStaticsOfPost,
        metaData: state.staticsPostReducer.metaData,
    }))

    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [order, setOrder] = React.useState('desc')
    const [loadingButtonGetExcel, setLoadingButtonGetExcel] = React.useState(
        false,
    )

    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz

    const [pagination, setPagination] = React.useState(1)
    const [isFirstLoad, setIsFirstLoad] = React.useState(true)
    const [formData, setFormData] = React.useState({
        fromDate: moment().subtract(7, 'days').subtract(tz, 'hours').calendar({
            sameElse: 'YYYY-MM-DD',
        }),
        toDate: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        fromTime: 0,
        toTime: 23,
        limit: 10,
    })

    const handleChangeFormDate = (date, key) => {
        setFormData({
            ...formData,
            [key]: moment(date).subtract(tz, 'hours').format('YYYY-MM-DD'),
        })
    }
    const handleForm = (e, keys =null) => {
        if (e != null){
            if (keys == null) keys = e.target.name
            setFormData({ ...formData, [keys]: e.target.value })
        }
    }
    const handleChangeTimePicker = (event, key) => {
        setFormData({ ...formData, [key]: event.target.value })
    }

    const options = [
        '전체',
        '오늘의 꾸미기',
        '베스트 꾸미기',
        '꾸밍 챌린지',
        '꾸밍 태그',
        '피드',
        '팔로잉',
    ]

    const convertOptionToEnglish = (option) => {
        switch (option) {
            case '전체':
                return 'ALL'
            case '오늘의 꾸미기':
                return 'POST_TODAY_DECORATING'
            case '베스트 꾸미기':
                return 'POST_BEST_DECORATING'
            case '꾸밍 챌린지':
                return 'POST_CHALLENGE'
            case '꾸밍 태그':
                return 'POST_BY_TAG'
            case '피드':
                return 'POST_FEED'
            case '팔로잉':
                return 'POST_FOLLOWING'
        }
    }

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index)
        setFormData({ ...formData, type: convertOptionToEnglish(options[index]) })
        setOpen(false)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    const headCells = [
        {
            id: 'date',
            numeric: false,
            disablePadding: false,
            label: '날짜',
        },
        { id: 'joincount', numeric: true, disablePadding: false, label: '가입자' },
        { id: 'in_deRate', numeric: true, disablePadding: false, label: '증감율' },
        { id: 'deactivatedCount', numeric: true, disablePadding: false, label: '회원탈퇴수' },
        { id: 'deactivatedRate', numeric: true, disablePadding: false, label: '탈퇴율' },
        { id: 'totalJoinCount', numeric: true, disablePadding: false, label: '누적회원가입수(탈퇴포함)' },
        { id: 'totalActiveCount', numeric: true, disablePadding: false, label: '누적회원가입수(탈퇴제외)' },
        { id: 'totalDeActivatedCount', numeric: true, disablePadding: false, label: '누적탈퇴수' },
        { id: 'totalDeActivatedRate', numeric: true, disablePadding: false, label: '누적탈퇴율' },
        { id: 'readyUserCount', numeric: true, disablePadding: false, label: '가입준비단계' },
    ]

    // Handle API
    const compiled = _.template('${ date } ${ time }:00:00')
    const { type, limit, fromDate, toDate, fromTime, toTime } = formData

    const convertTime = (isFirstLoad, time) => {
        let result

        if (isFirstLoad) {
            result = _.split(
                moment().subtract(tz, 'hours').format('YYYY-MM-DD, H'),
                ',',
                2,
            )[1]?.trim()
        } else {
            const hour = time - tz;
            result = hour < 0 ? hour + 24 : hour;
        }

        return result <= 9 ? `0${result}` : result
    }

    const convertDate = (time, date) =>
        time >= tz ? date : moment(date).subtract(1, 'days').format('YYYY-MM-DD')

    let params = {
        type,
        limit,
        sortByTotalView: order.toUpperCase(),
        offset: pagination,
        fromDate: compiled({
            date: convertDate(fromTime, fromDate),
            time: convertTime(isFirstLoad, fromTime),
        }),
        toDate: compiled({
            date: convertDate(toTime, toDate),
            time: convertTime(isFirstLoad, toTime),
        }),
    }

    const getExcelFile = async () => {
        try {
            setLoadingButtonGetExcel(true)
            params['limit'] = 1000
            delete params['offset']
            const convertParamsToQueryUrl = queryString.stringify(params)

            const data = await staticsOfClickApi.getExcelStaticsUsers(convertParamsToQueryUrl)
            fileDownload(data, 'data.xlsx')
            setLoadingButtonGetExcel(false)
        } catch (error) {
            setLoadingButtonGetExcel(false)
            dispatch(staticsOfClickRequestErrorAction(error?.response?.data))
        }
    }

    const getListStaticsOfClick = async () => {
        try {
            setLoading(true)
            // params['postInfoid'] = fromDate
            const { data } = await staticsOfClickApi.getStaticsUsers(params)
            dispatch(getStaticsPostsAction(data))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            dispatch(staticsOfClickRequestErrorAction(error?.response?.data))
        }
    }

    React.useEffect(() => {
        getListStaticsOfClick()
        setIsFirstLoad(false)
    }, [pagination, order])

    return (
        <div className='statistic-click'>
            {loadingPage ? (
                <Spinner />
            ) : (
                <>
                    <GridContainer>
                        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}
                                  style={{'margin': 'auto', 'padding': '0px'}}
                                  className={classes.responsiveStyle}
                                  className={classes.mobileHidden}  >
                            일자
                        </GridItem>

                        <GridItem
                            container
                            alignItems='center'
                            className={classes.setFlexBasis}
                            xs={7}
                            sm={7}
                            md={7}
                            lg={7}
                            xl={7}
                        >
                            <GridContainer
                                justifyContent='flex-start'
                                className={classes.setJustifyContent}
                            >
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={5}
                                    xl={5}
                                    className={classes.responsiveStyle}
                                >
                                    <GridContainer>
                                        <GridItem
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className={classes.styleDatePicker}
                                        >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    variant='inline'
                                                    TextFieldComponent={TextField}
                                                    format='yyyy/MM/dd'
                                                    id='date-picker-inline1'
                                                    value={formData?.fromDate}
                                                    onChange={(date) =>
                                                        handleChangeFormDate(date, 'fromDate')
                                                    }
                                                    autoOk={true}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </GridItem>
                                        {/*<GridItem
                                            className={classes.paddingLeft}
                                            xs={5}
                                            sm={3}
                                            md={3}
                                            lg={5}
                                            xl={5}
                                        >
                                            <Box>
                                                <TimePicker
                                                    time={formData?.fromTime}
                                                    handleChangeTimePicker={handleChangeTimePicker}
                                                    setKey='fromTime'
                                                />
                                            </Box>
                                        </GridItem>*/}
                                    </GridContainer>
                                </GridItem>

                                <Box
                                    display='flex'
                                    flexDirection='center'
                                    alignItems='center'
                                    className={classes.styleSymbol}
                                >
                                    <p>~</p>
                                </Box>

                                <GridItem xs={12} sm={12} md={12} lg={5} xl={5}>
                                    <GridContainer>
                                        <GridItem
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            className={classes.styleDatePicker}
                                        >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    variant='inline'
                                                    format='yyyy/MM/dd'
                                                    TextFieldComponent={TextField}
                                                    id='date-picker-inline2'
                                                    autoOk={true}
                                                    value={formData?.toDate}
                                                    onChange={(date) =>
                                                        handleChangeFormDate(date, 'toDate')
                                                    }
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem  xs={4}
                                   sm={4}
                                   md={4}
                                   lg={4}
                                   xl={4}>

                        </GridItem>
                    </GridContainer>


                    <GridContainer justifyContent='center' style={{display: 'flex', justifyContent: 'center'}}>
                        <GridItem xs={2} sm={2} md={2}>
                            <Box className={classes.styleButtonSubmit}>
                                <Button
                                    disabled={loading}
                                    color='primary'
                                    onClick={getListStaticsOfClick}
                                >
                                    검색
                                </Button>
                            </Box>
                        </GridItem>
                    </GridContainer>
                    <GridContainer justifyContent='flex-end' xs={12} container>
                        <Tooltip title='Delete'>
                            <Button
                                disabled={loading}
                                className={classes.mobileHidden}
                                onClick={() => {
                                    getExcelFile();
                                }}
                                aria-label='delete'
                                color='info'
                            >
                                엑셀 다운로드
                            </Button>
                        </Tooltip>
                    </GridContainer>

                    <GridContainer>
                        <GridItem container justifyContent='flex-end' xs={12}>
                           {/* <Button
                                color='primary'
                                disabled={loadingButtonGetExcel}
                                onClick={getExcelFile}
                            >
                                엑셀 다운로드
                            </Button>*/}
                        </GridItem>

                        <GridItem xs={12}>
                            {loading ? (
                                <Spinner />
                            ) : (
                                <StatisticUsersTable
                                    headCells={headCells}
                                    rows={listStaticsOfPost}
                                    setOrder={setOrder}
                                    order={order}
                                />
                            )}

                            <Box mt={2} display='flex' justifyContent='flex-end'>
                                <Pagination
                                    pagination={pagination}
                                    totalPages={totalPages}
                                    setPagination={setPagination}
                                />
                            </Box>
                        </GridItem>
                    </GridContainer>
                </>
            )}
        </div>
    )
}

export default StatisticUsers

/*
const mapStateToProps = (state) => {
    return {

        loading: state.staticsPostReducer.loading,
        listStaticsOfPost: state.staticsPostReducer.listStaticsOfPost,
        // codeList: state.staticsPostReducer.codeList,
        // subCodeList: state.staticsPostReducer.subCodeList
        metaData: state.staticsPostReducer.metaData,

    }
}

export default connect(mapStateToProps, {
    getStaticsPostsAction,
    // updateCommentsAction
})(StatisticUsers)
*/
