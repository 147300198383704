import * as actionTypes from './types'

// export const createBoardManagingAction = (payload) => ({
//     type: actionTypes.CREATE_BOARD_INFO_MANAGING,
//     payload,
// })

//
// export const updateBoardManagingAction = (payload) => ({
//     type: actionTypes.UPDATE_BOARD_INFO_MANAGING,
//     payload,
// })
//
export const getListSplashAction = (payload) => ({
    type: actionTypes.GET_SPLASH_LIST,
    payload,
})
export const getSplashOneAction = (payload) => ({
    type: actionTypes.GET_SPLASH_ONE,
    payload,
})
export const updateSplashAction = (payload) => ({
    type: actionTypes.UPDATE_SPLASH,
    payload,
})

export const requestSplashAction = (payload) => ({
    type: actionTypes.BOARD_INFO_MANAGING_REQUEST_ERROR,
    payload,
})
// export const updateEventsAction = (payload) => ({
//     type: actionTypes.UPDATE_EVNETS,
//     payload,
// })