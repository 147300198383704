/* Auth */
export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const GET_DEVICE_TOKEN = 'GET_DEVICE_TOKEN'
export const LOGOUT = 'LOGOUT'
export const REQUEST_GET_DEVICE_TOKEN = 'REQUEST_GET_DEVICE_TOKEN'
export const REQUEST_GET_DEVICE_TOKEN_ERROR = 'REQUEST_GET_DEVICE_TOKEN_ERROR'

/* Tag Managing */
export const CREATE_TAG = 'CREATE_TAG'
export const DELETE_TAG = 'DELETE_TAG'
export const REQUEST_TAG = 'REQUEST_TAG'
export const ERROR_REQUEST_TAG = 'ERROR_REQUEST_TAG'
export const GET_LIST_TAGS = 'GET_LIST_TAGS'
export const ORDER_TAG = 'ORDER_TAG'
export const CHANGE_ORDER_TAG_ACTION = 'CHANGE_ORDER_TAG_ACTION'
export const UPDATE_TAG = 'UPDATE_TAG'

/* Notice */
export const REQUEST_NOTICE = 'REQUEST_NOTICE'
export const GET_LIST_NOTICES = 'GET_LIST_NOTICES'
export const ERROR_REQUEST_NOTICE = 'ERROR_REQUEST_NOTICE'
export const NOTICE_DELETE = 'NOTICE_DELETE'
export const EDIT_NOTICE = 'EDIT_NOTICE'
export const ADD_NOTICE = 'ADD_NOTICE'
export const SET_IS_CHECKED_NOTICE = 'SET_IS_CHECKED_NOTICE'

/* Best Decorating */
export const REQUEST_BESTDECORATING = 'REQUEST_BESTDECORATING'
export const GET_LIST_BESTDECORATING = 'GET_LIST_BESTDECORATING'
export const BEST_DECORATING_ERROR_REQUEST = 'BEST_DECORATING_ERROR_REQUEST'
export const UPDATE_ORDER_BESTDECORATING = 'UPDATE_ORDER_BESTDECORATING'
export const DELETE_BESTDECORATING = 'DELETE_BESTDECORATING'

/* Post Managing */
export const REQUEST_POST_MANAGING = 'REQUEST_POST_MANAGING'
export const GET_LIST_POST_MANAGING = 'GET_LIST_POST_MANAGING'
export const POST_MANAGING_DETAIL = 'POST_MANAGING_DETAIL'
export const POST_DETAIL_DELETE = 'POST_DETAIL_DELETE'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_FORMDATA_GLOBAL = 'SET_FORMDATA_GLOBAL'
export const TOGGLE_RECOMMEND_POST = 'TOGGLE_RECOMMEND_POST'
export const GET_LIST_COMMENT_IN_POSTS = 'GET_LIST_COMMENT_IN_POSTS'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const DELETE_REPLY_IN_COMMENT = 'DELETE_REPLY_IN_COMMENT'
export const POST_MANAGING_REQUEST_ERROR = 'POST_MANAGING_REQUEST_ERROR'
export const DELETE_POST = 'DELETE_POST'
export const MOVE_POST = 'MOVE_POST'
export const MOVE_PUSH_POST = 'MOVE_PUSH_POST'

/* User Managing */
export const REQUEST_USER_MANAGING = 'REQUEST_USER_MANAGING'
export const GET_LIST_USERS = 'GET_LIST_USERS'
export const SET_PAGINATION_USERMANAGING = 'SET_PAGINATION_USERMANAGING'
export const GET_USER_DETAIL = 'GET_USER_DETAIL'
export const TOGGLE_RECOMMEND_USER = 'TOGGLE_RECOMMEND_USER'
export const GET_LIST_REPORTED_IN_USER_DETAIL =
  'GET_LIST_REPORTED_IN_USER_DETAIL'
export const REQUEST_USER_MANAGING_ERROR = 'REQUEST_USER_MANAGING_ERROR'
export const USER_CHANGE_STATE = 'USER_CHANGE_STATE'

/* Writer Managing */
export const REQUEST_WRITER_MANAGING = 'REQUEST_WRITER_MANAGING'
export const GET_LIST_WRITER = 'GET_LIST_WRITER'
export const CHANGE_WRITER_EXPOSUER = 'CHANGE_WRITER_EXPOSUER'
export const CHANGE_WRITER_USERID = 'CHANGE_WRITER_USERID'
export const CHANGE_WRITER_IS_SYNC = 'CHANGE_WRITER_IS_SYNC'
export const WRITER_CHANGE_STATE = 'WRITER_CHANGE_STATE'
export const SET_PAGINATION_WRITERMANAGING = 'SET_PAGINATION_WRITERMANAGING'
export const REQUEST_WRITER_MANAGING_ERROR = 'REQUEST_WRITER_MANAGING_ERROR'

/* Writer Schedules Managing */
export const REQUEST_WRITER_SCHEDULES_MANAGING = 'REQUEST_WRITER_SCHEDULES_MANAGING'
export const GET_LIST_WRITER_SCHEDULES = 'GET_LIST_WRITER_SCHEDULES'
export const WRITER_SCHEDULES_CHANGE_STATE = 'WRITER_SCHEDULES_CHANGE_STATE'
export const SET_PAGINATION_WRITERSCHEDULESMANAGING = 'SET_PAGINATION_WRITERSCHEDULESMANAGING'
export const REQUEST_WRITER_SCHEDULES_MANAGING_ERROR = 'REQUEST_WRITER_SCHEDULES_MANAGING_ERROR'

/* Writer Main Managing */
export const REQUEST_WRITER_MAIN_MANAGING = 'REQUEST_WRITER_MAIN_MANAGING'
export const GET_LIST_WRITER_MAIN = 'GET_LIST_WRITER_MAIN'
export const CHANGE_WRITER_MAIN_ORDER = 'CHANGE_WRITER_MAIN_ORDER'
export const WRITER_MAIN_CHANGE_STATE = 'WRITER_MAIN_CHANGE_STATE'
export const SET_PAGINATION_WRITERMAINMANAGING = 'SET_PAGINATION_WRITERMAINMANAGING'
export const REQUEST_WRITER_MAIN_MANAGING_ERROR = 'REQUEST_WRITER_MAIN_MANAGING_ERROR'

/* Writer Goods Managing */
export const REQUEST_WRITER_GOODS_MANAGING = 'REQUEST_WRITER_GOODS_MANAGING'
export const GET_LIST_WRITER_GOODS = 'GET_LIST_WRITER_GOODS'
export const TOGGLE_WRITER_GOODS_IS_SYNC = 'TOGGLE_WRITER_GOODS_IS_SYNC'
export const TOGGLE_WRITER_GOODS_IS_SHOW = 'TOGGLE_WRITER_GOODS_IS_SHOW'
export const CREATE_GOODS_MANAGING = 'CREATE_GOODS_MANAGING'
export const GET_GOODS_MANAGING_LIST = 'GET_GOODS_MANAGING_LIST'
export const CHANGE_TAG_ACTION = 'CHANGE_TAG_ACTION'
export const ORDER_GOODS_MANAGING = 'ORDER_GOODS_MANAGING'
export const CHANGE_GOODS_MANAGING = 'CHANGE_GOODS_MANAGING'
export const WRITER_GOODS_CHANGE_STATE = 'WRITER_GOODS_CHANGE_STATE'
export const SET_PAGINATION_WRITERGOODSMANAGING = 'SET_PAGINATION_WRITERGOODSMANAGING'
export const REQUEST_WRITER_GOODS_MANAGING_ERROR = 'REQUEST_WRITER_GOODS_MANAGING_ERROR'

/* Best User */
export const GET_LIST_BEST_USER = 'GET_LIST_BEST_USER'
export const UPDATE_ORDER_BEST_USER = 'UPDATE_ORDER_BEST_USER'
export const BEST_USER_REQUEST_ERROR = 'BEST_USER_REQUEST_ERROR'
export const DELETE_BEST_USER = 'DELETE_BEST_USER'

/* Report Block Managing */
export const GET_LIST_REPORT_BLOCK_MANAGING = 'GET_LIST_REPORT_BLOCK_MANAGING'
export const GET_REPORT_BLOCK_DETAIL = 'GET_REPORT_BLOCK_DETAIL'
export const DELETE_REPORT_BLOCK_ITEM = 'DELETE_REPORT_BLOCK_ITEM'
export const GET_HISTORY_REPORTED_IN_REPORT_BLOCK_DETAIL =
  'GET_HISTORY_REPORTED_IN_REPORT_BLOCK_DETAIL'
export const GET_HISTORY_REPORTED_DETAIL = 'GET_HISTORY_REPORTED_DETAIL'
export const REPORT_BLOCK_MANAGING_REQUEST_ERROR =
  'REPORT_BLOCK_MANAGING_REQUEST_ERROR'
export const GET_TOTAL_NEW_REPORTS = 'GET_TOTAL_NEW_REPORTS'

/* Q&A */
export const GET_LIST_INQUIRY = 'GET_LIST_INQUIRY'
export const DELETE_INQUIRY = 'DELETE_INQUIRY'
export const QA_REQUEST_ERROR = 'QA_REQUEST_ERROR'

/* Manager Managing */
export const GET_LIST_MANAGER_MANAGING = 'GET_LIST_MANAGER_MANAGING'
export const DELETE_USER_MANAGER_MANAGING = 'DELETE_USER_MANAGER_MANAGING'
export const CHANGE_POSITION_IN_MANAGER_MANAGING =
  'CHANGE_POSITION_IN_MANAGER_MANAGING'
export const CREATE_USER_MANAGER_MANAGING = 'CREATE_USER_MANAGER_MANAGING'
export const MANAGER_MANAGING_REQUEST_ERROR = 'MANAGER_MANAGING_REQUEST_ERROR'

/* Statics Of Click */
export const GET_LIST_STATICS_OF_CLICK = 'GET_LIST_STATICS_OF_CLICK'
export const GET_LIST_STATICS_OF_QUIT_REASON_CLICK = 'GET_LIST_STATICS_OF_QUIT_REASON_CLICK'
export const STATICS_OF_CLICK_REQUEST_ERROR = 'STATICS_OF_CLICK_REQUEST_ERROR'

/* Statics Of Search Request */
export const GET_LIST_STATICS_OF_SEARCH = 'GET_LIST_STATICS_OF_SEARCH'
export const GET_MAIN_LIST_STATICS_OF_SEARCH = 'GET_MAIN_LIST_STATICS_OF_SEARCH'
export const STATICS_OF_SEARCH_REQUEST_ERROR = 'STATICS_OF_SEARCH_REQUEST_ERROR'

/* stiatics of post*/
export const GET_STATICS_POST = 'GET_STATICS_POST'
export const GET_STATICS_USER = 'GET_STATICS_USER'

/* Banner Managing */
export const GET_LIST_BANNER_MANAGING = 'GET_LIST_BANNER_MANAGING'
export const EDIT_BANNER_MANAGING = 'EDIT_BANNER_MANAGING'
export const BANNER_MANAGING_REQUEST_ERROR = 'BANNER_MANAGING_REQUEST_ERROR'
export const DELETE_BANNER_MANAGING = 'DELETE_BANNER_MANAGING'
export const CREATE_BANNER_MANAGING = 'CREATE_BANNER_MANAGING'
export const UPDATE_ORDER_BANNER_MANAGING = 'UPDATE_ORDER_BANNER_MANAGING'

/* Ggooming Challenge */
export const GET_LIST_GGOOMING_CHALLENGE = 'GET_LIST_GGOOMING_CHALLENGE'
export const GGOOMING_CHALLENGE_REQUEST_ERROR =
  'GGOOMING_CHALLENGE_REQUEST_ERROR'
export const CREATE_GGOOMING_CHALLENGE = 'CREATE_GGOOMING_CHALLENGE'
export const EDIT_GGOOMING_CHALLENGE = 'EDIT_GGOOMING_CHALLENGE'
export const DELETE_GGOOMING_CHALLENGE = 'DELETE_GGOOMING_CHALLENGE'

/* BoardINfo MAnaging */
export const  CREATE_BOARD_INFO_MANAGING = "CREATE_BOARD_INFO_MANAGING"
export const  GET_LIST_BOARD_INFO_MANAGING = "GET_LIST_BOARD_INFO_MANAGING"
export const UPDATE_BOARD_INFO_MANAGING = 'UPDATE_BOARD_INFO_MANAGING'
export const BOARD_INFO_MANAGING_REQUEST_ERROR  = 'BOARD_INFO_MANAGING_REQUEST_ERROR'
export const GET_PHRASE_LIST  = 'GET_PHRASE_LIST'

//version
export const REQUEST_VERSION_MANAGING = 'REQUEST_VERSION_MANAGING'
export const GET_LIST_VERSION = 'GET_LIST_VERSION'
export const REQUEST_VERSION_ERROR = 'REQUEST_VERSION_ERROR'

/* events */
export  const GET_LIST_EVNETS = "GET_LISTT_EVNETS"
export  const GET_EVNETS = "GET_EVNETS"
export  const UPDATE_EVNETS = "UPDATE_EVNETS"

/* pushInfo */
export const GET_PUSH_INFO_LIST = 'GET_PUSH_INFO_LIST'
/* splash */
export const GET_SPLASH_LIST = 'GET_SPLASH_LIST'
export const GET_SPLASH_ONE = 'GET_SPLASH_ONE'
export const CREATE_SPLASH = 'CREATE_SPLASH'
export const UPDATE_SPLASH = 'UPDATE_SPLASH'
export const DELETE_SPLASH = 'DELETE_SPLASH'

//comment
export const GET_LIST_COMMENTS = 'GET_LIST_COMMENTS'
export const GET_NEXT_CODE = 'GET_NEXT_CODE'
export const GET_CODE_LIST = 'GET_CODE_LIST'
export const GET_CODE_CHILD_LIST = 'GET_CODE_CHILD_LIST'
// export const BOARD_INFO_MANAGING_REQUEST_ERROR = 'BOARD_INFO_MANAGING_REQUEST_ERROR'


//content, 인기피드 관리, 라이징스타관리
export const GET_LIST_CONTENT = 'GET_LIST_CONTENT'
export const ORDER_CONTENT ='ORDER_CONTENT' // content 순서변경
export const DELETE_CONTENT_ACTION ='DELETE_CONTENT_ACTION' // content 순서변경
export const CHANGE_CONTENT_ACTION ='CHANGE_CONTENT_ACTION' // content 순서변경
export const GET_LIST_CONTENT_MULTI ='GET_LIST_CONTENT_MULTI' // content, 피드 이벤트 등등 걸 수 있게

export const GET_AI_COMMENT_LIST_ACTION = "GET_AI_COMMENT_LIST_ACTION"
export const SET_AI_PHRASE = "SET_AI_PHRASE"
export const SET_AI_COMMENT_LIST = "SET_AI_COMMENT_LIST"