import {connect, useDispatch} from "react-redux";
import Paper from "@material-ui/core/Paper";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/Notice/notice2style";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import TextField from "../../components/Gm-TextField/TextFieldForDatePicker";
import Box from "@material-ui/core/Box";
import Button from "../../components/CustomButtons/Button";
import CustomButton from "../../components/CustomButtons/Button";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import moment from "moment";
import Spinner from "../../components/Spinner/Spinner";
//자동제안은 react suggest
import stylesModal from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import bannerManagingApi from "../../api/mainManaging/bannerManagingApi";
import WriterManagingApi from "../../api/writerManagingApi";
// import GoodsAndWriterModal from "./components/GoodsAndWritersModal";
import MenuItem from "@material-ui/core/MenuItem";
import SweetAlert from "react-bootstrap-sweetalert";
import Typography from "@material-ui/core/Typography";
import writerMainManagingApi from "../../api/writerMainManagingApi";

const useStyles = makeStyles(styles)
const useStylesModal = makeStyles(stylesModal)

// 이벤트 상세임 아무튼 그럼
const WriterMainEdit = (props) => {

    const {
        match,
        postForm,
    } = props

    const classes = useStyles()
    const classesModal = useStylesModal()


    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz


    const dispatch = useDispatch()

    const [styleTagSelected, setStyleSelected] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [alert2, setAlert] = React.useState(null)
    const [writers, setWriters] = React.useState([])
    const [isUploadImage, setIsUploadImage] = React.useState(false)
    const [isUploadImage2, setIsUploadImage2] = React.useState(false)
    const [formData, setFormData] = React.useState({
        id: isNaN(match?.params?.id) ? 0 : match?.params?.id,
        status: 'ACTIVE',
        avatar: null,
        title: ''
    })
    const [imageFile, setImageFile] = React.useState(null)
    const [imageFile2, setImageFile2] = React.useState(null)
    const hideAlert = () => {
        setAlert(null)
    }
    const [loadingBtn, setLoadingBtn] = React.useState(false)


    const eventsModel = {
        questionTitle: '',
        files: {}
    }


    const [voteQuestionList, setvoteQuestionList] = React.useState([eventsModel])



    const handleForm = (value, key) => {
        //vue의 데이터 매핑 형태였던 것 같은데.... key도 매핑되네 히야...

        switch (key) {
            case 'startAt' :
                setFormData({
                    ...formData,
                    [key]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'endAt' :
                setFormData({
                    ...formData,
                    [key]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'isDuplication' :
                setFormData({
                    ...formData,
                    [key]: !formData.isDuplication,
                })
                break;
            case 'postType' :
                setFormData({
                    ...formData,
                    [key]: value,
                    description: ''
                })

                break;
            default:
                setFormData({
                    ...formData,
                    [key]: value,
                })

        }


    }
    const getWriterLists = async () => {
        try {

            const writers = await WriterManagingApi.getListWriters({limit: 10000});
            setWriters(writers.data.writers)

        }catch (e){

        }

    }
    const handleFormQuestionFile = async (file, index) => {
        //파일 저장해서 가져와진 fileidx를 input


        //이미지 cdn 업로드
        const fd = new FormData()

        fd.append('file', file, file.name)
        fd.append('type', 'POST')
        const {
            data: {id: idImage, url, filename},
        } = await bannerManagingApi.uploadImage(fd); //banner라고 세팅했지만원본은 file api 로 통신

        let tempObject = voteQuestionList[index];
        tempObject.files = {id: idImage, url, filename};
        setvoteQuestionList([...voteQuestionList.slice(0, index),
            tempObject,
            ...voteQuestionList.slice(index + 1)]
        )

    }

    const handleFormFeedFile = async (e, index) => {
        //feed 파일
        //파일 저장해서 가져와진 fileidx를 input


        const fd = new FormData()
        for (let i = 0; i < e.target.files.length; i++) {
            fd.append('file', e.target.files[i])
        }
        fd.append('type', 'WRITER')

        const {data} = await bannerManagingApi.uploadImageMulti(fd)

    }


    const handleChangeTimePickerStart = (event) => {

        setFormData({...formData, fromTime: event.target.value})
    }
    const handleChangeTimePickerEnd = (event) => {

        setFormData({...formData, toTime: event.target.value})
    }

    const validateImageAlert = (message) => {
        setAlert(
            <SweetAlert
                warning
                style={{display: 'block', marginTop: '-100px'}}
                title=''
                onConfirm={() => hideAlert()}
                showConfirm={false}
                onCancel={() => hideAlert()}
            >
                <Typography component='p' gutterBottom>
                    {message}
                </Typography>
                <Button color='success' onClick={hideAlert}>
                    OK
                </Button>
            </SweetAlert>,
        )
    }

    const validateUploadImage = (file, whichCheck, whichBanner) => {
        if (whichCheck === 'size' && Math.round(file.size / 1024) > 2048) {
            validateImageAlert('파일이 너무 큽니다. 2MB 넘지 않아야합니다')
            return true
        }

    }
    const handleChangeFile = (e, type) => {
        //파일 Onchage 시 이미지 체인지

        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            if (validateUploadImage(file, 'size')) return

            const updateState = (file) => {
                setImageFile(file)
                setIsUploadImage(true)
            }
            const updateState2 = (file) => {
                setImageFile2(file)
                setIsUploadImage2(true)
            }
            // use for check width and height image
            const img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.addEventListener('load', () => {
                type === 'mainImage' && updateState(file)
                //validateUploadImage(file, 'size') || (!validateUploadImage(img, 'widthAndHeight') && updateState(file))
                type === 'backgroundImage' && updateState2(file)
                // !validateUploadImage(file, 'size', whichBanner) && updateState2(file)
            })
        }

    }


    //api 통신해서 db저장 이벤트 생성
    const createPostMananging = async () => {


        try {

            //유효성체크
            if (imageFile == null && formData.mainImage == null){
                alert("메인 사진을 넣어주세요")
                return
            }
            if (imageFile2 == null && formData.backgroundImage == null){
                alert("배경 사진을 넣어주세요")
                return
            }
            if(formData.writerId == null){
                alert("작가를 선택해주세요")
                return
            }

            const body = formData
            // call api upload file
            setLoading(true)
            let setNewmainImage
            let setNewsubImage
            let setNewPopupImage
            if (isUploadImage) {
                const formData = new FormData()
                formData.append('file', imageFile, imageFile.name)
                formData.append('type', 'WRITER')

                const {
                    data: {id, url, filename},
                } = await bannerManagingApi.uploadImage(formData)

                setNewmainImage = {
                    id,
                    url,
                    filename,
                }
                setIsUploadImage(false)
            }
            if (isUploadImage2) {
                const formData = new FormData()
                formData.append('file', imageFile2, imageFile2.name)
                formData.append('type', 'WRITER')

                const {
                    data: {id, url, filename},
                } = await bannerManagingApi.uploadImage(formData)

                setNewsubImage = {
                    id,
                    url,
                    filename,
                }
                setIsUploadImage2(false)
            }

            body.mainFile = ( isUploadImage ? setNewmainImage.id : formData?.mainImage?.id )
            body.backFile = ( isUploadImage2 ? setNewsubImage.id : formData?.backgroundImage?.id  )

            //업데이트 일때, 하단은 Insert
            if (formData.id != 0) {

                // 일반 핃,
                //추천태그 빼기

                const {data} = await writerMainManagingApi.update(body);

                if (data) {
                    alert("수정되었습니다.")
                    location.href = "/admin/writer-main-managing"
                }
                return
            }


            const {data} = await writerMainManagingApi.create(body);

            if (data) {
                alert("저장되었습니다.")
                location.href = "/admin/writer-main-managing"
            }


        } catch (e) {
            setLoadingBtn(false)
        }

    }
    const getWriterMainData = async () => {
        try {
            setLoading(true)

            if (formData.id > 0) {
                //데이터가 있는 것

                //setLoading 필요

                let {data} = await writerMainManagingApi.getWriterMain({id: parseInt(formData.id)});
                //데이터 매핑 dispatch쓰면 코드가 깔끔해지나 쓰지 않음
                setLoading(false)

                // 기본
                const formNewDATA = {
                    ...formData,
                    backgroundImage: data?.writerMain.backgroundImage,
                    mainImage: data?.writerMain.mainImage,
                    writerId: data?.writerMain.writerId
                }

                //최종데이터 세팅
                setFormData(formNewDATA)
                setLoading(false)

            }

            setLoading(false)


        } catch (e) {

        }
    }

    let count = 0;
    React.useEffect(() => {
        // if (writers == []){
            getWriterLists()
        // }
    },[])
    React.useEffect(() => {
        //getData

        getWriterMainData()
    }, [formData.id, formData.writerId])

    // const handleTitle = (e)


    //스타일 태그 input,output
    const selectStyleTag = async (type) => {
        //있으면 빼고 없으면 넣고
        let isContain = false;
        let iindex = 0;

        styleTagSelected.map((item, index) => {
            if (item == type) {
                isContain = true;
                iindex = index
            }
        });

        if (isContain) {
            //해당 인덱스 구해서 빼기
            setStyleSelected(styleTagSelected.slice(0, iindex));

        } else {

            //이렇게 하면 state가 변한걸 탐지 못함
            /*const aa = styleTagSelected;
            aa.push(type)
            setStyleSelected(aa);*/

            const aa = [];
            styleTagSelected.map((item) => {
                aa.push(item)
            });
            aa.push(type)
            setStyleSelected(aa);
        }
    }
    const openGoodsView = async () => {

        setAlert(
            <GoodsAndWriterModal
                item={goods}
                hideAlert={(e) => {
                    setAlert(null)
                }}
                setAlert={setAlert}
                dispatch={dispatch}
                setGoods={setGoods}
                goods={goods}
            />
        )
    }

    return (

        <div>

            {loading ? (<Spinner/>) : (<div>
                {alert2}
                <Paper variant='outlined' className={classes.paper}>
                    <GridContainer className={classes.grid} alignItems='center'
                                   style={{marginBottom: '10px', marginTop: '10px'}}>
                        <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                            연결할 작가 이름
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            {/*{formData.postType}*/}
                            <TextField
                                id='user-managing-select'
                                select
                                size='small'
                                className={classes.widthTextFieldModal}
                                    value={formData?.writerId}
                                onChange={(e) => {
                                    handleForm(e.target.value, 'writerId')
                                }}
                                label='연결할 작가 이름을 선택해주세요'
                                style={{width: '100%'}}
                            >
                                {/*<MenuItem value={18}>{'dlfsdf'}</MenuItem>*/}
                                {
                                    writers.map((item)=>{
                                        return(<MenuItem name={item.nickname} value={item.id}>{item.nickname}{item.id}</MenuItem>)
                                    })
                                }

                            </TextField>
                        </GridItem>
                    </GridContainer>
                    {/*메인사진*/}
                    <GridContainer alignItems='center' className={classes.grid}>
                        <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                            메인 사진
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            <input
                                accept='image/*'
                                className={classes.inputBtnUpload}
                                id={'contained-button-file'}
                                multiple
                                type='file'
                                onClick={(event) => {
                                    event.target.value = ''
                                }}
                                onChange={(e) => handleChangeFile(e, 'mainImage')}
                            />
                            <label htmlFor={'contained-button-file'}>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    component='span'
                                    fullWidth={true}
                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                >

                                    {imageFile
                                        ? imageFile.name
                                        : '메인 사진 올리기'}
                                </Button>
                            </label>
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            {
                                formData.mainImage != null ? <img style={{'maxWidth':'200px'}} src={formData?.mainImage?.url}/> : null
                            }
                        </GridItem>


                    </GridContainer>
                    {/*배경사진*/}
                    <GridContainer alignItems='center' className={classes.grid}>
                        <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                            배경 사진
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            <input
                                accept='image/*'
                                className={classes.inputBtnUpload}
                                id={'contained-button-file-2'}
                                multiple
                                type='file'
                                onClick={(event) => {
                                    event.target.value = ''
                                }}
                                onChange={(e) => handleChangeFile(e, 'backgroundImage')}
                            />
                            <label htmlFor={'contained-button-file-2'}>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    component='span'
                                    fullWidth={true}
                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                >

                                    {imageFile2
                                        ? imageFile2.name
                                        : ' 배경 사진 올리기'}
                                </Button>
                            </label>
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            {
                                formData.backgroundImage != null ? <img style={{'maxWidth':'200px'}} src={formData?.backgroundImage?.url}/> : null
                            }
                        </GridItem>


                    </GridContainer>
                    {/*순서*/}

                </Paper>


                <Box className='add-list' display='flex' justifyContent='center'>
                    {loadingBtn ? (
                            <Spinner/>
                        ) :
                        (
                            <CustomButton color="primary" mr={2} variant="contained"
                                          onClick={createPostMananging}
                            >
                                {formData?.id == 0 ? '등록' : '수정'}
                            </CustomButton>)
                    }
                    <CustomButton color="default" mr={2} variant="contained"
                                  onClick={(event) => history.back()}
                    >취소</CustomButton>

                </Box>
            </div>)}


        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        // userDetail: state.post.postForm,
        loading: state.eventsReducer.loading,
        postForm: state.eventsReducer.postForm,
        post: state.eventsReducer.post,
        metaData: state.eventsReducer.metaData,
    }
}

export default connect(mapStateToProps, {
    // requestNoticeAction,
    // getListNoticesAction,
    // noticesWithErrAction,
})(WriterMainEdit)
