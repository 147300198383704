import { primaryColor } from 'assets/jss/material-dashboard-pro-react.js'

const postManaging = (theme) => ({


  iconBtn: {
    marginLeft: '7px',
  },

  groupBtnDropdown: {
    boxShadow: 'unset',
  },

  textField: {
    maxWidth: '150px',
    '& input': {
      textAlign: 'end',
    },
  },
  mobileHidden:{
    '@media (max-width: 768px)': {
      display: 'none'
    },
  },
  textFieldOne: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      '@media (max-width: 768px)': {
        fontSize: '1px'
      },
    },
  },
  numberField: {
    width: '80px'
  },

  setZindex: {
    zIndex: '9999',
  },

  // * Datetime Picker
  inputSearch: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },

    '& > div': {
      [theme.breakpoints.only('lg')]: {
        width: '170px',
      },
    },

    '& input': {
      '&::placeholder': {
        [theme.breakpoints.only('lg')]: {
          fontSize: '13px',
        },
      },
    },
  },

  setFlexBasis: {
    [theme.breakpoints.only('lg')]: {
      flexBasis: '81.333333%',
    },
  },

  responsiveStyle: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      // display:'none'
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      '@media (max-width: 769px)': {
        display: 'none'
      },
    },
  },

  setJustifyContent: {
    [theme.breakpoints.only('xl')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.only('md')]: {
      justifyContent: 'flex-start',
    },
  },

  styleDatePicker: {
    '& > div': {
      width: '100%',
    },
  },

  styleSymbol: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },

    '& p': {
      fontWeight: 'bold',
      fontSize: '25px',
      color: 'gray',
    },
  },

  styleButtonSubmit: {
    [theme.breakpoints.down('md')]: {
      padding: '0 15px',
    },
  },

  paddingLeft: {
    paddingLeft: '0px !important',
  },

  // * Post Detail
  swiper: {
    // position: 'relative',

    '& .card-media-post-detail': {
      position: 'relative',
      width: '100%',
      height: '300px',
      objectFit: 'cover',
    },

    '& .total-view': {
      width: '100%',
      position: 'absolute',
      zIndex: 1,
      bottom: 0,

      display: 'flex',
      justifyContent: 'space-around',

      backgroundColor: 'rgba(255, 255, 255, 0.8);',
      padding: '.4rem',
      '& p': {
        color: '#222',
      },
    },

    '& .info-detail-post': {
      zIndex: 2,
    },
  },
  totalViewVote :{
      display: 'flex',
      width: '100%',
      bottom: 0,
      padding: '0.4rem',
      'z-index': 1,
      /* position: absolute; */
      'justify-content': 'space-around',
      'background-color': 'rgba(255, 255, 255, 0.8)'
  },
  gridContainerOne: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  wrapIcon: {
    verticalAlign: 'middle',
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
  },
  paperSwitch: {
    padding: theme.spacing(1),
  },
  postDetailTags: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    '& div': {
      '& span': {
        fontWeight: 'bold',
      },
    },
  },
  feedColor: {
    // background: '#FFA7A7'
    background: '#fc876f',
    // color:'#fff',
    // 'font-weight': 500
  },
  voteColor: {
    background: '#B2CCFF'
  },
  /*chipColor: {
    background: '#B2CCFF',
    color: "rgba(0, 0, 0, 0.87)",
    border: 'none',
    cursor: "default",
    height: "32px",
    display: "inline-flex",
    outline: 0,
    padding: 0,
    fontSize: "0.8125rem",
    boxSizing: "border-box",
    transition: "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    alignItems: "center",
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
    whiteSpace: "nowrap",
    borderRadius: "16px",
    verticalAlign: "middle",
    justifyContent: "center",
    textDecoration: "none",
    backgroundColor: "#e0e0e0",
  },*/
  etcColor: {
    background: '#B7F0B1'
  },
  starColor: {
    background: '#FFE08C'
  },
  issueColor: {
    background: '#007aff'
  },
  goodsColor: {
    background: '#fa8ee5'
  },
  postDetailContentDiv:{
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  subGoodsDiv:{
    border: '1px solid #eee',
    padding: '10px',
    marginBottom: '10px'
  },
  postDetailToggleBtn: {
    width: '30%',
    [theme.breakpoints.down('lg')]: {
      width: '40%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  votePaper: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  swiperCustomStyle: {
    width: '100%',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '50%',
    },
    height: 'auto',
    '& .swiper-button-prev, .swiper-button-next': {
      backgroundColor: '#fff',
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      '&:after': {
        color: '#222',
        fontSize: '20px',
        fontWeight: 'bold',
      },
    },
  },

  // Spinner for table
  boxTableBlock: {
    position: 'relative',
  },
  buttonProgress: {
    color: primaryColor[0],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

})

export default postManaging
