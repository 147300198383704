import React, {useEffect} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/PushManaging/PushManangingStyle";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import TextField from "../../../components/Gm-TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "../../../components/CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Table from './PostTables.js';
import MenuItem from "@material-ui/core/MenuItem";
import {requestUserManagingAction} from "../../../redux/actions/userManagingAction";


const useStyles = makeStyles(styles)

const PostModal = (props) => {


    const {
        hideAlert,
        setAlert,
        dispatch,
        postManagingApi,
        getListUserAction,
        loading,
        setAddMemberIds,
        setPosts,
        posts,
        type
        // searchText,
        // setSearchText,
    } = props
    const [selected, setSelected] = React.useState([])
    const [page, setPage] = React.useState(0)

    useEffect(() => {
        document.querySelector('.sweet-alert').parentElement.style.zIndex = 1034;
        getPostList();
    }, [page])
    const searchPost = async () => {

        setPage(0)
        getPostList();
    }
    //회원데이터
    const getPostList = async () => {
        let orderString = '';

        if (orderBy == 'first') {
            //처음 정렬시 현재 front에 나오는 표출 순서대로
            orderString = 'numOrder,DESC&createdAt,DESC';
        } else {
            orderString = orderBy + ',' + order
        }

        try {
            requestUserManagingAction()
            let postInfoId = null
            if (type == 'FEED'){
                postInfoId = 1
            }else if(type == 'STAR'){
                postInfoId = 4
            }else if(type == 'POST_GOODS'){
                postInfoId = 6
            }

            const params = {
                limit: 10,
                offset: page == 0 ?1 :  page,
                order: 'DESC',
                searchType: searchType,
                searchText: searchText,
                postInfoId
                // userStatus: select,
                // clientId,
            }

            // !clientId && delete params.clientId
            const {data} = await postManagingApi.getListPostManaging(params)
            // setIsResetPagination(false)

            // used for condition to render totalUserBySearch
            // const hasClientIdData = clientId ? true : false
            // data['hasClientIdData'] = hasClientIdData

            setPostsList(data.posts)

            setMetaData(data.metaData)
            // dispatch(getListUserAction(data))
        } catch (error) {
            console.log(error.response)
            if (error && error.response && error.response.data) {
                // requestUserManagingErrorAction(error.response.data)
            }
        }
    }


    const headCells = [
        {
            id: 'title',
            numeric: true,
            disablePadding: false,
            label: '제목',
            allowSortable: false,
        },
        {
            id: 'thumbnail',
            numeric: true,
            disablePadding: false,
            minWidth: 20,
            label: '썸네일',
            allowSortable: false,
        },
        {
            id: 'type',
            numeric: false,
            disablePadding: false,
            label: '게시판종류',
            // minWidth: 100,
            allowSortable: false,
        },
        {
            id: 'nickname',
            numeric: false,
            disablePadding: false,
            label: '작성자',
            allowSortable: false,
        },
        {
            id: 'createdAt',
            numeric: true,
            disablePadding: false,
            label: '작성일',
            allowSortable: false,
        },
    ]

    const classes = useStyles()

    const [showNotification, setShowNotification] = React.useState({
        open: false,
        message: '',
    })
    // const [users, setUsers] = React.useState([])
    // const [select, setSelect] = React.useState('NORMAL')
    const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('')
    const [clientId, setClientId] = React.useState('')
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [isResetPagination, setIsResetPagination] = React.useState(false)


    //알림대상 관련 변수
    const [limit, setLimit] = React.useState(10)
    const [searchText, setSearchText] = React.useState('')
    const [searchType, setSearchKey] = React.useState('nickname')
    const [postsList, setPostsList] = React.useState([])
    const [totalRecords, setTotalRecords] = React.useState(10)
    const [metaData, setMetaData] = React.useState({
        totalRecords: 0,
        totalPages: 0
    })

    const handleCloseOpen = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setShowNotification({...showNotification, open: false})
    }

    const handleSearchKey = (valuwe) => {
        setSearchKey(valuwe)
        setOrder('asc')
    }
    const test = () => {

        if ( (selected.length + posts.length) > 1) {
            alert("게시물 연결은 하나만 가능합니다.")
            return
        }


        //기존
        let postsNew = posts
        let newSelected = []


        //새로 선택한거
        selected.map((item) => {

            let isCheck = false;

            //기존에 선택되어 있던 것
            postsNew.map((item2) => {
                //중복데이터 체크
                if (item2 == item.id) {
                    isCheck = true
                }
            })

            if (!isCheck) {
                postsNew.push(item)
            }
        })

        setPosts(postsNew)


        hideAlert()


    }

    return (
        <>
            <SweetAlert
                style={{display: 'block', marginTop: '-100px', width: '80em', zIndex: '1034',}}
                title={'게시물선택'}
                onConfirm={() => {
                }}
                showConfirm={false} 를
                onCancel={() => hideAlert()}
            >

                <GridContainer alignItems='center'>
                    <GridItem xs={2} sm={2} md={2}>
                        {/*{formData.type}*/}
                        <TextField
                            id='push-managing-select'
                            select
                            size='small'
                            className={classes.widthTextFieldModal}
                            value={searchType}
                            onChange={(e) => {
                                // handelS
                                // setSearchKey(e.target.value)
                                handleSearchKey(e.target.value)
                            }}
                            label='검색 타입'
                            style={{width: '100%'}}
                        >
                            <MenuItem value={'nickname'}>작성자</MenuItem>
                            <MenuItem value={'postIds'}>게시물 아이디</MenuItem>
                            <MenuItem value={'title'}>제목</MenuItem>
                        </TextField>
                    </GridItem>
                    <GridItem
                        className={classes.gridContainerOne}
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                    >

                        <TextField
                            id='post-managing-textfield'
                            size='small'
                            className={classes.textFieldOne}
                            placeholder={'검색해주세요'}
                            name='clientId'
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                        />


                    </GridItem>
                    <GridItem xs={2} sm={2} md={2} lg={2} xl={2}>
                        <Button
                            color='primary'
                            // disabled={loading색ㅋㅋ}
                            onClick={searchPost}
                        >
                            검색
                        </Button>
                    </GridItem>

                    <Box mb={2} style={{'max-width': '100%'}}>
                        {loading ? (
                            <CircularProgress size={30} className={classes.buttonProgress}/>
                        ) : (
                            <Table
                                headCells={headCells}
                                rows={postsList}
                                setPagination={metaData}
                                totalPages={metaData.totalPages}
                                setRowsPerPage={setRowsPerPage}
                                rowsPerPage={rowsPerPage}
                                setPage={setPage}
                                page={page}
                                setOrder={setOrder}
                                order={order}
                                setOrderBy={setOrderBy}
                                orderBy={orderBy}
                                setSelected={setSelected}
                                selected={selected}
                            />
                        )}
                    </Box>


                </GridContainer>


                <Box mt={1} display='flex' justifyContent='center'>
                    <Button
                        onClick={hideAlert}
                        className={classes.marginRightBtnInModal}
                        color='danger'
                    >
                        취소
                    </Button>
                    <Button
                        color='success'
                        disabled={loading}
                        onClick={test}
                    >
                        추가
                    </Button>
                </Box>

            </SweetAlert>

        </>


    )

}
PostModal.propTypes = {}
export default PostModal