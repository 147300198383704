import React from 'react'
import fileDownload from 'js-file-download'

import {makeStyles} from '@material-ui/core/styles'
import TextField from 'components/Gm-TextField/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import GridContainer from 'components/Grid/GridContainer.js'
import InputAdornment from '@material-ui/core/InputAdornment'
import Box from '@material-ui/core/Box'
import SearchIcon from '@material-ui/icons/Search'
import GridItem from 'components/Grid/GridItem.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from 'components/CustomButtons/Button.js'
import Table from './components/writerGoodsTable.js'

import {connect} from 'react-redux'
import {
	getListWriterGoodsAction,
	requestWriterGoodsManagingAction,
	requestWriterGoodsManagingErrorAction,
	toggleWriterGoodsIsShowAction,
	toggleWriterGoodsIsSyncAction
} from 'redux/actions/writerGoodsManagingAction'
import writerGoodsManagingApi from 'api/writerGoodsManagingApi'
import writerManagingApi from 'api/writerManagingApi'

import styles from 'assets/jss/material-dashboard-pro-react/views/UserManaging/userManaging'
import codeManagingApi from "../../api/codeManagingApi";
import {getCodeListAction, requestCommentsAction} from "../../redux/actions/codeManagingAction";
import Radio from "@material-ui/core/Radio";
import {FiberManualRecord} from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(styles)

const WriterGoodsManaging = (props) => {
	const {
		requestWriterGoodsManagingAction,
		getListWriterGoodsAction,
		goodsManagingList,
		requestWriterGoodsManagingErrorAction,
		loading,
		goods,
		totalWriterGoods,
		totalWriterGoodsBySearch,
		toggleWriterGoodsIsSyncAction,
		toggleWriterGoodsIsShowAction,
		totalRecord,
		metaData: { totalRecords },
	} = props

	const fileInput = React.createRef()
	const imgInput = React.createRef()
	const imgInput2 = React.createRef()
	const classes = useStyles()

	const [loadingBtnGetExcel, setLoadingBtnGetExcel] = React.useState(false)
	const [select, setSelect] = React.useState('NORMAL')
	const [order, setOrder] = React.useState('desc')
	const [orderBy, setOrderBy] = React.useState('')
	const [isCategoryMode, setIsCateoryMode]  = React.useState(false)
	//작가 상품 일괄 삭제용 변수
	const [writerId, setWriterId] = React.useState('')
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [isResetPagination, setIsResetPagination] = React.useState(false)
	const [page, setPage] = React.useState(0)
	const [searchKey, setSearchKey] = React.useState('all')
	const [searchText, setSearchText] = React.useState('')
	const [writerList, setWriterList] = React.useState([])
	const [codeList, setCodeList] = React.useState([])
	const [childCodeList, setChildCodeList] = React.useState([])
	const [parentCode, setParentCode] = React.useState(null)
	const [childCode, setChildCode] = React.useState(null)
	const [minPrice , setMinPrice] = React.useState(0)
	const [maxPrice , setMaxPrice] = React.useState(1000000)
	const [isShow, setIsShow] = React.useState(null)


	const handleChangeSearchInput = (e) => {
		setSearchText(e.target.value)
		!isResetPagination && setIsResetPagination(true)
	}

	const handleChangeSelect = (event) => {
		setSearchKey(event.target.value)
		!isResetPagination && setIsResetPagination(true)
	}

	const handleGoodsManaging = async (event, id) => {
		// setWriterId(event.target.value)

		//태그 업데이트
		const dd = await writerGoodsManagingApi.updateGoodsManagingWriterGoods({
			goodsManagingId: event.target.value,
			writerGoodsId: id
		})
		//선택한 작가의 상품 개수 조회?
	}
	const allDeleteWriter = async () => {

		//상품 개수 조회해서 삭제 확인 체크 필요

		//상품 삭제
		if (confirm('선택한 작가의 상품을 삭제하시겠습니까?')){

			const data = await writerGoodsManagingApi.deleteGoodsByWriterId({writerId});

			if (data.status == 200 && !data.messages ){
				alert("성공적으로 삭제되었습니다. ")
				location.reload()

			}
		}
	}

	const headCells = [
		/*{
			id: 'home',
			numeric: false,
			disablePadding: false,
			label: '홈 노출',
			allowSortable: false,
		},*/
		{
			id: 'No',
			numeric: false,
			disablePadding: false,
			label: 'No.',
			allowSortable: false,
		},
		{
			id: 'isSync',
			numeric: false,
			disablePadding: false,
			label: '동기화여부',
			allowSortable: false,
		},
		{
			id: 'isShow',
			numeric: false,
			disablePadding: false,
			label: '노출여부',
			allowSortable: false,
		},
		{
			id: 'thumbnail',
			numeric: false,
			disablePadding: false,
			label: '썸네일',
			allowSortable: false,
		},
		{
			id: 'nickname',
			numeric: true,
			disablePadding: false,
			label: '브랜드/작가 닉네임',
			allowSortable: false,
		},
		{
			id: 'goodsName',
			numeric: true,
			disablePadding: false,
			label: '상품명',
			allowSortable: true,
		},
		/*{
			id: 'nickname',
			numeric: true,
			disablePadding: false,
			label: '브랜드 / 작가 닉네임',
			allowSortable: false,
		},
		{
			id: 'goodsName',
			numeric: true,
			disablePadding: false,
			label: '상품명',
			allowSortable: true,
		},
		/*{
			id: 'files',
			numeric: false,
			disablePadding: false,
			label: '파일 리스트',
			allowSortable: false,
		},*/
		/*{
			id: 'url',
			numeric: false,
			disablePadding: false,
			label: '상품링크',
			allowSortable: false,
		},*/
		{
			id: 'categoryName',
			numeric: true,
			disablePadding: false,
			label: '카테고리',
			allowSortable: true,
		},
		{
			id: 'price',
			numeric: true,
			disablePadding: false,
			label: '가격',
			allowSortable: true,
		},
		/*{
			id: 'brandName',
			numeric: true,
			disablePadding: false,
			label: '브랜드',
			allowSortable: true,
		},
		{
			id: 'goodsName',
			numeric: true,
			disablePadding: false,
			label: '상품명',
			allowSortable: true,
		},*/
		{
			id: 'cmdtCode',
			numeric: true,
			disablePadding: false,
			label: '상품코드',
			allowSortable: true,
		},
		{
			id: 'id',
			numeric: true,
			disablePadding: false,
			label: '생성일 / 업데이트',
			allowSortable: true,
		}
	]
	const getListWriterGoods = async (page2= null, paramParentCode = null, paramChildCode = null) => {
		// alert("parent", parentCode)
		let orderString = '';

		// alert("chick"+ parentCode + "  "+childCode)
		if (orderBy == 'first') {
			//처음 정렬시 현재 front에 나오는 표출 순서대로
			orderString = 'numOrder,DESC&createdAt,DESC';
		} else {
			orderString = orderBy + ',' + order
		}
		//유효성 체크
		const positiveIntegerPattern = /^\d+$/;
		if (!positiveIntegerPattern.test(minPrice)){
			alert("최소금액이 잘못 설정되었습니다.")
			return
		}
		if (!positiveIntegerPattern.test(maxPrice)){
			alert("최대금액이 잘못 설정되었습니다.")
			return
		}
		if (maxPrice< minPrice){
			alert("최대금액이 잘못 설정되었습니다.")
			return
		}


		let ppCode = parentCode
		let ccCode = childCode
		if (parentCode == null){
			ppCode = paramParentCode
			// alert(ppCode, "Dsfs")
		}
		if (childCode == null){
			ccCode = paramChildCode
			// alert(ccCode, "Dsfs")
		}


		try {
			requestWriterGoodsManagingAction()
			const params = {
				limit: rowsPerPage,
				offset: page2 == null? page + 1 : page2+1,
				order: order,
				orderBy: orderBy,
				searchKey: searchKey,
				searchText: searchText,
				writerId,
				parentCode: ppCode,
				childCode: ccCode,
				minPrice,
				maxPrice,
				isShow
			}


			// const { data } = await writerScheduleManagingApi.getListSchedule(params)
			const { data } = await writerGoodsManagingApi.getListGoods(params)
			setPage(page2)
			setWriterList(data.writerList)
			getListWriterGoodsAction(data)


		} catch (error) {
			console.log(error.response)
			if (error && error.response && error.response.data) {
				requestWriterGoodsManagingErrorAction(error.response.data)
			}
		}
	}
	const getCodeList = async (depth, parentCode) => {

		let params = {
			limit: 100000,
			offset: 0,
			depth: depth,
			parentCode: parentCode
		}

		try {
			requestCommentsAction()
			const result = await codeManagingApi.getCodeList(params)
			if(depth == 0){
				setCodeList(result.data.codeList)
			}else{
				setChildCodeList(result.data.codeList)
			}
			// alert("dfdf"+ JSON.stringify(params))

			getCodeListAction(result.data)
		} catch (error) {
			if (
				error &&
				error.response &&
				error.response.data &&
				error.response.data.data
			) {
				// noticesWithErrAction(error.response.data)
				console.log(error.response.data)
			}
		}
	}
	const getListGoodsExcel = async () => {
		let orderString = '';

		if (orderBy == 'first') {
			//처음 정렬시 현재 front에 나오는 표출 순서대로
			orderString = 'numOrder,DESC&createdAt,DESC';
		} else {
			orderString = orderBy + ',' + order
		}

		try {
			const params = {
				limit: rowsPerPage,
				offset: page + 1,
				order: order,
				orderBy: orderBy,
				searchKey: searchKey,
				searchText: searchText
			}
			const data  = await writerGoodsManagingApi.getListGoodsExcel(params)

			fileDownload(data, 'goods.xlsx')

		} catch (error) {
			if (error && error.response && error.response.data) {
				requestWriterGoodsManagingErrorAction(error.response.data)
			}
		}
	}


	const handleSearchKey = (value) => {
		setSearchKey(value)
		setOrder('asc')
	}

	const handleButtonClick = async e => {
		fileInput.current.click();
	}

	const handleButtonClickImg = async e => {
		imgInput.current.click();
	}
	const handleButtonClickImg2 = async e => {
		imgInput2.current.click();
	}

	const handleChangeImg = async e => {
		const files = e.target.files;
		const formData = new FormData()

		for(let i=0; i<files.length; i++) {
			formData.append('files',  e.target.files[i])
		}
		formData.append('type', 'SCHEDULE')

		const { data } = await writerManagingApi.saveImageFile(formData)

		if (data){
			// alert("파일 업로드가 완료되었습니다.")
			alert(data.messages)
		}
	}
	const handleChangeGoodsExcel = async e => {
		const files = e.target.files;
		const formData = new FormData()

		for(let i=0; i<files.length; i++) {
			formData.append('files',  e.target.files[i])
		}
		formData.append('type', 'SCHEDULE')

		const { data } = await writerGoodsManagingApi.setListGoodsExcel(formData)

		if (data){
			// alert("파일 업로드가 완료되었습니다.")
			alert(data.messages)
			location.reload()
		}
	}

	const newGoodsExcel = async e => {
		const files = e.target.files;
		const formData = new FormData()

		for(let i=0; i<files.length; i++) {
			formData.append('files',  e.target.files[i])
		}
		formData.append('type', 'SCHEDULE')

		const { data } = await writerGoodsManagingApi.newGoodsExcel(formData)

		if (data){
			// alert("파일 업로드가 완료되었습니다.")
			alert(data.messages)
			location.reload()
		}
	}

	const handleChange = async e => {
		try {
			const file = e.target.files[0];
			const fd = new FormData()
			fd.append('xlsx', file, file.name)
			fd.append('type', 'xlsx')

			const { data } = await writerGoodsManagingApi.setExcelFile(fd)

			getListWriterGoods()

		} catch (error) {
			console.log(error.response)
			if (error && error.response && error.response.data) {
			}
		}
	}

	React.useEffect(async () => {


		const urlString = window.location.href;

		// URL 문자열에서 '?' 뒤의 쿼리스트링 부분을 가져옵니다.
		const queryString = urlString?.split('?')[1];
		let queryPaerntCode= null
		let queryChildCode= null

		if (queryString != null) {

			// '&'를 기준으로 쿼리스트링을 나눕니다.
			const params = queryString?.split('&');

			// 매개변수를 담을 객체를 생성합니다.
			const paramsObj = {};

			// 매개변수를 객체에 저장합니다.
			params.forEach(param => {
				const [key, value] = param.split('=');
				paramsObj[key] = decodeURIComponent(value);
			});


			//챌린지에 속한 게시판 리스트 가져오기
			if (location.search.indexOf('parentCode=') > -1) {
				setParentCode(paramsObj['parentCode'])
				setChildCode(paramsObj['childCode'])
				setIsCateoryMode(true)
				queryPaerntCode = paramsObj['parentCode']
				queryChildCode = paramsObj['childCode']
			}
		}

		let ppCode = null
		let ccCode = null

		//챌린지에 속한 게시판 리스트 가져오기
		if (new URL(location).searchParams.get('parentCode') > -1) {


			ppCode = new URL(location).searchParams.get('parentCode')

			if ( new URL(location).searchParams.get('childCode') > -1 ){
				// ppCode = null
				ccCode =new URL(location).searchParams.get('childCode')
			}
			setChildCode(ccCode)
			setIsCateoryMode(true)
			getListWriterGoods(page, queryPaerntCode, queryChildCode)



		}else{

			getCodeList(0, parentCode)
			getListWriterGoods(page)

		}

	}, [page, rowsPerPage, order, ])

	React.useEffect(() => {


		if (parentCode != null){
			getCodeList(0, parentCode)
		}
		if (childCode != null){
			getCodeList(1, childCode)
		}

	}, [isCategoryMode])



	//** 초기 1번 가져오기 **//



	return (
		<div className='writer-managing'>
			<GridContainer alignItems='center'>

				<GridItem
					className={classes.gridContainerOne}
					xs={12}
					sm={4}
					md={3}
					lg={2}
					xl={2}
				>
					<TextField
						id='writer-managing-select'
						select
						size='small'
						className={classes.textFieldOne}
						label=''
						value={searchKey}
						onChange={handleChangeSelect}
					>
						<MenuItem value={'all'}>전체</MenuItem>체
						{/*<MenuItem value={'writerName'}>작가닉네임</MenuItem>*/}
						<MenuItem value={'url'}>상품링크</MenuItem>
						<MenuItem value={'brandName'}>브랜드</MenuItem>
						<MenuItem value={'goodsName'}>상품명</MenuItem>
					</TextField>
				</GridItem>
				<GridItem xs={12} sm={6} md={5} lg={4} xl={4}>
					<TextField
						id='post-managing-textfield'
						size='small'
						className={classes.textFieldOne}
						placeholder='검색어를 입력해주세요'
						name='searchText'
						value={searchText}
						onChange={handleChangeSearchInput}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
				</GridItem>


			</GridContainer>
			<GridContainer alignItems='center' style={{paddingTop : '10px', paddingBottom : '10px'}}>
				<GridItem
					className={classes.gridContainerOne}
					xs={12}
					sm={4}
					md={3}
					lg={2}
					xl={2}
				>
					작가이름
				</GridItem>
				<GridItem
					className={classes.gridContainerOne}
					xs={12}
					sm={4}
					md={3}
					lg={2}
					xl={2}
				>
					<TextField
						id='writer-select'
						select
						size='small'
						className={classes.textFieldOne}
						label='작가'
						value={writerId}
						onChange={(e)=>{
							setWriterId(e.target.value)
						}}
					>
						<MenuItem value={''}>선택해주세요</MenuItem>
						{ writerList.map((row, index) =>{
							return (<MenuItem value={row.id}>{row.nickname}</MenuItem>)
							// return (<div>dfsdfsdfsdf</div>)
						})}
					</TextField>
				</GridItem>
			</GridContainer>
			<GridContainer alignItems='center' style={{paddingTop : '10px', paddingBottom : '10px'}}>
				<GridItem
					className={classes.gridContainerOne}
					xs={12}
					sm={4}
					md={3}
					lg={2}
					xl={2}
				>
					카테고리
				</GridItem>

				<GridItem
					className={classes.gridContainerOne}
					xs={12}
					sm={4}
					md={3}
					lg={2}
					xl={2}
				>

					{
						isCategoryMode &&
							codeList.map((row, index) =>{
								if (row.code == parentCode){
									return  <div>{row.codeName}</div>
								}else{
									return <div>{row.parentCode}</div>
								}
							})
					}

					{
						!isCategoryMode &&
						<TextField
							id='writer-select'
							select
							size='small'
							className={classes.textFieldOne}
							label='대분류'
							value={parentCode}
							onChange={(e)=>{
								setParentCode(e.target.value)
								getCodeList(1,  e.target.value)
							}}
						>
							<MenuItem value={''}>선택해주세요</MenuItem>
							{ codeList.map((row, index) =>{
								return (<MenuItem value={row.code}>{row.codeName}</MenuItem>)
								// return (<div>dfsdfsdfsdf</div>)
							})}
						</TextField>

					}
				</GridItem>

				<GridItem
					className={classes.gridContainerOne}
					xs={12}
					sm={4}
					md={3}
					lg={2}
					xl={2}
				>

					{
						isCategoryMode &&
						childCodeList.map((row, index) =>{

							if (row.code == childCode){
								return  <span style={{
									'color': 'green',
									'fontSize': '16px'
								}}>{row.codeName}</span>
							}
						})

					}

					{
						!isCategoryMode &&
						<TextField
						id='writer-managing-select'
						select
						size='small'
						className={classes.textFieldOne}
						label='중분류'
						value={childCode}
						onChange={(e)=>{
						setChildCode(e.target.value)
						getCodeList(1, parentCode)

					}}
						>
						<MenuItem value={''}>선택해주세요</MenuItem>
								{ childCodeList.map((row, index) =>{
									return (<MenuItem value={row.code} >{row.codeName}</MenuItem>)
									// return (<div>dfsdfsdfsdf</div>)
								})}
						</TextField>
					}


				</GridItem>
			</GridContainer>
			<GridContainer alignItems='center'>
				<GridItem
					className={classes.gridContainerOne}
					xs={12}
					sm={4}
					md={3}
					lg={2}
					xl={2}
				>
					가격(할인가기준)
				</GridItem>

				<GridItem
					className={classes.gridContainerOne}
					xs={12}
					sm={4}
					md={3}
					lg={2}
					xl={2}
				>

					<TextField
						id='post-managing-textfield'
						size='small'
						className={classes.textFieldOne}
						placeholder='최소'
						name='minPrice'
						value={minPrice}
						onChange={(e)=>{
							setMinPrice(e.target.value)
						}}
						InputProps={{
						}}
					/>
				</GridItem>
~
				<GridItem
					className={classes.gridContainerOne}
					xs={12}
					sm={4}
					md={3}
					lg={2}
					xl={2}
				>

					<TextField
						id='post-managing-textfield'
						size='small'
						className={classes.textFieldOne}
						placeholder='최대'
						name='maxPrice'
						value={maxPrice}
						onChange={(e)=>{
							setMaxPrice(e.target.value)
						}}
						InputProps={{
						}}
					/>
				</GridItem>
			</GridContainer>

			<GridContainer alignItems='center'>
				<GridItem
					className={classes.gridContainerOne}
					xs={12}
					sm={4}
					md={3}
					lg={2}
					xl={2}
				>
					노출여부
				</GridItem>
				<FormControlLabel
					control={
						<Radio
							checked={isShow == null}
							onChange={(e) => setIsShow(null)}
							value={null}
							aria-label="T"
							icon={<FiberManualRecord className={classes.radioUnchecked}/>}
							checkedIcon={
								<FiberManualRecord className={classes.radioChecked}/>
							}
							classes={{
								checked: classes.radio,
								root: classes.radioRoot
							}}
						/>
					}
					classes={{
						label: classes.p0
					}}
					label="전체"
				/>
				<FormControlLabel
					control={
						<Radio
							checked={isShow == 0}
							onChange={(e) => setIsShow(false)}
							value={false}
							aria-label="T"
							icon={<FiberManualRecord className={classes.radioUnchecked}/>}
							checkedIcon={
								<FiberManualRecord className={classes.radioChecked}/>
							}
							classes={{
								checked: classes.radio,
								root: classes.radioRoot
							}}
						/>
					}
					classes={{
						label: classes.p0
					}}
					label="미노출"
				/>
				<FormControlLabel
					control={
						<Radio
							checked={isShow == 1}
							onChange={(e) => setIsShow(true)}
							value={true}
							aria-label="T"
							icon={<FiberManualRecord className={classes.radioUnchecked}/>}
							checkedIcon={
								<FiberManualRecord className={classes.radioChecked}/>
							}
							classes={{
								checked: classes.radio,
								root: classes.radioRoot
							}}
						/>
					}
					classes={{
						label: classes.p0
					}}
					label="노출"
				/>


			</GridContainer>



			<GridContainer >
			<GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
				<Button
					color='primary'
					disabled={loading}
					onClick={() => {
						getListWriterGoods(0)
					}}
				>
					검색
				</Button>
			</GridItem>
			</GridContainer>


			<Box my={2}>
				<TextField
					className={`${classes.textFieldTwo} ${classes.textFieldTwoChildOne}`}
					id='post-managing-textfield-show-info1'
					size='small'
					value={totalWriterGoods}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>전체</InputAdornment>
						),
						readOnly: true,
					}}
				/>
				<TextField
					className={classes.textFieldTwo}
					id='post-managing-textfield-show-info2'
					size='small'
					value={totalRecords}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>검색</InputAdornment>
						),
						readOnly: true,
					}}
				/>
			</Box>

			<Box className={classes.btnGetExcelAndPaginationTable} mb={2}
				 style={{justifyContent : 'flex-end'}}
			>
				{/*<div>
					<GridContainer alignItems='center'>
						<GridItem
							className={classes.gridContainerOne}
							xs={8}
						>
							<TextField
								id='writer-select'
								select
								size='small'
								className={classes.textFieldOne}
								label='작가'
								value={writerId}
								onChange={handleChangeWriter}
							>
								<MenuItem value={''}>선택해주세요</MenuItem>
								{ writerList.map((row, index) =>{
									return (<MenuItem value={row.id}>{row.nickname}</MenuItem>)
									// return (<div>dfsdfsdfsdf</div>)
								})}
							</TextField>
						</GridItem>
						<GridItem
							className={classes.gridContainerOne}
							xs={4}
						>

							<Button
								color='error'
								onClick={allDeleteWriter}
							>
								작가 상품 전체 삭제
							</Button>
						</GridItem>


					</GridContainer>

				</div>*/}

				<div style={{'display': 'flex', justifyContent : 'flex-end'}}>
					<>
						{/*<Button
							color='github'
							onClick={(e)=>{
								location.href = 'https://d16sraug4hsad1.cloudfront.net/excel_example/writer_goods.xlsx'
							}}
						>
							엑셀 양식 다운로드
						</Button>*/}
						<Button
							color='github'
							onClick={(e)=>{
								getListGoodsExcel();
								// location.href = 'https://d16sraug4hsad1.cloudfront.net/excel_example/writer_goods.xlsx'
								//
							}}
						>
						 	상품 엑셀 다운로드
						</Button>
						<Button
							color='success'
							onClick={handleButtonClickImg}
						>
							상품 엑셀 업로드
						</Button>
						<Button
							color='github'
							onClick={(e)=>{
								// getListGoodsExcel();
								location.href = 'https://d16sraug4hsad1.cloudfront.net/writer_goods_uplaod_imaunal.xlsx'
								//
							}}
						>
						 	상품 업로드 엑셀 양식(신규)
						</Button>
						<Button
							color='success'
							onClick={handleButtonClickImg2}
						>
						 	신규 상품 엑셀 업로드(핫트랙스)
						</Button>
						<input
							name="FILE_UPLOAD"
							type="file"
							multiple
							ref={imgInput}
							id="fileUpload"
							onChange={handleChangeGoodsExcel}
							onClick={(event)=> {event.target.value = null}}    //같은 파일 적용도 가능하게
							accept='*'
							style={{ display: "none" }}
						/>
						<input
							name="FILE_UPLOAD"
							type="file"
							multiple
							ref={imgInput2}
							id="fileUpload"
							onChange={newGoodsExcel}
							onClick={(event)=> {event.target.value = null}}    //같은 파일 적용도 가능하게
							accept='*'
							style={{ display: "none" }}
						/>
						{/*<Button
							color='success'
							onClick={handleButtonClickImg}
						>
							사진 업로드
						</Button>*/}
						{/*<input
							name="FILE_UPLOAD"
							type="file"
							multiple
							ref={imgInput}
							id="fileUpload"
							onChange={handleChangeImg}
							onClick={(event)=> {event.target.value = null}}    //같은 파일 적용도 가능하게
							accept='image/*'
							style={{ display: "none" }}
						/>*/}
					</>
					{/*2.2.5~ 숨김처리 추후 타 사이트 상품 가져올 때
					<>
						<Button
							color='info'
							onClick={handleButtonClick}
						>
							엑셀 업로드childCode
						</Button>
						<input
							name="FILE_UPLOAD"
							type="file"
							ref={fileInput}
							id="fileUpload"
							onChange={handleChange}
							onClick={(event)=> {event.target.value = null}}    //같은 파일 적용도 가능하게
							accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  //엑셀만 허용
							style={{ display: "none" }}
						/>
					</>*/}
				</div>



			</Box>
			<Box>
				<div >
					<span style={{'paddingTop': '15px', 'paddingRight': '10px'}}>* 갱신주기: 하루 1번 오전 3시부터  </span><br/>
					<span style={{ 'display': 'inline-block'}}>*상품 업로드 기능은 현재 핫트랙스만 지원되고 있습니다. 일괄 수정은 상품엑셀다운로드->내용변경(노란색 항목들만 수정가능. 다른 내용은 변경하지 말아주세요!)->상품엑셀업로드를 통하여 수정이 가능합니다.</span>
				</div>
			</Box>

			<Box mb={2}>
				{loading ? (
					<CircularProgress size={30} className={classes.buttonProgress} />
				) : (
					<Table
						headCells={headCells}
						rows={goods}
						totalRecords={totalRecords}
						setRowsPerPage={setRowsPerPage}
						rowsPerPage={rowsPerPage}
						setPage={setPage}
						page={page}
						setOrder={setOrder}
						order={order}
						setOrderBy={setOrderBy}
						orderBy={orderBy}
						getListWriterGoods={getListWriterGoods}
						goodsManagingList={goodsManagingList}
						handleGoodsManaging={handleGoodsManaging}
						toggleWriterGoodsIsSyncAction={toggleWriterGoodsIsSyncAction}
						toggleWriterGoodsIsShowAction={toggleWriterGoodsIsShowAction}
					/>
				)}
			</Box>
		</div>
	)
}

const mapStateToProps = (state) => ({
	loading: state.WriterGoodsManaging.loading,
	goods: state.WriterGoodsManaging.goods,
	metaData: state.WriterGoodsManaging.metaData,
	goodsManagingList: state.WriterGoodsManaging.goodsManagingList,
	totalWriterGoods: state.WriterGoodsManaging.totalWriterGoods,
	totalWriterGoodsBySearch: state.WriterGoodsManaging.totalWriterGoodsBySearch,
	paginationWriterManaging: state.WriterGoodsManaging.paginationUserManaging,
})

export default connect(mapStateToProps, {
	requestWriterGoodsManagingAction,
	getListWriterGoodsAction,
	requestWriterGoodsManagingErrorAction,
	toggleWriterGoodsIsSyncAction,
	toggleWriterGoodsIsShowAction,
})(WriterGoodsManaging)
