import axiosInterceptors from 'api/axios'
import queryString from "query-string";

const commentsManagingApi = {

    create: (params) => {
        const url = '/comments/create'
        return axiosInterceptors.post(url, { params })
    },

    getListEvents: (params) => {
        const url = '/comments/getList'
        return axiosInterceptors.get(url, { params })
    },
    update: (params) => {
        const url = '/comment/update'
        return axiosInterceptors.put(url, { params })
    },
    updateStatus: (params) => {
        const url = '/comments/updateStatus'
        return axiosInterceptors.put(url, { params })
    }
    ,getEvents: (params) => {
        const url ='/comments/getEvents'
        return axiosInterceptors.get(url, { params })
    }
    ,destroy: (params) => {
        const url ='/comment/destroy'
        return axiosInterceptors.delete(url, { params })
    },
    getExcelFile : (params) =>
    {
        let url

        url = `/comments/getEventsExcel?${queryString.stringify(
            params,
        )}`
        return axiosInterceptors.get(url, {
            responseType: 'blob',
            'Content-Disposition': 'attachment;filename=' + encodeURI('ssss')
        })
    },
    getListAiComment : (params) => {
        const url ='/comment/getListAiComment'
        return axiosInterceptors.get(url, { params })
    },
    getListAiCommentExcel : (params) => {
        const url = `/comment/getListAiCommentExcel?${queryString.stringify(
            params,
        )}`

        return axiosInterceptors.get(url, {
            responseType: 'blob',
            'Content-Disposition': 'attachment;filename=' + encodeURI('ssss')
        })
    },
    getAiComment : (params) => {
        const url ='/comment/getAiComment'
        return axiosInterceptors.get(url, { params } )
    }

}

export default commentsManagingApi
