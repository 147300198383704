import * as actionTypes from './types'

export const createBoardManagingAction = (payload) => ({
    type: actionTypes.CREATE_BOARD_INFO_MANAGING,
    payload,
})


export const updateBoardManagingAction = (payload) => ({
    type: actionTypes.UPDATE_BOARD_INFO_MANAGING,
    payload,
})

export const getListPostInfoManagingAction = (payload) => ({
    type: actionTypes.GET_LIST_BOARD_INFO_MANAGING,
    payload,
})



export const postInfoManagingRequestError = (payload) => ({
    type: actionTypes.BOARD_INFO_MANAGING_REQUEST_ERROR,
    payload,
})

