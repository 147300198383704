import * as actionTypes from '../actions/types'

const initialState = {

    metaData: {
        totalPages: 0,
        totalRecords: 0},
    loading: true,
    error: null,
    phrases: [],

}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type){
        case actionTypes.GET_PHRASE_LIST:
            const {phrases, metaData} = payload
            return {
                ...state,
                loading: false,
                phrases,
                metaData,
            }
        default:
            return state
    }
}

