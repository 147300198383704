import {connect, useDispatch} from "react-redux";
import React, {useEffect} from "react";
import moment from "moment/moment";
import phrasesSettingReducer from "../../redux/reducers/phrasesSettingReducer";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Box from "@material-ui/core/Box";
import Spinner from "../../components/Spinner/Spinner";
import TextField from "../../components/Gm-TextField/TextField";


import styles from 'assets/jss/material-dashboard-pro-react/views/Setting/setting'
import {makeStyles} from "@material-ui/core/styles";
import CustomButton from "../../components/CustomButtons/Button";
import PhraseSettingApi from "../../api/phraseSettingApi";
import phraseSettingApi from "../../api/phraseSettingApi";
import managerManagingApi from "../../api/managerManagingApi";
import {getListManagerManagingAction, managerManagingRequestError} from "../../redux/actions/managerManagingAction";


/**
 * 문구 관리 페이지(지금은 로그인만 쓰임)
 * @param props
 * @constructor
 */
const PhrasesSetting = (props) =>{

    // const classes = useStyles()
    const dispatch = useDispatch()
    const useStyles = makeStyles(styles)
    const classes = useStyles()

    const {
        metaData: metadata,
    } = props

    //-------------- state 세팅
    const [loading, setLoading] = React.useState(false)
    // TOP_UPPER
    // TOP_LOWER
    // COMMENT
    const [topUpper, setTopUpper] = React.useState({
        phrase: ''
    })
    const [topLower, setTopLower] = React.useState({
        phrase: ''
    })
    const [comment, setComment] = React.useState({
        phrase: ''
    })





    //./ state 세팅

    //------- handle form
    const handleForm = (e, keys =null) => {

        if (e != null){
            if (keys == 'topUpper'){
                setTopUpper({ ...topUpper, phrase : e.target.value })
            }else if(keys == 'topLower'){
                setTopLower({ ...topLower, phrase : e.target.value })
            }else{
                setComment({ ...comment, phrase : e.target.value })
            }
        }


    }
    //저장
    const createForm = async (e, keys = null) => {

        if (e != null) {

            let phrase = ''
            let position = ''
            if (keys == 'topUpper') {
                // setTopUpper({...topUpper, phrase: e.target.value})
                phrase = topUpper.phrase
                position = 'LOGIN_TOP_UPPER'

            } else if (keys == 'topLower') {
                // setTopLower({...topLower, phrase: e.target.value})
                phrase = topLower.phrase
                position = 'LOGIN_TOP_LOWER'


            } else {
                // setComment({...comment, phrase: e.target.value})
                phrase = comment.phrase
                position = 'LOGIN_COMMENT'
            }

            const {data} = await phraseSettingApi.createPhraseSetting({
                phrase,
                position
            })

            alert("저장되었습니다.")

        }
    }

    //./ handle form ------

    const getPhraseSettings = async () =>{

        try {
            setLoading(true)
            const { data } = await phraseSettingApi.getPhraseSettings()

            setTopUpper({phrase: data.loginTopUpper?.phrase})
            setTopLower({phrase: data.loginTopLower?.phrase})
            setComment({phrase: data.loginComment?.phrase})


            // dispatch(getListManagerManagingAction(data))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            dispatch(managerManagingRequestError(error?.response?.data))
        }
    }

    useEffect(()=>{
        getPhraseSettings()
    }, [])


    return (
        <div className='phrasesSetting-managing'>
            <Box mb={4} className='phrase-1'>
                <Typography variant='h6' component='h6' gutterBottom>
                    상단 문구
                </Typography>
                <Paper
                    className={`${classes.paperCommon} ${classes.setMarginBottom}`}
                    variant='outlined'
                >
                    <GridContainer alignItems='center'>
                        <GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
                            <Typography component='p'>윗줄</Typography>
                        </GridItem>
                        <GridItem xs={7} sm={6} md={5} lg={5} xl={5}>
                            <Box className={classes.setPositionRelative}>
                                {/*{{loadingSpinner ? (*/}
                                {/*    <Spinner />*/}
                                {/*) : (*/}
                                    <TextField
                                        id='notice-title-input1'
                                        value={topUpper?.phrase}
                                        fullWidth={true}
                                        variant='outlined'
                                        size='small'
                                        onChange={(event)=>handleForm(event,'topUpper')}
                                    />
                                {/*)}}*/}
                            </Box>
                        </GridItem>

                        <GridItem xs={2} sm={2} md={2} lg={2} xl={2}>
                            <CustomButton color="primary" mr={2} variant="contained"
                                          onClick={(event) => createForm(event,'topUpper')}
                            >수정</CustomButton>
                        </GridItem>
                    </GridContainer>
                </Paper>
                <Paper
                    className={`${classes.paperCommon} ${classes.setMarginBottom}`}
                    variant='outlined'
                >
                    <GridContainer alignItems='center'>
                        <GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
                            <Typography component='p'>아랫줄</Typography>
                        </GridItem>
                        <GridItem xs={7} sm={6} md={5} lg={5} xl={5}>
                            <Box className={classes.setPositionRelative}>
                                {/*{{loadingSpinner.getVersion ? (*/}
                                {/*    <Spinner />*/}
                                {/*) : (*/}
                                    <TextField
                                        id='notice-title-input1'
                                        value={topLower?.phrase}
                                        fullWidth={true}
                                        variant='outlined'
                                        size='small'
                                        onChange={(event)=>handleForm(event,'topLower')}
                                    />
                                {/*)}}*/}
                            </Box>
                        </GridItem>

                        <GridItem xs={2} sm={2} md={2} lg={2} xl={2}>
                            <CustomButton color="primary" mr={2} variant="contained"
                                          onClick={(event) => createForm(event,'topLower')}
                            >수정</CustomButton>
                        </GridItem>
                    </GridContainer>
                </Paper>
            </Box>
            <Box mb={4} className='phrase-2'>
                <Typography variant='h6' component='h6' gutterBottom>
                    말풍선 문구
                </Typography>
                <Paper
                    className={`${classes.paperCommon} ${classes.setMarginBottom}`}
                    variant='outlined'
                >
                    <GridContainer alignItems='center'>
                        <GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
                            <Typography component='p'>말풍선</Typography>
                        </GridItem>
                        <GridItem xs={7} sm={6} md={5} lg={5} xl={5}>
                            <Box className={classes.setPositionRelative}>
                                {/*{{loadingSpinner.getVersion ? (*/}
                                {/*    <Spinner />*/}
                                {/*) : (*/}
                                    <TextField
                                        id='notice-title-input1'
                                        value={comment?.phrase}
                                        fullWidth={true}
                                        variant='outlined'
                                        size='small'
                                        onChange={(event)=>handleForm(event,'comment')}
                                    />
                                {/*)}*!/*/}
                            </Box>
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2} lg={2} xl={2}>
                            <CustomButton color="primary" mr={2} variant="contained"
                                          onClick={(event) => createForm(event,'comment')}
                            >수정</CustomButton>
                        </GridItem>
                    </GridContainer>
                </Paper>
            </Box>
        </div>
    )

}

const mapStateToProps = (state) => ({
    loading: state.phrasesSettingReducer.loading,
    error: state.phrasesSettingReducer.error,
})
export default connect(mapStateToProps, {


})(PhrasesSetting)

