import React, {useEffect} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/PushManaging/PushManangingStyle";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import TextField from "../../../components/Gm-TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "../../../components/CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Table from './MemberTable.js';
import MenuItem from "@material-ui/core/MenuItem";
import {requestUserManagingAction} from "../../../redux/actions/userManagingAction";


const useStyles = makeStyles(styles)




const PushModal = (props) => {


    const {
        hideAlert,
        setAlert,
        dispatch,
        userManagingApi,
        getListUserAction,
        loading,
        setAddMemberIds,
        setMember,
        member
        // searchText,
        // setSearchText,
    } = props
    const [selected, setSelected] = React.useState([])

    const [page, setPage] = React.useState(0)
useEffect(() =>{
    document.querySelector('.sweet-alert').parentElement.style.zIndex = 1034;
    getMemberList();
},[page])
    //회원데이터
    const searchPost = async () => {

        setPage(0)
        getMemberList();
    }
    const getMemberList = async () => {
        let orderString = '';

        if (orderBy == 'first') {
            //처음 정렬시 현재 front에 나오는 표출 순서대로
            orderString = 'numOrder,DESC&createdAt,DESC';
        } else {
            orderString = orderBy + ',' + order
        }

        try {
            requestUserManagingAction()
            const params = {
                limit: 10,
                offset: page == 0 ? 1 :  page,
                order: 'DESC',
                orderBy: 'DATE_SIGN_UP',
                searchKey: searchKey,
                searchText: searchText
                // userStatus: select,
                // clientId,
            }

            // !clientId && delete params.clientId
            const { data } = await userManagingApi.getListUsers2(params)

            setMemberList(data.users)

            setMetaData(data.metaData)
        } catch (error) {
            console.log(error.response)
            if (error && error.response && error.response.data) {
                // requestUserManagingErrorAction(error.response.data)
            }
        }
    }


    const headCells = [
        {
            id: 'nickname',
            numeric: true,
            disablePadding: false,
            label: '닉네임',
            allowSortable: false,
        },
        {
            id: 'ACCOUNT_TYPE',
            numeric: true,
            disablePadding: false,
            label: '로그인타입',
            allowSortable: false,
        },
        {
            id: 'memberID',
            numeric: false,
            disablePadding: false,
            label: '고객번호',
            allowSortable: false,
        },
        {
            id: 'id',
            numeric: true,
            disablePadding: false,
            label: '아이디',
            allowSortable: false,
        },
        {
            id: 'access',
            numeric: true,
            disablePadding: false,
            label: '접근 처리 현황',
            allowSortable: false,
        },
        // {
        //     id: 'DATE_SIGN_UP',
        //     numeric: true,
        //     disablePadding: false,
        //     label: '가입일',
        //     allowSortable: true,
        // },
        // {
        //     id: 'DATE_ACCESS_APP',
        //     numeric: true,
        //     disablePadding: false,
        //     label: '최근 접속일',
        //     allowSortable: true,
        // },
    ]

    const classes = useStyles()

    const [showNotification, setShowNotification] = React.useState({
        open: false,
        message: '',
    })
    const [users, setUsers] = React.useState([])
    const [select, setSelect] = React.useState('NORMAL')
    const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('')
    const [clientId, setClientId] = React.useState('')
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [isResetPagination, setIsResetPagination] = React.useState(false)



    //알림대상 관련 변수
    const [limit, setLimit] = React.useState(10)
    const [searchText, setSearchText] = React.useState('')
    const [searchKey, setSearchKey] = React.useState('nickname')
    const [memberList, setMemberList] = React.useState([])
    const [totalRecords, setTotalRecords] = React.useState(10)
    const [metaData, setMetaData] = React.useState({
        totalRecords: 0,
        totalPages:0
    })

    const handleCloseOpen = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setShowNotification({...showNotification, open: false})
    }

    const handleSearchKey = ( valuwe) => {
        setSearchKey(valuwe)
        setOrder('asc')
    }
    const test = () => {

        //기존


        //추가되는거
        selected

        let memberNew = member
        let newSelected = []

        selected.map((item) =>{

            let isCheck = false;

            memberNew.map((item2)=>{
                //중복데이터 체크
                if (item2.id == item.id){
                    isCheck = true
                }
            })

            if (!isCheck){
                memberNew.push(item)
            }


        })

        setMember(memberNew)


        hideAlert()


    }

    return (
                <>
                    {/*<Snackbar*/}
                    {/*    anchorOrigin={{vertical: 'top', horizontal: 'center'}}*/}
                    {/*    open={showNotification.open}*/}
                    {/*    autoHideDuration={6000}*/}
                    {/*    onClose={handleCloseOpen}*/}
                   {/*>`*/}
                    {/*    <CustomAlert onClose={handleCloseOpen} severity='error'>*/}
                    {/*        {showNotification.message}*/}
                    {/*    </CustomAlert>*/}
                    {/*</Snackbar>*/}
                    <SweetAlert
                        style={{display: 'block', marginTop: '-100px', width: '80em', zIndex: '1034',}}
                        title={'회원선택'}
                        onConfirm={() => {
                        }}
                        showConfirm={false}를
                        onCancel={() => hideAlert()}
                    >

                        <GridContainer alignItems='center'>
                            <GridItem xs={2} sm={2} md={2}>
                                {/*{formData.type}*/}
                                <TextField
                                    id='push-managing-select'
                                    select
                                    size='small'
                                    className={classes.widthTextFieldModal}
                                    value={searchKey}
                                    onChange={(e) => {
                                        // handelS
                                        // setSearchKey(e.target.value)
                                        handleSearchKey(e.target.value)
                                    }}
                                    label='검색 타입'
                                    style={{width: '100%'}}
                                >
                                    <MenuItem value={'nickname'}>닉네임</MenuItem>
                                    <MenuItem value={'clientId'}>고객 번호</MenuItem>
                                    <MenuItem value={'memberId'}>아이디</MenuItem>
                                </TextField>
                            </GridItem>
                            <GridItem
                                className={classes.gridContainerOne}
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                            >

                                <TextField
                                    id='post-managing-textfield'
                                    size='small'
                                    className={classes.textFieldOne}
                                    placeholder={'검색해주세요'}
                                    name='clientId'
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value)
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <SearchIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />


                            </GridItem>
                            <GridItem xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Button
                                    color='primary'
                                    // disabled={loading색ㅋㅋ}
                                    onClick={searchPost}
                                >
                                    검색
                                </Button>
                            </GridItem>

                            <Box mb={2} style={{'max-width': '100%'}}>
                                {loading ? (
                                    <CircularProgress size={30} className={classes.buttonProgress} />
                                ) : (
                                    <Table
                                        headCells={headCells}
                                        rows={memberList}
                                        setPagination={metaData}
                                        totalPages={metaData.totalPages}
                                        setRowsPerPage={setRowsPerPage}
                                        rowsPerPage={rowsPerPage}
                                        setPage={setPage}
                                        page={page}
                                        setOrder={setOrder}
                                        order={order}
                                        setOrderBy={setOrderBy}
                                        orderBy={orderBy}
                                        setSelected={setSelected}
                                        selected={selected}
                                    />
                                )}
                            </Box>





                        </GridContainer>


                        <Box mt={1} display='flex' justifyContent='center'>
                            <Button
                                onClick={hideAlert}
                                className={classes.marginRightBtnInModal}
                                color='danger'
                            >
                                취소
                            </Button>
                            <Button
                                color='success'
                                disabled={loading}
                                onClick={test}
                            >
                                추가
                            </Button>
                        </Box>

                    </SweetAlert>




                </>


    )

}
PushModal.propTypes = {
}
export default PushModal