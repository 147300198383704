import axiosInterceptors from 'api/axios'
import axiosInterceptors2 from "./axios2";

const stickerApi = {

    getStickers: (params) => {
        const url = `/stickerManaging/list`
        // delete params.tagName

        return axiosInterceptors.get(url, {params})

    },
    create: (body) => {
        const url = `/sticker/create`

        return axiosInterceptors2.post(url, body)

    },
    update: (body) => {
        const url = `/sticker/update`
        return axiosInterceptors2.put(url, body)

    },
    toggleIsShow: (body) => {
        const url = `/sticker/toggleIsShow`

        return axiosInterceptors2.put(url, body)
    },
    updateOrderNum:(body) => {
        const url = `/stickerManaging/changeOrderNum`
    return axiosInterceptors.put(url, body)
    }

}

export default stickerApi
