import React from 'react'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import clsx from 'clsx'

import {lighten, makeStyles, useTheme} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from 'components/CustomButtons/Button'
import Tooltip from '@material-ui/core/Tooltip'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Paper from '@material-ui/core/Paper'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import Modal from './Modal'
import Box from "@material-ui/core/Box";
import {MenuItem, TextField} from "@material-ui/core";
import postManagingApi from "../../../api/postManagingApi";
import fileDownload from "js-file-download";
import {fi} from "date-fns/locale";
import axiosInterceptors from "../../../api/axios";
import {movePostPushAction} from "../../../redux/actions/postManaging";

const useStyles = makeStyles({
    table: {
        minWidth: 900,
        '@media (max-width: 768px)': {
            minWidth: 700,
        },
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
})

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}))

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    mobileHidden: {
        '@media (max-width: 768px)': {
            display: 'none'
        },
    },
    cellWidth: {
        '@media (max-width: 768px)': {
            width: '12px',
            padding: '0px !important'
        },
    },
    buttonPadding: {
        '@media (max-width: 768px)': {
            padding: '10px',
        },
    },
    mobileWidth: {
        minWidth: '80px'
    },
    minWidth: {
        '@media (min-width: 768px)': {
            minWidth: '170px',
            maxWidth: '170px'
        },
    },
    inputStyle: {
        '@media (max-width: 768px)': {
            width: '100%',
        },
        '@media (min-width: 768px)': {
            width: '20%',
        },
    }
}))

const TablePaginationActions = (props) => {
    const classes = useStyles1()
    const theme = useTheme()
    const {count, page, rowsPerPage, onPageChange} = props

    return (
        <div className={classes.root}>
            <IconButton
                onClick={(event) => {
                    onPageChange(event, 0)
                }}
                disabled={page === 0}
                aria-label='first page'
            >
                {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
            </IconButton>
            <IconButton
                onClick={(event) => {
                    onPageChange(event, page - 1)
                }}
                disabled={page === 0}
                aria-label='previous page'
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight/>
                ) : (
                    <KeyboardArrowLeft/>
                )}
            </IconButton>
            <IconButton
                onClick={(event) => {
                    onPageChange(event, page + 1)
                }}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='next page'
            >
                {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft/>
                ) : (
                    <KeyboardArrowRight/>
                )}
            </IconButton>
            <IconButton
                onClick={(event) => {
                    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
                }}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='last page'
            >
                {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
            </IconButton>
        </div>
    )
}

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles()
    const {
        numSelected,
        handleDeletePost,
        loading,
        handleMovePosts,
        handleMovePushPosts,
        setMovePostInfoId,
        movePostInfoId,
        getListPostManagingExcel
    } = props

    const handleChangeSelect = async (e) => {
        setMovePostInfoId(e.target.value)
    }

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {/*<Tooltip title='Edit'>
                <TextField
                    id='writer-managing-select'
                    select
                    size='small'
                    style={{'marginRight': '5px'}}
                    className={classes.inputStyle}
                    label='게시판 타입'
                    value={movePostInfoId}
                    onChange={handleChangeSelect}

                >
                    <MenuItem value={1}>꾸미기(피드)</MenuItem>
                    <MenuItem value={2}>투표문답</MenuItem>
                    <MenuItem value={3}>자유잡담</MenuItem>
                    <MenuItem value={4}>일러스트(라이징스타)</MenuItem>
                </TextField>
            </Tooltip>
            <Tooltip title='Delete'>
                <Button
                    className={classes.buttonPadding}
                    disabled={loading}
                    onClick={handleMovePosts}
                    aria-label='delete'
                    color='info'
                >
                    게시물 이동하기
                </Button>
            </Tooltip>*/}
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color='inherit'
                    variant='subtitle1'
                    component='div'
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant='h6'
                    id='tableTitle'
                    component='div'
                ></Typography>
            )}


            <Tooltip title='Delete'>
                <Button
                    disabled={loading}
                    className={classes.mobileHidden}
                    onClick={() => {
                        getListPostManagingExcel();
                    }}
                    aria-label='delete'
                    color='info'
                >
                    엑셀 다운로드
                </Button>
            </Tooltip>

            <Tooltip title='Delete'>
                <Button
                    disabled={loading}
                    className={classes.mobileHidden}
                    onClick={() => {
                        location.href = '/admin/post-talk'
                    }}
                    aria-label='delete'
                    color='info'
                >
                    꾸밍톡 등록하기
                </Button>
            </Tooltip>

            <Tooltip title='Delete'>
                <Button
                    className={classes.buttonPadding}
                    disabled={loading}
                    onClick={handleDeletePost}
                    aria-label='delete'
                    color='delete'
                >
                    삭제하기
                </Button>
            </Tooltip>
        </Toolbar>
    )
}

const EnhancedTableHead = (props) => {
    const {classes, order, orderBy, onRequestSort, headCells} = props

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'></TableCell>
                {headCells.map((headCell, i) => (
                    (window.innerWidth < '768') ? (
                            <TableCell
                                key={headCell.id}
                                classesName={classes.mobileWidth}>
                                &nbsp;&nbsp;
                            </TableCell>
                        ) :
                        (<TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            classesName={classes.minWidth}
                            classesName={classes.maxWidth}
                        >
                            <TableSortLabel
                                active={headCell.allowSortable}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={
                                    headCell.allowSortable
                                        ? createSortHandler(headCell.id)
                                        : undefined
                                }
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>)
                ))}
            </TableRow>
        </TableHead>
    )
}

const CustomTable = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const {
        rows,
        headCells,
        setFormData,
        formData,
        totalRecords,
        postManagingApi,
        deletePostAction,
        movePostAction,
        movePostPushAction,
        postManagingErrAction,
        setChangePostInfoId,
        getListPostManagingExcel
    } = props

    const [selected, setSelected] = React.useState([])
    const [isPreventOnRowClick, setIsPreventOnRowClick] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [alert, setAlert] = React.useState(null)
    const [movePostInfoId, setMovePostInfoId] = React.useState(0)

    const hideAlert = () => {
        setAlert(null)
    }

    const handleChangePage = (event, newPage) => {
        setFormData({
            ...formData,
            offset: newPage + 1
        })
        // setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setFormData({
            ...formData,
            limit: parseInt(event.target.value),
            rowsPerPage: parseInt(event.target.value, 10),
            offset: 1
        })
    }

    const handleRequestSort = (event, property) => {
        const isAsc = formData.orderBy === property && formData.order === 'asc'
        setFormData({
            ...formData,
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    }

    const handleOnRowClick = (row) => {
        if (isPreventOnRowClick) return
        history.push({
            pathname: '/admin/post-detail/' + row.id,
            state: {postId: row.id},
        })
    }

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        setSelected(newSelected)
    }

    const downdloadFile = async (id, title, nickname) => {

        const files = await postManagingApi.getFileList({postId: id})

        fileDownload(files, 'fileList_' + id + '_' + title + '_' + nickname + ".zip")

    }

    const isSelected = (id) => selected.indexOf(id) !== -1

    const handleOnMouseEnter = (e) => {
        setIsPreventOnRowClick(true)
    }

    const handleOnMouseLeave = (e) => {
        setIsPreventOnRowClick(false)
    }

    const handleDeletePost = async (id) => {
        setAlert(
            <Modal
                hideAlert={hideAlert}
                postIds={selected.length > 0 ? selected : [id]}
                postManagingApi={postManagingApi}
                deletePostAction={deletePostAction}
                movePostAction={movePostAction}
                postManagingErrAction={postManagingErrAction}
                setSelected={setSelected}
                type={'D'}
            />,
        )
    }
    const handleMovePushPosts = async (id) => {
        // alert("Dfddm handleMovePushPosts")
        setAlert(
            <Modal
                hideAlert={hideAlert}
                postIds={selected.length > 0 ? selected : [id]}
                postInfoId={movePostInfoId}
                postManagingApi={postManagingApi}
                movePostPushAction={movePostPushAction}
                postManagingErrAction={postManagingErrAction}
                setSelected={setSelected}
                type={'P'}
            />,
        )
    }

    const handleMovePosts = async (id) => {
        setAlert(
            <Modal
                hideAlert={hideAlert}
                postIds={selected.length > 0 ? selected : [id]}
                postInfoId={movePostInfoId}
                postManagingApi={postManagingApi}
                deletePostAction={deletePostAction}
                movePostAction={movePostAction}
                postManagingErrAction={postManagingErrAction}
                handleMovePushPosts={handleMovePushPosts}
                setSelected={setSelected}
                type={'M'}
            />,
        )
    }
    return (
        <TableContainer component={Paper}>
            {alert}
            <EnhancedTableToolbar
                numSelected={selected.length}
                handleDeletePost={handleDeletePost}
                handleMovePosts={handleMovePosts}
                handleMovePushPosts={handleMovePushPosts}
                setMovePostInfoId={setMovePostInfoId}
                movePostInfoId={movePostInfoId}
                getListPostManagingExcel={getListPostManagingExcel}
                loading={loading}
            />
            <Table
                disabled={true}
                className={classes.table}
                aria-label='simple table'
                size='small'
            >
                <EnhancedTableHead
                    classes={classes}
                    order={formData?.order}
                    orderBy={formData?.orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headCells}
                />

                <TableBody>
                    {rows.map((row, index) => {
                        const isItemSelected = isSelected(row?.id)
                        const labelId = `enhanced-table-checkbox-${index}`

                        return (
                            <TableRow
                                hover
                                role='checkbox'
                                aria-checked={isItemSelected}
                                key={index}
                            >
                                <TableCell padding='checkbox'>
                                    <Checkbox
                                        onClick={(event) => handleClick(event, row?.id)}
                                        checked={isItemSelected}
                                        inputProps={{'aria-labelledby': labelId}}
                                        onMouseEnter={handleOnMouseEnter}
                                        onMouseLeave={handleOnMouseLeave}
                                    />
                                </TableCell>
                                {window.innerWidth > '768' &&
                                    (<TableCell align='left' className={classes.mobileHidden}>
                                        {row && row.id}

                                        {row.isKkommingTalk && (<span style={{
                                            background: '#000',
                                            color: '#fff',
                                            'borderRadius': '5px',
                                            padding: '5px',
                                            'margin-left': '10px'
                                        }}>꾸밍톡</span>)}
                                        {/*row.isNotiPostrow.isNotiPostrow.isNotiPostrow.isNotiPostrow.isNotiPostrow.isNotiPostrow.isNotiPostrow.isNotiPostrow.isNotiPost*/}
                                        {/*{row.isNotiPost}*/}
                                        {row.isNotiPost && (<span style={{
                                            background: '#F361A6',
                                            color: '#fff',
                                            'borderRadius': '5px',
                                            padding: '5px',
                                            'margin-left': '10px'
                                        }}>알</span>)}


                                    </TableCell>)}
                                <TableCell align='right' className={classes.cellWidth}>
                                    {row.postInfoId == 1 && (<span style={{
                                        background: '#fc876f',
                                        color: '#fff',
                                        'borderRadius': '5px',
                                        padding: '5px'
                                    }}>꾸미기(피드)</span>)}
                                    {row.postInfoId == 2 && (<span style={{
                                        background: '#6799FF',
                                        color: '#fff',
                                        'borderRadius': '5px',
                                        padding: '5px'
                                    }}>투표문답</span>)}
                                    {row.postInfoId == 3 && (<span style={{
                                        background: '#BCE55C',
                                        color: '#fff',
                                        'borderRadius': '5px',
                                        padding: '5px'
                                    }}>자유잡담</span>)}
                                    {row.postInfoId == 4 && (<span style={{
                                        background: '#FFE08C',
                                        color: '#fff',
                                        'borderRadius': '5px',
                                        padding: '5px'
                                    }}>일러스트(라이징스타)</span>)}
                                </TableCell>

                                <TableCell align='right'>
                                    <div>
                                        <img
                                            width='87px'
                                            height='87px'
                                            style={{objectFit: 'cover'}}
                                            src={row?.thumbnailImage}
                                            onClick={(e) => handleOnRowClick(row)}
                                            alt='...'
                                        />
                                        <button onClick={(e) => {
                                            downdloadFile(row?.id, row?.title, row?.owner.nickname)
                                        }
                                        }>
                                            파일 리스트
                                        </button>
                                    </div>
                                </TableCell>
                                <TableCell align='right'>
                                    {row && row.title}
                                    {row.postInfoId == 2 ? (
                                        <span style={{color: '#fc876f'}}><br/>참여자수: {row && row.totalApplyCount}</span>
                                    ) : null}
                                </TableCell>

                                <TableCell align='right'>{row && row.totalLikes}</TableCell>

                                <TableCell align='right'>{row && row.totalScraps}</TableCell>

                                <TableCell align='right'>{row && row.totalViews}</TableCell>

                                <TableCell align='right'>
                                    {moment(row && row.createdAt).format('YYYY/MM/DD h:mmA')}
                                </TableCell>

                                <TableCell align='right'>
                                    <IconButton
                                        onMouseEnter={handleOnMouseEnter}
                                        onMouseLeave={handleOnMouseLeave}
                                        onClick={(e) => handleDeletePost(row?.id)}
                                    >
                                        <HighlightOffIcon/>
                                    </IconButton>
                                </TableCell>

                                <TableCell align='right'>
                                    ID:&nbsp;{row && row.owner && row.owner.memberID} <br/>
                                    {row && row.owner && row.owner.nickname}
                                </TableCell>
                                <TableCell align='right'>
                                    <Box className={classes.styleButtonSubmit}>
                                        <Button
                                            color='info'
                                            onClick={(e) => handleOnRowClick(row)}
                                        >
                                            상세
                                        </Button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[
                                10,
                                30,
                                50,
                                100,
                                {label: 'All', value: totalRecords},
                            ]}
                            colSpan={9}
                            count={totalRecords ? totalRecords : 0}
                            rowsPerPage={formData?.rowsPerPage}
                            page={formData?.offset - 1}
                            SelectProps={{
                                inputProps: {'aria-label': 'rows per page'},
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}

export default React.memo(CustomTable)
