import React, {useEffect} from 'react'

import {makeStyles} from '@material-ui/core/styles'
import GridContainer from 'components/Grid/GridContainer.js'
import TextField from 'components/Gm-TextField/TextField'
import GridItem from 'components/Grid/GridItem.js'
import Box from '@material-ui/core/Box'
import Button from 'components/CustomButtons/Button'

// import VersionManagingApi from 'api/VersionManagingApi'
import styles from 'assets/jss/material-dashboard-pro-react/views/UserManaging/userManaging'

import {connect, useDispatch} from "react-redux";
import {
    getListVersionAction,
    requestVersionErrorAction,
    requestVersionManagingAction
} from "../../redux/actions/versionManagingAction";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import VersionTable from "../Setting/components/VersionTables";
import versionManangingApi from "../../api/versionManangingApi";
import {set} from "lodash/object";
// const useStyles = makeStyles(styles)

const useStyles = makeStyles(styles)
const VersionManaging = (props) => {
    // const classes = useStyles()
    const dispatch = useDispatch()
    const {
        loading,
        versions,
        totalVersions,
        paginationVersionsManaging,

        versionDetail,
        error,
        metaData: { totalRecords },
    } = props

    const [formData, setFormData] = React.useState({
        searchKey: 'version',
        searchText: '',
        page: 0,
        order: 'desc',
    })
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [page, setPage] = React.useState(0)
    const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('')

//------- handle form
    const handleForm = (value, keys =null) => {
        // if (e != null){
        //     if (keys == null) keys = e.target.name
            setFormData({ ...formData, [keys]: value })
        // }
    }
    const classes = useStyles()

    const headCells = [

        {
            id: 'version',
            numeric: false,
            disablePadding: false,
            label: '버전',
            allowSortable: false,
        },
        {
            id: 'os',
            numeric: false,
            disablePadding: false,
            label: 'os',
            allowSortable: false,
        },
        {
            id: 'description',
            numeric: false,
            disablePadding: false,
            label: '설명',
            allowSortable: false,
        },
        {
            id: 'forceUpdate',
            numeric: false,
            disablePadding: false,
            label: '강제업데이트 여부',
            allowSortable: false,
        },
        {
            id: 'createdAt',
            numeric: false,
            disablePadding: false,
            label: '생성일',
            allowSortable: false,
        },
        {
            id: 'isDelete',
            numeric: false,
            disablePadding: false,
            label: '삭제 여부',
            allowSortable: false,
        },
    ]

    const getListVersion = async () => {

        let orderString = '';

        //if (orderBy == 'first') {
        //처음 정렬시 현재 front에 나오는 표출 순서대로
        orderString = 'version,DESC';
        // } else {
        //   orderString = orderBy + ',' + order
        // }


        let params = {
            limit: 10000,
            offset: 0,
            // type: 'all',
            order: orderString,
            searchText: '',
            searchKey: formData.searchKey,
        }

        try {
            //requestEventsAction()
            const {data} = await versionManangingApi.getListVersions(params)
            dispatch(getListVersionAction(data))
            // setEventList(data.rows)
        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                // noticesWithErrAction(error.response.data)
                console.log(error.response.data)
            }
        }

    }

    useEffect(() => {
        getListVersion()

    }, [page, order])

    return (
        <div className='VersionManaging'>
            <GridContainer alignItems='center'>
                <GridItem
                    className={classes.gridContainerOne}
                    xs={12}
                    sm={4}
                    md={3}
                    lg={2}
                    xl={2}
                >
                    <TextField
                        id='writer-managing-select'
                        select
                        size='small'
                        className={classes.textFieldOne}
                        label='검색타입'
                        value={formData?.searchKey}
                        onChange={(e)=>{
                            handleForm(e.target.value, "searchKey");
                        }}
                    >
                        <MenuItem value={'version'}>버전</MenuItem>
                        <MenuItem value={'description'}>설명</MenuItem>
                        <MenuItem value={'os'}>os</MenuItem>
                        {/*<MenuItem value={'linkShareName'}>사이트명</MenuItem>*/}
                        {/*<MenuItem value={'BLOCKED'}>차단</MenuItem>*/}
                        {/*<MenuItem value={'BLOCKED'}>차단</MenuItem>*/}
                    </TextField>
                </GridItem>
                <GridItem xs={12} sm={6} md={5} lg={4} xl={4}>
                    <TextField
                        id='post-managing-textfield'
                        size='small'
                        className={classes.textFieldOne}
                        placeholder='검색어를 입력해주세요'
                        name='searchText'
                        value={formData?.searchText}
                        onChange={(e)=>{
                            handleForm(e.target.value, "searchText");
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </GridItem>

                <GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
                    <Button
                        color='primary'
                        disabled={loading}
                        onClick={() => {
                            setPage(0)
                            getListVersion()
                        }}
                    >
                        검색
                    </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{'text-align': 'end'}}>
                    <Button
                        color='success'
                        onClick={(e)=>{
                            location.href = '/admin/version-managing/detail'
                        }}
                    >
                        등록
                    </Button>
                </GridItem>
            </GridContainer>

            <Box mb={2}>
                {loading ? (
                    <CircularProgress size={30} className={classes.buttonProgress} />
                ) : (
                    <VersionTable
                        headCells={headCells}
                        rows={versions}
                        totalRecords={totalRecords}
                        setRowsPerPage={setRowsPerPage}
                        rowsPerPage={rowsPerPage}
                        setPage={setPage}
                        page={page}
                        setOrder={setOrder}
                        order={order}
                        setOrderBy={setOrderBy}
                        orderBy={orderBy}
                        getListVersion={getListVersion}
                    />
                )}
            </Box>

        </div>
    )
}


const mapStateToProps = (state) => ({
    loading: state.versionManagingReducer.loading,
    versions: state.versionManagingReducer.versions,
    metaData: state.versionManagingReducer.metaData,
    // totalWriter: state.versionManagingReducer.totalWriter,
    // totalWriterBySearch: state.versionManagingReducer.totalWriterBySearch,
    // paginationVersionManaging: state.versionManagingReducer.paginationUserManaging,
})

export default connect(mapStateToProps, {
    requestVersionManagingAction,
    getListVersionAction,
    requestVersionErrorAction,
    // toggleWriterExposureAction,
    // toggleWriterIsSyncAction
})(VersionManaging)

