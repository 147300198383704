import axiosInterceptors from "./axios";


const codeManagingApi = {
    getNewCode: (params) => {
        const url = `/codeManaging/getNewCode`
        return axiosInterceptors.get(url, { params })
    },
    getCodeList: (params) => {
        const url = `/codeManaging/getCodeList`
        return axiosInterceptors.get(url, { params })
    },
    getHottrakcsCodeAndKKoommingCode: (params) => {
        const url = `/codeManaging/exportFileExceltHottrakcsCodeAndKKoommingCode`
        return axiosInterceptors.get(url, {
            responseType: 'blob',
        })
    },
    getChildCodeList: (params) => {
        const url = `/codeManaging/getCodeList`
        return axiosInterceptors.get(url, { params })
    },
    createCode: (params) => {
        const url = `/codeManaging/createCode`
        return axiosInterceptors.post(url, { params })
    },
    updateCode: (params) => {
        const url = `/codeManaging/updateCode`
        return axiosInterceptors.put(url, { params })
    },

    updateOrderCode: (params) => {
        const url = '/codeManaging/updateOrderCode'
        return axiosInterceptors.put(url, { params })
    },
    main: (params) => {
        const url = `/other/all2.3`
        return axiosInterceptors.get(url, { params })
    }

}

export default codeManagingApi