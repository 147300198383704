import {connect, useDispatch} from "react-redux";
import React, {useEffect} from "react";
import moment from "moment/moment";
import phrasesSettingReducer from "../../redux/reducers/phrasesSettingReducer";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Box from "@material-ui/core/Box";
import Spinner from "../../components/Spinner/Spinner";
import TextField from "../../components/Gm-TextField/TextField";


import styles from 'assets/jss/material-dashboard-pro-react/views/Setting/setting'
import {makeStyles} from "@material-ui/core/styles";
import CustomButton from "../../components/CustomButtons/Button";
import PhraseSettingApi from "../../api/phraseSettingApi";
import phraseSettingApi from "../../api/phraseSettingApi";
import managerManagingApi from "../../api/managerManagingApi";
import {getListManagerManagingAction, managerManagingRequestError} from "../../redux/actions/managerManagingAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import PushTables from "../PushManaging/components/PushTables";
import StickerPhraseTable from "./components/StickerPhraseTable";
import {getListPhraseSettingsAction} from "../../redux/actions/phrasesSettingAction";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {da} from "date-fns/locale";
import {handleFormValue} from "../../util/common";


/**
 * 문구 관리 페이지(지금은 로그인만 쓰임)
 * @param props
 * @constructor
 */
const PhrasesSetting = (props) =>{

    // const classes = useStyles()
    const dispatch = useDispatch()
    const useStyles = makeStyles(styles)
    const classes = useStyles()

    const {
        metaData: {totalPages, totalRecords},
        phrases,
        requestPhraseAction
    } = props

    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [searchText, setSearchText] = React.useState('')

    //-------------- state 세팅
    const [loading, setLoading] = React.useState(false)
    // TOP_UPPER
    // TOP_LOWER
    // COMMENT
    const [phrase, setPhrase] = React.useState({
        phrase: ''
    })
    const [boldPhrase, setBoldPhrase] = React.useState({
        phrase: ''
    })
    const [phraseList, setPhraseList] = React.useState([])

    const headCells = [
        {
            id: 'id',
            align: 'left',
            disablePadding: false,
            label: 'No.',
            // width: '50',
            // minWidth: '50',
            allowSortable: true,
        },
        {
            id: 'text',
            // align: 'left',
            disablePadding: false,
            label: '문구',
            // minWidth: '340',
            allowSortable: true,
        },
        {
            id: 'bold',
            // align: 'left',
            disablePadding: false,
            label: '볼드 문구',
            // minWidth: '340',
            allowSortable: true,
        },
        {
            id: 'createdAt',
            // align: 'center',구
            disablePadding: false,
            label: '생성일자',
            // minWidth: '340',
            allowSortable: true,
        },
    ]




    //저장
    const createForm = async (e, keys = null) => {

        if (e != null) {
            const {data} = await phraseSettingApi.settingPhrase({
                phrase: phrase.phrase,
                boldPhrase: phrase.boldPhrase,
                position: 'HOME_STICKER'
            })
            if(data){
                alert("저장되었습니다.")
                location.reload()
            }
        }
    }

    //./ handle form ------

    const getPhraseSettings = async () =>{

        try {
            setLoading(true)
            const { data } = await phraseSettingApi.getPhraseList({type: 'HOME_STICKER'})
            // dispatch()

            setPhrase(data.phrase)
            // setPhraseList(data.phraseList)
            dispatch(getListPhraseSettingsAction(data))


            // dispatch(getListManagerManagingAction(data))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            dispatch(managerManagingRequestError(error?.response?.data))
        }
    }


    useEffect(()=>{
        getPhraseSettings()
    }, [])


    return (
        <div className='phrasesSetting-managing'>
            <Box mb={4} className='phrase-1'>
                <Typography variant='h6' component='h6' gutterBottom>
                    타이틀 문구
                </Typography>
                <Paper
                    className={`${classes.paperCommon} ${classes.setMarginBottom}`}
                    variant='outlined'
                >
                    <GridContainer alignItems='center'>
                        <GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
                            <Typography component='p'>볼드 문구</Typography>
                        </GridItem>
                        <GridItem xs={7} sm={6} md={5} lg={5} xl={5}>
                            <Box className={classes.setPositionRelative}>
                                <TextareaAutosize
                                    aria-label='minimum height'
                                    minRows={3}
                                    maxRows={5}
                                    style={{width: '100%', minHeight: '70px', maxHeight: '100px'}}
                                    name='boldPhrase'
                                    value={phrase?.boldPhrase}
                                    onChange={(event)=>handleFormValue(event.target.value, 'boldPhrase', phrase, setPhrase)}
                                />
                            </Box>
                        </GridItem>

                        <GridItem xs={2} sm={2} md={2} lg={2} xl={2}>
                            <CustomButton color="primary" mr={2} variant="contained"
                                          onClick={(event) => createForm(event,'boldPhrase')}
                            >수정</CustomButton>
                        </GridItem>
                    </GridContainer>
                </Paper>
                <Paper
                    className={`${classes.paperCommon} ${classes.setMarginBottom}`}
                    variant='outlined'
                >
                    <GridContainer alignItems='center'>
                        <GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
                            <Typography component='p'>문구</Typography>
                        </GridItem>
                        <GridItem xs={7} sm={6} md={5} lg={5} xl={5}>
                            <Box className={classes.setPositionRelative}>
                                <Box className={classes.setPositionRelative}>
                                    <TextareaAutosize
                                        aria-label='minimum height'
                                        minRows={3}
                                        maxRows={5}
                                        style={{width: '100%', minHeight: '70px', maxHeight: '100px'}}
                                        name='phrase'
                                        value={phrase?.phrase}
                                        onChange={(event)=>handleFormValue(event.target.value, 'phrase', phrase, setPhrase)}
                                    />
                                </Box>
                            </Box>
                        </GridItem>

                        <GridItem xs={2} sm={2} md={2} lg={2} xl={2}>
                            <CustomButton color="primary" mr={2} variant="contained"
                                          onClick={(event) => createForm(event)}
                            >수정</CustomButton>
                        </GridItem>
                    </GridContainer>
                </Paper>
            </Box>
            <GridContainer>
                <GridItem xs={12}>
                    {/*발송시간 전에만 수정 가능*/}
                    {/*현재 시간 이전에는 발송 불가능*/}
                    <Box className={classes.boxTableBlock} my={2}>
                        {loading ? (
                            <CircularProgress size={30} className={classes.buttonProgress} />
                        ) : (
                            <StickerPhraseTable
                                rows={phrases}
                                totalPages={totalPages}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                page={page}
                                setPage={setPage}
                                setLimit={setLimit}
                                headCells={headCells}
                                classes={useStyles}
                                /*order={order}
                                setOrderBy={setOrderBy}
                                setOrder={setOrder}
                                orderBy={orderBy}*/
                                // updateEvents={updateEvents}
                                // setSelected={setSelected}
                                // selected={selected}

                            />
                        )
                        }
                    </Box>
                </GridItem>
            </GridContainer>
        </div>
    )

}

const mapStateToProps = (state) => ({
    loading: state.phrasesSettingReducer.loading,
    error: state.phrasesSettingReducer.error,
    phrases: state.phrasesSettingReducer.phrases,
    metaData: state.phrasesSettingReducer.metaData,
})
export default connect(mapStateToProps, {
    getListPhraseSettingsAction

})(PhrasesSetting)

