import * as actionTypes from './types'
import {CHANGE_WRITER_EXPOSUER} from "./types";

export const requestWriterManagingAction = () => {
	return {
		type: actionTypes.REQUEST_WRITER_MANAGING,
	}
}

export const getListWriterAction = (payload) => {
	return {
		type: actionTypes.GET_LIST_WRITER,
		payload: payload,
	}
}
export const toggleWriterExposureAction = (payload) => {
	return {
		type: actionTypes.CHANGE_WRITER_EXPOSUER,
		payload: payload,
	}
}
export const changeWriterUserIDAction = (payload) => {
	return {
		type: actionTypes.CHANGE_WRITER_USERID,
		payload: payload,
	}
}
export const toggleWriterIsSyncAction = (payload) => {
	return {
		type: actionTypes.CHANGE_WRITER_IS_SYNC,
		payload: payload,
	}
}

export const setPaginationWriterManagingAction = (payload) => {
	return {
		type: actionTypes.SET_PAGINATION_WRITERMANAGING,
		payload: payload,
	}
}

export const getUserDetailAction = (payload) => {
	return {
		type: actionTypes.GET_USER_DETAIL,
		payload: payload,
	}
}

export const userChangeStateAction = (payload) => {
	return {
		type: actionTypes.USER_CHANGE_STATE,
		payload: payload,
	}
}

export const toggleRecommendUserAction = (payload) => {
	return {
		type: actionTypes.TOGGLE_RECOMMEND_USER,
		payload: payload,
	}
}

export const getListReportedInUserDetailAction = (payload) => {
	return {
		type: actionTypes.GET_LIST_REPORTED_IN_USER_DETAIL,
		payload: payload,
	}
}

export const requestWriterManagingErrorAction = (payload) => {
	return {
		type: actionTypes.REQUEST_WRITER_MANAGING_ERROR,
		payload: payload,
	}
}
