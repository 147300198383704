import * as actionTypes from './types'
import {SET_AI_COMMENT_LIST} from "./types";


export const getAiCommentListAction = (payload) => ({
    type: actionTypes.GET_AI_COMMENT_LIST_ACTION,
    payload,
})
export const setAiPhraseAction = (payload) => ({
    type: actionTypes.SET_AI_PHRASE,
    payload,
})


export const setAiManagingAction = (payload) => ({
    type: actionTypes.SET_AI_COMMENT_LIST,
    payload,
})
