import * as actionTypes from '../actions/types'

const initialState = {
    loading: false,
    schedules: [],
    metaData: {
        totalPages: 1,
    },
    totalWriterSchedule: 0,
    totalWriterScheduleBySearch: 0,
    paginationWriterManaging: 1,

    // user-detail
    writerDetail: null,
    error: null,
    listReportedInWriterDetail: [],
    metaDataForListReportedInWriterDetail: {
        totalPages: 1,
    },
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case actionTypes.REQUEST_WRITER_SCHEDULES_MANAGING:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.GET_LIST_WRITER_SCHEDULES:
            const {
                schedules,
                metaData,
                totalWriterSchedule,
                totalWriterScheduleBySearch,
                hasClientIdData,
            } = payload

            const resetTotalUserBySearchToZeroIfHasClientIdEqualFalse = hasClientIdData
                ? state.totalWriterScheduleBySearch
                : 0
            return {
                ...state,
                loading: false,
                error: null,
                schedules: schedules,
                metaData,
                totalWriterSchedule: totalWriterSchedule !== null ? totalWriterSchedule : state.totalWriterSchedule,
                totalWriterScheduleBySearch:
                    totalWriterScheduleBySearch !== null
                        ? totalWriterScheduleBySearch
                        : resetTotalUserBySearchToZeroIfHasClientIdEqualFalse,
            }
        case actionTypes.SET_PAGINATION_WRITERSCHEDULESMANAGING:
            return {
                ...state,
                loading: false,
                error: null,
                paginationWriterManaging: payload,
            }
        case actionTypes.REQUEST_WRITER_SCHEDULES_MANAGING_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        default:
            return state
    }
}
