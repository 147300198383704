import React from 'react'
import fileDownload from 'js-file-download'
import queryString from 'query-string'

import { makeStyles } from '@material-ui/core/styles'
import TextField from 'components/Gm-TextField/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import GridContainer from 'components/Grid/GridContainer.js'
import InputAdornment from '@material-ui/core/InputAdornment'
import Box from '@material-ui/core/Box'
import SearchIcon from '@material-ui/icons/Search'
import GridItem from 'components/Grid/GridItem.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from 'components/CustomButtons/Button.js'
import Table from './components/Table'

import { connect } from 'react-redux'
import {
  requestUserManagingAction,
  getListUserAction,
  requestUserManagingErrorAction,
} from 'redux/actions/userManagingAction'
import userManagingApi from 'api/userManagingApi'

import styles from 'assets/jss/material-dashboard-pro-react/views/UserManaging/userManaging'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {FiberManualRecord} from "@material-ui/icons";
const useStyles = makeStyles(styles)

const UserManaging = (props) => {
  const {
    requestUserManagingAction,
    getListUserAction,
    requestUserManagingErrorAction,
    loading,
    users,
    totalUser,
    realTotalUser,
    totalJoinCount,
    totalDeActivatedCount,
    totalUserBySearch,
    metaData: { totalRecords },
  } = props

  const classes = useStyles()

  const [loadingBtnGetExcel, setLoadingBtnGetExcel] = React.useState(false)
  const [select, setSelect] = React.useState('NORMAL')
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('')
  const [clientId, setClientId] = React.useState('')
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [isResetPagination, setIsResetPagination] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [searchKey, setSearchKey] = React.useState('nickname')
  const [searchBadge, setSearchBadge] = React.useState('ALL')
  const [searchText, setSearchText] = React.useState('')


  const handleChangeSearchInput = (e) => {
    setClientId(e.target.value)
    !isResetPagination && setIsResetPagination(true)
  }

  const handleChangeSelect = (event) => {
    setSelect(event.target.value)
    !isResetPagination && setIsResetPagination(true)
  }

  const headCells = [
    {
      id: 'memberID',
      numeric: false,
      disablePadding: false,
      label: '고객번호',
      allowSortable: false,
    },
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      label: '아이디',
      allowSortable: false,
    },
    {
      id: 'avatar',
      numeric: true,
      disablePadding: false,
      label: '프로필',
      allowSortable: false,
    },
    {
      id: 'ACCOUNT_TYPE',
      numeric: true,
      disablePadding: false,
      label: '로그인타입',
      allowSortable: false,
    },
    {
      id: 'userBirth',
      numeric: true,
      disablePadding: false,
      label: '생년월일',
      allowSortable: false,
    },
    {
      id: 'nickname',
      numeric: true,
      disablePadding: false,
      label: '닉네임',
      allowSortable: false,
    },
    {
      id: 'TOTAL_FOLLOWER',
      numeric: true,
      disablePadding: false,
      label: '팔로워수',
      allowSortable: true,
    },
    {
      id: 'TOTAL_FOLLOWING',
      numeric: true,
      disablePadding: false,
      label: '팔로잉수',
      allowSortable: true,
    },
    {
      id: 'TOTAL_REPORTED',
      numeric: true,
      disablePadding: false,
      label: '신고받은횟수',
      allowSortable: true,
    },
    {
      id: 'access',
      numeric: true,
      disablePadding: false,
      label: '접근 처리 현황',
      allowSortable: false,
    },
    {
      id: 'DATE_SIGN_UP',
      numeric: true,
      disablePadding: false,
      label: '가입일',
      allowSortable: true,
    },
    {
      id: 'DATE_ACCESS_APP',
      numeric: true,
      disablePadding: false,
      label: '최근 접속일',
      allowSortable: true,
    },
    {
      id: 'goto-detail-page',
      numeric: true,
      disablePadding: false,
      label: '',
      allowSortable: false,
    },
  ]
  const getListUsers = async () => {
    let orderString = '';

    if (orderBy == 'first') {
      //처음 정렬시 현재 front에 나오는 표출 순서대로
      orderString = 'numOrder,DESC&createdAt,DESC';
    } else {
      orderString = orderBy + ',' + order
    }

    try {
      requestUserManagingAction()
      const params = {
        limit: rowsPerPage,
        offset: page + 1,
        order: 'DESC',
        orderBy: 'DATE_SIGN_UP',
        searchKey: searchKey,
        searchText: clientId,
        searchBadge: searchBadge
        // userStatus: select,
        // clientId,
      }


      const { data } = await userManagingApi.getListUsers2(params)

      getListUserAction(data)
      // setMetaData(data.metaData)
      // dispatch(getListUserAction(data))
    } catch (error) {
      if (error && error.response && error.response.data) {
        // requestUserManagingErrorAction(error.response.data)
      }
    }
  }
  const getExcelFileUserManaging = async () => {
    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    try {
      setLoadingBtnGetExcel(true)
      const params = {
        order: order.toUpperCase(),
        userStatus: select,
        clientId,
        localTimeZone: tz
      }

      if (orderBy) {
        params['orderBy'] = orderBy
      }

      !clientId && delete params.clientId
      const convertParamsToQueryUrl = queryString.stringify(params)

      const data = await userManagingApi.getExcelFileUserManaging(
        convertParamsToQueryUrl,
      )
      fileDownload(data, 'data.xlsx')
      setLoadingBtnGetExcel(false)
    } catch (error) {
      setLoadingBtnGetExcel(false)
      if (error && error.response && error.response.data) {
        requestUserManagingErrorAction(error.response.data)
      }
    }
  }
  const handleSearchKey = ( valuwe) => {
    setSearchKey(valuwe)
    setOrder('asc')
  }
  React.useEffect(() => {
    getListUsers()
  }, [page, rowsPerPage, order])

  return (
    <div className='user-managing'>
      <GridContainer alignItems='center'>
        <GridItem
          className={classes.gridContainerOne}
          xs={12}
          sm={4}
          md={3}
          lg={2}
          xl={2}
        >
          <TextField
            id='user-managing-select'
            select
            size='small'
            className={classes.textFieldOne}
            label='접근 처리 현황'
            value={select}
            onChange={handleChangeSelect}
          >
            <MenuItem value={'NORMAL'}>정상</MenuItem>
            <MenuItem value={'BLOCKED'}>차단</MenuItem>
            <MenuItem value={'DEACTIVATED'}>탈퇴</MenuItem>
          </TextField>
        </GridItem>
        <GridItem xs={2} sm={2} md={2}>
          {/*{formData.type}*/}
          <TextField
              id='push-managing-select'
              select
              size='small'
              className={classes.widthTextFieldModal}
              value={searchKey}
              onChange={(e) => {
                // handelS
                // setSearchKey(e.target.value)
                handleSearchKey(e.target.value)
              }}
              label='검색 타입'
              style={{width: '100%'}}
          >
            <MenuItem value={'nickname'}>닉네임</MenuItem>
            <MenuItem value={'clientId'}>고객 번호</MenuItem>
            <MenuItem value={'memberId'}>아이디</MenuItem>
          </TextField>
        </GridItem>


        <GridItem xs={12} sm={6} md={5} lg={4} xl={4}>
          <TextField
            id='post-managing-textfield'
            size='small'
            className={classes.textFieldOne}
            placeholder='검색어를 입력해주세요'
            name='clientId'
            value={clientId}
            onChange={handleChangeSearchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
          <Button
            color='primary'
            disabled={loading}
            onClick={() => {

              getListUsers()
              setPage(0)
            }}
          >
            검색
          </Button>
        </GridItem>
      </GridContainer>


      {/*뱃지 소유 여부*/}
      <GridContainer>
        <GridItem xs={1} sm={1} md={1} className={classes.lableSt}
                  style={{'margin': 'auto', 'padding': '0px'}}
                  className={classes.responsiveStyle}>
          뱃지 소유 여부
        </GridItem>
        <GridItem xs={11} sm={11} md={11} style={{padding: '20px !important'}}>
          <FormControlLabel
              control={
                <Radio
                    checked={searchBadge == 'ALL'}
                    onChange={(e) => setSearchBadge('ALL')}
                    value={'0'}
                    aria-label="T"
                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked}/>
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot
                    }}
                />
              }
              classes={{
                label: classes.p0
              }}
              label="전체"
          />
          <FormControlLabel
              control={
                <Radio
                    checked={searchBadge == 'DAKKUZZANG'}
                    onChange={(e) => setSearchBadge('DAKKUZZANG')}
                    value={'3'}
                    aria-label="T"
                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked}/>
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot
                    }}
                />
              }
              classes={{
                label: classes.label
              }}
              label="다꾸짱"
          />
          <FormControlLabel
              control={
                <Radio
                    checked={searchBadge == 'KKOOMMINGFAM'}
                    onChange={(e) => setSearchBadge('KKOOMMINGFAM')}
                    value={'4'}
                    aria-label="T"
                    icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked}/>
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot
                    }}
                />
              }
              classes={{
                label: classes.label
              }}
              label="꾸밍팸"
          />
        </GridItem>


      </GridContainer>

      <Box my={3}>
        <TextField
            className={`${classes.textFieldTwo} ${classes.textFieldTwoChildOne}`}
            id='post-managing-textfield-show-info1'
            style={{'width': '200px'}}
            size='small'
            value={totalJoinCount}
            InputProps={{
              startAdornment: (
                  <InputAdornment position='start'>누적회원가입수(탈퇴포함)</InputAdornment>
              ),
              readOnly: true,
            }}
        />
        <TextField
          className={`${classes.textFieldTwo} ${classes.textFieldTwoChildOne}`}
          id='post-managing-textfield-show-info1'
          size='small'
          value={totalDeActivatedCount}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>누적탈퇴수</InputAdornment>
            ),
            readOnly: true,
          }}
        />
        <TextField
          className={classes.textFieldTwo}
          id='post-managing-textfield-show-info2'
          size='small'
          value={totalRecords}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>검색</InputAdornment>
            ),
            readOnly: true,
          }}
        />
      </Box>

      {/*작동 안함+ISMS이슈로 삭제*/}
      {/*<Box className={classes.btnGetExcelAndPaginationTable} mb={2}>
        <Button
          color='primary'
          disabled={loadingBtnGetExcel}
          onClick={getExcelFileUserManaging}
        >
          엑셀 다운로드
        </Button>
      </Box>*/}

      <Box mb={2}>
        {loading ? (
          <CircularProgress size={30} className={classes.buttonProgress} />
        ) : (
          <Table
            headCells={headCells}
            rows={users}
            totalRecords={totalRecords}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            page={page}
            setOrder={setOrder}
            order={order}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
          />
        )}
      </Box>
    </div>
  )
}

const mapStateToProps = (state) => ({
  loading: state.userManaging.loading,
  users: state.userManaging.users,
  metaData: state.userManaging.metaData,
  totalUser: state.userManaging.totalUser,
  realTotalUser: state.userManaging.realTotalUser,
  totalJoinCount: state.userManaging.totalJoinCount,
  totalActiveCount: state.userManaging.totalActiveCount,
  totalDeActivatedCount: state.userManaging.totalDeActivatedCount,
  totalUserBySearch: state.userManaging.totalUserBySearch,
  paginationUserManaging: state.userManaging.paginationUserManaging,
})

export default connect(mapStateToProps, {
  requestUserManagingAction,
  getListUserAction,
  requestUserManagingErrorAction,
})(UserManaging)
