import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Box from "@material-ui/core/Box";
import Pagination from "../../../components/Pagination/Pagination";
import TableContainer from "@material-ui/core/TableContainer";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CustomButton from "../../../components/CustomButtons/Button";
import TextField from "../../../components/Gm-TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import GridItem from "../../../components/Grid/GridItem";
import {dateFormat, typeFilter} from "../../../util/filter";


const useRowStyles = makeStyles({
    table: {
        minWidth: 900,
    },
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    styleBox: {
        backgroundColor: '#EDEDED',
    },
    p0: {
        padding: 0
    },
    setZindexMenuList: {
        // '& div:last-child': {
        //     zIndex: 9999,
        // },
    },
    textFieldOne: {
        width: '60%',
    },
    gridContainerOne: {
        textAlign: 'center'
    },
    grey: {
        backgroundColor : '#eee'
    }
})


const Row = (props) => {

    const { row, index, page, selected, setSelected,updateEvents } = props
    const classes = useRowStyles()

    const [open, setOpen] = React.useState(false)

    const number = page === 0 ? index + 1 : index + 1 + parseInt(`${page}0`)


    const handleSelEdit = (e, row) =>{

        location.href = '/admin/push-managing/detail/' + row.id

    }

    const handleChangeSelect = (e, row) =>{
        //update 문 치기 활성화 하나만이라도

        //
        row.status = e.target.value
        updateEvents(row);
    }

    return (
        <React.Fragment>
            <TableRow hover={true} className={[classes.root,row.status == 'REMOVED' ? classes.grey : null] } >
                {/*<TableCell padding='checkbox' align={'center'}>
                    {row.status == 'REMOVED' ? null : (
                        <Checkbox
                            onClick={(event) => handleClick(event, row?.id)}
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                    )
                    }
                </TableCell>*/}
                <TableCell align={'center'}>{row.id}</TableCell>
                <TableCell align={'center'}>{row.phrase}</TableCell>
                <TableCell align={'center'}>{row.boldPhrase}</TableCell>
                <TableCell align={'center'}>{dateFormat(row.createdAt)}</TableCell>
                {/**/}

                {/*<TableCell align={'center'} className={classes.p0} >
                    <CustomButton color="primary" mr={2} variant="contained" onClick={(event) => handleSelEdit(event,row)} >수정</CustomButton>
                </TableCell>*/}
            </TableRow>
        </React.Fragment>
    )

}

// TABLe head 정렬 때문에
function EnhancedTableHead(props) {



    const { classes, order, orderBy, onRequestSort, headCells } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {/*<TableCell padding='checkbox'></TableCell>*/}
                {/*<TableCell style={{ width: '50px' }}>No.</TableCell>*/}
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.align}
                        align={'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            minWidth: headCell.minWidth ? headCell.minWidth : 170,

                        }}
                        // className={orderBy === headCell.id ? classes.active : classes.nonActive }
                    >
                        <TableSortLabel
                            style={{
                                color : orderBy === headCell.id ? 'red' : 'black'
                            }}
                            active={ orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={
                                // headCell.allowSortable
                                //     ?
                                createSortHandler(headCell.id)
                                // : undefined
                            }
                        >
                            {headCell.label}
                        </TableSortLabel>

                        {/*{loading ? (*/}
                        {/*    <CircularProgress size={30} className={classes.buttonProgress} />*/}
                        {/*) : (*/}



                    </TableCell>
                ))}
                <TableCell >
                    {/*수정*/}


                </TableCell>

            </TableRow>
        </TableHead>
    )
}


const StickerPhraseTable = (props) =>{

    const {
        setFormData,
        rows,
        rowsPerPage,
        setRowsPerPage,
        page,
        setPage,
        setLimit,
        totalPages,
        sortable,
        orderBy,
        headCells,
        order,
        setOrder,
        setOrderBy,
        classes,
        updateEvents,
        setSelected,
        selected
    } = props



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setLimit(parseInt(event.target.value))
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }


    const [pagination, setPagination] = React.useState(1)


    return (
        <TableContainer component={Paper}>
            <Table  aria-label='collapsible table'>

                <EnhancedTableHead
                    sortable={sortable}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headCells}
                />

                <TableBody>
                    {rows.map((row, i) => {
                        return (
                            <Row
                                key={row.id}
                                row={row}
                                index={i}
                                page={page}
                                setSelected={setSelected}
                                selected={selected}
                                updateEvents={updateEvents}
                                // postInfoTypeList={postInfoTypeList}
                                // selEdit={selEdit} //수정삭제버튼

                            />
                        )
                    })}
                </TableBody>
            </Table>

            <Box mb={2} mt={2} display='flex' justifyContent='flex-end'>
                <Pagination
                    pagination={page==0 ? 1 : page}
                    setPagination={setPage}
                    totalPages={totalPages}
                />
            </Box>

        </TableContainer>
    )



}

export default StickerPhraseTable