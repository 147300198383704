import React, {useEffect} from 'react'
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import SweetAlert from "react-bootstrap-sweetalert";
import Button from "../../../components/CustomButtons/Button";
import Box from "@material-ui/core/Box";
import commentsManangingApi from "../../../api/commentsManangingApi";

const AiCommentModal = (props) => {

    const {
        hideAlert,
        dispatch,
        setAiManagingAction,
        title,
        id,
        setAiManaging
    } = props

    const [clovaAiSystemPhrase, setClovaAiSystemPhrase] = React.useState(null)

    const getAiComment = async ()=>{
        try {

            const data = await commentsManangingApi.getAiComment({
                id
            })
            setClovaAiSystemPhrase(data?.data?.content)

            // dispatch(setAiPhraseAction({clovaAiSystemPhrase: data?.data?.clovaAiSystemPhrase}))

        } catch (e) {
        }
    }

    const updateAiComment = async () => {
        try {


            const data = await commentsManangingApi.update({
                id,
                content: clovaAiSystemPhrase
            });
            dispatch(setAiManagingAction({content: clovaAiSystemPhrase, id}))
            hideAlert()

        } catch (e) {
        }

    }
    React.useEffect(() => {
        getAiComment()
    },[])


    return (
        <>
            <SweetAlert
                style={{display: 'block', marginTop: '-100px', width: '80em', zIndex: '1034',}}
                title={title}
                onConfirm={() => {

                }}
                showConfirm={false}
                onCancel={() => hideAlert()}
            >
                <TextareaAutosize
                    // className={classes.textareaAutosize}
                    style={{'width': '80%', 'height': '400px', 'overflow': 'scroll'}}
                    minRows={5}
                    maxRows={9}
                    aria-label='maximum height'
                    placeholder='내용 입력'
                    value={clovaAiSystemPhrase}
                    onChange={(e)=>{
                        setClovaAiSystemPhrase(e.target.value)
                    }}
                    name='content'
                />


                <Box mt={1} display='flex' justifyContent='center'>
                    <Button
                        onClick={hideAlert}
                        // className={classes.marginRightBtnInModal}
                        color='danger'
                    >
                        취소
                    </Button>
                    <Button
                        color='success'
                        // disabled={loading}
                        onClick={updateAiComment}
                    >
                        수정
                    </Button>
                </Box>

            </SweetAlert>


        </>
    )

}

export default AiCommentModal