import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment/moment";
import Box from "@material-ui/core/Box";
import Pagination from "../../../components/Pagination/Pagination";
import {makeStyles} from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from "../../../components/CustomButtons/Button";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";
import AiCommentModal from "./AiCommentModal";
import commentsManangingApi from "../../../api/commentsManangingApi";

const useStyles = makeStyles({
    table: {
        minWidth: 1000,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    groupBtnDropdown: {
        boxShadow: 'unset',
    },
    menuList: {
        '& li': {
            '& span': {
                paddingLeft: '0',
            },
        },
    },
    customBtnMI: {
        borderColor: '#222',
        color: '#222',
        '&:hover': {
            borderColor: 'unset',
            boxShadow: 'none',
        },
    },
    setZindexMenuList: {
        '& div:last-child': {
            zIndex: 9999,
        },
    },
})

export default function AiCommentTables(props) {
    const classes = useStyles()

    const {
        rows,
        headCells,
        sortable,
        setRowsPerPage,
        setPage,
        page,


        pagination,
        setPagination,

        order,
        setOrder,
        setOrderBy,
        orderBy,
        totalPages,
        setSelected,
        selected,
        modal,
        setModal,
        dispatch,
        setAiManagingAction,
    } = props


    function EnhancedTableHead(props) {
        const {classes, order, orderBy, onRequestSort, headCells} = props
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property)
        }

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding='checkbox'></TableCell>
                    {headCells.map((headCell, i) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric || 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={{
                                minWidth: headCell.minWidth ? headCell.minWidth : 170,
                            }}
                        >
                            <TableSortLabel
                                // active={orderBy === headCell.id}
                                active={headCell.allowSortable}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                // onClick={createSortHandler(headCell.id)}
                                onClick={
                                    headCell.allowSortable
                                        ? createSortHandler(headCell.id)
                                        : undefined
                                }
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleClick = (event, row) => {
        const selectedIndex = selected.indexOf(row.id)
        let isAdd = true;
        let isAddIndex = 0;
        // let newSelected = []

        let newselected = selected

        //있는지 체크
        selected.forEach((item, index) => {
            //한번이라도 해당 항목이 체크된적 있으면
            if (item.goodsId == row.goodsId && item.writerId == row.writerId) {
                isAdd = false
                isAddIndex = index
            } else {
                isAdd = true
            }
        })


        if (isAdd) {
            newselected.push(row)
        } else {
            //있었던것이면 빼기
            if (selected.length > isAddIndex) {
                selected.splice(isAddIndex, 1)
            }
        }

        setSelected(newselected)
    }

    const isSelected = (row) => {
        selected?.indexOf(row) !== -1
    }
    const aiCommentModal = (item) => {

        setModal(
            <AiCommentModal
                hideAlert={(e) => {
                    setModal(null)
                }}
                title={'댓글 내용 수정'}
                mode
                id={item.id}
                setAiManagingAction={setAiManagingAction}
                // 댓글 내용 수정
                // setAiPhraseAction={setAiPhraseAction}
                dispatch={dispatch}
                setModal={setModal}
            />
        )

    }
    const deleteAiComment = async (id) => {

        try {

            if(confirm("삭제하시겠습니까?")){
                const aa = await commentsManangingApi.destroy({id});

                if (aa){
                    location.reload()
                }


            }

        } catch (error) {

        }

    }

    return (
        <TableContainer component={Paper}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <Table className={classes.table} aria-label='simple table'>
                <EnhancedTableHead
                    sortable={sortable}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headCells}
                />

                <TableBody>

                    {rows?.map((row, index) => {
                        const isItemSelected = isSelected(row?.id)
                        const labelId = `enhanced-table-checkbox-${index}`

                        const number =
                            page === 0 ? index + 1 : index + 1 + parseInt(`${page}0`)

                        return (

                            <TableRow hover key={index}>

                                <TableCell padding='checkbox'>
                                    <Checkbox
                                        onClick={(event) => handleClick(event, row)}
                                        checked={isItemSelected}
                                        inputProps={{'aria-labelledby': labelId}}
                                    />
                                </TableCell>
                                <TableCell align='left'>
                                    {row.id}
                                </TableCell>
                                <TableCell align='right'>
                                    {row?.content}
                                </TableCell>
                                <TableCell align='right'>
                                    {moment(row && row.createdAt).format('YYYY/MM/DD h:mmA')}
                                </TableCell>
                                <TableCell align='center'>
                                    <Button
                                        color='primary'
                                        onClick={(e) => {
                                            //alert
                                            aiCommentModal(row);
                                        }}
                                    >
                                        수정
                                    </Button>
                                </TableCell>
                                <TableCell align='center'>
                                    <IconButton
                                        // disabled={loading}
                                        aria-label='delete'
                                        onClick={(e) => {
                                            deleteAiComment(row.id)
                                        }}
                                    >
                                        <HighlightOffIcon/>
                                    </IconButton>
                                </TableCell>
                                <TableCell align='right'>
                                    {row?.post.title}
                                </TableCell>

                                <TableCell align='center'>
                                    <Button
                                        color='info'
                                        onClick={(e) => {
                                            location.href = '/admin/post-detail/' + row.post.id
                                        }}
                                    >
                                        상세
                                    </Button>
                                </TableCell>
                            </TableRow>

                        )
                    })}
                </TableBody>
            </Table>
            <Box mb={2} mt={2} display='flex' justifyContent='flex-end'>
                <Pagination
                    pagination={pagination}
                    setPagination={setPagination}
                    totalPages={totalPages}
                />
            </Box>
        </TableContainer>
    )
}