import * as actionTypes from '../actions/types'
import {
	GET_LIST_WRITER,
	REQUEST_WRITER_MANAGING,
	SET_PAGINATION_WRITERMANAGING,
	WRITER_CHANGE_STATE
} from "../actions/types";

const initialState = {
	loading: false,
	writers: [],
	metaData: {
		totalPages: 1,
	},
	totalWriter: 0,
	totalWriterBySearch: 0,
	paginationWriterManaging: 1,

	// user-detail
	writerDetail: null,
	error: null,
	listReportedInWriterDetail: [],
	metaDataForListReportedInWriterDetail: {
		totalPages: 1,
	},
}

export default function (state = initialState, action) {
	const { type, payload } = action
	switch (type) {
		case actionTypes.REQUEST_WRITER_MANAGING:
			return {
				...state,
				loading: true,
			}
		case actionTypes.GET_LIST_WRITER:
			const {
				writers,
				metaData,
				totalWriter,
				totalWriterBySearch,
				hasClientIdData,
			} = payload

			const resetTotalUserBySearchToZeroIfHasClientIdEqualFalse = hasClientIdData
					? state.totalWriterBySearch
					: 0

			return {
				...state,
				loading: false,
				error: null,
				writers: writers,
				metaData,
				totalWriter: totalWriter !== null ? totalWriter : state.totalWriter,
				totalWriterBySearch:
						totalWriterBySearch !== null
								? totalWriterBySearch
								: resetTotalUserBySearchToZeroIfHasClientIdEqualFalse,
			}
		case actionTypes.CHANGE_WRITER_EXPOSUER:
			let {writerId, mainExposure} = payload
			return {
				...state,
				writers: state.writers.filter(item => {
					if (item.id == writerId){
						item.mainExposure = mainExposure
						return item
					}else{
						return item
					}
				}),
				// listManagerManaging: state.listManagerManaging.filter(
					// (item) => item.id !== payload,
				// ),

			}
		case actionTypes.CHANGE_WRITER_USERID:
			let {id, userId, users} = payload
			return {
				...state,
				writers: state.writers.filter(item => {
					if (item.id == id){
						item.userId = userId
						item.users = users
						return item
					}else{
						return item
					}
				}),
				// listManagerManaging: state.listManagerManaging.filter(
					// (item) => item.id !== payload,
				// ),

			}
		case actionTypes.CHANGE_WRITER_IS_SYNC:
			let {writerIds, isSync} = payload
			return {
				...state,
				writers: state.writers.filter(item => {
					if (item.id == writerIds){
						item.isSync = isSync
						return item
					}else{
						return item
					}
				}),
				// listManagerManaging: state.listManagerManaging.filter(
					// (item) => item.id !== payload,
				// ),

			}
		case actionTypes.WRITER_CHANGE_STATE:
			return {
				...state,
				writers: state.writers.map((item) => {
					if (item.id === payload.id && payload.status === 'BLOCKED') {
						item['oldNickname'] = item.nickname
						item['nickname'] = '서비스이용중지'
					} else if (item.id === payload.id && payload.status === 'NORMAL') {
						item['nickname'] = item.oldNickname
					}

					return item
				}),
			}
		case actionTypes.SET_PAGINATION_WRITERMANAGING:
			return {
				...state,
				loading: false,
				error: null,
				paginationWriterManaging: payload,
			}
		// case actionTypes.GET_USER_DETAIL:
		// 	return {
		// 		...state,
		// 		loading: false,
		// 		error: null,
		// 		userDetail: payload,
		// 	}
		// case actionTypes.TOGGLE_RECOMMEND_USER:
		// 	return {
		// 		...state,
		// 		loading: false,
		// 		error: null,
		// 		userDetail: {
		// 			...state.userDetail,
		// 			userType: payload,
		// 		},
		// 	}
		case actionTypes.GET_LIST_REPORTED_IN_USER_DETAIL:
			const { reports, metaData: metaDataForListReportedInUserDetail } = payload
			return {
				...state,
				loading: false,
				error: null,
				listReportedInUserDetail: reports,
				metaDataForListReportedInUserDetail,
			}
		case actionTypes.REQUEST_WRITER_MANAGING_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			}
		default:
			return state
	}
}
