import {connect, useDispatch} from "react-redux";
import Paper from "@material-ui/core/Paper";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/Notice/notice2style";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "../../components/Gm-TextField/TextFieldForDatePicker";
import Box from "@material-ui/core/Box";
import TimePicker from "../Statistics/components/TimePicker";
import Button from "../../components/CustomButtons/Button";
import CustomButton from "../../components/CustomButtons/Button";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import moment from "moment";
import Spinner from "../../components/Spinner/Spinner";
//자동제안은 react suggest
import stylesModal from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import bannerManagingApi from "../../api/mainManaging/bannerManagingApi";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import WriterManagingApi from "../../api/writerManagingApi";
// import GoodsAndWriterModal from "./components/GoodsAndWritersModal";
import MenuItem from "@material-ui/core/MenuItem";
import CustomTextField from "../../components/Gm-TextField/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import Typography from "@material-ui/core/Typography";
import writerScheduleManagingApi from "../../api/writerScheduleManagingApi";
import {dateTimeCheck} from "../../util/common";

const useStyles = makeStyles(styles)
const useStylesModal = makeStyles(stylesModal)

// 이벤트 상세임 아무튼 그럼
const WriterSchedulesDetail = (props) => {

    const {
        match,
        postForm,
    } = props

    const classes = useStyles()
    const classesModal = useStylesModal()


    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz


    const dispatch = useDispatch()

    const [styleTagSelected, setStyleSelected] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [alert2, setAlert] = React.useState(null)
    const [writers, setWriters] = React.useState([])
    const [isUploadImage, setIsUploadImage] = React.useState(false)
    const [isUploadImage2, setIsUploadImage2] = React.useState(false)
    const [formData, setFormData] = React.useState({
        id: isNaN(match?.params?.id) ? 0 : match?.params?.id,
        status: 'ACTIVE',
        avatar: null,
        startAt: moment().subtract(tz, 'hours').format("YYYY-MM-DD"),
        endAt: moment().subtract(tz, 'hours').format("YYYY-MM-DD"),
        fromTime: 0,
        toTime: 23,
        /*fromTime: formDataGlobal
            ? formDataGlobal.fromTime
            : _.split(moment().add(1, 'hours').format('YYYY-MM-DD, H'), ',', 2)[1]?.trim(),
        timeTo: formDataGlobal
            ? formDataGlobal.timeTo
            : _.split(moment().add(1, 'hours').format('YYYY-MM-DD, H'), ',', 2)[1]?.trim(),*/
        title: ''
    })
    const [imageFile, setImageFile] = React.useState(null)
    const hideAlert = () => {
        setAlert(null)
    }
    const [loadingBtn, setLoadingBtn] = React.useState(false)


    const eventsModel = {
        questionTitle: '',
        files: {}
    }


    const [voteQuestionList, setvoteQuestionList] = React.useState([eventsModel])


    const handleForm = (value, key) => {
        //vue의 데이터 매핑 형태였던 것 같은데.... key도 매핑되네 히야...

        switch (key) {
            case 'startAt' :
                setFormData({
                    ...formData,
                    [key]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'endAt' :
                setFormData({
                    ...formData,
                    [key]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'isDuplication' :
                setFormData({
                    ...formData,
                    [key]: !formData.isDuplication,
                })
                break;
            case 'postType' :
                setFormData({
                    ...formData,
                    [key]: value,
                    description: ''
                })

                break;
            default:
                setFormData({
                    ...formData,
                    [key]: value,
                })

        }


    }
    const getWriterLists = async () => {
        try {

            const writers = await WriterManagingApi.getListWriters({limit: 10000});
            setWriters(writers.data.writers)

        }catch (e){

        }

    }

    const handleFormQuestionFile = async (file, index) => {
        //파일 저장해서 가져와진 fileidx를 input


        //이미지 cdn 업로드
        const fd = new FormData()

        fd.append('file', file, file.name)
        fd.append('type', 'POST')
        const {
            data: {id: idImage, url, filename},
        } = await bannerManagingApi.uploadImage(fd); //banner라고 세팅했지만원본은 file api 로 통신

        let tempObject = voteQuestionList[index];
        tempObject.files = {id: idImage, url, filename};
        setvoteQuestionList([...voteQuestionList.slice(0, index),
            tempObject,
            ...voteQuestionList.slice(index + 1)]
        )

    }

    const handleFormFeedFile = async (e, index) => {
        //feed 파일
        //파일 저장해서 가져와진 fileidx를 input


        const fd = new FormData()
        for (let i = 0; i < e.target.files.length; i++) {
            fd.append('file', e.target.files[i])
        }
        fd.append('type', 'WRITER')

        const {data} = await bannerManagingApi.uploadImageMulti(fd)

    }


    const handleChangeTimePickerStart = (event) => {
        setFormData({...formData, fromTime: event.target.value})
    }
    const handleChangeTimePickerEnd = (event) => {
        setFormData({...formData, toTime: event.target.value})
    }

    const validateImageAlert = (message) => {
        setAlert(
            <SweetAlert
                warning
                style={{display: 'block', marginTop: '-100px'}}
                title=''
                onConfirm={() => hideAlert()}
                showConfirm={false}
                onCancel={() => hideAlert()}
            >
                <Typography component='p' gutterBottom>
                    {message}
                </Typography>
                <Button color='success' onClick={hideAlert}>
                    OK
                </Button>
            </SweetAlert>,
        )
    }

    const validateUploadImage = (file, whichCheck, whichBanner) => {
        if (whichCheck === 'size' && Math.round(file.size / 1024) > 2048) {
            validateImageAlert('파일이 너무 큽니다. 2MB 넘지 않아야합니다')
            return true
        }

    }
    const handleChangeFile = (e, type) => {
        //파일 Onchage 시 이미지 체인지

        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            if (validateUploadImage(file, 'size')) return

            const updateState = (file) => {
                setImageFile(file)
                setIsUploadImage(true)
            }
            const updateState2 = (file) => {
                setImageFile2(file)
                setIsUploadImage2(true)
            }
            // use for check width and height image
            const img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.addEventListener('load', () => {
                type === 'mainImage' && updateState(file)
                //validateUploadImage(file, 'size') || (!validateUploadImage(img, 'widthAndHeight') && updateState(file))
                type === 'backgroundImage' && updateState2(file)
                // !validateUploadImage(file, 'size', whichBanner) && updateState2(file)
            })
        }

    }


    //api 통신해서 db저장 이벤트 생성
    const createWriterSchedules = async () => {


        try {

            //유효성체크
            if (imageFile == null && formData.mainImage == null){
                alert("일정 사진을 넣어주세요")
                return
            }
            if (formData.title == null){
                alert("제목을 넣어주세요")
                return
            }
            if(formData.writerId == null){
                alert("작가를 선택해주세요")
                return
            }
            if(formData.category == null){
                alert("카테고리를 선택해주세요")
                return
            }

            const body = formData
            // call api upload file
            setLoading(true)
            let setNewmainImage
            let setNewPopupImage
            if (isUploadImage) {
                const formData = new FormData()
                formData.append('file', imageFile, imageFile.name)
                formData.append('type', 'WRITER')

                const {
                    data: {id, url, filename},
                } = await bannerManagingApi.uploadImage(formData)

                setNewmainImage = {
                    id,
                    url,
                    filename,
                }
                setIsUploadImage(false)
            }
            body.mainFile = ( isUploadImage ? setNewmainImage.id : formData?.mainImage?.id )

            //
            body.startAt =  moment(dateTimeCheck(formData.startAt, formData.fromTime)).format("YYYY-MM-DD HH:mm:SS")
            body.endAt =  moment(dateTimeCheck(formData.endAt, formData.toTime)).format("YYYY-MM-DD HH:mm:SS")

            //업데이트 일때, 하단은 Insert
            if (formData.id != 0) {

                // 일반 핃,
                //추천태그 빼기

                const {data} = await writerScheduleManagingApi.update(body);
                if (data) {
                    alert("수정되었습니다.")
                    location.href = "/admin/writer-schedules-managing"
                }
                return
            }

            const {data} = await writerScheduleManagingApi.create(body);

            if (data) {
                alert("저장되었습니다.")
                location.href = "/admin/writer-schedules-managing"
            }


        } catch (e) {
            setLoadingBtn(false)
        }

    }
    const getWriterSchedule = async () => {
        try {

            setLoading(true)

            if (formData.id > 0) {
                //데이터가 있는 것

                //setLoading 필요

                let {data} = await writerScheduleManagingApi.getSchedule({id: parseInt(formData.id)});
                //데이터 매핑 dispatch쓰면 코드가 깔끔해지나 쓰지 않음
                // data = data
                setLoading(false)

                // 기본
                const formNewDATA = {
                    ...formData,
                    writerId: data.writerId,
                    category: data.category,
                    content: data.content,
                    startAt: moment(data.startAt).format('YYYY-MM-DD'),
                    endAt: moment(data.startAt).format('YYYY-MM-DD'),
                    fromTime: parseInt(moment(data.startAt).format('HH')),
                    toTime:parseInt(moment(data.endAt).format('HH')) ,
                    linkUrl: data.linkUrl,
                    title: data.title,
                    fileId: data?.avatarUrl.id,
                    avatarUrl: data.avatarUrl
                }
                setImageFile(data.avatarUrl)

                //최종데이터 세팅
                setFormData(formNewDATA)


                setLoading(false)

            }

            setLoading(false)


        } catch (e) {

        }
    }

    let count = 0;
    React.useEffect(() => {
        getWriterLists()
    },[])
    React.useEffect(() => {
        //getData

        getWriterSchedule()
    }, [formData.id, formData.writerId])

    //스타일 태그 input,output
    const selectStyleTag = async (type) => {
        //있으면 빼고 없으면 넣고
        let isContain = false;
        let iindex = 0;

        styleTagSelected.map((item, index) => {
            if (item == type) {
                isContain = true;
                iindex = index
            }
        });

        if (isContain) {
            //해당 인덱스 구해서 빼기
            setStyleSelected(styleTagSelected.slice(0, iindex));

        } else {

            //이렇게 하면 state가 변한걸 탐지 못함

            const aa = [];
            styleTagSelected.map((item) => {
                aa.push(item)
            });
            aa.push(type)
            setStyleSelected(aa);
        }
    }
    const openGoodsView = async () => {

        setAlert(
            <GoodsAndWriterModal
                item={goods}
                hideAlert={(e) => {
                    setAlert(null)
                }}
                setAlert={setAlert}
                dispatch={dispatch}
                setGoods={setGoods}
                goods={goods}
            />
        )
    }

    return (

        <div>

            {loading ? (<Spinner/>) : (<div>
                {alert2}
                <Paper variant='outlined' className={classes.paper}>
                    <GridContainer className={classes.grid} alignItems='center'
                                   style={{marginBottom: '10px', marginTop: '10px'}}>
                        <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                            연결할 작가 이름
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            <TextField
                                id='user-managing-select'
                                select
                                size='small'
                                className={classes.widthTextFieldModal}
                                value={formData?.writerId}
                                onChange={(e) => {
                                    handleForm(e.target.value, 'writerId')
                                }}
                                label='연결할 작가 이름을 선택해주세요'
                                style={{width: '100%'}}
                            >
                                {
                                    writers.map((item)=>{
                                        return(<MenuItem name={item.nickname} value={item.id}>{item.nickname}{item.id}</MenuItem>)
                                    })
                                }

                            </TextField>
                        </GridItem>
                    </GridContainer>
                    {/*일정사진*/}
                    <GridContainer alignItems='center' className={classes.grid}>
                        <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                            일정 사진
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            <input
                                accept='image/*'
                                className={classes.inputBtnUpload}
                                id={'contained-button-file'}
                                multiple
                                type='file'
                                onClick={(event) => {
                                    event.target.value = ''
                                }}
                                onChange={(e) => handleChangeFile(e, 'mainImage')}
                            />
                            <label htmlFor={'contained-button-file'}>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    component='span'
                                    fullWidth={true}
                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                >

                                    {imageFile
                                        ? imageFile.name
                                        : '메인 사진 올리기'}
                                </Button>
                            </label>
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            {
                                formData.avatarUrl != null ? <img style={{'maxWidth':'200px'}} src={formData?.avatarUrl?.url}/> : null
                            }
                        </GridItem>
                    </GridContainer>
                    {/*카테고리*/}
                    <GridContainer className={classes.grid} alignItems='center'
                                   style={{marginBottom: '10px', marginTop: '10px'}}>
                        <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                            카테고리
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            {/*{formData.postType}*/}
                            <TextField
                                id='user-managing-select'
                                select
                                size='small'
                                className={classes.widthTextFieldModal}
                                value={formData?.category}
                                onChange={(e) => {
                                    handleForm(e.target.value, 'category')
                                }}
                                label='카테고리 선택해주세요'
                                style={{width: '100%'}}
                            >
                                <MenuItem value={'EVENT'}>{'이벤트'}</MenuItem>
                                <MenuItem value={'POPUP'}>{'팝업'}</MenuItem>
                                <MenuItem value={'FAIR'}>{'페어'}</MenuItem>
                                <MenuItem value={'SUPPORT'}>{'서포터즈'}</MenuItem>
                                <MenuItem value={'MARKET'}>{'마켓'}</MenuItem>
                                <MenuItem value={'EXHIBITION'}>{'전시회'}</MenuItem>
                            </TextField>
                        </GridItem>
                    </GridContainer>
                    {/*제목*/}
                    <GridContainer alignItems='center' className={classes.grid}>
                        <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                            제목
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            <CustomTextField
                                className={classes.textFieldAddTag}
                                id='tag-register-new'
                                label='제목'
                                fullWidth={true}
                                size='small'
                                onChange={(e)=>{
                                    handleForm(e.target.value, 'title')
                                }}
                                value={formData?.title}
                                // defaultValue='Default Value'
                                variant='outlined'
                            />
                        </GridItem>
                    </GridContainer>
                    {/*링크*/}
                    <GridContainer alignItems='center' className={classes.grid}>
                        <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                            링크
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            <CustomTextField
                                className={classes.textFieldAddTag}
                                id='tag-register-new'
                                label='링크'
                                fullWidth={true}
                                size='small'
                                onChange={(e)=>{
                                    handleForm(e.target.value, 'linkUrl')
                                }}
                                value={formData?.linkUrl}
                                // defaultValue='Default Value'
                                variant='outlined'
                            />
                        </GridItem>
                    </GridContainer>
                    {/*시작일*/}
                    <GridContainer alignItems='center' className={classes.grid}>
                        <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                            시작일
                        </GridItem>
                        <GridItem xs={3} className={classes.lableSt}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant='inline'
                                    TextFieldComponent={TextField}
                                    format='yyyy-MM-dd'
                                    id='date-picker-inline1'
                                    value={formData?.startAt}
                                    onChange={(date) =>
                                        handleForm(date, 'startAt')
                                    }
                                    autoOk={true}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </GridItem>
                        <GridItem xs={3} className={classes.lableSt}>
                            <Box>
                                <TimePicker
                                    time={formData?.fromTime}
                                    handleChangeTimePicker={handleChangeTimePickerStart}
                                    setKey='toTime'
                                />

                            </Box>
                        </GridItem>
                    </GridContainer>

                    {/*종료일*/}
                    <GridContainer alignItems='center' className={classes.grid}>
                        <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                            종료일
                        </GridItem>
                        <GridItem xs={3} className={classes.lableSt}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    variant='inline'
                                    TextFieldComponent={TextField}
                                    format='yyyy-MM-dd'
                                    id='date-picker-inline1'
                                    value={formData?.endAt}
                                    onChange={(date) =>
                                        handleForm(date, 'endAt')
                                    }
                                    autoOk={true}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </GridItem>
                        <GridItem xs={3} className={classes.lableSt}>
                            <Box>
                                <TimePicker
                                    style={{paddingTop:'10px', paddingBottom: '10px' }}
                                    time={formData?.toTime}
                                    handleChangeTimePicker={handleChangeTimePickerEnd}
                                    setKey='toTime'
                                />
                            </Box>
                        </GridItem>
                    </GridContainer>

                    {/*내용*/}
                    <GridContainer alignItems='center' className={classes.grid}>
                        <GridItem xs={2} sm={2} md={2} className={classes.lableSt}>
                            내용
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            <TextareaAutosize
                                // className={classes.contentStyle}
                                minRows={5}
                                maxRows={9}
                                aria-label='maximum height'
                                placeholder='내용을 입력해주세요'
                                value={formData?.content}
                                // onChange={handleForm(, 'startAt')}
                                onChange={(e) =>
                                    handleForm(e.target.value, 'content')
                                }
                                name='content'
                                style={{width: '100%', height: '50px'}}
                            />
                        </GridItem>
                    </GridContainer>

                </Paper>


                <Box className='add-list' display='flex' justifyContent='center'>
                    {loadingBtn ? (
                            <Spinner/>
                        ) :
                        (
                            <CustomButton color="primary" mr={2} variant="contained"
                                          onClick={createWriterSchedules}
                            >
                                {formData?.id == 0 ? '등록' : '수정'}
                            </CustomButton>)
                    }
                    <CustomButton color="default" mr={2} variant="contained"
                                  onClick={(event) => history.back()}
                    >취소</CustomButton>

                </Box>
            </div>)}


        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        // userDetail: state.post.postForm,
        loading: state.eventsReducer.loading,
        postForm: state.eventsReducer.postForm,
        post: state.eventsReducer.post,
        metaData: state.eventsReducer.metaData,
    }
}

export default connect(mapStateToProps, {
    // requestNoticeAction,
    // getListNoticesAction,
    // noticesWithErrAction,
})(WriterSchedulesDetail)
