import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Box from "@material-ui/core/Box";
import Pagination from "../../../components/Pagination/Pagination";
import TableContainer from "@material-ui/core/TableContainer";
import moment from "moment";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import CustomButton from "../../../components/CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "../../UserManaging/components/Radio";
import TextField from "../../../components/Gm-TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import GridItem from "../../../components/Grid/GridItem";
import {dateFormat} from "../../../util/filter";


const useRowStyles = makeStyles({
    table: {
        minWidth: 900,
    },
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    styleBox: {
        backgroundColor: '#EDEDED',
    },
    p0: {
        padding: 0
    },
    setZindexMenuList: {
        // '& div:last-child': {
        //     zIndex: 9999,
        // },
    },
    textFieldOne: {
        width: '100%',
    },
    gridContainerOne: {
        textAlign: 'center'
    },
    grey: {
        backgroundColor : '#eee'
    }
})


const Row = (props) => {

    const { row, index, page, selected, setSelected,updateEvents,type } = props
    const classes = useRowStyles()

    const [open, setOpen] = React.useState(false)

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        setSelected(newSelected)
    }

    const isSelected = (id) => selected.indexOf(id) !== -1
    const isItemSelected = isSelected(row?.id)
    const labelId = `enhanced-table-checkbox-${index}`
    const filterSTatus  = (value) => {
        if (value = 'ACTIVE') return '활성화'
        if (value = 'DEACTIVATED') return '비활성화'
        if (value = 'REMOVED') return '삭제'
    }

    const number = page === 0 ? index + 1 : index + 1 + parseInt(`${page}0`)

    const handleSelEdit = (e, row) =>{

        location.href = '/admin/event-managing/detail/'+ row.type + '/' + row.id

    }
    const handleSelView = (e, row) =>{
        // 1/admin/event-managing/view/EVENT/28#
        location.href = '/admin/event-managing/view/'+ row.type + '/' + row.id

    }

    const handleChallengeList = (e, row) =>{
        //챌린지 이벤트 리스트 이동

        location.href = '/admin/post-list/1?eventsId='+ row.id

    }

    const handleChangeSelect = (e, row) =>{
        //update 문 치기 활성화 하나만이라도

        //
        row.status = e.target.value
        updateEvents(row);
    }

    return (
        <React.Fragment>
            <TableRow hover={true} className={[classes.root,row.status == 'REMOVED' ? classes.grey : null] } >
                <TableCell padding='checkbox' align={'center'}>
                    {row.status == 'REMOVED' ? null : (
                        <Checkbox
                            onClick={(event) => handleClick(event, row?.id)}
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                        )
                    }
                </TableCell>
                <TableCell align={'center'}>{row.id}</TableCell>
                <TableCell align={'left'} style={{cursor: 'pointer'}} onClick={(event) => handleSelView(event,row)}>{row.title}</TableCell>
                <TableCell align={'center'}>{dateFormat(row.createdAt)}</TableCell>
                <TableCell align={'center'}>{dateFormat(row.startAt)} ~ {dateFormat(row.endAt)}</TableCell>
                <TableCell align={'center'}>{row.numOrder}</TableCell>
                <TableCell className={classes.setZindexMenuList} align='center'>
                    {/*{filterSTatus(row.status)}*/}
                    {/*<Radio index={index} status={row.status} userId={row.id} />*/}

                    {
                        row.status == 'REMOVED' ? (
                            <span style={{fontSize: '0.875rem'}}>삭제됨</span>
                        ) :(
                            <GridItem
                                className={classes.gridContainerOne}
                                xs={12}
                            >
                                <TextField
                                    id='user-managing-select'
                                    select
                                    size='small'
                                    className={classes.textFieldOne}
                                    value={row.status}
                                    onChange={(e)=>{
                                        handleChangeSelect(e,row)
                                    }}
                                >
                                    <MenuItem value={'ACTIVE'}>활성화</MenuItem>
                                    <MenuItem value={'DEACTIVATED'}>비활성화</MenuItem>
                                    <MenuItem value={'REMOVED'}>삭제됨</MenuItem>
                                </TextField>
                            </GridItem>
                        )
                    }


                </TableCell>
                <TableCell align={'center'} className={classes.p0} >
                    {
                        type == 'CHALLENGE' &&(
                            <CustomButton color="primary" mr={2} variant="contained" onClick={(event) => handleChallengeList(event,row)} >리스트</CustomButton>
                        )

                    }

                    <CustomButton color="primary" mr={2} variant="contained" onClick={(event) => handleSelEdit(event,row)} >수정</CustomButton>
                    {
                        type == 'EVENT' &&(<CustomButton color="info" mr={2} variant="contained" onClick={(event) => handleSelView(event,row)} >상세조회</CustomButton>
                        )
                    }
                </TableCell>
            </TableRow>
        </React.Fragment>
    )

}

// TABLe head 정렬 때문에
function EnhancedTableHead(props) {



    const { classes, order, orderBy, onRequestSort, headCells } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'></TableCell>
                {/*<TableCell style={{ width: '50px' }}>No.</TableCell>*/}
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            minWidth: headCell.minWidth ? headCell.minWidth : 170,

                        }}
                        // className={orderBy === headCell.id ? classes.active : classes.nonActive }
                    >
                        <TableSortLabel
                              style={{
                                  color : orderBy === headCell.id ? 'red' : 'black'
                              }}
                            active={ orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={
                                // headCell.allowSortable
                                //     ?
                                    createSortHandler(headCell.id)
                                    // : undefined
                            }
                        >
                            {headCell.label}
                        </TableSortLabel>

                        {/*{loading ? (*/}
                        {/*    <CircularProgress size={30} className={classes.buttonProgress} />*/}
                        {/*) : (*/}



                    </TableCell>
                ))}
                <TableCell >
                    {/*수정*/}


                </TableCell>

            </TableRow>
        </TableHead>
    )
}


const EventsListTable = (props) =>{

    const {
        setFormData,
        rows,
        rowsPerPage,
        setRowsPerPage,
        page,
        setPage,
        setLimit,
        totalPages,
        sortable,
        orderBy,
        headCells,
        order,
        setOrder,
        setOrderBy,
        classes,
        updateEvents,
        type,
        typeName,
        setSelected,
        selected
    } = props



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setLimit(parseInt(event.target.value))
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }


    const [pagination, setPagination] = React.useState(1)


    return (
        <TableContainer component={Paper}>
            <Table  aria-label='collapsible table'>

                <EnhancedTableHead
                    sortable={sortable}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headCells}
                    type={type}
                />

                <TableBody>
                    {rows.map((row, i) => {
                        return (
                            <Row
                                key={row.id}
                                row={row}
                                index={i}
                                page={page}
                                setSelected={setSelected}
                                selected={selected}
                                updateEvents={updateEvents}
                                type={type}
                                // postInfoTypeList={postInfoTypeList}
                                // selEdit={selEdit} //수정삭제버튼

                            />
                        )
                    })}
                </TableBody>
                {/*{테이블페이지 네이션테이블페이지 네이션 기존게 맘에 안들어서 바꿈}*/}
            </Table>

            <Box mb={2} mt={2} display='flex' justifyContent='flex-end'>
                <Pagination
                    pagination={page==0 ? 1 : page} 
                    setPagination={setPage}
                    totalPages={totalPages}
                />
            </Box>

        </TableContainer>
    )



}

export default EventsListTable