import axiosInterceptors from 'api/axios'

const settingApi = {
  getVersionIOS: (params) => {
    const url = '/app/getVersioniOS'
    return axiosInterceptors.get(url, { params })
  },

  getVersionAndroid: (params) => {
    const url = '/app/getVersionAndroid'
    return axiosInterceptors.get(url, { params })
  },

  getUrl: () => {
    const url = '/app/getAppSettings'
    return axiosInterceptors.get(url)
  },

  updateTermsServiceUrl: (body) => {
    const url = '/app/updateTermsServiceUrl'
    return axiosInterceptors.put(url, body)
  },

  updatePrivacyPolicyUrl: (body) => {
    const url = '/app/updatePrivacyPolicyUrl'
    return axiosInterceptors.put(url, body)
  },

  updateSysEmail: (body) => {
    const url = '/app/updateSystemEmail'
    return axiosInterceptors.put(url, body)
  },
  getAiPhrase: (body) => {
    const url = '/app/getAiPhrase'
    return axiosInterceptors.get(url, body)
  },
  updateAiPhrase: (body) => {
    const url = '/app/updateAiPhrase'
    return axiosInterceptors.put(url, body)
  },
  toggleAiOnOff: (body) => {
    const url = '/app/toggleAiOnOff'
    return axiosInterceptors.put(url, body)

  }
}

export default settingApi
