import * as actionTypes from './types'

// export const createBoardManagingAction = (payload) => ({
//     type: actionTypes.CREATE_BOARD_INFO_MANAGING,
//     payload,
// })

//
// export const updateBoardManagingAction = (payload) => ({
//     type: actionTypes.UPDATE_BOARD_INFO_MANAGING,
//     payload,
// })
//
export const getPushInfosAction = (payload) => ({
    type: actionTypes.GET_PUSH_INFO_LIST,
    payload,
})
//
//
//


export const requestPushInfosAction = (payload) => ({
    type: actionTypes.BOARD_INFO_MANAGING_REQUEST_ERROR,
    payload,
})
// export const updateEventsAction = (payload) => ({
//     type: actionTypes.UPDATE_EVNETS,
//     payload,
// })