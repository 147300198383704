import * as actionTypes from './types'

export const requestWriterSchedulesManagingAction = () => {
    return {
        type: actionTypes.REQUEST_WRITER_SCHEDULES_MANAGING,
    }
}

export const getListWriterSchedulesAction = (payload) => {
    return {
        type: actionTypes.GET_LIST_WRITER_SCHEDULES,
        payload: payload,
    }
}

export const setPaginationWriterSchedulesManagingAction = (payload) => {
    return {
        type: actionTypes.SET_PAGINATION_WRITERSCHEDULESMANAGING,
        payload: payload,
    }
}

export const requestWriterSchedulesManagingErrorAction = (payload) => {
    return {
        type: actionTypes.REQUEST_WRITER_SCHEDULES_MANAGING_ERROR,
        payload: payload,
    }
}
