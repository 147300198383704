import * as actionTypes from './types'

export const createGoodsManaging = () => {
    return {
        type: actionTypes.CREATE_GOODS_MANAGING,
    }
}

export const getGoodsManagingListAction = (payload) => {
    return {
        type: actionTypes.GET_GOODS_MANAGING_LIST,
        payload: payload,
    }
}


export const changeGoodsManagingAction = (payload) => {
    return {
        type: actionTypes.CHANGE_GOODS_MANAGING,
        payload: payload,
    }
}

export const orderContentAction = (data) => {
    return {
        type: actionTypes.ORDER_GOODS_MANAGING,
        payload: data,
    }
}