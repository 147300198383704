import React, {useEffect} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/PushManaging/PushManangingStyle";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import TextField from "../../../components/Gm-TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "../../../components/CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Table from './GoodsTables.js';
import MenuItem from "@material-ui/core/MenuItem";
import {requestUserManagingAction} from "../../../redux/actions/userManagingAction";
import writerSearchManagingApi from "../../../api/writerSearchManagingApi";
import TableContainer from "@material-ui/core/TableContainer";


const useStyles = makeStyles(styles)

const GoodsAndWritersModal = (props) => {


    const {
        hideAlert,
        setAlert,
        dispatch,
        getListUserAction,
        loading,
        setAddMemberIds,
        // goodsManagingId,
        setGoods,
        goods
    } = props
    const [selected, setSelected] = React.useState([])

    const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('')
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [page, setPage] = React.useState(0)

    useEffect(() => {
        document.querySelector('.sweet-alert').parentElement.style.zIndex = 1034;
        getPostList();
    }, [page])
    const searchPost = async () => {

        setPage(0)
        getPostList();
    }
    //회원데이터
    const getPostList = async () => {
        let orderString = '';

        if (orderBy == 'first') {
            //처음 정렬시 현재 front에 나오는 표출 순서대로
            orderString = 'numOrder,DESC&createdAt,DESC';
        } else {
            orderString = orderBy + ',' + order
        }

        try {
            requestUserManagingAction()
            const params = {
                limit: 10,
                offset: page ,
                order: 'DESC',
                searchKey: searchKey,
                searchText: searchText
                // userStatus: select,
                // clientId,
            }

            const {data} = await writerSearchManagingApi.getWriterSearchGoodsByPosts(params)
            setPostsList(data.writerAndGoods)

            setMetaData(data.metaData)
        } catch (error) {
            console.log(error.response)
            if (error && error.response && error.response.data) {
                // requestUserManagingErrorAction(error.response.data)
            }
        }
    }


    const headCells = [
        {
            id: 'title',
            numeric: true,
            disablePadding: false,
            label: '제목',
            allowSortable: false,
        },
        {
            id: 'writer',
            numeric: true,
            disablePadding: false,
            minWidth: 20,
            label: '작가',
            allowSortable: false,
        },
        {
            id: 'price',
            numeric: false,
            disablePadding: false,
            label: '가격',
            // minWidth: 100,
            allowSortable: false,
        },
        {
            id: 'createdAt',
            numeric: true,
            disablePadding: false,
            label: '등록일',
            allowSortable: false,
        },
    ]

    const classes = useStyles()

    const [showNotification, setShowNotification] = React.useState({
        open: false,
        message: '',
    })


    //알림대상 관련 변수
    const [limit, setLimit] = React.useState(10)
    const [searchText, setSearchText] = React.useState('')
    const [searchKey, setSearchKey] = React.useState('goodsName')
    const [postsList, setPostsList] = React.useState([])
    const [metaData, setMetaData] = React.useState({
        totalRecords: 0,
        totalPages: 0
    })

    const handleCloseOpen = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setShowNotification({...showNotification, open: false})
    }

    const handleSearchKey = (valuwe) => {
        setSearchKey(valuwe)
        setOrder('asc')
    }
    const test = async () => {

        //기존
        let postsNew = goods
        let newSelected = []
        let isAdd =true;
        let isAddIndex = 0;

        //새로 선택한거
        selected.map(async (item) => {

            //있는지 체크
            postsNew.forEach((item2,index) => {
                //한번이라도 해당 항목이 체크된적 있으면
                if (item2.goodsId == item.goodsId && item2.writerId == item.writerId) {
                    isAdd = false
                    isAddIndex = index
                }else{
                    isAdd = true
                }
            })


            if (isAdd){
                newSelected.push(item)
            }else{
                //있었던것이면 빼기
                if (  selected.length > isAddIndex){
                    selected.splice(isAddIndex, 1)
                }
            }

        })

        postsNew.push(...newSelected)

        setGoods(postsNew)

        //바로 API 써서 저장


        hideAlert()
        // location.reload()
    }

    return (
        <>
            <SweetAlert
                style={{display: 'block', marginTop: '-100px', width: '80em', zIndex: '1034',}}
                title={'상품선택'}
                onConfirm={() => {
                }}
                showConfirm={false}
                onCancel={() => hideAlert()}
            >

                <GridContainer alignItems='center'>
                    <GridItem xs={2} sm={2} md={2}>
                        {/*{formData.type}*/}
                        <TextField
                            id='push-managing-select'
                            select
                            size='small'
                            className={classes.widthTextFieldModal}
                            value={searchKey}
                            onChange={(e) => {
                                // handelS
                                // setSearchKey(e.target.value)
                                handleSearchKey(e.target.value)
                            }}
                            label='검색 타입'
                            style={{width: '100%'}}
                        >
                            <MenuItem value={'goodsName'}>전체</MenuItem>
                            {/*<MenuItem value={'goodsName'}>상품 이름</MenuItem>*/}
                            {/*<MenuItem value={'writerName'}>작가이름</MenuItem>*/}
                            {/*<MenuItem value={'title'}>제목</MenuItem>*/}
                        </TextField>
                    </GridItem>
                    <GridItem
                        className={classes.gridContainerOne}
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                    >

                        <TextField
                            id='post-managing-textfield'
                            size='small'
                            className={classes.textFieldOne}
                            placeholder={'검색해주세요'}
                            name='clientId'
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                        />


                    </GridItem>
                    <GridItem xs={2} sm={2} md={2} lg={2} xl={2}>
                        <Button
                            color='primary'
                            // disabled={loading}
                            onClick={searchPost}
                        >
                            검색
                        </Button>
                    </GridItem>

                    <Box mb={2} style={{'max-width': '100%'}}>
                        {loading ? (
                            <CircularProgress size={30} className={classes.buttonProgress}/>
                        ) : (

                            (postsList?.length == 0) ? (
                                <TableContainer>
                                    검색어를 입력 후 검색을 눌러주세요
                                </TableContainer>
                            ) :(<Table
                                    headCells={headCells}
                                    rows={postsList}
                                    setPagination={metaData}
                                    totalPages={metaData.totalPages}
                                    setRowsPerPage={setRowsPerPage}
                                    rowsPerPage={rowsPerPage}
                                    setPage={setPage}
                                    page={page}
                                    setOrder={setOrder}
                                    order={order}
                                    setOrderBy={setOrderBy}
                                    orderBy={orderBy}
                                    setSelected={setSelected}
                                    selected={selected}
                                />)

                        )}
                    </Box>


                </GridContainer>


                <Box mt={1} display='flex' justifyContent='center'>
                    <Button
                        onClick={hideAlert}
                        className={classes.marginRightBtnInModal}
                        color='danger'
                    >
                        취소
                    </Button>
                    <Button
                        color='success'
                        disabled={loading}
                        onClick={test}
                    >
                        추가
                    </Button>
                </Box>

            </SweetAlert>

        </>


    )

}
GoodsAndWritersModal.propTypes = {}
export default GoodsAndWritersModal