import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles({
    table: {
        minWidth: 900,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
})

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHeadStaticOfClick(props) {
    const { classes, order, orderBy, onRequestSort, headCells } = props

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, i) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            minWidth: headCell.minWidth ? headCell.minWidth : 170,
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export const StaticOfClickTable = (props) => {
    // const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('totalViews')
    const classes = useStyles()

    const { rows, headCells, setOrder, order } = props

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
                <EnhancedTableHeadStaticOfClick
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headCells}
                />

                <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                        return (
                            <TableRow hover key={index}>
                                <TableCell align='left'>{row?.postId}</TableCell>
                                <TableCell align='right'>{row?.clickType}</TableCell>
                                <TableCell align='right'>{row?.totalViews}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

/* StaticOfSearchTable */

function EnhancedTableHeadStaticOfSearch(props) {
    const { classes, order, orderBy, onRequestSort, headCells } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, i) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            minWidth: headCell.minWidth ? headCell.minWidth : 170,
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export const StatisticMainTable = (props) => {
    // const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('totalSearch')
    const classes = useStyles()

    const { rows, headCells, setOrder, order } = props


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
                <EnhancedTableHeadStaticOfSearch
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headCells}
                />

                <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                        return (
                            <TableRow hover key={index}>
                                <TableCell align='left'>{row?.times}</TableCell>
                                <TableCell align='right'>{row?.dau}</TableCell>
                                <TableCell align='right'>{row?.nru2}</TableCell>
                                <TableCell align='right'>{row?.postCount}({row?.postCountUser})({row?.postPersent}) </TableCell>
                                <TableCell align='right'>{row?.commentCount}({row?.commentCountUser})({row?.commentPersent}) </TableCell>
                                {/*<TableCell align='right'>{row?.deletePostCount}({row?.postDeletePersent}) </TableCell>*/}
                                {/*<TableCell align='right'>{row?.deleteCommentCount}({row?.commentDeletePersent}) </TableCell>*/}
                                <TableCell align='right'>{row?.likesCount}</TableCell>
                                <TableCell align='right'>{row?.outCount}</TableCell>
                                <TableCell align='right'>{row?.MAU}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
