import * as actionTypes from '../actions/types'

const initialState = {
    loading: false,
    versions: [],
    metaData: {
        totalPages: 1,
    },
    totalVersions: 0,
    paginationVersionsManaging: 1,

    versionDetail: null,
    error: null,
}

export default function (state = initialState, action) {
    const {type, payload} = action
    switch (type) {

        case actionTypes.GET_LIST_VERSION:
            const {
                rows,
                metaData,
                totalVersions,
            } = payload

            const data = {
                ...state,
                loading: false,
                error: null,
                versions: rows,
                metaData,
            }


            return data
        case actionTypes.REQUEST_VERSION_MANAGING:
            return {
                ...state,
                loading: true,
            }

        case actionTypes.REQUEST_VERSION_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        default:
            return state
    }
}