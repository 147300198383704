import React, {useEffect} from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeStyles } from '@material-ui/core/styles'

import stylesAlert from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "../../../components/Gm-TextField/TextField";
import CustomTextField from "../../../components/Gm-TextField/TextField";
import Button from "../../../components/CustomButtons/Button";
import Box from "@material-ui/core/Box";
const useStylesModal = makeStyles(stylesAlert)
import userManangingApi from "../../../api/userManagingApi.js";

const Modal = (props) => {
  const classesAlert = useStylesModal()

  const {
    hideModal,
    postIds,
    postManagingErrAction,
    setSelected,

    userId,
  } = props

  useEffect(() => {
    //mounted
    document.querySelector('.sweet-alert').parentElement.style.zIndex = 1034;

    // if (item?.type === 'EVENT') {
    //   getListEvents('event')
    // } else if (item?.type === 'CHALLENGE') {
    //   getListEvents('challenge')
    // }

  }, []);

  const [chanageNickName, setChangeNickName] = React.useState(null)
  const [accountType, setAccountType] = React.useState('KAKAO')

  const editChangeUser = async () => {
    try {
      // deletePostAction(postIds)
      hideModal()


      const ownUserInfo = {
        userId: userId
      }
      const changeUserInfo = {
        nickname: chanageNickName.trim(),
        accountType: accountType
      }

      const data = await userManangingApi.changeUser({ ownUserInfo, changeUserInfo })


      alert(JSON.stringify(data))


      location.reload();



    } catch (error) {
      console.error(error)
      postManagingErrAction(error?.response?.data)
    }
  }

  return (
    <SweetAlert
        style={{display: 'block', marginTop: '-100px', width: '80em', zIndex: '1034',}}
        title={'전환하기'}
        onConfirm={() => {
        }}
        showConfirm={false}
        onCancel={() => hideModal()}
    >
      <GridContainer>
        <GridItem
            container
            justifyContent='flex-start'
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{marginBottom: '10px'}}
        >
          <TextField
              id='user-managing-select'
              select
              size='small'
              // className={classes.widthTextFieldModal}
              value={accountType}
              onChange={(e) => {
                setAccountType(e.target.value)
              }}
              label='로그인타입 을 선택해주세요'
              style={{marginLeft: '25px', width: '30%', paddingTop: '10px'}}
          >
            <MenuItem value={'KAKAO'}>카카오</MenuItem>
            <MenuItem value={'NAVER'}>네이버</MenuItem>
            <MenuItem value={'GOOGLE'}>구글</MenuItem>
            <MenuItem value={'APPLE'}>애플</MenuItem>
          </TextField>
          <CustomTextField
              id='editBannerUpload'
              label='변경할 회원의 닉네임을 입력하세요'
              variant='standard'
              name='changeNickName'
              value={chanageNickName}
              onChange={(e)=>{
                setChangeNickName(e.target.value)
              }}
              style={{marginBottom: '20px', width: '50%'}}
          />
        </GridItem>
      </GridContainer>
      <Box mt={1} display='flex' justifyContent='center'>
        <Button
            onClick={hideModal}
            // className={classes.marginRightBtnInModal}
            color='danger'
        >
          취소
        </Button>
        <Button
            color='success'
            // disabled={loading}
            onClick={editChangeUser}
        >
          수정
        </Button>
      </Box>



    </SweetAlert>
  )
}

export default Modal
