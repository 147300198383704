import { primaryColor } from 'assets/jss/material-dashboard-pro-react.js'

const notice = {
  buttonProgress: {
    color: primaryColor[0],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  // * Notice Add
  textareaAutosize: {
    width: '100%',
  },
  textFieldOne: {
    width: '100%'
  },
  textFieldTwo: {
    width: '150px',
    '& input': {
      textAlign: 'end',
    },
  },
  active: {
    //정렬일때
    color: 'black'
  },
  nonActive: {
    //비정렬일때
    color: 'red',
  },
  inputT : {
    '& input': {
      paddingTop: '10.5px',
      paddingBottom: '10.5px'
    },
  },
  p20: {
    padding: '20px',
  },
  p10: {
  },
  w100: {
    width: '100%',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #eee',
    cursor: 'pointer',
  },
  w100C:{
    width: '100%',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #eee',
    cursor: 'pointer',

  },
  align :{

    alignItems : 'flex-start',
  },
  backgroundGrey :{

    background : '#eee'
  },
  displayBlock:{

    display: 'block',
  }

  // gridContainerOne: {
  //   [theme.breakpoints.down('xs')]: {
  //     marginBottom: theme.spacing(1),
  //   },
  // },
}

export default notice
