import moment from "moment/moment";

export const priceFilter = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export const typeFilter =(type) => {
    switch (type) {
        case 'WEB_VIEW':
            return "내부URL"
        case 'URL':
            return "외부URL"
        case "WRITER":
            return "작가"
        case "EVENT":
            return "이벤트"
        case "CHALLENGE":
            return "챌린지"
        case "FEED":
            return "꾸미기"
        case "POST_GOODS":
            return "굿즈"
        case "STAR":
            return "라이징스타"
        case "TAG":
            return "태그"
        case "WRITER_TAG":
            return "작가태그"
        case "STYLE_TAG":
            return "스타일 태그"
        case "URL":
            return "URL"
    }
}



export const dateFormat = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm")
}