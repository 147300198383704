import React from 'react'
import moment from 'moment'
import fileDownload from 'js-file-download'
import queryString from 'query-string'


import {makeStyles} from '@material-ui/core/styles'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import MenuList from '@material-ui/core/MenuList'
import TimePicker from '../components/TimePicker'
import TextField from 'components/Gm-TextField/TextFieldForDatePicker'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'

import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import {StatisticMainTable} from '../components/StatisticMainTable'
import Spinner from 'components/Spinner/Spinner'
import Pagination from 'components/Pagination/Pagination'

import {useSelector, useDispatch} from 'react-redux'
import {
    getListStaticsOfSearchAction,
    staticsOfSearchRequestErrorAction,
} from 'redux/actions/staticsOfSearchAction'
import staticsOfSearchApi from 'api/staticsOfSearchApi'

import styles from 'assets/jss/material-dashboard-pro-react/views/Statistics/statisticSearch.js'

const useStyles = makeStyles(styles)

const StatisticMain = () => {
    const classes = useStyles()

    const dispatch = useDispatch()
    const {
        loading: loadingPage,
        listStaticsOfSearch,
        metaData: {totalPages},
        today,
        retenRate,
    } = useSelector((state) => ({
        loading: state.staticsOfSearch.loading,
        listStaticsOfSearch: state.staticsOfSearch.listStaticsOfSearch,
        metaData: state.staticsOfSearch.metaData,
        today: state.staticsOfSearch.today,
        retenRate: state.staticsOfSearch.retenRate,
    }))

    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [order, setOrder] = React.useState('desc')
    const [loading, setLoading] = React.useState(false)
    const [loadingButtonGetExcel, setLoadingButtonGetExcel] = React.useState(
        false,
    )
    const [pagination, setPagination] = React.useState(1)
    const [isFirstLoad, setIsFirstLoad] = React.useState(true)
    const [searchTime, setSearchTime] = React.useState(new Date())

    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz

    const [formData, setFormData] = React.useState({
        type: 'ALL',
        fromDate: moment().subtract(7, 'days').calendar({
            sameElse: 'YYYY-MM-DD',
        }),
        toDate: moment().format('YYYY-MM-DD'),
        // fromTime: 0,
        // toTime: 23,
        limit: 10,
    })

    const handleChangeFormDate = (date, key) => {
        setFormData({
            ...formData,
            [key]: moment(date).subtract(tz, 'hours').format('YYYY-MM-DD'),
        })
    }

    const handleChangeTimePicker = (event, key) => {
        setFormData({...formData, [key]: event.target.value})
    }

    const headCells = [
        {
            id: 'day',
            numeric: false,
            disablePadding: false,
            label: '일자',
        },
        {
            id: 'dau',
            numeric: true,
            disablePadding: false,
            label: 'DAU',
        },
        {
            id: 'nru',
            numeric: true,
            disablePadding: false,
            label: 'NRU',
        },
        {
            id: 'posts',
            numeric: true,
            disablePadding: false,
            label: '신규 게시글 수(사람수)(비율)',
        },
        {
            id: 'comments',
            numeric: true,
            disablePadding: false,
            label: '신규 댓글 수(사람수)(비율)',
        },
       /* {
            id: 'deletePosts',
            numeric: true,
            disablePadding: false,
            label: '삭제 게시글 수(비율)',
        },
        {
            id: 'deleteComments',
            numeric: true,
            disablePadding: false,
            label: '삭제 댓글 수(비율)',
        },*/
       {
            id: 'likes',
            numeric: true,
            disablePadding: false,
            label: '좋아요 수 ',
        },
        {
            id: 'out',
            numeric: true,
            disablePadding: false,
            label: '탈퇴건수',
        },
        {
            id: 'MAU',
            numeric: true,
            disablePadding: false,
            label: 'mau',
        },

    ]

    // Handle API
    const compiled = _.template('${ date } ${ time }:00:00')
    const {type, limit, fromDate, toDate, fromTime, toTime} = formData

    const convertTime = (isFirstLoad, time) => {
        let result

        if (isFirstLoad) {
            result = _.split(
                moment().subtract(tz, 'hours').format('YYYY-MM-DD, H'),
                ',',
                2,
            )[1]?.trim()
        } else {
            const hour = time - tz;
            result = hour < 0 ? hour + 24 : hour;
        }
        return result <= 9 ? `0${result}` : result
    }

    const convertDate = (time, date) =>
        time >= tz ? date : moment(date).subtract(1, 'days').format('YYYY-MM-DD')

    let params = {
        type,
        limit,
        sortByTotalSearch: order.toUpperCase(),
        offset: pagination,
        fromDate: fromDate,
        toDate: toDate
    }

    const getExcelFile = async () => {
        try {
            setLoadingButtonGetExcel(true)
            params['limit'] = 1000
            delete params['offset']
            const convertParamsToQueryUrl = queryString.stringify(params)

            const data = await staticsOfSearchApi.getSearchAllExcelFile(
                convertParamsToQueryUrl,
            )
            fileDownload(data, 'dashboard_' + moment(params.fromDate).format('YYYYMMDD') + '_' + moment(params.toDate).format('YYYYMMDD') + '.xlsx' )
            setLoadingButtonGetExcel(false)
        } catch (error) {
            setLoadingButtonGetExcel(false)
            dispatch(staticsOfClickRequestErrorAction(error?.response?.data))
        }
    }

    const getUserStatisticExcelFile = async () => {
        try {
            setLoadingButtonGetExcel(true)
            params['limit'] = 100000
            delete params['offset']
            const convertParamsToQueryUrl = queryString.stringify(params)

            const data = await staticsOfSearchApi.getUserStatisticExcelFile(
                convertParamsToQueryUrl,
            )
            fileDownload(data, 'user_' + moment(params.fromDate).format('YYYYMMDD') + '_' + moment(params.toDate).format('YYYYMMDD') + '.xlsx' )
            setLoadingButtonGetExcel(false)
        } catch (error) {
            setLoadingButtonGetExcel(false)
            dispatch(staticsOfClickRequestErrorAction(error?.response?.data))
        }
    }

    const getListStaticsOfSearch = async () => {
        try {
            setLoading(true)
            const {data} = await staticsOfSearchApi.getSearchAll(params)
            data.searchs = data.statics
            dispatch(getListStaticsOfSearchAction(data))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            dispatch(staticsOfSearchRequestErrorAction(error?.response?.data))
        }
    }

    React.useEffect(() => {
        getListStaticsOfSearch()
        setIsFirstLoad(false)
    }, [pagination, order])

    return (
        <div className='statistic-click'>
            {/*상단*/}
            <div className='top'>
                <div>
                    <h3 style={{margin: '0px'}}>실시간 요약: {moment(searchTime).format('YYYY-MM-DD, HH:mm:ss')}</h3>
                    {/*{retenRate?.[0]?.reten}*/}
                </div>
                {loading ?
                    null :
                    (
                    <div className={classes.blockMain}>
                        <div>
                            <span className={classes.title}>오늘 방문자 수 (DAU)</span>
                            <span className={classes.content}>{today?.dau?.length > 0 ? today?.dau[0].dau : 0 }</span>
                        </div>
                        <div>
                            <span className={classes.title}>오늘 가입 수(NRU)</span>
                            {<span className={classes.content}>{ today?.nru2?.length > 0 ? today?.nru2[0].nru : 0}</span>}
                        </div>
                        {/*<div>
                            <span className={classes.title}>오늘 가입 수(NRU)(과거지표)</span>
                            {<span className={classes.content}>{ today?.nru?.length > 0 ? today?.nru[0].nru : 0}</span>}
                        </div>*/}
                        <div>
                            <span className={classes.title}>오늘 게시글 수 </span>
                            {<span className={classes.content}>{ today?.posts?.length > 0 ? today?.posts[0].postsCount : 0}</span>}
                        </div>
                        <div>
                            <span className={classes.title}>탈퇴 건수</span>
                            {<span className={classes.content}>{today?.out?.length > 0 ? today?.out[0].outCount : 0}</span>}
                        </div>
                        {/*<div>
                        <span className={classes.title}></span>
                        <span>오늘 방문자 수 (DAU)</span>
                    </div>*/}
                    </div>
                    )}
            </div>
            <div className='bottom'>
                <GridContainer
                    justifyContent='flex-end'
                    className={classes.datePickerStyle}
                >
                    <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={2}
                        xl={2}
                        className={classes.responsiveStyle}
                        style={{'textAlign': 'center', 'marginTop': '10px '}}
                    >
                        기간별 조회
                    </GridItem>
                    <GridItem
                        xs={7}
                        sm={5}
                        md={2}
                        lg={2}
                        xl={2}
                        className={classes.styleDatePicker}
                    >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant='inline'
                                className={`${classes.resDateTimePicker}`}
                                format='yyyy/MM/dd'
                                TextFieldComponent={TextField}
                                id='date-picker-inline2'
                                autoOk={true}
                                value={formData?.fromDate}
                                onChange={(date) =>
                                    handleChangeFormDate(date, 'fromDate')
                                }
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </GridItem>
                    <GridItem
                        xs={12}
                        sm={12}
                        md={1}
                        lg={1}
                        xl={1}
                        className={classes.styleDatePicker}
                    >
                        <Box
                            className={classes.styleSymbol}
                            style={{'paddingTop': '10px'}}
                        >
                            <p>~</p>
                        </Box>
                    </GridItem>

                    <GridItem
                        xs={7}
                        sm={5}
                        md={2}
                        lg={2}
                        xl={2}
                        className={classes.styleDatePicker}
                    >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant='inline'
                                className={`${classes.resDateTimePicker}`}
                                format='yyyy/MM/dd'
                                TextFieldComponent={TextField}
                                id='date-picker-inline2'
                                autoOk={true}
                                value={formData?.toDate}
                                onChange={(date) =>
                                    handleChangeFormDate(date, 'toDate')
                                }
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </GridItem>


                    <GridItem
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        xl={2}
                        className={classes.responsiveStyle}
                    >
                        <Box className={classes.styleButtonSubmit}>
                            <Button
                                color='primary'
                                disabled={loading}
                                onClick={getListStaticsOfSearch}
                                style={{position: 'relative', top : '-5px'}}
                            >
                                검색
                            </Button>
                        </Box>
                    </GridItem>

                    <GridItem
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        xl={3}
                        className={classes.responsiveStyle}
                        style={{'display': 'flex', 'justifyContent': 'end'}}
                    >
                        <Box className={classes.styleButtonSubmit}>
                            <Button
                                color='primary'
                                disabled={loadingButtonGetExcel}
                                onClick={getExcelFile}
                            >
                                대시보드 엑셀 다운로드
                            </Button>
                            <Button
                                color='info'
                                disabled={loadingButtonGetExcel}
                                onClick={getUserStatisticExcelFile}
                            >
                                유저별 엑셀 다운로드
                            </Button>
                        </Box>
                    </GridItem>

                    {/*<GridItem
                        xs={12}
                        sm={12}
                        md={1}
                        lg={1}
                        xl={1}
                        className={classes.responsiveStyle}
                    >
                    </GridItem>*/}

                </GridContainer>

                {/*{loading ? (
                    <Spinner />
                ) : (*/}
                    <StatisticMainTable
                        headCells={headCells}
                        rows={listStaticsOfSearch}
                        setOrder={setOrder}
                        order={order}
                    />
                {/*)}*/}
                <Box mt={2} display='flex' justifyContent='flex-end'>
                    <Pagination
                        pagination={pagination}
                        setPagination={setPagination}
                        totalPages={totalPages}
                    />
                </Box>




            </div>






        </div>
    )
}

export default StatisticMain
