import * as actionTypes from '../actions/types'
import {GET_GOODS_MANAGING_LIST} from "../actions/types";

const initialState = {
    loading: true,
    error: null,
    contents: []
}

export default function (state = initialState, action) {
    const {type, payload} = action
    // let {id, orderNum} = payload
    switch (type) {
        case actionTypes.GET_GOODS_MANAGING_LIST:

            const {contents, metaData} = payload
            return {
                ...state,
                loading: false,
                contents: contents,
                metaData,
            }
        case actionTypes.DELETE_CONTENT_ACTION:
            return {
                ...state,
                loading: false,
                contents: state.contents.filter((content) => content.id !== payload),
                error: null,
            }
        case actionTypes.CHANGE_GOODS_MANAGING:
            let {id, orderNum, numOrder} = payload
            return {
                ...state,
                contents: state.contents.filter(item => {
                    if (item.id == id) {
                        item.orderNum = orderNum
                        item.numOrder = numOrder
                        return item
                    } else {
                        return item
                    }
                })
            }
        /*case actionTypes.CHANGE_ORDER_TAG_ACTION:
            /!*return {
              ...state,
              loading: false,
              tags: payload,
              error: null,
            }*!/


            return {
                ...state,
                contents: state.contents.filter(item => {
                    if (item.id == id) {
                        item.orderNum = orderNum
                        return item
                    } else {
                        return item
                    }
                })
            }*/
        default:
            return state
    }

}