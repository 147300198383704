import React from 'react'
import moment from 'moment'
import fileDownload from 'js-file-download'
import queryString from 'query-string'


import {makeStyles} from '@material-ui/core/styles'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import MenuList from '@material-ui/core/MenuList'
import TimePicker from '../components/TimePicker'
import TextField from 'components/Gm-TextField/TextFieldForDatePicker'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'

import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import {StaticOfSearchTable} from '../components/Table'
import Spinner from 'components/Spinner/Spinner'
import Pagination from 'components/Pagination/Pagination'

import {useSelector, useDispatch} from 'react-redux'
import {
    getListStaticsOfSearchAction,
    staticsOfSearchRequestErrorAction,
} from 'redux/actions/staticsOfSearchAction'
import staticsOfSearchApi from 'api/staticsOfSearchApi'

import styles from 'assets/jss/material-dashboard-pro-react/views/Statistics/statisticSearch.js'
import {da} from "date-fns/locale";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(styles)

const StatisticSearch2 = () => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const {
        loading: loadingPage,
        listStaticsOfSearch,
        metaData: {totalPages},
    } = useSelector((state) => ({
        loading: state.staticsOfSearch.loading,
        listStaticsOfSearch: state.staticsOfSearch.listStaticsOfSearch,
        metaData: state.staticsOfSearch.metaData,
    }))

    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [order, setOrder] = React.useState('desc')
    const [loading, setLoading] = React.useState(false)
    const [loadingButtonGetExcel, setLoadingButtonGetExcel] = React.useState(
        false,
    )
    const [pagination, setPagination] = React.useState(1)
    const [isFirstLoad, setIsFirstLoad] = React.useState(true)

    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz

    const [formData, setFormData] = React.useState({})
    const [todayTopClickTypeStatics, setTodayTopClickTypeStatics] = React.useState([])
    const [statics, setStatics] = React.useState({})

    const handleChangeFormDate = (date, key) => {
        setFormData({
            ...formData,
            [key]: moment(date).subtract(tz, 'hours').format('YYYY-MM-DD'),
        })
    }

    const handleChangeTimePicker = (event, key) => {
        setFormData({...formData, [key]: event.target.value})
    }


    // Handle API
    const compiled = _.template('${ date } ${ time }:00:00')
    const {type, limit, fromDate, toDate, fromTime, toTime} = formData

    const convertTime = (isFirstLoad, time) => {
        let result

        if (isFirstLoad) {
            result = _.split(
                moment().subtract(tz, 'hours').format('YYYY-MM-DD, H'),
                ',',
                2,
            )[1]?.trim()
        } else {
            const hour = time - tz;
            result = hour < 0 ? hour + 24 : hour;
        }
        return result <= 9 ? `0${result}` : result
    }

    const convertDate = (time, date) =>
        time >= tz ? date : moment(date).subtract(1, 'days').format('YYYY-MM-DD')

    let params = {
        type,
        limit,
        sortByTotalSearch: order.toUpperCase(),
        offset: pagination,
        fromDate: compiled({
            date: convertDate(fromTime, fromDate),
            time: convertTime(isFirstLoad, fromTime),
        }),
        toDate: compiled({
            date: convertDate(toTime, toDate),
            time: convertTime(isFirstLoad, toTime),
        }),
    }

    const getExcelFile = async () => {
        try {
            setLoadingButtonGetExcel(true)
            params['limit'] = 1000
            delete params['offset']
            const convertParamsToQueryUrl = queryString.stringify(params)

            const data = await staticsOfSearchApi.getExcelFile(
                convertParamsToQueryUrl,
            )
            fileDownload(data, 'data.xlsx')
            setLoadingButtonGetExcel(false)
        } catch (error) {
            setLoadingButtonGetExcel(false)
            dispatch(staticsOfClickRequestErrorAction(error?.response?.data))
        }
    }

    const dateFormatDay = (date) => {
        const dateForm = moment(date).format('YYYY.MM.DD.')
        const aa=  new Date(date).getDay()
        let day = '일'

        switch (aa){
            case 1:
                day = '월'
                break;
            case 2:
                day = '화'
                break;
            case 3:
                day = '수'
                break;
            case 4:
                day = '목'
                break;
            case 5:
                day = '금'
                break;
            case 6:
                day = '토'
                break;
            case 0:
                day = '일'
                break;
        }

        // return "a"
        return  dateForm + "("+ day + ")"

    }
    const onPostDetail = async (e,item)=>{
            if (e==null) return
            history.push({
                pathname: '/admin/post-detail/' +item.postId,
                state: { postId: item.postId },
            })
    }

    const getListStaticsOfSearch = async () => {
        try {
            setLoading(true)
            const {data} = await staticsOfSearchApi.getList(params)
            setStatics(data.statics)
        } catch (error) {
            setLoading(false)
            dispatch(staticsOfSearchRequestErrorAction(error?.response?.data))
        }
    }

    React.useEffect(() => {
        getListStaticsOfSearch()
        setIsFirstLoad(false)
    }, [pagination, order])

    return (
        <div className='statistic-click'>
            {/*{loadingPage ? (
        <Spinner />
      ) : (*/}
            {/*인기 검색어? 최근 하루 */}
            <span>검색어</span>
            <div style={{justifyContent : 'left',
                display: 'flex', marginBottom: '20px'}} >

                    {/*{sta}*/}
                <div className={classes.blockMain2}>
                    <div>
                        <div className={classes.title2}>
                            {dateFormatDay(moment(new Date()).add('day', -2))}
                            {/*인기 검색어 ( 조회수 순)*/}
                        </div>
                        <hr/>
                        {statics.yesterDay2TopSearchKeyStatics?.map((item) => {
                            return (
                                <div style={{justifyContent : 'space-between',
                                    display: 'flex'}}>
                                    <div style={{'fontSize': '15px'}}>
                                        {item.textSearch}
                                    </div>
                                    <div style={{'fontSize': '15px'}}>
                                        {item.totalCount}회
                                    </div>
                                </div>
                            )

                        })}
                    </div>
                </div>
                <div className={classes.blockMain2}>
                    <div>

                        <div className={classes.title2}>
                            {dateFormatDay(moment(new Date()).add('day', -1))}
                        </div>
                        <hr/>
                        {statics.yesterDayTopSearchKeyStatics?.map((item) => {
                            return (
                                <div style={{justifyContent : 'space-between',
                                    display: 'flex'}}>
                                    <div style={{'fontSize': '15px'}}>
                                        {item.textSearch}
                                    </div>
                                    <div style={{'fontSize': '15px'}}>
                                        {item.totalCount}회
                                    </div>
                                </div>
                            )

                        })}
                    </div>
                </div>
                <div className={classes.blockMain2}>
                        <div>

                            <div className={classes.title2}>
                                {dateFormatDay(moment(new Date()).add('day', 0))}
                            </div>
                            <hr/>
                            {statics.todayTopSearchKeyStatics?.map((item) => {
                                return (
                                    <div style={{justifyContent : 'space-between',
                                        display: 'flex'}}>
                                        <div style={{'fontSize': '15px'}}>
                                            {item.textSearch}
                                        </div>
                                        <div style={{'fontSize': '15px'}}>
                                            {item.totalCount}회
                                        </div>
                                    </div>
                                )

                            })}
                        </div>
                    </div>
            </div>
            {/*)}*/}
            <span>인기 포스트 top 10</span>
            <div style={{justifyContent : 'left',
                display: 'flex', marginBottom: '20px'}} >
                <div className={classes.blockMain2}>
                    <div>
                    <div className={classes.title2}>
                        {dateFormatDay(moment(new Date()).add('day', -2))}
                        {/*인기 검색어 ( 조회수 순)*/}
                    </div>
                    <hr/>
                    {statics.yesterDay2TopViewStatics
                        ?.map((item) => {
                        return (
                            <div style={{justifyContent : 'space-between',
                                display: 'flex'}}>
                                <div style={{'fontSize': '15px', 'min-height': '20px'}}>
                                    <img src={item.url} alt="" style={{'max-width': '100px'}} /> &nbsp;
                                    {item.title} - {item.nickname} &nbsp;
                                    <button onClick={(e)=>{
                                        onPostDetail(e, item)}
                                    }>상세</button>
                                </div>
                                <div style={{'fontSize': '15px'}}>
                                    {item.count}회
                                </div>
                            </div>
                        )

                    })}
                </div>
                </div>
                <div className={classes.blockMain2}>
                    <div>
                    <div className={classes.title2}>
                        {dateFormatDay(moment(new Date()).add('day', -1))}
                        {/*인기 검색어 ( 조회수 순)*/}
                    </div>
                    <hr/>
                    {statics.yesterDayTopViewStatics?.map((item) => {
                        return (
                            <div style={{justifyContent : 'space-between',
                                display: 'flex'}}>
                                <div style={{'fontSize': '15px', 'min-height': '20px'}}>
                                    <img src={item.url} alt="" style={{'max-width': '100px'}} /> &nbsp;
                                    {item.title} - {item.nickname} &nbsp;
                                    <button onClick={(e)=>{
                                        onPostDetail(e, item)}
                                    }>상세</button>
                                </div>
                                <div style={{'fontSize': '15px'}}>
                                    {item.count}회
                                </div>
                            </div>
                        )

                    })}
                </div>
                </div>
                <div className={classes.blockMain2}>
                    <div>
                        <div className={classes.title2}>
                            {dateFormatDay(moment(new Date()).add('day', 0))}
                            {/*인기 검색어 ( 조회수 순)*/}
                        </div>
                        <hr/>
                        {statics.todayTopViewStatics?.map((item) => {
                            return (
                                <div style={{justifyContent : 'space-between',
                                    display: 'flex'}}>
                                    <div style={{'fontSize': '15px', 'min-height': '20px'}}>
                                        <img src={item.url} alt="" style={{'max-width': '100px'}} /> &nbsp;
                                        {item.title} - {item.nickname} &nbsp;
                                        <button onClick={(e)=>{
                                            onPostDetail(e, item)}
                                        }>상세</button>
                                    </div>
                                    <div style={{'fontSize': '15px'}}>
                                        {item.count}회
                                    </div>
                                </div>
                            )

                        })}
                    </div>
                </div>
            </div>



        </div>
    )
}

export default StatisticSearch2
