import axiosInterceptors from 'api/axios'
import queryString from "query-string";

const postManagingApi = {
  getListPostManaging: (params) => {
    const url = `/post/postManaging/getListPostManaging`
    return axiosInterceptors.get(url, { params })
  },

  getListPostManagingExportExcel: (params) => {
    const url = `/post/postManaging/getListPostManagingExportExcel?${queryString.stringify(
        params,
    )}`
    return axiosInterceptors.get(url, {
      responseType: 'blob',
    })
  },

  getFileList: (params) => {
    const url = `/post/postManaging/getFileList`
    // return axiosInterceptors.get(url, { params })
    return axiosInterceptors.get(url, {
      params,
      responseType: 'blob',
    })
  },

  move: (params) => {
    const url = `/post/postManaging/movePosts?postIds=[${params.postIds}]&postInfoId=${params.postInfoId}`
    return axiosInterceptors.post(url)
  },


  movePush: (params) => {
    const url = `/post/postManaging/movePushPosts?postIds=[${params.postIds}]&postInfoId=${params.postInfoId}`
    return axiosInterceptors.post(url)
  },

  delete: (params) => {
    const url = `/post/postManaging/deleteMultiplePost?postIds=[${params.postIds}]`
    return axiosInterceptors.delete(url)
  },

  deletePush: (params) => {
    const url = `/post/postManaging/deleteMultiplePostPush?postIds=[${params.postIds}]`
    return axiosInterceptors.delete(url)
  },

  getPostDetail: (params) => {
    const url = `/post/postManaging/getDetailPostManaging`
    return axiosInterceptors.get(url, { params })
  },

  /*수정할때 */
  getPosts: (params) => {
    const url = `/post/postManaging/getPosts`
    return axiosInterceptors.get(url, { params })
  },

  toggleRecommendPost: (body) => {
    const url = '/post/postManaging/toggleRecommend'
    return axiosInterceptors.put(url, body)
  },

  toggleisNotiPost: (body) => {
    const url = '/post/postManaging/toggleisNotiPost'
    return axiosInterceptors.put(url, body)
  },

  getListCommentsOfPost: (params) => {
    const url = '/comment/getListComment'
    return axiosInterceptors.get(url, { params })
  },

  deleteComment: (params) => {
    const url = '/comment/deleteComment'
    return axiosInterceptors.delete(url, { params })
  },
  create: (params) =>{
    let url = '/post/postManaging/create';

    if (params?.postInfoId == 1){
      url = '/post/postManaging/createNewPost/FEED'
    }else if(params?.postInfoId == 5){
      url = '/post/postManaging/createNewPost/ISSUE'
    } else{
      url = '/post/postManaging/createNewPost/VOTE'
    }

    return axiosInterceptors.post(url, { params })

  },

  update: (params) =>{
    let url = '/post/postManaging/create';

    if (params?.postInfoId == 1){
      url = '/post/postManaging/updatePost/FEED'
    }else if(params?.postInfoId == 5){
      url = '/post/postManaging/updatePost/ISSUE'
    } else{
      url = '/post/postManaging/updatePost/VOTE'
    }

    return axiosInterceptors.post(url, { params })

  },
  toggleContentManaging:(params) =>{
    const url = '/post/toggleContentManaging'
    return axiosInterceptors.put(url, { params })
  }





}

export default postManagingApi
