import * as actionTypes from './types'

export const getNextCode = (payload) => ({
    type: actionTypes.GET_NEXT_CODE,
    payload,
})
export const getCodeListAction = (payload) => ({
    type: actionTypes.GET_CODE_LIST,
    payload,
})
export const getChildCodeListAction = (payload) => ({
    type: actionTypes.GET_CODE_CHILD_LIST,
    payload,
})
export const requestCommentsAction = (payload) => ({
    type: "",
    payload,
})