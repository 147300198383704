import axiosInterceptors from 'api/axios'

const staticsOfClickApi = {
  getListStaticsOfClick: (params) => {
    const url = '/statistics/getClickHistories'
    return axiosInterceptors.get(url, { params })
  },

  getListStaticsOfQuitReasonClick: (params) => {
    const url = '/statistics/searchs/getSearchJoinOutAll'
    return axiosInterceptors.get(url, { params })
  },
  getStaticsPosts: (params) => {
    const url = '/statistics/getStaticsPosts'
    return axiosInterceptors.get(url, { params })
  },
  getStaticsUsers: (params) => {
    const url = '/statistics/getStaticsUsers'
    return axiosInterceptors.get(url, { params })
  },

  getExcelFile: (params) => {
    const url = `/statistics/clicks/exportFileExcel?${params}`
    return axiosInterceptors.get(url, {
      responseType: 'blob',
    })
  },

  getExcelStaticsPosts: (params) => {
    const url = `/statistics/staticsPosts/exportFileExcel?${params}`
    return axiosInterceptors.get(url, {
      responseType: 'blob',
    })
  },

  getExcelStaticsUsers: (params) => {
    const url = `/statistics/staticsUsers/exportFileExcel?${params}`
    return axiosInterceptors.get(url, {
      responseType: 'blob',
    })
  },

  getExcelFileJoinOut: (params) => {
    const url = `/statistics/joinout/exportFileExcel?${params}`
    return axiosInterceptors.get(url, {
      responseType: 'blob',
    })
  },
}

export default staticsOfClickApi
