import React from 'react'

import {makeStyles} from '@material-ui/core/styles'
import Button from 'components/CustomButtons/Button.js'
import Box from '@material-ui/core/Box'
import EventsTable from './components/EventsTable.js'
import CircularProgress from '@material-ui/core/CircularProgress'

import {connect, useDispatch} from 'react-redux'
import {getListEventsAction, requestEventsAction, updateEventsAction} from 'redux/actions/eventsManagingAction.js'

import styles from 'assets/jss/material-dashboard-pro-react/views/Notice/notice'
import eventsManagingApi from "../../api/eventsManagingApi.js";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import TextField from "../../components/Gm-TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {FiberManualRecord} from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import {handleFormValue} from "../../util/common";

const useStyles = makeStyles(styles)

const EventManaging = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const {
        loading,
        metaData: {totalPages, totalRecords},
        // requestNoticeAction,
        getListEventsAction, requestEventsAction,
        events,
        match
    } = props

    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [orderBy, setOrderBy] = React.useState('first')
    const [order, setOrder] = React.useState('desc')
    const [type, setType] = React.useState(match.url.split('/admin/event-managing/')[1])
    const [typeName, setTypeName] = React.useState(match.url.split('/admin/event-managing/')[1] == 'EVENT' ? '이벤트' : '챌린지')
    const [selected, setSelected] = React.useState([])
    const [searchData, setSearchData] = React.useState({
        searchText: '',
        contentLinkType: 'ALL',
        outOfData: false //기간 지난것도 포함
    })

    const getListEvents = async () => {

        let orderString = '';

        if (orderBy == 'first') {
            //처음 정렬시 현재 front에 나오는 표출 순서대로
            orderString = 'status,ASC&numOrder,DESC&createdAt,DESC';
        } else {
            orderString = orderBy + ',' + order
        }

        let params = {
            limit: limit,
            offset: page,
            type: type,
            order: orderString,
            searchText: searchData.searchText,
            outOfData: searchData.outOfData,
            contentLinkType: searchData.contentLinkType,
            searchKey: 'title',
        }

        try {
            requestEventsAction()
            const {data} = await eventsManagingApi.getListEvents(params)
            getListEventsAction(data)
        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                // noticesWithErrAction(error.response.data)
                console.log(error.response.data)
            }
        }
    }

    const deleteEventsList = async (row) => {


        const selArr = {id : []}

        selected.forEach((item) => {
            // item = parseInt(item)

            selArr.id.push({
                item
            })
        })


        const {data} = await eventsManagingApi.destroy({id: selected})



        getListEvents()

    }

    const updateEvents = async (row) => {

        try {
            // setIsShowPostInfoInputField(true)
            // const body = {
            //     ...formData,
            // }



            delete row.createdAt
            delete row.updatedAt
            delete row.startAt
            delete row.endAt

            const {data} = await eventsManagingApi.updateStatus(row)


            dispatch(updateEventsAction(row))
        } catch
            (error) {
            // setIsShowPostInfoInputField(false)
            // handleShowError(error?.response?.data?.data?.error)
            // dispatch(postInfoManagingRequestError(error?.response?.data))
        }
    }


    React.useEffect(() => {
        getListEvents()
    }, [page + 1, limit, order]) //해당 값들이  state값이 변하면 해당 부분 실행됨....


    const headCells = [
        {
            id: 'id',
            align: 'center',
            disablePadding: false,
            label: 'No.',
            width: '50',
            minWidth: '50',
            allowSortable: true,
        },
        {
            id: 'title',
            align: 'left',
            disablePadding: false,
            label: typeName+' 명',
            minWidth: '340',
            allowSortable: true,
        },
        {
            id: 'createdAt',
            align: 'center',
            disablePadding: false,
            label: '생성 일자',
            minWidth: '170',
            allowSortable: true,
        },
        {
            id: 'startAt',
            align: 'center',
            disablePadding: false,
            label: '시작 일자 ~ 종료 일자',
            minWidth: '340',
            allowSortable: false,
        },
        {
            id: 'numOrder',
            align: 'center',
            disablePadding: false,
            label: '순서번호',
            width: '90',
            minWidth: '90',
            allowSortable: true,
        },
        {
            id: 'status',
            align: 'center',
            disablePadding: false,
            label: '상태',
            minWidth: '100',
            allowSortable: true,
        },
    ]

    return (
        <div className='notice'>

            {/*기본리스트 표출은 현재 활성화되는 순서대로*/}
            {/*생성일자 이벤트 명 id */}

            <GridContainer alignItems='center'>
                <GridItem
                    className={classes.gridContainerOne}
                    xs={12}
                    sm={4}
                    md={3}
                    lg={2}
                    xl={2}
                >
                    <TextField
                        id='user-managing-select'
                        select
                        size='small'
                        className={classes.widthTextField}
                        label='타입'
                        value={setSearchData?.contentLinkType}
                        onChange={(e)=>{
                            handleFormValue(e.target.value, 'contentLinkType', searchData, setSearchData)}
                        }
                    >
                        {/*enum('URL', 'VOTE', 'APPLY', 'WEB_VIEW', 'COMMENT', 'BINGO', 'DAILY_CHECK', 'MULTI')*/}
                        <MenuItem value={'ALL'}>전체</MenuItem>
                        <MenuItem value={'URL'}>외부URL</MenuItem>
                        <MenuItem value={'VOTE'}>투표형</MenuItem>
                        <MenuItem value={'APPLY'}>응모형</MenuItem>
                        <MenuItem value={'WEB_VIEW'}>내부URL</MenuItem>
                        <MenuItem value={'COMMENT'}>댓글</MenuItem>
                        <MenuItem value={'BINGO'}>빙고</MenuItem>
                        <MenuItem value={'DAILY_CHECK'}>출석체크</MenuItem>
                        <MenuItem value={'MULTI'}>혼합형</MenuItem>
                    </TextField>
                </GridItem>
                <GridItem
                    className={classes.gridContainerOne}
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                >

                    <TextField
                        id='post-managing-textfield'
                        size='small'
                        className={classes.textFieldOne}
                        placeholder={typeName +'명을 입력해주세요'}
                        name='clientId'
                        value={searchData.searchText}
                        onChange={(e) => {
                            handleFormValue(e.target.value, 'searchText', searchData, setSearchData)
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                    />


                </GridItem>
                <GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
                    <Button
                        color='primary'
                        disabled={loading}
                        onClick={(e)=>{
                            setPage(0)

                            getListEvents()
                        }}
                    >
                        검색
                    </Button>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={11} sm={11} md={11} style={{padding: '20px !important'}}>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={searchData.outOfData ==false}
                                onChange={(e) => handleFormValue(false,'outOfData', searchData, setSearchData)}
                                value={'0'}
                                aria-label="T"
                                icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                checkedIcon={
                                    <FiberManualRecord className={classes.radioChecked}/>
                                }
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                            />
                        }
                        classes={{
                            label: classes.p0
                        }}
                        label="기간 지난 항목 제외"
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                checked={searchData.outOfData}
                                onChange={(e) => handleFormValue(true, 'outOfData', searchData, setSearchData)}
                                value={'0'}
                                aria-label="T"
                                icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                checkedIcon={
                                    <FiberManualRecord className={classes.radioChecked}/>
                                }
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                            />
                        }
                        classes={{
                            label: classes.p0
                        }}
                        label="전체"
                    />
                </GridItem>
            </GridContainer>
            <GridContainer alignItems='center'>
                <GridItem xs={6}>
                    <Box my={2}>
                        <TextField
                            className={`${classes.textFieldTwo} ${classes.textFieldTwoChildOne}`}
                            id='post-managing-textfield-show-info1'
                            size='small'
                            value={totalRecords}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>전체</InputAdornment>
                                ),
                                readOnly: true,
                            }}
                        />
                    </Box>
                </GridItem>
                <GridItem xs={4}>
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2} xl={2}>

                    <Box display='flex' justifyContent='flex-end' className='add-notice'>
                        <Button
                            color='primary'
                            onClick={() => props.history.push('/admin/event-managing/detail/'+ type  )}
                        >
                            추가하기
                        </Button>
                        <Button
                            color='info'
                            onClick={deleteEventsList}
                        >
                            삭제하기
                        </Button>
                    </Box>

                    <Box display='flex' justifyContent='flex-end' className='add-notice'>

                    </Box>
                </GridItem>
            </GridContainer>


            <Box my={2} className='EventsTable'>
                {loading ? (
                    <CircularProgress size={30} className={classes.buttonProgress}/>
                ) : (
                    <EventsTable
                        rows={events}
                        totalPages={totalPages}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        page={page}
                        setPage={setPage}
                        setLimit={setLimit}
                        headCells={headCells}
                        classes={useStyles}
                        order={order}
                        setOrderBy={setOrderBy}
                        setOrder={setOrder}
                        orderBy={orderBy}
                        updateEvents={updateEvents}
                        type={type}
                        typeName={typeName}
                        setSelected={setSelected}
                        selected={selected}
                    />
                )}
            </Box>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.eventsReducer.loading,
        events: state.eventsReducer.events,
        metaData: state.eventsReducer.metaData,
    }
}

export default connect(mapStateToProps, {
    getListEventsAction, requestEventsAction,
    updateEventsAction
})(EventManaging)
