import axiosInterceptors from 'api/axios'
import axiosInterceptors2 from "./axios2";

const tagApi = {
  createTag: (body) => {
    const url = `/manageTag/create`
    return axiosInterceptors.post(url, body)
  },

  deleteTag: (params) => {
    const url = `/manageTag/removeTag`
    return axiosInterceptors.delete(url, { params })
  },

  createMultipleTags: (body) => {
    const url = `/tag/createMultipleTag`
    return axiosInterceptors.post(url, body)
  },

  getListTags: (params) => {
    const url = `/manageTag/getListTag`
    return axiosInterceptors.get(url, { params })
  },

  updateOrderTag: (body) => {
    const url = `/manageTag/updateOrder`
    return axiosInterceptors.put(url, body)
  },

  updateTag: (body) => {
    const url = `/manageTag/update`
    return axiosInterceptors.put(url, body)
  },

  updateOrderNumStatic: (body) => {
    const url = `/manageTag/updateOrderNumStatic`
    return axiosInterceptors.put(url, body)
  },
  getTagOne: (params) => {
    const url = `/manageTag/detail`
    return axiosInterceptors.get(url, {params})
  }

}

export default tagApi
