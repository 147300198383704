import {connect, useDispatch} from "react-redux";
import React from "react";
import Spinner from "../../components/Spinner/Spinner";
import {
    changeGoodsManagingAction,
    createGoodsManaging,
    getGoodsManagingListAction
} from "../../redux/actions/goodsManagingAction";
import {createTagAction, orderTagAction} from "../../redux/actions/tagManaging";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/PostManaging/postManaging";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CustomTextField from "../../components/Gm-TextField/TextField";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "../../components/Gm-TextField/TextFieldForDatePicker";
import Box from "@material-ui/core/Box";
import TimePicker from "../Statistics/components/TimePicker";
import Button from "../../components/CustomButtons/Button";
import ContentManagingApi from "../../api/contentManagingApi";
import GoodsManagingApi from "../../api/goodsManagingApi";
import {orderContentAction} from "../../redux/actions/contentsManagingAction";
import postManagingApi from "../../api/postManagingApi";
import goodsManagingApi from "../../api/goodsManagingApi";
import writerGoodsManagingApi from "../../api/writerGoodsManagingApi";
import WriterMainManagingApi from "../../api/writerMainManagingApi";
import {handleFormEvent} from "../../util/common";
import tagApi from "../../api/tagApi";
import {unmountComponentAtNode} from "react-dom";
import stickerApi from "../../api/stickerApi";
import StickerModal from "./components/StickerModal";
import CustomButton from "../../components/CustomButtons/Button";
import Switch from "@material-ui/core/Switch";
import settingApi from "../../api/settingApi";
import StickerApi from "../../api/stickerApi";

const useStyles = makeStyles(styles)

const StickerTagmanagingDetail = (props) => {

    // const classes = useStyles()
    const dispatch = useDispatch()
    const classes = useStyles()

    const {
        metaData: metadata,
        contents,
        getContentManagingAction,
        changeGoodsManagingAction,
        match,
    } = props

    //-------------- state 세팅
    const [loading, setLoading] = React.useState(false)
    const [mode, setMode] = React.useState('I')
    const [alert2, setAlert] = React.useState(null)
    const [sticker, setSticker] = React.useState(null)
    const [formData, setFormData] = React.useState({
        id: isNaN(match?.params?.id) ? 0 : match?.params?.id,
        limit: 1000,
        offset: 0,
        page: 0,
        title: ''
    })

    //./ state 세팅

    const openGoodsView = async () => {
        setAlert(
            <StickerModal
                item={sticker}
                hideAlert={(e) => {
                    setAlert(null)
                }}
                setAlert={setAlert}
                tagName={formData.title}
                // getMemberList={getMemberList}
                // postManagingApi={postManagingApi}
                // goodsManagingApi={goodsManagingApi}
                dispatch={dispatch}
                setSticker={setSticker}
                sticker={sticker}
            />
        )
        // alert("openGoodsView")
    }

    const updateTag = async () => {

        let convertInputTag
        // if (match?.params?.type == 'MAIN_BOTTOM')
        // if (formData.title.includes('#')) {
        convertInputTag = formData.title
        // } else {
        //     convertInputTag = `#${formData.title}`
        // }

        //생성
        const data = await tagApi.updateTag({
            id: formData.id,
            tagName: convertInputTag
        });

        createTagAction(data)

        location.reload()
    }
    const updateSticker = async (item) => {
        setAlert(
            <StickerModal
                item={item}
                hideAlert={(e) => {
                    setAlert(null)
                }}
                setAlert={setAlert}
                tagName={formData.title}
                // getMemberList={getMemberList}
                // postManagingApi={postManagingApi}
                // goodsManagingApi={goodsManagingApi}
                dispatch={dispatch}
                setSticker={setSticker}
                sticker={item}
            />
        )
        // alert("openGoodsView")
    }

    const getListGoodsManaging = async () => {
        try {

            const params = {
                limit: 10000,
                offset: 1,
                tagName: formData.title
                // type: path.split('content-managing/')[1]
            }
            setLoading(true)

            if (params.tagName == null || params.tagName == "") return true
            const {data} = await stickerApi.getStickers(params)

            const data2 = {
                contents: data.stickers
            }

            setLoading(false)

            dispatch(getGoodsManagingListAction(data2))
            // alert(formData.title)
            // dispatch(getGoodsManagingListAction(data))
            // alert("fddfd")

        } catch (error) {
            setLoading(false)
            if (error && error.response && error.response.data) {
                // dispatch(bestUserRequestErrorAction(error.response.data))
            }
        }
    }
    const getManageTag = async () => {
        try {

            setLoading(true)
            if (formData.id == null){
                setLoading(false)
                return
            }

            const {data} = await tagApi.getTagOne({id: formData.id})

            setFormData({'title': data.tagName,id: data.id})

            setLoading(false)

        } catch (error) {
            setLoading(false)
            if (error && error.response && error.response.data) {
                // dispatch(bestUserRequestErrorAction(error.response.data))
            }
        }
    }


    React.useEffect(async () => {
        await getManageTag().then(async () => {
            await getListGoodsManaging()
        })
    }, [formData.limit, match?.params?.type])

    const deleteGoodsContentWriterGoods = async (id) => {
        const params = {
            id,
        }

        try {
            // requestTagAction()
            await GoodsManagingApi.deleteGoodsContentWriterGoods(params)

            await getListGoodsManaging()

        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                // createTagErrAction(error.response.data)
                // if (error.response.data.data.isShow === true) {
                //     handleClick({
                //         vertical: 'top',
                //         horizontal: 'center',
                //         message: error.response.data.data.error,
                //     })
                // } else {
                //     handleClick({
                //         vertical: 'top',
                //         horizontal: 'center',
                //         message: 'error',
                //     })
                // }
            }
        }
    }
    const toggleIsShow = async (id, isShow)=>{
        try {
            // setLoading(true)
            const { data
            } = await stickerApi.toggleIsShow({
                id: id,
                isShow: !isShow
            })
            location.reload()
            // setAiOnOff(!aiOnOff)
            // setLoading(false)
        } catch (error) {
            console.log(error.response)
            // setLoading(false)
        }
    }

    const deleteGoodsContentWriterGoodsAll = async (id) => {
        const params = {
            goodsManagingId:formData.id,
        }


        try {

            if (confirm('상품을 전체 삭제하겠습니까?')){

                // requestTagAction()
                await writerGoodsManagingApi.deleteGoodsManagingWriterGoods(params)

                location.reload()
            }else{
                return
            }
            // await getListGoodsManaging()

        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                // createTagErrAction(error.response.data)
                // if (error.response.data.data.isShow === true) {
                //     handleClick({
                //         vertical: 'top',
                //         horizontal: 'center',
                //         message: error.response.data.data.error,
                //     })
                // } else {
                //     handleClick({
                //         vertical: 'top',
                //         horizontal: 'center',
                //         message: 'error',
                //     })
                // }
            }
        }
    }
    const changeIndexOfArr = async (up, down, orderNum, id, index) => {
        const deepCloneData = [...contents]
        const currentIndex = index
        if (up) {
            if (index !== 0) {
                // requestTagAction()
                try {
                    let changeUpIndex = index - 1
                    let body = {
                        id,
                        action: 'UP',
                        goodsManagingId: match?.params?.id
                    }
                    await GoodsManagingApi.updateOrderWriterGoods(body)

                    let updateorderNum = deepCloneData.map((item, i) => {
                        if (currentIndex === i) {
                            item.orderNum = item.orderNum - 1
                        }
                        ``
                        if (changeUpIndex === i) {
                            item.orderNum = item.orderNum + 1
                        }

                        return item
                    })

                    // updateorderNum.sort((a, b) => a.orderNum - b.orderNum)
                    await getListGoodsManaging()
                } catch (error) {
                    console.log(error.response)
                }
            }
        } else if (down) {
            if (index !== deepCloneData.length - 1) {
                // requestTagAction()
                try {
                    let changeDownIndex = index + 1
                    let body = {
                        id,
                        action: 'DOWN',
                        goodsManagingId: match?.params?.id
                    }
                    await GoodsManagingApi.updateOrderWriterGoods(body)

                    let updateorderNum = deepCloneData.map((item, i) => {
                        if (currentIndex === i) {
                            item.orderNum = item.orderNum + 1
                        }

                        if (changeDownIndex === i) {
                            item.orderNum = item.orderNum - 1
                        }

                        return item
                    })

                    updateorderNum.sort((a, b) => a.orderNum - b.orderNum)
                    // orderContentAction(updateorderNum)
                    await getListGoodsManaging()

                } catch (error) {
                    console.log(error.response)
                }
            }
        }
    }


    return (
        <div className='contents-managing'>

            {loading ? (
                <Spinner/>
            ) : (
                <div>
                    <Paper className={classes.paper} variant='outlined' square>
                        {alert2}

                        <GridContainer>
                            <GridItem
                                className={classes.symBolTag}
                                container
                                alignItems='center'
                                justifyContent='center'
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                            >
                                {/*<p>#</p>*/}
                            </GridItem>
                            <GridItem
                                container
                                alignItems='center'
                                xs={8}
                            >
                                <CustomTextField
                                    className={classes.textFieldAddTag}
                                    id='tag-register-new'
                                    label='태그명을 입력하세요'
                                    fullWidth={true}
                                    size='small'
                                    onChange={(e)=>{
                                    handleFormEvent(e, 'title', formData, setFormData)}}
                                    value={formData?.title}
                                    // defaultValue='Default Value'
                                    variant='outlined'
                                />
                            </GridItem>
                            <GridItem
                                container
                                className={classes.customStyleBtn}
                                xs={1}
                            >
                                <Button disabled={loading} onClick={() => updateTag()} color='primary'>
                                    {'수정'}
                                </Button>
                                <Button disabled={loading} onClick={() => openGoodsView()} color='primary'>
                                    {'스티커추가'}
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </Paper>

                    <div style={{textAlign: 'right'}}>
                        {/*<Button disabled={loading} onClick={() => deleteGoodsContentWriterGoodsAll()} color='primary'>
                            {'전체삭제'}
                        </Button>*/}
                    </div>
                    <TableContainer component={Paper}>

                        <Paper
                            variant='outlined'
                            square
                        >
                            <GridContainer className={[classes.paperSwitch]}>
                                <GridItem
                                    container
                                    alignItems='center'
                                    justifyContent='center'
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    xl={1}
                                >
                                    No.
                                </GridItem>
                                <GridItem
                                    container
                                    alignItems='center'
                                    justifyContent='center'
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    xl={1}>
                                    썸네일
                                </GridItem>
                                <GridItem
                                    container
                                    alignItems='center'
                                    justifyContent='center'
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}>
                                    이름
                                </GridItem>
                                <GridItem
                                    container
                                    alignItems='center'
                                    justifyContent='center'
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}>
                                    작가명
                                </GridItem>
                                <GridItem
                                    container
                                    direction='column'
                                    alignItems='center'
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    xl={1}>
                                    등록일자
                                </GridItem>
                                <GridItem
                                    container
                                    direction='column'
                                    alignItems='center'
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}>
                                    수정
                                </GridItem>
                                <GridItem
                                    container
                                    direction='column'
                                    alignItems='center'
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    xl={1}>
                                    노출여부
                                </GridItem>
                                <GridItem>

                                </GridItem>
                            </GridContainer>

                        </Paper>
                        {/*#태그*/}


                        {contents?.map((item, i) => {
                            return (
                                <Paper
                                    key={i}
                                    // className={[classes.paper, !(new Date(item.startAt).getTime() < new Date().getTime()  &&   new Date().getTime() < new Date(item.endAt).getTime()) && classes.notNow ]}
                                    variant='outlined'
                                    square
                                >
                                    <GridContainer>
                                        <GridItem
                                            // className={classes.symBolTag}
                                            container
                                            alignItems='center'
                                            justifyContent='center'
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={1}
                                            xl={1}
                                        >
                                            {/*<p>{item.orderNum}</p>*/}
                                            <p>{i + 1}</p>
                                        </GridItem>
                                        <GridItem
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={1}
                                            xl={1} container
                                            alignItems='center'
                                            justifyContent='center'>
                                            {/*{item.title}*/}
                                            <img src={item?.imgUrl}
                                                 style={{maxWidth: '100px'}}/>
                                        </GridItem>
                                        <GridItem
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2} container
                                            alignItems='center'
                                            justifyContent='center'>
                                            {item.name}
                                        </GridItem>
                                        <GridItem
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2} container
                                            alignItems='center'
                                            justifyContent='center'>
                                            {item.writerName}
                                        </GridItem>
                                        <GridItem
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={1}
                                            xl={1} container
                                            alignItems='center'
                                            justifyContent='center'>
                                            {moment(item && item?.writerGoods?.createdAt).format('YYYY/MM/DD h:mmA')}
                                        </GridItem>
                                        <GridItem
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2} container
                                            alignItems='center'
                                            justifyContent='center'>
                                            <CustomButton color="primary" mr={2} variant="contained"
                                                          onClick={() => updateSticker(item)}
                                            >수정</CustomButton>
                                        </GridItem>
                                        <GridItem
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={1}
                                            xl={1} container
                                            alignItems='center'
                                            justifyContent='center'>
                                            <Switch
                                                checked={item.isShow}
                                                onChange={(e)=> {
                                                    toggleIsShow(item.id, item.isShow)
                                                }}
                                                name='checkedA'
                                                inputProps={{'aria-label': 'secondary checkbox'}}
                                            />

                                        </GridItem>
                                        <GridItem
                                            container
                                            direction='column'
                                            alignItems='center'
                                            style={{'display': 'flex','justify-content': 'center'}}
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <TextField align='right'
                                                       onChange={(e)=>{
                                                           dispatch(changeGoodsManagingAction({
                                                               id: item.id,
                                                               orderNum: e.target.value
                                                           }))
                                                       }}
                                                       onKeyUp={async (e) => {
                                                           if (e.keyCode == 13) {
                                                               if (confirm("변경하시겠습니까?")) {
                                                                   await StickerApi.updateOrderNum({
                                                                       id: item.id, orderNum: e.target.value
                                                                   });
                                                                   // location.reload()
                                                               }
                                                           }
                                                       }}
                                                       value={item.orderNum}
                                                       className={classes.inputTextAdd__textField}
                                                       label='숫자입력 후 enter'
                                                       name='orderNum'
                                                       variant='outlined'
                                                       size='small'
                                            />
                                        </GridItem>





                                        {/*<GridItem
                                            container
                                            // justifyContent='flex-end'
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={1}
                                            xl={1}
                                        >
                                            <IconButton
                                                disabled={loading}
                                                aria-label='delete'
                                                onClick={() => deleteGoodsContentWriterGoods(item.id)}
                                            >
                                                <HighlightOffIcon/>
                                            </IconButton>
                                        </GridItem>*/}
                                    </GridContainer>
                                </Paper>
                            )
                        })}
                    </TableContainer>
                </div>
            )}

        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.goodsManagingReducer.loading,
    error: state.goodsManagingReducer.error,
    contents: state.goodsManagingReducer.contents
})
export default connect(mapStateToProps, {
    getGoodsManagingListAction,
    orderTagAction,
    changeGoodsManagingAction
    // deleteGoodsContentWriterGoodsAction/

})(StickerTagmanagingDetail)
