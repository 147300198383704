import React, {useEffect} from 'react'
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import SweetAlert from "react-bootstrap-sweetalert";
import Button from "../../../components/CustomButtons/Button";
import Box from "@material-ui/core/Box";
import axiosInterceptors from "../../../api/axios";
import settingApi from "../../../api/settingApi";
import {setAiPhraseAction} from "../../../redux/actions/aiManagingAction";
import commentsManangingApi from "../../../api/commentsManangingApi";


const AiSystemPhraseModal = (props) => {

    const {
        hideAlert,
        dispatch,
        setAiPhraseAction,
        title
    } = props

    const [clovaAiSystemPhrase, setClovaAiSystemPhrase] = React.useState(null)

    const getAiPhrase = async () => {
        try {

            const data = await settingApi.getAiPhrase();
            setClovaAiSystemPhrase(data?.data?.clovaAiSystemPhrase)

            // dispatch(setAiPhraseAction({clovaAiSystemPhrase: data?.data?.clovaAiSystemPhrase}))

        } catch (e) {
            // setLoadingBtn(false)
            // console.log(error.response)
            // if (error && error.response && error.response.data) {
            //     postManagingErrAction(error.response.data)
            //
        }
    }
    const getAiComment = async (id)=>{
        try {

            const data = await commentsManangingApi.getAiComment({
                id
            })
            setClovaAiSystemPhrase(data?.data?.clovaAiSystemPhrase)

        } catch (e) {
        }
    }

    const updateAiPhrase = async () => {
        try {


            const data = await settingApi.updateAiPhrase({
                clovaAiSystemPhrase: clovaAiSystemPhrase
            });
            setClovaAiSystemPhrase(data?.data?.clovaAiSystemPhrase)


            // dispatch(setAiPhraseAction({clovaAiSystemPhrase: data?.data?.clovaAiSystemPhrase}))

            if (data?.data != null){

                alert("저장에 성공했습니다.")

                hideAlert()
            }




        } catch (e) {
        }

    }
    React.useEffect(() => {
        getAiPhrase()
    },[])


    return (
        <>
            <SweetAlert
                style={{display: 'block', marginTop: '-100px', width: '80em', zIndex: '1034',}}
                title={title}
                onConfirm={() => {

                }}
                showConfirm={false}
                onCancel={() => hideAlert()}
            >
                <TextareaAutosize
                    // className={classes.textareaAutosize}
                    style={{'width': '80%', 'height': '400px', 'overflow': 'scroll'}}
                    minRows={5}
                    maxRows={9}
                    aria-label='maximum height'
                    placeholder='내용 입력'
                    value={clovaAiSystemPhrase}
                    onChange={(e)=>{
                        setClovaAiSystemPhrase(e.target.value)
                    }}
                    name='content'
                />


                <Box mt={1} display='flex' justifyContent='center'>
                    <Button
                        onClick={hideAlert}
                        // className={classes.marginRightBtnInModal}
                        color='danger'
                    >
                        취소
                    </Button>
                    <Button
                        color='success'
                        // disabled={loading}
                        onClick={updateAiPhrase}
                    >
                        수정
                    </Button>
                </Box>

            </SweetAlert>


        </>
    )

}

export default AiSystemPhraseModal