import React from 'react'
import clsx from 'clsx'
import moment from 'moment'

import {lighten, makeStyles, useTheme} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import Checkbox from '@material-ui/core/Checkbox'
import Pagination from "../../../components/Pagination/Pagination";
import Box from "@material-ui/core/Box";
import {priceFilter} from "../../../util/filter";

const useStyles = makeStyles({
    table: {
        minWidth: 1000,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    groupBtnDropdown: {
        boxShadow: 'unset',
    },
    menuList: {
        '& li': {
            '& span': {
                paddingLeft: '0',
            },
        },
    },
    customBtnMI: {
        borderColor: '#222',
        color: '#222',
        '&:hover': {
            borderColor: 'unset',
            boxShadow: 'none',
        },
    },
    setZindexMenuList: {
        '& div:last-child': {
            zIndex: 9999,
        },
    },
})

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, headCells } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'></TableCell>
                <TableCell style={{ width: '50px' }}>No.</TableCell>
                {headCells.map((headCell, i) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            minWidth: headCell.minWidth ? headCell.minWidth : 170,
                        }}
                    >
                        <TableSortLabel
                            // active={orderBy === headCell.id}
                            active={headCell.allowSortable}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            // onClick={createSortHandler(headCell.id)}
                            onClick={
                                headCell.allowSortable
                                    ? createSortHandler(headCell.id)
                                    : undefined
                            }
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}


const Row = (props) => {

    const {row, key, index, page, selected, setSelected, dispatch} = props

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selectedChk.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        setSelected(newSelected)
    }

    const isSelected = (id) => selected.indexOf(id) !== -1
    const isItemSelected = isSelected(row?.id)
    const labelId = `enhanced-table-checkbox-${index}`


    return (
        <React.Fragment>
            <TableRow hover key={index}>
                <TableCell padding='checkbox'>
                    <Checkbox
                        onClick={(event) => handleClick(event, row.id)}
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                </TableCell>
                <TableCell align='left'>{row.id}</TableCell>
                <TableCell align='right'>{row.goodsName}<img src={row.thumbnail} alt="" width={30}/></TableCell>
                <TableCell align='right'>{row.brandName}</TableCell>
                <TableCell align='right'>

                    <span style={{'color': 'red'}}>{row.salePersent}%</span>&nbsp;
                    <span style={{
                        'color': 'black',
                        'fontSize': '16px'
                    }}>{priceFilter(row.salePrice)}</span>&nbsp;
                    <span style={{
                        'color': 'grey',
                        'textDecoration': 'line-through'
                    }}>{priceFilter(row.defaultPrice)}</span>
                </TableCell>
                {/*<TableCell align='left'>{row.owner?.nickname}</TableCell>*/}
                <TableCell align='right'>
                    {moment(row?.createdAt).format('YYYY/MM/DD')}
                </TableCell>
            </TableRow>
        </React.Fragment>
    )

}


export default function GoodsTables(props) {
    const classes = useStyles()

    const {
        rows,
        headCells,
        sortable,
        totalRecords,
        setRowsPerPage,
        rowsPerPage,
        setPage,
        page,
        order,
        setOrder,
        setOrderBy,
        orderBy,
        totalPages,
        setSelected,
        selected,
        dispatch
    } = props



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }


    return (
        <TableContainer component={Paper}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <Table className={classes.table} aria-label='simple table'>
                <EnhancedTableHead
                    sortable={sortable}
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headCells}
                />

                <TableBody>
                    {rows.map((row, index) => {
                        return (
                            <Row
                                key={row.id}
                                row={row}
                                index={index}
                                page={page}
                                setSelected={setSelected}
                                selected={selected}
                                // ModalMoreVert={ModalMoreVert}
                                dispatch={dispatch}
                                // getData={getData}
                                // goodsManagingApi={goodsManagingApi}

                            >

                            </Row>
                        )

                    })}
                </TableBody>
            </Table>
            <Box mb={2} mt={2} display='flex' justifyContent='flex-end'>
                <Pagination
                    pagination={page==0 ? 1 : page} 
                    setPagination={setPage}
                    totalPages={totalPages}
                />
            </Box>
        </TableContainer>
    )
}
