import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import moment from 'moment'

import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Spinner from 'components/Spinner/Spinner'
import EditBanner from './components/EditBanner'
import Snackbar from '@material-ui/core/Snackbar'

import {connect, useDispatch, useSelector} from 'react-redux'
import {
    bannerManagingRequestErrorAction,
    createBannerManagingAction,
    deleteBannerManagingAction,
    editBannerManagingAction,
    getListBannerManagingAction,
    updateOrderBannerManagingAction,
} from 'redux/actions/mainManaging/bannerManagingAction'
import bannerManagingApi from 'api/mainManaging/bannerManagingApi'

import styles from 'assets/jss/material-dashboard-pro-react/views/MainManaging/bannerManaging'
import stylesModal from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import TextField from "../../components/Gm-TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import BannerListTable from "./components/BannerTable";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {FiberManualRecord} from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import {CustomAlert} from "../../util/common";

const useStyles = makeStyles(styles)
const useStylesModal = makeStyles(stylesModal)


const BannerManaging = (props) => {

    const {
        bannerList,
        metaData: {totalPages, totalRecords},
    } = props

    const classes = useStyles()
    const classesModal = useStylesModal()

    const dispatch = useDispatch()
    const {loading, listBannerManagings} = useSelector((state) => ({
        loading: state.bannerManaging.loading,
        listBannerManagings: state.bannerManaging.listBannerManagings,
    }))

    //추가
    const [select, setSelect] = React.useState('NORMAL')
    const [webViewTitle, setwebViewTitle] = React.useState('')
    const [isResetPagination, setIsResetPagination] = React.useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [orderBy, setOrderBy] = React.useState('first')
    const [order, setOrder] = React.useState('desc')
    const [searchText, setSearchText] = React.useState('')
    const [selectedChk, setSelectedChk] = React.useState([])

    const [searchData, setSearchData] = React.useState({
        exposure: 'ALL',
        searchText: '',
        outOfData: false //기간 지난것도 포함
    })

    const handleForm = (value, key) => {
        //vue의 데이터 매핑 형태였던 것 같은데.... key도 매핑되네 히야...

        switch (key) {
            default:
                setSearchData({
                    ...searchData,
                    [key]: value,
                })

        }
    }
    const handleChangeSelect = (event) => {
        setSelect(event.target.value)
        !isResetPagination && setIsResetPagination(true)
    }

    const handleChangeSearchInput = (e) => {
        setwebViewTitle(e.target.value)
        !isResetPagination && setIsResetPagination(true)
    }

    const headCells = [
        {
            id: 'id',
            numeric: true,
            disablePadding: false,
            label: 'NO.',
            allowSortable: true,
        },
        {
            id: 'exposure',
            numeric: false,
            disablePadding: false,
            label: '타입',
            allowSortable: true,
        },
        {
            id: 'webViewTitle',
            numeric: false,
            disablePadding: false,
            label: '배너 명',
            allowSortable: true,
        },
        // {
        //   id: 'position',
        //   numeric: true,
        //   disablePadding: false,
        //   label: '배너 위치',
        //   allowSortable: true,
        // },
        // {
        //   id: 'mainImage.filename',
        //   numeric: true,
        //   disablePadding: false,
        //   label: '이미지 명',
        //   allowSortable: false,
        // },
        // {
        //   id: 'linkUrl',
        //   numeric: true,
        //   disablePadding: false,
        //   label: 'URL',
        //   allowSortable: false,
        // },
        {
            id: 'type',
            numeric: true,
            disablePadding: false,
            label: '링크 타입',
            allowSortable: true,
        },
        {
            id: 'startAt',
            numeric: true,
            disablePadding: false,
            label: '시작일',
            allowSortable: true,
        },
        {
            id: 'endAt',
            numeric: true,
            disablePadding: false,
            label: '종료일',
            allowSortable: true,
        },
        {
            id: 'createdAt',
            numeric: true,
            disablePadding: false,
            label: '생성일',
            allowSortable: true,
        },
        // {
        //   id: 'isSend',
        //   numeric: false,
        //   disablePadding: false,
        //   label: '발송여부',
        //   allowSortable: true,
        // },
        {
            id: 'orderNum',
            numeric: true,
            disablePadding: false,
            label: '순서',
            allowSortable: true,
            minWidth: 100
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: '활성화',
            allowSortable: true,
        }
    ]

    //==========================
    const [alert2, setAlert] = React.useState(null)
    const [imageFile, setImageFile] = React.useState(null)
    const [loadingBtn, setLoadingBtn] = React.useState(false)
    const [loadingBtnChangeOrder, setLoadingBtnChangeOrder] = React.useState(
        false,
    )
    const [formData, setFormData] = React.useState({
        title: '',
        url: '',
    })
    const [showNotification, setShowNotification] = React.useState({
        open: false,
        message: '',
    })

    const handleCloseOpen = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setShowNotification({...showNotification, open: false})
    }

    // Modal
    const ModalMoreVert = (item) => {

        if (item === null) {
            const d = new Date()
            let tz = d.getTimezoneOffset() / 60
            tz = tz < 0 ? tz * -1 : tz
            item = {
                startAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
                endAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
                numOrder: 0,
                eventsId: null,
                type: 'URL',
                webViewTitle: '',
                mainImage: null,
                subImage: null,
                linkUrl: '',
                position: 'MAIN',
                editBannerAction: true,
                exposure: 'BANNER',
                status: 'ACTIVE'
            }

        }

        setAlert(
            <EditBanner
                item={item}
                hideAlert={hideAlert}
                setAlert={setAlert}
                getData={getData}
                bannerManagingApi={bannerManagingApi}
                dispatch={dispatch}
                editBannerManagingAction={editBannerManagingAction}
                bannerManagingRequestErrorAction={
                    bannerManagingRequestErrorAction
                }
            />,
        )
    }

    const successDelete = () => {
        setAlert(
            <SweetAlert
                success
                style={{display: 'block', marginTop: '-100px'}}
                title='Deleted!'
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classesModal.button + ' ' + classesModal.success}
            ></SweetAlert>,
        )
    }


    const hideAlert = () => {
        setAlert(null)
    }

    const validateImageAlert = (message) => {
        setAlert(
            <SweetAlert
                warning
                style={{display: 'block', marginTop: '-100px'}}
                title=''
                onConfirm={() => hideAlert()}
                showConfirm={false}
                onCancel={() => hideAlert()}
            >
                <Typography component='p' gutterBottom>
                    {message}
                </Typography>
                <Button color='success' onClick={hideAlert}>
                    OK
                </Button>
            </SweetAlert>,
        )
    }

    const deleteBannerList = async (row) => {

        if (selectedChk.length < 1) {
            alert("체크박스를 선택해주세요")
            return
        }

        const selArr = {id: []}

        selectedChk.forEach((item) => {
            // item = parseInt(item)

            selArr.id.push({
                item
            })
        })

        const {data} = await bannerManagingApi.destroy({id: selectedChk})

        getData()

    }

    const handleChangeFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            const img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.addEventListener('load', () => {
                validateUploadImage(file, 'size') ||
                (!validateUploadImage(img, 'widthAndHeight') && setImageFile(file))
            })
        }
    }

    // reset value input width type = file so that can show alert with same file select
    const onInputClick = (event) => {
        event.target.value = ''
    }

    const validateUploadImage = (file, whichCheck) => {
        if (whichCheck === 'size' && Math.round(file.size / 1024) > 2048) {
            validateImageAlert('2MB 이하의 파일만 업로드 가능합니다.')
            return true
        }

        if (
            whichCheck === 'widthAndHeight' &&
            file.width !== 670 &&
            file.height !== 200
        ) {
            validateImageAlert('670*200(px) 사이즈의 이미지만 업로드 가능합니다.')
            return true
        }
    }

    const createBannerManaging = async () => {
        try {
            setLoadingBtn(true)
            // call api upload image
            const fd = new FormData()
            fd.append('file', imageFile, imageFile.name)
            fd.append('type', 'SLIDE')

            const {
                data: {id: idImage, url, filename},
            } = await bannerManagingApi.uploadImage(fd)

            // call api create
            const body = {
                type: 'WEB_VIEW',
                mainImage: idImage,
                webViewTitle: formData.title,
                linkUrl: formData.url,
            }

            const {data} = await bannerManagingApi.create(body)
            data['mainImage'] = {
                id: idImage,
                url,
                filename,
            }
            dispatch(createBannerManagingAction(data))

            setFormData({title: '', url: ''})
            setImageFile(null)
            setLoadingBtn(false)
        } catch (error) {
            setLoadingBtn(false)
            setShowNotification({
                ...showNotification,
                open: true,
                message: error?.response?.data?.data?.error,
            })
            dispatch(bannerManagingRequestErrorAction(error?.response?.data))
        }
    }

    const deleteBannerManaging = async (id) => {
        try {
            dispatch(deleteBannerManagingAction(id))
            successDelete()
            await bannerManagingApi.delete({id})
        } catch (error) {
            dispatch(bannerManagingRequestErrorAction(error?.response?.data))
        }
    }

    const changeIndexOfArr = (action, bannerId, index) => {
        const cloneData = [...listBannerManagings]
        const currentIndex = index

        updateOrderBannerManaging(action, bannerId, cloneData, currentIndex)
    }

    const updateOrderBannerManaging = async (
        action,
        bannerId,
        cloneData,
        currentIndex,
    ) => {
        try {
            setLoadingBtnChangeOrder(true)
            let changeIndex = action === 'UP' ? currentIndex - 1 : currentIndex + 1
            let body = {
                id: bannerId,
                action: action,
            }
            await bannerManagingApi.updateOrder(body)

            let updateNumOrder = cloneData.map((item, i) => {
                if (currentIndex === i) {
                    item.numOrder =
                        action === 'UP' ? item.numOrder - 1 : item.numOrder + 1
                }

                if (changeIndex === i) {
                    item.numOrder =
                        action === 'UP' ? item.numOrder + 1 : item.numOrder - 1
                }

                return item
            })

            updateNumOrder.sort((a, b) => a.numOrder - b.numOrder)
            dispatch(updateOrderBannerManagingAction(updateNumOrder))
            setLoadingBtnChangeOrder(false)
        } catch (error) {
            setLoadingBtnChangeOrder(false)
            console.log(error)
        }
    }

    const getData = async () => {
        let orderString = '';

        if (orderBy == 'first') {
            //처음 정렬시 현재 front에 나오는 표출 순서대로
            orderString = 'numOrder,DESC&createdAt,DESC';
        } else {
            orderString = orderBy + ',' + order
        }

        try {
            const params = {
                limit: limit,
                offset: page,
                order: orderString,
                searchText: searchText,
                exposure: searchData.exposure,
                outOfData: searchData.outOfData
                // searchKey: 'webViewTitle',
            }

            const {data} = await bannerManagingApi.getList(params)
            dispatch(getListBannerManagingAction(data))


        } catch (error) {
            dispatch(bannerManagingRequestErrorAction(error?.response?.data))
        }
    }

    React.useEffect(() => {
        getData()
    }, [page + 1, limit, order])

    return (
        <div className='banner-managing'>
            {loading ? (
                <Spinner/>
            ) : (
                <>
                    {alert2}
                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        open={showNotification.open}
                        autoHideDuration={6000}
                        onClose={handleCloseOpen}
                    >
                        <CustomAlert onClose={handleCloseOpen} severity='error'>
                            {showNotification.message}
                        </CustomAlert>
                    </Snackbar>

                    <GridContainer alignItems='center'>
                        <GridItem
                            className={classes.gridContainerOne}
                            xs={12}
                            sm={4}
                            md={3}
                            lg={2}
                            xl={2}
                        >
                            <TextField
                                id='user-managing-select'
                                select
                                size='small'
                                className={classes.widthTextField}
                                label='타입'
                                value={setSearchData?.exposure}
                                onChange={(e)=>{
                                    handleForm(e.target.value, 'exposure')}
                                }
                            >
                                <MenuItem value={'ALL'}>전체</MenuItem>
                                <MenuItem value={'MAIN'}>메인-검색메인</MenuItem>
                                <MenuItem value={'SHOP_MAIN'}>꾸밍샵메인</MenuItem>
                                <MenuItem value={'STAR'}>라이징스타(~2.3.5)</MenuItem>
                            </TextField>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={5} lg={4} xl={4}>
                            <TextField
                                id='post-managing-textfield'
                                size='small'
                                className={classes.widthTextField}
                                placeholder='배너/팝업 명을 입력해주세요'
                                name='searchText'
                                value={searchText}
                                // onChange={handleChangeSearchInput}
                                onChange={(e) => {
                                    setSearchText(e.target.value)
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={2} md={2} lg={2} xl={2}>
                            <Button
                                color='primary'
                                disabled={loading}
                                onClick={() => {
                                    setPage(0)
                                    getData()
                                }}
                            >
                                검색
                            </Button>
                        </GridItem>


                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={11} sm={11} md={11} style={{padding: '20px !important'}}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={searchData.outOfData ==false}
                                        onChange={(e) => handleForm(false,'outOfData')}
                                        value={'0'}
                                        aria-label="T"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.p0
                                }}
                                label="기간 지난 항목 제외"
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={searchData.outOfData}
                                        onChange={(e) => handleForm(true, 'outOfData')}
                                        value={'0'}
                                        aria-label="T"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.p0
                                }}
                                label="전체"
                            />
                        </GridItem>
                    </GridContainer>


                    <Box my={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                        <TextField
                            className={`${classes.textFieldTwo} ${classes.textFieldTwoChildOne}`}
                            id='post-managing-textfield-show-info1'
                            size='small'
                            value={totalRecords}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>전체</InputAdornment>
                                ),
                                readOnly: true,
                            }}
                        />
                        <GridItem xs={2} sm={2} md={2} lg={2} xl={2}>

                            <Box display='flex' justifyContent='flex-end' className='add-notice'>
                                <Button
                                    color='primary'
                                    onClick={() => ModalMoreVert(null)}
                                >
                                    추가하기
                                </Button>

                                <Button
                                    color='rose'
                                    onClick={() => deleteBannerList(null)}
                                    style={{marginLeft: '20px'}}
                                >
                                    삭제하기
                                </Button>
                            </Box>
                        </GridItem>
                    </Box>
                    <Box my={2} className='BannerTable'>
                        {loading ? (
                            <CircularProgress size={30} className={classes.buttonProgress}/>
                        ) : (
                            <BannerListTable
                                ModalMoreVert={ModalMoreVert}
                                rows={bannerList}
                                totalPages={totalPages}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                page={page}
                                setPage={setPage}
                                setLimit={setLimit}
                                headCells={headCells}
                                classes={useStyles}
                                order={order}
                                setOrderBy={setOrderBy}
                                setOrder={setOrder}
                                orderBy={orderBy}
                                useDispatch={useDispatch}
                                getData={getData}
                                bannerManagingApi={bannerManagingApi}
                                setSelectedChk={setSelectedChk}
                                selectedChk={selectedChk}
                            />
                        )}
                    </Box>
                </>
            )}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.bannerManaging.loading,
        bannerList: state.bannerManaging.listBannerManagings,
        metaData: state.bannerManaging.metaData,
    }
}

export default connect(mapStateToProps, {
    getListBannerManagingAction,
    bannerManagingRequestErrorAction,
    editBannerManagingAction,
})(BannerManaging)

