import {connect, useDispatch} from "react-redux";
import Paper from "@material-ui/core/Paper";
import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/Notice/notice2style";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "../../components/Gm-TextField/TextFieldForDatePicker";
import Box from "@material-ui/core/Box";
import TimePicker from "../Statistics/components/TimePicker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {FiberManualRecord} from "@material-ui/icons";
import Button from "../../components/CustomButtons/Button";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import CustomButton from "../../components/CustomButtons/Button";
import moment from "moment";
import Spinner from "../../components/Spinner/Spinner";
import eventsManagingApi from "../../api/eventsManagingApi";


import TagsInput from 'react-tagsinput';
//자동제안은 react suggest

import stylesModal from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";


import bannerManagingApi from "../../api/mainManaging/bannerManagingApi";
import Checkbox from "@material-ui/core/Checkbox";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import VoteQuestion from "../../components/VoteQuestion/VoteQuestion";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import writerManagingApi from "../../api/writerManagingApi";
import Tooltip from "@material-ui/core/Tooltip";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";
import postManagingApi from "../../api/postManagingApi";
import GoodsAndWriterModal from "./components/GoodsAndWritersModal";

const useStyles = makeStyles(styles)
const useStylesModal = makeStyles(stylesModal)

// 이벤트 상세임 아무튼 그럼
const PostForm = (props) => {

    const {
        match,
        postForm,
    } = props

    const classes = useStyles()
    const classesModal = useStylesModal()


    const d = new Date()
    let tz = d.getTimezoneOffset() / 60
    tz = tz < 0 ? tz * -1 : tz

    const dispatch = useDispatch()

    const [styleTagSelected, setStyleSelected] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [alert2, setAlert] = React.useState(null)
    const [goods, setGoods] = React.useState([])
    const [formData, setFormData] = React.useState({
        id: isNaN(match?.params?.id) ? 0 : match?.params?.id,
        title: postForm != null ? postForm.title : '',
        // type: type,
        status: 'ACTIVE',
        startAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        endAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        fromTime: 0,
        toTime: 23,
        contentLinkType: 'URL',
        linkUrl: '',
        numOrder: 0,
        tags: [],
        writerTags: [],
        files: '',
        feedFiles: [],
        files2: '',
        // primaryTag: type == 'EVENT'? '' : '#꾸밍챌린지',
        isDuplication: false,
        contents:'',
        postInfoId: 1,
        minTime: 0,
        isNotiPost: false //알려드려요(꾸밍톡)


    })
    const [loadingBtn, setLoadingBtn] = React.useState(false)


    const eventsModel = {
        questionTitle: '',
        files: {}
    }


    const [voteQuestionList, setvoteQuestionList] = React.useState([eventsModel])



    const dateTimeCheck = (date, time) => {
        if (time <= 9) {
            return date + " " + "0" + time + ":00" // YYYY-MM-DD HH:mm
        } else {
            return date + " " + time + ":00"
        }
    }
    const handleForm = (value, key) => {
        //vue의 데이터 매핑 형태였던 것 같은데.... key도 매핑되네 히야...

        switch (key) {
            case 'startAt' :
                setFormData({
                    ...formData,
                    [key]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'endAt' :
                setFormData({
                    ...formData,
                    [key]: moment(value).format('YYYY-MM-DD'),
                })
                break;
            case 'isDuplication' :
                setFormData({
                    ...formData,
                    [key]: !formData.isDuplication,
                })
                break;
            default:
                setFormData({
                    ...formData,
                    [key]: value,
                })

        }

    }
    const handleFormQuestionList = (value, key, index) => {
        let tempObject = voteQuestionList[index];
        tempObject[key] = value;
        setvoteQuestionList([ ...voteQuestionList.slice(0,index),
            tempObject,
            ...voteQuestionList.slice(index+1)]
        )
    }
    const handleFormQuestionFile = async (file,  index) => {
        //파일 저장해서 가져와진 fileidx를 input


        //이미지 cdn 업로드
        const fd = new FormData()

        fd.append('file', file, file.name)
        fd.append('type', 'POST')
        const {
            data: {id: idImage, url, filename},
        } = await bannerManagingApi.uploadImage(fd); //banner라고 세팅했지만원본은 file api 로 통신

        let tempObject = voteQuestionList[index];
        tempObject.files = {id: idImage, url, filename};
        setvoteQuestionList([ ...voteQuestionList.slice(0,index),
            tempObject,
            ...voteQuestionList.slice(index+1)]
        )

    }

    const handleFormFeedFile = async (e,  index) => {
        //feed 파일
        //파일 저장해서 가져와진 fileidx를 input


        const fd = new FormData()
        for(let i=0; i<e.target.files.length; i++) {
            fd.append('file',  e.target.files[i])
        }
        fd.append('type', 'POST')

        const { data } = await bannerManagingApi.uploadImageMulti(fd)


        const aa = formData.feedFiles;

        aa.push(...data)



        setFormData({...formData, feedFiles: aa});

    }



    const handleChangeTimePickerStart = (event) => {
        //
        setFormData({...formData, fromTime: event.target.value})
    }
    const handleChangeTimePickerEnd = (event) => {
        //
        setFormData({...formData, toTime: event.target.value})
    }
    const handleChangeFile = (e, type) => {
        //파일 Onchage 시 이미지 체인지



    }


    const delGoodsAndWriterList = (i) => {
        let member2 = goods.splice(0, i)
        if (goods.splice(goods.length).length > 0) {
            member2.push(goods.splice(goods.length))
        }
        setGoods(member2)
    }


    //api 통신해서 db저장 이벤트 생성
    const createPostMananging = async () => {

        try {
            const body = {}
            body.title = formData.title
            body.tagInputs = formData.tags
            body.description = formData.description
            body.id = parseInt(isNaN(match?.params?.id) ? 0 : match?.params?.id)
            body.postInfoId = formData.postInfoId
            body.isNotiPost = formData.isNotiPost
            body.isKkommingTalk = formData.isKkommingTalk
            body.writerAndGoodsTags = goods?.map((item)=>{


                return {
                    type: item.goodsId == null ? 'WRITER' : 'GOODS',
                    goodsId: item.goodsId,
                    writerId: item.writerId,
                }
            })


            //피드일때

            if (formData.postInfoId ==1 || formData.postInfoId ==5){
                body.stylePostTags = styleTagSelected
                body.writerTags = formData.writerTags
                body.fileIds = formData.feedFiles.map((item)=>{
                    return item.id
                })

            }else{
                //vote
                body.minTime = formData.minTime;
                body.isDuplication = formData.isDuplication;
                body.voteQuestions = voteQuestionList.map((item)=>{
                    const question = {
                        questionTitle: item?.questionTitle,
                        fileId: item?.files?.id || null
                    }
                    return question
                })


            }


            //업데이트 일때, 하단은 Insert
            if (formData.id != 0) {


                // 일반 핃,


                //추천태그 빼기



                const {data} = await postManagingApi.update(body);
                if (data){
                    alert("수정되었습니다.")
                    location.href = "/admin/post-list/2" //꾸밍톡 리스트
                }

                return
            }

            const {data} = await postManagingApi.create(body);
            if (data){
                alert("저장되었습니다.")
                location.href = "/admin/post-list/2" //꾸밍톡 리스트
            }


        } catch (e) {
            setLoadingBtn(false)
        }

    }
    const getPostData = async () => {
        try {



            setLoading(true)

            if (formData.id > 0) {
                //데이터가 있는 것

                //setLoading 필요

                let {data} = await postManagingApi.getPosts({id: parseInt(formData.id)});
                //데이터 매핑 dispatch쓰면 코드가 깔끔해지나 쓰지 않음


                // 기본
                const formNewDATA = {
                    ...formData,
                    title: data.title,
                    tags: data.hasTags?.tagInputs || [],
                    postInfoId: data.postInfoId,
                    description: data.description,
                    isNotiPost : data.isNotiPost,
                    writerAndGoodsTags  : data.writerAndGoodsList?.writerAndGoods.map((item)=>{
                        return item
                    })
                };

                setGoods(formNewDATA.writerAndGoodsTags)

                // //피드일 경우
                if (data.postInfoId == 1){
                    // formData.feedFiles
                    //스타일 태그

                    formNewDATA.feedFiles =  data?.album
                    formNewDATA.tags =  data?.hashTags?.tagInputs.map((item)=>{
                        return item.tagName
                    }) || []
                    formNewDATA.writerTags = data?.writerTag || []

                    setStyleSelected(data.styleTag);
                }else{
                    formNewDATA.isDuplication = data?.isDuplication || false
                    const voteQuesti = data?.voteQuestion?.map((item)=>{
                        return {
                            questionTitle: item?.questionTitle,
                            files: item?.files || null
                        }
                    })
                    setvoteQuestionList(voteQuesti);
                    formNewDATA.minTime = parseInt((new Date().getTime() - new Date(data.endAt).getTime()) / (1000*60))

                }



                //최종데이터 세팅
                setFormData(formNewDATA)

                setLoading(false)

            }
            setLoading(false)

        } catch (e) {

        }
    }

    let count = 0;
    React.useEffect(() => {
        //getData
        getPostData()
    }, [formData.id])

    // const handleTitle = (e)


    //스타일 태그 input,output
    const selectStyleTag = async (type) =>{
        //있으면 빼고 없으면 넣고
        let isContain = false;
        let iindex = 0;

        styleTagSelected.map((item, index)=>{
            if (item == type){
                isContain = true;
                iindex = index
            }
        });

        if (isContain){
            //해당 인덱스 구해서 빼기
            setStyleSelected(styleTagSelected.slice(0,iindex));

        }else{

            //이렇게 하면 state가 변한걸 탐지 못함
            /*const aa = styleTagSelected;
            aa.push(type)
            setStyleSelected(aa);*/

            const aa = [];
            styleTagSelected.map((item) => {
                aa.push(item)
            });
            aa.push(type)
            setStyleSelected(aa);
        }
    }
    //스타일 태그 존재여
    const checkHasStyleTag = (type) =>{
        return styleTagSelected.indexOf(type) !== -1
    }

    const openGoodsView = async () => {
        setAlert(
            <GoodsAndWriterModal
                item={goods}
                hideAlert={(e) => {
                    setAlert(null)
                }}
                setAlert={setAlert}
                dispatch={dispatch}
                setGoods={setGoods}
                goods={goods}
            />
        )
    }

    /*enum('KITCH', 'LOVELY_CUTE', 'CHIC', 'EMOTION', 'DREAM', 'VINTAGE', 'NATURAL', 'ANIMAL', 'TYPO', 'SIMPLE')}*/
    const handleStyleTag = [

        {
            title : '키치',
            type: 'KITCH',
        },

        {
            title : '러블리큐트',
            type: 'LOVELY_CUTE',
        },
        {
            title : '시크',
            type: 'CHIC',
        },
        {
            title : '감정',
            type: 'EMOTION',
        },
        {
            title : '몽환',
            type: 'DREAM',
        },

        {
            title : '빈티지',
            type: 'VINTAGE',
        },
        {
            title : '자연',
            type: 'NATURAL',
        },
        {
            title : '동물',
            type: 'ANIMAL',
        },
        {
            title : '타이포',
            type: 'TYPO',
        },
        {
            title : '심플',
            type: 'SIMPLE',
        },
    ]


    return (

        <div>

            {loading ? (<Spinner/>) : ( <div>
                {alert2}
                <Paper variant='outlined' className={classes.paper}>

                    {/*타입  */}
                    <GridContainer className={classes.grid}>
                        <GridItem xs={12} sm={12} md={12} className={classes.lableSt}>
                            카테고리
                        </GridItem>

                        {/*피드형*/}
                        <GridItem xs={2} sm={2} md={2}>

                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={formData.postInfoId == 1}
                                        onChange={() => handleForm(1, "postInfoId")}
                                        value="APPLY"
                                        name="radio button enabled"
                                        aria-label="F"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="피드형"
                            />
                        </GridItem>

                        {/*투표문답형*/}
                        <GridItem xs={2} sm={2} md={2}>

                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={formData.postInfoId === 2}
                                        onChange={() => handleForm(2, "postInfoId")}
                                        value="VOTE"
                                        name="radio button enabled"
                                        aria-label="T"
                                        icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                                        checkedIcon={
                                            <FiberManualRecord className={classes.radioChecked}/>
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="투표문답형"
                            />
                        </GridItem>



                        <GridItem  xs={6} sm={6} md={6} />
                        <GridItem  xs={2} sm={2} md={2} />


                    </GridContainer>

                    {/*피드형일때 이미지*/}
                    <GridContainer alignItems='center' className={classes.grid}>



                            {formData?.feedFiles.map((item,index)=> (
                                <GridItem xs={3} sm={3} md={3} style={{'textAlign': 'right'}}>
                                    <IconButton
                                        style={{
                                            color:'red',
                                            position: 'relative',
                                            top: '23px',
                                            'zIndex': 10,
                                            left: '20px'
                                        }}
                                        // onMouseEnter={handleOnMouseEnter}
                                        // onMouseLeave={handleOnMouseLeave}
                                        onClick={(e) => {
                                            //formData에 있는 files 지우기
                                            formData.feedFiles.splice(index, 1);
                                            setFormData({ ...formData, feedFiles: formData.feedFiles })
                                        }}
                                    >
                                        <HighlightOffIcon />
                                    </IconButton>
                                    <img style={{'maxWidth': '100%'}} src={item.url} />
                                    {/*<div>삭제</div>*/}

                                </GridItem>

                            ))}


                    </GridContainer>
                    {
                        formData.postInfoId == 1 && (
                            <GridContainer alignItems='center' className={classes.grid}>
                        <GridItem xs={4} sm={4} md={4}>
                            <input
                                accept='image/*'
                                className={classes.inputBtnUpload}
                                id={'contained-button-file'}
                                multiple
                                type='file'
                                onClick={(event => {
                                })}
                                onChange={(e) => {

                                    handleFormFeedFile(e)

                                }}
                            />
                            <label htmlFor={'contained-button-file'}>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    component='span'
                                    fullWidth={true}
                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                >
                                    사진 올리기
                                    {/*{row.*/}
                                    {/*    ? row.files.filename*/}
                                    {/*    : ' 이미지를 첨부하세요'}*/}
                                </Button>
                            </label>
                        </GridItem>
                        <GridItem xs={2} sm={2} md={2}>
                            {/*{*/}
                            {/*    row?.files != null ? <img style={{'maxWidth':'200px'}} src={row?.files?.url}/> : null*/}
                            {/*}*/}
                        </GridItem>


                    </GridContainer>) }






                    {/*제목*/}
                    <GridContainer className={classes.grid} alignItems='center'>
                        {/*1*/}
                        <GridContainer className={classes.grid}>
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomInput
                                    labelText={'제목'}
                                    id="float"
                                    inputProps={{
                                        onChange: (event) => {
                                            handleForm(event.target.value, 'title')
                                        },
                                    }}
                                    value={formData.title}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    // onChange={handleTitle()}
                                />

                            </GridItem>
                        </GridContainer>
                        {/*내용*/}
                        <GridContainer className={classes.grid}>
                            <GridItem xs={12} sm={12} md={12} className={classes.inputClass}>
                            <Box className='notice-detail'>
                                <TextareaAutosize
                                    className={classes.textareaAutosize}
                                    minRows={5}
                                    maxRows={9}
                                    aria-label='maximum height'
                                    placeholder='내용 입력'
                                    value={formData.description}
                                    onChange={(e)=>{
                                        setFormData({...formData, description: e.target.value});
                                    }}
                                    name='content'
                                />
                            </Box>
                            </GridItem>
                        </GridContainer>


                        <GridContainer className={classes.grid}>
                            <GridItem xs={1} sm={1} md={1} className={classes.lableSt2}>
                                태그
                            </GridItem>
                            <GridItem xs={11} sm={11} md={11} className={classes.tagInput}>
                                <TagsInput
                                    // inputProps={{'dfdf'}}
                                    // className={classes.tagInput}
                                    value={formData.tags}
                                    onChange={(e) => {
                                        handleForm(e, 'tags')
                                    }}
                                    inputProps={{
                                        placeholder:'태그'
                                    }}
                                    tagProps={{className: "react-tagsinput-tag "}}
                                />
                            </GridItem>
                        </GridContainer>



                        {
                            formData.postInfoId == 1 && (<GridContainer className={classes.grid}>
                            <GridItem xs={1} sm={1} md={1} className={classes.lableSt2}>
                                작가/브랜드 태그
                            </GridItem>
                                <GridItem xs={11} sm={11} md={11} className={classes.tagInput}>

                                    <Button disabled={loading} onClick={() => openGoodsView()} color='primary'>
                                        {'상품추가'}
                                    </Button>

                                    {
                                        goods?.map((row, i)=>{
                                            return (
                                                <div key={row.goodsId + ',' + row.writerId}
                                                >
                                                    {row?.title}
                                                    {row.goodsId == null?  '(작가)' : '(상품)'}
                                                    <img src={row?.thumbnailImage} alt="" width={100}/>
                                                    <a style={{'cursor': 'pointer'}}
                                                        onClick={() => delGoodsAndWriterList(i)} style={{color: 'red'}}>X</a></div>
                                            )
                                        })
                                    }

                                </GridItem>
                        </GridContainer>)}




                        {
                            formData.postInfoId == 1 && (<GridContainer className={classes.grid}>
                            <GridItem xs={2} sm={2} md={2} className={classes.lableSt2}>
                                스타일 태그
                            </GridItem>
                            {
                                handleStyleTag.map((item,index)=>{

                                    const isItemSelected = checkHasStyleTag(item.type)
                                    const labelId = `enhanced-table-checkbox-style${index}`

                                    return (
                                        <GridItem xs={2} sm={2} md={2} className={classes.tagInput}>
                                        {/*enum('KITCH', 'LOVELY_CUTE', 'CHIC', 'EMOTION', 'DREAM', 'VINTAGE', 'NATURAL', 'ANIMAL', 'TYPO', 'SIMPLE')*/}
                                        <Checkbox
                                            onClick={() =>selectStyleTag(item.type)}
                                            checked={isItemSelected}
                                            inputProps={{ 'aria-labelledby': labelId}}
                                        />
                                            {item.title}
                                         </GridItem>
                                    )
                                })
                            }
                        </GridContainer>)}






                        {
                            formData.postInfoId == 2 && (<GridContainer className={classes.grid}>
                            <GridItem xs={12} sm={12} md={12}>
                                <GridContainer>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText={'투표문답 시간(분단위)'}
                                            id="float"
                                            inputProps={{
                                                onChange: (event) => {
                                                    handleForm(event.target.value, 'minTime')
                                                },
                                            }}
                                            value={formData?.minTime}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            // onChange={handleTitle()}
                                        />

                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>)}

                        {
                            formData.postInfoId == 2 && (<GridContainer className={classes.grid}>
                            
                            <GridItem xs={12} sm={12} md={12} className={classes.border}>
                                


                                        <div>
                                            <Box className='add-list' display='flex' justifyContent='left'>



                                                <CustomButton color="default" mr={100} variant="contained"
                                                              onClick={(event) => {
                                                                  setvoteQuestionList([
                                                                      ...voteQuestionList,
                                                                      {
                                                                          questionTitle: '',
                                                                          files: {}
                                                                      }
                                                                  ])
                                                              }}
                                                >질문 추가</CustomButton>


                                                <div className={classes.lableSt} style={{'margin-left': '10px'}}>
                                                    중복 체크 가능여부
                                                </div>
                                                <Checkbox
                                                    onClick={(event) => handleForm(event.target.value, 'isDuplication')}
                                                    checked={formData.isDuplication == true ? true : false}
                                                    inputProps={{ 'aria-labelledby': 1 }}
                                                />


                                            </Box>

                                            {voteQuestionList.map((row, item) => {

                                                return (


                                                    <GridContainer alignItems='center'>

                                                        <GridItem xs={6} sm={6} md={6}>
                                                            <CustomInput
                                                                labelText={'질문' + (item + 1)}
                                                                id="float"
                                                                inputProps={{
                                                                    onChange: (event)  => {

                                                                        handleFormQuestionList(event.target.value, 'questionTitle', item)
                                                                    }
                                                                }}
                                                                value={row.questionTitle}
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                            />
                                                        </GridItem>
                                                        <GridItem xs={4} sm={4} md={4}>
                                                            <input
                                                                accept='image/*'
                                                                className={classes.inputBtnUpload}
                                                                id={'contained-button-file-'+item}
                                                                type='file'
                                                                onClick={(event => {
                                                                    event.target.value = ''
                                                                })}
                                                                onChange={(e) => {

                                                                    if (e.target.files && e.target.files.length > 0) {
                                                                        const file = e.target.files[0]
                                                                        const img = new Image()
                                                                            img.src = window.URL.createObjectURL(file)
                                                                        // img.addEventListener('load', (e) => {
                                                                        handleFormQuestionFile(file,  item)
                                                                        // validateUploadImage(file, 'size') ||
                                                                        // (!validateUploadImage(img, 'widthAndHeight') && setImageFile(file))
                                                                        // })
                                                                    }

                                                                }}
                                                            />
                                                            <label htmlFor={'contained-button-file-'+item}>
                                                                <Button
                                                                    variant='outlined'
                                                                    color='primary'
                                                                    component='span'
                                                                    fullWidth={true}
                                                                    startIcon={<AddCircleOutlineOutlinedIcon/>}
                                                                >
                                                                    {row.files
                                                                        ? row.files.filename
                                                                        : ' 이미지를 첨부하세요'}
                                                                </Button>
                                                            </label>
                                                        </GridItem>
                                                        <GridItem xs={2} sm={2} md={2}>
                                                            {
                                                                row?.files != null ? <img style={{'maxWidth':'200px'}} src={row?.files?.url}/> : null
                                                            }
                                                        </GridItem>


                                                    </GridContainer>
                                                )
                                            })
                                            }
                                        </div>



                            </GridItem>


                        </GridContainer>)}


                            <GridContainer className={classes.grid}>
                                <GridItem xs={1} sm={1} md={1} className={classes.lableSt2}>
                                    알려드려요
                                </GridItem>
                                <GridItem xs={11} sm={11} md={11} className={classes.tagInput}>
                                    <Checkbox
                                        onClick={(e) =>handleForm(e.target.checked, 'isNotiPost')}
                                        checked={formData?.isNotiPost}
                                        inputProps={{ 'aria-labelledby': 'notiPost'}}
                                        />
                                </GridItem>
                            </GridContainer>


                    </GridContainer>
                </Paper>


                <Box className='add-list' display='flex' justifyContent='center'>


                    {loadingBtn ? (
                        <Spinner/>
                    ) :
                        (
                        <CustomButton color="primary" mr={2} variant="contained"
                                      onClick={createPostMananging}
                        >
                            {formData?.id == 0 ? '등록' : '수정'}
                        </CustomButton>)
                    }
                    <CustomButton color="default" mr={2} variant="contained"
                                  onClick={(event) => history.back()}
                    >취소</CustomButton>

                </Box>
            </div>)}


        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        // userDetail: state.post.postForm,
        loading: state.eventsReducer.loading,
        postForm: state.eventsReducer.postForm,
        post: state.eventsReducer.post,
        metaData: state.eventsReducer.metaData,
    }
}

export default connect(mapStateToProps, {
    // requestNoticeAction,
    // getListNoticesAction,
    // noticesWithErrAction,
})(PostForm)
