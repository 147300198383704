import * as actionTypes from '../actions/types'

const initialState = {
    loading: true,
    comments: [], //리스트에서 쓰이는거
    metaData: {
        totalPages: 1,
        totalRecords: 0,
    },
    error: null,
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case actionTypes.GET_LIST_COMMENTS:
            const {comments
                , metaData} = payload
            return {
                ...state,
                loading: false,
                comments: comments
                ,
                metaData,
            }
        case actionTypes.UPDATE_EVNETS:
            return {
                ...state,
                loading: false,
                events: state.events.map((item) =>
                    item.id === payload.id
                        ? { ...item, ...payload }
                        : item,
                ),
            }
        default:
            return state
    }

}