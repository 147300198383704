import * as actionTypes from "../actions/types";

const initialState = {
    loading: true,
    error: null,
    aiCommentManaging: [],
    formDataGlobal: {},
    metaData: { totalRecords: 0 , totalPages: 1},
    clovaAiSystemPhrase: null // aiSystemPhrase
}


export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case actionTypes.GET_AI_COMMENT_LIST_ACTION:
            const {comments, metaData} = payload
            return {
                ...state,
                loading: false,
                aiCommentManaging: comments,
                metaData,
            }
        case actionTypes.SET_AI_COMMENT_LIST:
            const {id, content} = payload
            return {
                ...state,
                loading: false,
                // aiCommentManaging: comments,
                aiCommentManaging: state.aiCommentManaging.map((item) =>
                    item.id === id
                        ? {
                            ...item,
                            content
                        }
                        : item,
                ),
            }
        case actionTypes.SET_AI_PHRASE:
            const {clovaAiSystemPhrase} = payload
            return {
                ...state,
                loading: false,
                clovaAiSystemPhrase: clovaAiSystemPhrase
            }
        default:
            return state
    }
}