import StatisticClick from 'views/Statistics/StatisticClick/StatisticClick.js'
import StatisticSearch2 from 'views/Statistics/StatisticSearch/StatisticSearch2'
import StatisticPosts from 'views/Statistics/StatisticSearch/StatisticPosts'
import BannerManaging from 'views/MainManaging/BannerManaging'
import TagManaging from 'views/MainManaging/TagManaging'
import BestFollow from 'views/MainManaging/BestFollow'
import PostManaging from 'views/PostManaging/PostManaging'
import KkoommingTalkManaging from 'views/PostManaging/KkoommingTalkManaging'
import UserManaging from 'views/UserManaging/UserManaging'
import WriterManaging from 'views/WriterManaging/WriterManaging'
import WriterSchedulesManaging from 'views/WriterManaging/WriterSchedulesManaging'
import WriterGoodsManaging from 'views/WriterManaging/WriterGoodsManaging'
import WriterMainManaging from 'views/WriterManaging/WriterMainManaging'
import ReportBlockManaging from 'views/ReportBlockManaging/ReportBlockManaging'
import QA from 'views/Q&A/QA'
import Notice from 'views/Notice/Notice'
import Setting from 'views/Setting/Setting'
import ManagerManaging from 'views/ManagerManaging/ManagerManaging'
import EventManaging from "./views/Notice/EventManaging";
import PushManaging from "./views/PushManaging/PushManaging";
import SplashManaging from "./views/SplashManaging/SplashManaging";
import React from "react";
import CommentManaging from "./views/CommentManaging/CommentMananging";
import ContentsManaging from "./views/ContentsManaging/ContentsManaging";
import RecommendWriterGoodsManaging from "./views/ContentsManaging/GoodsManaging";
import PhrasesSetting from "./views/PhrasesSetting/PhrasesSetting";
import StatisticMain from "./views/Statistics/StatisticMain/StatisticMain";
import StatisticJoinOut from "./views/Statistics/StatisticJoinOut/StatisticJoinOut";
import ContentsMultiManaging from "./views/ContentsManaging/ContentsMultiManaging";
import CategoryManaging from "./views/CategoryManaging/CategoryManaging";
import StatisticUsers from "./views/Statistics/StatisticSearch/StatisticUsers";
import VersionManaging from "./views/Setting/VersionManaging";
import AiManaging from "./views/PostManaging/AiManaging";
import HomePhraseSetting from "./views/StickerManaging/HomePhraseSetting";
import StickerTagManaging from "./views/StickerManaging/StickerTagManaging";

var gmdashRoutes = [
    {
        collapse: true,
        name: '통계',
        state: 'statistics',
        views: [
            {
                path: '/statistics-main',
                name: '대시보드',
                mini: 'd',
                component: StatisticMain,
                layout: '/admin',
            },
            {
                path: '/statistics-click',
                name: '사이트 클릭 내역',
                mini: '사',
                component: StatisticClick,
                layout: '/admin',
            },
            {
                path: '/statistics-search',
                name: '검색 통계 내역',
                mini: '검',
                component: StatisticSearch2,
                layout: '/admin',
            },
            {
                path: '/joinout',
                name: '회원 탈퇴 통계',
                mini: '탈',
                component: StatisticJoinOut,
                layout: '/admin',
            },
            {
                path: '/statistics-posts',
                name: '게시물 통계',
                mini: '게',
                component: StatisticPosts,
                layout: '/admin',
            },
            {
                path: '/statistics-users',
                name: '회원 통계',
                mini: '회',
                component: StatisticUsers,
                layout: '/admin',
            },
        ],
    },
    {
        collapse: true,
        name: '메인 관리',
        state: 'mainManaging',
        views: [
            {
                path: '/banner-managing',
                name: '배너/팝업 관리',
                mini: '배팝',
                component: BannerManaging,
                layout: '/admin',
            },
            {
                path: '/splash-managing',
                name: '스플래시 관리',
                mini: '시',
                component: SplashManaging,
                layout: '/admin',
            },
            {
                path: '/phrases-setting',
                name: '로그인 관리',
                mini: '로',
                component: PhrasesSetting,
                layout: '/admin',
            },
        ],
    },

    {
        collapse: true,
        name: '홈 화면 관리',
        state: 'contentManaging',
        views: [
            {
                path: '/content-managing/HOME_POP_FEED',
                name: '인기피드 관리-이번주 인기피드',
                mini: 'P',
                component: ContentsManaging,
                layout: '/admin',
            },
            /*{
                path: '/content-managing/HOME_POP_STAR',
                name: '라이징스타 관리- 주목할 만한 라이징스티',
                mini: 'R',
                component: ContentsManaging,
                layout: '/admin',
            },*/
            /*{
                path: '/content-managing/HOME_WRITER',
                name: '작가 노출 관리-꾸밍 프로작가',
                mini: 'W',1
                component: ContentsManagingWriter,
                layout: '/admin',
            },*/
            {
                path: '/recommend-writer-goods-managing/MAIN_BOTTOM',
                name: '추천 상품 관리- 추천 pick 상품',
                mini: 'R',
                component: RecommendWriterGoodsManaging,
                layout: '/admin',
            },

            {
                path: '/post-list/2',
                name: '꾸밍톡 관리',
                mini: '꾸',
                component: KkoommingTalkManaging,
                layout: '/admin',
            },
        ],
    },

    {
        collapse: true,
        name: '이벤트관리',
        state: 'eventManaging',
        views: [
            {
                path: '/event-managing/CHALLENGE',
                name: '꾸밍챌린지',
                mini: 'C',
                component: EventManaging,
                layout: '/admin',
            },
            {
                path: '/event-managing/EVENT',
                name: '이벤트',
                mini: 'E',
                component: EventManaging,
                layout: '/admin',
            },
        ],
    },

    {
        collapse: true,
        name: '스티커 관리',
        state: 'stickerManaging',
        views: [
            {
                path: '/sticker-managing/phrase',
                name: '홈 배너 텍스트 관리',
                mini: 'H',
                component: HomePhraseSetting,
                layout: '/admin',
            },
            {
                path: '/sticker-managing/sticker',
                name: '스티커 관리',
                mini: 'S',
                component: StickerTagManaging,
                layout: '/admin',
            },
            /*{
                path: '/sticker-managing/prize',
                name: '경품 관리',
                mini: 'P',
                component: EventManaging,
                layout: '/admin',
            },*/
        ],
    },


    {
        collapse: true,
        name: '꾸밍뷰관리',
        state: 'kkoommingViewManaging',
        views: [
            {
                path: '/tag-managing/RECOMMEND',
                name: '추천 태그 관리',
                mini: '태',
                component: TagManaging,
                layout: '/admin',
            },
            {
                path: '/tag-managing/POST_GOODS',
                name: '굿즈 추천 태그 관리',
                mini: '태',
                component: TagManaging,
                layout: '/admin',
            },
            {
                path: '/best-follow',
                name: '지금 뜨고 있어요',
                mini: '지',
                component: BestFollow,
                layout: '/admin',
            },
        ],
    },

    {
        collapse: true,
        name: '게시판 관리',
        state: 'postManaging',
        views: [
            {
                path: '/post-list/1',
                name: '게시물 관리',
                mini: 'P',
                component: PostManaging,
                layout: '/admin',
            },
            {
                path: '/ai-comment',
                name: 'AI 댓글관리',
                mini: 'C',
                component: AiManaging,
                layout: '/admin',
            },
        ]
    },

    {
        collapse: true,
        name: '꾸밍샵 관리',
        state: 'shopContentManaging',
        views: [

            {
                path: '/recommend-writer-goods-managing/SHOP_MAIN',
                name: '상단 상품 관리-꾸밍 MD 큐레이션',
                mini: 'P',
                component: RecommendWriterGoodsManaging,
                layout: '/admin',
            },
            {
                path: '/content-multi-managing/SHOP_CONTENT',
                name: '하단 컨텐츠 관리-유저 꾸미기 연계영역',
                mini: 'R',
                component: ContentsMultiManaging,
                layout: '/admin',
            },

            {
                path: '/tag-managing/SHOP_RECOMMEND',
                name: '꾸밍샵 추천 태그 관리',
                mini: '태',
                component: TagManaging,
                layout: '/admin',
            },

            {
                path: '/category-managing',
                name: '카테고리 관리',
                mini: '카',
                component: CategoryManaging,
                layout: '/admin',
            },
        ],
    },

    {
        collapse: true,
        name: '작가 관리',
        state: 'writerManaging',
        views: [
            {
                path: '/writer-managing',
                name: '작가 관리',
                mini: '작',
                component: WriterManaging,
                layout: '/admin',
            },
            {
                path: '/writer-goods-managing',
                name: '상품 관리',
                mini: '상',
                component: WriterGoodsManaging,
                layout: '/admin',
            },
            {
                path: '/writer-schedules-managing',
                name: '일정 관리',
                mini: '일',
                component: WriterSchedulesManaging,
                layout: '/admin',
            },
            {
                path: '/writer-main-managing',
                name: '메인 관리',
                mini: '메',
                component: WriterMainManaging,
                layout: '/admin',
            },
        ],
    },


    {
        path: '/user-managing',
        name: '회원 관리',
        component: UserManaging,
        layout: '/admin',
    },

    {
        path: '/reported-blocked-managing',
        name: '신고/차단 관리',
        component: ReportBlockManaging,
        layout: '/admin',
    },
    {
        path: '/question-answers',
        name: '문의 답변',
        component: QA,
        layout: '/admin',
    },
    {
        path: '/push-managing',
        name: '푸시 관리',
        component: PushManaging,
        layout: '/admin',
    },

    {
        path: '/notice',
        name: '공지 게시글',
        component: Notice,
        layout: '/admin',
    },


    {
        collapse: true,
        name: '환경 설정',
        state: 'setting',
        views: [
            {
                path: '/setting',
                name: '환경 설정',
                mini: '환',
                component: Setting,
                layout: '/admin',
            },
            {
                path: '/version-managing',
                name: '버전 관리',
                mini: '버',
                component: VersionManaging,
                layout: '/admin',
            }
        ]
    },
    {
        path: '/comment-list',

        name: '댓글 리스트 조회(임시)',
        component: CommentManaging,
        layout: '/admin',
    },

    {
        path: '/manager-managing',
        name: '관리자 관리',
        component: ManagerManaging,
        layout: '/admin',
    },


]

export default gmdashRoutes
