import * as actionTypes from '../actions/types'
const initialState = {
    loading: true,
    error: null,
    code: null,
    codeList: [],
    subCodeList: []

}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case actionTypes.GET_NEXT_CODE:
            const {data, metaData} = payload
            return {
                ...state,
                loading: false,
                code : data.code,
                metaData,
            }
        case actionTypes.GET_CODE_LIST:
            const {codeList, } = payload
            // if (childCodeList )

            return {
                ...state,
                loading: false,
                codeList :codeList
            }
        case actionTypes.GET_CODE_CHILD_LIST:
            const {subCodeList} = payload
            // if (childCodeList )

            return {
                ...state,
                loading: false,
                subCodeList :subCodeList
            }
        default:
            return state
    }

}