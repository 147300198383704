import axiosInterceptors from 'api/axios'

const writerMainManagingApi = {


	setExcelFile: (body) => {
		const url = '/writerMain/writerMainManaging/setExcelFile'
		return axiosInterceptors.post(url, body)
	},
	deleteWriterMain: (params) => {
		const url = '/writerMain/writerMainManaging/delete'
		return axiosInterceptors.delete(url, { params })
	},
	getListMain: (params) => {
		const url = '/writerMain/writerMainManaging/getListMain'
		return axiosInterceptors.get(url, {params})
	},
	getWriterMain: (params) => {
		const url = '/writerMain/writerMainManaging/getWriterMain'
		return axiosInterceptors.get(url,  {params})
	},
	update: (params) => {
		const url = '/writerMain/writerMainManaging/update'
		return axiosInterceptors.put(url, { params })
	},
	create: (params) => {
		const url = '/writerMain/writerMainManaging/create'
		return axiosInterceptors.post(url, { params })
	},
	updateWriterMainOrder: (params)=> {
		const url = '/writerMain/writerMainManaging/updateWriterMainOrder'
		return axiosInterceptors.put(url, { params })

	}
}

export default writerMainManagingApi