import {connect, useDispatch} from "react-redux";
import React from "react";
import moment from "moment/moment";
import Spinner from "../../components/Spinner/Spinner";
import {
    changeContentAction,
    deleteContentAction,
    getContentManagingAction,
    orderContentAction
} from "../../redux/actions/contentsManagingAction";
import contentsManagingApi from "../../api/contentManagingApi";
import ContentManagingApi from "../../api/contentManagingApi";
// import ContentsTables from "./components/ContentsTables";
import Paper from "@material-ui/core/Paper";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TableContainer from "@material-ui/core/TableContainer";
import {orderTagAction} from "../../redux/actions/tagManaging";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/PostManaging/postManaging";
import TextField from "../../components/Gm-TextField/TextFieldForDatePicker";

/**
 * 라이징스타 관리 페이지
 * @param props
 * @constructor
 */
const useStyles = makeStyles(styles)
const ContentsManaging = (props) =>{

    const classes = useStyles()
    const dispatch = useDispatch()

    const {
        metaData: metadata,
        contents,
        getContentManagingAction,
        match,
        changeContentAction
    } = props

    //-------------- state 세팅
    const [loading, setLoading] = React.useState(false)
    const [formData, setFormData] = React.useState({
        limit: 1000,
        offset: 0,
        page: 0
    })

    //./ state 세팅

    const handleMultiCheck = (e, multiSelect = null) =>{
        if( e!= null){
            if (multiSelect == null || multiSelect ){ //여러개의 option이 들어갈 경우 ex: style태그 여러개 선택
                let arr = new Set();
                if (Array.isArray(formData[e.target.name])) {
                    arr = new Set(formData[e.target.name]);
                }
                if (arr.has(e.target.value)){
                    arr.delete(e.target.value) //잇으면 체크해제
                }else{
                    arr.add(e.target.value); //없으면 추가
                }
                setFormData({ ...formData, [e.target.name]: [...arr]  }) //중복방지 new Set
            }else{
                //단일 옵션 ex: 꾸밍톡 여부?
                setFormData({ ...formData, [e.target.name]: formData[e.target.name] ? false : true })
            }
        }
    }
    const handleDateChange = (date, key) => {
        setFormData({
            ...formData,
            [key]: moment(date).subtract(tz, 'hours').format('YYYY-MM-DD'),
        })
    }
    const getListContentManaging = async () => {
        try {
            const params = {
                limit: 10000,
                offset: 1,
                type: match?.params?.type || 'HOME_POP_FEED'
            }
            setLoading(true)
            const { data } = await ContentManagingApi.getMultiOne(params)


            dispatch(getContentManagingAction(data))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            if (error && error.response && error.response.data) {
                // dispatch(bestUserRequestErrorAction(error.response.data))
            }
        }
    }

    const changeIndexOfArr = async (up, down, orderNum, id, index) => {
        const deepCloneData = [...contents]
        const currentIndex = index
        if (up) {
            if (index !== 0) {
                // requestTagAction()
                try {
                    let changeUpIndex = index - 1
                    let body = {
                        type: path.split('content-managing/')[1],
                        id,
                        action: 'UP',
                    }
                    await contentsManagingApi.updateOrderContents(body)

                    let updateorderNum = deepCloneData.map((item, i) => {
                        if (currentIndex === i) {
                            item.orderNum = item.orderNum - 1
                        }
                        ``
                        if (changeUpIndex === i) {
                            item.orderNum = item.orderNum + 1
                        }

                        return item
                    })

                    updateorderNum.sort((a, b) => a.orderNum - b.orderNum)
                    orderContentAction(updateorderNum)
                } catch (error) {
                }
            }
        } else if (down) {
            if (index !== deepCloneData.length - 1) {
                // requestTagAction()
                try {
                    let changeDownIndex = index + 1
                    let body = {
                        type: path.split('content-managing/')[1],
                        id,
                        action: 'DOWN',
                    }
                    await contentsManagingApi.updateOrderContents(body)

                    let updateorderNum = deepCloneData.map((item, i) => {
                        if (currentIndex === i) {
                            item.orderNum = item.orderNum + 1
                        }

                        if (changeDownIndex === i) {
                            item.orderNum = item.orderNum - 1
                        }

                        return item
                    })

                    updateorderNum.sort((a, b) => a.orderNum - b.orderNum)
                    orderContentAction(updateorderNum)
                } catch (error) {
                }
            }
        }
    }
    const [stateOfAlert, setStateOfAlert] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
    })
    const handleClick = (newState) => {
        setStateOfAlert({open: true, ...newState})
    }
    const deleteContent = async (id) => {
        const params = {
            id,
        }

        try {
            // requestTagAction()
            await contentsManagingApi.deleteContent(params)
            deleteContentAction(id)

            handleClick({
                vertical: 'top',
                horizontal: 'center',
                message: 'success',
            })

            location.reload()

        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                // createTagErrAction(error.response.data)
                // if (error.response.data.data.isShow === true) {
                //     handleClick({
                //         vertical: 'top',
                //         horizontal: 'center',
                //         message: error.response.data.data.error,
                //     })
                // } else {
                //     handleClick({
                //         vertical: 'top',
                //         horizontal: 'center',
                //         message: 'error',
                //     })
                // }
            }
        }
    }

    React.useEffect(() => {
        getListContentManaging()
    }, [formData.offset, formData.limit, formData.order, match?.params?.type])

    return (
        <div className='contents-managing'>

            {loading ? (
                    <Spinner />
                ) : (
                <TableContainer component={Paper}>
                    <Paper
                        variant='outlined'
                        square
                    >
                        <GridContainer className={[classes.paperSwitch]}>
                            <GridItem
                                // className={classes.symBolTag}
                                container
                                alignItems='center'
                                justifyContent='center'
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                            >
                                id
                            </GridItem>
                            <GridItem
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}>
                                썸네일
                            </GridItem>
                            <GridItem
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}>
                                좋아요 수 (집계 당시 좋아요 수)
                            </GridItem>
                            <GridItem
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}>
                                작성일
                            </GridItem>
                            <GridItem
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}>
                                작성자
                            </GridItem>
                            <GridItem
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}>
                                순서변경
                            </GridItem>
                            <GridItem
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}>
                                삭제
                            </GridItem>
                        </GridContainer>

                    </Paper>
                    {contents?.map((item, i) => {
                        return (
                            <Paper
                                key={i}
                                // className={[classes.paper, !(new Date(item.startAt).getTime() < new Date().getTime()  &&   new Date().getTime() < new Date(item.endAt).getTime()) && classes.notNow ]}
                                variant='outlined'
                                square
                            >
                                <GridContainer>
                                    <GridItem
                                        // className={classes.symBolTag}
                                        container
                                        alignItems='center'
                                        justifyContent='center'
                                        xs={1}
                                        sm={1}
                                        md={1}
                                        lg={1}
                                        xl={1}
                                    >
                                        {/*<p>{item.orderNum}</p>*/}
                                        <p>{i+1}</p>
                                    </GridItem>
                                    <GridItem
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}>
                                        <div >
                                            <img
                                                width='87px'
                                                height='87px'
                                                style={{ objectFit: 'cover' }}
                                                src={item?.post?.files[0].url}
                                                alt='...'
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}>
                                        {item && item.post?.totalLikes || 0} ({item && item.totalLikes || 0})
                                    </GridItem>
                                    <GridItem
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}>
                                        {moment(item && item.post?.createdAt).format('YYYY/MM/DD h:mmA')}
                                    </GridItem>
                                    <GridItem
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}>
                                        {item && item.post?.owner.memberID}<br/>&nbsp;@
                                        {item && item.post?.owner.nickname}
                                    </GridItem>
                                    <GridItem
                                        container
                                        direction='column'
                                        alignItems='center'
                                        style={{'display': 'flex','justify-content': 'center'}}
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                    >
                                        <TextField align='right'
                                                   onChange={(e)=>{
                                                       dispatch(changeContentAction({
                                                           id: item.id,
                                                           orderNum: e.target.value
                                                       }))
                                                   }}
                                                   onKeyUp={async (e) => {
                                                       if (e.keyCode == 13) {
                                                           if (confirm("변경하시겠습니까?")) {
                                                               await ContentManagingApi.updateWriterManagingOrder({
                                                                   id: item.id, orderNum: e.target.value
                                                               });
                                                               // location.reload()
                                                           }
                                                       }
                                                   }}
                                                   value={item.orderNum}
                                                   className={classes.inputTextAdd__textField}
                                                   label='숫자입력 후 enter'
                                                   name='orderNum'
                                                   variant='outlined'
                                                   size='small'
                                        />
                                    </GridItem>
                                    <GridItem
                                        container
                                        justifyContent='flex-end'
                                        xs={1}
                                        sm={1}
                                        md={1}
                                        lg={1}
                                        xl={1}
                                    >


                                        <IconButton
                                            disabled={loading}
                                            aria-label='delete'
                                            onClick={() => deleteContent(item.id)}
                                        >
                                            <HighlightOffIcon/>
                                        </IconButton>
                                    </GridItem>
                                </GridContainer>
                            </Paper>
                        )
                    })}
                </TableContainer>
                )}

        </div>
    )

}

const mapStateToProps = (state) => ({
    loading: state.contentsReducer.loading,
    error: state.contentsReducer.error,
    contents: state.contentsReducer.contents
})
export default connect(mapStateToProps, {
    getContentManagingAction,
    orderTagAction,
    deleteContentAction,
    changeContentAction

})(ContentsManaging)

