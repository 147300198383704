import {connect, useDispatch} from "react-redux";
import React from "react";
import Spinner from "../../components/Spinner/Spinner";
import {orderTagAction} from "../../redux/actions/tagManaging";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/PostManaging/postManaging";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CustomTextField from "../../components/Gm-TextField/TextField";
import Button from "../../components/CustomButtons/Button";
import ContentManagingApi from "../../api/contentManagingApi";
import {getContentsMultiManagingListAction} from "../../redux/actions/contentsManagingAction";
import {typeFilter} from "../../util/filter";
import {handleFormEvent} from "../../util/common";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "../../components/Gm-TextField/TextField";
import contentManagingApi from "../../api/contentManagingApi";

const useStyles = makeStyles(styles)

const ContentsMultiManaging = (props) => {

    // const classes = useStyles()
    const dispatch = useDispatch()
    const classes = useStyles()

    const {
        metaData: metadata,
        contentsMulti,
        getContentManagingAction,
        contentsMultiMain,
        match,
    } = props

    //-------------- state 세팅
    const [loading, setLoading] = React.useState(false)
    const [mode, setMode] = React.useState('I')
    const [formData, setFormData] = React.useState({
        id: 0,
        type: isNaN(match?.params?.type) ? match?.params?.type : match?.params?.type,
        title: '',
        limit: 1000,
        offset: 0,
        page: 0
    })

    //./ state 세팅


    const createContent = async ()=>{
        location.href = '/admin/content-managing/'+ match?.params?.type +'/detail'
    }
    const updateMultiMain = async (e) => {
        const data = await ContentManagingApi.updateMultiMain({
            type: match?.params?.type || 'SHOP_MAIN',
            title:formData?.title
        });
        if (data != null){
            alert("변경되었습니다.")
        }
    }

    const createTag = async () => {
        //생성
        const data = await ContentManagingApi.createMultiMain({
            title: contentsMultiMain.title,
            type:match?.params?.type || 'SHOP_MAIN'
        });

        //

        await getListContentsMultiManaging()
    }

    const deleteContent = async (id) => {
        const params = {
            id,
        }

        try {
            // requestTagAction()

            await ContentManagingApi.deleteContent(params)
            // deleteContentAction(id)
            //
            //
            // handleClick({
            //     vertical: 'top',
            //     horizontal: 'center',
            //     message: 'success',
            // })

            await getListContentsMultiManaging()

        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
                // createTagErrAction(error.response.data)
                // if (error.response.data.data.isShow === true) {
                //     handleClick({
                //         vertical: 'top',
                //         horizontal: 'center',
                //         message: error.response.data.data.error,
                //     })
                // } else {
                //     handleClick({
                //         vertical: 'top',
                //         horizontal: 'center',
                //         message: 'error',
                //     })
                // }
            }
        }
    }

    const ContentsMultiManagingDetail = async (event, item) => {
        location.href = '/admin/content-managing/'+match?.params?.type + "/detail/" + item.id
    }


    const getListContentsMultiManaging = async () => {
        try {

            const params = {
                limit: 10000,
                offset: 1,
                // type: 'SHOP_MAIN'
                type: match?.params?.type
            }
            // alert(match?.params?.type)
            setLoading(true)

            const {data} = await ContentManagingApi.getMultiOne(params)
            setFormData(data.contentsMultiMain)
            dispatch(getContentsMultiManagingListAction(data))
            // alert("fddfd")
            setLoading(false)
        } catch (error) {
            setLoading(false)
            if (error && error.response && error.response.data) {
                // dispatch(bestUserRequestErrorAction(error.response.data))
            }
        }
    }

    React.useEffect(() => {
        getListContentsMultiManaging()
    }, [formData.limit])

    const updateContentManaging = async (item) => {

        item.multiType = match?.params?.type
        const {data} = await contentManagingApi.update(item);
        if (data){
            location.reload()
        }

    }
    const handleChangeSelect = async (e, item ) => {
        item.status = e.target.value
        updateContentManaging(item);
    }

    const changeIndexOfArr = async (up, down, orderNum, id, index) => {
        const deepCloneData = [...contentsMulti]
        const currentIndex = index
        if (up) {
            if (index !== 0) {
                // requestTagAction()
                try {
                    let changeUpIndex = index - 1
                    let body = {
                        id,
                        action: 'UP',
                        type: match?.params?.type
                    }
                    await ContentManagingApi.updateOrderContents(body)

                    let updateorderNum = deepCloneData.map((item, i) => {
                        if (currentIndex === i) {
                            item.orderNum = item.orderNum - 1
                        }
                        ``
                        if (changeUpIndex === i) {
                            item.orderNum = item.orderNum + 1
                        }

                        return item
                    })

                    // updateorderNum.sort((a, b) => a.orderNum - b.orderNum)
                    await getListContentsMultiManaging()
                } catch (error) {
                    console.log(error.response)
                }
            }
        } else if (down) {
            if (index !== deepCloneData.length - 1) {
                // requestTagAction()
                try {
                    let changeDownIndex = index + 1
                    let body = {
                        id,
                        action: 'DOWN',
                        type: match?.params?.type
                    }
                    await ContentManagingApi.updateOrderContents(body)

                    let updateorderNum = deepCloneData.map((item, i) => {
                        if (currentIndex === i) {
                            item.orderNum = item.orderNum + 1
                        }

                        if (changeDownIndex === i) {
                            item.orderNum = item.orderNum - 1
                        }

                        return item
                    })

                    updateorderNum.sort((a, b) => a.orderNum - b.orderNum)
                    // orderContentAction(updateorderNum)
                    await getListContentsMultiManaging()

                } catch (error) {
                    console.log(error.response)
                }
            }
        }
    }


    return (
        <div className='contentsMulti-managing'>

            {loading ? (
                <Spinner/>
            ) : (
                <div>
                    <Paper className={classes.paper} variant='outlined' square>
                        <GridContainer>
                            <GridItem
                                className={classes.symBolTag}
                                container
                                alignItems='center'
                                justifyContent='center'
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                            >
                                {/*{match?.params?.type == 'MAIN_BOTTOM'  && (<p>#</p>) }*/}
                            </GridItem>
                            <GridItem
                                container
                                alignItems='center'
                                xs={8}
                            >
                                <CustomTextField
                                    className={classes.textFieldAddTag}
                                    id='tag-register-new'
                                    label={match?.params?.type == 'SHOP_CONTENT' ? '유저 꾸미기 연계영역' : '태그를 입력하세요'}
                                    fullWidth={true}
                                    size='small'
                                    onChange={(e)=>{
                                    handleFormEvent(e, 'title', formData, setFormData)}}
                                    value={formData?.title}
                                    // defaultValue='Default Value'
                                    variant='outlined'
                                />
                            </GridItem>
                            <GridItem
                                container
                                // justifyContent='flex-end'
                                className={classes.customStyleBtn}
                                xs={1}
                            >
                                <Button disabled={loading} onClick={() => updateMultiMain()} color='primary'>
                                    {formData?.title ? '수정' : '등록'}
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </Paper>

                    {/*추가*/}
                    <Button disabled={loading} onClick={() => createContent()} color='primary'>
                        추가
                    </Button>

                    <TableContainer component={Paper}>

                        <Paper
                            variant='outlined'
                            square
                        >
                            <GridContainer className={[classes.paperSwitch]}>
                                <GridItem
                                    // className={classes.symBolTag}
                                    container
                                    alignItems='center'
                                    justifyContent='center'
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    xl={1}
                                >
                                    No.
                                </GridItem>
                                <GridItem
                                    container
                                    alignItems='center'
                                    justifyContent='center'
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    md={2}
                                    lg={2}
                                    xl={2}>
                                    타입명
                                </GridItem>
                                <GridItem
                                    container
                                    direction='column'
                                    alignItems='center'
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}>
                                    제목<br/>
                                    부제목
                                </GridItem>
                                <GridItem
                                    container
                                    direction='column'
                                    alignItems='center'
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    xl={1}>
                                    태그
                                </GridItem>
                                <GridItem
                                    container
                                    direction='column'
                                    alignItems='center'
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    xl={1}>
                                    생성날짜
                                </GridItem>
                                <GridItem
                                    container
                                    direction='column'
                                    alignItems='center'
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}>
                                    수정
                                </GridItem>
                                <GridItem
                                    container
                                    direction='column'
                                    alignItems='center'
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    xl={1}>
                                    상태
                                </GridItem>
                                <GridItem
                                    container
                                    direction='column'
                                    alignItems='center'
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    xl={1}>
                                    순서변경
                                </GridItem>
                                <GridItem
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    xl={1}>
                                    삭제
                                </GridItem>
                            </GridContainer>

                        </Paper>
                        {/*#태그*/}


                        {contentsMulti?.map((item, i) => {
                            return (
                                <Paper
                                    key={i}
                                    // className={[classes.paper, !(new Date(item.startAt).getTime() < new Date().getTime()  &&   new Date().getTime() < new Date(item.endAt).getTime()) && classes.notNow ]}
                                    variant='outlined'
                                    square
                                >
                                    <GridContainer>
                                        <GridItem
                                            // className={classes.symBolTag}
                                            container
                                            alignItems='center'
                                            justifyContent='center'
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={1}
                                            xl={1}
                                        >
                                            <p>{i + 1}</p>
                                        </GridItem>
                                        <GridItem
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2} container
                                            alignItems='center'
                                            justifyContent='center'>
                                            {typeFilter(item.type) }
                                        </GridItem>
                                        <GridItem
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2} container
                                            alignItems='center'
                                            justifyContent='center'>
                                            {item.title} <br/>
                                            {item.contents}
                                        </GridItem>
                                        <GridItem
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={1}
                                            xl={1} container
                                            alignItems='center'
                                            justifyContent='center'>
                                            {item.tags}
                                        </GridItem>
                                        <GridItem
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2} container
                                            alignItems='center'
                                            justifyContent='center'>
                                            {moment(item && item?.createdAt).format('YYYY/MM/DD h:mmA')}
                                        </GridItem>
                                        <GridItem
                                            container
                                            direction='column'
                                            alignItems='center'
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={1}
                                            xl={1}
                                        >
                                            <Button color='primary'
                                                    onClick={(event) => ContentsMultiManagingDetail(event, item)}>
                                                수정
                                            </Button>
                                        </GridItem>
                                        <GridItem
                                            container
                                            direction='column'
                                            alignItems='center'
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={1}
                                            xl={1}
                                        >
                                            <TextField
                                                id='user-managing-select'
                                                select
                                                size='small'
                                                className={classes.textFieldOne}
                                                value={item.status}
                                                onChange={(e)=>{
                                                    handleChangeSelect(e,item)
                                                }}
                                            >
                                                <MenuItem value={'ACTIVE'}>활성화</MenuItem>
                                                <MenuItem value={'ALWAYS'}>영구 노출</MenuItem>
                                            </TextField>
                                        </GridItem>
                                        <GridItem
                                            container
                                            direction='column'
                                            alignItems='center'
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={1}
                                            xl={1}
                                        >
                                            <div>
                                                {<IconButton
                                                    size='small'
                                                    disabled={loading}
                                                    onClick={() =>
                                                        changeIndexOfArr(
                                                            true,
                                                            false,
                                                            item.orderNum,
                                                            item.id,
                                                            i,
                                                        )
                                                    }
                                                >
                                                    <ExpandLessIcon/>
                                                </IconButton>}
                                            </div>
                                            <div>
                                                {<IconButton
                                                    size='small'
                                                    disabled={loading}
                                                    onClick={() =>
                                                        changeIndexOfArr(
                                                            false,
                                                            true,
                                                            item.orderNum,
                                                            item.id,
                                                            i,
                                                        )
                                                    }
                                                >
                                                    <ExpandMoreIcon/>
                                                </IconButton>}
                                            </div>
                                        </GridItem>
                                        <GridItem
                                            container
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={1}
                                            xl={1}
                                        >
                                            <IconButton
                                                disabled={loading}
                                                aria-label='delete'
                                                onClick={() => deleteContent(item.id)}
                                            >
                                                <HighlightOffIcon/>
                                            </IconButton>
                                        </GridItem>
                                    </GridContainer>
                                </Paper>
                            )
                        })}
                    </TableContainer>
                </div>
            )}

        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.contentsReducer.loading,
    error: state.contentsReducer.error,
    contentsMulti: state.contentsReducer.contentsMulti
})
export default connect(mapStateToProps, {
    // getContentManagingAction,
    orderTagAction,
    getContentsMultiManagingListAction
    // deleteContentAction/
})(ContentsMultiManaging)
