import * as actionTypes from '../actions/types'
const initialState = {
    loading: true,
    error: null,
    contents: [],
    contentsMultiMain: null,
    contentsMulti: [],
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case actionTypes.GET_LIST_CONTENT:
            const {contents, metaData} = payload
            return {
                ...state,
                loading: false,
                contents : contents,
                metaData,
            }
        case actionTypes.DELETE_CONTENT_ACTION:
            return {
                ...state,
                loading: false,
                contents: state.contents.filter((content) => content.id !== payload),
                error: null,
            }
        case actionTypes.GET_LIST_CONTENT_MULTI:
            // const {contents1} = payload
            const contentsMulti = payload.contents

            return {
                ...state,
                loading: false,
                contentsMulti: contentsMulti,
                contentsMultiMain: payload.contentsMultiMain,
                error: null,
            }

        case actionTypes.CHANGE_CONTENT_ACTION:
            let {id, orderNum} = payload

            return {
                ...state,
                contents: state.contents.filter(item => {
                    if(item.id == id){
                        item.orderNum = orderNum
                        return item
                    }else{
                        return item
                    }
                })
            }
        default:
            return state
    }

}