import { primaryColor } from 'assets/jss/material-dashboard-pro-react.js'

const pushManagingStyle = (theme) => ({
    contentsStyle: {
        height: '50px',
        width: '100%'

    },
    linkTypeInput: {
        width:'100%',
    },
    textFieldOne: {
        width:'100%',
    },
    widthTextFieldModal: {
        // width: '25%',
        // '& div': {
        //     '& input': {
        //         [theme.breakpoints.down('md')]: {
        //             fontSize: '14px',
        //         },
        //     },
        // },
        // [theme.breakpoints.down('sm')]: {
        //     width: '40%',
        // },
        // [theme.breakpoints.down('xs')]: {
        //     width: '100%',
        // },
    },
})

export default pushManagingStyle
