import axiosInterceptors from 'api/axios'


const splashManagingApi = {
    getListSplash: (params) => {
        const url = `/splash/getSplashList`
        return axiosInterceptors.get(url, { params })
    },
    create: (param) => {
        const url = '/splash/createSplash'
        return axiosInterceptors.post(url, { param })
    },
    getsplash: (params) => {
        const url = '/splash/getDetailSplash'
        return axiosInterceptors.get(url, { params })
    },
    update: (params) => {
        const url = '/splash/updateSplash'
        return axiosInterceptors.put(url, { params })
    },
    destroy: (params) => {
        const url = '/splash/deleteSplash'
        return axiosInterceptors.delete(url, { params })
    }
}


export default splashManagingApi