import * as actionTypes from '../actions/types'

const initialState = {
    loading: false,
    mains: [],
    metaData: {
        totalPages: 1,
    },
    totalWriterMain: 0,
    totalWriterMainBySearch: 0,
    paginationWriterManaging: 1,

    // user-detail
    writerDetail: null,
    error: null,
    listReportedInWriterDetail: [],
    metaDataForListReportedInWriterDetail: {
        totalPages: 1,
    },
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case actionTypes.REQUEST_WRITER_MAIN_MANAGING:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.GET_LIST_WRITER_MAIN:
            const {
                mains,
                metaData,
                totalWriterMain,
                totalWriterMainBySearch,
                hasClientIdData,
            } = payload

            const resetTotalUserBySearchToZeroIfHasClientIdEqualFalse = hasClientIdData
                ? state.totalWriterMainBySearch
                : 0
            return {
                ...state,
                loading: false,
                error: null,
                mains: mains,
                metaData,
                totalWriterMain: totalWriterMain !== null ? totalWriterMain : state.totalWriterMain,
                totalWriterMainBySearch:
                    totalWriterMainBySearch !== null
                        ? totalWriterMainBySearch
                        : resetTotalUserBySearchToZeroIfHasClientIdEqualFalse,
            }
        case actionTypes.SET_PAGINATION_WRITERMAINMANAGING:
            return {
                ...state,
                loading: false,
                error: null,
                paginationWriterManaging: payload,
            }
        case actionTypes.REQUEST_WRITER_MAIN_MANAGING_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        case actionTypes.CHANGE_WRITER_MAIN_ORDER:
            let {id, orderNum} = payload
            return {
                ...state,
                mains: state.mains.filter(item => {
                    if(item.id == id){
                        item.orderNum = orderNum
                        return item
                    }else{
                        return item
                    }
                })
            }
        default:
            return state
    }
}
