import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from 'components/CustomButtons/Button'
import TextField from 'components/Gm-TextField/TextField'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { useHistory } from 'react-router-dom'

import { connect } from 'react-redux'
import { addNoticeAction, setIsCheckedAction } from 'redux/actions/notice'
import noticeApi from 'api/noticeApi'

import styleAlert from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
const useStylesAlert = makeStyles(styleAlert)
import styles from 'assets/jss/material-dashboard-pro-react/views/Notice/notice'
import GridItem from "../../components/Grid/GridItem";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import GridContainer from "../../components/Grid/GridContainer";
import Paper from "@material-ui/core/Paper";
import TimePicker from "../Statistics/components/TimePicker2";
import TimePickerMin from "../Statistics/components/TimePickerMin";
import moment from "moment";
import Switch from '@material-ui/core/Switch'
import {dateTimeCheckThree} from "../../util/common";
const useStyles = makeStyles(styles)

const NoticeAdd = ({
  addNoticeAction,
  setIsCheckedAction,
  isCheckedInNoticeAdd,
}) => {
  const classes = useStyles()
  const classesAlert = useStylesAlert()
  const history = useHistory()
  const d = new Date()
  let tz = d.getTimezoneOffset() / 60
  tz = tz < 0 ? tz * -1 : tz
  const [loading, setLoading] = React.useState(false)
  const [isSetSendTime, setSendTime] = React.useState(false)
  const [alert, setAlert] = React.useState(null)
  const [titleNoticeState, setTitleNoticeState] = React.useState('')
  const [contentNoticeState, setContentNoticeState] = React.useState('')
  const [formData, setFormData] = React.useState({
    title: '',
    content: '',
    type: 'EVENT',
    startAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
    fromTime: 0,
    fromMin: 0,
  })

  const { title, content } = formData

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true
    }
    return false
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const handleChangeTimePickerStart = (event) => {
    setFormData({...formData, fromTime: event.target.value})
  }

  const handleForm = (value, key) => {
    //vue의 데이터 매핑 형태였던 것 같은데.... key도 매핑되네 히야...

    switch (key) {
      case 'startAt' :
        setFormData({
          ...formData,
          [key]: moment(value).format('YYYY-MM-DD'),
        })
        break;
      case 'endAt' :
        setFormData({
          ...formData,
          [key]: moment(value).format('YYYY-MM-DD'),
        })
        break;
      case 'type' :
        setFormData({
          ...formData,
          [key]: value,
        })
        break;
      default:
        setFormData({
          ...formData,
          [key]: value,
        })

    }
  }
  const callApiAddNotice = async () => {
    title || setTitleNoticeState('error')
    content || setContentNoticeState('error')

    if (!title || !content) {
      return
    }



    let body = {
      ...formData,
      isShow: isCheckedInNoticeAdd,
    }

    if (isSetSendTime){

      body.sendTime =  new Date(moment(dateTimeCheckThree(formData.startAt, formData.fromTime, formData.fromMin)).format("YYYY-MM-DD HH:mm")).getTime();

    }else{
      body.sendTime = null
    }

    delete body.startAt
    delete body.fromMin
    delete body.fromTime

    try {
      setLoading(true)
      const { data } = await noticeApi.addNotice(body)
      addNoticeAction(data)
      setLoading(false)
      setFormData({ ...formData, title: '', content: '', type: 'EVENT',startAt: moment().subtract(tz, 'hours').format('YYYY-MM-DD'),
        fromTime: 0,
        fromMin: 0, })
      setIsCheckedAction(false)
      showAlert()
    } catch (error) {
      setLoading(false)
    }
  }

  const showAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: 'block', marginTop: '-100px' }}
        title='등록 완료되었습니다'
        confirmBtnCssClass={classesAlert.button + ' ' + classesAlert.success}
        cancelBtnCssClass={classesAlert.button + ' ' + classesAlert.danger}
        confirmBtnText='확인'
        onConfirm={() => {
          hideAlert()
          history.goBack()
        }}
        onCancel={() => hideAlert()}
      ></SweetAlert>,
    )
  }

  const handleSnedTime = () =>{
    setSendTime(!isSetSendTime)
  }

  const hideAlert = () => {
    setAlert(null)
  }

  return (
    <div className='notice-add'>
      {alert}


      {/*1.5버전 공지사항 알림 시간 추가*/}
      {/*공지사항 알림을 안걸고 싶어할지도 모르는데 이건 어캄?*/}
      <Paper variant='outlined' className={classes.paper} style={{'padding': '10px'}}>
        <GridContainer className={classes.grid} alignItems='center'>
          <GridItem xs={1} sm={1} md={1} className={classes.lableSt} style={{'marginLeft': '20px', }}>
            <Switch
                checked={isSetSendTime}
                onChange={handleSnedTime}
                // disabled={loading}
                name='checkedA'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />

          </GridItem>


          {
            isSetSendTime ? (

                <React.Fragment>
                  <GridItem xs={1} sm={1} md={1} className={classes.lableSt} style={{'marginLeft': '20px', }}>
                    발송 시간
                  </GridItem>

                <GridItem
                    xs={2}
                    sm={2}
                    // className={classes.styleDatePicker}
                >



                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        variant='inline'
                        TextFieldComponent={TextField}
                        format='yyyy-MM-dd'
                        id='date-picker-inline1'
                        value={formData?.startAt}
                        className={classes.inputT}
                        // style={{height: '40px'}}
                        onChange={(date) =>
                            handleForm(date, 'startAt')
                        }
                        autoOk={true}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                    />
                  </MuiPickersUtilsProvider>

                </GridItem>

            <GridItem
            xs={1}
            sm={1}
            // className={classes.styleDatePicker}
            >

            <Box>
            <TimePicker
            time={formData?.fromTime}
            handleChangeTimePicker={handleChangeTimePickerStart}
            />
            </Box>
            </GridItem>
            <GridItem
            xs={1}
            sm={1}
            // className={classes.styleDatePicker}
            >

            <Box>
            <TimePickerMin
            time={formData?.fromMin}
            handleChangeTimePicker={(e) => {
            handleForm(e.target.value, 'fromMin')
          }}
            />
            </Box>
            </GridItem>
                </React.Fragment>
            ) : null
          }


      </GridContainer>
      </Paper>

     <Box className='notice-title' mb={2} style={{'margin-top': '10px'}}>
        <TextField
          error={titleNoticeState === 'error'}
          id='notice-title-input'
          value={title}
          name='title'
          onChange={(e) => {
            if (verifyLength(e.target.value, 1)) {
              setTitleNoticeState('success')
            } else {
              setTitleNoticeState('error')
            }

            handleChange(e)
          }}
          label='제목 입력'
          fullWidth={true}
          variant='outlined'
          size='small'
        />
      </Box>

      <Box className='notice-detail'>
        <TextareaAutosize
          className={classes.textareaAutosize}
          minRows={5}
          maxRows={9}
          aria-label='maximum height'
          placeholder='내용 입력'
          value={content}
          onChange={handleChange}
          name='content'
        />
        {/* <TextField
          error={contentNoticeState === 'error'}
          id='notice-title-input2'
          value={content}
          name='content'
          onChange={(e) => {
            if (verifyLength(e.target.value, 1)) {
              setContentNoticeState('success')
            } else {
              setContentNoticeState('error')
            }

            handleChange(e)
          }}
          label='내용 입력'
          fullWidth={true}
          variant='outlined'
          size='small'
        /> */}
      </Box>

      <Box
        display='flex'
        justifyContent='center'
        className='notice-register-button'
      >
        <Button onClick={callApiAddNotice} disabled={loading} color='primary'>
          등록하기
        </Button>
      </Box>
    </div>
  )
}

const mapStateToProps = (state) => ({
  isCheckedInNoticeAdd: state.notice.isCheckedInNoticeAdd,
})

export default connect(mapStateToProps, {
  addNoticeAction,
  setIsCheckedAction,
})(NoticeAdd)
