import React, {useEffect} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/PostManaging/postManaging";
import {connect, useDispatch} from "react-redux";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import TextField from "../../components/Gm-TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextFieldForDatePicker from "../../components/Gm-TextField/TextFieldForDatePicker";
import Box from "@material-ui/core/Box";
import TimePicker from "./components/TimePicker";
import {
    dateTimeCheck,
    handleFormEvent,
    handleFormValue,
    timeZoneFnc
} from "../../util/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import AiCommentTables from "./components/AiCommentTables";
import commentsManangingApi from "../../api/commentsManangingApi";
import moment from "moment/moment";

import postManagingApi from "../../api/postManagingApi";
import {
    getAiCommentListAction,
    setAiManagingAction,
    setAiPhrase,
    setAiPhraseAction
} from "../../redux/actions/aiManagingAction";
import Button from "../../components/CustomButtons/Button";
import AiSystemPhraseModal from "./components/AiSystemPhraseModal";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import noticeApi from "../../api/noticeApi";
import settingApi from "../../api/settingApi";
import writerGoodsManagingApi from "../../api/writerGoodsManagingApi";
import fileDownload from "js-file-download";

const useStyles = makeStyles(styles)

const AiManaging = ({
                        aiManagingLists,
                        setAiManagingAction,
                        metaData: {  totalPages },

                    }) => {

    const dispatch = useDispatch()

    const classes = useStyles()
    const [loadingBtn, setLoadingBtn] = React.useState(false)
    const [pagination, setPagination] = React.useState(1)
    const [modal, setModal] = React.useState(null)
    const [aiOnOff, setAiOnOff] =React.useState(false)
    const [formData, setFormData] = React.useState({
        orderBy: '',
        rowsPerPage: 10,
        searchType: 'ALL', //검색타입
        searchText: '', //검색어
        limit: 10,
        fromDate: moment().subtract(7, 'days').subtract(timeZoneFnc(), 'hours').calendar({
            sameElse: 'YYYY-MM-DD',
        }),
        toDate: moment().subtract(timeZoneFnc(), 'hours').format('YYYY-MM-DD'),
        fromTime: _.split(moment().add(1, 'hours').format('YYYY-MM-DD, H'), ',', 2)[1]?.trim(),
        toTime: 23,
    })
    const headCells = [
        {
            id: 'id',
            numeric: 'left',
            disablePadding: false,
            label: 'No.',
        },
        {
            id: 'content',
            numeric: 'center',
            disablePadding: false,
            label: '댓글 내용',
        },
        {
            id: 'createdAt',
            numeric: 'center',
            disablePadding: false,
            label: '업로드 일자',
        },
        {
            id: 'edit',
            numeric: 'center',
            disablePadding: false,
            label: '수정',
        },
        {
            id: 'delete',
            numeric: 'center',
            disablePadding: false,
            label: '삭제',
        },
        {
            id: 'title',
            numeric: 'center',
            disablePadding: false,
            label: '게시글 제목',
        },
        {
            id: 'detail',
            numeric: 'center',
            disablePadding: false,
            label: '게시글 본문',
        },
    ]

    const getAiComments = async () => {
        try {
            let compiled = _.template('${ date } ${ time }:00:00')

            let params = JSON.parse(JSON.stringify(formData))


            params.offset = pagination
            params.startAt = new Date(moment(dateTimeCheck(formData.fromDate, formData.fromTime)).format("YYYY-MM-DD HH:mm")).getTime()
            params.endAt = new Date(moment(dateTimeCheck(formData.toDate, formData.toTime)).format("YYYY-MM-DD HH:mm")).getTime()

            setLoadingBtn(true)
            const {data} = await commentsManangingApi.getListAiComment(params)
            dispatch(getAiCommentListAction(data))
            setLoadingBtn(false)


        } catch (error) {
            setLoadingBtn(false)
            console.log(error.response)
            if (error && error.response && error.response.data) {
                // postManagingErrAction(error.response.data)
            }
        }
    }

    const getAiCommentsExcel = async () => {
        try {

            let params = JSON.parse(JSON.stringify(formData))

            params.startAt = new Date(moment(dateTimeCheck(formData.fromDate, formData.fromTime)).format("YYYY-MM-DD HH:mm")).getTime()
            params.endAt = new Date(moment(dateTimeCheck(formData.toDate, formData.toTime)).format("YYYY-MM-DD HH:mm")).getTime()

            const data  = await commentsManangingApi.getListAiCommentExcel(params)

            fileDownload(data, 'comments.xlsx')

        }catch (error){
            if (error && error.response && error.response.data) {
                // postManagingErrAction(error.response.data)
            }
        }
    }


    const phraseModal = async () => {
        /*const data = await getNewCode({
            depth: 0,
            parentCode: null
        })*/
        setModal(
            <AiSystemPhraseModal
                hideAlert={(e)=>{
                    setModal(null)
                }}
                title={'시스템 문구 수정'}
                setAiPhraseAction={setAiPhraseAction}
                dispatch={dispatch}
                setModal={setModal}
            />
        )
    }

    useEffect(() => {
        // document.querySelector('.sweet-alert').parentElement.style.zIndex = 1034;
        getAiComments();
        getAiOnOff()
    }, [pagination])
    const getAiOnOff = async ()=>{
        try {
            // setLoading(true)
            const { data
            } = await settingApi.getAiPhrase()
            setAiOnOff(data.aiOnOff)
        } catch (error) {
            console.log(error.response)
        }
    }


    const toggleAiOnOff = async ()=>{
        try {
            // setLoading(true)
            const { data
            } = await settingApi.toggleAiOnOff({
                aiOnOff: !aiOnOff
            })
            setAiOnOff(!aiOnOff)
        } catch (error) {
            console.log(error.response)
        }
    }



    return (
        <div className='post-managing'>
            {modal}
            <GridContainer>
                <GridItem>
                {/*toggle*/}
                <Box my={2}>
                    <Paper
                        className={`${classes.paperSwitch} ${classes.postDetailToggleBtn}`}
                        variant='outlined'
                        style={{'width': '100%'}}
                    >
                        <p>AI 댓글 사용 on/off</p>
                        <Switch
                            checked={aiOnOff}
                            onChange={(e)=> {
                                toggleAiOnOff()
                            }}
                            name='checkedA'
                            inputProps={{'aria-label': 'secondary checkbox'}}
                        />
                    </Paper>
                </Box>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem
                    className={classes.gridContainerOne}
                    md={1}
                    xs={3}
                >
                    <TextField
                        id='type-managing-select'
                        select
                        size='small'
                        className={classes.textFieldOne}
                        label='검색 타입'
                        value={formData?.searchType}
                        onChange={(e) => handleFormEvent(e, "searchType", formData, setFormData)}
                    >
                        <MenuItem value={'ALL'}>전체</MenuItem>
                        <MenuItem value={'comment'}>댓글 내용</MenuItem>
                        <MenuItem value={'title'}>게시글 제목</MenuItem>
                    </TextField>
                </GridItem>
                <GridItem md={6} xs={9}>
                    <TextField
                        id='post-managing-textfield'
                        size='small'
                        style={{'width': '100%'}}
                        placeholder='검색어를 입력해주세요'
                        name='searchText'
                        onChange={(e) => {
                            handleFormEvent(e, 'searchText', formData, setFormData)
                        }}
                        value={formData?.searchText}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer style={{'paddingTop': '10px', 'paddingBottom': '10px'}}>

                <GridItem xs={1} md={1} className={classes.responsiveStyle} className={classes.lableSt}
                          className={classes.mobileHidden}
                          style={{'margin': 'auto'}}
                >
                    일자
                </GridItem>
                <GridItem
                    container
                    alignItems='left'
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11}
                    xl={11}
                >
                    <GridContainer
                    >
                        <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                            className={classes.responsiveStyle}
                        >
                            <GridContainer>
                                <GridItem
                                    xs={7}
                                    sm={5}
                                    md={4}
                                    lg={7}
                                    xl={7}
                                    className={classes.styleDatePicker}
                                >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            className={`${classes.resDateTimePicker}`}
                                            variant='inline'
                                            TextFieldComponent={TextFieldForDatePicker}
                                            format='yyyy/MM/dd'
                                            id='date-picker-inline1'
                                            value={formData?.fromDate}
                                            onChange={(e) => {
                                                handleFormValue(e, 'fromDate', formData, setFormData)
                                            }}
                                            autoOk={true}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </GridItem>
                                <GridItem
                                    className={classes.paddingLeft}
                                    xs={5}
                                    sm={3}
                                    md={3}
                                    lg={5}
                                    xl={5}
                                >
                                    <Box className={classes.setMarginLeft}>
                                        <TimePicker
                                            time={formData?.fromTime}
                                            handlechangetimepicker={(e) => handleFormEvent(e, 'fromTime', formData, setFormData)}
                                        />
                                    </Box>
                                </GridItem>


                            </GridContainer>
                        </GridItem>
                        <GridItem
                            container
                            alignItems='left'
                            xs={1}
                            sm={1}
                            md={1}
                            lg={1}
                            xl={1}
                        >
                            <Box
                                display='flex'
                                flexDirection='center'
                                alignItems='center'
                                className={classes.styleSymbol}
                            >
                                <p>~</p>
                            </Box>
                        </GridItem>
                        <GridItem
                            container
                            alignItems='left'
                            xs={12}
                            md={5}
                            lg={5}
                            xl={5}
                        >
                            <GridContainer>
                                <GridItem
                                    xs={6}
                                    md={4}
                                    lg={7}
                                    xl={7}
                                    className={classes.styleDatePicker}
                                >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            variant='inline'
                                            className={`${classes.resDateTimePicker}`}
                                            format='yyyy/MM/dd'
                                            TextFieldComponent={TextFieldForDatePicker}
                                            id='date-picker-inline2'
                                            autoOk={true}
                                            value={formData?.toDate}
                                            onChange={(e) => {
                                                handleFormValue(e, 'toDate', formData, setFormData)
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </GridItem>
                                <GridItem
                                    className={classes.paddingLeft}
                                    xs={6}
                                    md={3}
                                    lg={5}
                                    xl={5}
                                >
                                    <Box className={classes.setMarginLeft}>
                                        <TimePicker
                                            time={formData?.toTime}
                                            name={'toTime'}
                                            handlechangetimepicker={(e) => handleFormEvent(e, 'toTime', formData, setFormData)}
                                        />
                                    </Box>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem container
                                  alignItems='left'
                                  xs={1}
                                  sm={1}
                                  md={1}
                                  lg={1}
                                  xl={1}>

                            <Box className={classes.styleButtonSubmit}>
                                <Button
                                    disabled={loadingBtn}
                                    color='primary'
                                    onClick={(e) => {
                                        setFormData({...formData, offset: 0})
                                        getAiComments()
                                    }}
                                >
                                    검색
                                </Button>
                            </Box>
                        </GridItem>


                    </GridContainer>

                </GridItem>
            </GridContainer>
            <GridContainer style={{'display': 'flex', 'justify-content': 'right'}}>
                <GridItem style={{'display': 'flex', 'justify-content': 'right'}}>
                    <Box className={classes.btnGetExcelAndPaginationTable} mb={2}>
                        <Button
                            color='primary'
                            // disabled={loadingBtnGetExcel}
                            onClick={(e) => {
                                phraseModal()
                            }}
                        >
                            시스템 문구 수정
                        </Button>
                    </Box>
                    <Box className={classes.btnGetExcelAndPaginationTable} mb={2}>
                        <Button
                            color='info'
                            // disabled={loadingBtnGetExcel}
                            onClick={getAiCommentsExcel}
                        >
                            엑셀 다운로드
                        </Button>
                    </Box>
                    <Box className={classes.btnGetExcelAndPaginationTable} mb={2}>
                        <Button
                            color=""
                            // disabled={loadingBtnGetExcel}
                            // onClick={getExcelFileUserManaging}
                        >
                            삭제하기
                        </Button>
                    </Box>
                </GridItem>
            </GridContainer>
            <Box className={classes.boxAiCommentTablesBlock} my={2}>
                {loadingBtn ? (
                    <CircularProgress size={30} className={classes.buttonProgress}/>
                ) : (
                    <AiCommentTables
                        dispatch={dispatch}
                        headCells={headCells}
                        rows={aiManagingLists}
                        setFormData={setFormData}
                        formData={formData}
                        commentsManangingApi={commentsManangingApi}
                        setAiManagingAction={setAiManagingAction}
                        modal={modal}
                        setModal={setModal}
                        pagination={pagination}
                        setPagination={setPagination}
                        totalPages={totalPages}

                        /*deletePostAction={deletePostAction}
                        movePostAction={movePostAction}
                        movePostPushAction={movePostPushAction}

                        postManagingErrAction={postManagingErrAction}
                        setChangePostInfoId={setChangePostInfoId}
                        getListPostManagingExcel={getListPostManagingExcel}*/
                    />
                )}
            </Box>

        </div>
    )
}


const mapStateToProps = (state) => ({
    aiManagingLists: state.aiCommentManagingReducer.aiCommentManaging,
    formDataGlobal: state.aiCommentManagingReducer.formDataGlobal,
    metaData: state.aiCommentManagingReducer.metaData,
    /*
    pagination: state.AiManaging.pagination,
    formDataGlobal: state.AiManaging.formDataGlobal,
    totalPostByTag: state.AiManaging.totalPostByTag,
    totalPost: state.AiManaging.totalPost,
    events: state.AiManaging.events*/
})

export default connect(mapStateToProps, {
    setAiManagingAction
    /*requestAiManagingAction,
    getListAiManagingAction,
    deletePostAction,
    movePostAction,
    AiManagingErrAction,
    setPaginationAction,
    setFormDataGlobalAction,*/
})(AiManaging)
