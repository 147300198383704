import axiosInterceptors from 'api/axios'
import queryString from "query-string";

const writerManagingApi = {

	getWriter: (params) => {
		const url = '/writer/writerManaging/getWriter'
		return axiosInterceptors.get(url, { params })
	},
	update: (params) => {
		const url = '/writer/writerManaging/update'
		return axiosInterceptors.get(url, { params })
	},
	create: (params) => {
		const url = '/writer/writerManaging/create'
		return axiosInterceptors.post(url, { params })
	},
	getListWriters: (params) => {
		const url = '/writer/writerManaging/getListWriter'
		return axiosInterceptors.get(url, { params })
	},
	getListWritersExcel: (params) => {
		const url = `/writer/writerManaging/getListWritersExcel?${queryString.stringify(
			params,
		)}`
		return axiosInterceptors.get(url, {
			responseType: 'blob',
		})
	},
	setExcelFile: (body) => {
		const url = '/writer/writerManaging/setExcelFile'
		return axiosInterceptors.post(url, body)
	},

	saveImageFile: (body) => {
		const url = '/writer/writerManaging/saveImageFile'
		return axiosInterceptors.post(url, body)
	},

	deleteWriterManaging: (params) => {
		const url = '/writer/writerManaging/delete'
		return axiosInterceptors.delete(url, { params })
	},
	toggleWriterExposureMain: (param)=> {
		const url = "/writer/writerManaging/toggleWriterExposeMain"
		return axiosInterceptors.put(url, {param});
	},
	toggleIsSync:(param)=>{
		const url = "/writer/writerManaging/toggleIsSync"
		return axiosInterceptors.put(url, {param});
	},
	updateWriterUserId:(param)=>{
		const url = "/writer/writerManaging/updateWriterUserId"
		return axiosInterceptors.put(url, {param});
	}

}

export default writerManagingApi