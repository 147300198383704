import {connect, useDispatch} from "react-redux";
import React from "react";
import Spinner from "../../components/Spinner/Spinner";
import {changeGoodsManagingAction, getGoodsManagingListAction} from "../../redux/actions/goodsManagingAction";
import {orderTagAction} from "../../redux/actions/tagManaging";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/PostManaging/postManaging";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CustomTextField from "../../components/Gm-TextField/TextField";
import Button from "../../components/CustomButtons/Button";
import GoodsManagingApi from "../../api/goodsManagingApi";
import {handleFormEvent} from "../../util/common";
import tagApi from "../../api/tagApi";
import TextField from "../../components/Gm-TextField/TextFieldForDatePicker";
import goodsManagingApi from "../../api/goodsManagingApi";

const useStyles = makeStyles(styles)

const GoodsManaging = (props) => {

    // const classes = useStyles()
    const dispatch = useDispatch()
    const classes = useStyles()

    const {
        metaData: metadata,
        contents,
        getContentManagingAction,
        match,
    } = props

    //-------------- state 세팅
    const [loading, setLoading] = React.useState(false)
    const [mode, setMode] = React.useState('I')
    const [formData, setFormData] = React.useState({
        id: 0,
        type: isNaN(match?.params?.type) ? match?.params?.type : match?.params?.type,
        title: '',
        limit: 1000,
        offset: 0,
        page: 0
    })

    //./ state 세팅

    const createTag = async () => {

        let convertInputTag
        // if (match?.params?.type == 'MAIN_BOTTOM')
        // if (formData.title.includes('#')) {
            convertInputTag = formData.title
        // } else {
        //     convertInputTag = `#${formData.title}`
        // }

        //생성
        const data = await GoodsManagingApi.create({
            title: convertInputTag,
            type:match?.params?.type || 'MAIN_BOTTOM'
        });

        await getListGoodsManaging()
    }

    const deleteContent = async (id) => {
        const params = {
            id,
        }

        try {
            // requestTagAction()
            await GoodsManagingApi.deleteContent(params).then(async (e) => {
                await getListGoodsManaging()
            })


        } catch (error) {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.data
            ) {
            }
        }
    }

    const goodsManagingDetail = async (event, item) => {
        location.href = '/admin/recommend-writer-goods-managing/'+match?.params?.type + '/detail/' + item.id;
    }


    const getListGoodsManaging = async () => {
        try {

            const params = {
                limit: 10000,
                offset: 1,
                type: match?.params?.type
            }
            setLoading(true)

            const {data} = await GoodsManagingApi.getList(params)
            dispatch(getGoodsManagingListAction(data))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            if (error && error.response && error.response.data) {
                // dispatch(bestUserRequestErrorAction(error.response.data))
            }
        }
    }

    React.useEffect(() => {
        getListGoodsManaging()
    }, [formData.limit, match?.params?.type])

    const changeIndexOfArr = async (up, down, orderNum, id, index) => {
        const deepCloneData = [...contents]
        const currentIndex = index
        if (up) {
            if (index !== 0) {
                // requestTagAction()
                try {
                    let changeUpIndex = index - 1
                    let body = {
                        id,
                        action: 'UP',
                    }
                    await GoodsManagingApi.updateOrderContents(body)

                    let updateorderNum = deepCloneData.map((item, i) => {
                        if (currentIndex === i) {
                            item.orderNum = item.orderNum - 1
                        }
                        ``
                        if (changeUpIndex === i) {
                            item.orderNum = item.orderNum + 1
                        }

                        return item
                    })

                    await getListGoodsManaging()
                } catch (error) {
                    console.log(error.response)
                }
            }
        } else if (down) {
            if (index !== deepCloneData.length - 1) {
                // requestTagAction()
                try {
                    let changeDownIndex = index + 1
                    let body = {
                        id,
                        action: 'DOWN',
                    }
                    await GoodsManagingApi.updateOrderContents(body)

                    let updateorderNum = deepCloneData.map((item, i) => {
                        if (currentIndex === i) {
                            item.orderNum = item.orderNum + 1
                        }

                        if (changeDownIndex === i) {
                            item.orderNum = item.orderNum - 1
                        }

                        return item
                    })

                    updateorderNum.sort((a, b) => a.orderNum - b.orderNum)
                    // orderContentAction(updateorderNum)
                    await getListGoodsManaging()

                } catch (error) {
                    console.log(error.response)
                }
            }
        }
    }


    return (
        <div className='contents-managing'>

            {loading ? (
                <Spinner/>
            ) : (
                <div>
                    <Paper className={classes.paper} variant='outlined' square>
                        <GridContainer>
                            <GridItem
                                className={classes.symBolTag}
                                container
                                alignItems='center'
                                justifyContent='center'
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                            >
                                {/*{match?.params?.type == 'MAIN_BOTTOM'  && (<p>#</p>) }*/}
                            </GridItem>
                            <GridItem
                                container
                                alignItems='center'
                                xs={8}
                            >
                                <CustomTextField
                                    className={classes.textFieldAddTag}
                                    id='tag-register-new'
                                    label={match?.params?.type == 'SHOP_MAIN' ? '제목을 입력하세요' : '태그를 입력하세요'}
                                    fullWidth={true}
                                    size='small'
                                    onChange={(e)=>{
                                    handleFormEvent(e, 'title', formData, setFormData)}}
                                    value={formData?.title}
                                    // defaultValue='Default Value'
                                    variant='outlined'
                                />
                            </GridItem>
                            <GridItem
                                container
                                // justifyContent='flex-end'
                                className={classes.customStyleBtn}
                                xs={1}
                            >
                                <Button disabled={loading} onClick={() => createTag()} color='primary'>
                                    {mode == 'I' ? '등록' : '수정'}
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </Paper>
                    <TableContainer component={Paper}>

                        <Paper
                            variant='outlined'
                            square
                        >
                            <GridContainer className={[classes.paperSwitch]}>
                                <GridItem
                                    // className={classes.symBolTag}
                                    container
                                    alignItems='center'
                                    justifyContent='center'
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    xl={1}
                                >
                                    No.
                                </GridItem>
                                <GridItem
                                    container
                                    alignItems='center'
                                    justifyContent='center'
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    md={4}
                                    lg={4}
                                    xl={4}>
                                    태그명
                                </GridItem>
                                <GridItem
                                    container
                                    direction='column'
                                    alignItems='center'
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}>
                                    생성일자
                                </GridItem>
                                <GridItem
                                    container
                                    direction='column'
                                    alignItems='center'
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}>
                                    수정
                                </GridItem>
                                <GridItem
                                    container
                                    direction='column'
                                    alignItems='center'
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}>
                                    순서변경
                                </GridItem>
                                <GridItem
                                    xs={1}
                                    sm={1}
                                    md={1}
                                    lg={1}
                                    xl={1}>
                                    삭제
                                </GridItem>
                            </GridContainer>

                        </Paper>
                        {/*#태그*/}


                        {contents?.map((item, i) => {
                            return (
                                <Paper
                                    key={i}
                                    // className={[classes.paper, !(new Date(item.startAt).getTime() < new Date().getTime()  &&   new Date().getTime() < new Date(item.endAt).getTime()) && classes.notNow ]}
                                    variant='outlined'
                                    square
                                >
                                    <GridContainer>
                                        <GridItem
                                            // className={classes.symBolTag}
                                            container
                                            alignItems='center'
                                            justifyContent='center'
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={1}
                                            xl={1}
                                        >
                                            {/*<p>{item.orderNum}</p>*/}
                                            <p>{i + 1}</p>
                                        </GridItem>
                                        <GridItem
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4} container
                                            alignItems='center'
                                            justifyContent='center'>
                                            {item.title}
                                        </GridItem>
                                        <GridItem
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2} container
                                            alignItems='center'
                                            justifyContent='center'>
                                            {moment(item && item?.createdAt).format('YYYY/MM/DD h:mmA')}
                                        </GridItem>
                                        <GridItem
                                            container
                                            direction='column'
                                            alignItems='center'
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2}

                                        >
                                            <Button color='primary'
                                                    onClick={(event) => goodsManagingDetail(event, item)}>
                                                수정
                                            </Button>
                                        </GridItem>
                                        <GridItem
                                            container
                                            direction='column'
                                            alignItems='center'
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                        >
                                            <TextField align='right'
                                                       onChange={(e)=>{
                                                           dispatch(changeGoodsManagingAction({
                                                               id: item.id,
                                                               orderNum: e.target.value
                                                           }))
                                                       }}
                                                       onKeyUp={async (e) => {
                                                           if (e.keyCode == 13) {
                                                               if (confirm("변경하시겠습니까?")) {
                                                                   await goodsManagingApi.updateOrderContentsOrderStatic({
                                                                       id: item.id, orderNum: e.target.value
                                                                   });
                                                                   // location.reload()
                                                               }
                                                           }
                                                       }}
                                                       value={item.orderNum}
                                                       className={classes.inputTextAdd__textField}
                                                       label='숫자입력 후 enter'
                                                       name='orderNum'
                                                       variant='outlined'
                                                       size='small'
                                            />
                                        </GridItem>
                                        <GridItem
                                            container
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={1}
                                            xl={1}
                                        >
                                            <IconButton
                                                disabled={loading}
                                                aria-label='delete'
                                                onClick={() => deleteContent(item.id)}
                                            >
                                                <HighlightOffIcon/>
                                            </IconButton>
                                        </GridItem>
                                    </GridContainer>
                                </Paper>
                            )
                        })}
                    </TableContainer>
                </div>
            )}

        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.goodsManagingReducer.loading,
    error: state.goodsManagingReducer.error,
    contents: state.goodsManagingReducer.contents
})
export default connect(mapStateToProps, {
    getGoodsManagingListAction,
    orderTagAction,
    // deleteContentAction/
})(GoodsManaging)
