import axiosInterceptors from 'api/axios'
import queryString from "query-string";

const eventsManagingApi = {

    create: (params) => {
        const url = '/events/createEvents'
        return axiosInterceptors.post(url, { params })
    },

    getListEvents: (params) => {
        const url = '/events/getEventsList'
        return axiosInterceptors.get(url, { params })
    },
    update: (params) => {
        const url = '/events/updateEvents'
        return axiosInterceptors.put(url, { params })
    },
    updateStatus: (params) => {
        const url = '/events/updateEventsStatus'
        return axiosInterceptors.put(url, { params })
    }
    ,getEvents: (params) => {
        const url ='/events/getEvents'
        return axiosInterceptors.get(url, { params })
    }
    ,getListComments: (params) => {
        const url ='/comment/getListComment'
        return axiosInterceptors.get(url, { params })
    }
    ,destroy: (params) => {
        const url ='/events/deleteEvents'
        return axiosInterceptors.delete(url, { params })
    },
    getExcelFile : (params) =>
{
    let url

    // if (!params.hasOwnProperty('eventId')) {
        url = `/events/getEventsExcel?${queryString.stringify(
            params,
        )}`
    // }

    // res.writeHead(200, {
    //     'Content-Type': 'application/octet-stream; charset=utf-8',
    //     'Content-Disposition': 'attachment; filename=' + encodeURI('ssss')
    // });

    return axiosInterceptors.get(url, {
        responseType: 'blob',
        'Content-Disposition': 'attachment;filename=' + encodeURI('ssss')
    })
},
    getEventsCommentListExcel : (params) =>
{
    let url

    // if (!params.hasOwnProperty('eventId')) {
        url = `/events/getEventsCommentListExcel?${queryString.stringify(
            params,
        )}`
    // }

    // res.writeHead(200, {
    //     'Content-Type': 'application/octet-stream; charset=utf-8',
    //     'Content-Disposition': 'attachment; filename=' + encodeURI('ssss')
    // });

    return axiosInterceptors.get(url, {
        responseType: 'blob',
        'Content-Disposition': 'attachment;filename=' + encodeURI('ssss')
    })
},
    getEventsBingoExcel : (params) =>
{
    let url

    // if (!params.hasOwnProperty('eventId')) {
        url = `/events/getEventsBingoExcel?${queryString.stringify(
            params,
        )}`
    // }

    // res.writeHead(200, {
    //     'Content-Type': 'application/octet-stream; charset=utf-8',
    //     'Content-Disposition': 'attachment; filename=' + encodeURI('ssss')
    // });

    return axiosInterceptors.get(url, {
        responseType: 'blob',
        'Content-Disposition': 'attachment;filename=' + encodeURI('ssss')
    })
}

}

export default eventsManagingApi
