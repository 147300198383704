import { primaryColor } from 'assets/jss/material-dashboard-pro-react.js'

const statisticClick = (theme) => ({
  iconBtn: {
    marginLeft: '7px',
  },
  groupBtnDropdown: {
    boxShadow: 'unset',
  },

  setZindex: {
    zIndex: '9999',
  },

  mediaQueryBtn: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: '12%',
    },
  },

  // Datetime Picker
  setFlexBasis: {
    [theme.breakpoints.only('lg')]: {
      flexBasis: '81.333333%',
    },
  },

  responsiveStyle: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },

  setJustifyContent: {
    [theme.breakpoints.only('xl')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.only('md')]: {
      justifyContent: 'flex-start',
    },
  },

  styleDatePicker: {
    textAlign: 'center',
    '& > div': {
      width: '100%',
    },
  },

  styleSymbol: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },

    '& p': {
      fontWeight: 'bold',
      fontSize: '25px',
      color: 'gray',
    },
  },

  styleButtonSubmit: {
    [theme.breakpoints.down('md')]: {
      padding: '0 15px',
    },
  },

  paddingLeft: {
    paddingLeft: '0px !important',
  },
  blockMain: {
    display: 'flex',
    'justifyContent': 'space-between',
    '& div' : {
      width: '20%', border : '1px solid #c3cbd7', height: '100px',
      background: 'white',
      display: 'block',
      textAlign: 'center',
      '& span': {
        'display': 'inline-block'
      },
    }
  },
  content2 :{
    justifyContent : 'space-between',
    display: 'flex'
  },
  blockMain2: {
    padding: '10px',
    display: 'flex',
    'justifyContent': 'space-between',
    marginRight: '50px',
    border : '1px solid #c3cbd7',
    background: 'white',
    '& div' : {
      paddingRight: '10px',
      paddingLeft: '10px',
      paddingTop: '5px',
      paddingBottom: '5px',
        // height: '100px',
      display: 'block',
      textAlign: 'center',
      '& span': {
        'display': 'inline-block'
      },
    }
  },
  title: {
    fontWeight: 900,
    width: '100%',
    paddingTop: '20px',
  },
  title2: {
    fontWeight: 900,
    width: '100%',
    padding: '30px',
    fontSize: '20px'
  },
  content: {
    // fontWeight: 900,
    paddingTop: '10px',
    fontSize: '25px'
  },
  datePickerStyle:{
    marginTop: '10px',
    width: '100%',
    justifyContent: 'center'
  }


})

export default statisticClick
