import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Box from "@material-ui/core/Box";
import TableContainer from "@material-ui/core/TableContainer";
import moment from "moment";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CustomButton from "../../../components/CustomButtons/Button";
import TextField from "../../../components/Gm-TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import GridItem from "../../../components/Grid/GridItem";
import Pagination from "../../../components/Pagination/Pagination";
import {dateFormat} from "../../../util/filter";


const useRowStyles = makeStyles({
	table: {
		minWidth: 900,
	},
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	styleBox: {
		backgroundColor: '#EDEDED',
	},
	p0: {
		padding: 0
	},
	setZindexMenuList: {
		// '& div:last-child': {
		//     zIndex: 9999,
		// },
	},
	textFieldOne: {
		width: '100%',
	},
	gridContainerOne: {
		textAlign: 'center'
	}
})


const Row = (props) => {

	const { row, index, page, selectedChk, setSelectedChk, getData, bannerManagingApi, ModalMoreVert } = props
	const classes = useRowStyles()

	const [open, setOpen] = React.useState(false)

	const handleClick = (event, id) => {
		const selectedIndex = selectedChk.indexOf(id)
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedChk, id)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedChk.slice(1))
		} else if (selectedIndex === selectedChk.length - 1) {
			newSelected = newSelected.concat(selectedChk.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
					selectedChk.slice(0, selectedIndex),
					selectedChk.slice(selectedIndex + 1),
			)
		}

		setSelectedChk(newSelected)
	}

	const isSelected = (id) => selectedChk.indexOf(id) !== -1
	const isItemSelected = isSelected(row?.id)
	const labelId = `enhanced-table-checkbox-${index}`

	const handleChangeSelect = (e, row) =>{
		//update 문 치기 활성화 하나만이라도

		row.status = e.target.value
		updateBanner(row);
	}

	const updateBanner = async (row) => {
		try {

			// call api edit banner
			const body = {
				id: row?.id,
				webViewTitle: row?.webViewTitle,
				mainImage: row?.mainImage?.id || null,
				subImage: row?.subImage?.id || null,
				linkUrl: row?.linkUrl,
				status:row?.status,
				eventsId: row?.eventsId || null,
				position: row?.position,
				startAt: new Date(moment(row?.startAt).format("YYYY-MM-DD HH:mm")).getTime(),
				endAt: new Date(moment(row?.endAt).format("YYYY-MM-DD HH:mm")).getTime(),
				numOrder: parseInt(row?.numOrder),
				type: row.type,
			}


			// return
			await bannerManagingApi.edit(body)

			getData()

		} catch (error) {
			console.log(error)
		}
	}

	return (
			<React.Fragment>
				{/*// className={[classes.paper, !(new Date(item.startAt).getTime() < new Date().getTime()  &&   new Date().getTime() < new Date(item.endAt).getTime()) && classes.notNow ]}*/}
				<TableRow hover={true}
						  className={[classes.root]}
						  style={{'text-decoration' :  !(new Date(row.startAt).getTime() < new Date().getTime()  &&   new Date().getTime() < new Date(row.endAt).getTime())  &&  'line-through'}}
				>
					<TableCell padding='checkbox' align={'center'}>
						{row.status == 'REMOVED' ? null : (
							<Checkbox
								onClick={(event) => handleClick(event, row?.id)}
								checked={isItemSelected}
								inputProps={{'aria-labelledby': labelId}}
							/>
						)}
					</TableCell>
					<TableCell align={'left'}
					>{row.id}


					</TableCell>
						<TableCell align={'left'}>{row.exposure==='BANNER'&&'배너'}{row.exposure==='POPUP'&&'팝업'}
							{row.position==='MAIN'&&'(메인-검색메인)'}{row.position==='STAR'&&'(라이징스타)'}{row.position==='SHOP_MAIN'&&'(꾸밍샵메인)'}
							{row.position==='POST_GOODS'&&'(굿즈)'}
						</TableCell>
					<TableCell align={'left'}
					>{row.webViewTitle}</TableCell>
					{/*<TableCell align={'center'}>{row.position==='MAIN'&&'메인배너'} {row.position==='BOTTOM'&&'하단'}</TableCell>*/}
					<TableCell align={'left'}>{row.type==='WEB_VIEW'&&'내부URL'}{row.type==='URL'&&'외부URL'}{row.type==='EVENT'&&'이벤트'}{row.type==='CHALLENGE'&&'챌린지'}</TableCell>
					<TableCell align={'left'}>{row.status == 'ALWAYS' ? '-' : dateFormat(row.startAt)}</TableCell>
					<TableCell align={'left'}>{row.status == 'ALWAYS' ? '-' : dateFormat(row.endAt)}</TableCell>
					<TableCell align={'left'}>{dateFormat(row.createdAt)}</TableCell>
					{/*<TableCell align={'left'} >{row.isSend ? '발송' : '미발송'}</TableCell>*/}
					<TableCell align={'left'} style={{'min-width': '100px'}}>{row.numOrder}</TableCell>
					<TableCell className={classes.setZindexMenuList} align='left'>
						<GridItem
								className={classes.gridContainerOne}
								xs={12}
						>
							<TextField
									id='user-managing-select'
									select
									size='small'
									className={classes.textFieldOne}
									value={row.status}
									onChange={(e)=>{
										handleChangeSelect(e,row)
									}}
							>
								<MenuItem value={'ACTIVE'}>활성화</MenuItem>
								<MenuItem value={'DEACTIVATED'}>비활성화</MenuItem>
								<MenuItem value={'REMOVED'}>삭제됨</MenuItem>
								<MenuItem value={'ALWAYS'}>영구 노출</MenuItem>
							</TextField>
						</GridItem>
					</TableCell>
					<TableCell align={'center'} className={classes.p0} >
						<CustomButton color="primary" mr={2} variant="contained" onClick={() => ModalMoreVert(row)} >수정</CustomButton>
					</TableCell>
				</TableRow>
			</React.Fragment>
	)

}

// TABLe head 정렬 때문에
function EnhancedTableHead(props) {



	const { classes, order, orderBy, onRequestSort, headCells } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
			<TableHead>
				<TableRow>
					<TableCell padding='checkbox'></TableCell>
					{/*<TableCell style={{ width: '50px' }}>No.</TableCell>*/}

					{headCells.map((headCell, index) => (
							<TableCell
									key={headCell.id}
									align={headCell.align}
									padding={headCell.disablePadding ? 'none' : 'normal'}
									sortDirection={orderBy === headCell.id ? order : false}
									style={{
										minWidth: headCell.minWidth ? headCell.minWidth : 170,

									}}

									// className={orderBy === headCell.id ? classes.active : classes.nonActive }
							>
								<TableSortLabel
										style={{
											color : orderBy === headCell.id ? 'red' : 'black'
										}}
										active={orderBy === headCell.id}
										direction={orderBy === headCell.id ? order : 'asc'}
										onClick={
											headCell.allowSortable
													?
													createSortHandler(headCell.id)
													: undefined
										}
								>
									{headCell.label}

								</TableSortLabel>

								{/*{loading ? (*/}
								{/*    <CircularProgress size={30} className={classes.buttonProgress} />*/}
								{/*) : (*/}



							</TableCell>
					))}
					<TableCell >
						{/*수정*/}


					</TableCell>
					<TableCell >
						{/*수정*/}


					</TableCell>

				</TableRow>
			</TableHead>
	)
}


const BannerListTable = (props) =>{

	const {
		rows,
		page,
		setPage,
		totalPages,
		sortable,
		orderBy,
		headCells,
		getData,
		bannerManagingApi,
		order,
		setOrder,
		setOrderBy,
		classes,
		ModalMoreVert,
		useDispatch,
		setSelectedChk,
		selectedChk
	} = props

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	return (
			<TableContainer component={Paper}>
				<Table  aria-label='collapsible table'>

					<EnhancedTableHead
							sortable={sortable}
							classes={classes}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							headCells={headCells}
					/>

					<TableBody>
						{rows.map((row, i) => {
							return (
									<Row
											key={row.id}
											row={row}
											index={i}
											page={page}
											setSelectedChk={setSelectedChk}
											selectedChk={selectedChk}
											ModalMoreVert={ModalMoreVert}
											useDispatch={useDispatch}
											getData={getData}
											bannerManagingApi={bannerManagingApi}
									/>
							)
						})}
					</TableBody>
				</Table>

				<Box mb={2} mt={2} display='flex' justifyContent='flex-end'>
					<Pagination
							pagination={page==0 ? 1 : page} 
							setPagination={setPage}
							totalPages={totalPages}
					/>
				</Box>

			</TableContainer>
	)



}

export default BannerListTable