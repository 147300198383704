import axiosInterceptors from 'api/axios'
import axiosInterceptors2 from 'api/axios2'

const phraseSettingApi = {

    getPhraseSettings: (params) => {
        const url = '/phraseSetting/getPhraseSettings'
        return axiosInterceptors.get(url, { params })
    },

    getPhraseList: (params) => {
        const url = '/phraseSetting/list'
        return axiosInterceptors.get(url, { params })
    },
    now: (params) => {
        const url = '/phraseSetting/now'
        return axiosInterceptors2.get(url, { params })
    },
    createPhraseSetting: (body)=> {
        const url = '/phraseSetting/createPraseSettings'
        return axiosInterceptors.post(url, {body})
    },
    settingPhrase: (body)=> {
        const url = '/phraseSetting/settingPhrase'
        return axiosInterceptors.post(url, body)
    },




}

export default phraseSettingApi