import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import moment from "moment/moment";

const useStyles = makeStyles({
    table: {
        minWidth: 900,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
})

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHeadStaticOfClick(props) {
    const { classes, order, orderBy, onRequestSort, headCells } = props

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, i) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            minWidth: headCell.minWidth ? headCell.minWidth : 170,
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export const StaticJoinOutTable = (props) => {
    // const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('totalViews')
    const classes = useStyles()

    const { rows, headCells, setOrder, order } = props

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
                <EnhancedTableHeadStaticOfClick
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headCells}
                />

                <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                        return (
                            <TableRow hover key={index}>
                                <TableCell align='center'>{moment(row?.updatedAt).format('YYYYMMDD HH:mm:s')}</TableCell>
                                <TableCell align='center'>{row?.user?.clientId}</TableCell>
                                <TableCell align='center'>{row?.user?.memberID}</TableCell>
                                <TableCell align='center'>

                                    {row?.user?.accountType==='NORMAL'&& <span style={{background:'#fc876f', color:'#fff', 'borderRadius':'5px', padding:'5px'}}>교보 북클럽</span>}
                                    {row?.user?.accountType==='KAKAO'&& <span style={{background:'#f9e000', 'borderRadius':'5px', padding:'5px'}}>카카오</span>}
                                    {row?.user?.accountType==='NAVER'&& <span style={{background:'#1Ec800', color:'#fff', 'borderRadius':'5px', padding:'5px'}}>네이버</span>}
                                    {row?.user?.accountType==='APPLE'&& <span style={{background:'#000', color:'#fff', 'borderRadius':'5px', padding:'5px'}}>애플</span>}
                                    {row?.user?.accountType==='GOOGLE'&& <span style={{background:'#4285F4', color:'#fff', 'borderRadius':'5px', padding:'5px'}}>구글</span>}

                                </TableCell>
                                <TableCell align='center'>{row?.user?.nickname}</TableCell>
                                <TableCell align='center'>{row?.report_category?.description}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

/* StaticOfSearchTable */

function EnhancedTableHeadStaticOfSearch(props) {
    const { classes, order, orderBy, onRequestSort, headCells } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, i) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            minWidth: headCell.minWidth ? headCell.minWidth : 170,
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export const StaticOfSearchTable = (props) => {
    // const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('totalSearch')
    const classes = useStyles()

    const { rows, headCells, setOrder, order } = props

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
                <EnhancedTableHeadStaticOfSearch
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headCells}
                />

                <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                        return (
                            <TableRow hover key={index}>
                                <TableCell align='left'>{row?.textSearch}</TableCell>
                                <TableCell align='right'>{row?.resultSearch}</TableCell>
                                <TableCell align='right'>{row?.totalSearch}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
